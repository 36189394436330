const Codes: {
  [x: string]: number
} = {
  // Server codes
  OPC_S_REGISTERED: 100,
  OPC_S_SET_META: 101,
  OPC_S_USER_AUTHENTICATED: 102,
  OPC_S_USER_NOT_AUTHENTICATED: 103,
  OPC_S_UPDATE_AVAILABLE: 104,
  OPC_S_UPDATE_PUSH: 105,
  OPC_S_LOCK_CHANGE: 106,
  OPC_S_SIMULATION_STATE_UPDATE: 107,
  OPC_S_GENERATE_DONE: 108,
  OPC_S_EXECUTION_DONE: 109,

  // Client codes
  OPC_C_GET_META: 200,
  OPC_C_GET_DATA: 201,
  OPC_C_SET_PLOT_DEFINITION: 202,
  OPC_C_REMOVE_PLOT_DEFINITION: 203,
  OPC_C_AUTHENTICATE_USER: 204,
  OPC_C_CONFIRM_MESSAGE_RECEIVED: 205,
  OPC_C_LOGOUT_USER: 206,
  OPC_C_UPDATE_GET: 207,
  OPC_C_SET_LIVE_MODE: 208,
  OPC_C_AUTHENTICATE_LOCAL_USER: 209,
  OPC_C_AUTHENTICATE_VIA_JWT: 210,
}

export default Codes
