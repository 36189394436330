export enum StrandGuideActionsEnum {
  ACTION_ADD_STRAND_GUIDE = 'StrandGuide/ACTION_ADD_STRAND_GUIDE',
  DELETE = 'StrandGuide/DELETE',
  REMOVE_CHILD_ID = 'StrandGuide/REMOVE_CHILD_ID',
  UPDATE = 'StrandGuide/UPDATE',
}

export function addStrandGuide (strandGuideHash: Record<number, StrandGuide & Parent>) {
  return {
    type: StrandGuideActionsEnum.ACTION_ADD_STRAND_GUIDE,
    strandGuideHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const StrandGuideReducers: Record<string, Function | undefined> = {
  [StrandGuideActionsEnum.ACTION_ADD_STRAND_GUIDE]:
  (_state: StrandGuideHash, action: { strandGuideHash: StrandGuideHash}) => (
    action.strandGuideHash
  ),

  [StrandGuideActionsEnum.REMOVE_CHILD_ID]:
  (state: StrandGuideHash, action: {id: number, child: {type: string, id: number}}) => {
    const newState = { ...state }
    const element: any = newState[action.id]

    element[`#${action.child.type}Ids`] = element[`#${action.child.type}Ids`]
      .filter((childId: number) => childId !== action.child.id)

    return newState
  },

  [StrandGuideActionsEnum.UPDATE]: (state: StrandGuideHash, action: {elements: StrandGuideHash}) => {
    return {
      ...state,
      ...action.elements,
      // '#hasChanges': false,
    }
  },
}

export default function (
  state: StrandGuideHash = {},
  action: any,
) {
  const handler = StrandGuideReducers[action.type]

  return handler ? handler(state, action) : state
}
