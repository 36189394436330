import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { ThemeProvider } from 'styled-components'

import StyleConfig from 'react/visualization/dashboard/config/StyleConfig'
import Input from 'react/specific/Input'
import FeatureFlags from 'react/FeatureFlags/index'

import StrandGuide from './StrandGuide'
import Nozzles from './Nozzles'
import { PRE_TRANS } from './Logic'
import { Wrapper, Title } from './styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
  authenticationData: state.application.main.authenticationData,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  uploadType: string
};

export class InputDataDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    uploadType: 'strandGuide',
  }

  // @AnalyzeTime(0)
  handleChangeUploadType = (event: any) =>
    this.setState({ [(event.target.name as string)]: (event.target.value as string) });

  @AnalyzeTime(0)
  render () {
    const { uploadType } = this.state
    const { darkTheme, authenticationData: { featureFlags }, t } = this.props
    let usedUploadType = uploadType

    const canEditRoller = FeatureFlags.canEditRoller(featureFlags)
    const canEditNozzles = FeatureFlags.canEditNozzle(featureFlags)

    const uploadTypeSelectors = []

    if (canEditRoller) {
      uploadTypeSelectors.push({ key: 'strandGuide', value: t(`${PRE_TRANS}.uploadType.strandGuide`) })
    }

    if (canEditNozzles) {
      uploadTypeSelectors.push({ key: 'nozzles', value: t(`${PRE_TRANS}.uploadType.nozzles`) })
    }

    if (uploadTypeSelectors.length === 1) {
      usedUploadType = uploadTypeSelectors[0].key
    }

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <Wrapper>
          <Title>{t(`${PRE_TRANS}.title`)}</Title>
          <Input
            name='uploadType'
            type='select'
            label={t(`${PRE_TRANS}.uploadType.label`)}
            title={t(`${PRE_TRANS}.uploadType.title`)}
            value={usedUploadType}
            selectors={uploadTypeSelectors}
            onChange={this.handleChangeUploadType}
          />
          {canEditRoller && uploadType === 'strandGuide' && <StrandGuide />}
          {canEditNozzles && uploadType === 'nozzles' && <Nozzles />}
        </Wrapper>
      </ThemeProvider>
    )
  }
}

export default withNamespaces('application')(connector(InputDataDialog as any) as any) as any
