import * as THREE from 'three'
import Mold from 'three/objects/Mold'
import Getters from './Getters'
import DrawHandlers from './DrawHandlers'
import Util from '../../logic/Util'
import OrbitControls from '../../logic/OrbitControls'
import SectionView from '.'
import { AnalyzeTime } from 'Util'

export default class SectionHandlers {
  @AnalyzeTime(0)
  static hideAllButtons (buttons: any) {
    if (Object.keys(buttons).length) {
      Object.keys(buttons).forEach(name => {
        if (name === 'expandSectionViewButton') {
          return
        }

        buttons[name].visible = false
      })
    }
  }

  @AnalyzeTime(0)
  static handleUpdatePlane (view: SectionView) {
    view.updatePlane = false

    const { thickness, width, minWidth, maxWidth } = Mold

    view.passlineCurve?.hideStrand()

    view.sectionPlaneFolded.geometry = Getters.getSectionPlaneFoldedGeometry(thickness, maxWidth || width, view)

    view.sectionPlaneHeader = Getters.getSectionPlaneHeader(view, thickness)

    Util.addOrReplace(view.sectionPlaneFolded, view.sectionPlaneHeader)

    view.sectionPartingLineHeader = Getters.getPlanePartingLineHeader(view, thickness)

    Util.addOrReplace(view.sectionPlaneFolded, view.sectionPartingLineHeader)

    view.sectionPlaneFolded.geometry.translate(-thickness / 2, 0, 0)

    DrawHandlers.drawStrandAndRuler(view, thickness, width, minWidth, maxWidth)
    DrawHandlers.drawPassLineCoordinates(view)
    DrawHandlers.drawNextElements(view)

    if (!view.views?.uiView?.isSectionActive) {
      view.hideSection()
    }
    else {
      const { width, height } = view.views.uiView.viewport

      view.setView(height, true)
      view.showSection(width, height)
    }

    view.updateTransform(true)
  }

  @AnalyzeTime(0)
  static showClosestSnapsTooltip (snap: any, tooltips: Tooltip[]) {
    const { parent, userData: { markerName } } = snap

    const marker = parent.getObjectByName(markerName)

    if (parent.userData.deleted === 0 && parent.visible) {
      tooltips.push({
        tooltip: marker.userData.tooltip,
        position: 'right',
      })
    }
  }

  @AnalyzeTime(0)
  static setupControls (view: SectionView, center = false) {
    if (!view.orthographicControls) {
      view.orthographicControls = new (OrbitControls as any)(view.orthographicCamera, view.renderer.domElement)
      view.orthographicControls.enabled = true
      view.orthographicControls.enableKeys = false
      view.orthographicControls.enableRotate = false
      view.orthographicControls.update()
    }

    if (center) {
      view.orthographicControls.target.copy(center)
    }

    view.controls = view.orthographicControls
    view.controls.update()
  }

  @AnalyzeTime(0)
  static setupRaycaster (view: SectionView, near: number, far: number) {
    view.raycaster = new THREE.Raycaster()
    view.raycaster.near = near
    view.raycaster.far = far
  }

  @AnalyzeTime(0)
  static setupCamera (view: SectionView, near: number, far: number) {
    const camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, near, far)

    camera.zoom = 6

    // noinspection JSUnusedGlobalSymbols
    view.perspectiveCamera = SectionView.debug ? new THREE.PerspectiveCamera() : camera
    view.orthographicCamera = camera
    view.camera = camera
  }

  @AnalyzeTime(0)
  static setupSectionPlaneFolded (view: SectionView) {
    const planeGeometryFolded = new THREE.PlaneBufferGeometry(2, 4, 1)

    // section plane folded is the box where everything is displayed
    view.sectionPlaneFolded = new THREE.Mesh(planeGeometryFolded, SectionView.planeMaterial)
    view.sectionPlaneFolded.rotateX(-Util.RAD90)
    view.sectionPlaneFolded.visible = true
    view.sectionPlaneFolded.name = 'sectionPlaneFolded'
  }
}
