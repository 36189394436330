import { AnalyzeTime } from 'Util'
import createStore from './createStore'
export default class Index {
  static _store: any = null

  @AnalyzeTime(0)
  static getStore () {
    if (!Index._store) {
      const initialState = window.___INITIAL_STATE__

      Index._store = createStore(initialState)
    }

    return Index._store
  }
}
