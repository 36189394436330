/* eslint-disable key-spacing */
import { General } from './GeneralDefinition'
import { SegmentGroup } from './SegmentGroupDefinition'
import { SegmentGroupSupportPoints } from './SegmentGroupSupportPointsDefinition'
import { Segment } from './SegmentDefinition'
import { SupportPoint } from './SupportPointDefinition'
import { Nozzle } from './NozzleDefinition'
import { Roller } from './RollerDefinition'
import { RollerBody } from './RollerBodyDefinition'
import { RollerBearing } from './RollerBearingDefinition'
import { SensorPoint } from './SensorPointDefinition'
import { DataPoint } from './DataPointDefinition'
import { DataLine } from './DataLineDefinition'
import { ConsistencyCheck } from './ConsistencyCheckDefinition'

export type DefinitionKey =
  'General' |
  'SegmentGroup' |
  'SegmentGroupSupportPoints' |
  'Segment' |
  'SupportPoint' |
  'Nozzle' |
  'Roller' |
  'RollerBody' |
  'RollerBearing' |
  'SensorPoint' |
  'DataPoint' |
  'DataLine' |
  'ConsistencyCheck'

export const DEFINITION: Record<string, any> = { // TODO: add Definition type
  General,
  SegmentGroup,
  SegmentGroupSupportPoints,
  Segment,
  SupportPoint,
  Nozzle,
  Roller,
  RollerBody,
  RollerBearing,
  SensorPoint,
  DataPoint,
  DataLine,
  ConsistencyCheck,
}
/* eslint-enable key-spacing */
