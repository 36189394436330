/* eslint-env browser */

import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import ViewGrid from './ViewGrid'
import Tabs from './Tabs'

import { Wrapper } from './Dashboard/styles'
import { DefaultState } from 'types/state'
import ViewTile from './ViewTile'

const connector = connect(({
  data,
  visualization,
}: DefaultState) => ({
  currentDashboard: visualization.currentDashboard,
  rootData: data.rootData,
  viewsObject: visualization.viewsObject,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  viewId: string,
  simple: boolean,
}

type State = {
  tileIdsWithoutData: string[]
};

export class View extends PureComponent<Props, State> {
  state = {
    tileIdsWithoutData: [],
  }

  render () {
    const { viewsObject, viewId, currentDashboard, simple, rootData } = this.props

    const object = viewsObject[viewId] || {}
    const dashboards = object.dashboards || {}

    const showTabs = true // isEditModeOn || Object.keys(dashboards).length > 1
    const currentLayout = (dashboards[currentDashboard[viewId]] || {}).layout || []
    const oldLayout = (dashboards[currentDashboard[viewId]] || {}).oldLayout || []
    const viewDashboards = (viewsObject[viewId] || {}).dashboards || {}
    const currentDashboardTiles = viewDashboards[currentDashboard[viewId]]

    let showTiles = true

    if (!rootData.Caster || !(currentDashboardTiles && currentDashboardTiles.tileIds)) {
      showTiles = false
    }

    const tiles = showTiles
      ? currentDashboardTiles.tileIds.map(tileId => (
        <ViewTile tileId={tileId} key={tileId} viewId={viewId} />
      ))
      : []

    return (
      <Wrapper>
        {(showTabs && !simple) && <Tabs viewId={viewId} />}
        <ViewGrid
          viewId={viewId}
          showTabs={showTabs}
          currentDashboard={currentDashboard[viewId]}
          layout={currentLayout}
          oldLayout={oldLayout}
          dashboard={(dashboards[currentDashboard[viewId]] || {})}
          simple={simple}
        >
          {tiles}
        </ViewGrid>
      </Wrapper>
    )
  }
}

export default connector(View as any) as any
