import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import { PlotConfig, TileConfig, ViewsObject } from 'types/visualization'

export class ApiService {
  static setVisConfig (
    configId: string,
    data: {
      viewsObject: ViewsObject,
      plotConfigs: Record<string, PlotConfig>,
      tileConfigs: Record<string, TileConfig>
    },
  ): Promise<any> {
    if (
      !configId ||
      configId.startsWith('group-') ||
      !data.viewsObject ||
      !Object.entries(data.viewsObject).length
    ) {
      return Promise.resolve()
    }

    return ApiClient
      .patch(`${Network.URI}/visualization_config/${configId}`, { data: { d: 1, data } })
      // eslint-disable-next-line no-console
      .catch((err) => {
        console.log('Error:', err)

        return Promise.resolve()
      })
  }
}
