export enum RollerActionsEnum {
  ACTION_ADD_ROLLER = 'Roller/ACTION_ADD_ROLLER',
  DELETE = 'Roller/DELETE',
  DELETE_MULTIPLE = 'Roller/DELETE_MULTIPLE',
  REMOVE_CHILD_ID = 'Roller/REMOVE_CHILD_ID',
  UPDATE = 'Roller/UPDATE',
}

export function addRoller (rollerHash: Record<number, Roller & Parent>) {
  return {
    type: RollerActionsEnum.ACTION_ADD_ROLLER,
    rollerHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerReducers: Record<string, Function | undefined> = {
  [RollerActionsEnum.ACTION_ADD_ROLLER]:
  (_state: RollerHash, action: { rollerHash: RollerHash }) => (
    action.rollerHash
  ),

  [RollerActionsEnum.DELETE]: (state: RollerHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerActionsEnum.DELETE_MULTIPLE]: (state: RollerHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerActionsEnum.REMOVE_CHILD_ID]: (state: RollerHash, action: {id: number, child: {type: string, id: number}}) => {
    const newState = { ...state }
    const element: any = newState[action.id]

    element[`#${action.child.type}Ids`] = element[`#${action.child.type}Ids`]
      .filter((childId: number) => childId !== action.child.id)

    return newState
  },

  [RollerActionsEnum.UPDATE]: (state: RollerHash, action: {elements: RollerHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (
  state: RollerHash = {},
  action: any,
) {
  const handler = RollerReducers[action.type]

  return handler ? handler(state, action) : state
}
