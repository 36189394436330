import type { ElementsHashes } from 'types/state'

export function addToModules (element: any, type: string, modules: Record<string, Module>) {
  const inModules = element._module.split(' ')

  for (const module of inModules) {
    if (!modules[module]) {
      modules[module] = {
        [type]: {
          [element._id]: 1,
        },
      }
    }
    else if (!modules[module][type]) {
      modules[module][type] = {
        [element._id]: 1,
      }
    }
    else {
      modules[module][type][element._id] = 1
    }
  }
}

export enum moduleActions {
  ACTION_ADD_MODULES = 'MODULES/ACTION_ADD_MODULES',
  DELETE = 'MODULES/DELETE',
  UPDATE = 'MODULES/UPDATE',
}

export function updateModules (data: ElementsHashes) {
  const modules: Record<string, Module> = {}

  Object.keys(data).forEach(type => {
    Object.values((data as any)[type]).forEach((element: any) => {
      if (element._module) {
        addToModules(element, type, modules)
      }
    })
  })

  return {
    type: moduleActions.ACTION_ADD_MODULES,
    modules,
  }
}

export function addModules (modules: Record<string, Module>) {
  return {
    type: moduleActions.ACTION_ADD_MODULES,
    modules,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const modulesReducers: Record<string, Function | undefined> = {
  [moduleActions.ACTION_ADD_MODULES]:
  (state: any, action: { modules: Record<string, Module> }) => {
    return {
      ...action.modules,
    }
  },
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = modulesReducers[action.type]

  return handler ? handler(state, action) : state
}
