import LogicInterface from './LogicInterface'
import IpcManager from '../IpcManager'

// If electron and a user is not logged in we send to electron

export default class ExternalLogic extends LogicInterface {
  on (...params: unknown[]) {
    const { isLoggedIn } = IpcManager.props

    if (window.isElectron) {
      IpcManager.electron.on(...params)
    }

    if (!window.isElectron || isLoggedIn) {
      return IpcManager.server.on(...params)
    }

    IpcManager.internal.on(...params)
  }

  send (...params:unknown[]) {
    const { isLoggedIn } = IpcManager.props

    if (window.isElectron) {
      IpcManager.electron.send(...params)
    }

    if (!window.isElectron || isLoggedIn) {
      return IpcManager.server.send(...params)
    }

    IpcManager.internal.send(...params)
  }

  removeListener (...params:unknown[]) {
    const { isLoggedIn } = IpcManager.props

    if (!window.isElectron || isLoggedIn) {
      return IpcManager.server.removeListener(...params)
    }

    IpcManager.electron.removeListener(...params)
    IpcManager.internal.removeListener(...params)
  }
}
