import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'

import Button from '../../../components/Button'
import TimeUtil from '../../../../logic/TimeUtil'

import { Wrapper, Content, Change, EditContent, ButtonWrapper, Spinner } from './styles'
import Logic, { TRANS } from './Logic'

import type { Props, State } from './Logic'
import { AnalyzeTime } from 'Util'
import { DefaultState } from 'types/state'

const connector = connect(({ application: { main } }: DefaultState) => ({
  currentSimulationCase: main.currentSimulationCase,
}))

export class EditBoxWrapper extends Component<Props, State> {
  state: State = {
    content: '',
    editContent: '',
    change: {},
    isEditing: false,
    savePending: false,
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount (): void {
    this.handleLoadData()
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props, prevState: State) {
    const { isEditing } = this.state
    const { configId, onChildState } = this.props

    if (prevState.isEditing !== isEditing) {
      onChildState({ isEditing })
    }

    if (configId !== prevProps.configId) {
      this.handleLoadData()
    }
  }

  // @AnalyzeTime(0)
  handleLoadData = () => {
    const { configId, currentSimulationCase, enqueueSnackbar, t } = this.props

    this.setState({ loading: true })

    setTimeout(() => {
      Logic.getContent(configId, currentSimulationCase._id)
        .then(({ content, change }) => this.setState({ content, change, editContent: content, loading: false }))
        .catch(() => {
          this.setState({ loading: false })

          enqueueSnackbar(
            t(`${TRANS}.loading.error`),
            { autoHideDuration: 2000, variant: 'error', key: 'editable_file_loading_error', preventDuplicate: true },
          )
        })
    }, 0)
  };

  // @AnalyzeTime(0)
  handleEdit = () => {
    const { currentSimulationCase, configId } = this.props
    const isFrozen = Logic.isFrozen(currentSimulationCase)
    const isOnlyViewable = Logic.isOnlyViewable(currentSimulationCase, configId)

    if (!isFrozen && !isOnlyViewable) {
      this.setState({ isEditing: true })
    }
  };

  // @AnalyzeTime(0)
  handleChange = (event: any) => {
    const { value } = event.target

    this.setState({ editContent: value })
  };

  // @AnalyzeTime(0)
  handleSave = () => {
    const { editContent } = this.state
    const { configId, currentSimulationCase, enqueueSnackbar, t } = this.props

    this.setState({ savePending: true })

    Logic.patchContent(configId, currentSimulationCase._id, editContent)
      .then(({ change }) => {
        this.setState({ savePending: false, isEditing: false, content: editContent, change })

        enqueueSnackbar(t(`${TRANS}.save.success`), { autoHideDuration: 2000, variant: 'success' })
      })
      .catch(() => {
        this.setState({ savePending: false })

        enqueueSnackbar(t(`${TRANS}.save.error`), { autoHideDuration: 2000, variant: 'error' })
      })
  };

  // @AnalyzeTime(0)
  handleCancel = () => {
    const { content } = this.state

    this.setState({ isEditing: false, editContent: content })
  };

  // @AnalyzeTime(0)
  handleWheel = (event: any) => {
    event.stopPropagation()
  };

  @AnalyzeTime(0)
  render () {
    const { content, editContent, change, isEditing, savePending, loading } = this.state
    const { t, currentSimulationCase, configId, tileId } = this.props
    const { authorName, createdAt } = change || {}
    const isFrozen = Logic.isFrozen(currentSimulationCase)
    const isOnlyViewable = Logic.isOnlyViewable(currentSimulationCase, configId)

    if (loading) {
      return (
        <Wrapper>
          <Spinner disableShrink />
        </Wrapper>
      )
    }

    if (!isEditing || isFrozen || isOnlyViewable) {
      return (
        <Wrapper>
          <Content
            onWheel={this.handleWheel}
            id={`editable_content_${tileId}`}
            isFrozen={isFrozen || isOnlyViewable}
            onDoubleClick={this.handleEdit}
            title={!isFrozen ? (isOnlyViewable ? '' : t(`${TRANS}.content.edit`)) : t(`${TRANS}.content.view`)}
          >{content}
          </Content>
          <Change>{
            createdAt
              ? t(`${TRANS}.changed.message`, { user: authorName, dateTime: TimeUtil.getDisplayDateTime(createdAt) })
              : (!isFrozen && !isOnlyViewable ? t(`${TRANS}.changed.not_yet`) : t(`${TRANS}.changed.view`))
          }
          </Change>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <EditContent onWheel={this.handleWheel} onChange={this.handleChange} disabled={savePending}>
          {editContent}
        </EditContent>
        <ButtonWrapper>
          <Button onClick={this.handleSave} title={t(`${TRANS}.content.save.title`)} disabled={savePending} half>
            {t(`${TRANS}.content.save.label`)}
          </Button>
          <Button onClick={this.handleCancel} title={t(`${TRANS}.content.cancel.title`)} disabled={savePending} half>
            {t(`${TRANS}.content.cancel.label`)}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

export default withSnackbar(withNamespaces('visualization')(connector(EditBoxWrapper as any) as any) as any) as any
