import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import { withNamespaces } from 'react-i18next'
import Switch from '@material-ui/core/Switch'

import DataActions from '../store/data/actions'

import NetworkManager from '../network/NetworkManager'
import FeatureFlags from './FeatureFlags'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'

const UpdatesDisplayView = styled.div`${() => css`
  position: absolute;
  right: 10px;
  bottom: 10px;
  min-width: 35px;
  height: 34px;
  background: #22282e;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  line-height: 34px;
  border-radius: 16px;
  padding: 0 10px;
  font-size: 14px;
`}`

const Count = styled.span`${() => css`
  margin: 0 8px;
`}`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const I = styled.div<{loading?: boolean}>`${({ loading }) => css`
  font-weight: bold;
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
  z-index: 100;

  :hover {
    color: #a2a6a9;
  }
  
  ${loading && css`
    animation: ${rotate} 2s linear infinite;
  `};
`}`

const StyledSwitch = styled(Switch)`${() => css`
  margin: -8px -15px;
`}`

const connector = connect((state: DefaultState) => ({
  liveMode: state.data.liveMode,
  openDialogs: state.application.main.openDialogs,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setLiveMode: DataActions.setLiveMode,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  updatesCount: number;
  t: Translation
}

export class UpdatesDisplay extends Component<Props> {
  handleChange = () => {
    const { liveMode, setLiveMode } = this.props

    NetworkManager.toggleLiveMode(!liveMode)
    setLiveMode(!liveMode)
  };

  handleRefreshClick = () => {
    const { currentSimulationCase: { _id } } = this.props

    NetworkManager.requestUpdates(_id)

    // TODO: set loading status for refresh button
  };

  render () {
    const { updatesCount, liveMode, t } = this.props

    return (
      <UpdatesDisplayView title={t('updatesDisplay.updates', { count: updatesCount })}>
        <StyledSwitch
          checked={liveMode}
          onChange={this.handleChange}
          color='primary'
          title={t(`updatesDisplay.liveMode.${liveMode ? 'on' : 'off'}`)}
        />
        {
          !liveMode && updatesCount > 0 &&
            <span title={t('updatesDisplay.refresh', { count: updatesCount })}>
              <Count>{updatesCount}</Count>
              <I className='pe-7s-refresh-2' onClick={this.handleRefreshClick} />
            </span>
        }
      </UpdatesDisplayView>
    )
  }
}

export default withNamespaces('caster')(connector(UpdatesDisplay as any) as any) as any
