/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Container } from 'react-smooth-dnd'
import { v4 as uuid } from 'uuid'
import sizeMe from 'react-sizeme'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from '../../../store/visualization/actions'

import { TabsWrapper, Tab, AddDashboard, ViewIcon, ViewIconsWrapper, CompactViewIcons } from './Dashboard/styles'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'

const connector = connect(({ visualization, application }: DefaultState) => ({
  featureFlags: application.main.authenticationData.featureFlags,
  appState: application.main.appState,
  viewsObject: visualization.viewsObject,
  currentDashboard: visualization.currentDashboard,
  isEditModeOn: visualization.isEditModeOn,
}), {
  setDashboardObject: VisualizationActions.setDashboardObject,
  setCurrentDashboard: VisualizationActions.setCurrentDashboard,
  showDeleteDashboardDialog: VisualizationActions.showDeleteDashboardDialog,
  splitView: VisualizationActions.splitView,
  deleteSplitView: VisualizationActions.deleteSplitView,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
  changeTabs: VisualizationActions.changeTabs,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  size: any
  viewId: string
  t: Translation
}

type State = {
  middleMouseDownTab: string | undefined | null,
  compactMenu: boolean
};

export class Tabs extends Component<Props, State> {
  tabWrapperRef?: HTMLElement = undefined;

  state = {
    middleMouseDownTab: null,
    compactMenu: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { viewsObject, viewId } = this.props
    const dashboards = (viewsObject[viewId] || {}).dashboards || {}
    const currentDashboardKeys = Object.keys(dashboards)

    if (currentDashboardKeys.length < 1) {
      this.handleAddDashboard(true)
    }
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleResize()
  }

  // TODO: without any type it would be an event, is this correct?
  // @AnalyzeTime(0)
  handleAddDashboard = (storeOnly:any = false) => {
    const { viewId, setDashboardObject, t } = this.props
    const dashboardId = `dashboard_${uuid()}`

    setDashboardObject(viewId, { name: t('tabs.defaultName') }, dashboardId, storeOnly)
  };

  // @AnalyzeTime(0)
  handleResize = () => {
    this.handleWheel({
      target: this.tabWrapperRef,
      deltaY: 0,
    })
  };

  // @AnalyzeTime(0)
  handleWheel = (event: any) => {
    if (!this.tabWrapperRef) {
      return
    }

    const target = this.tabWrapperRef

    target.scrollLeft += event.deltaY

    if (target.scrollLeft < 25) {
      target.setAttribute('data-arrow-left', 'off')
    }
    else {
      target.setAttribute('data-arrow-left', 'on')
    }

    if (target.offsetWidth + target.scrollLeft > target.scrollWidth - 25) {
      target.setAttribute('data-arrow-right', 'off')
    }
    else {
      target.setAttribute('data-arrow-right', 'on')
    }
  };

  // @AnalyzeTime(0)
  handleTabWrapperRef = (ref: any) => {
    if (ref) {
      this.tabWrapperRef = ref.container.querySelector('.smooth-dnd-container')
    }
  };

  // @AnalyzeTime(0)
  handleTabClick = (event: any) => {
    const { viewId, setCurrentDashboard } = this.props

    setCurrentDashboard(viewId, event.target.id)
  };

  // @AnalyzeTime(0)
  handleTabMouseDown = (event: any) => {
    if (event.button === 1) {
      this.setState({
        middleMouseDownTab: event.target.id,
      })
    }
  };

  // @AnalyzeTime(0)
  handleTabMouseUp = (event: any) => {
    const { isEditModeOn } = this.props

    if (isEditModeOn && event.button === 1 && event.target.id === this.state.middleMouseDownTab) {
      this.handleDeleteDashboard(event)
    }
  };

  // @AnalyzeTime(0)
  handleDeleteDashboard = (event: any) => {
    const { showDeleteDashboardDialog, viewId } = this.props

    showDeleteDashboardDialog(viewId, event.target.id)
  };

  // @AnalyzeTime(0)
  handleSplitHorizontal = () => {
    const { viewId, splitView } = this.props

    splitView(viewId, 'vertical')
  };

  // @AnalyzeTime(0)
  handleSplitVertical = () => {
    const { viewId, splitView } = this.props

    splitView(viewId, 'horizontal')
  };

  // @AnalyzeTime(0)
  handleDeleteSplit = () => {
    const { viewId, deleteSplitView } = this.props

    deleteSplitView(viewId)
  };

  // @AnalyzeTime(0)
  handleMouseDown = (event: any) => {
    if (!this.tabWrapperRef) {
      return
    }

    const offset = event.pageX - this.tabWrapperRef.getBoundingClientRect().left
    const containerWidth = this.tabWrapperRef.getBoundingClientRect().width

    if (event.button === 0) {
      if (offset <= 20) {
        this.handleWheel({
          target: this.tabWrapperRef,
          deltaY: -156,
        })
      }

      const { isEditModeOn } = this.props
      const marginA = isEditModeOn ? 57 : 21
      const marginB = isEditModeOn ? 37 : 0

      if (offset >= containerWidth - marginA && offset < containerWidth - marginB) {
        this.handleWheel({
          target: this.tabWrapperRef,
          deltaY: 156,
        })
      }
    }
  };

  // @AnalyzeTime(0)
  handleDrop = (data: any) => {
    const { viewId, changeTabs } = this.props
    const { addedIndex, removedIndex, payload } = data

    this.tabWrapperRef?.classList.remove('drag_over')

    if (addedIndex !== removedIndex) {
      changeTabs(addedIndex, removedIndex, viewId, payload.key, payload.data)
    }
  };

  // @AnalyzeTime(0)
  handleDragStart = () => {
    if (!document) {
      // eslint-disable-next-line no-console
      return console.error('document is null or undefined')
    }

    document.querySelectorAll('.drag_container .smooth-dnd-container').forEach(container => {
      container.classList.add('drag_over')
    })
  };

  // @AnalyzeTime(0)
  handleDragEnd = () => {
    if (!document) {
      // eslint-disable-next-line no-console
      return console.error('document is null or undefined')
    }

    document.querySelectorAll('.drag_container .smooth-dnd-container').forEach(container => {
      container.classList.remove('drag_over')
    })
  };

  // handleContainerMouseDown = (event: any) => { // TODO: Remove?
  //   const { isEditModeOn } = this.props

  //   if (!isEditModeOn) {
  //     event.stopPropagation()

  //     return false
  //   }
  // };

  // @AnalyzeTime(0)
  handleOpenCompactMenu = () => {
    this.setState({
      compactMenu: true,
    })
  };

  // @AnalyzeTime(0)
  handleCloseCompactMenu = () => {
    this.setState({
      compactMenu: false,
    })
  };

  // @AnalyzeTime(0)
  getChildPayload = (index: number) => {
    const { viewsObject, viewId } = this.props
    const currentDashboardKeys = Object.keys(viewsObject[viewId].dashboards)

    return {
      key: currentDashboardKeys[index],
      data: viewsObject[viewId].dashboards[currentDashboardKeys[index]],
    }
  };

  @AnalyzeTime(0)
  render () {
    const { viewsObject, viewId, currentDashboard, isEditModeOn, size, t, appState, featureFlags } = this.props
    const { compactMenu } = this.state

    const dashboards = (viewsObject[viewId] || {}).dashboards || {}
    const currentDashboardKeys = Object.keys(dashboards)
    const isSplit = viewId.includes('split')
    const compactMode = size.width <= 345
    const canAddNewTab = (appState !== 1) || (appState === 1 && FeatureFlags.canAddParameterDashboardTab(featureFlags))

    return (
      <div>
        <TabsWrapper
          split={isSplit}
          onResize={this.handleResize}
          onMouseDown={this.handleMouseDown}
          ref={this.handleTabWrapperRef}
          onWheel={this.handleWheel}
          data-wheel
          edit={isEditModeOn}
          className='drag_container'
          compact={compactMode}
        >
          <Container
            orientation='horizontal'
            groupName='tabs'
            onDrop={this.handleDrop}
            getChildPayload={this.getChildPayload}
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
            dragClass='dragging'
            // onMouseDown={this.handleContainerMouseDown} TODO: test this, this props doesnt exist
            dragHandleSelector='.tab_drag_handle'
          >
            {
              currentDashboardKeys.map((dashboardId, index) =>
                <Tab
                  key={index}
                  active={dashboardId === currentDashboard[viewId]}
                  onClick={this.handleTabClick}
                  onMouseDown={this.handleTabMouseDown}
                  onMouseUp={this.handleTabMouseUp}
                  id={dashboardId}
                  title={dashboards[dashboardId].name}
                  payload={dashboardId}
                  className={currentDashboardKeys.length > 1 && isEditModeOn ? 'tab_drag_handle' : ''}
                  compact={compactMode}
                >
                  <span>{dashboards[dashboardId].name}</span>
                  {
                    isEditModeOn &&
                      <i
                        className='pe-7s-close pe-va'
                        title={t('tabs.delete')}
                        onClick={this.handleDeleteDashboard}
                        id={dashboardId}
                      />
                  }
                </Tab>)
            }
            {
              isEditModeOn && canAddNewTab && size.width > 345 &&
                <AddDashboard onClick={this.handleAddDashboard} title={t('tabs.new')}>
                  <i className='pe-7s-plus pe-va' />
                </AddDashboard>
            }
          </Container>
        </TabsWrapper>
        {
          isEditModeOn && size.width > 345 &&
            <ViewIconsWrapper isSplit={isSplit}>
              {
                isSplit &&
                  <ViewIcon onClick={this.handleDeleteSplit} title={t('tabs.merge')}>
                    <svg width='33px' height='33px'>
                      <use xlinkHref='icons/dashboardIcons.svg#merge' />
                    </svg>
                  </ViewIcon>
              }
              <ViewIcon onClick={this.handleSplitVertical} title={t('tabs.splitVertical')}>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitV' />
                </svg>
              </ViewIcon>
              <ViewIcon onClick={this.handleSplitHorizontal} title={t('tabs.splitHorizontal')}>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitH' />
                </svg>
              </ViewIcon>
            </ViewIconsWrapper>
        }
        {
          isEditModeOn && compactMode &&
            <ViewIconsWrapper compact>
              <ViewIcon onMouseEnter={this.handleOpenCompactMenu} onMouseLeave={this.handleCloseCompactMenu} compact>
                <i className='pe-7s-config pe-va' />
              </ViewIcon>
            </ViewIconsWrapper>
        }
        {
          isEditModeOn && compactMode && compactMenu &&
            <CompactViewIcons
              isSplit={isSplit} onMouseEnter={this.handleOpenCompactMenu} onMouseLeave={this.handleCloseCompactMenu}
            >
              <AddDashboard onClick={this.handleAddDashboard} title={t('tabs.new')} compact>
                <i className='pe-7s-plus pe-va' />
              </AddDashboard>
              {
                isSplit &&
                  <ViewIcon onClick={this.handleDeleteSplit} title={t('tabs.merge')} compact>
                    <svg width='33px' height='33px'>
                      <use xlinkHref='icons/dashboardIcons.svg#merge' />
                    </svg>
                  </ViewIcon>
              }
              <ViewIcon onClick={this.handleSplitVertical} title={t('tabs.splitVertical')} compact>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitV' />
                </svg>
              </ViewIcon>
              <ViewIcon onClick={this.handleSplitHorizontal} title={t('tabs.splitHorizontal')} compact>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitH' />
                </svg>
              </ViewIcon>
            </CompactViewIcons>
        }
      </div>
    )
  }
}

export default withNamespaces('visualization')(sizeMe()(connector(Tabs as any) as any) as any) as any
