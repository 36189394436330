import React from 'react'
import FeatureFlags from 'react/FeatureFlags'
import { AppState, ApplicationMainActionsEnum } from 'store/application/main/consts'
import { I } from '../../ApplicationActions'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

export default class Menu {
  static evaluateActiveKey (activeKey: string, caster: Caster) {
    if (!activeKey) {
      return true
    }

    if (!caster || (activeKey && !caster.XMLDataBase)) {
      return false
    }

    // https://stackoverflow.com/questions/18473326/javascript-break-sentence-by-words
    // to get all words
    const keys = activeKey.match(/\b(\w+)\b/g) || []

    for (const key of keys) {
      const value = ![ '0', undefined ].includes((caster.XMLDataBase || {})[`_${key}`])

      // TODO: review security, it should be secure enough, if word is not in keys, then everything should be false or
      // true, no room for malicious functions or code
      // replace all occurrences of key with value ( match whole word only )
      activeKey = activeKey.replace(new RegExp(`\\b${key}\\b`, 'g'), `${value}`)
    }

    let result

    try {
      // eslint-disable-next-line no-eval
      result = eval(activeKey)
    }
    catch (error) {
      console.log('Evaluation Error:', activeKey, error)

      return false
    }

    return result
  }

  @AnalyzeTime(0)
  static getDefaultFileMenu (menuEntries: any) {
    return [
      {
        label: (t: Translation) => t('openProject'),
        shortcut: 'Ctrl+O',
        action: 'openProjectDialog',
        visible: (permissions: Record<string, boolean>, state: any) => (
          !state.util.isConsistencyCheck &&
          FeatureFlags.canViewOpenProjectMenuOption(permissions)
        ),
        disabled: ({ application, util }: DefaultState) => (
          !FeatureFlags.canUseOpenProjectMenuOption(application.main.authenticationData.featureFlags) ||
          util.isConsistencyCheck
        ),
      },
      {
        label: (t: Translation) => t('newProject'),
        shortcut: 'Ctrl+Shift+O',
        action: 'openNewProjectDialog',
        visible: (permissions: Record<string, boolean>, state: any) => (
          FeatureFlags.canViewCreateProjectMenuOption(permissions) &&
          !state.util.isConsistencyCheck
        ),
        disabled: (state: any) => {
          const permissions = FeatureFlags.getRealFeatureFlags(state)

          return (
            !FeatureFlags.isLoggedIn(state) ||
            state.util.isConsistencyCheck ||
            !FeatureFlags.canUseCreateProjectMenuOption(permissions)
          )
        },
      },
      {
        visible: (permissions: Record<string, boolean>, state: any) => (
          FeatureFlags.canViewOpenProjectMenuOption(permissions) ||
          FeatureFlags.canViewCreateProjectMenuOption(permissions) ||
          !state.util.isConsistencyCheck
        ),
        type: 'separator',
      },
      {
        label: (t: Translation) => t('uploadCaster'),
        action: 'uploadCaster',
        visible: (permissions: Record<string, boolean>) => {
          return (
            FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadCasterXMLMenuOption(permissions)
          )
        },
        disabled: (state: DefaultState) => (
          !FeatureFlags.canUseUploadCasterXMLMenuOption(FeatureFlags.getRealFeatureFlags(state))
        ),
      },
      {
        label: (t: Translation) => t('uploadCatalog'),
        action: 'uploadCatalog',
        visible: (permissions: Record<string, boolean>) => {
          return (
            FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadNozzleCatalogMenuOption(permissions)
          )
        },
        disabled: (state: DefaultState) => (
          !FeatureFlags.canUseUploadNozzleCatalogMenuOption(FeatureFlags.getRealFeatureFlags(state))
        ),
      },
      ...menuEntries,
      {
        type: 'separator',
      },
      {
        label: (t: Translation) => t('loadCaster'),
        action: 'loadCaster',
        visible: (permissions: Record<string, boolean>) => {
          return (
            FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewLoadCasterMenuOption(permissions)
          )
        },
        disabled: ({ loading, application }: DefaultState) => {
          return (
            !application.main.currentSimulationCase.currentCasterRoot ||
            loading.uploadLoading.CasterDataIsLoading ||
            !FeatureFlags.canUseLoadCasterMenuOption(application.main.authenticationData.featureFlags)
          )
        },
      },
      {
        type: 'separator',
        visible: (permissions: Record<string, boolean>) => {
          return FeatureFlags.usesSlimVersion(permissions)
        },
      },
      {
        label: (t: Translation) => t('selectDashboard'),
        action: 'openSelectDashboard',
        visible: (permissions: Record<string, boolean>) => {
          return FeatureFlags.canViewSelectCasterDashboardMenuOption(permissions)
        },
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseSelectCasterDashboardMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        label: (t: Translation) => t('consistencyCheck'),
        action: 'sendCasterToConsistencyCheck',
        visible: (permissions: Record<string, boolean>, state: any) => {
          return FeatureFlags.canViewSendToConsistencyCheckOption(permissions) &&
            Object.keys(state.CCElement || {}).length > 0
        },
        disabled: ({ data: { rootData }, application }: DefaultState) => {
          return (
            !rootData.Caster ||
            !FeatureFlags.canUseSendToConsistencyCheckOption(application.main.authenticationData.featureFlags)
          )
        },
      },
    ]
  }

  @AnalyzeTime(0)
  static getDefaultEditMenu (menuEntries: any) {
    return [
      {
        label: (t: Translation) => t('manageDynamicDataSourcesDialog'),
        action: 'openManageDynamicDataSourcesDialog',
        visible: (permissions: Record<string, boolean>, state: DefaultState) => {
          // TODO: add specific FF
          return FeatureFlags.canViewManageDynamicDataMenuOption(permissions) &&
            state.application.main.appState === AppState.Caster
        },
        disabled: ({ application, visualization: { visualizationMetaInformation } }: DefaultState) => {
          const { config } = (visualizationMetaInformation || {})[AppState.Caster] || {}
          const featureFlags = application.main.authenticationData.featureFlags
          const appState = application.main.appState

          return (
            !config ||
            !FeatureFlags.canUseManageDynamicDataMenuOption(featureFlags, visualizationMetaInformation, appState)
          )
        },
      },
      {
        label: (t: Translation) => t('configurePlots'),
        action: ApplicationMainActionsEnum.configurePlots,
        disabled: ({ visualization, application }: DefaultState) => (
          // TODO: if networkStatus works again use: visualization.networkStatus !== 'connected' ||
          Object.keys(visualization.plotConfigs || {}).length === 0 ||
          !visualization.isEditModeOn ||
          !FeatureFlags.canUseConfigurePlotsMenuOption(
            application.main.authenticationData.featureFlags,
            visualization.visualizationMetaInformation,
            application.main.appState,
          )
        ),
        visible: (permissions: any) => {
          return FeatureFlags.canViewConfigurePlotsMenuOption(permissions)
        },
      },
      ...menuEntries,
    ]
  }

  static getViewMenu () {
    return [
      {
        label: (t: Translation) => t('toggleFullScreen'),
        shortcut: 'F11',
        doNotPreventDefault: false,
        action: 'toggleFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewToggleFullScreenMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseToggleFullScreenMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      {
        label: (t: Translation) => t('exitFullScreen'),
        shortcut: 'ESC',
        action: 'exitFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewExitFullScreenMenuOption(permissions),
        disabled: ({ application }: DefaultState) => (
          !FeatureFlags.canUseExitFullScreenMenuOption(application.main.authenticationData.featureFlags)
        ),
      },
      // {
      //   label: (t: Translation, { application }: any) =>
      //     t('theme', { type: application.main.darkTheme ? 'Light' : 'Dark' }),
      //   shortcut: 'F6',
      //   action: ApplicationMainActionsEnum.ACTION_SET_THEME,
      // },
      {
        label: (t: Translation) => t('kill3D'),
        action: 'kill3D',
        visible: (permissions: any) => FeatureFlags.canViewRecreate3DMenuOption(permissions),
        disabled: ({ data, application: { main } }: DefaultState) => (
          Object.keys(data.rootData || {}).length === 0 ||
          !FeatureFlags.canUseRecreate3DMenuOption(main.authenticationData.featureFlags)),
      },
    ]
  }

  @AnalyzeTime(0)
  static getRunMenu (executableDefinitions: ExecutableDefinition[], permissions?: any) {
    return {
      disabled: () => !(permissions || {}).Menu_Modules_Use,
      visible: (_permissions: Record<string, boolean>, state: DefaultState) => !state.util.isConsistencyCheck,
      label: (t: Translation) => t('modules'),
      submenu: executableDefinitions.length
        ? executableDefinitions.map((definition) => ({
          label: () => definition.name,
          action: definition.isDownloadXMLModule ? 'openDownloadXMLDialog' : 'openExecutableDialog',
          params: () => definition,
          disabled: ({ application: { main: { currentSimulationCase } }, data: { rootData } }: DefaultState) =>
            !currentSimulationCase ||
            !currentSimulationCase._id ||
            !this.evaluateActiveKey(definition.activeKey || '', rootData.Caster),
        }))
        : [ { label: (t: Translation) => t('noExecutables'), disabled: () => true } ],
    }
  }

  static getCasterMenu (featureFlags: any) {
    return [
      {
        label: (t: Translation) => t('file'),
        visible: (permissions: any) => {
          return FeatureFlags.canViewCaster(permissions)
        },
        submenu: Menu.getDefaultFileMenu([
          {
            label: (t: Translation) => t('download'),
            shortcut: 'Ctrl+S',
            action: 'saveCasterXML',
            visible: (permissions: any) => {
              return FeatureFlags.canViewDownloadCasterXMLMenuOption(permissions)
            },
            params: ({ application: { main: { currentSimulationCase } } }: DefaultState) => currentSimulationCase._id,
            disabled: ({ application: { main: { currentSimulationCase } } }: DefaultState) => (
              !currentSimulationCase ||
              !currentSimulationCase._id ||
              !FeatureFlags.canUseDownloadCasterXMLMenuOption(featureFlags)
            ),
          },
        ]),
      },
      {
        label: (t: Translation) => t('edit'),
        submenu: Menu.getDefaultEditMenu([]),
      },
      {
        label: () => 'View', // TODO: use translation
        submenu: Menu.getViewMenu(),
      },
    ]
  }

  static dashboardMenu = [
    {
      label: (t: Translation) => t('file'),
      visible: (permissions: any) => {
        return true
      },
      submenu: Menu.getDefaultFileMenu([
        // {
        //   label: (t: Translation) => t('selectDataSource'),
        //   shortcut: 'Ctrl+D',
        //   action: Const.selectDataSource,
        // },
        { // this is here to bind the shortcut and prevent default
          // TODO: is this necessary?
          label: (t: Translation) => '',
          visible: () => false,
          shortcut: 'Ctrl+S',
          action: 'saveVisChanges',
        },
      ]),
    },
    {
      label: (t: Translation) => t('edit'),
      submenu: Menu.getDefaultEditMenu([
        {
          label: (t: Translation, state: DefaultState) =>
            t('editMode', { state: state.visualization.isEditModeOn ? 'On' : 'Off' }),
          shortcut: 'F7',
          action: ApplicationMainActionsEnum.editMode,
          visible: (permissions: any) => {
            return FeatureFlags.canViewToggleEditMode(permissions)
          },
          disabled: ({ application }: DefaultState) => {
            return !FeatureFlags.canUseToggleEditMode(application.main.authenticationData.featureFlags)
          },
        },
        {
          label: (t: Translation) => t('exportPlots'),
          action: ApplicationMainActionsEnum.exportPlots,
          visible: (permissions: any) => {
            return FeatureFlags.canViewExportPlots(permissions)
          },
          disabled: ({ visualization, application }: DefaultState) => (
            // TODO: if networkStatus works again use: visualization.networkStatus !== 'connected' ||
            (
              !FeatureFlags.canUseExportPlots(application.main.authenticationData.featureFlags) ||
              Object.keys(visualization.plotConfigs || {}).length === 0
            )
          ),
        },
        {
          label: (t: Translation) => t('mapCommands'),
          action: ApplicationMainActionsEnum.mapCommands,
          // TODO: Store commands in redux
          visible: (permissions: any) => {
            return FeatureFlags.canViewCommandFileMapping(permissions)
          },
          disabled: ({ application }: DefaultState) => {
            return !FeatureFlags.canUseCommandFileMapping(application.main.authenticationData.featureFlags)
          },
        },
      ]),
    },
  ]

  static applicationMenu = [
    {
      label: 'pe-7s-help1',
      submenu: [
        {
          label: (t: Translation) => t('about'),
          action: 'openAboutDialog',
        },
      ],
    },
    {
      // eslint-disable-next-line react/display-name
      label: (t: Translation, state: DefaultState) => {
        return (
          (state.application.main.authenticationData || {}).name
            ? ((state.application.main.authenticationData || {}).name || ' ')[0].toUpperCase()
            : <I className='pe-7s-user' />
        )
      },
      submenu: [
        {
          label: (t: Translation, state: DefaultState) =>
            (state.application.main.authenticationData || {}).name || 'offline',
        },
        {
          label: (t: Translation) => t('settings'),
          disabled: () => true,
        },
        {
          type: 'separator',
          visible: (_permission: any, state: DefaultState) =>
            ((state.application.main.authenticationData || {}).name || '').length > 0,
        },
        {
          label: (t: Translation) => t('logout'),
          visible: (_permission: any, state: DefaultState) =>
            ((state.application.main.authenticationData || {}).name || '').length > 0,
          action: 'ACTION_RESET_STORE',
        },
      ],
    },
  ]

  @AnalyzeTime(0)
  static menu (appState: number, executableDefinitions: ExecutableDefinition[], permissions?: Record<string, boolean>) {
    const showRunMenu = permissions?.Menu_Modules_View

    return appState !== AppState.Caster
      ? Menu.dashboardMenu
      : showRunMenu
        ? [ ...Menu.getCasterMenu(permissions), Menu.getRunMenu(executableDefinitions || [], permissions) ]
        : Menu.getCasterMenu(permissions)
  }

  @AnalyzeTime(0)
  static getApplicationMenu () {
    return Menu.applicationMenu
  }
}
