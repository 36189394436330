import { AnalyzeTime } from 'Util'

export default class CalculationUtil {
  @AnalyzeTime(0)
  static calcPlHeight (Passline: Passline) {
    const { section } = Passline

    return Number(section[section.length - 1]._passln_coord) || 0
  }

  @AnalyzeTime(0)
  static calcMaxPosition (min: number, top: number, bottom: number, height: number, viewportHeight: number) {
    return (min + (viewportHeight - top - bottom) - height)
  }

  @AnalyzeTime(0)
  static calcButtonYpos (min: number, max: number, scrollValue: number) {
    return (scrollValue * (max - min) + min)
  }

  @AnalyzeTime(0)
  static calcUpdatedButtonPos (
    posX: number,
    posY: number,
    bWidth: number,
    bHeight: number,
    dirX: number,
    dirY: number,
    x: number,
    y: number,
  ) {
    return {
      x: posX * dirX + (bWidth / 2 + x) * -dirX,
      y: posY * dirY + (bHeight / 2 + y) * -dirY,
      z: 0,
    }
  }

  @AnalyzeTime(0)
  static calcNewScrollValue (min: number, max: number, y: number) {
    return ((y - min) / (max - min))
  }

  @AnalyzeTime(0)
  static calcTargetHeight (newScrollValue: number, plHeight: number) {
    return (((newScrollValue * -plHeight) + plHeight) / 1000)
  }

  @AnalyzeTime(0)
  static getNearestSegmentToSectionPlane (segments: SegmentHash, plHeight: number) {
    let min = Infinity
    let minId = 1

    Object.values(segments).forEach((segment: any) => {
      // eslint-disable-next-line camelcase
      const { _passln_coord } = segment
      const coord = Number(_passln_coord)

      if (Math.abs(coord - plHeight) < min) {
        min = Math.abs(coord - plHeight)
        minId = segment._id
      }
    })

    const selectedSegment: any = segments[minId]

    return `SegmentGroup:${selectedSegment['#parent'].id}/Segment:${minId}`
  }
}
