import cloneDeep from 'clone-deep'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { AnalyzeTime } from 'Util'

export default class MatrixUtil {
  @AnalyzeTime(0)
  static updateSimulationCaseName (
    currentProject: Project,
    setCurrentProject: (project: Project) => void,
    name: string,
    simulationCaseId: string,
    selectedCellData: any,
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ): void {
    const project = cloneDeep(currentProject)
    const simulationCase = project.simulationCases.find(simulationCase => simulationCase._id === simulationCaseId)

    ApiClient
      .patch(
        `${Network.URI}/simulation_case/${simulationCaseId || ''}`,
        { data: { name, description: simulationCase?.description || '', selectedCellData } },
      )
      .then(() => {
        if (selectedCellData && selectedCellData.length) {
          // eslint-disable-next-line react/prop-types
          const ids = selectedCellData.map((data: any) => data.simulationCaseId)

          project.simulationCases.forEach(simulationCase => {
            if (ids.includes(simulationCase._id)) {
              simulationCase.name = name
            }
          })
        }
        else {
          if (simulationCase) {
            simulationCase.name = name
          }
        }

        setCurrentProject(project)

        successCallback()
      })
      .catch((response) => {
        const { error } = response

        errorCallback(error)
      })
  }

  @AnalyzeTime(0)
  static async updateSimulationCaseNames (
    simulationCaseData: Array<any>,
    currentProject: Project,
    currentSimulationCase: SimulationCase,
    setCurrentProject: (project: Project) => void,
    setCurrentSimulationCase: (simCase: SimulationCase) => void,
  ): Promise<any> {
    const project = cloneDeep(currentProject)
    const currentSimulationCaseClone = cloneDeep(currentSimulationCase)

    await ApiClient.patch(`${Network.URI}/simulation_case/names`, { data: { simulationCaseData } })

    for (const { simulationCaseId, name } of simulationCaseData) {
      const simulationCase = project.simulationCases.find(simulationCase => simulationCase._id === simulationCaseId)

      if (simulationCase) {
        simulationCase.name = name
      }

      if (currentSimulationCaseClone._id === simulationCaseId) {
        currentSimulationCaseClone.name = name
      }
    }

    setCurrentProject(project)

    if (currentSimulationCaseClone.name !== currentSimulationCase.name) {
      setCurrentSimulationCase(currentSimulationCaseClone)
    }

    return project
  }
}
