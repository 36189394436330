import React, { Component } from 'react'
import { AnalyzeTime } from 'Util'

import { InputForm } from '../InputStyles'

type Props = {
  placeholder: string,
  massValue: string | undefined,
  name: string,
  isDisabled?: boolean,
  onChange: (e: any) => void,
  inputRef: any
  style?: any
};

export default class InputField extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { placeholder, massValue, isDisabled, onChange, name, inputRef, style } = this.props

    return (
      <InputForm
        placeholder={placeholder}
        value={massValue}
        title={massValue}
        onChange={onChange}
        disabled={isDisabled}
        name={name}
        ref={inputRef}
        style={style}
      />
    )
  }
}
