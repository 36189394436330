import Util from '../util/Util'

import { VisualizationActionsEnum as VisActionsEnum } from '../consts'
import { PlotConfig, TileConfig, ViewsObject, VisualizationState } from 'types/visualization'

const dashboardReducers = {
  [VisActionsEnum.ACTION_UPDATE_DASHBOARD_REQUEST]: (state: VisualizationState) => state,

  [VisActionsEnum.ACTION_UPDATE_DASHBOARD_SUCCESS]:
  (state: VisualizationState, { result: { currentDashboard, viewsObject } }: any) => ({
    ...state,
    currentDashboard,
    viewsObject,
  }),
  [VisActionsEnum.ACTION_UPDATE_COMPARISON_CASTERS_SUCCESS]:
  (state: VisualizationState, { result: { viewsObject } }: any) => ({
    ...state,
    viewsObject,
  }),
  [VisActionsEnum.ACTION_UPDATE_DASHBOARD_ERROR]: (state: VisualizationState) => state,

  [VisActionsEnum.ACTION_SET_CURRENT_DASHBOARD]: (state: VisualizationState, action: any) => ({
    ...state,
    currentDashboard: {
      ...state.currentDashboard,
      [action.key]: action.dashboardId,
    },
  }),

  [VisActionsEnum.ACTION_DELETE_DASHBOARD]: (state: VisualizationState, action: any) => {
    const newState: any = {
      ...state,
      viewsObject: {
        ...state.viewsObject,
        [action.key]: {
          ...state.viewsObject[action.key],
          dashboards: {
            ...state.viewsObject[action.key].dashboards,
          },
        },
      },
    }

    delete newState.viewsObject[action.key].dashboards[action.dashboardId]

    const boards = Object.keys(newState.viewsObject[action.key].dashboards)

    if (newState.currentDashboard[action.key] === action.dashboardId && boards.length) {
      newState.currentDashboard = {
        ...newState.currentDashboard,
        [action.key]: boards[0],
      }
    }

    return newState
  },

  [VisActionsEnum.ACTION_CHANGE_TABS]:
  (state: VisualizationState, { addedIndex, removedIndex, viewId, dashboardId, dashboardData }: {
    addedIndex: number,
    removedIndex: number,
    viewId: string,
    dashboardId: string,
    dashboardData: any,
  }) => {
    const dashboardKeys = Object.keys(state.viewsObject[viewId].dashboards)
    const startLength = dashboardKeys.length

    if (removedIndex !== null) {
      dashboardKeys.splice(removedIndex, 1)
    }

    if (addedIndex !== null) {
      dashboardKeys.splice(addedIndex, 0, dashboardId)
    }

    const endLength = dashboardKeys.length

    const dashboards = dashboardKeys.reduce((boards: any, key) => {
      if (key === dashboardId) {
        boards[key] = { ...dashboardData }
      }
      else {
        boards[key] = { ...state.viewsObject[viewId].dashboards[key] }
      }

      return boards
    }, {})

    let currentDashboard = state.currentDashboard[viewId]

    if (endLength < startLength) {
      currentDashboard = dashboardKeys[removedIndex - 1] || dashboardKeys[0]
    }
    else if (endLength > startLength) {
      currentDashboard = dashboardId
    }

    return {
      ...state,
      viewsObject: {
        ...state.viewsObject,
        [viewId]: {
          ...state.viewsObject[viewId],
          dashboards,
        },
      },
      currentDashboard: {
        ...state.currentDashboard,
        [viewId]: currentDashboard,
      },
    }
  },

  [VisActionsEnum.ACTION_SET_CONFIG]:
  (state: VisualizationState, { viewsObject, tileConfigs, plotConfigs }: {
    viewsObject: ViewsObject,
    plotConfigs:Record<string, PlotConfig>
    tileConfigs:Record<string, TileConfig>
  }) => {
    const currentDashboard = Util.getCurrentDashboards(viewsObject, { ...state.currentDashboard }) || {}

    // TODO: Handle error message
    if (currentDashboard.error) {
      return ({
        ...state,
      })
    }

    return ({
      ...state,
      viewsObject,
      tileConfigs,
      // TODO: merge by group and key not just the objects...!?
      plotConfigs: {
        ...plotConfigs,
      },
      currentDashboard,
    })
  },
}

export default dashboardReducers
