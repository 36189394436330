export enum FilterActionsEnum {
  ACTION_SET_TERM = 'filter/ACTION_SET_TERM',
  ACTION_SET_TARGET = 'filter/ACTION_SET_TARGET',
  ACTION_RESET_TARGET = 'filter/ACTION_RESET_TARGET',
  ACTION_SET_FILTER_VALUES = 'filter/ACTION_SET_FILTER_VALUES',
  ACTION_SET_IS_TERM_DISABLED = 'filter/ACTION_SET_IS_TERM_DISABLED',
  ACTION_SET_ACTIVE_FILTER_CONTROL_INDEX = 'filter/ACTION_SET_ACTIVE_FILTER_CONTROL_INDEX',
  ACTION_SET_CURRENT_FILTER_CONTROL_VALUE = 'filter/ACTION_SET_CURRENT_FILTER_CONTROL_VALUE',
  ACTION_GET_FILTER_CONTROL_DEFINITIONS_REQUEST = 'filter/ACTION_GET_FILTER_CONTROL_DEFINITIONS_REQUEST',
  ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS = 'filter/ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS',
  ACTION_GET_FILTER_CONTROL_DEFINITIONS_ERROR = 'filter/ACTION_GET_FILTER_CONTROL_DEFINITIONS_ERROR',
}

export const initialState = {
  termDisabled: false,
  term: '',
  target: '',
  filterElement: {} as any,
  filterControlDefinitions: [],
  filterControlVariables: [],
}
