import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'

import LogicUtil from '../../logic/Util'
import Button from './Button'
import SecondaryButton from './SecondaryButton'

import DataActions from '../../store/data/actions'
import Util from '../../three/logic/Util'

import { DialogWrapper, CardHeader, CardBody, CardContainer, Buttons } from './Dialog'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const ListElement = styled.div`
  margin: 2px 0;
`

const connector = connect(({ data, Roller, RollerBearing, RollerBody }: DefaultState) => (
  {
    RollerBearing,
    RollerBody,
    Roller,
    pendingDeleteList: data.pendingDeleteList,
    hidePaths: data.hidePaths,
    dirtyDeletePaths: data.dirtyDeletePaths,
  }), {
  addPendingDeleteList: DataActions.addPendingDeleteList,
  addDirtyPath: DataActions.addDirtyPath,
  addDirtyDeletePath: DataActions.addDirtyDeletePath,
  saveElement: DataActions.saveElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}
export class DeleteDialog extends Component<Props> {
  // @AnalyzeTime(0)
  handleDeletePendingElements = () => {
    const { saveElement, pendingDeleteList, addPendingDeleteList } = this.props

    addPendingDeleteList()
    saveElement(pendingDeleteList, [], 'delete') // TODO: needs to send elementType too
  };

  // @AnalyzeTime(0)
  handleDeletePendingList = () => {
    const { addPendingDeleteList, pendingDeleteList, addDirtyPath, addDirtyDeletePath } = this.props
    const allChildren = [ ...pendingDeleteList ]

    pendingDeleteList.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'Roller') {
        const element = LogicUtil.getElement(path, {
          Roller: this.props.Roller,
        } as any)

        allChildren.push(...LogicUtil.RollerChildren(element, path))
      }

      if (type === 'RollerBody' || type === 'RollerBearing') {
        const parentPath = Util.getParentInfo(path).path
        const parentElement = LogicUtil.getElement(parentPath, {
          RollerBody: this.props.RollerBody,
          RollerBearing: this.props.RollerBearing,
          Roller: this.props.Roller,
        } as any)

        const rollerChildren = LogicUtil.RollerChildren(parentElement, parentPath)

        if (rollerChildren.length === 1 && rollerChildren[0] === path) { // if this element is the only roller child
          allChildren.push(parentPath) // because parent isn't in pending delete list, only in dirtyDeleteList
        }
      }
    })

    addDirtyPath(allChildren)
    addDirtyDeletePath(allChildren) // if path is already in array it removes it from the array
    addPendingDeleteList()
  };

  @AnalyzeTime(0)
  render () {
    const { pendingDeleteList, t } = this.props

    return (
      <DialogWrapper>
        <CardHeader>
          <h3>{t('specific.deleteDialog.title')}</h3>
        </CardHeader>
        <CardContainer>
          <CardBody>
            {
              pendingDeleteList.map(path =>
                <ListElement key={path}>
                  {path}
                </ListElement>)
            }
          </CardBody>
        </CardContainer>
        <Buttons>
          <Button
            onClick={this.handleDeletePendingElements}
            value={t('specific.deleteDialog.button')}
            small
          />
          <SecondaryButton
            onClick={this.handleDeletePendingList}
            value={t('specific.deleteDialog.secondaryButton')}
            small
          />
        </Buttons>
      </DialogWrapper>
    )
  }
}

export default withNamespaces('caster')(connector(DeleteDialog as any)) as any
