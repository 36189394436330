/* eslint-disable camelcase */
import { PARENT } from '../../../react/context/AllInOne/consts'

import CalculateHelperFunctions from '../../../react/context/form/functions/CalculateHelperFunctions'
import Util from '../../../logic/Util'
import type { ElementsHashes } from 'types/state'

export function getNewIdForType (data: XMLData, type: string): number {
  const key = `_lastTypeId_${type}`
  const { XML_Generation_Parameters: parameters } = data.root

  ;(parameters as any)[key] = Number((parameters as any)[key] || '0') + 1

  return (parameters as any)[key]
}

export function getElementFromPath (path: string | string[], elementsHashes: ElementsHashes, copy?: boolean) {
  if (!elementsHashes) {
    return undefined
  }

  const pathString = path instanceof Array ? Util.getPathFromPathArray(path) : path

  return Util.getElement(pathString, elementsHashes, copy)
}

export function getDifferenceElement (paths: string[], editElements: any, elementsHashes: ElementsHashes) {
  let highestElement: any = {}
  let highestElementOriginal: any = { _passln_coord: Infinity }

  paths.forEach(path => {
    const element = getElementFromPath(path, elementsHashes, true)

    if (highestElementOriginal._passln_coord > element._passln_coord) {
      highestElementOriginal = element
      highestElement = { ...editElements[path] }
    }
  })

  const differenceElement: any = { ...highestElement }

  differenceElement._passln_coord -= highestElementOriginal._passln_coord
  differenceElement._width_coord -= highestElementOriginal._width_coord

  return differenceElement
}

export function getPathArrayFromPath (path: string) {
  return path.split('/').reduce((list: any, part) => (parts => ([
    ...list,
    parts[0],
    Number(parts[1]),
  ]))(part.split(':')), [])
}

export function getSegmentByPassln (data: any, passln: number) {
  const xmlData = { ...data }

  return xmlData.SegmentGroup
    .filter((SegmentGp: any) => Number(SegmentGp._passln_coord) <= Number(passln))
    .sort((a: any, b: any) => Number(b._passln_coord) - Number(a._passln_coord))[0]
}

export function getSegmentByRollerPassln (rollerList: Array<any>, passln: number) {
  const rollers = rollerList
    .filter(roller => roller._passln_coord > passln)
    .sort((a, b) => b._passln_coord - a._passln_coord)

  return rollers[rollers.length - 1]
}

export function getDefaultElement (element: any, elementType: string) {
  let newElement = { ...element[elementType] }

  switch (elementType) {
    case 'Roller':

      // eslint-disable camelcase
      newElement = {
        ...newElement,
        _passln_coord: newElement._passln_coord || 0,
        RollerBearing: [],
        RollerBody: [],
      }

      // eslint-disable-next-line camelcase
      const { _roll_width: rollerWidth, _diameter, _passln_coord } = newElement
      const RollerBody = {
        _width: rollerWidth,
        _width_start: (rollerWidth / 2) * -1,
        _name: 'DSCRollerBody',
        _diameter,
        _passln_coord,
      }

      const RollerBearing = [
        {
          _width: 100,
          _width_start: rollerWidth / 2,
          _name: '',
          _passln_coord,
        },
        {
          _width: 100,
          _width_start: -(rollerWidth / 2) - 100,
          _name: '',
          _passln_coord,
        },
      ]
      // eslint-enable camelcase

      newElement.RollerBody.push(RollerBody) // TODO: make sure new elements get new ids
      newElement.RollerBearing.push(...RollerBearing) // TODO: make sure new elements get new ids

      break
    default:
  }

  return newElement
}

export function getGapPositions (
  elementsHashes: ElementsHashes,
  element: any,
  targetArray: any[],
  offset: number,
  amount: number,
) {
  const gapPositions = []
  const segmentSide = (elementsHashes.Segment[targetArray[3]] || {})._FixedLooseSide || ''

  const rollerList = CalculateHelperFunctions
    .getRollerList(elementsHashes, targetArray[1], segmentSide, [])
    .sort((a, b) => a._passln_coord - b._passln_coord)

  const startCoord = Number(element._passln_coord)
  let list = []

  if (offset > 0) {
    list = rollerList.filter(val => val._passln_coord > startCoord)
  }
  else {
    list = rollerList.filter(val => val._passln_coord < startCoord).reverse()
  }

  for (let i = Math.abs(offset) - 1; i < list.length - 1; i += Math.abs(offset)) {
    if (offset > 0) {
      const a = Number(list[i]._passln_coord) + Number(list[i]._diameter) / 2
      const b = Number(list[i + 1]._passln_coord) - Number(list[i + 1]._diameter) / 2

      gapPositions.push(a + ((b - a) / 2))
    }
    else {
      const a = Number(list[i]._passln_coord) - Number(list[i]._diameter) / 2
      const b = Number(list[i + 1]._passln_coord) + Number(list[i + 1]._diameter) / 2

      gapPositions.push(a - ((a - b) / 2))
    }

    if (gapPositions.length === amount) {
      break
    }
  }

  return { gapPositions, list }
}

export function getPathByTypeAndId (type: CasterElementNames, id: number, elementsHashes: ElementsHashes) {
  const path = []
  let currentType: CasterElementNames | undefined | null = type
  let currentId = id

  do {
    path.push(`${currentType}:${currentId}`)
    currentId = (elementsHashes[currentType] || {})[currentId]
    currentType = PARENT[currentType]
  } while (currentType)

  return path.reverse().join('/')
}

export function getAddedObject (addedObject: any, xmlData: XMLData, elementType: CasterElementNames) {
  const object = addedObject

  object._numericId = getNewIdForType(xmlData, elementType)
  object._id = object._numericId

  return object
}

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case 'FixedSide': distance2passline = radius
      break
    case 'LooseSide': distance2passline = radius + thickness
      break
    case 'NarrowFaceLeft':
    case 'NarrowFaceRight': distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}

export function getAddedElementPathWithoutNewParent (
  parentPathArray: string[],
  elementType: CasterElementNames,
  counter: number,
) {
  const newParentPath = Util.getPathFromPathArray(parentPathArray)

  return `${newParentPath}/${elementType}:-${counter}`
}

export function getCoolingLoopRefIdMap (coolingLoopHash: Record<number, (CoolingLoop & Parent) | undefined>) {
  const coolingLoopMap: Record<string, CoolingLoop> = {}
  const coolingLoops = Object.values(coolingLoopHash)

  for (const coolingLoop of coolingLoops) {
    if (!coolingLoop || !coolingLoop._refId) {
      continue
    }

    coolingLoopMap[coolingLoop._refId] = coolingLoop
  }

  return coolingLoopMap
}
