import Util from '../../logic/Util'
import FilterHandler from '../../logic/FilterHandler'
import * as THREE from 'three'
import Mold from '../../objects/Mold'
import PasslineCurve from '../../objects/PasslineCurve'
import FeatureFlags from '../../../react/FeatureFlags'
import DrawHandlers from './DrawHandlers'
import UpdateTransformHandler from './UpdateTransformHandler'
import ConditionUtil from './ConditionUtil'
import CalculationUtil from './CalculationUtil'
import OrbitControls from '../../logic/OrbitControls'
import MainView from '.'
import type { Views } from 'three/ThreeBase'
import { AnalyzeTime } from 'Util'
import type { ElementsHashes } from 'types/state'
import type { PassedData } from 'react/Caster'

export default class MainUtil {
  @AnalyzeTime(0)
  static updateCasterData (view: MainView, data: PassedData) {
    if (!view.data || !view.data || !view.data.Caster || !view.views) {
      return
    }

    if (!data.dirtyDeletePaths.equals(view.deleteList)) {
      MainUtil.handleDeleteListUpdate(view, data)
    }

    const { Caster }: Root = view.data
    const { Mold: mold, Passline: pl } = Caster

    view.plHeight = CalculationUtil.plHeightCalculator(pl)

    const dirtyElements = view.dirtyList && view.dirtyList.length > 0

    if (!view.caster) {
      MainUtil.handleNoCaster(view, data, mold, Caster)
    }
    else if (dirtyElements || data.newCopiedElementsToDraw) {
      DrawHandlers.drawStrandGuide(view)

      if (view.className === 'MainView' && view.views.uiView && view.views.sectionView) {
        view.views.sectionView.updateMinAndMaxPasslineCoordinates()
      }

      if (dirtyElements && view.clearDirtyList) {
        view.clearDirtyList()
      }

      if (data.newCopiedElementsToDraw) {
        data.setNewCopiedElementsToDraw(false)
      }
    }

    if (view.additionalData !== data.additionalData) {
      MainUtil.updateAdditionalData(view, data)
    }

    if (!data.selectedDataArray.equals(view.selectedElements)) {
      MainUtil.handleNewSelection(view.selectedElements, data, view.phantomElementList, view.elementList)
    }

    MainUtil.handleElementsOrParentsPathDifference(view, data)

    if (view.dataChanged) {
      DrawHandlers.redrawSectionPlane(view, Caster)
    }

    view.selectedElements = data.selectedDataArray

    view.setTerm = data.setTerm

    if (data.term !== view.term || data.newCopiedElementsToDraw) {
      view.term = data.term
      MainUtil.handleFilter(
        data.term,
        view.elementsHashes.SegmentGroup,
        view.elementList,
        data.elementsHashes,
        view.views,
        data.rollerChildren === 1 && view.className === 'MainView',
        data.rootData.Caster.Mold.MoldFace,
      )
    }
  }

  @AnalyzeTime(0)
  static updateAdditionalData (view: MainView, data: PassedData) {
    view.additionalData = data.additionalData
    const { Caster } = view.data || {}

    if (!Caster) {
      return
    }

    view.passlineCurve?.setValues(Caster, view.clickableObjects, view.applyCurve)

    view.mold?.setValues(Caster, view.additionalData)
    view.passlineCurve?.drawStrand()

    view.views?.sectionView?.updateTransform(true)

    UpdateTransformHandler.updateTransformElements(view.elementList, data)
    Caster.Mold.MoldFace?.forEach((moldFace) => {
      for (const sensorPoint of moldFace.SensorPoint) {
        const sensorPointElement = view.elementList.SensorPoint[`Mold/SensorPoint:${sensorPoint._id}`]

        if (sensorPointElement) {
          sensorPointElement.updateTransform()
        }
      }
    })
    UpdateTransformHandler.updateTransformSegments(
      view.containerList.Segment,
      view.elementList.Segment,
    )

    if (!view.sectionDetail) {
      Util.sides.filter(side => side.indexOf('Narrow') === -1).forEach(name => {
        view.coordinate?.generateCoordinateAxes(Caster, name)
        view.coordinateStraight?.generateCoordinateAxes(Caster, name)
      })
    }
  }

  // this gets called twice 1 for main view 1 for section view so thats normal!
  @AnalyzeTime(0)
  static handleParentMismatch (view: MainView) {
    const pathsToRemove: string[] = []

    view.deleteList.forEach(path => {
      const { type, id } = Util.getElementInfo(path)
      const { id: parentId } = Util.getParentInfo(path)

      if (!(view.elementsHashes as any)[type] || !(view.elementsHashes as any)[type][id]) {
        pathsToRemove.push(path)
      }
      else if (view.elementList &&
        view.elementList[type] &&
        Number((view.elementsHashes as any)[type][id]['#parent'].id || '-1') !== parentId) {
        pathsToRemove.push(path)
        const element = view.elementList[type][path]
        const phantom = view.phantomElementList[type][path]

        if (element) {
          Object.values(element.objects).forEach((object: any) => {
            view.clickableObjects.splice(view.clickableObjects.findIndex(obj => obj.uuid === object.uuid), 1)

            if (object.geometry) {
              object.geometry.dispose()
            }

            element.container.remove(object)
          })

          Object.values(phantom.objects).forEach((object: any) => {
            if (object.geometry) {
              object.geometry.dispose()
            }

            phantom.container.remove(object)
          })

          delete view.elementList[type][path]
          delete view.phantomElementList[type][path]
        }
      }
    })

    if (pathsToRemove.length && view.removeDeletePaths) {
      view.removeDeletePaths(pathsToRemove)
    }
  }

  // Work around for roller body behavior
  // TODO: find another way
  static reloadFilteredElements (view?: MainView) {
    if (!view) {
      return
    }

    this.handleFilter(
      view.term,
      view.elementsHashes.SegmentGroup,
      view.elementList,
      view.elementsHashes,
      view.views,
      false,
      view.data?.Caster.Mold.MoldFace,
    )
  }

  @AnalyzeTime(0)
  static handleFilter (
    term: string | undefined,
    segmentGroupHash: SegmentGroupHash,
    elementList: any,
    elementsHashes: ElementsHashes,
    views: Views,
    skipRollerChildren: boolean,
    mold?: Record<number, MoldFace>,
  ) {
    let newTerm = term
    const lowerNewTerm = newTerm?.toLowerCase()

    // TODO: this should not happen outside of the FilterHandler!
    if (lowerNewTerm === 'r' || lowerNewTerm === 'roller') {
      newTerm = 'r/*'
    }

    const filteredElements = FilterHandler.getFilteredElements(elementsHashes, newTerm, skipRollerChildren, mold, true)

    FilterHandler.applyFilterToElements(
      segmentGroupHash,
      filteredElements,
      elementList,
      views,
      elementsHashes,
      skipRollerChildren,
    )
  }

  @AnalyzeTime(0)
  static handleNoCaster (view: MainView, data: any, mold: any, Caster: Caster) {
    view.caster = new THREE.Group()
    view.caster.name = 'Caster'

    view.passlineCurve = new PasslineCurve(view.caster)
    view.mold = new Mold(view.caster)

    // as long as they are not editable they can stay here
    view.passlineCurve.setValues(Caster, view.clickableObjects, view.applyCurve)
    view.mold.setValues(Caster, data.additionalData)
    view.passlineCurve.drawStrand()
    const moldFaces = new THREE.Group()

    moldFaces.name = 'moldFaces'
    ;(view as any).moldFaces = moldFaces
    Util.addOrReplace(view.caster, moldFaces)

    if (!view.sectionDetail) {
      DrawHandlers.redrawSectionPlane(view, Caster)
    }
    else {
      view.caster.rotation.y = Util.RAD180
      view.caster.position.x = -mold._thickness / 1000
    }

    view.phantoms = new THREE.Group()
    view.phantoms.name = 'Phantoms'
    view.phantoms.rotation.y = Util.RAD270
    DrawHandlers.drawStrandGuide(view, mold)
    DrawHandlers.drawCaster(view)

    Util.addOrReplace(view.scene, view.caster)
    Util.addOrReplace(view.caster, view.phantoms)

    // TODO: maybe wait for the section view to finish!?
    if (!view.sectionDetail && view.setLoadingStatus) {
      view.setLoadingStatus(false)
    }

    view.sceneReady = true
    view.isRedrawing = false
  }

  static handleSelectSegmentGroup (segmentGroupPath: string, elementList: any, isSelected: boolean) {
    const segmentGroup = elementList.SegmentGroup[segmentGroupPath]

    if (!segmentGroup) {
      // eslint-disable-next-line no-console
      console.log('segmentGroup not found', segmentGroupPath)

      return
    }

    const fixedSide = segmentGroup.container?.children?.find((child: any) => child.userData.side === 'FixedSide')

    if (!fixedSide) {
      // eslint-disable-next-line no-console
      console.log('segmentGroup fixedSide not found', segmentGroupPath)

      return
    }

    const { path } = fixedSide.userData

    elementList.Segment[path]?.setSegmentGroupSelected(isSelected)
  }

  @AnalyzeTime(0)
  static handleNewSelection (selectedElements: any[], data: any, phantomElementList: any, elementList: any) {
    selectedElements.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, false)

        return
      }

      if (!~data.selectedDataArray.indexOf(path)) {
        if (elementList[type] && elementList[type][path]) {
          elementList[type][path].setSelected(false)

          if (phantomElementList[type] && phantomElementList[type][path]) {
            phantomElementList[type][path].hide()
          }
        }
      }
    })

    data.selectedDataArray.forEach((path: any) => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, true)

        return
      }

      if (elementList[type] && elementList[type][path]) {
        elementList[type][path].setSelected(true)

        if (phantomElementList[type] && phantomElementList[type][path]) {
          phantomElementList[type][path].show()
        }
      }
    })
  }

  @AnalyzeTime(0)
  static handleElementsOrParentsPathDifference (view: MainView, data: any) {
    if (!ConditionUtil.editElementsOrParentPathAreDifferent(view, data)) {
      return
    }

    const { featureFlags, sectionDetail, phantomElementList, elementsHashes, plHeight, hideList = [] } = view
    const { editElements, parentPath } = data

    const editElementPath = Object.keys(data.editElements)

    view.editElements = data.editElements
    view.parentPath = data.parentPath
    editElementPath.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (
        !Util.isPhantom(type, sectionDetail) ||
        !phantomElementList[type] ||
        !phantomElementList[type][path]
      ) {
        return
      }

      if (FeatureFlags.canEditElement(type, featureFlags)) {
        let parentInfo: any = {}

        while (parentInfo.type !== 'Segment') {
          parentInfo = Util.getParentInfo(parentInfo.path || path)
        }

        const { _FixedLooseSide } = elementsHashes.Segment[(parentPath || {}).Segment || parentInfo.id] || {}

        phantomElementList[type][path].container.userData.side = _FixedLooseSide

        phantomElementList[type][path].setValues(
          editElements[path],
          plHeight,
          path,
          false,
          ~hideList.indexOf(path),
          0,
          sectionDetail,
          true,
        )
      }
    })
  }

  @AnalyzeTime(0)
  static resetSegments (containerList: any) {
    Object.values(containerList.Segment).forEach((segment: any) => {
      segment.position.x = 0
      segment.position.z = 0
      segment.rotation.y = Util.RAD270
    })
  }

  @AnalyzeTime(0)
  static handleDeleteListUpdate (view: MainView, data: PassedData) {
    if (!view.views || !view.data) {
      return
    }

    view.deleteList = data.dirtyDeletePaths

    MainUtil.handleParentMismatch(view)

    MainUtil.handleFilter(
      undefined,
      view.elementsHashes.SegmentGroup,
      view.elementList,
      data.elementsHashes,
      view.views,
      false,
      data.rootData.Caster.Mold.MoldFace,
    )
    view.term = undefined
    view.updateRoller(Util.RollerMode)
  }

  @AnalyzeTime(0)
  static handleNoSectionDetail (view: MainView) {
    if (!view.coordinate || !view.coordinateStraight) {
      return
    }

    DrawHandlers.drawGridHelper(view)

    if (view.applyCurve) {
      view.coordinate.show()
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
      view.coordinateStraight.show()
    }
  }

  @AnalyzeTime(0)
  static handleIntersects (view: MainView, intersects: any[]) {
    for (let i = 0; i < intersects.length; i++) {
      const { type, path, userData } = intersects[i].object

      if (userData.disabled) {
        break
      }

      // needed for selection (setSelected())
      if (
        type === 'Nozzle' ||
        (type === 'Roller' && view.rollerChildren !== 2) ||
        (type === 'RollerBody' && view.rollerChildren !== 1) ||
        (type === 'RollerBearing' && view.rollerChildren !== 1) ||
        type === 'SensorPoint' ||
        type === 'DataPoint' ||
        type === 'DataLine' ||
        userData.type === 'SegmentGroupDetails' ||
        type === 'SupportPoint'
      ) {
        if (
          (type === 'Nozzle' && FeatureFlags.canSelectNozzle(view.featureFlags)) ||
          (type === 'Roller' && view.rollerChildren !== 2 && FeatureFlags.canSelectRoller(view.featureFlags)) ||
          (type === 'RollerBody' && view.rollerChildren !== 1 && FeatureFlags.canSelectRollerBody(view.featureFlags)) ||
          (
            type === 'RollerBearing' &&
            view.rollerChildren !== 1 &&
            FeatureFlags.canSelectRollerBearing(view.featureFlags)
          ) ||
          // TODO: add feature flag for sensor point
          (type === 'SensorPoint') || //  && FeatureFlags.canSelectSensorPoint(view.featureFlags)
          (type === 'DataPoint' && FeatureFlags.canSelectDataPoint(view.featureFlags)) ||
          (type === 'DataLine' && FeatureFlags.canSelectDataLine(view.featureFlags)) ||
          (userData.type === 'SegmentGroupDetails' && FeatureFlags.canSelectSupportPoint(view.featureFlags)) ||
          (type === 'SupportPoint' && FeatureFlags.canSelectSupportPoint(view.featureFlags))
        ) {
          view.selection.push(path)
        }

        break
      }

      if (userData.filter) {
        if (!view.setTerm) {
          return
        }

        const { filter } = userData

        if (~(view.term || [] as any).indexOf(filter)) {
          view.setTerm(filter, false, false, true)
          view.jumpToFiltered()
        }
        else {
          view.setTerm(filter, true, false, true)
          view.jumpToFilter(filter, () => {
            setTimeout(() => {
              view.setTerm(filter, false, false, false)
            }, 1)
          })
        }

        if (view.selectedElement) {
          view.selectedElement()
        }

        break
      }

      if (type === 'Strand') {
        break
      }
    }
  }

  @AnalyzeTime(0)
  static setupPerspectiveControls (view: any) {
    view.perspectiveControls = new (OrbitControls as any)(view.perspectiveCamera, view.renderer.domElement)
    view.perspectiveControls.enabled = false
    view.perspectiveControls.enableKeys = false
    view.perspectiveControls.enableRotate = true
    view.perspectiveControls.update()
  }

  @AnalyzeTime(0)
  static setupOrthographicControls (view: any) {
    view.orthographicControls = new (OrbitControls as any)(view.orthographicCamera, view.renderer.domElement)
    view.orthographicControls.enabled = false
    view.orthographicControls.enableKeys = false
    view.orthographicControls.enableRotate = false
    view.orthographicControls.update()
  }
}
