export enum RollerBodyActionsEnum {
  ACTION_ADD_ROLLER_BODY = 'RollerBody/ACTION_ADD_ROLLER_BODY',
  DELETE = 'RollerBody/DELETE',
  DELETE_MULTIPLE = 'RollerBody/DELETE_MULTIPLE',
  UPDATE = 'RollerBody/UPDATE'
}

export function addRollerBody (rollerBodyHash: Record<number, RollerBody & Parent>) {
  return {
    type: RollerBodyActionsEnum.ACTION_ADD_ROLLER_BODY,
    rollerBodyHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const RollerBodyReducers: Record<string, Function | undefined> = {
  [RollerBodyActionsEnum.ACTION_ADD_ROLLER_BODY]:
  (_state: RollerBodyHash, action: {rollerBodyHash: RollerBodyHash}) => (
    action.rollerBodyHash
  ),

  [RollerBodyActionsEnum.DELETE]: (state: RollerBodyHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [RollerBodyActionsEnum.DELETE_MULTIPLE]: (state: RollerBodyHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [RollerBodyActionsEnum.UPDATE]: (state: RollerBodyHash, action: {elements: RollerBodyHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (
  state: RollerBodyHash = {},
  action: any,
) {
  const handler = RollerBodyReducers[action.type]

  return handler ? handler(state, action) : state
}
