import * as THREE from 'three'
import React from 'react'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Mold from './Mold'
import VisUtil from 'react/visualization/VisUtil'
import { AnalyzeTime } from 'Util'

export default class SensorPoint extends BaseObject {
  static _3DGeometryCache: Record<string, THREE.BoxGeometry> = {}
  static _2DGeometryCache: Record<string, THREE.PlaneGeometry> = {}

  static selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41' })
  static selectedMaterialBasic = new THREE.MeshBasicMaterial({ color: '#7eda41' })

  static colors = [
    ...VisUtil.TRACE_COLORS,
  ]

  static coloredMaterials = SensorPoint.colors.map(color => new THREE.MeshStandardMaterial({ color }))
  static coloredMaterialsBasic = SensorPoint.colors.map(color => new THREE.MeshBasicMaterial({ color }))

  static colorHelper = SensorPoint.colors.length

  tooltipObjects: any
  wCoord: number
  plCoord: number
  size: number
  sectionDetail?: any
  colorIndex?: number
  hiddenKeys: string[]

  @AnalyzeTime(0)
  static get3DGeometry (size: number) {
    const geometryKey = `${size}`
    let geometry = SensorPoint._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.BoxGeometry(size, size, size) // no Buffer!
      geometry.translate(0, size / 2, 0)

      SensorPoint._3DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  @AnalyzeTime(0)
  static get2DGeometry (size: number) {
    const geometryKey = `${size}`
    let geometry = SensorPoint._2DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.PlaneGeometry(size, size)

      SensorPoint._2DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent)

    this.tooltipObjects = tooltipObjects
    this.size = 0.05

    const geometry = SensorPoint.get3DGeometry(this.size)

    this.objects.sensorPoint = new THREE.Mesh(geometry, SensorPoint.coloredMaterials[0])
    this.objects.sensorPoint.type = 'SensorPoint'

    this.hiddenKeys = [ '_numericId', '#parent' ]

    this.wCoord = 0
    this.plCoord = 0

    clickableObjects.push(this.objects.sensorPoint)
    container.add(this.objects.sensorPoint)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    pointData: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _loopColorIndex: number,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.sectionDetail = sectionDetail

    // eslint-disable-next-line camelcase
    const {
      _type,
      _passln_coord: passlineCoord,
      _width_coord: widthCoord,
      _size,
    } = pointData

    const { sensorPoint } = this.objects

    this.wCoord = widthCoord / 1000
    this.plCoord = passlineCoord / 1000

    this.colorIndex = ((Number(_type) || 1) - 1) % SensorPoint.colorHelper

    this.size = (_size || 50) / 1000

    if (!this.sectionDetail) {
      sensorPoint.geometry = SensorPoint.get3DGeometry(this.size)
      sensorPoint.material = SensorPoint.coloredMaterials[this.colorIndex]
    }
    else {
      sensorPoint.geometry = SensorPoint.get2DGeometry(this.size)
      sensorPoint.material = SensorPoint.coloredMaterialsBasic[this.colorIndex]

      if (!isPhantom) {
        this.getTooltips(pointData, path)
      }
    }

    this.updateTransform()

    sensorPoint.geometryNeedsUpdate = true
    sensorPoint.path = path

    this.objects.sensorPoint.userData.deleted = isDeleted

    this.setSelected(this.objects.sensorPoint.userData.selected)
  }

  @AnalyzeTime(0)
  updateTransform () {
    const { sensorPoint } = this.objects
    const { side } = this.container.userData
    const { position, angleX, normal } =
      PasslineCurve.getInfoAtPlCoord(this.plCoord || 0, this.sectionDetail ? true : undefined)

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    newSectionRotation.x = -Util.RAD90

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance
    const size = this.sectionDetail ? this.size : 0

    switch (side) {
      case 'FixedSide':
        newPosition.set(this.wCoord, position.y + 0.00001, position.z + FixedSide.x - size / 2)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case 'LooseSide':
        newPosition.set(this.wCoord, position.y + 0.00001, position.z + LooseSide.x + size / 2)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        // newSectionRotation.y = Util.RAD180
        break
      case 'NarrowFaceRight':
        newPosition.set(NarrowFaceRight.x - size / 2, position.y + 0.00001, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        // newSectionRotation.y = Util.RAD90
        break
      case 'NarrowFaceLeft':
        newPosition.set(NarrowFaceLeft.x + size / 2, position.y + 0.00001, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        // newSectionRotation.y = -Util.RAD90
        break
      default:
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  getTooltips (pointData: any, path: string) {
    const { sensorPoint } = this.objects

    const keys = Object.keys(pointData).filter(key => !this.hiddenKeys.includes(key))
    const { id } = Util.getElementInfo(path)
    const { side } = this.container.userData

    // const s = side === 'LooseSide' || side === 'NarrowFaceRight' ? -1 : 1

    const pos = new THREE.Vector3(0, 0, 0)
    const name = `tooltipMarkerHeight_${side}_${id}`

    Util.createTooltipMarker(
      sensorPoint,
      this.tooltipObjects,
      name,
      pos,
      (
        <div>
          <b>{sensorPoint.type}: {id}</b><br />
          <br />
          {keys.map(key => <div key={key}>{key.replace(/^_/, '')}: {pointData[key]}</div>)}
        </div>
      ),
    )

    const marker = sensorPoint.getObjectByName(`TooltipMarker_${name}`)
    const snap = sensorPoint.getObjectByName(`TooltipMarkerSnap_${name}`)

    marker.visible = false
    snap.material.visible = false

    snap.position.copy(new THREE.Vector3(0, 0, 0))
    snap.rotation.copy(new THREE.Euler(0, 0, Util.RAD90, 'XYZ'))
  }

  @AnalyzeTime(0)
  getMeasures () {
    const { geometry, position: { y } } = this.objects.sensorPoint

    geometry.computeBoundingBox()

    const { min, max } = geometry.boundingBox

    return {
      heightMin: y + min.z,
      heightMax: y + max.z,
    }
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    this.objects.sensorPoint.userData.selected = isSelected

    if (!this.sectionDetail) {
      this.objects.sensorPoint.material = isSelected
        ? SensorPoint.selectedMaterial
        : SensorPoint.coloredMaterials[this.colorIndex || Infinity] || SensorPoint.coloredMaterials[0]
    }
    else {
      this.objects.sensorPoint.material = isSelected
        ? SensorPoint.selectedMaterialBasic
        : SensorPoint.coloredMaterialsBasic[this.colorIndex || Infinity] || SensorPoint.coloredMaterialsBasic[0]
    }
  }

  show (dontShowRollerChildren: boolean) {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true

    if (this.parent) {
      if (dontShowRollerChildren && this.parent.parent && this.parent.parent.type === 'Roller') {
        this.parent.parent.show()
      }
      else {
        this.parent.show()
      }
    }
  }
}
