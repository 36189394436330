import Mold from 'three/objects/Mold'
import type { ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'
import Util from '../logic/Util'
import type { Views } from '../ThreeBase'

type FilterAttribute = {
  key: string
  value: string
  operator: string
}

type Filter = {
  id: string
  type: string
  attributes: FilterAttribute[]
}

type FilterFunction = {(path: string, type: FilterableElementType, element: any): boolean}
type FilterHandlerData = {
  filterableTypes: Record<string, boolean>,
  typeMatch: Record<string, string>,
  sideMatch: Record<string, string>
}

export default class FilterHandler {
  static CHILDREN = {
    SegmentGroup: [ 'Segment', 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing', 'SensorPoint', 'SupportPoint' ],
    Segment: [ 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing', 'SensorPoint' ],
    SupportPoint: [],
    Nozzle: [],
    Roller: [ 'RollerBody', 'RollerBearing', 'SensorPoint' ],
    RollerBody: [],
    RollerBearing: [],
    SensorPoint: [],
    DataPoint: [],
    DataLine: [],
  }

  static visibleElements: Record<string, FilterableElementType> = {}
  static filteredElements: Record<string, Record<string, FilterableElementType>> = {}

  static data: FilterHandlerData = {
    filterableTypes: {
      SegmentGroup: true,
      Segment: true,
      SupportPoint: true,
      Nozzle: true,
      Roller: true,
      RollerBody: true,
      RollerBearing: true,
      SensorPoint: true,
      DataPoint: true,
      DataLine: true,
      CoolingLoop: true,
      AirLoop: true,
    },
    typeMatch: {
      sg: 'SegmentGroup',
      s: 'Segment',
      su: 'SupportPoint',
      n: 'Nozzle',
      ry: 'RollerBody',
      rg: 'RollerBearing',
      r: 'Roller',
      sp: 'SensorPoint',
      segmentgroup: 'SegmentGroup',
      segment: 'Segment',
      supportpoint: 'SupportPoint',
      nozzle: 'Nozzle',
      rollerbody: 'RollerBody',
      rollerbearing: 'RollerBearing',
      roller: 'Roller',
      sensorpoint: 'SensorPoint',
      datapoint: 'DataPoint',
      dataline: 'DataLine',
      coolingloop: 'CoolingLoop',
      airloop: 'AirLoop',
    },
    sideMatch: {
      fs: 'FixedSide',
      fixed: 'FixedSide',
      ls: 'LooseSide',
      loose: 'LooseSide',
      nl: 'NarrowFaceLeft',
      left: 'NarrowFaceLeft',
      nr: 'NarrowFaceRight',
      right: 'NarrowFaceRight',
    },
  }

  static variables = [
    'CurrentPassLnCoord',
    'HalfStrandWidth',
  ]

  // static deconstructedFiltersPerTerm: Record<string, Filter[] | Filter> = {}

  static variableRegEx = new RegExp(`(${FilterHandler.variables.join('|')})`, 'g')

  @AnalyzeTime(0)
  private static isFilterable (type: string) {
    return FilterHandler.data.filterableTypes[type]
  }

  @AnalyzeTime(0)
  private static getFullType (type: string) {
    return (FilterHandler.data.typeMatch as any)[type.toLowerCase()] || type
  }

  @AnalyzeTime(0)
  private static getFullSide (side: string) {
    return (FilterHandler.data.sideMatch as any)[side.toLowerCase()] || side
  }

  @AnalyzeTime(0)
  private static handleOperator (a: string, b: string, operator: string) {
    const nA = Number(a)
    const nB = Number(b)

    switch (operator) {
      case '': return !b
      case '!=': return a !== b
      case '>=': return nA >= nB
      case '<=': return nA <= nB
      case '=': return a === b
      case '>': return nA > nB
      case '<': return nA < nB
      default:
    }

    return false
  }

  @AnalyzeTime(0)
  private static getFilter (filter: string): Filter | Filter[] {
    if (~filter.indexOf('/')) {
      const levels = filter.split('/')
      const filters: any[] = levels.map(FilterHandler.getFilter)

      return filters.reduce((list: any, element) => {
        const elements = element instanceof Array ? element : [ element ]

        elements.forEach(filterElem => {
          list.push(filterElem)
        })

        return list
      }, [])
    }

    const hashIndex = filter.indexOf('#')
    const colonIndex = filter.indexOf(':')
    const hasHash = hashIndex !== -1
    const hasColon = (colonIndex !== -1 && !filter.includes('#ref='))
    let typeRaw = hasHash ? filter.substring(0, hashIndex) : filter

    typeRaw = hasColon ? filter.substring(0, colonIndex) : typeRaw

    const type = FilterHandler.getFullType(typeRaw)
    const attributePairs = hasHash ? filter.substring(hashIndex + 1) : ''
    const id = hasColon ? filter.substring(colonIndex + 1) : null

    const attributes = !attributePairs ? [] : attributePairs.split(',').map(pair => {
      const parts = pair.replace(/(!=|>=|<=|[=<>])/, '#').split('#')

      const key = `_${parts[0]}`
      const value = parts[1] || ''
      const operator = pair.substring(parts[0].length, pair.length - value.length) || ''

      return {
        key,
        value,
        operator,
      }
    })

    return {
      type,
      attributes,
      id,
    } as Filter
  }

  @AnalyzeTime(0)
  private static handleLevel (filter: Filter, path: string, _type: string, element: any) {
    let hit = true

    if (filter.attributes.length) {
      const elementAttributes = Object.keys(element)
      const hitAttributes = filter.attributes.filter((attribute: any) => {
        if (!~elementAttributes.indexOf(attribute.key)) {
          return false
        }

        return FilterHandler.handleOperator(String(element[attribute.key]), attribute.value, attribute.operator)
      })

      hit = hitAttributes.length === filter.attributes.length
    }
    else if (filter.type === FilterHandler.getFullType('sg') && filter.id === '*') {
      hit = true
    }
    else if (filter.type === FilterHandler.getFullType('sg') && filter.id && filter.id.includes(',')) {
      hit = filter.id.split(',').includes(`${element._id}`)
    }
    else if (filter.type === FilterHandler.getFullType('sg') && filter.id && filter.id.includes(':')) {
      const [ rangeStart, rangeStop ] = filter.id.split(':')

      hit = Number(rangeStart) <= Number(element._id) && Number(element._id) <= Number(rangeStop)
    }
    else if (filter.type === FilterHandler.getFullType('s') && typeof filter.id === 'string') {
      const lastPart = path.split('/').splice(-1)[0]

      hit = lastPart === `${filter.type}:${filter.id}` ||
        element._FixedLooseSide.toLowerCase() === FilterHandler.getFullSide(filter.id).toLowerCase()
    }
    else if (filter.id) {
      const lastPart = path.split('/').splice(-1)[0]

      hit = lastPart === `${filter.type}:${filter.id}`
    }

    return hit
  }

  @AnalyzeTime(0)
  private static handleElement (
    term: string,
    filters: Filter | Filter[],
    path: string,
    type: FilterableElementType,
    element: any,
  ) {
    let hit = false

    const parentInfo = Util.getParentInfo(path)
    const isParentVisible = FilterHandler.filteredElements[term]?.[parentInfo.path]

    const arrayOfFilters = filters instanceof Array ? filters : [ filters ]

    arrayOfFilters.forEach((filterElement, index) => {
      const allowed = index && filters instanceof Array
        ? filters[index - 1].type !== type
        : true
      const isChild = index && filters instanceof Array
        ? ((FilterHandler.CHILDREN as any)[filters[index - 1].type] || []).includes(type)
        : false

      if (isParentVisible && allowed && isChild && filterElement.type === '*') {
        hit = true

        return
      }

      if (filterElement.type !== type) {
        return
      }

      if (isParentVisible || index === 0) {
        hit = FilterHandler.handleLevel(filterElement, path, type, element)
      }
    })

    if (hit) {
      if (!FilterHandler.filteredElements[term]) {
        FilterHandler.filteredElements[term] = {}
      }

      FilterHandler.filteredElements[term][path] = type
    }

    return hit
  }

  private static handleCurrentPassLnCoord (): string {
    return String((window as any).currentPasslnPosition || 0)
  }

  private static handleHalfStrandWidth (): string {
    return String(Mold.width / 2 * 1000)
  }

  private static handleFilterControlVariables (variable: string): string {
    return String((window as any).filterControlVariables?.[variable] ?? 1)
  }

  @AnalyzeTime(0)
  private static handleVariables (_match: string, group1: string): string {
    if (group1 === 'CurrentPassLnCoord') {
      return FilterHandler.handleCurrentPassLnCoord()
    }
    else if (group1 === 'HalfStrandWidth') {
      return FilterHandler.handleHalfStrandWidth()
    }
    else if (FilterHandler.variables.includes(group1)) {
      return FilterHandler.handleFilterControlVariables(group1)
    }

    return group1
  }

  private static handleCalculate (_match: string, g1: string): string {
    // prepare the expression, do not allow any code injection other than the allowed Math functions
    // e.g.:
    // input: [console.log('asd');(Math.sin(1)+5)*3.5;console.log('asd')]
    // clean: (Math.sin(1)+5)*3.5
    // result: 20.445148446827638
    const calc = g1.replace(/.*?(\d\.\d|[\d+\-*()]|Math\.[a-zA-Z]+\()]?/g, '$1').replace(/\(\)/g, '')

    let result = 0

    try {
      // eslint-disable-next-line no-eval
      result = eval(calc)
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('Calculation Invalid:', calc, error)
      // TODO: display error
    }

    return String(result)
  }

  @AnalyzeTime(0)
  private static prepareFilters (term: string): (string | FilterFunction)[] {
    // e.g. Nozzle#passln_coord=[CurrentPassLnCoord+1-1]
    let preparedTerm = term.replace(FilterHandler.variableRegEx, FilterHandler.handleVariables)

    preparedTerm = preparedTerm.replace(/(\[[^\]]{3,}\])/g, FilterHandler.handleCalculate)

    return FilterHandler.tokenizeExpression(preparedTerm)
  }

  static isValidFilterTerm (filterTerm: string) {
    // First, we check if the filter term ends with an incomplete operator
    if (/(\|\||&&)$/.test(filterTerm)) {
      return false
    }

    // Then, we split the filter term into tokens
    const tokens = filterTerm.split(/(\|\||&&|\(|\)|#|=)/).filter(token => token.trim() !== '')

    // We keep track of the number of opening and closing parentheses
    let numOpenParens = 0
    let numCloseParens = 0

    // We iterate through the tokens and check for syntax errors
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]

      if (token === '(') {
        if (tokens[i + 1] && tokens[i + 1] === ')') {
          // If we encounter an empty pair of parentheses, the syntax is invalid
          return false
        }

        numOpenParens++
      }
      else if (token === ')') {
        numCloseParens++

        // If we encounter more closing parentheses than opening ones, the syntax is invalid
        if (numCloseParens > numOpenParens) {
          return false
        }
      }
      else if (token === '||' || token === '&&') {
        // If an operator is encountered without two expressions on either side, the syntax is invalid
        if (i === 0 || i === tokens.length - 1 || tokens[i - 1] === '(' || tokens[i + 1] === ')') {
          return false
        }
      }
      else if (token === '#' || token === '=') {
        // If a '#' or '=' is encountered without valid identifiers or values on either side, the syntax is invalid
        if (i === 0 || i === tokens.length - 1) {
          return false
        }
      }
      else {
        // If an identifier or value is encountered without valid tokens on either side, the syntax is invalid
        if (
          (i > 0 && !/(\|\||&&|\(|#|=)/.test(tokens[i - 1])) ||
        (i < tokens.length - 1 && !/(\|\||&&|\)|#|=)/.test(tokens[i + 1]))) {
          return false
        }
      }
    }

    // Finally, we check if there are the same number of opening and closing parentheses
    if (numOpenParens !== numCloseParens) {
      return false
    }

    // If none of the syntax checks failed, the filter term is valid
    return true
  }

  static tokenizeExpression (expression: string) {
    const precedence = {
      '(': 1,
      ')': 1,
      '||': 2,
      '&&': 3,
    }

    const filters: (string | FilterFunction)[] = []
    const filterStack: string[] = []

    // eslint-disable-next-line no-useless-escape
    const tokens = expression.replace(/\s/g, '').match(/([()])|\|\||&&|[^()|&]+/g)

    if (!tokens) {
      return []
    }

    tokens.forEach(token => {
      if (token === '||' || token === '&&') {
        while (
          filterStack.length > 0 &&
          (precedence as any)[filterStack[filterStack.length - 1]] >= precedence[token]
        ) {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.push(token)
      }
      else if (token === '(') {
        filterStack.push(token)
      }
      else if (token === ')') {
        while (filterStack[filterStack.length - 1] !== '(') {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.pop()
      }
      else {
        const deconstructedFilters = FilterHandler.getFilter(token)

        filters.push((path: string, type: FilterableElementType, element: any) =>
          FilterHandler.handleElement(token, deconstructedFilters as any, path, type, element))
      }
    })

    while (filterStack.length > 0) {
      const nextFilter = filterStack.pop()

      if (nextFilter) {
        filters.push(nextFilter)
      }
    }

    return filters
  }

  @AnalyzeTime(0)
  static applyFiltersToElement (
    tokens: (string | FilterFunction)[],
    path: string,
    type: FilterableElementType,
    element: any,
  ) {
    const hit = FilterHandler.applyTokensToElement(tokens, path, type, element)

    if (!hit) {
      return
    }

    FilterHandler.visibleElements[path] = type
  }

  static applyTokensToElement (
    tokens: (string | FilterFunction)[],
    path: string,
    type: FilterableElementType,
    element: any,
  ) {
    const stack: FilterFunction[] = []

    tokens.forEach(token => {
      if (token === '&&') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) => filter1(path, type, element) && filter2(path, type, element))
      }
      else if (token === '||') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) => filter1(path, type, element) || filter2(path, type, element))
      }
      else {
        if (typeof token === 'string') {
          return
        }

        // is a filter
        stack.push(token)
      }
    })

    return stack[0](path, type, element)
  }

  static getNozzlesByLoopId (
    elementsHashes: ElementsHashes,
    SegmentGroup: SegmentGroupHash,
    loopId: number,
  ) {
    const filteredNozzlesPaths: string[] = []

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
          Object.keys(SegmentGroup).filter(key => key[0] !== '#') as any,
          null,
          '',
          elementsHashes,
          (path: string, type: string, element: any) => {
            if (type === 'Nozzle') {
              if (element && element._loop === loopId) {
                filteredNozzlesPaths.push(path)
              }
            }
          },
    )

    return filteredNozzlesPaths
  }

  @AnalyzeTime(0)
  static getFilteredElements (
    elementsHashes: ElementsHashes,
    term: string | undefined,
    skipRollerChildren: boolean,
    moldFaces?: Record<string, MoldFace>,
    override = false,
  ) {
    FilterHandler.filteredElements = {}

    if (term && !FilterHandler.isValidFilterTerm(term)) {
      return {}
    }

    const filters = term ? FilterHandler.prepareFilters(term) : []

    FilterHandler.visibleElements = {}
    FilterHandler.filteredElements = {}

    if (filters.length) {
      // get the sensorPoints that are in MoldFace
      if (moldFaces) {
        Object.values(moldFaces).forEach(moldFace => {
          for (const sensorPoint of moldFace.SensorPoint) {
            FilterHandler.applyFiltersToElement(
              filters,
              `Mold/SensorPoint:${sensorPoint._id}`,
              'SensorPoint',
              elementsHashes.SensorPoint[parseInt(sensorPoint._id)],
            )
          }
        })
      }

      const standaloneTypes: ('CoolingLoop' | 'AirLoop' | 'DataPoint' | 'DataLine')[] =
        [ 'CoolingLoop', 'AirLoop', 'DataPoint', 'DataLine' ]

      standaloneTypes.forEach(type => {
        Object.keys(elementsHashes[type] || {}).filter(key => key[0] !== '#').forEach(id => {
          FilterHandler.applyFiltersToElement(
            filters,
            `${type}:${id}`,
            type,
            elementsHashes[type][parseInt(id)],
          )
        })
      })

      Util.runPerElementHash(
        'SegmentGroup',
        'SegmentGroup',
          Object.keys(elementsHashes.SegmentGroup).filter(key => key[0] !== '#') as any,
          null,
          '',
          elementsHashes,
          (path: string, type: FilterableElementType, element: any) => {
            if (FilterHandler.isFilterable(type)) {
              if (skipRollerChildren && (type === 'RollerBody' || type === 'RollerBearing')) {
                return
              }

              FilterHandler.applyFiltersToElement(
                filters,
                path,
                type,
                element,
              )
            }
          },
      )
    }

    const ret: any = { ...FilterHandler.visibleElements }

    if (override && !term) {
      return null
    }

    return ret
  }

  @AnalyzeTime(0)
  static getAllElements (SegmentGroup: SegmentGroupHash, elementsHashes: ElementsHashes) {
    const paths: {path: string, type: string}[] = []

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
      Object.keys(SegmentGroup).filter(key => key[0] !== '#') as any,
      null,
      '',
      elementsHashes,
      (
        path: string,
        type: string,
      ) => {
        if (FilterHandler.isFilterable(type)) {
          paths.push({ path, type })
        }
      },
    )

    const { DataPoint, DataLine, CoolingLoop, AirLoop } = elementsHashes

    const coolingLoopIds = Object.keys(CoolingLoop).filter(key => key[0] !== '#').map(id => parseInt(id))
    const numberOfCoolingLoops = coolingLoopIds.length

    for (let i = 0; i < numberOfCoolingLoops; i++) {
      paths.push({ path: `CoolingLoop:${coolingLoopIds[i]}`, type: 'CoolingLoop' })
    }

    const airLoopIds = Object.keys(AirLoop).filter(key => key[0] !== '#').map(id => parseInt(id))
    const numberOfAirLoops = coolingLoopIds.length

    for (let i = 0; i < numberOfAirLoops; i++) {
      paths.push({ path: `AirLoop:${airLoopIds[i]}`, type: 'AirLoop' })
    }

    if (DataPoint) {
      const dataPointIds = Object.keys(DataPoint).filter(key => key[0] !== '#').map(id => parseInt(id))
      const numberOfDataPoints = dataPointIds.length

      for (let i = 0; i < numberOfDataPoints; i++) {
        paths.push({ path: `DataPoint:${dataPointIds[i]}`, type: 'DataPoint' })
      }
    }

    if (DataLine) {
      const dataLineIds = Object.keys(DataLine).filter(key => key[0] !== '#').map(id => parseInt(id))
      const numberOfDataLines = dataLineIds.length

      for (let i = 0; i < numberOfDataLines; i++) {
        paths.push({ path: `DataLine:${dataLineIds[i]}`, type: 'DataLine' })
      }
    }

    const { Segment } = elementsHashes

    Object.keys(Segment).filter(key => key[0] !== '#').forEach(id => {
      const segment = Segment[id as any]

      if (!segment) {
        return
      }

      const path = `SegmentGroup:${(segment as any)['#parent'].id}/Segment:${id}`

      paths.push({ path, type: 'Segment' })
    })

    Object.keys(SegmentGroup).filter(key => key[0] !== '#').forEach(id => {
      paths.push({ path: `SegmentGroup:${id}`, type: 'SegmentGroup' })
    })

    return paths
  }

  @AnalyzeTime(0)
  static applyFilterToElements (
    SegmentGroup: SegmentGroupHash,
    visibleElements: Record<string, FilterableElementType> | null,
    elementList: any,
    views: Views,
    elementsHashes: ElementsHashes,
    skipRollerChildren = false,
  ) {
    const list = visibleElements ? Object.entries(visibleElements) as [string, FilterableElementType][] : []
    const shouldHide = Boolean(list.length) || visibleElements !== null

    // Commented to not hide section view when there are no elements to show
    // const filteredTypes = Object.keys(filteredElements).reduce((list: any[], filteredElement) => ([
    //   ...list,
    //   ...JSON.parse(filteredElement).map((element: any) => element.type),
    // ]), [])

    // const nozzleIncl = filteredTypes.includes('Nozzle')

    // if (views.uiView?.isSectionActive) {
    //   views.uiView?.sectionViewHandler(nozzleIncl || !(filteredTypes.length > 0))
    // }

    // handle all
    const ids = Object.keys(SegmentGroup).filter(key => !key.includes('hasChanges')).map(key => parseInt(key))

    Object.keys(elementsHashes.DataPoint).filter(key => !key.includes('hasChanges')).forEach(id => {
      if (!elementList.DataPoint) {
        return
      }

      const object = elementList.DataPoint[`DataPoint:${id}`]

      if (!object) {
        return
      }

      if (shouldHide) {
        return object.hide()
      }

      object.show()
    })
    Object.keys(elementsHashes.DataLine).filter(key => !key.includes('hasChanges')).forEach(id => {
      if (!elementList.DataLine) {
        return
      }

      const object = elementList.DataLine[`DataLine:${id}`]

      if (!object) {
        return
      }

      if (shouldHide) {
        return object.hide()
      }

      object.show()
    })

    const moldFaces = views.mainView?.data?.Caster.Mold.MoldFace

    if (moldFaces) {
      Object.values(moldFaces).forEach(moldFace => {
        const sensorPoints = moldFace.SensorPoint
        const amountOfSensorPoints = sensorPoints.length

        for (let i = 0; i < amountOfSensorPoints; i++) {
          const object = elementList.SensorPoint[`Mold/SensorPoint:${sensorPoints[i]._id}`]

          if (!object) {
            return
          }

          if (shouldHide) {
            object.hide()
            continue
          }

          object.show(skipRollerChildren)
        }
      })
    }

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
      ids,
      null,
      '',
      elementsHashes,
      (path: string, type: string) => {
        if (!FilterHandler.isFilterable(type) || !elementList[type]) {
          return
        }

        const object = elementList[type][path]

        if (!object) {
          return
        }

        if (shouldHide) {
          return object.hide()
        }

        if (skipRollerChildren && (type === 'RollerBody' || type === 'RollerBearing')) {
          return
        }

        object.show(skipRollerChildren)
      },
    )

    // show filtered
    list.forEach(([ path, type ]) => {
      if (elementList[type] && elementList[type][path]) {
        elementList[type][path].show(skipRollerChildren)
      }
    })
  }
}
