/* eslint-env browser */

import ApiClient from '../../../store/apiClient'
import { Network } from '../../../network/Network'
import IpcManager from '../../../IpcManager'
import Util from './Util'

const util: {hdf5SchemaPath: string | null | undefined} = { hdf5SchemaPath: undefined }

// TODO: is this function still in use?
export function openFile (currentSimulationCase: any): void {
  IpcManager.both.send('setLoading', null, true, 'VisualizationIsLoading')

  Util.openUploadFileDialog('.hdf5', '/visualization_data', (formData: any) => {
    formData.append('simulationCaseId', currentSimulationCase._id)
    formData.append('schemaPath', util.hdf5SchemaPath || 'default')
  })
    .then(({ project }) => {
      const { visualizationDataList } = project.simulationCases[0]

      if (visualizationDataList && visualizationDataList.length) {
        IpcManager.both.send('VisualizationData', null, visualizationDataList.slice(-1)[0])
        IpcManager.both.send('setLoading', null, false)
      }
    })
    .catch(() => IpcManager.both.send('setLoading', null, false))
}

export function openHdf5Schema () {
  Util.openUploadFileDialog('.json', '/visualization_schema')
    .then(({ fileName }) => {
      IpcManager.both.send('addHdf5Schema', null, fileName)

      util.hdf5SchemaPath = fileName
    })
    .catch(() => IpcManager.both.send('setLoading', null, false))
}

export function setHdf5SchemaPath (hdf5SchemaPath: string) {
  util.hdf5SchemaPath = hdf5SchemaPath !== 'default' ? hdf5SchemaPath : null
}

export function visualizationPlotExportImages (
  plotImages: Array<any>,
  exportType: string,
  projectId: string,
  simulationCaseId: string,
  visualizationMetaInfo: any,
) {
  ApiClient.post(
    `${Network.URI}/visualization_save_plot_images`,
    { data: { plotImages, exportType, projectId, simulationCaseId, visualizationMetaInfo } },
  )
    .then(({ data }) => Util.openDownloadFileDialog(data, `export.${exportType}`))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}
