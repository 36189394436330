import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core'
import styled from 'styled-components'

import StyleConfig from 'react/visualization/dashboard/config/StyleConfig'

import * as ApplicationActions from 'store/application/main/actions'

import MultiStateSwitch from 'react/components/MultiStateSwitch'
import ProjectDataDialog from 'react/dialogs/project/ProjectDataDialog'
import ProjectMatrixDialog from 'react/dialogs/ProjectMatrixDialog'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const Wrapper = styled.div`
  display: inline-block;

  > div {
    background-color: transparent;
    border-left: 1px solid #373f49;
    border-right: 1px solid #373f49;
  }

  button {
    border-top-right-radius: 10px;
  }

  button:hover {
    background-color: rgba(127, 127, 127, 0.1);
  }

  [data-value="0"] button:nth-of-type(1),
  [data-value="1"] button:nth-of-type(2),
  [data-value="2"] button:nth-of-type(3) {
    background-color: rgba(127, 127, 127, 0.2);
  }
`

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  openAppDialogs: state.application.main.openAppDialogs,
}), {
  setAppState: ApplicationActions.setAppState,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

const TRANS = 'appSwitch'

/*
  SCSS/HTML source: https://codepen.io/personable/pen/stpwD
*/
class AppSwitch extends Component<Props> {
  // @AnalyzeTime(0)
  handleChange = (value: number) => {
    const { setAppState } = this.props

    setAppState(value)
  };

  @AnalyzeTime(0)
  render () {
    const { appState, openAppDialogs, t } = this.props

    const scopes = [ 'caster', 'paramDashboard', 'resultDashboard' ]

    const options = scopes.map(scope => ({
      label: t(`${TRANS}.${scope}.label`),
      title: t(`${TRANS}.${scope}.title`),
    }))

    const disabled = openAppDialogs.includes(ProjectDataDialog.NAME) ||
      openAppDialogs.includes(ProjectMatrixDialog.NAME)

    return (
      <MuiThemeProvider theme={StyleConfig.muiTheme(true)}>
        <Wrapper>
          <MultiStateSwitch options={options} value={appState} onChange={this.handleChange} disabled={disabled} />
        </Wrapper>
      </MuiThemeProvider>
    )
  }
}

export default withNamespaces('titlebar')(connector(AppSwitch))
