import React from 'react'
import Icon from '../../../specific/Icon'
import Editor from '../Editor'
import Logic, { StyledTooltip } from '.'
import { AnalyzeTime } from 'Util'

export default class Renderers {
  // @AnalyzeTime(0)
  static valueRenderer = ({ value, valueType, title, titleList }:
    {value: any, valueType: string, title: string, titleList: any}) => {
    if (valueType === 'icon') {
      return <Icon {...value} />
    }

    const valueComponent = value ? <span>{value}</span> : <span>&nbsp;</span>

    if (title || (titleList && titleList.length)) {
      return (
        <StyledTooltip
          interactive={titleList && titleList.length > 1}
          placement='bottom'
          title={
            title || (titleList ? (
              <table>
                <tbody>
                  {
                    titleList.map((titleEntry: any, i: number) => {
                      let value: JSX.Element | JSX.Element[] = <td>{titleEntry}</td>

                      if (titleEntry instanceof Array) {
                        value = titleEntry.map((titleEntryPart, j) => <td key={j}>{titleEntryPart}</td>)
                      }

                      return <tr key={i}>{value}</tr>
                    })
                  }
                </tbody>
              </table>
            ) : '')
          }
        >
          {valueComponent}
        </StyledTooltip>
      )
    }

    return valueComponent
  }

  // @AnalyzeTime(0)
  static renderDataEditor = (editorProps: any, selectedCellData: any) => {
    const { grid } = Logic.dialogInstance.props
    const { row } = editorProps
    const { id, simulationStartedAt } = (grid[row].find((cell: any) => cell.key === 'simulation_case') || {}).data || {}

    return (
      <Editor
        {...editorProps}
        selectedCellData={selectedCellData}
        simulationCaseId={id}
        simulationStartedAt={simulationStartedAt}
        onClose={Logic.handleDataEditor}
      />
    )
  }
}
