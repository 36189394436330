import cloneDeep from 'clone-deep'
import type { ElementsHashes } from 'types/state'
import { AppState } from 'store/application/main/consts'

import * as flags from './consts'
import FeatureFlagsUtil from './util'

type Permissions = Record<string, boolean>

export default class FeatureFlags {
  static getRealFeatureFlags (state: any): Permissions {
    const { application: { main: { authenticationData, currentSimulationCase } } } = state

    let featureFlags = authenticationData?.featureFlags || {}

    featureFlags = cloneDeep(featureFlags || {})

    if (currentSimulationCase && currentSimulationCase.simulationStartedAt) {
      featureFlags[flags.Caster_Details_Edit_Roller] = false
      featureFlags[flags.Caster_Details_Edit_RollerBearing] = false
      featureFlags[flags.Caster_Details_Edit_RollerBody] = false
      featureFlags[flags.Caster_Details_Edit_Mold] = false
      featureFlags[flags.Caster_Details_Edit_Nozzle] = false
      featureFlags[flags.Caster_Details_Edit_DataLine] = false
      featureFlags[flags.Caster_Details_Edit_DataPoint] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInMoldFace] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInNozzle] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRoller] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRollerBearing] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRollerBody] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInSegment] = false
      featureFlags[flags.Caster_Details_Edit_CoolingLoop] = false
    }

    return featureFlags
  }

  static isLoggedIn ({ application: { main: { authenticationData } } }: any): boolean {
    return authenticationData &&
      authenticationData.featureFlags &&
      Object.keys(authenticationData.featureFlags).length > 0
  }

  static canViewNozzlesIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_Nozzle] ?? false
  }

  static canSetDefaultCase (permissions: Permissions = {}): boolean {
    return permissions[flags.General_Set_Default_Case] ?? false
  }

  static canViewRollersIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_Roller] ?? false
  }

  static canViewSensorPointsInRollerBodiesIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_SensorPointInRollerBody] ?? false
  }

  static canViewSupportPointsIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_SupportPoint] ?? false
  }

  static canEditElement (elementType: string, permissions: Permissions = {}): boolean {
    switch (elementType) {
      case 'General':
        return this.canEditMold(permissions)
      case 'Nozzle':
        return this.canEditNozzle(permissions)
      case 'Roller':
        return this.canEditRoller(permissions)
      case 'RollerBody':
        return this.canEditRollerBody(permissions)
      case 'RollerBearing':
        return this.canEditRollerBearing(permissions)
      case 'SensorPoint':
        return (
          FeatureFlags.canEditSensorPointInMoldFace(permissions) ||
          FeatureFlags.canEditSensorPointInRollerBody(permissions) ||
          FeatureFlags.canEditSensorPointInRollerBearing(permissions) ||
          FeatureFlags.canEditSensorPointInRoller(permissions) ||
          FeatureFlags.canEditSensorPointInSegment(permissions) ||
          FeatureFlags.canEditSensorPointInNozzle(permissions)
        )
      case 'DataPoint':
        return this.canEditDataPoint(permissions)
      case 'DataLine':
        return this.canEditDataLine(permissions)
      case 'SegmentGroup':
      case 'SegmentGroupSupportPoints':
      case 'SupportPoint':
        return this.canEditSupportPoint(permissions)
      case 'Elements':
      case 'Modules':
        return false
      default:
        // eslint-disable-next-line no-console
        console.log(`Unknown element type: ${elementType}`)

        return false
    }
  }

  static canEditMold (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Mold] ?? false
  }

  static canEditRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Roller] ?? false
  }

  static canEditRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_RollerBody] ?? false
  }

  static canEditRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_RollerBearing] ?? false
  }

  static canEditSensorPointInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInMoldFace] ?? false
  }

  static canEditSensorPointInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRollerBody] ?? false
  }

  static canEditSensorPointInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRollerBearing] ?? false
  }

  static canEditSensorPointInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRoller] ?? false
  }

  static canEditSensorPointInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInNozzle] ?? false
  }

  static canEditSensorPointInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInSegment] ?? false
  }

  static canEditDataPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_DataPoint] ?? false
  }

  static canEditDataLine (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_DataLine] ?? false
  }

  static canEditNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Nozzle] ?? false
  }

  static canEditSupportPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SupportPoint] ?? false
  }

  static canSelectRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_Roller] ?? false
  }

  static canSelectRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_RollerBody] ?? false
  }

  static canSelectRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_RollerBearing] ?? false
  }

  static canSelectSensorPointInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInMoldFace] ?? false
  }

  static canSelectSensorPointInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRollerBody] ?? false
  }

  static canSelectSensorPointInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRollerBearing] ?? false
  }

  static canSelectSensorPointInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRoller] ?? false
  }

  static canSelectSensorPointInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInNozzle] ?? false
  }

  static canSelectSensorPointInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInSegment] ?? false
  }

  static canSelectSupportPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SupportPoint] ?? false
  }

  static canSelectDataPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_DataPoint] ?? false
  }

  static canSelectDataLine (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_DataLine] ?? false
  }

  static canSelectNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_Nozzle] ?? false
  }

  static canViewTypesAttributes (type: CasterElementNames | 'General', permissions: Permissions) {
    switch (type) {
      case 'General':
        return this.canViewMoldAttributes(permissions)
      case 'Roller':
        return this.canViewRollerAttributes(permissions)
      case 'RollerBearing':
        return this.canViewRollerBearingAttributes(permissions)
      case 'RollerBody':
        return this.canViewRollerBodyAttributes(permissions)
      case 'DataLine':
        return this.canViewDataLineAttributes(permissions)
      case 'DataPoint':
        return this.canViewDataPointAttributes(permissions)
      case 'Nozzle':
        return this.canViewNozzleAttributes(permissions)
      case 'SegmentGroup':
      case 'SegmentGroupSupportPoints':
      case 'SupportPoint':
        return this.canViewSupportPointAttributes(permissions)
      default:
        return false
    }
  }

  static canViewMoldAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Mold] ?? false
  }

  static canViewRollerAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Roller] ?? false
  }

  static canViewRollerBodyAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_RollerBody] ?? false
  }

  static canViewRollerBearingAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_RollerBearing] ?? false
  }

  static canViewSensorPointAttributesInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInMoldFace] ?? false
  }

  static canViewSensorPointAttributesInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRollerBody] ?? false
  }

  static canViewSensorPointAttributesInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRollerBearing] ?? false
  }

  static canViewSensorPointAttributesInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRoller] ?? false
  }

  static canViewSensorPointAttributesInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInNozzle] ?? false
  }

  static canViewSensorPointAttributesInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInSegment] ?? false
  }

  static canViewSupportPointAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SupportPoint] ?? false
  }

  static canViewSensorPointAttributesInCurrentLocations (
    permissions: Permissions = {},
    paths: string[],
    elementsHashes: ElementsHashes,
  ): boolean {
    const sensorPointViewPermissions = {
      MoldFace: FeatureFlags.canViewSensorPointAttributesInMoldFace(permissions),
      RollerBody: FeatureFlags.canViewSensorPointAttributesInRollerBody(permissions),
      RollerBearing: FeatureFlags.canViewSensorPointAttributesInRollerBearing(permissions),
      Roller: FeatureFlags.canViewSensorPointAttributesInRoller(permissions),
      Segment: FeatureFlags.canViewSensorPointAttributesInSegment(permissions),
      Nozzle: FeatureFlags.canViewSensorPointAttributesInNozzle(permissions),
    }
    const sensorPointLocations: any = FeatureFlagsUtil.getSensorPointLocations(paths, elementsHashes)
    const sensorPointPermissions = Object.keys(sensorPointViewPermissions)

    for (const permission of sensorPointPermissions) {
      if (sensorPointLocations[permission] && !((sensorPointViewPermissions as any)[permission])) {
        return false
      }
    }

    return true
  }

  static canViewDataPointAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_DataPoint] ?? false
  }

  static canViewDataLineAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_DataLine] ?? false
  }

  static canViewNozzleAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Nozzle] ?? false
  }

  static canToggleBending (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_Bending] ?? false
  }

  static canToggleSectionView (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_SectionView] ?? false
  }

  static canToggleRollerDetails (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_RollerDetails] ?? false
  }

  static canCenter3DPosition (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_CenterPosition] ?? false
  }

  static canDeselectAll (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_DeselectAll] ?? false
  }

  static canDeleteFilterWith3DButton (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_Filter] ?? false
  }

  static canViewSidesCube (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SidesCube_View] ?? false
  }

  static canToggleLiveMode (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_LiveMode] ?? false
  }

  static canViewTreeView (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_View] ?? false
  }

  static canViewMoldMinWidth (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionView_View_MoldMin] ?? false
  }

  static canViewMoldMaxWidth (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionView_View_MoldMax] ?? false
  }

  static canSelectSomeElement (permissions: Permissions = {}): boolean {
    return (
      this.canSelectDataLine(permissions) ||
      this.canSelectDataPoint(permissions) ||
      this.canSelectNozzle(permissions) ||
      this.canSelectRoller(permissions) ||
      this.canSelectRollerBearing(permissions) ||
      this.canSelectRollerBody(permissions) ||
      this.canSelectSensorPointInMoldFace(permissions) ||
      this.canSelectSensorPointInNozzle(permissions) ||
      this.canSelectSensorPointInRoller(permissions) ||
      this.canSelectSensorPointInRollerBearing(permissions) ||
      this.canSelectSensorPointInRollerBody(permissions) ||
      this.canSelectSensorPointInSegment(permissions)
    )
  }

  static canRenameElements (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_Rename_Elements] ?? false
  }

  static canJumpToElement (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_JumpToElement] ?? false
  }

  static canUseFilterBar (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_FilterBar_Use] ?? false
  }

  static canViewFilterBar (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_FilterBar_View] ?? false
  }

  static canFilterElements (permissions: Permissions = {}): boolean {
    return permissions[flags.FILTER_ELEMENTS] ?? false
  }

  static canSetFilterAndJumpToElement (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_SetFilterAndJumpToElement] ?? false
  }

  static canJumpOverNozzles (permissions: Permissions = {}): boolean {
    return (
      (permissions[flags.ThreeD_SectionSlider_JumpOver_Nozzles] ?? false) &&
      (permissions[flags.ThreeD_Caster_Display_Nozzle] ?? false)
    )
  }

  static canJumpOverRollers (permissions: Permissions = {}): boolean {
    return (
      (permissions[flags.ThreeD_SectionSlider_JumpOver_Roller] ?? false) &&
      (permissions[flags.ThreeD_Caster_Display_Roller] ?? false)
    )
  }

  static canJumpOverDataPoints (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionSlider_JumpOver_DataPoints] ?? false
  }

  static canJumpOverSomeElement (permissions: Permissions = {}): boolean {
    return (
      this.canJumpOverDataPoints(permissions) ||
      this.canJumpOverNozzles(permissions) ||
      this.canJumpOverRollers(permissions)
    ) ?? false
  }

  static canViewModules (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_View] ?? false
  }

  static canFilterPerModule (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_Filter_Module] ?? false
  }

  static canFilterPerModuleElementType (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_Filter_ModuleElementType] ?? false
  }

  static canVerifyCaster (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_Caster] ?? false
  }

  static canVerifyCatalogFile (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_NozzleCatalog] ?? false
  }

  static canVerifyProcessParameter (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_ProcessParameters] ?? false
  }

  static canStartSimulation (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Start_Simulation] ?? false
  }

  static canSeeHistory (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__View_CasterHistory] ?? false
  }

  static canGenerateCasterFile (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Use_GenerateCaster] ?? false
  }

  static canUploadNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Upload_NozzleCatalog] ?? false
  }

  static canDownloadNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Download_NozzleCatalog] ?? false
  }

  // TODO: after Menu_File_Use_DownloadCasterXML addition this isnt being used anymore
  static canDownloadCasterXML (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Download_CasterXML] ?? false
  }

  static canCreateProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Create_Project] ?? false
  }

  static canDeleteProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_Project] ?? false
  }

  static canRenameProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Rename_Project] ?? false
  }

  static canEditProjectDescription (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Edit_ProjectDescription] ?? false
  }

  static canCreateCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Create_Case] ?? false
  }

  static canDeleteCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_Case] ?? false
  }

  static canRenameCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Rename_Case] ?? false
  }

  static canEditCaseDescription (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Edit_CaseDescription] ?? false
  }

  static canDeleteNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_NozzleCatalog] ?? false
  }

  static canAddDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_DynamicData] ?? false)
  }

  static canEditDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_DynamicData] ?? false)
  }

  static canMergeDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Merge_DynamicData] ?? false)
  }

  static canDeleteDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_DynamicData] ?? false)
  }

  static canUseToggleEditMode (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_Use_ToggleEditMode] ?? false
  }

  static canViewToggleEditMode (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_View_ToggleEditMode] ?? false
  }

  static canUseExportPlots (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Use_ExportPlots] ?? false
  }

  static canViewExportPlots (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_View_ExportPlots] ?? false
  }

  static canUseCommandFileMapping (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_Use_CommandFileMapping] ?? false
  }

  static canViewCommandFileMapping (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_View_CommandFileMapping] ?? false
  }

  static canWorkOnDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    if (!permissions || typeof permissions !== 'object') {
      return false
    }

    return (
      this.canAddDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canEditDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canDeleteDynamicData(permissions, visualizationMetaInformation, appState)
    )
  }

  static canWorkOnMergedDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    if (!permissions || typeof permissions !== 'object') {
      return false
    }

    return (
      this.canMergeDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canEditDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canDeleteDynamicData(permissions, visualizationMetaInformation, appState)
    )
  }

  // After the addition of canViewSelectCasterDashboardMenuOption this is not used anymore
  static canViewCasterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_View] ?? false
  }

  static canDownloadCasterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Download] ?? false
  }

  static canCreateCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Create_Config] ?? false
  }

  static canEditCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Edit_Config] ?? false
  }

  static canDeleteCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Delete_Config] ?? false
  }

  static canAddPlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_Plot] ?? false)
  }

  static canResizePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Resize_Plot] ?? false)
  }

  static canMovePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Move_Plot] ?? false)
  }

  static canEditPlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_Plot] ?? false)
  }

  static canDeletePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_Plot] ?? false)
  }

  static canEditCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_Tab] ?? false)
  }

  static canAddCasterDashboardTab (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_Tab] ?? false)
  }

  static canDeleteCasterDashboardTab (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_Tab] ?? false)
  }

  static canUploadCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Upload_Config] ?? false
  }

  static canViewParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_View] ?? false
  }

  static canAddPlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Add_Plot] ?? false
  }

  static canDownloadParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Download] ?? false
  }

  static canDeleteParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Config] ?? false
  }

  static canCreateParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Create_Config] ?? false
  }

  static canMovePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Move_Plot] ?? false
  }

  static canResizePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Resize_Plot] ?? false
  }

  static canEditPlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Edit_Plot] ?? false
  }

  static canEditParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Edit] ?? false
  }

  static canAddParameterDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Add_Tab] ?? false
  }

  static canDeleteParameterDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Tab] ?? false
  }

  static canUploadParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Upload_Config] ?? false
  }

  static canDeletePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Plot] ?? false
  }

  static canUploadExecutableFile (permissions: Permissions = {}): boolean {
    return permissions[flags.Dialog_Run_UploadFile] ?? false
  }

  static canEditExecutableDialog (permissions: Permissions = {}): boolean {
    return permissions[flags.Dialog_Run_Edit] ?? false
  }

  static canViewResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_View] ?? false
  }

  static canAddPlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Add_Plot] ?? false
  }

  static canDownloadResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Download] ?? false
  }

  static canDeleteResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Config] ?? false
  }

  static canCreateResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Create_Config] ?? false
  }

  static canMovePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Move_Plot] ?? false
  }

  static canResizePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Resize_Plot] ?? false
  }

  static canEditPlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Edit_Plot] ?? false
  }

  static canEditResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Edit] ?? false
  }

  static canAddResultDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Add_Tab] ?? false
  }

  static canDeleteResultDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Tab] ?? false
  }

  static canUploadResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Upload_Config] ?? false
  }

  static canDeletePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Plot] ?? false
  }

  static canDeleteTabInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canDeleteCasterDashboardTab(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canDeleteParameterDashboardTab(permissions)) ||
      (appState === 2 && FeatureFlags.canDeleteResultDashboardTab(permissions))
    )
  }

  static canEditDashboardOptionsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (appState === 0 && FeatureFlags.canEditCasterDashboard(permissions, visualizationMetaInformation, appState)) ||
      (appState === 1 && FeatureFlags.canEditParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canEditResultDashboard(permissions))
    )
  }

  static canMovePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canMovePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canMovePlotsParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canMovePlotsResultDashboard(permissions))
    )
  }

  static canDownloadPlotImage (permission: Permissions = {}) {
    return permission[flags.CasterDashboard_Download_Plot] ?? false
  }

  static canResizePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canResizePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canResizePlotsParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canResizePlotsResultDashboard(permissions))
    )
  }

  static canAddPlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canAddPlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canAddPlotsParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canAddPlotsParameterDashboard(permissions))
    )
  }

  static canDeletePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canDeletePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canDeletePlotsParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canDeletePlotsResultDashboard(permissions))
    )
  }

  static canEditPlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: number,
  ) {
    return (
      (
        appState === 0 &&
        FeatureFlags.canEditPlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === 1 && FeatureFlags.canEditPlotsParameterDashboard(permissions)) ||
      (appState === 2 && FeatureFlags.canEditPlotsResultDashboard(permissions))
    )
  }

  static hasPermission (
    requirePermissions: string | Array<string>,
    permissions: Permissions = {},
  ): boolean {
    const authorizations = requirePermissions instanceof Array ? requirePermissions : [ requirePermissions ]

    return authorizations.reduce((acc: boolean, authorization: string) => {
      return acc && permissions[authorization]
    }, true)
  }

  static canViewSendToConsistencyCheckOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_SendCasterToConsistencyCheck]
  }

  static canUseSendToConsistencyCheckOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_SendCasterToConsistencyCheck]
  }

  static canViewCaster (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster__View] ?? false
  }

  static usesSlimVersion (permissions: Permissions = {}): boolean {
    return permissions[flags.SlimVersion] ?? false
  }

  // Vis
  static canEditVisualization (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Upload_Config] &&
    permissions[flags.ResultDashboard_Download] &&
    permissions[flags.ResultDashboard_Create_Config] &&
    permissions[flags.ResultDashboard_View]
  }

  static canResizeCasterDashboards (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (!!permissions[flags.CasterDashboard_Resize])
  }

  static canEditCasterComparison (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (!!permissions[flags.CasterDashboard_Edit_CasterComparison])
  }

  static canCompareCastersInCasterDialog (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Caster_Details_Compare]
  }

  static canViewCasterComparison (permissions: Permissions = {}): boolean {
    return !!permissions[flags.CasterDashboard_View_CasterComparison]
  }

  static canActivateFilterPlot (permissions: Permissions = {}): boolean {
    return !!permissions[flags.CasterDashboard_Filter_Plot]
  }

  static canUseFilterControls (permissions: Permissions = {}): boolean {
    return !!permissions[flags.ThreeD_FilterControls_Use]
  }

  // lockedBy
  static getLockedStatus (type: string, currentSimulationCase: any, authData: any):
  {isLocked: boolean, lockedBy: string | null} {
    // FIXME: This is a temporary fix to prevent false locking
    return { isLocked: false, lockedBy: null }
    // const { miscLockedBy, nozzlesLockedBy, rollersLockedBy } = currentSimulationCase
    // let isLocked = false
    // let lockedBy: string | null = null

    // switch (type) {
    //   case 'General':
    //   case 'SegmentGroup':
    //   case 'Segment':
    //     isLocked = Boolean(miscLockedBy !== (authData || {}).name || '')
    //     lockedBy = miscLockedBy
    //     break
    //   case 'Nozzle':
    //     isLocked = Boolean(nozzlesLockedBy !== (authData || {}).name || '')
    //     lockedBy = nozzlesLockedBy
    //     break
    //   case 'Roller':
    //   case 'RollerBody':
    //   case 'RollerBearing':
    //     isLocked = Boolean(rollersLockedBy !== (authData || {}).name || '')
    //     lockedBy = rollersLockedBy
    //     break
    //   default:
    //     break
    // }

    // return { isLocked, lockedBy }
  }

  static canViewOpenProjectMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_OpenProject]
  }

  static canUseOpenProjectMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_OpenProject]
  }

  static canViewCreateProjectMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_CreateProject]
  }

  static canUseCreateProjectMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_CreateProject]
  }

  static canViewUploadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_UploadCasterXML]
  }

  static canUseUploadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_UploadCasterXML]
  }

  static canViewUploadNozzleCatalogMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_UploadNozzleCatalog]
  }

  static canUseUploadNozzleCatalogMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_UploadNozzleCatalog]
  }

  static canViewDownloadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_DownloadCasterXML]
  }

  static canUseDownloadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_DownloadCasterXML]
  }

  static canViewLoadCasterMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_LoadCasterView]
  }

  static canUseLoadCasterMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_LoadCasterView]
  }

  static canViewSelectCasterDashboardMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_View_SelectCasterDashboard]
  }

  static canUseSelectCasterDashboardMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_File_Use_SelectCasterDashboard]
  }

  static canViewManageDynamicDataMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_Edit_View_ManageDynamicData]
  }

  static canUseManageDynamicDataMenuOption (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (!!permissions[flags.Menu_Edit_Use_ManageDynamicData])
  }

  static canViewToggleFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_View_ToggleFullScreen]
  }

  static canUseToggleFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_Use_ToggleFullScreen]
  }

  static canViewExitFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_View_ExitFullScreen]
  }

  static canUseExitFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_Use_ExitFullScreen]
  }

  static canViewRecreate3DMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_View_Recreate3D]
  }

  static canUseRecreate3DMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_View_Use_Recreate3D]
  }

  static canUseConfigurePlotsMenuOption (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (!!permissions[flags.Menu_Edit_Use_ConfigurePlots])
  }

  static canViewConfigurePlotsMenuOption (permissions: Permissions = {}): boolean {
    return !!permissions[flags.Menu_Edit_View_ConfigurePlots]
  }

  static isDashboardEditable (visualizationMetaInformation: any, appState: AppState) {
    const { config } = (visualizationMetaInformation || {})[appState] || {}

    return !(config || '').startsWith('group-')
  }
}
