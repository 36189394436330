import { AnalyzeTime } from 'Util'
import SegmentGroup from 'three/objects/SegmentGroup'
import Segment from 'three/objects/Segment'
import Nozzle from 'three/objects/Nozzle'
import Roller from 'three/objects/Roller'
import RollerBody from 'three/objects/RollerBody'
import RollerBearing from 'three/objects/RollerBearing'
import SensorPoint from 'three/objects/SensorPoint'
import DataPoint from 'three/objects/DataPoint'
import DataLine from 'three/objects/DataLine'
import SupportPoint from 'three/objects/SupportPoint'

export default class DrawUtil {
  @AnalyzeTime(0)
  static isContainer (type: CasterElementNames) {
    switch (type) {
      case 'SegmentGroup':
      case 'Segment':
        return true
      default:
        return false
    }
  }

  @AnalyzeTime(0)
  static getNewElementByType ( // TODO: improve typing
    type: string,
    parent: any,
    container: any,
    clickableObjects: any,
    tooltipObjects: any,
    sectionDetail: any,
    phantomGroup?: THREE.Group,
    featureFlags?: Record<string, boolean>,
  ) {
    switch (type) {
      case 'SegmentGroup':
        return new SegmentGroup(container, parent)
      case 'Segment':
        return new Segment(container, parent, clickableObjects, sectionDetail, featureFlags ?? {})
      case 'SupportPoint':
        return new SupportPoint(container, parent, clickableObjects)
      case 'Nozzle':
        return new Nozzle(container, parent, clickableObjects, tooltipObjects)
      case 'Roller':
        return new Roller(container, parent, clickableObjects, phantomGroup)
      case 'RollerBody':
        return new RollerBody(container, parent, clickableObjects, phantomGroup)
      case 'RollerBearing':
        return new RollerBearing(container, parent, clickableObjects, phantomGroup)
      case 'SensorPoint':
        return new SensorPoint(container, parent, clickableObjects, tooltipObjects)
      case 'DataPoint':
        return new DataPoint(container, parent, clickableObjects, tooltipObjects)
      case 'DataLine':
        return new DataLine(container, parent, clickableObjects, tooltipObjects)
      default:
        return null
    }
  }

  @AnalyzeTime(0)
  static isDrawable (type: string) {
    switch (type) {
      case 'SegmentGroup':
      case 'Segment':
      case 'SupportPoint':
      case 'Nozzle':
      case 'Roller':
      case 'RollerBearing':
      case 'RollerBody':
      case 'SensorPoint':
      case 'DataPoint':
      case 'DataLine':
        return true
      default:
        return false
    }
  }
}
