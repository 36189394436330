/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import cloneDeep from 'clone-deep'
import { v4 as uuid } from 'uuid'

import { AppState } from 'store/application/main/consts'
import * as ApplicationActions from '../../../../store/application/main/actions'
import * as ErrorActions from '../../../../store/application/error/actions'
import * as VisualizationActions from '../../../../store/visualization/actions'
import DataActions from '../../../../store/data/actions'

import IpcManager from '../../../../IpcManager'
import { Network } from '../../../../network/Network'
import ApiClient from '../../../../store/apiClient'
import FeatureFlags from '../../../FeatureFlags'
import TimeUtil from '../../../../logic/TimeUtil'
// TODO: move this file or its contents
import { Form } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import Button from '../../../components/Button'
import VerifyButton from '../../../components/VerifyButton'
import InfoMarker from '../../../components/InfoMarker'
import Input from '../../../specific/Input'

import EditConfigDialog from '../EditConfigDialog'

import { Title, Hr, Label } from './Styles'
import Logic from './Logic'
import { Spacer } from '../OpenProjectDialog/Styles'
import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectDataDialog.processingParameters'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  darkTheme: state.application.main.darkTheme,
  currentCatalogId: state.data.currentCatalogId,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  error: state.application.error,
  uploadLoading: state.loading.uploadLoading,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  resetReducer: DataActions.resetReducer,
  openDialog: ApplicationActions.openDialog,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditConfigId: ApplicationActions.setEditConfigId,
  setAppState: ApplicationActions.setAppState,
  setError: ErrorActions.setError,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onClose:(isClick?: any) => void
  onChange:(event: any) => void | {type: string, name: string, value: string}
  onDeleteConfig: (type: string, key: string)=> null | undefined
  processParametersButtonRef:any
  t: Translation & ArrayOfTranslations
}

type State = {
  loading: any,
  selectedConfig: string | undefined | null
};

export class ProcessParameters extends Component<Props, State> {
  state = {
    loading: {
      startProcessParameters: false,
      resetProcessParameters: false,
      openVis: false,
    },
    selectedConfig: null,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { visualizationMetaInformation } = this.props

    this.setState({
      selectedConfig: ((visualizationMetaInformation || {})[AppState.ParamDashboard] || {}).config,
    })
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props) {
    const { visualizationConfigList: oldVisualizationConfigList } = prevProps.currentSimulationCase

    if (!oldVisualizationConfigList) {
      return
    }

    const { visualizationConfigList } = this.props.currentSimulationCase

    const oldVisualizationParamConfigList = oldVisualizationConfigList
      .filter(config => config.appState === AppState.ParamDashboard)

    const visualizationParamConfigList = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.ParamDashboard)

    if (oldVisualizationParamConfigList.length !== visualizationParamConfigList.length) {
      this.setState({
        selectedConfig: (visualizationParamConfigList.slice(-1)[0] || {}).configId,
      })
    }
  }

  // @AnalyzeTime(0)
  handleVerifyCasterData = () => {
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    // FIXME: doesnt work inmediately, it needs a reload with ctrl + r
    ApiClient
      .patch(`${Network.URI}/simulation_case_verify/caster/${currentSimulationCase._id}`)
      .then(({ verificationList }) => {
        const simulationCase = cloneDeep(currentSimulationCase)

        simulationCase.casterVerifications = verificationList

        setCurrentSimulationCase(simulationCase)
      })
      .catch((response) => {
        this.setState({
          loading: { openCaster: false },
          // error: response.error.status,
        })
      })
  };

  // @AnalyzeTime(0)
  handleVerifyCatalogData = () => {
    const { currentSimulationCase, setCurrentSimulationCase, currentCatalogId } = this.props
    const selectedCatalog = currentCatalogId ||
    ((currentSimulationCase.casterCatalogList || [])[0] || {}).catalogId || 'default'

    ApiClient
      .patch(
        `${Network.URI}/simulation_case_verify/catalog/${currentSimulationCase._id}`,
        { data: { payload: selectedCatalog } },
      )
      .then(({ verificationList }) => {
        const simulationCase = cloneDeep(currentSimulationCase)

        simulationCase.catalogVerifications = verificationList

        setCurrentSimulationCase(simulationCase)
      })
      .catch((response) => {
        this.setState({
          loading: { openCaster: false },
          // error: response.error.status,
        })
      })
  };

  // @AnalyzeTime(0)
  handleVerifyProcessingParameters = () => {
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    ApiClient
      .patch(`${Network.URI}/simulation_case_verify/processing_parameters/${currentSimulationCase._id}`)
      .then(({ verificationList }) => {
        const simulationCase = cloneDeep(currentSimulationCase)

        simulationCase.processingParametersVerifications = verificationList

        setCurrentSimulationCase(simulationCase)
      })
      .catch((response) => {
        this.setState({
          loading: { startProcessParameters: false },
          // error: response.error.status,
        })
      })
  };

  // @AnalyzeTime(0)
  handleDownloadConfig = async (key: string) => {
    const { darkTheme, currentSimulationCase: { visualizationConfigList, commandFiles } } = this.props

    let assignedCommands

    if (commandFiles && commandFiles.length) {
      assignedCommands = commandFiles[0].assignedCommands
    }

    const { visualizationConfig } = await ApiClient.get(`${Network.URI}/visualization_config/${key}?rid=6`)

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme, assignedCommands }),
      (visualizationConfigList.filter(config => config.configId === key)[0] || {}).name,
    )
  };

  // @AnalyzeTime(0)
  handleEditConfig = (key?: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleOpenVisualization = async () => {
    const {
      setConfig,
      setAppState,
      currentSimulationCase,
      setCurrentSimulationCase,
      setError,
      onChange,
      onClose,
    } = this.props

    let config = this.getSelectedConfig()

    this.setState({
      loading: {
        openVis: true,
      },
    })

    setAppState(AppState.Caster)

    if (!config || config === 'default') {
      const formData = new FormData()
      const fileName = `config_${Date.now()}.json`

      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }

      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      formData.append('file', file)
      formData.append('simulationCaseId', currentSimulationCase._id)
      formData.append('appState', AppState.ParamDashboard.toString())

      const response = await ApiClient
        .post(`${Network.URI}/visualization_config`, { data: formData })
        .catch(({ status }) => {
          setError('openDashboard', status)
        })

      if (!response) {
        this.setState({ loading: { openVis: false } })

        return
      }

      setCurrentSimulationCase(response.simulationCase)

      config = response.visualizationConfigId
    }

    onChange({ target: { name: 'config', value: config } })

    const { visualizationConfig } = await ApiClient
      .get(`${Network.URI}/visualization_config/${config}?rid=7`)
      .catch((error) => {
        setError('openDashboard', error.status)

        return {}
      })

    if (!visualizationConfig) {
      this.setState({ loading: { openVis: false } })

      return
    }

    setAppState(AppState.ParamDashboard)
    setConfig(visualizationConfig.data)
    onClose()
  };

  // @AnalyzeTime(0)
  handleChangeConfig = (event: any) => {
    const { onChange } = this.props
    const { value } = event.target

    if (value === 'add') {
      return onChange({ target: { name: 'config', value: 'add' } })
    }

    this.setState({
      selectedConfig: value,
    })
  };

  // @AnalyzeTime(0)
  getSelectedConfig = () => {
    const { selectedConfig } = this.state
    const { currentSimulationCase: { visualizationConfigList } } = this.props
    const configs = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.ParamDashboard)

    return Logic.getSelectedConfig(selectedConfig, configs)
  };

  @AnalyzeTime(0)
  render () {
    const { loading } = this.state
    const {
      t,
      currentCatalogId,
      currentSimulationCase,
      error,
      featureFlags,
      uploadLoading,
      onDeleteConfig,
      processParametersButtonRef,
    } = this.props

    const {
      casterFileName,
      processingParametersVerifications,
      casterVerifications,
      catalogVerifications,
      casterCatalogList,
    } = currentSimulationCase

    const lastCasterVerification = (casterVerifications || []).slice(-1)[0]
    const lastProcessParametersVerification = (processingParametersVerifications || []).slice(-1)[0]

    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, casterCatalogList)

    const lastCatalogVerification = (catalogVerifications || [])
      .filter(verification => verification.payload === selectedCatalog).slice(-1)[0]

    const isCasterVerified = Boolean(lastCasterVerification && lastCasterVerification.isVerified)
    const isProcessParametersVerified = Boolean(lastProcessParametersVerification &&
      lastProcessParametersVerification.isVerified)
    const isCatalogVerified = Boolean(lastCatalogVerification && lastCatalogVerification.isVerified)

    const visualizationEditor = FeatureFlags.canEditVisualization(featureFlags)
    const canUploadParameterDashboardConfig = FeatureFlags.canUploadParameterDashboardConfig(featureFlags)
    const canCreateParameterDashboardConfig = FeatureFlags.canCreateParameterDashboardConfig(featureFlags)

    const defaultSelector = canCreateParameterDashboardConfig
      ? {
        value: t(`projectDataDialog.visualization.config.${visualizationEditor ? 'default' : 'viewerDefault'}`),
        disabled: false,
      }
      : null

    const configSelectors = Logic.getSelectors(
      (currentSimulationCase.visualizationConfigList || [])
        .filter(config => config.appState === AppState.ParamDashboard),
      'configId',
      'name',
      defaultSelector,
    )

    const processParametersDisabled = (configSelectors.length - (defaultSelector ? 1 : 0)) === 0

    if (canUploadParameterDashboardConfig) {
      configSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`projectDataDialog.visualization.config.add`),
        disabled: !visualizationEditor,
      })
    }

    const casterDisabled = !casterFileName || !casterFileName.length || uploadLoading.CasterDataIsLoading
    const catalogDisabled = !casterCatalogList || !casterCatalogList.length || uploadLoading.CasterDataIsLoading

    return (
      <Form maxWidth='300px' padding='18px 28px 20px'>
        <Title>
          {t(`${PRE_TRANS}.title`)}
          <InfoMarker message={t(`${PRE_TRANS}.info`, { returnObjects: true }).join('\n')} x={224} y={-23} />
        </Title>
        <Label>{t(`${PRE_TRANS}.verify.label`)}</Label>
        <VerifyButton
          label={t(`${PRE_TRANS}.verify.caster.label`)}
          title={
            isCasterVerified
              ? t(
              `${PRE_TRANS}.verify.caster.verified`,
              {
                name: lastCasterVerification.authorName,
                dateTime: TimeUtil.getDisplayDateTime(lastCasterVerification.createdAt),
              },
              )
              : (casterDisabled
                ? t(`${PRE_TRANS}.verify.caster.disabled`)
                : t(`${PRE_TRANS}.verify.caster.title`)
              )
          }
          isVerified={isCasterVerified}
          disabled={casterDisabled || !FeatureFlags.canVerifyCaster(featureFlags)}
          onChange={this.handleVerifyCasterData}
        />
        <VerifyButton
          label={t(`${PRE_TRANS}.verify.catalog.label`)}
          title={
            isCatalogVerified
              ? t(
              `${PRE_TRANS}.verify.catalog.verified`,
              {
                name: lastCatalogVerification.authorName,
                dateTime: TimeUtil.getDisplayDateTime(lastCatalogVerification.createdAt),
              },
              )
              : (catalogDisabled
                ? t(`${PRE_TRANS}.verify.catalog.disabled`)
                : t(`${PRE_TRANS}.verify.catalog.title`)
              )
          }
          isVerified={isCatalogVerified}
          disabled={catalogDisabled || !FeatureFlags.canVerifyCatalogFile(featureFlags)}
          onChange={this.handleVerifyCatalogData}
        />
        <VerifyButton
          label={t(`${PRE_TRANS}.verify.processingParameters.label`)}
          title={
            isProcessParametersVerified
              ? t(
              `${PRE_TRANS}.verify.processingParameters.verified`,
              {
                name: lastProcessParametersVerification.authorName,
                dateTime: TimeUtil.getDisplayDateTime(lastProcessParametersVerification.createdAt),
              },
              )
              : t(`${PRE_TRANS}.verify.processingParameters.title`)
          }
          isVerified={isProcessParametersVerified}
          onChange={this.handleVerifyProcessingParameters}
          disabled={processParametersDisabled || !FeatureFlags.canVerifyProcessParameter(featureFlags)}
        />
        <Spacer h={49} br />
        <Hr />
        <Input
          name='config'
          type='select'
          title={
            error.config
              ? t([ `error.${error.config}`, 'error.default' ])
              : t(`projectDataDialog.visualization.config.title`)
          }
          error={error.config && t([ `error.${error.config}`, 'error.default' ])}
          value={this.getSelectedConfig()}
          selectors={configSelectors}
          onChange={this.handleChangeConfig}
          onDelete={FeatureFlags.canDeleteParameterDashboardConfig(featureFlags) && onDeleteConfig}
          onDownload={FeatureFlags.canDownloadParameterDashboard(featureFlags) && this.handleDownloadConfig}
          onEdit={FeatureFlags.canEditParameterDashboard(featureFlags) && this.handleEditConfig}
        />
        <Button
          onClick={this.handleOpenVisualization}
          loading={loading.openVis}
          error={error.openDashboard ? t([ `error.${error.openDashboard}`, 'error.default' ]) : ''}
          title={
            error.openDashboard
              ? t([ `error.${error.openDashboard}`, 'error.default' ])
              : t(`${PRE_TRANS}.openDashboard.title`)
          }
          hideError
          ref={processParametersButtonRef}
          disabled={!FeatureFlags.canViewParameterDashboard(featureFlags)}
        >
          {t(`${PRE_TRANS}.openDashboard.label`)}
        </Button>
      </Form>
    )
  }
}

export default withSnackbar(withNamespaces('application')(connector(ProcessParameters as any) as any) as any) as any
