import { DEFINITION, DefinitionKey } from '../../type/consts'
import PasslineCurve from '../../../three/objects/PasslineCurve'
import { getNewIdForType } from 'store/elements/logic'

export function handleDirtyDeletePaths (dirtyDeletePaths: string[], paths: string[]) {
  // remove path from list if already in list
  // add to list otherwise
  paths.forEach((path) => {
    const index = dirtyDeletePaths.indexOf(path)

    if (~index) {
      dirtyDeletePaths.splice(index, 1)
    }
    else {
      dirtyDeletePaths.push(path)
    }
  })
}

export function handleUndoChanges (editElements: any, action: any, editElementsInitial: any) {
  for (const path in editElements) {
    if (path.includes(action.elementType)) {
      if (action.key) {
        editElements[path][action.key] = editElementsInitial[path][action.key]
      }
      else {
        editElements[path] = { ...editElementsInitial[path] }
      }
    }
  }
}

export function _hasChildren (type: string): boolean {
  switch (type) {
    case 'SegmentGroup':
    case 'Segment':
    case 'Roller':
      return true
    default:
      return false
  }
}

export function _shouldBeArray (type: string): boolean {
  switch (type) {
    case 'CoolingZone':
    case 'SegmentGroup':
    case 'Segment':
    case 'SupportPoint':
    case 'Nozzle':
    case 'Roller':
    case 'RollerBody':
    case 'RollerBearing':
      return true
    default:
      return false
  }
}

export function _makeArray (parentObject: any, key: string): void {
  parentObject[key] = [ parentObject[key] ]
}

export function _cleanArray (array: any[]) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (!array[i]) {
      array.splice(i, 1)
    }
  }
}

export function _prepareObject (parentObject: any, key: string): void {
  if (_shouldBeArray(key) && !(parentObject[key] instanceof Array)) {
    _makeArray(parentObject, key)
    _cleanArray(parentObject[key])
  }
}

// TODO: make sure this works with the index/id stuff
export function _addAllElementsFromNew (data: any, newData: any, dataTypes: string[]) {
  if (data instanceof Array) {
    data.forEach((element, index) => {
      if (newData && newData[index]) {
        _addAllElementsFromNew(element, newData[index], dataTypes)
      }
    })
  }
  else {
    const keys = Object.keys(newData)

    for (let name = 0; name < keys.length; name++) {
      if (keys[name] === 'Segment' || keys[name] === 'SegmentGroup') {
        _addAllElementsFromNew(data[keys[name]], newData[keys[name]], dataTypes)
      }

      dataTypes.forEach(dataType => {
        if (keys[name] === dataType) {
          newData[keys[name]] = newData[keys[name]] instanceof Array ? newData[keys[name]] : [ newData[keys[name]] ]

          const newElements = newData[keys[name]].filter((element: any) => {
            if (Number(element._passln_coord) <= PasslineCurve.plHeight * 1000) {
              return element
            }

            return null
          })

          if (!data[keys[name]]) {
            data[keys[name]] = []
          }

          data[keys[name]].push(...newElements)

          data[keys[name]].sort((a: any, b: any) => {
            const PassLnA = Number(a._passln_coord)
            const PassLnB = Number(b._passln_coord)

            if (PassLnA > PassLnB) {
              return 1
            }

            if (PassLnA < PassLnB) {
              return -1
            }

            return 0
          })
        }
      })
    }
  }
}

export function addAllElementsFromNew (data: any, newData: any, dataTypes: string[]) {
  _addAllElementsFromNew(data.root.Caster.StrandGuide, newData.root.Caster.StrandGuide, dataTypes)
}

export function updateList (list: Array<string>, element: string, massSelect: boolean, multiSelect: boolean) {
  const index = list.indexOf(element)
  const length = list.length

  if (massSelect) {
    if (~index) {
      list = [ ...[ element ] ]
    }
    else {
      if (length < 1) {
        list.push(element)
      }
      else {
        const cutFirst = list[0].length - list[0].lastIndexOf(':') - 1 // TODO: does this work with ids?
        const cutLast = element.length - element.lastIndexOf(':') - 1

        const first = Number(list[0].substr(list[0].length - cutFirst))
        const last = Number(element.substr(element.length - cutLast))

        const min = Math.min(last, first)
        const max = Math.max(last, first)

        for (let i = min; i <= max; i++) {
          list.push(element.slice(0, -cutLast) + i)
        }

        list = list.filter((element: string, index: number) => list.lastIndexOf(element) === index)
      }
    }
  }
  else {
    if (!multiSelect) {
      list = []

      if (length > 1 || !~index) {
        list.push(element)
      }
    }
    else {
      if (~index) {
        list.splice(index, 1)
      }
      else {
        list.push(element)
      }
    }
  }

  return list
}

export function addPathsIfNotAlreadyInList (list: string[], paths: string[]) {
  paths.forEach(path => {
    if (!list.includes(path)) {
      list.push(path)
    }
  })
}

export function updateListWithSelectedData (
  list: string[],
  selectedData: string[],
  massSelect = false,
  multiSelect = false,
) {
  selectedData.forEach(element => {
    list = updateList(list, element, massSelect, multiSelect)
  })

  return list
}

export function ensureActionPathIsArray (action: any) {
  return action.path instanceof Array ? action.path : [ action.path ]
}

export function parseXmlData (data: any, elementData?: any) {
  const element = elementData || data.root.Caster.StrandGuide
  const keys = Object.keys(element)

  const fullSegment: any = [
    { _FixedLooseSide: 'FixedSide' },
    { _FixedLooseSide: 'LooseSide' },
    { _FixedLooseSide: 'NarrowFaceLeft' },
    { _FixedLooseSide: 'NarrowFaceRight' },
  ]

  for (let n = 0; n < keys.length; n++) {
    _prepareObject(element, keys[n])

    if (keys[n] === 'Segment' && element[keys[n]].length < 4) {
      const segment = [ ...fullSegment ]

      let index = 0

      fullSegment.forEach((seg: any) => {
        index = segment.indexOf(seg)

        let newElement = {}

        element[keys[n]].forEach((element: any) => {
          if (element._FixedLooseSide === seg._FixedLooseSide) {
            newElement = element
          }
        })

        segment[index] = Object.keys(newElement).length ? newElement : segment[index]

        if (segment[index]._id === undefined) {
          const newId = getNewIdForType(data, 'Segment')

          segment[index] = {
            ...element[keys[n]][0],
            ...seg,
            Nozzle: [],
            Roller: [],
            ...segment[index],
            _id: newId,
            _numericId: newId,
          }
        }
      })

      element[keys[n]] = segment
    }

    if (keys[n] === 'Segment') {
      const list = element[keys[n]]

      for (let i = 0; i < list.length; i++) {
        list[i].Nozzle = list[i].Nozzle || []
        list[i].Roller = list[i].Roller || []
      }
    }

    if (_hasChildren(keys[n])) {
      for (let i = 0; i < element[keys[n]].length; i++) {
        parseXmlData(data, element[keys[n]][i])
      }
    }
  }
}

export function setEditValuesForEachType (stateEditValues: any, action: any, types: DefinitionKey[]) {
  let editValues = {
    ...stateEditValues,
  }

  types.forEach(type => {
    Object.keys(DEFINITION[type].fields).forEach(field => {
      editValues = {
        ...editValues,
        [type]: {
          ...editValues[type],
          ...action.editValues[type], // TODO: this may override [field] from a previous iteration of the inner loop
          [field]: action.editValues[type][field],
        },
      }
    })
  })

  return editValues
}
