/* eslint-env browser */

import { Network } from './Network'
import Codes from './Codes'
import CalcUtil from './CalcUtil'
import { Definition } from 'types/visualization'
import { Info } from 'logic/Info'

export enum NetworkStatusEnum {
  CONNECTED= 'connected',
  CONNECTING= 'connecting',
  DISCONNECTED= 'disconnected',
}
export default class NetworkManager {
  static _registeredPlots: { [key: string]: any } = {}
  static _nextId = 1
  static _shouldBeConnected = false
  static _previousStatus: NetworkStatusEnum | null = null
  static _wasDataSourceServer: boolean | null = null
  static _isDataSourceServer = false
  static authCallback: any
  static checkInterval:number | NodeJS.Timer

  static statusCallback (status: NetworkStatusEnum) {
    // empty
  }

  static connectCallback () {
    // empty
  }

  static get wasDataSourceServer () {
    return NetworkManager._wasDataSourceServer
  }

  static get isDataSourceServer () {
    return NetworkManager._isDataSourceServer
  }

  static set isDataSourceServer (value) {
    NetworkManager._wasDataSourceServer = NetworkManager._isDataSourceServer

    NetworkManager._isDataSourceServer = value
  }

  static __data (rawData: any, registeredPlot: any) {
    const dataView = new DataView(rawData)

    // const time = dataView.getUint32(0) // start at 0 using 4 byte next index is 4
    // const id = dataView.getUint16(4) // start at 4 using 2 byte next index is 6
    // const number = dataView.getUint16(6) // start at 6 using 2 byte next index is 8
    const definitionCount = dataView.getUint8(8) // start at 8 using 1 byte next index is 9

    const start = 9
    const step = 3 // 2 + 1 bytes
    const end = start + step * definitionCount // 7 + 5 * definitionCount

    const plotDefinition = registeredPlot.data
    const definitions: Definition[] = []

    for (let i = start; i < end; i += step) {
      definitions.push({
        id: plotDefinition.plotIds[(i - start) / step],
        length: dataView.getUint16(i), // start at i using 2 byte next index is i + 2
        bytes: dataView.getUint8(i + 2), // start at i + 4 using 1 byte next index is i + 3 (which is the next step)
      })
    }

    const scale = CalcUtil._getDataScale(plotDefinition)
    const { min } = CalcUtil.getMinMax(plotDefinition.valueRange)
    let dataCursor = end // dataCursor starts after end

    for (let j = 0; j < definitions.length; j++) {
      const definition = definitions[j]

      definition.data = []

      let getNextValue = (n: number) => 0

      switch (definition.bytes) {
        case 1: getNextValue = dataView.getUint8.bind(dataView)
          break
        case 2: getNextValue = dataView.getUint16.bind(dataView)
          break
        case 4: getNextValue = dataView.getFloat32.bind(dataView)
          break
        default:
      }

      for (let i = 0; i < definition.length; i++) {
        definition.data.push(getNextValue(dataCursor) * scale + min)

        dataCursor += definition.bytes || 0
      }
    }

    return {
      tileId: plotDefinition.tileId,
      definitions,
    }
  }

  static _done (id: number, number: number) {
    Network.sendCode(Codes.OPC_C_CONFIRM_MESSAGE_RECEIVED, { id, number })
  }

  static _data (rawData: any) {
    const dataView = new DataView(rawData)
    const id = dataView.getUint16(4)
    const number = dataView.getUint16(6)
    const registeredPlot = Object.values(NetworkManager._registeredPlots).filter((plot: any) =>
      plot.id === id)[0] as any

    if (registeredPlot) {
      const data = NetworkManager.__data(rawData, registeredPlot)

      registeredPlot.callbackList.forEach((callback: (data: any) => void) => {
        if (callback) {
          callback(data)
        }
      })

      NetworkManager._done(id, number)
    }
  }

  static updateStatus (status: NetworkStatusEnum) {
    NetworkManager._previousStatus = status

    NetworkManager.statusCallback(status)
  }

  static _checkStatus () {
    const readyState = Network.webSocket && Network.webSocket.readyState

    if (!NetworkManager._shouldBeConnected && (!readyState || readyState !== WebSocket.OPEN)) {
      return NetworkManager.updateStatus(NetworkStatusEnum.DISCONNECTED)
    }

    if (NetworkManager._shouldBeConnected && readyState === WebSocket.CONNECTING) {
      return NetworkManager.updateStatus(NetworkStatusEnum.CONNECTING)
    }

    if (NetworkManager._shouldBeConnected && readyState === WebSocket.OPEN) {
      if (NetworkManager._previousStatus === NetworkStatusEnum.CONNECTING) {
        NetworkManager.reRegisterPlots()
      }

      return NetworkManager.updateStatus(NetworkStatusEnum.CONNECTED)
    }

    NetworkManager.connect(NetworkManager.connectCallback)

    NetworkManager.updateStatus(NetworkStatusEnum.CONNECTING)
  }

  static _authCallback (data: any) {
    NetworkManager.authCallback(data)
  }

  static hasChanges (newData: any) {
    const oldData = (NetworkManager._registeredPlots as {[key: string]: any})[newData.tileId]

    if (!oldData || !oldData.data) {
      return true
    }

    return !oldData.data.equals(newData)
  }

  static init ({
    handleNetworkStatus: statusCallback,
    handleMetaData: metaCallback,
    handleNotAuthenticated: notAuthCallback,
    updateNotificationCallback,
    updatePushCallback,
    lockChangeCallback,
    simulationUpdateCallback,
    onCloseCallback,
    onOpenCallback,
    generateDoneCallback,
    executionDoneCallback,
  }: {
    handleNetworkStatus: typeof NetworkManager.statusCallback
    handleMetaData: (meta: any) => void
    handleNotAuthenticated: () => void
    updateNotificationCallback: ({ updatesCount }: { updatesCount:number }) => void
    updatePushCallback: ({ changes }: {changes: Array<{action: string, elementType: string, data:any}>}) => void
    lockChangeCallback: () => void
    simulationUpdateCallback: ({ simulationCaseId, state }:
      {simulationCaseId: string, state: 'done' | 'data' }) => void
    onCloseCallback: () => void
    onOpenCallback: () => void
    generateDoneCallback: ({ simulationCaseId, errorType }: { simulationCaseId: string, errorType?: string }) => void
    executionDoneCallback: (
      { simulationCaseId, definitionId, caseId, errorType, chosenCaseOptionIndex, userName }:
        {
          simulationCaseId: string
          definitionId: string
          caseId: string
          errorType?: string
          chosenCaseOptionIndex: number
          userName: string
        }
    ) => void
  }) {
    NetworkManager.statusCallback = statusCallback

    NetworkManager.checkInterval = setInterval(NetworkManager._checkStatus, 1000)

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    NetworkManager.authCallback = () => {}

    Network.init(
      NetworkManager._data,
      metaCallback,
      NetworkManager._authCallback,
      notAuthCallback,
      updateNotificationCallback,
      updatePushCallback,
      lockChangeCallback,
      simulationUpdateCallback,
      onCloseCallback,
      onOpenCallback,
      generateDoneCallback,
      executionDoneCallback,
    )
  }

  static connect (connectCallback: any) {
    NetworkManager.connectCallback = connectCallback

    Network.connect(connectCallback)

    NetworkManager._shouldBeConnected = true
  }

  static disconnect () {
    Network.disconnect()

    NetworkManager._shouldBeConnected = false
  }

  static setPlot (data: any, callback: (data: any) => void) {
    const { plotIds, tileId } = data

    if ((plotIds || plotIds === 0) && !(plotIds instanceof Array)) {
      data.plotIds = [ plotIds ]
    }

    const oldId = NetworkManager._registeredPlots[tileId]
      ? NetworkManager._registeredPlots[tileId].id
      : 0

    data.id = oldId || NetworkManager._nextId

    // if there is no changes return
    if (!NetworkManager.hasChanges(data)) {
      return
    }

    NetworkManager._registeredPlots[tileId] = {
      ...NetworkManager._registeredPlots[tileId],
      data,
      callbackList: [
        ...(NetworkManager._registeredPlots[tileId] || {}).callbackList || [],
        callback,
      ],
      id: data.id,
      // registeredCount: ((NetworkManager._registeredPlots[tileId] || {}).registeredCount || 0) + 1,
    }

    if (!oldId) {
      NetworkManager._nextId++
    }

    if (!NetworkManager._isDataSourceServer) {
      return
    }

    Network.sendCode(Codes.OPC_C_SET_PLOT_DEFINITION, data)
  }

  static removePlot (tileId: string) {
    const plot = NetworkManager._registeredPlots[tileId]

    if (!plot) {
      return
    }

    // if (plot && plot.registeredCount > 1) {
    //   plot.registeredCount--

    //   return
    // }

    const id = plot.id

    delete NetworkManager._registeredPlots[tileId]

    Network.sendCode(Codes.OPC_C_REMOVE_PLOT_DEFINITION, id)
  }

  static reRegisterPlots () {
    if (!NetworkManager._isDataSourceServer) {
      return
    }

    Network.sendCode(Codes.OPC_C_GET_META)

    Object.values(NetworkManager._registeredPlots).forEach(plot => {
      Network.sendCode(Codes.OPC_C_SET_PLOT_DEFINITION, plot.data)
    })
  }

  static unRegisterPlots () {
    Object.values(NetworkManager._registeredPlots).forEach(plot => {
      Network.sendCode(Codes.OPC_C_REMOVE_PLOT_DEFINITION, plot.id)
    })
  }

  static authenticateUser (
    userName: string | null,
    password: string | null,
    isLocalLogin: boolean,
    featureFlags: Record<string, boolean>,
    callback: (data: AuthData) => void,
  ) {
    // if login is with local server, toggle Network's use local URI to true
    if (isLocalLogin) {
      Network.toggleUseLocalURI(true)
      Network.sendCode(Codes.OPC_C_AUTHENTICATE_LOCAL_USER, { featureFlags })
    }
    else {
      Network.toggleUseLocalURI(false)
      Network.sendCode(Codes.OPC_C_AUTHENTICATE_USER, { userName, password })
    }

    NetworkManager.authCallback = callback
  }

  static authenticateViaJWT (accessToken: string, callback: (data: AuthData) => void) {
    Network.toggleUseLocalURI(false)
    Network.sendCode(Codes.OPC_C_AUTHENTICATE_VIA_JWT, { accessToken })

    NetworkManager.authCallback = callback
  }

  static logout () {
    localStorage.removeItem('tokenData')

    Info.removeRecentlyUsedInfo()

    Network.sendCode(Codes.OPC_C_LOGOUT_USER)
  }

  static requestUpdates (simulationCaseId: string) {
    Network.sendCode(Codes.OPC_C_UPDATE_GET, simulationCaseId)
  }

  static toggleLiveMode (liveMode: boolean) {
    Network.sendCode(Codes.OPC_C_SET_LIVE_MODE, liveMode)
  }
}
