import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'clone-deep'

import Button from '../../components/Button'
import { Form } from '../../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../../specific/Input'

import * as ApplicationActions from '../../../store/application/main/actions'

import BaseDialog from '../BaseDialog'
import ApiClient from '../../../store/apiClient'
import { Network } from '../../../network/Network'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  editConfigId: state.application.main.editConfigId,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string | boolean
  name: string,
  error: string,
  loading: boolean
};

export class EditConfigDialog extends Component<Props, State> {
  static NAME = uuid()

  didInit = false;

  state = {
    name: '',
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleInit()

    hotkeys('Escape', this.handleClose as any)
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleInit()
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
  }

  // @AnalyzeTime(0)
  handleInit = () => {
    const { editConfigId, currentSimulationCase } = this.props

    if (this.didInit || !currentSimulationCase) {
      return
    }

    this.didInit = true
    let name = ''

    const config = currentSimulationCase.visualizationConfigList.find(config => config.configId === editConfigId)

    if (config) {
      name = config.name
    }

    this.setState({
      name,
    })
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog, setEditConfigId } = this.props

    closeDialog(EditConfigDialog.NAME)
    setEditConfigId()
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value }:{ name: string, value: string} = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name } = this.state
    const { editConfigId, currentSimulationCase, setCurrentSimulationCase } = this.props

    if (name) {
      this.setState(({
        loading: true,
      }))

      ApiClient
        .patch(`${Network.URI}/visualization_config/edit/${editConfigId || ''}`, { data: { name } })
        .then(() => {
          if (!currentSimulationCase) {
            return
          }

          const simulationCase = cloneDeep(currentSimulationCase)
          const config = simulationCase.visualizationConfigList.find(config => config.configId === editConfigId)

          if (config) {
            config.name = name

            setCurrentSimulationCase(simulationCase)
          }

          this.handleClose()
        })
        .catch((response) => {
          const { error } = response

          this.setState(({
            error: error ? error.status : 'unknown',
            loading: false,
          }))
        })
    }
  };

  @AnalyzeTime(0)
  render () {
    const { name, error, loading } = this.state
    const { t } = this.props

    return (
      <BaseDialog
        title={t('editConfigDialog.title')}
        icon='pe-7s-folder'
        header={t('editConfigDialog.header')}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t('editConfigDialog.name.label')}
            title={t('editConfigDialog.name.label')}
            placeholder={t('editConfigDialog.name.placeholder')}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            type='primary'
            disabled={!(name.length > 0)}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            isRef
          >
            {t('editConfigDialog.save')}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t('editConfigDialog.cancel')}
          >
            {t('editConfigDialog.cancel')}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(EditConfigDialog as any) as any) as any
