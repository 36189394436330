import { ApplicationMainActionsEnum as Actions } from '../consts'

const updateReducers = {
  [Actions.ACTION_UPDATE_CURRENT_PROJECT_SCHEMA]: (state: ApplicationMainState, { containerName, data }:
    {containerName: string, data:any}) => ({
    ...state,
    currentSimulationCase: {
      ...state.currentSimulationCase,
      [containerName]: data,
    },
  }),

  [Actions.ACTION_UPDATE_HISTORY]: (state: ApplicationMainState, { newHistory }: any) => ({
    ...state,
    currentSimulationCase: {
      ...state.currentSimulationCase,
      history: [ ...state.currentSimulationCase.history, ...newHistory ],
    },
  }),
}

export default updateReducers
