import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import { FromCompareCasterField } from '../InputStyles'

import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import InputField from './InputField'

const connector = connect((store: DefaultState) => ({
  term: store.filter.term,
  amountOfComparisonCasterColumns: store.visualization.amountOfComparisonCasterColumns,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideValue?: boolean
  name: string,
  label: any
  elementType: string,
  value: string,
  t: Translation
  key: any
  elementKey?: string,
  error?: boolean,
}

type State = {
  noMass?: boolean
};

export class CompareCasterInput extends Component<Props, State> {
  textInput: any = null;

  state = {
    noMass: true,
  }

  static getDerivedStateFromProps (nextProps: Props) {
    return {
      noMass: nextProps.error,
    }
  }

  setTextInputRef = (element: any) => {
    this.textInput = element
  };

  focusTextInput = () => {
    if (this.textInput) {
      this.textInput.focus()
      this.textInput.select()
    }
  };

  render () {
    const {
      value,
      name,
      hideValue,
      label,
      t,
    } = this.props
    const { noMass } = this.state
    const mass = noMass === false
    const massValue = mass ? 'multiple Values' : value
    const placeholder = (mass && !hideValue) ? 'Multi-Value' : (typeof label === 'string' ? label : name.substring(1))

    return (
      <FromCompareCasterField>
        <InputField
          isDisabled
          massValue={hideValue ? undefined : massValue ?? ''}
          placeholder={placeholder}
          onChange={() => null}
          name={t(name)}
          inputRef={this.setTextInputRef}
          style={
            {
              maxWidth: '57px',
              paddingRight: '3px',
              marginRight: '10px',
            }
          }
        />
      </FromCompareCasterField>
    )
  }
}
// TODO: fix export type
export default withNamespaces('caster')(connector(CompareCasterInput as any) as any) as any
