export enum loopAssignmentActionsEnum {
  ACTION_ADD_LOOP_ASSIGNAMENT = 'LoopAssignment/ACTION_ADD_LOOP_ASSIGNAMENT',
  DELETE = 'LoopAssignment/DELETE',
  DELETE_MULTIPLE = 'LoopAssignment/DELETE_MULTIPLE',
  UPDATE = 'LoopAssignment/UPDATE',
}

export function addLoopAssignment (loopAssignmentHash: Record<number, LoopAssignment & Parent>) {
  return {
    type: loopAssignmentActionsEnum.ACTION_ADD_LOOP_ASSIGNAMENT,
    loopAssignmentHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const loopAssignmentReducers: Record<string, Function | undefined> = {
  [loopAssignmentActionsEnum.ACTION_ADD_LOOP_ASSIGNAMENT]:
  (_state: LoopAssignmentHash, action: {loopAssignmentHash: LoopAssignmentHash}) => (
    action.loopAssignmentHash
  ),

  [loopAssignmentActionsEnum.DELETE]: (state: CoolingZoneHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [loopAssignmentActionsEnum.DELETE_MULTIPLE]: (state: CoolingZoneHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [loopAssignmentActionsEnum.UPDATE]: (state: LoopAssignmentHash, action: {elements: LoopAssignmentHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (
  state: LoopAssignmentHash = {},
  action: any,
) {
  const handler = loopAssignmentReducers[action.type]

  return handler ? handler(state, action) : state
}
