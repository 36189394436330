import { VisualizationActionsEnum as VisActionsEnum, initialState, VisualizationAction as VisAction } from './consts'

import autoscaleReducers from './reducers/autoscaleReducers'
import contextMenuReducers from './reducers/contextMenuReducers'
import dashboardReducers from './reducers/dashboardReducers'
import dataReducers from './reducers/dataReducers'
import dialogReducers from './reducers/dialogReducers'
import hdf5Reducers from './reducers/hdf5Reducers'
import plotReducers from './reducers/plotReducers'
import viewReducers from './reducers/viewReducers'

import { VisualizationState } from 'types/visualization'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  ...autoscaleReducers,

  ...contextMenuReducers,

  ...dashboardReducers,

  ...dataReducers,

  ...dialogReducers,

  ...hdf5Reducers,

  ...plotReducers,

  ...viewReducers,

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING]: (state: VisualizationState) => ({
    ...state,
    isCommandMappingOpen: !state.isCommandMappingOpen,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_EDIT_MODE]: (state: VisualizationState, { mode }: VisAction) => ({
    ...state,
    isEditModeOn: mode !== undefined ? mode : !state.isEditModeOn,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_LOADING_BUTTON_STATUS]: (state:VisualizationState, { status, buttonType }:
    { status:boolean, buttonType: string}) => {
    if (!buttonType && !status) {
      return ({
        ...state,
        loadingStatus: {},
      })
    }

    return ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        [buttonType]: status,
      },
    })
  },

  [VisActionsEnum.ACTION_STORE_NETWORK_STATUS]: (state: VisualizationState, { status }: VisAction) => ({
    ...state,
    networkStatus: status,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_DASHBOARD_WIDTH]:
  (state: VisualizationState, { width }: VisAction) => ({
    ...state,
    currentDashboardWidth: width,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_CASTER_DIALOG_WIDTH]:
  (state: VisualizationState, { width }: VisAction) => ({
    ...state,
    currentCasterDialogWidth: width,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_AMOUNT_OF_COMPARISON_CASTER_COLUMNS]:
  (state: VisualizationState, { amountOfComparisonCasterColumns }: VisAction) => ({
    ...state,
    amountOfComparisonCasterColumns,
  }),

  'data/ACTION_RESET_ALL': (state: VisualizationState, action: { avoidVisualization: boolean }) => {
    if (action.avoidVisualization) {
      return state
    }

    return ({
      ...initialState,
      visualizationMetaInformation: {
        ...state.visualizationMetaInformation,
      },
    })
  },
}

export default function (state: VisualizationState = initialState, action: VisAction) {
  if (state.equals({})) {
    state = {
      ...initialState,
      ...state,
    }
  }

  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
