import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { ContextMenuWrapper, ContextMenuItem } from './ContextMenu'

import * as VisualizationActions from '../../../store/visualization/actions'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((store: DefaultState) => ({
  contextMenu: store.visualization.contextMenu,
}), {
  closeContextMenu: VisualizationActions.closeContextMenu,
  derivePlot: VisualizationActions.derivePlot,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  data: any,
  t: Translation
}

class PlotContextMenu extends Component<Props> {
  // @AnalyzeTime(0)
  handleDeriveClick = () => {
    const { data, contextMenu, derivePlot, closeContextMenu } = this.props

    const viewId = contextMenu?.data?.viewId || ''

    derivePlot(data.configId, data.xIndex, viewId, data.x)
    closeContextMenu()
  };

  @AnalyzeTime(0)
  render () {
    const { data, t } = this.props

    return (
      <ContextMenuWrapper data-contextmenu position={data.mousePos}>
        <ContextMenuItem data-contextmenu onClick={this.handleDeriveClick}>
          {t('plotContextMenu.label', { x: data.x.toFixed(2) })}
        </ContextMenuItem>
      </ContextMenuWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(PlotContextMenu as any) as any) as any
