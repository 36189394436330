import ApiClient from '../../../../store/apiClient'
import Logic from '.'
import { Network } from '../../../../network/Network'
import { AnalyzeTime } from 'Util'

export default class Getters {
  // @AnalyzeTime(0)
  static getMissingData = (missingData: ResultData): void => {
    const { updateResultData } = Logic.dialogInstance.props
    const dataIds = Object.keys(missingData || {})

    if (!dataIds.length) {
      return
    }

    ApiClient.get(`${Network.URI}/matrix/result_data`, { params: { dataIds: dataIds.join(',') } })
      .then(({ resultData }) => {
        updateResultData(resultData)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        const resetData = dataIds.reduce((data, dataId) => ({
          ...data,
          [dataId]: null,
        }), {})

        updateResultData(resetData)
      })
  }

  // @AnalyzeTime(0)
  static getStatus = (simulationCase?: SimulationCase) => {
    if (!simulationCase) {
      return
    }

    const { t } = Logic.dialogInstance.props
    const isVerified = Logic.checkIfVerified(simulationCase)
    const { wasStoppedManually } = simulationCase

    if (!isVerified && !simulationCase.simulationStartedAt) {
      return { fixedWidth: true, icon: 'exclamation-triangle', title: t(`${Logic.PRE_TRANS}.status.notVerified`) }
    }

    if (isVerified && !simulationCase.simulationStartedAt) {
      return { fixedWidth: true, icon: 'check-circle', type: 'fas', title: t(`${Logic.PRE_TRANS}.status.notStarted`) }
    }

    if (isVerified && !simulationCase.simulationDataReceivedAt) {
      return { fixedWidth: true, icon: 'sync-alt', spin: true, title: t(`${Logic.PRE_TRANS}.status.started`) }
    }

    if (wasStoppedManually) {
      return { fixedWidth: true, icon: 'lock', title: t(`${Logic.PRE_TRANS}.status.doneManually`) }
    }

    return { fixedWidth: true, icon: 'lock', title: t(`${Logic.PRE_TRANS}.status.done`) }
  }
}
