/* eslint-env browser */

import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import * as TileWarningsActions from 'store/tileWarnings'

import PlotWrapper from '../PlotWrapper'
import cloneDeep from 'clone-deep'
import { DefaultState } from 'types/state'
import Util from 'logic/Util'
import { ViewLogic } from './ViewLogic'
import FeatureFlags from 'react/FeatureFlags'
import { TileConfig } from 'types/visualization'

const connector = connect(({
  AirLoop,
  application,
  ComparisonCasters,
  CoolingLoop,
  CoolingZone,
  data,
  DataLine,
  DataPoint,
  filter,
  LoopAssignment,
  Nozzle,
  Roller,
  RollerBearing,
  RollerBody,
  SupportPoint,
  Segment,
  SegmentGroup,
  SensorPoint,
  StrandGuide,
  visualization,
}: DefaultState) => ({
  comparisonCasters: ComparisonCasters,
  currentDashboard: visualization.currentDashboard,
  currentProject: application.main.currentProject,
  AirLoop,
  CoolingLoop,
  CoolingZone,
  LoopAssignment,
  Nozzle,
  Roller,
  RollerBearing,
  RollerBody,
  SupportPoint,
  Segment,
  SegmentGroup,
  SensorPoint,
  StrandGuide,
  DataPoint,
  DataLine,
  featureFlags: application.main.authenticationData.featureFlags,
  filterControlVariables: filter.filterControlVariables,
  plotConfigs: visualization.plotConfigs,
  rootData: data.rootData,
  tileConfigs: visualization.tileConfigs,
  viewsObject: visualization.viewsObject,
}), {
  setTileWarnings: TileWarningsActions.setTileWarnings,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  tileId: string,
  viewId: string,
}

export class ViewTile extends PureComponent<Props> {
  forceUpdateHandler = () => {
    requestAnimationFrame(() => requestAnimationFrame(() => this.forceUpdate()))
  }

  getXDomain (xDomain: number[], xValues: number[], tileConfig: TileConfig): number[] {
    if (!tileConfig.followPasslnCoord) {
      return xDomain
    }

    const { numberOfPointsBeforeAndAfterPasslnCoord = 1 } = tileConfig

    const currentPasslnCoord = (window as any).currentPasslnPosition ?? 0

    const nearestSmallerValues: number[] = []
    const nearestBiggerValues: number[] = []

    let lastIndex: number | undefined

    for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
      if (i === 0) {
        const { value, index } = ViewLogic.getNearestValueSmallerThanX(currentPasslnCoord, xValues)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestSmallerValues.push(value)
      }
      else {
        const { value, index } = ViewLogic.getNearestValueSmallerThanX(nearestSmallerValues[i - 1], xValues, lastIndex)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestSmallerValues.push(value)
      }
    }

    lastIndex = undefined

    for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
      if (i === 0) {
        const { value, index } = ViewLogic.getNearestValueBiggerThanX(currentPasslnCoord, xValues)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestBiggerValues.push(value)
      }
      else {
        const { value, index } = ViewLogic.getNearestValueBiggerThanX(nearestBiggerValues[i - 1], xValues, lastIndex)

        if (value === undefined || index === undefined) {
          break
        }

        lastIndex = index
        nearestBiggerValues.push(value)
      }
    }

    if (nearestSmallerValues.length === 0 && nearestBiggerValues.length === 0) {
      return xDomain
    }

    const min = Math.min(...nearestSmallerValues, currentPasslnCoord)
    const max = Math.max(...nearestBiggerValues, currentPasslnCoord)

    return [ min, max ]
  }

  // find all points that have x in the range of xDomain, add them to an array, then find the min and max of the array
  getYDomain (xDomain: number[], yDomain: number[], tileConfig: TileConfig, dynamicData: any[]): number[] {
    if (!tileConfig.followPasslnCoord) {
      return yDomain
    }

    const pointsComprehendedInXDomain = this.getPointsComprehendedInXDomain(xDomain, dynamicData)

    if (!pointsComprehendedInXDomain.length) {
      return []
    }

    const min = Math.min(...pointsComprehendedInXDomain.map(point => point.y))
    const max = Math.max(...pointsComprehendedInXDomain.map(point => point.y))

    return [ min, max ]
  }

  getPointsComprehendedInXDomain = (xDomain: number[], dynamicData: Coord[]): Coord[] => {
    const points: Coord[] = []

    for (const point of dynamicData) {
      if (point.x <= xDomain[1] && point.x >= xDomain[0]) {
        points.push(point)
      }

      if (point.x > xDomain[1]) {
        break
      }
    }

    return points
  }

  findPointIndexWithXValue = (
    pointsArray: Coord[],
    x: number,
    start: number,
    end: number,
  ): number => {
    if (end < start) {
      return -1
    }

    const mid = Math.floor((start + end) / 2)

    if (pointsArray[mid].x === x) {
      return mid
    }

    if (pointsArray[mid].x < x) {
      return this.findPointIndexWithXValue(pointsArray, x, mid + 1, end)
    }

    else {
      return this.findPointIndexWithXValue(pointsArray, x, start, mid - 1)
    }
  }

  getXDomainForDynamicPlotsWithComparisonCasters (
    xDomain: number[],
    tileConfig: TileConfig,
    dynamicData: {x: number, y:number}[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return xDomain
    }

    const linesXDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0) {
        return
      }

      linesXDomains.push(this.getXDomain(xDomain, line.map(point => point.x), tileConfig))
    })

    const min = linesXDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity)
    const max = linesXDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity)

    return [ min, max ]
  }

  getYDomainForDynamicPlotsWithComparisonCasters (
    xDomain: number[],
    yDomain: number[],
    tileConfig: TileConfig,
    dynamicData: any[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return yDomain
    }

    const linesYDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0) {
        return
      }

      linesYDomains.push(this.getYDomain(xDomain, yDomain, tileConfig, line))
    })

    const min = linesYDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity) ?? yDomain[0]
    const max = linesYDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity) ?? yDomain[1]

    return [ min, max ]
  }

  getMultiLineMin = (dynamicData: any[][], coord: 'x' | 'y') => {
    const min = dynamicData.reduce((acc, curr) => {
      const currMin = Math.min(...curr.map(d => d[coord] ?? Infinity))

      return Math.min(acc, currMin)
    }, Infinity)

    return min === Infinity ? 0 : min
  }

  getMultiLineMax = (dynamicData: any[][], coord: 'x' | 'y') => {
    const max = dynamicData.reduce((acc, curr) => {
      const currMax = Math.max(...curr.map(d => d[coord] ?? -Infinity))

      return Math.max(acc, currMax)
    }, -Infinity)

    return max === -Infinity ? 0 : max
  }

  getRealXDomainForMergedPlot = (
    xDomain: number[],
    dynamicDataList: {x: number, y: number}[][][],
    tileConfig: TileConfig,
  ): number[] => {
    const { followPasslnCoord, numberOfPointsBeforeAndAfterPasslnCoord = 1 } = tileConfig

    if (!followPasslnCoord) {
      return xDomain
    }

    const currentPasslnCoord = (window as any).currentPasslnPosition ?? 0
    const nearestSmallerValues: number[] = []
    const nearestBiggerValues: number[] = []

    // treat merged plot as a single plot

    dynamicDataList.forEach(dynamicData => {
      dynamicData.forEach(line => {
        if (line.length === 0) {
          return
        }

        let lastIndex: number | undefined
        const xValues = line.map(point => point.x)

        for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
          if (i === 0) {
            const { value, index } = ViewLogic.getNearestValueSmallerThanX(currentPasslnCoord, xValues)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestSmallerValues.includes(value)) {
              nearestSmallerValues.push(value)
            }
          }
          else {
            const { value, index } = ViewLogic
              .getNearestValueSmallerThanX(nearestSmallerValues[i - 1], xValues, lastIndex)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestSmallerValues.includes(value)) {
              nearestSmallerValues.push(value)
            }
          }
        }

        lastIndex = undefined

        for (let i = 0; i < numberOfPointsBeforeAndAfterPasslnCoord; i++) {
          if (i === 0) {
            const { value, index } = ViewLogic
              .getNearestValueBiggerThanX(currentPasslnCoord, xValues)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestBiggerValues.includes(value)) {
              nearestBiggerValues.push(value)
            }
          }
          else {
            const { value, index } = ViewLogic
              .getNearestValueBiggerThanX(nearestBiggerValues[i - 1], xValues, lastIndex)

            if (value === undefined || index === undefined) {
              break
            }

            lastIndex = index

            if (!nearestBiggerValues.includes(value)) {
              nearestBiggerValues.push(value)
            }
          }
        }
      })
    })

    if (nearestSmallerValues.length === 0 && nearestBiggerValues.length === 0) {
      return xDomain
    }

    // sort and only account for the last numberOfPointsBeforeAndAfterPasslnCoord values
    nearestSmallerValues.sort((a, b) => b - a).splice(numberOfPointsBeforeAndAfterPasslnCoord)

    // sort and only account for the first numberOfPointsBeforeAndAfterPasslnCoord values
    nearestBiggerValues.sort((a, b) => a - b).splice(numberOfPointsBeforeAndAfterPasslnCoord)

    const min = Math.min(...nearestSmallerValues, currentPasslnCoord) ?? xDomain[0]
    const max = Math.max(currentPasslnCoord, ...nearestBiggerValues) ?? xDomain[1]

    return [ min, max ]
  }

  getRealYDomainForMergedPlot = (
    realXDomain: number[],
    dynamicDataList: {x: number, y: number}[][][],
    tileConfig: TileConfig,
    yDomain: number[],
  ): number[] => {
    if (!tileConfig.followPasslnCoord) {
      return yDomain
    }

    const linesYDomains: number[][] = []

    dynamicDataList.forEach(dynamicData => {
      dynamicData.forEach(line => {
        if (line.length === 0) {
          return
        }

        const lineYDomain = this.getYDomain(realXDomain, yDomain, tileConfig, line)

        if (lineYDomain.length) {
          linesYDomains.push(lineYDomain)
        }
      })
    })

    const min = linesYDomains.reduce((acc, curr) => Math.min(acc, curr[0]), Infinity) ?? yDomain[0]
    const max = linesYDomains.reduce((acc, curr) => Math.max(acc, curr[1]), -Infinity) ?? yDomain[1]

    return [ min, max ]
  }

  getViewTile = () => {
    const {
      viewsObject,
      tileId,
      plotConfigs,
      viewId,
      tileConfigs,
      rootData,
      comparisonCasters,
      setTileWarnings,
      featureFlags,
      currentProject,
      filterControlVariables,
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SupportPoint,
      Segment,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
      DataPoint,
      DataLine,
    } = this.props

    const elementsHashes = {
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SegmentGroupSupportPoints: SupportPoint,
      SupportPoint,
      Segment,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
      DataPoint,
      DataLine,
    }

    const simulationCaseIds = currentProject.simulationCases.map(simCase => simCase._id)
    const canCompareCasters = FeatureFlags.canViewCasterComparison(featureFlags)
    const comparisonCastersIds = viewsObject[viewId]?.selectedComparisonCasters ?? []
      .filter(id => simulationCaseIds.includes(id)) // filter out deleted casters

    if (!rootData.Caster || !tileConfigs) {
      return null
    }

    const filteredElementCache: Record<string, string[]> = {}

    const tileWarnings: Record<string, Record<string, any>> = {}

    const { configId, type, isDynamicData, isMergedDynamicData } = tileConfigs?.[tileId] ?? {}

    if (configId !== undefined && plotConfigs && plotConfigs[configId] && !isDynamicData && !isMergedDynamicData) {
      const data = plotConfigs[configId]
      const tileData = tileConfigs[tileId]

      let shapeIds: string[] = []

      if (type === 'line' || type === 'area' || type === 'bar') {
        shapeIds = (tileConfigs[tileId].shapeIds ?? [])
          .map(shapeId => shapeId.id)
          .filter(shapeId => shapeId !== undefined && shapeId !== '')
      }

      const { xDomainMin: xMin, xDomainMax: xMax } = tileData
      const { yDomainMin: yMin, yDomainMax: yMax } = tileData

      const xDomain = data.xValues ? [
        xMin !== undefined && String(xMin).length ? Number(xMin) : data.xValues[0],
        xMax !== undefined && String(xMax).length ? Number(xMax) : data.xValues[data.xValues.length - 1],
      ] : [ 0, 0 ]

      const yDomain = data.yValueRange ? [
        yMin !== undefined && String(yMin).length ? Number(yMin) : data.yValueRange[0],
        yMax !== undefined && String(yMax).length ? Number(yMax) : data.yValueRange[1],
      ] : [ 0, 0 ]

      return (
        <PlotWrapper
          tileId={tileId}
          key={tileId}
          configId={configId}
          type={type}
          xDomain={xDomain}
          yDomain={yDomain}
          xRange={data.xRangeMin && data.xRangeMax ? [ data.xRangeMin, data.xRangeMax ] : [ 0, 0 ]}
          xValues={data.xValues}
          yValueRange={data.yValueRange}
          valueRange={data.vRangeMin && data.vRangeMax ? [ data.vRangeMin, data.vRangeMax ] : [ 0, 0 ]}
          shapeIds={shapeIds}
          radiusDomain={[ data.radiusMin, data.radiusMax ]}
          radius0={data.radius0}
          radius={data.radius}
          frequency={data.frequency}
          xLabel={tileData.xLabel ?? ''}
          yLabel={tileData.yLabel ?? ''}
          viewId={viewId}
          flipAxes={data.flipAxes}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }
    else if (configId !== undefined && !/^config_/.test(configId) && !isDynamicData && !isMergedDynamicData) {
      return (
        <PlotWrapper
          tileId={tileId}
          key={tileId}
          configId={configId}
          type={type}
          viewId={viewId}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }
    else if (isDynamicData && configId !== undefined) {
      const tileData = tileConfigs[tileId]
      const plotConfig = plotConfigs[configId]

      if (!plotConfig || !plotConfig.selectedY || !plotConfig.selectedX) {
        return null
      }

      const [ type, attrY ] = plotConfig.selectedY.split('|')
      const [ _, attrX ] = plotConfig.selectedX.split('|')

      const elements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, plotConfig, filteredElementCache)
      const comparisonCasterData: any[] = []
      const comparisonElements: string[][] = []

      if (canCompareCasters && comparisonCastersIds && comparisonCastersIds.length > 0) {
        for (const casterId of comparisonCastersIds) {
          const data = comparisonCasters[casterId]

          if (!data) {
            continue
          }

          const casterIndex = simulationCaseIds.findIndex(key => casterId === key) + 1

          data.casterName = `C${casterIndex}`

          const elements = ViewLogic
            .getDynamicElementsFromConfig(data.elementsHashes, plotConfig, filteredElementCache)

          comparisonElements.push(elements)
          comparisonCasterData.push(data)
        }
      }

      if (!elements.length && !Util.hasElementType((elementsHashes as any)[type])) {
        const message = `Can not show plot because element: ${type} does not exist in current caster`

        return (
          <PlotWrapper
            tileId={tileId}
            key={tileId}
            configId={configId}
            type='message'
            message={message}
            viewId={viewId}
          />
        )
      }

      const { hasX, hasY } = ViewLogic.checkDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY)
      const hasFilter = ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
          ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)

      if ((!hasX || !hasY) && !hasFilter) {
        let message = ''

        // replace only first underscore
        const attrXWithoutUnderScore = attrX?.replace('_', '')
        const attrYWithoutUnderScore = attrY?.replace('_', '')

        if (!hasX && !hasY) {
          message = `No data was found for attributes ${attrXWithoutUnderScore} and ${attrYWithoutUnderScore}`
        }
        else {
          message = `No data was found for attribute: ${!hasX ? attrXWithoutUnderScore : attrYWithoutUnderScore}`
        }

        message += ` in element of type: ${type}`

        return (
          <PlotWrapper
            tileId={tileId}
            key={tileId}
            configId={configId}
            type='message'
            message={message}
            viewId={viewId}
          />
        )
      }

      let dynamicData: any[] = []
      let isMultiLinePlot = false
      let dataLinePlotRanges: any
      const isRefIdLine = plotConfig.filter?.includes('#ref=')

      if (type === 'DataLine') {
        const filterEmpty = !plotConfig.filter

        if (isRefIdLine && comparisonCasterData.length === 0) {
          dynamicData = ViewLogic.getDynamicDataFromDataLines(
            elements,
            elementsHashes,
            type,
            attrX,
            attrY,
            filterEmpty,
            isRefIdLine,
          )
        }
        else {
          dynamicData[0] = ViewLogic.getDynamicDataFromDataLines(
            elements,
            elementsHashes,
            type,
            attrX,
            attrY,
            filterEmpty,
            isRefIdLine,
          )
        }

        if (comparisonCasterData.length > 0) {
          for (let i = 0; i < comparisonCasterData.length; i++) {
            const data = comparisonCasterData[i]
            const elements = ViewLogic
              .getDynamicElementsFromConfig(data.elementsHashes, plotConfig, {})

            comparisonElements.push(elements)
            dynamicData[i + 1] = ViewLogic.getDynamicDataFromDataLines(
              elements,
              data.elementsHashes,
              type,
              attrX,
              attrY,
              filterEmpty,
              isRefIdLine,
            )

            if (!dynamicData[i + 1].length) {
              if (!tileWarnings[configId]) {
                tileWarnings[configId] = {}
              }

              if (!tileWarnings[configId][data.casterName]) {
                tileWarnings[configId][data.casterName] = true
              }
            }

            if (!isRefIdLine) {
              dynamicData[i + 1].forEach?.((line: any) => {
                line.forEach?.((point: any) => {
                  point.casterName = data.casterName
                })
              })
            }
            else {
              dynamicData[i + 1].forEach?.((point: any) => {
                point.casterName = data.casterName
              })
            }
          }
        }

        isMultiLinePlot = !(isRefIdLine && comparisonCasterData.length === 0)

        if (isRefIdLine) {
          dataLinePlotRanges = {
            x: [
              Math.min(...dynamicData.map((p: any) => p.x)),
              Math.max(...dynamicData.map((p: any) => p.x)),
            ],
            y: [
              Math.min(...dynamicData.map((p: any) => p.y)),
              Math.max(...dynamicData.map((p: any) => p.y)),
            ],
          }
        }
        else if (isRefIdLine && comparisonCasterData.length > 0) {
          const xs = []
          const ys = []

          for (const data of dynamicData) {
            for (const line of data) {
              xs.push(line.x)
              ys.push(line.y)
            }
          }

          dataLinePlotRanges = {
            x: [
              Math.min(...xs),
              Math.max(...xs),
            ],
            y: [
              Math.min(...ys),
              Math.max(...ys),
            ],
          }
        }
        else {
          dataLinePlotRanges = {
            x:
                [
                  Math.min(...dynamicData.map(d => this.getMultiLineMin(d, 'x'))),
                  Math.max(...dynamicData.map(d => this.getMultiLineMax(d, 'x'))),
                ],
            y: [
              Math.min(...dynamicData.map(d => this.getMultiLineMin(d, 'y'))),
              Math.max(...dynamicData.map(d => this.getMultiLineMax(d, 'y'))),
            ],
          }
        }

        const auxDynamicData: any[] = []

        if (!isRefIdLine) {
          dynamicData.forEach((d: any) => {
            auxDynamicData.push(...d)
          })

          dynamicData = auxDynamicData
        }
      }
      else if (comparisonCasterData.length > 0) {
        dynamicData[0] = ViewLogic.getDynamicDataFromElements(
          elements,
          elementsHashes,
          type,
          attrX,
          attrY,
          !hasFilter,
        )

        for (let i = 0; i < comparisonCasterData.length; i++) {
          const data = comparisonCasterData[i]
          const elements = comparisonElements[i]

          if (!elements.length) {
            if (!tileWarnings[configId]) {
              tileWarnings[configId] = {}
            }

            if (!tileWarnings[configId][data.casterName]) {
              tileWarnings[configId][data.casterName] = true
            }

            continue
          }

          dynamicData[i + 1] = ViewLogic.getDynamicDataFromElements(
            elements,
            data.elementsHashes,
            type,
            attrX,
            attrY,
            !hasFilter,
          )

          if (!dynamicData[i + 1].length) {
            if (!tileWarnings[configId]) {
              tileWarnings[configId] = {}
            }

            if (!tileWarnings[configId][data.casterName]) {
              tileWarnings[configId][data.casterName] = true
            }
          }

          // add caster name info to every point of line
          for (const el of dynamicData[i + 1]) {
            el.casterName = data.casterName
          }
        }
        // for every comparison caster, add its elements to dynamic data

        isMultiLinePlot = true
      }
      else {
        dynamicData = ViewLogic.getDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY, !hasFilter)
      }

      let hasNoData = false

      if (!dynamicData.length && !hasFilter) {
        // eslint-disable-next-line max-len
        const message = `Can not show plot because there are no data for attributes ${attrX} and ${attrY} in element of type: ${type}`

        return (
          <PlotWrapper
            tileId={tileId}
            key={tileId}
            configId={configId}
            type='message'
            message={message}
            viewId={viewId}
          />
        )
      }
      else if (!dynamicData.length && hasFilter) {
        // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
        dynamicData.push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])

        hasNoData = true
      }
      else if (dynamicData.every(data => Array.isArray(data) && !data.length) && hasFilter) {
        // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
        dynamicData[0].push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])

        hasNoData = true
      }

      const yValues = cloneDeep(dynamicData).map((el: any) => el.y).sort((a:any, b: any) => b - a)

      let shapeIds: string[] = []
      const shapeData: any = []

      if (tileData.type === 'line' || tileData.type === 'area' || tileData.type === 'bar') {
        shapeIds = (tileData.shapeIds ?? [])
          .map(shapeId => shapeId.id)
          .filter(shapeId => shapeId !== undefined && shapeId !== '')

        for (const shape of shapeIds) {
          const plot = plotConfigs[shape]

          if (
            shape === undefined ||
            shape === '' ||
            shape === '_passln_coord' ||
            filterControlVariables.includes(shape) ||
            !plot
          ) {
            continue
          }

          const { selectedY } = plot
          const shapeElements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, plot, {})
          const shapeAttrY = selectedY.split('|')[1]

          shapeData.push({
            shapeId: shape,
            data: ViewLogic.getShapeDynamicData(shapeElements, elementsHashes, type, shapeAttrY),
          })
        }
      }

      const { xDomainMin: xMin, xDomainMax: xMax } = tileData
      const { yDomainMin: yMin, yDomainMax: yMax } = tileData

      const isDataLine = plotConfig.selectedX.split('|')[0] === 'DataLine'

      const xDomain = [
        xMin !== undefined && String(xMin).length
          ? Number(xMin)
          : !Array.isArray(dynamicData[0])
            ? dynamicData[0]?.x ?? 0
            : !(isDataLine && !isRefIdLine)
              ? this.getMultiLineMin(dynamicData, 'x')
              : dataLinePlotRanges.x[0],
        xMax !== undefined && String(xMax).length
          ? Number(xMax)
          : !Array.isArray(dynamicData[0])
            ? dynamicData[dynamicData.length - 1]?.x ?? 0
            : !(isDataLine && !isRefIdLine)
              ? this.getMultiLineMax(dynamicData, 'x')
              : dataLinePlotRanges.x[1],
      ]

      const yDomain = !isMultiLinePlot ? [
        yMin !== undefined && String(yMin).length ? Number(yMin) : yValues[0],
        yMax !== undefined && String(yMax).length ? Number(yMax) : yValues[yValues.length - 1],
      ] : [
        yMin !== undefined && String(yMin).length ? Number(yMin) : this.getMultiLineMin(dynamicData, 'y'),
        yMax !== undefined && String(yMax).length ? Number(yMax) : this.getMultiLineMax(dynamicData, 'y'),
      ]

      const isComparingCasters = comparisonCasterData.length

      const xValues = !isComparingCasters
        ? dynamicData.map((el: any) => el.x)
        : dynamicData.map(line => line.map((el: any) => el.x)).flat()

      const { xAxisPadding = 0, yAxisPadding = 0 } = tileData

      xDomain.sort()
      yDomain.sort()

      const realXDomain = !isComparingCasters
        ? this.getXDomain(xDomain, xValues, tileData)
        : this.getXDomainForDynamicPlotsWithComparisonCasters(xDomain, tileData, dynamicData)
      const realYDomain = !isComparingCasters
        ? this.getYDomain(realXDomain, yDomain, tileData, dynamicData) ?? yDomain[0]
        : this.getYDomainForDynamicPlotsWithComparisonCasters(realXDomain, yDomain, tileData, dynamicData)

      realXDomain.sort()
      realYDomain.sort()

      if (realXDomain[0] < 0 && realXDomain[1] < 0) {
        realXDomain.reverse()
      }

      if (realYDomain[0] < 0 && realYDomain[1] < 0) {
        realYDomain.reverse()
      }

      realXDomain[0] = realXDomain[0] - Number(xAxisPadding)
      realXDomain[1] = realXDomain[1] + Number(xAxisPadding)
      realYDomain[0] = realYDomain[0] - Number(yAxisPadding)
      realYDomain[1] = realYDomain[1] + Number(yAxisPadding)

      return (
        <PlotWrapper
          tileId={tileId}
          key={tileId}
          configId={configId}
          dynamicData={dynamicData}
          type={tileData.type}
          shapeIds={shapeIds}
          xRange={[ dynamicData[0]?.x ?? 0, dynamicData[dynamicData.length - 1]?.x ?? 0 ]}
          yValueRange={[ yValues[0], yValues[yValues.length - 1] ]}
          valueRange={[ yValues[0], yValues[yValues.length - 1] ]}
          xValues={dynamicData.map((el: any) => el.x)}
          xDomain={realXDomain}
          yDomain={realYDomain}
          xLabel={tileData.xLabel ?? attrX.substring(1)}
          yLabel={tileData.yLabel ?? attrY.substring(1)}
          flipAxes={plotConfig.flipAxes}
          isDynamicData
          hasNoData={hasNoData}
          isMultiLinePlot={isMultiLinePlot}
          shapeData={shapeData}
          viewId={viewId}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }
    else if (isMergedDynamicData && configId !== undefined) {
      const tileData = tileConfigs[tileId]
      const plotConfig = plotConfigs[configId]

      if (!plotConfig) {
        return null
      }

      const dynamicDataList: any[] = []
      let xValues: number[] = []
      let yValues: number[] = []

      const hasFilter = ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
          ViewLogic.isMergedDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)

      const xLabels: string[] = []
      const yLabels: string[] = []

      // did not use plotConfig.configs because in plotly configIds is used and configs sometimes has another order
      plotConfig.configIds?.forEach((configId: string) => {
        const config = plotConfigs[configId]

        if (!config) {
          return null
        }

        const [ type, attrY ] = config.selectedY.split('|')
        const [ _, attrX ] = config.selectedX.split('|')

        const comparisonCasterData: any[] = []
        const comparisonElements: string[][] = []

        if (canCompareCasters && comparisonCastersIds && comparisonCastersIds.length > 0) {
          for (const casterId of comparisonCastersIds) {
            const data = comparisonCasters[casterId]

            if (!data) {
              continue
            }

            const casterIndex = simulationCaseIds.findIndex(key => casterId === key) + 1

            data.casterName = `C${casterIndex}`

            const elements = ViewLogic
              .getDynamicElementsFromConfig(data.elementsHashes, config, {})

            comparisonElements.push(elements)
            comparisonCasterData.push(data)
          }
        }

        xLabels.push(attrX.substring(1))
        yLabels.push(attrY.substring(1))

        const elements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, config, filteredElementCache)

        const dynamicData: any = []
        const isRefIdLine = config.filter?.includes('#ref=')

        if (type === 'DataLine') {
          const filterEmpty = !config.filter

          dynamicData[0] = ViewLogic.getDynamicDataFromDataLines(
            elements,
            elementsHashes,
            type,
            attrX,
            attrY,
            filterEmpty,
            isRefIdLine,
          )

          if (isRefIdLine) {
            for (const point of dynamicData[0]) {
              xValues.push(point.x)
              yValues.push(point.y)
            }
          }
          else {
            for (const line of dynamicData[0]) {
              for (const point of line) {
                xValues.push(point.x)
                yValues.push(point.y)
              }
            }
          }

          for (let i = 0; i < comparisonCasterData.length; i++) {
            const compareData = comparisonCasterData[i]

            const compareElements = ViewLogic
              .getDynamicElementsFromConfig(compareData.elementsHashes, config, {})

            // comparisonElements.push(compareElements)
            const dynamicCompareData: any = ViewLogic.getDynamicDataFromDataLines(
              compareElements,
              compareData.elementsHashes,
              type,
              attrX,
              attrY,
              filterEmpty,
              config.filter?.includes('#ref='),
            )

            if (!dynamicCompareData.length) {
              if (!tileWarnings[configId]) {
                tileWarnings[configId] = {}
              }

              if (!tileWarnings[configId][compareData.casterName]) {
                tileWarnings[configId][compareData.casterName] = true
              }
            }

            dynamicCompareData.forEach?.((line: any) => {
              if (line && !(line instanceof Array)) {
                line.casterName = compareData.casterName
              }

              line.forEach?.((point: any) => {
                point.casterName = compareData.casterName
              })
            })

            dynamicData.push(dynamicCompareData)

            if (isRefIdLine) {
              for (const point of dynamicCompareData) {
                xValues.push(point.x)
                yValues.push(point.y)
              }
            }
            else {
              for (const line of dynamicCompareData) {
                for (const point of line) {
                  xValues.push(point.x)
                  yValues.push(point.y)
                }
              }
            }
          }
        }
        else {
          dynamicData[0] = ViewLogic
            .getDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY, !hasFilter)

          for (let i = 0; i < comparisonCasterData.length; i++) {
            const data = comparisonCasterData[i]
            const elements = comparisonElements[i]

            if (!elements.length) {
              if (!tileWarnings[configId]) {
                tileWarnings[configId] = {}
              }

              if (!tileWarnings[configId][data.casterName]) {
                tileWarnings[configId][data.casterName] = true
              }

              continue
            }

            dynamicData[i + 1] = ViewLogic.getDynamicDataFromElements(
              elements,
              data.elementsHashes,
              type,
              attrX,
              attrY,
              !hasFilter,
            )

            if (!dynamicData[i + 1].length) {
              if (!tileWarnings[configId]) {
                tileWarnings[configId] = {}
              }

              if (!tileWarnings[configId][data.casterName]) {
                tileWarnings[configId][data.casterName] = true
              }
            }

            for (const el of dynamicData[i + 1]) {
              el.casterName = data.casterName
            }
          }

          for (const line of dynamicData) {
            for (const el of line) {
              xValues.push(el.x)
              yValues.push(el.y)
            }
          }
        }

        if (!dynamicData.length) {
          return null
        }

        dynamicDataList.push(dynamicData)
      })

      let hasNoData = false

      if (!dynamicDataList.length && !hasFilter) {
        const message = 'Can not show plot because the required data are missing'

        return (
          <PlotWrapper
            tileId={tileId}
            key={tileId}
            configId={configId}
            type='message'
            message={message}
            viewId={viewId}
          />
        )
      }
      else if (!dynamicDataList.length && hasFilter) {
        plotConfig.configs?.forEach(() => {
          // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
          dynamicDataList.push([ { x: 0, y: 0 }, { x: 0, y: 0 } ])
          xValues.push(0)
          yValues.push(0)
        })

        hasNoData = true
      }
      else if (
        hasFilter &&
          dynamicDataList.every((dynamicData: any[]) =>
            (Array.isArray(dynamicData) && dynamicData.every((line: any) => !line.length)))
      ) {
        plotConfig.configs?.forEach(() => {
          // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
          // TODO: fix, if we push this fallback data, data is wrong
          // if (dynamicDataList[0]?.length === 1) {
          // dynamicDataList[0][0].push({ x: 0, y: 0 })
          // }

          xValues.push(0)
          yValues.push(0)
        })

        hasNoData = true
      }

      xValues = xValues.sort((a, b) => b - a)
      yValues = yValues.sort((a, b) => b - a)

      let shapeIds: string[] = []
      const shapeData: any = []

      if (tileData.type === 'line' || tileData.type === 'area' || tileData.type === 'bar') {
        shapeIds = (tileData.shapeIds ?? [])
          .map(shapeId => shapeId.id)
          .filter(shapeId => shapeId !== undefined && shapeId !== '')

        for (const shape of shapeIds) {
          const config = plotConfigs[shape]

          if (
            shape === undefined ||
            shape === '' ||
            shape === '_passln_coord' ||
            filterControlVariables.includes(shape) ||
            !config
          ) {
            continue
          }

          const elements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, config, {})

          const { selectedY } = config
          const [ elementType, shapeAttrY ] = selectedY.split('|')

          shapeData.push({
            shapeId: shape,
            data: ViewLogic.getShapeDynamicData(elements, elementsHashes, elementType, shapeAttrY),
          })
        }
      }

      const {
        xDomainMin: xMin,
        xDomainMax: xMax,
        yDomainMin: yMin,
        yDomainMax: yMax,
        xAxisPadding = 0,
        yAxisPadding = 0,
      } = tileData

      const xDomain = [
        xMin !== undefined && String(xMin).length ? Number(xMin) : xValues[0],
        xMax !== undefined && String(xMax).length
          ? Number(xMax)
          : xValues[xValues.length - 1],
      ]

      const maxYValues = yValues[yValues.length - 1]

      const yDomain = [
        yMin !== undefined && String(yMin).length ? Number(yMin) : yValues[0],
        yMax !== undefined && String(yMax).length ? Number(yMax) : maxYValues,
      ]

      xDomain.sort()
      yDomain.sort()

      const realXDomain = this.getRealXDomainForMergedPlot(xDomain, dynamicDataList, tileData)
      const realYDomain = this.getRealYDomainForMergedPlot(realXDomain, dynamicDataList, tileData, yDomain)

      realXDomain[0] = realXDomain[0] - Number(xAxisPadding)
      realXDomain[1] = realXDomain[1] + Number(xAxisPadding)
      realYDomain[0] = realYDomain[0] - Number(yAxisPadding)
      realYDomain[1] = realYDomain[1] + Number(yAxisPadding)

      return (
        <PlotWrapper
          tileId={tileId}
          key={tileId}
          configId={configId}
          dynamicDataList={dynamicDataList}
          type={tileData.type}
          shapeIds={shapeIds}
          xRange={[ xValues[0], xValues[xValues.length - 1] ]}
          yValueRange={[ yValues[0], maxYValues ]}
          valueRange={[ yValues[0], maxYValues ]}
          xValues={xValues}
          xDomain={realXDomain}
          yDomain={realYDomain}
          xLabel={tileData.xLabel ?? Array.from(new Set(xLabels)).join(', ') ?? ''}
          yLabel={tileData.yLabel ?? Array.from(new Set(yLabels)).join(', ') ?? ''}
          flipAxes={plotConfig.flipAxes}
          isMergedDynamicData
          hasNoData={hasNoData}
          shapeData={shapeData}
          viewId={viewId}
          forceUpdateHandler={this.forceUpdateHandler}
        />
      )
    }

    setTileWarnings(tileWarnings)
  };

  render () {
    return this.getViewTile()
  }
}

export default connector(ViewTile as any) as any
