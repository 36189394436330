import { Vector2 } from 'three'
import { AnalyzeTime } from 'Util'

export default class ConditionUtil {
  @AnalyzeTime(0)
  static mouseNotOnCanvasOrIgnoredIntersect (event: any, mouseOnCanvas: any) {
    return (!mouseOnCanvas ||
    (!/canvas/i.test(event.target.tagName) && !event.target.getAttribute('data-ignore-intersect')))
  }

  @AnalyzeTime(0)
  static mouseOutsideOfView (width: number, height: number, x: number, y: number, mouseOnCanvas: Vector2) {
    return (mouseOnCanvas.x > width + x || mouseOnCanvas.y > height + y)
  }

  @AnalyzeTime(0)
  static differentMouseDownAndUpPositions (downMousePosition: Vector2, mouseOnCanvas: Vector2) {
    return (downMousePosition.x !== mouseOnCanvas.x ||
        downMousePosition.y !== mouseOnCanvas.y)
  }
}
