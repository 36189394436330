/* eslint-env browser */

// import Network.IpcManager from 'Network.IpcManager'
import { useConfig } from 'config'
import ApiClient from '../store/apiClient'
import Codes from './Codes'
import NetworkManager, { NetworkStatusEnum } from './NetworkManager'

type Message = {
  code: number,
  data: any
};

type Callback = {
  (data: any): void
};

export class Network {
  static IP = window.location.hostname || 'localhost'
  static localPort: number
  static localIp: string
  static useLocalURI: boolean
  // TODO: Change to 8072, is set to 3000 because when reloading attempts to connect to this port
  static PORT = 8072 // window.location.port || 8070  - 8072: proxy

  static isConnecting = false
  static webSocket: WebSocket | null;
  static queue: Array<Message> = [];

  static dataCallback: Callback;
  static metaCallback: Callback;
  static authCallback: Callback;
  static notAuthCallback: () => void
  static updateNotificationCallback: Callback;
  static updatePushCallback: Callback;
  static lockChangeCallback: () => void;
  static onCloseCallback: () => void;
  static onOpenCallback: () => void;
  static simulationUpdateCallback: Callback;
  static generateDoneCallback: Callback;
  static executionDoneCallback: Callback;
  static IpcManager: any

  // if the incoming state is different than the actual useLocalURI state, then change it
  static toggleUseLocalURI (localState: boolean) {
    if (Network.useLocalURI !== localState) {
      // if incoming state is different, disconnect the websocket
      if (Network.webSocket) {
        Network.webSocket.onclose = () => null // So that connection lost error doesn't show
      }

      NetworkManager.disconnect()
      NetworkManager.updateStatus(NetworkStatusEnum.DISCONNECTED)

      // after disconnecting the websocket, set URI to the local server's URI
      Network.useLocalURI = localState

      // after setting the URI to the local server's, connect a websocket to the local server
      NetworkManager.connect(Network.IpcManager?.appLockCaster)
    }
  }

  // function to set the Network's local URI, when the local server is started
  static setLocalURI (localIp: string, localPort: number) {
    if (Network.isConnecting && Network.IpcManager) {
      // If the Network class is still connecting the websocket, wait for it to finish, and then change the URI

      // wait for the NetworkConnected event to be emitted
      Network.IpcManager.internal.on('NetworkConnected', () => {
        // Once the connection is established and the event is emitted, remove the listener, so it doesn't loop
        Network.IpcManager.internal.removeListener('NetworkConnected')
        // Disconnect the websocket
        NetworkManager.disconnect()

        // set the local URI
        Network.localIp = localIp
        Network.localPort = localPort
        Network.useLocalURI = true
        NetworkManager.connect(Network.IpcManager.appLockCaster)
      })
    }
    else if (!Network.localPort && !Network.localIp) {
      // If Network is not connecting
      // Disconnect the websocket
      NetworkManager.disconnect()

      Network.localIp = localIp
      Network.localPort = localPort
      Network.useLocalURI = true
      NetworkManager.connect(Network.IpcManager.appLockCaster)
    }
  }

  static get URI () {
    // TODO: set local server ip and port when started ...

    if (Network.localIp && Network.localPort && Network.useLocalURI) {
      return `https://${Network.localIp}:${Network.localPort}`
    }

    // TODO: set local server info in local storage

    return useConfig().dispatcherBaseURL
  }

  static get options () {
    return window.isElectron
      // Basic auth is hardcoded for electron version to work ...
      ? { headers: { Authorization: 'Basic Z2FuZGFsZjp5b3Ugc2hhbGwgbm90IHBhc3M=' } }
      : {}
  }

  static _handleCodes (rawMessage: {
    data: string
  }) {
    const message: Message = JSON.parse(rawMessage.data)

    switch (message.code) {
      case Codes.OPC_S_REGISTERED:
        // TODO: check if this is needed after reload
        // Network.sendCode(Codes.OPC_C_GET_META)
        break
      case Codes.OPC_S_SET_META:
        Network.metaCallback(message.data)
        Network.sendCode(Codes.OPC_C_GET_DATA)
        break
      case Codes.OPC_S_USER_AUTHENTICATED:
        Network.authCallback(message.data)
        break
      case Codes.OPC_S_USER_NOT_AUTHENTICATED:
        Network.notAuthCallback()
        break
      case Codes.OPC_S_UPDATE_AVAILABLE:
        Network.updateNotificationCallback(message.data)
        break
      case Codes.OPC_S_UPDATE_PUSH:
        Network.updatePushCallback(message.data)
        break
      case Codes.OPC_S_LOCK_CHANGE:
        Network.lockChangeCallback()
        break
      case Codes.OPC_S_SIMULATION_STATE_UPDATE:
        Network.simulationUpdateCallback(message.data)
        break
      case Codes.OPC_S_GENERATE_DONE:
        Network.generateDoneCallback(message.data)
        break
      case Codes.OPC_S_EXECUTION_DONE:
        Network.executionDoneCallback(message.data)
        break
      default:
        // eslint-disable-next-line no-console
        console.log(message)
    }
  }

  static _handleMessage (message: {
    data: string
  }) {
    if (typeof message.data === 'string') {
      Network._handleCodes(message)

      return
    }

    Network.dataCallback(message.data)
  }

  static _handleOpen () {
    NetworkManager.updateStatus(NetworkStatusEnum.CONNECTED)
    Network.onOpenCallback()

    for (let i = 0; i < Network.queue.length; i++) {
      Network.sendCode(Network.queue[i].code, Network.queue[i].data)
    }

    Network.queue = []
  }

  static _handleClose () {
    NetworkManager.updateStatus(NetworkStatusEnum.DISCONNECTED)
    Network.onCloseCallback()
  }

  static init (
    dataCallback: Callback,
    metaCallback: Callback,
    authCallback: Callback,
    notAuthCallback: () => void,
    updateNotificationCallback: Callback,
    updatePushCallback: ({ changes }: {changes: Array<{action: string, elementType: string, data:any}>}) => void,
    lockChangeCallback: () => void,
    simulationUpdateCallback: Callback,
    onCloseCallback: () => void,
    onOpenCallback: () => void,
    generateDoneCallback: ({ simulationCaseId, errorType }: { simulationCaseId: string, errorType?: string }) => void,
    executionDoneCallback: (
      { simulationCaseId, definitionId, caseId, errorType, chosenCaseOptionIndex, userName }:
        {
          simulationCaseId: string
          definitionId: string
          caseId: string
          errorType?: string
          chosenCaseOptionIndex: number
          userName: string
        }
    ) => void,
  ) {
    Network.dataCallback = dataCallback
    Network.metaCallback = metaCallback
    Network.authCallback = authCallback
    Network.notAuthCallback = notAuthCallback
    Network.updateNotificationCallback = updateNotificationCallback
    Network.updatePushCallback = updatePushCallback
    Network.lockChangeCallback = lockChangeCallback
    Network.simulationUpdateCallback = simulationUpdateCallback
    Network.onCloseCallback = onCloseCallback
    Network.onOpenCallback = onOpenCallback
    Network.generateDoneCallback = generateDoneCallback
    Network.executionDoneCallback = executionDoneCallback
  }

  static connect (connectCallback: () => void) {
    if (Network.isConnecting || (Network.webSocket && Network.webSocket.readyState !== WebSocket.CLOSED)) {
      return
    }

    // because when developing and reloading, react's offline servers variables are deleted
    if (window.isElectron && !Network.localIp && !Network.localPort) {
      const localPortString = window.localStorage.getItem('localPort')
      const localPort = localPortString ? JSON.parse(localPortString) as number : 0
      const useLocalServer = JSON.parse(window.localStorage.getItem('useLocalServer') || 'false') as boolean

      if (useLocalServer && localPort) {
        Network.setLocalURI('localhost', localPort)
      }
    }

    Network.isConnecting = true
    ApiClient
      .get(`${Network.URI}/register`)
      .then(err => {
        if (err) {
          Network.isConnecting = false

          return
        }

        Network.disconnect()

        Network.webSocket = new WebSocket(`${Network.URI.replace(/^http/, 'ws')}/ws`)
        Network.webSocket.binaryType = 'arraybuffer'

        Network.webSocket.onopen = Network._handleOpen
        Network.webSocket.onmessage = Network._handleMessage
        Network.webSocket.onclose = Network._handleClose

        connectCallback()
        Network.isConnecting = false

        if (Network.IpcManager) {
          Network.IpcManager.internal.send('NetworkConnected')
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        Network.isConnecting = false
      })
  }

  static disconnect () {
    if (Network.webSocket && Network.webSocket.readyState === WebSocket.OPEN) {
      Network.webSocket.close()
    }

    Network.webSocket = null
  }

  static sendCode (code: number, data?: any) {
    if (!Network.webSocket || Network.webSocket.readyState !== WebSocket.OPEN) {
      Network.queue.push({ code, data })

      return
    }

    Network.webSocket.send(JSON.stringify({
      code,
      data,
    }))
  }
}
