import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import Button from '@material-ui/core/Button'

import Icon from 'react/specific/Icon'

import * as ApplicationActions from 'store/application/main/actions'
import Dialog from '../DuplicateSimulationCaseDialog'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect((state: DefaultState) => ({
  selections: state.matrix.selections,
}), {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  handleLoadingStatus?: any
  isSelected: boolean
  contentRef?: HTMLElement | null
  t: Translation
}

class Duplicate extends Component<Props> {
  // @AnalyzeTime(0)
  handleClick = () => {
    const { openDialog } = this.props

    openDialog(Dialog.NAME)
  };

  // @AnalyzeTime(0)
  getTitle = () => {
    const { isSelected, t } = this.props

    if (!isSelected) {
      return t(`${PRE_TRANS}.titles.duplicateSelected.nothingSelected`)
    }

    return t(`${PRE_TRANS}.titles.duplicateSelected.default`)
  };

  @AnalyzeTime(0)
  render () {
    const { isSelected, t, ...restProps } = this.props

    return (
      <Button {...restProps} title={this.getTitle()} disabled={!isSelected} onClick={this.handleClick}>
        <Icon icon='clone' />
      </Button>
    )
  }
}

export default withNamespaces('application')(connector(Duplicate as any) as any) as any
