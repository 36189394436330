/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import FeatureFlags from 'react/FeatureFlags/index'
import Input from 'react/specific/Input'

import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import DataActions from 'store/data/actions'

import Logic from './Logic'
import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import BaseDialog from 'react/dialogs/BaseDialog'
import { Button, Form } from 'react/visualization/dashboard/Dialogs/DialogStyles'

const PRE_TRANS = 'projectDataDialog.caster'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setLastLoadedCasterCatalogId: ApplicationActions.setLastLoadedCasterCatalogId,
  setError: ErrorActions.setError,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  saveCatalog: DataActions.saveCatalog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t: Translation & ArrayOfTranslations
}

type State = null

export class SelectCatalogDialog extends Component<Props, State> {
  static NAME = uuid()

  handleChangeCaster = (event: any) => {
    const { setCurrentCatalogId, setError } = this.props
    const { name, value } = event.target

    setError(name)

    if (value === 'add') {
      return Logic.handleUploadCasterCatalog(setCurrentCatalogId, setError, this.props)
    }

    setCurrentCatalogId(value)
  }

  handleDeleteCatalog = (_type: string, key: string) => {
    const { updateCurrentProjectContainer, currentSimulationCase } = this.props // TODO: this is not good!
    const data =
      ((currentSimulationCase as any).casterCatalogList as CasterCatalog[]).filter(val => val.catalogId !== key)

    ApiClient
      .del(`${Network.URI}/caster_catalog/${key}`)
      .then(() => updateCurrentProjectContainer('casterCatalogList', data))
      // eslint-disable-next-line no-console
      .catch((error: Error) => console.log(error))
  }

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectCatalogDialog.NAME)
  }

  handleLoadCatalog = () => {
    const {
      currentCatalogId,
      currentSimulationCase,
      closeDialog,
      saveCatalog,
      setLastLoadedCasterCatalogId,
    } = this.props
    const { casterCatalogList, _id } = currentSimulationCase
    let catalogId = currentCatalogId || ''

    if (!catalogId || catalogId === 'default') {
      catalogId = casterCatalogList[0]?.catalogId
    }

    if (!catalogId || catalogId === 'add') {
      return
    }

    ApiClient
      .get(`${Network.URI}/caster_catalog/${catalogId}?caseId=${_id}`)
      .then((response: any) => {
        const { casterCatalog } = response

        if (!casterCatalog?.data) {
          return
        }

        saveCatalog(casterCatalog.data)
        setLastLoadedCasterCatalogId(catalogId)

        closeDialog(SelectCatalogDialog.NAME)
      })
  }

  render () {
    const {
      t,
      currentSimulationCase,
      featureFlags,
      currentCatalogId,
      error,
    } = this.props

    const {
      casterCatalogList,
      simulationStartedAt,
    } = currentSimulationCase

    const catalogSelectors = Logic.getSelectors(
      casterCatalogList,
      'catalogId',
      'name',
      { value: t(`${PRE_TRANS}.catalog.default`), disabled: true },
    )
    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, casterCatalogList)

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.catalog.load`)}
        icon='pe-7s-folder'
        header={t(`${PRE_TRANS}.catalog.load`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='catalog'
            type='select'
            label={t(`${PRE_TRANS}.catalog.label`)}
            title={
              error.catalog
                ? t([ `error.${error.catalog}`, 'error.default' ])
                : t(`${PRE_TRANS}.catalog.title`)
            }
            error={error.catalog && t([ `error.${error.catalog}`, 'error.default' ])}
            value={selectedCatalog}
            selectors={
              FeatureFlags.canUploadNozzleCatalog(featureFlags)
                ? [
                  ...catalogSelectors,
                  {
                    key: 'add',
                    notRemovable: true,
                    value: t(`${PRE_TRANS}.catalog.add`),
                  },
                ]
                : [ ...catalogSelectors ]
            }
            onChange={this.handleChangeCaster}
            onDelete={FeatureFlags.canDeleteNozzleCatalog(featureFlags) && this.handleDeleteCatalog}
            disabled={Boolean(simulationStartedAt)}
          />
          <Button
            value=''
            onClick={this.handleLoadCatalog}
            title={t(`${PRE_TRANS}.catalog.load`)}
          >
            {t(`${PRE_TRANS}.catalog.load`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(SelectCatalogDialog as any) as any) as any
