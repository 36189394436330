import React, { Component, PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { withNamespaces } from 'react-i18next'

import DataActions from '../../../store/data/actions'

import { DEFINITION } from '../../../store/type/consts'

import Input from './Input'
import Section from '../Section'
import Icon from '../../specific/Icon'
import NozzleCatalog from './NozzleCatalog'
import Button from '../../specific/Button'
import LabelWithSuggestion from './LabelWithSuggestion'
import MirrorAndRepeat from './MirrorAndRepeat'

import CalculateHelperFunctions from './functions/CalculateHelperFunctions'
import FeatureFlags from '../../FeatureFlags'
import { DefaultState, ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'
import Util from 'three/logic/Util'
import CommentInput from './Input/CommentInput'
import FeatureFlagsUtil from 'react/FeatureFlags/util'
import CompareCasterInput from './Input/CompareCasterInput'
import { getCurrentDashboardEntry } from 'App/util'

const ErrorMessage = styled.p`${() => css`
  color: #FF0;
  margin: 5px auto;
  padding-left: 24px;
`}`

const Action = styled.div<{disabled?:boolean, viewer?:boolean}>`${({ disabled, viewer }) => css`
background:  ${disabled ? '#474b4e' : '#BB1B1B'};
color:       ${disabled ? '#999999' : '#FFFFFF'};
  position:    relative;
  min-height:  25px;
  font-size:   1em;
  margin:      ${viewer ? '0 5px 15px' : '10px 5px'};
  padding:     0.25em 1em;
  border:      2px solid ${disabled ? '#474b4e' : '#BB1B1B'};
  outline:     none;
  cursor:      ${disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  transition:  .2s;
  flex:        1;
  
  svg {
    position:  absolute;
    top:       50%;
    left:      50%;
    transform: translate(-50%,-50%);
  }
  &:hover {
    transition: .2s;
    border:     ${disabled ? '2px solid #474b4e' : '2px solid #A71A1A'};
    background: ${disabled ? '' : '#A71A1A'};
  }
`}`

const Form = styled.div`
  padding: 10px 10px 0 10px;
`

const ButtonsBox = styled.div`
  display:         flex;
  flex-wrap:       nowrap;
  flex-direction:  row;
  justify-content: space-between;

  > *:only-child > button {
    width: 100%;
  }
`

const InfoIcon = styled(Icon)`
  cursor:     pointer;
  font-size:  12px;
  
  &:hover {
    color: #E00A24;
  }
`

const FlexDiv = styled.div`
  flex: 25;
  display: flex;
`

const ComparisonCasterTitle = styled.div`
  display: flex;
  justify-content: center;
  min-width: 77px;
`

const ComparisonCasterTitlesContainer = styled.div`
  display: flex;
  margin-left: 150px;
`

const connector = connect((state: DefaultState) => ({
  editElements: state.data.editElements,
  editElementsInitial: state.data.editElementsInitial,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  hidePaths: state.data.hidePaths,
  catalogElement: state.data.catalogElement,
  rootData: state.data.rootData,
  hasEditChanges: state.data.hasEditChanges,
  editValues: state.data.editValues,
  createValid: state.data.createValid,
  loading: state.loading.loading,
  error: state.application.error,
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: state.application.main.authenticationData.featureFlags,
  ccElement: state.CCElement,
  viewsObject: state.visualization.viewsObject,
  comparisonCasters: state.ComparisonCasters,
  currentProject: state.application.main.currentProject,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  currentDashboard: state.visualization.currentDashboard,
  AirLoop: state.AirLoop,
  CoolingLoop: state.CoolingLoop,
  CoolingZone: state.CoolingZone,
  LoopAssignment: state.LoopAssignment,
  Nozzle: state.Nozzle,
  Roller: state.Roller,
  RollerBearing: state.RollerBearing,
  RollerBody: state.RollerBody,
  SupportPoint: state.SupportPoint,
  SegmentGroupSupportPoints: state.SupportPoint,
  Segment: state.Segment,
  SegmentGroup: state.SegmentGroup,
  SensorPoint: state.SensorPoint,
  StrandGuide: state.StrandGuide,
  DataPoint: state.DataPoint,
  DataLine: state.DataLine,
}), {
  setElements: DataActions.setElements,
  setParentPath: DataActions.setParentPath,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  type: CasterDialogElementType
  path: string
  paths: string[]
  allPaths?: string[]
  hideActions?: boolean
  target: any[]
  filterValue: any
  selectedParentIds: any
  onSave: (uuidsHash: any) => void
  onCreateOrCopy: () => void
  massForm: boolean
  onInput:({ target }:{ target: {name: string, value:any}})=>void
  onDeleteElement:() => void
  onUndo:(event:any)=>void
  direction: string
  onPatternInput:(event:any)=>void
  onMirrorElements:()=>void
  onPatternUndo:(event:any)=>void
  copies:number
  copyMode: string
  offset:number
  gapOffset:number
  onPatternApply:()=>void
  t (key: string | string[], params?: Record<string, unknown>): string
  isComparingCasters?: boolean
}

export class FormBuilder extends PureComponent<Props> {
  state = {}
  featureFlags: Record<string, boolean> = {}
  elementsHashes: ElementsHashes = {} as ElementsHashes

  constructor (props: Props) {
    super(props)
    const { paths, setElements, setParentPath, currentSimulationCase } = props

    this.featureFlags = FeatureFlags.getRealFeatureFlags({
      application:
        { main: { authenticationData: { featureFlags: props.featureFlags, currentSimulationCase } } },
    })

    this.elementsHashes = {
      AirLoop: props.AirLoop,
      CoolingLoop: props.CoolingLoop,
      CoolingZone: props.CoolingZone,
      LoopAssignment: props.LoopAssignment,
      Nozzle: props.Nozzle,
      Roller: props.Roller,
      RollerBearing: props.RollerBearing,
      RollerBody: props.RollerBody,
      SupportPoint: props.SupportPoint,
      SegmentGroupSupportPoints: props.SupportPoint,
      Segment: props.Segment,
      SegmentGroup: props.SegmentGroup,
      SensorPoint: props.SensorPoint,
      StrandGuide: props.StrandGuide,
      DataPoint: props.DataPoint,
      DataLine: props.DataLine,
    }

    if (paths) {
      setElements(paths)
      setParentPath()
    }
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props) {
    const { paths, setElements, setParentPath, featureFlags, currentSimulationCase } = this.props

    if (paths && !paths.equals(prevProps.paths)) {
      setElements(paths)
      setParentPath()
    }

    if (prevProps.featureFlags !== this.props.featureFlags) {
      this.featureFlags = FeatureFlags.getRealFeatureFlags({
        application:
          { main: { authenticationData: { featureFlags: featureFlags, currentSimulationCase } } },
      })
    }

    if (prevProps.AirLoop !== this.props.AirLoop) {
      this.elementsHashes.AirLoop = this.props.AirLoop
    }

    if (prevProps.CoolingLoop !== this.props.CoolingLoop) {
      this.elementsHashes.CoolingLoop = this.props.CoolingLoop
    }

    if (prevProps.CoolingZone !== this.props.CoolingZone) {
      this.elementsHashes.CoolingZone = this.props.CoolingZone
    }

    if (prevProps.LoopAssignment !== this.props.LoopAssignment) {
      this.elementsHashes.LoopAssignment = this.props.LoopAssignment
    }

    if (prevProps.Nozzle !== this.props.Nozzle) {
      this.elementsHashes.Nozzle = this.props.Nozzle
    }

    if (prevProps.Roller !== this.props.Roller) {
      this.elementsHashes.Roller = this.props.Roller
    }

    if (prevProps.RollerBearing !== this.props.RollerBearing) {
      this.elementsHashes.RollerBearing = this.props.RollerBearing
    }

    if (prevProps.RollerBody !== this.props.RollerBody) {
      this.elementsHashes.RollerBody = this.props.RollerBody
    }

    if (prevProps.SupportPoint !== this.props.SupportPoint) {
      this.elementsHashes.SupportPoint = this.props.SupportPoint
    }

    if (prevProps.SegmentGroupSupportPoints !== this.props.SegmentGroupSupportPoints) {
      this.elementsHashes.SegmentGroupSupportPoints = this.props.SegmentGroupSupportPoints
    }

    if (prevProps.Segment !== this.props.Segment) {
      this.elementsHashes.Segment = this.props.Segment
    }

    if (prevProps.SegmentGroup !== this.props.SegmentGroup) {
      this.elementsHashes.SegmentGroup = this.props.SegmentGroup
    }

    if (prevProps.SensorPoint !== this.props.SensorPoint) {
      this.elementsHashes.SensorPoint = this.props.SensorPoint
    }

    if (prevProps.StrandGuide !== this.props.StrandGuide) {
      this.elementsHashes.StrandGuide = this.props.StrandGuide
    }

    if (prevProps.DataPoint !== this.props.DataPoint) {
      this.elementsHashes.DataPoint = this.props.DataPoint
    }

    if (prevProps.DataLine !== this.props.DataLine) {
      this.elementsHashes.DataLine = this.props.DataLine
    }
  }

  // @AnalyzeTime(0)
  handleMassValue = (key: string, firstVal: any, isCompareCaster = false, elementsHashes?: any) => {
    const { paths, editElements } = this.props
    let val = true

    if (paths?.length < 2 || (isCompareCaster && !elementsHashes)) {
      return false
    }

    paths.forEach((path) => {
      // TODO: fix type
      if (isCompareCaster) {
        const { type, id } = Util.getElementInfo(path)

        if (
          ((elementsHashes || {})[type] || {})[id] &&
          (((elementsHashes || {})[type] || {})[id] || {})[key] !== firstVal
        ) {
          val = false
        }

        return
      }

      if ((editElements as any)[path] && ((editElements as any)[path]as any)[key] !== firstVal) {
        val = false
      }
    })

    return val
  };

  // @AnalyzeTime(0)
  handleHelper = (type: string, value:any) => {
    const { onInput } = this.props
    const name = type[0] === '_' ? type : `_${type}`

    onInput({ target: { name, value } })
  };

  // @AnalyzeTime(0)
  handleGetLabel = (key: string, massValue: boolean, elementValue: any) => {
    const {
      catalogElement,
      editElements,
      type,
      selectedParentIds,
      hidePaths,
      t,
    } = this.props
    const infoIcon = <InfoIcon icon='info-circle' />
    const label = t([ `formBuilder.keys.${key.substring(1)}.label`, key.substring(1) ]) || key.substring(1)

    if (
      FeatureFlags.canEditNozzle(this.featureFlags) &&
      key === '_passln_coord' &&
      type === 'Nozzle'
    ) {
      const passLn = []

      Object.values(editElements).forEach(element =>
        passLn.push(Number(element._passln_coord)))

      if (!passLn.length) {
        passLn.push(elementValue)
      }

      const value = CalculateHelperFunctions
        .calculatePassLn(Math.min(...passLn), selectedParentIds, this.elementsHashes, hidePaths)

      if (Number(elementValue) === Number(value)) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.same')}
            type={type}
          />
        )
      }

      if (!value) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.notFound')}
            type={type}
          />
        )
      }

      return (
        <LabelWithSuggestion
          onHelper={this.handleHelper}
          name={label}
          value={value || null}
          display={`(${value || null})`}
          title={t('formBuilder.suggestion.set')}
          type={type}
        />
      )
    }

    if (massValue && elementValue !== undefined && elementValue === catalogElement[key]) {
      return (
        <LabelWithSuggestion
          name={label}
          value={infoIcon}
          title={t('formBuilder.suggestion.equal')}
          type={type}
        />
      )
    }

    return (
      <LabelWithSuggestion
        onHelper={this.handleHelper}
        name={label}
        value={catalogElement[key] || null}
        display={`(${catalogElement[key] || null})`}
        title=''
        type={type}
      />
    )
  };

  handleSave = () => {
    const { onSave } = this.props

    onSave(this.state)
  }

  handleChangeItem = (type: string, key: string, comment: string) => {
    // make a new hash for each element with the uuid as key and rest of object as value
    const newState: any = {}

    if (type === undefined || key === undefined) {
      return
    }

    if (!newState[type]) {
      newState[type] = {}
    }

    newState[type][key] = comment

    this.setState(newState)
  }

  handleSavePermissions = () => {
    const { type, paths } = this.props

    if (type !== 'SensorPoint') {
      return FeatureFlags.canEditElement(type, this.featureFlags)
    }

    const sensorPointLocations: any = FeatureFlagsUtil.getSensorPointLocations(paths, this.elementsHashes)
    const sensorPointEditPermissions: any = this.getSensorPointEditPermissions()
    const permissions = Object.keys(sensorPointEditPermissions)

    for (const permission of permissions) {
      if (sensorPointLocations[permission] && !sensorPointEditPermissions[permission]) {
        return false
      }
    }

    return true
  }

  getSensorPointEditPermissions = () => {
    return {
      MoldFace: FeatureFlags.canEditSensorPointInMoldFace(this.featureFlags),
      RollerBody: FeatureFlags.canEditSensorPointInRollerBody(this.featureFlags),
      RollerBearing: FeatureFlags.canEditSensorPointInRollerBearing(this.featureFlags),
      Roller: FeatureFlags.canEditSensorPointInRoller(this.featureFlags),
      Segment: FeatureFlags.canEditSensorPointInSegment(this.featureFlags),
      Nozzle: FeatureFlags.canEditSensorPointInNozzle(this.featureFlags),
    }
  }

  getCCWarning = (key: string, comments: any, justReturnBoolean = false) => {
    if (!comments[key] || !comments[key].text) {
      return
    }

    if (justReturnBoolean) {
      return true
    }

    return (
      <div
        style={
          {
            width: 'fit-content',
            margin: '3px',
            padding: '6px',
            paddingLeft: '23px',
            color: '#fcc203',
          }
        }
      >
        {comments[key].text}
      </div>)
  }

  getCCComments = (categories: any) => {
    const { ccElement, path, type } = this.props
    let { paths } = this.props

    if (!paths.length) {
      paths = [ path ]
    }

    if (!paths.length || !ccElement) {
      return {}
    }

    const keysWithCCErrors: any = {}

    const keys: string[] = []

    Object.values(categories || {}).forEach((cat: any) => {
      (cat || []).forEach((key: string) => keys.push(key))
    })

    keys.forEach(key => {
      paths.forEach(path => {
        if (!path) {
          return
        }

        const { id } = Util.getElementInfo(path)
        const element = (this.elementsHashes as any)[type][id]

        const elementKeysWithUUID = Object.keys(element || {})
          .filter(key => key.includes('uuid'))
          .map(key => key.replace('_uuid', ''))

        if (!elementKeysWithUUID.includes(key)) {
          return {}
        }

        const ccElements = Object.keys(ccElement || {})

        for (let i = 0; i < ccElements.length; i++) {
          if (ccElements[i].includes(element[`${key}_uuid`])) {
            if (!keysWithCCErrors[key]) {
              keysWithCCErrors[key] = {
                text: ccElement[ccElements[i]]._text,
                n: 1,
                uuid: ccElement[ccElements[i]]._uuid,
              }
            }
            else if (keysWithCCErrors[key].uuid === ccElement[ccElements[i]]._uuid) {
              keysWithCCErrors[key].n++
            }
          }
        }
      })
    })

    Object.keys(keysWithCCErrors || {}).forEach(key => {
      if (keysWithCCErrors[key].n !== paths.length) {
        delete keysWithCCErrors[key]
      }
    })

    return keysWithCCErrors
  }

  // function that returns an input of type text if editElements[key] is not equal to editElementsInitial[key]
  getChangeItemInput = (key: string) => {
    const { type, editElements, editElementsInitial, paths } = this.props

    if (type === 'CCElement' || key.includes('_uuid')) {
      return null
    }

    const someValueChanged = paths.some(path => {
      if (!path || !editElements[path] || !editElements[path][key]) {
        return false
      }

      // if actual value is different than the initial value return true
      return editElements[path][key] !== editElementsInitial[path][key]
    })

    const keyUUID = `${key}_uuid`

    // use paths.some to get the first path that has a value for the key keyUUID
    const someElementHasKey = paths.some(path => {
      if (!path) {
        return false
      }

      const { id } = Util.getElementInfo(path)

      if (!id) {
        return false
      }

      const element = (this.elementsHashes as any)[type][id]

      if (!element) {
        return false
      }

      return element[keyUUID] !== undefined
    })

    if (someValueChanged && someElementHasKey) {
      return (
        <CommentInput placeholder='Comment' type={type} elementKey={key} onChange={this.handleChangeItem} />
      )
    }
  }

  // TODO: we could improve this, we do too many calculations, we could get the item only 1 time
  getComparisonCasterInputs = (def: any, key: string) => {
    const {
      type,
      path,
      paths,
      massForm,
      comparisonCasters,
      viewsObject,
      currentProject,
      currentDashboard,
      currentSimulationCase,
      amountOfComparisonCasterColumns,
    } = this.props

    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const selectedComparisonCasters = viewObject?.selectedComparisonCasters
    const cases = currentProject.simulationCases
    const compareCasterInputs: any = []
    const canViewAttributes = this.canViewAttributes()

    if (
      !selectedComparisonCasters?.length ||
      !Object.values(comparisonCasters).length ||
      !amountOfComparisonCasterColumns ||
      !paths.length
    ) {
      return null
    }

    const { id } = Util.getElementInfo(paths[0])
    const element = this.elementsHashes[type as CasterElementNames][id]

    const selectedCasesInOrder = cases
      .map(caseObject => caseObject._id)
      .filter(caseId => selectedComparisonCasters.includes(caseId))
      .filter(caseId => caseId !== currentSimulationCase._id)

    for (let i = 0; i < amountOfComparisonCasterColumns; i++) {
      const caseId = selectedCasesInOrder[i]
      const elementsHashes = comparisonCasters[caseId]?.elementsHashes
      let compareElement: any

      // move this to render function
      if (type === 'DataLine' || type === 'DataPoint') {
        if (element._name) {
          compareElement = Util.getCompareElementByName(element._name, type, elementsHashes)
        }
      }
      else if (type === 'SegmentGroupSupportPoints' || type === 'SupportPoint') {
        const realType = type === 'SegmentGroupSupportPoints' ? 'SupportPoint' : type as CasterElementNames

        if (element._name) {
          compareElement = Util.getCompareElementByNameAndParent(element._name, paths[0], realType, elementsHashes)
        }
      }
      else if (type === 'SegmentGroup') {
        compareElement = Util.getCompareElementById(element._id, type, elementsHashes)
      }
      else {
        compareElement = Util.getCompareElementByWidthAndPassLnCoordAndSide(
          element,
          type as CasterElementNames,
          this.elementsHashes,
          elementsHashes,
        )
      }

      const elementKeysObject = compareElement ? { ...compareElement } : {}

      const newCompareCasterInput =
        <CompareCasterInput
          key={`${key}${i}`}
          name={key}
          value={
            path && def.generate
              ? elementsHashes ? def.generate(elementKeysObject, elementsHashes) : undefined
              : (elementKeysObject as any)[key]
          }
          elementType={type}
          error={massForm ? this.handleMassValue(key, (elementKeysObject as any)[key], true, elementsHashes) : true}
          massValues={massForm}
          path={path}
          hideValue={!canViewAttributes}
        />

      compareCasterInputs.push(newCompareCasterInput)
    }

    return (
      <div style={{ display: 'flex' }}>
        {compareCasterInputs.map((compareCasterInput: any) => compareCasterInput)}
      </div>
    )
  }

  getComparisonCasterColumnTitles = () => {
    const {
      amountOfComparisonCasterColumns,
      currentProject,
      currentDashboard,
      viewsObject,
      type,
      currentSimulationCase,
    } = this.props

    if (type === 'General' || !amountOfComparisonCasterColumns) {
      return null
    }

    const caseIds = currentProject.simulationCases.map(fullCase => fullCase._id)
    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const selectedComparisonCasters = viewObject?.selectedComparisonCasters || []
    let comparisonCasterCounter = 1
    const comparisonCasterTitles = caseIds
      .map(() => `C${comparisonCasterCounter++}`)

    const shownComparisonCasters = []
    const currentCaseIdIndex = caseIds.findIndex(caseId => currentSimulationCase._id === caseId)

    shownComparisonCasters.push(`C${currentCaseIdIndex + 1}(R)`)

    for (let i = 0; i < caseIds.length; i++) {
      const caseId = caseIds[i]

      if (selectedComparisonCasters.includes(caseId)) {
        shownComparisonCasters.push(comparisonCasterTitles[i])
      }
    }

    return (
      <ComparisonCasterTitlesContainer>
        {
          shownComparisonCasters
            .slice(0, amountOfComparisonCasterColumns + 1)
            .map(title => (
              <ComparisonCasterTitle key={title}>
                {title}
              </ComparisonCasterTitle>
            ))
        }
      </ComparisonCasterTitlesContainer>
    )
  }

  getInvalidWidthValueMessage = () => {
    const { editValues, rootData } = this.props
    const { Mold } = (rootData || {}).Caster || {}

    if (!Mold || !editValues.General || !editValues.General._width) {
      return null
    }

    if (Mold._widthMin !== undefined && Mold._widthMin > editValues.General._width) {
      return <ErrorMessage>Invalid width, min value is: {Mold._widthMin}</ErrorMessage>
    }

    if (Mold._widthMax !== undefined && Mold._widthMax < editValues.General._width) {
      return <ErrorMessage>Invalid width, max value is: {Mold._widthMax}</ErrorMessage>
    }
  }

  canViewAttributes = (): boolean => {
    const { type, paths } = this.props

    // TODO: feature flags for CC element
    if (type === 'CCElement') {
      return true
    }

    if (type === 'SensorPoint') {
      return FeatureFlags.canViewSensorPointAttributesInCurrentLocations(this.featureFlags, paths, this.elementsHashes)
    }

    return FeatureFlags.canViewTypesAttributes(type, this.featureFlags)
  }

  supportPointsValuesValid = () => {
    const { type } = this.props

    if (type !== 'SegmentGroupSupportPoints') {
      return true
    }

    const { allPaths, editElements } = this.props

    let valid = true

    for (const path of allPaths ?? []) {
      const { _shim_propose: propose } = editElements[path]

      if (propose === '' || propose === undefined) {
        valid = false
      }
    }

    return valid
  }

  @AnalyzeTime(0)
  render () {
    const {
      filterValue,
      type,
      path,
      hideActions,
      editElements,
      massForm,
      dirtyDeletePaths,
      onCreateOrCopy,
      onInput: handleInput,
      onDeleteElement: handleDeleteElement,
      loading,
      hasEditChanges,
      onUndo: handleUndo,
      paths,
      target,
      direction,
      onPatternInput: handlePatternInput,
      onPatternUndo: handlePatternUndo,
      copies,
      copyMode,
      offset,
      gapOffset,
      onMirrorElements: handleMirrorElements,
      createValid,
      onPatternApply,
      error,
      authenticationData,
      currentSimulationCase,
      isComparingCasters,
      SegmentGroup,
      SupportPoint,
      t,
    } = this.props

    const definition = DEFINITION[type]

    let elementKeysObject = {}

    if (path) {
      for (const singlePath of paths) {
        elementKeysObject = {
          ...elementKeysObject,
          ...(editElements[singlePath] || {}),
        }
      }
    }

    const element = path ? elementKeysObject : Object.keys(definition.fields).reduce((acc, key) => ({
      ...acc,
      [key]: filterValue[key],
    }), {})

    const elementKeys = Object.keys({
      ...definition.fields,
      ...element,
    }).filter(key => (
      key[0] === '_' && // only attributes
      (
        !definition.fields[key] || // if not in definition -> "other" attributes
        ( // handle hidden
          typeof definition.fields[key].hidden !== 'function'
            ? !definition.fields[key].hidden // boolean | undefined
            : !definition.fields[key].hidden(this.props) // function
        )
      )
    ))

    const categories = elementKeys.reduce((cat, key) => {
      const { category } = definition.fields[key] || { category: 0 }

      return {
        ...cat,
        [category]: [
          ...((cat as any)[category] || []), // TODO: Fix type
          key,
        ],
      }
    }, {})

    const categoryList = Object.keys(categories)

    const passLn = []

    Object.values(editElements).forEach(element =>
      passLn.push(Number(element._passln_coord)))

    const isDeleted = dirtyDeletePaths.indexOf(path) > -1

    const { isLocked } = FeatureFlags.getLockedStatus(type, currentSimulationCase, authenticationData)

    const comments = this.getCCComments(categories)

    const invalidWidthMessage = this.getInvalidWidthValueMessage()

    const canEditElement = FeatureFlags.canEditElement(type, this.featureFlags)
    const canViewAttributes = this.canViewAttributes()

    return (
      <Form>
        {
          categoryList.map(category =>
            <Section
              key={category}
              name={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props)
                  : t([
                    `formBuilder.categories.${definition.categories[category]}.label`,
                    'formBuilder.categories.default',
                  ])
              }
              title={
                typeof definition.categories[category] === 'function'
                  ? definition.categories[category](this.props)
                  : t(`formBuilder.categories.${definition.categories[category]}.title`)
              }
              spaceForComparisonCasterTitle={isComparingCasters && type !== 'General'}
            >
              {this.getComparisonCasterColumnTitles()}
              {
                (categories as any)[category].map((key: any, index: number) => (def => {
                  return (
                    <div
                      key={`cat${index}`}
                      style={
                        this.getCCWarning(key, comments, true)
                          ? { borderLeft: '8px solid #fcc203', borderBottom: '1px solid #fcc203', marginLeft: '-8px' }
                          : undefined
                      }
                    >
                      <div style={{ display: 'flex', height: '31px' }}>
                        <Input
                          key={key}
                          label={
                            this.handleGetLabel(key, this.handleMassValue(
                              key,
                              (element as any)[key],
                            ), (element as any)[key])
                          }
                          title={t(`formBuilder.keys.${key.substring(1)}.title`)}
                          name={key}
                          type={def.type || 'text'}
                          min={
                            def.min ??
                            (def.computeMin && def.computeMin(element)) ??
                            undefined
                          }
                          max={
                            def.max ??
                            (def.computeMax && def.computeMax(element)) ??
                            undefined
                          }
                          value={
                            path && def.generate
                              ? def.generate(element, this.elementsHashes) ?? ''
                              : (element as any)[key] ?? ''
                          }
                          elementType={type}
                          error={massForm ? this.handleMassValue(key, (element as any)[key]) : true}
                          disabled={
                            isComparingCasters ||
                          !canViewAttributes ||
                          !canEditElement ||
                          (
                            !path
                              ? false
                              : (def.disabled ?? (
                                def.computeDisabled &&
                                def.computeDisabled(element, editElements, SegmentGroup, SupportPoint)
                              ))
                          )
                          }
                          options={def.options}
                          handleUndo={handleUndo}
                          hasFilter={type === 'DataLine' ? !(key === '_xCoords' || key[1] === 'y') : true}
                          onChange={handleInput}
                          path={path}
                          hideValue={!canViewAttributes}
                          isComparingCasters={isComparingCasters}
                          onlyPositiveNumbers={def.type === 'number' && def.positive}
                        />
                        {type !== 'General' && this.getComparisonCasterInputs(def, key)}
                      </div>
                      {type === 'General' && path && invalidWidthMessage}
                      {this.getChangeItemInput(key)}
                      {this.getCCWarning(key, comments)}
                    </div>
                  )
                }
                )(definition.fields[key] || {}))
              }
            </Section>)
        }
        {type === 'Nozzle' && !isComparingCasters && <NozzleCatalog path={path} type={type} />}
        {
          (
            (type === 'Nozzle' && FeatureFlags.canEditNozzle(this.featureFlags)) ||
            (type === 'Roller' && FeatureFlags.canEditRoller(this.featureFlags))
          ) &&
            <MirrorAndRepeat
              type={type}
              paths={paths}
              target={target}
              direction={direction}
              copyMode={copyMode}
              handleInput={handlePatternInput}
              handleUndo={handlePatternUndo}
              copies={copies}
              offset={offset}
              gapOffset={gapOffset}
              handlePatternApply={onPatternApply}
            />
        }
        <ButtonsBox>
          {
            !hideActions && !isLocked && this.handleSavePermissions() &&
              <FlexDiv>
                <Button
                  onClick={this.handleSave}
                  disabled={
                    isComparingCasters ||
                    (!path && type !== 'General') ||
                    (!(hasEditChanges as any || {})[type]) ||
                    !!invalidWidthMessage ||
                    !this.supportPointsValuesValid()
                  }
                  loading={(loading as any || {})[type] && ((loading as any || {})[type] || {}).apply}
                  error={!!error['caster/apply']}
                  value={t('formBuilder.apply')}
                  title={t(`formBuilder.${isDeleted ? 'applyDeletion' : 'applyChanges'}`)}
                />
                {
                  ![ 'General', 'SegmentGroup', 'SegmentGroupSupportPoints', 'SupportPoint' ].includes(type) &&
                    <Button
                      onClick={onCreateOrCopy}
                      value={t(`formBuilder.${path ? 'copy' : 'create'}`)}
                      error={!!error[`caster/${path ? 'copy' : 'create'}${type}`]}
                      loading={(loading as any || {})[type] && ((loading as any || {})[type] || {}).clone}
                      disabled={
                        isComparingCasters ||
                        path
                          ? !(hasEditChanges as any || {})[type]
                          : !((hasEditChanges as any || {})[type] && (createValid as any || {})[type])
                      }
                    />
                }
                {
                  type === 'Nozzle' &&
                    <Button
                      onClick={handleMirrorElements}
                      value={t('formBuilder.mirror.label')}
                      title={t('formBuilder.mirror.title')}
                      loading={(loading || {})[type] && ((loading || {})[type] || {}).mirror}
                      error={!!error[`caster/mirror${type}`]}
                      disabled={isComparingCasters}
                    />
                }
                {
                  ![ 'General', 'SegmentGroup', 'SegmentGroupSupportPoints', 'SupportPoint' ].includes(type) &&
                    <Action
                      onClick={path ? handleDeleteElement : () => null}
                      disabled={isComparingCasters || !path}
                      title={t(`formBuilder.${isDeleted ? 'restore' : 'mark'}`)}
                    >
                      {isDeleted ? <Icon icon='undo' /> : <Icon icon='trash' />}
                    </Action>
                }
              </FlexDiv>
          }
          {
            (
              !hideActions &&
              (
                (type === 'Nozzle' && !FeatureFlags.canEditNozzle(this.featureFlags)) ||
                (type === 'Roller' && !FeatureFlags.canEditRoller(this.featureFlags)) ||
                (
                  type === 'General' &&
                  !FeatureFlags.canEditMold(this.featureFlags)
                )
              )
            ) &&
              <Action
                onClick={handleUndo}
                viewer={!canEditElement}
                disabled={
                  isComparingCasters ||
                  !(hasEditChanges || {})[type] || ((hasEditChanges || {})[type] && isDeleted)
                }
                title={t('formBuilder.reset')}
              >
                <Icon icon='reply' />
              </Action>
          }
        </ButtonsBox>
      </Form>
    )
  }
}

export default withNamespaces('caster')(connector(FormBuilder as any) as any) as any
