import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from 'react/components/Button/index'
import { Form, Text, TextBody, List } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import { FixedButton } from '../../DialogStyles'
import * as ApplicationActions from 'store/application/main/actions'
import * as MatrixActions from 'store/matrix/actions'

import Logic from '../Logic'

import BaseDialog from '../../BaseDialog'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.resetSimulationDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
}), {
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string,
  loading: boolean
};

class ResetSimulationDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Enter', this.handleResetSelected)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleResetSelected)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ResetSimulationDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleResetSelected = () => {
    const { selections, setSelections } = this.props
    const selectedSimulationCases = this.getSelectedSimulatedSimulationCases()
    const ids = selectedSimulationCases.map(({ simulationCase }) => simulationCase._id)

    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })
    setSelections(deselect)

    Logic.handleResetSelected(ids)
    this.handleClose()
  };

  // @AnalyzeTime(0)
  getSelectedSimulatedSimulationCases = () => {
    const { currentProject, selections } = this.props

    return currentProject.simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => (
        selections[simulationCase._id] &&
        simulationCase.simulationDataReceivedAt
      ))
  };

  @AnalyzeTime(0)
  render () {
    const { error, loading } = this.state
    const { t } = this.props

    const selectedSimulationCases = this.getSelectedSimulatedSimulationCases()

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-display1'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${PRE_TRANS}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                selectedSimulationCases.map(({ num, simulationCase }) =>
                  <li key={simulationCase._id}>{num}. {simulationCase.name}</li>)
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleResetSelected}
              error={error}
              loading={loading}
              icon='pe-7s-display1'
              isRef
            >
              {t(`${PRE_TRANS}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(ResetSimulationDialog as any) as any) as any
