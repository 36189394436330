import React, { PureComponent, ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import sizeMe from 'react-sizeme'
import Input from 'react/specific/Input'

import GridItemContainer from '../GridItemContainer'
import GridLayout from 'react-grid-layout'

import * as VisualizationActions from 'store/visualization/actions'
import * as ApplicationActions from 'store/application/main/actions'
import * as ComparisonCastersActions from 'store/comparison-casters'

import {
  DashboardMenu,
  GridViewContainer,
  GridScrollbar,
  AddPlotGridItem,
  DashboardMenuAction,
  ScrollHint,
  ComparisonCasterMenu,
} from '../Dashboard/styles'

import Logic from './Logic'

import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { CurrentDashboard } from 'types/visualization'
import FeatureFlags from 'react/FeatureFlags'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { OptionsObject, withSnackbar } from 'notistack'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  viewsObject: state.visualization.viewsObject,
  isEditModeOn: state.visualization.isEditModeOn,
  visualizationState: state.visualization,
  currentProject: state.application.main.currentProject,
  comparisonCasters: state.ComparisonCasters,
  currentSimulationCase: state.application.main.currentSimulationCase,
  openDialogs: state.application.main.openDialogs,
  featureFlags: state.application.main.authenticationData.featureFlags,
  appState: state.application.main.appState,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
}), {
  setConfig: VisualizationActions.setConfig,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
  splitView: VisualizationActions.splitView,
  deleteSplitView: VisualizationActions.deleteSplitView,
  showEditDashboardDialog: VisualizationActions.showEditDashboardDialog,
  autoscaleGrid: VisualizationActions.autoscaleGrid,
  resetAutoscaling: VisualizationActions.resetAutoscaling,
  showDeleteDashboardDialog: VisualizationActions.showDeleteDashboardDialog,
  setDashboardObject: VisualizationActions.setDashboardObject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setAppState: ApplicationActions.setAppState,
  setComparisonCasters: VisualizationActions.setComparisonCasters,
  addComparisonCaster: ComparisonCastersActions.addComparisonCaster,
  setCurrentCasterDialogWidth: VisualizationActions.setCurrentCasterDialogWidth,
  setAmountOfComparisonCasterColumns: VisualizationActions.setAmountOfComparisonCasterColumns,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  viewId: string,
  children: any,
  layout: Array<any>,
  oldLayout: Array<any>,
  size: any,
  dashboard: CurrentDashboard,
  tileId: string,
  currentDashboard: string,
  showTabs: boolean,
  simple: boolean,
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  t: Translation,
}

// TODO: Handle via Reducer because this may be buggy
type State = {
  autoscaled: boolean
};

export class ViewGrid extends PureComponent<Props, State> {
  wrapperRef?: HTMLElement;
  waitingForComparisonCaster: null | string = null

  state = {
    autoscaled: false,
  }

  componentDidUpdate (prevProps: Props) {
    const { currentDashboard, comparisonCasters, viewsObject, viewId } = this.props

    this.handleResize()

    if (prevProps.currentDashboard !== currentDashboard) {
      this.setState({
        autoscaled: false,
      })
    }

    const { selectedComparisonCasters } = viewsObject[viewId] || {}

    if (
      this.waitingForComparisonCaster &&
      comparisonCasters[this.waitingForComparisonCaster] &&
      (selectedComparisonCasters || []).includes(this.waitingForComparisonCaster)
    ) {
      this.waitingForComparisonCaster = null
    }
  }

  handleLayoutChange = (layout: Array<any>) => {
    const {
      layout: layouts,
      viewId,
      currentDashboard,
      visualizationState,
      setConfig,
      plotConfigs,
      tileConfigs,
    } = this.props

    if (layout && layout.length && !layout.equals(layouts)) {
      const viewsObject = Logic.getLayout(
        visualizationState,
        { layout, viewId, dashboardId: currentDashboard },
      )

      setConfig({ viewsObject, plotConfigs, tileConfigs })
    }
  };

  handleEditDashboard = () => {
    const { viewId, currentDashboard: dashboardId, showEditDashboardDialog } = this.props

    showEditDashboardDialog({ viewId, dashboardId }, true)
  };

  handleAddPlot = () => {
    const { viewId, currentDashboard, showAddPlotDialog } = this.props

    if (viewId && currentDashboard) {
      showAddPlotDialog(viewId, true)
    }
  };

  handleExportPlot = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog()
  };

  handleWheel = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const target = this.wrapperRef

    if (!event.shiftKey) {
      target.scrollTop += event.deltaY
    }

    if (event.shiftKey) {
      target.scrollLeft += event.deltaY
    }

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }

    if (target.scrollLeft === 0) {
      target.setAttribute('data-arrow-left', 'off')
    }
    else {
      target.setAttribute('data-arrow-left', 'on')
    }

    if (target.offsetWidth + target.scrollLeft === target.scrollWidth) {
      target.setAttribute('data-arrow-right', 'off')
    }
    else {
      target.setAttribute('data-arrow-right', 'on')
    }
  };

  handleWrapperRef = (ref: any) => {
    if (ref) {
      this.wrapperRef = ref
    }
  };

  handleResize = () => {
    this.handleWheel({
      target: this.wrapperRef,
      deltaY: 0,
    })
  };

  handleAutoscaling = () => {
    const { layout, viewId, currentDashboard, autoscaleGrid, size, oldLayout, resetAutoscaling } = this.props
    const { autoscaled } = this.state

    if (autoscaled && oldLayout) {
      resetAutoscaling(oldLayout, viewId, currentDashboard)

      this.setState({
        autoscaled: false,
      })
    }
    else {
      autoscaleGrid(
        layout,
        viewId,
        currentDashboard,
        Logic.FULL_HD_VIEW_HEIGHT,
        Logic.FULL_HD_VIEW_WIDTH,
        size.height,
        size.width,
        Logic.GRID_COLUMNS, // TODO: is this correct?
      )

      this.setState({
        autoscaled: true,
      })
    }
  };

  handleDeleteDashboard = (event: any) => {
    const { showDeleteDashboardDialog, viewId, currentDashboard } = this.props

    showDeleteDashboardDialog(viewId, currentDashboard)
  };

  handleComparisonCasterSelectViaCheckbox = async (checked: boolean, comparisonCasterId: string) => {
    // query the simulation case with the compareCasterId
    // then add to state
    const {
      viewId,
      viewsObject,
      currentSimulationCase,
      currentProject,
      comparisonCasters,
      featureFlags,
      visualizationMetaInformation,
      appState,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setAmountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      setComparisonCasters,
      addComparisonCaster,
      enqueueSnackbar,
    } = this.props

    if (!FeatureFlags.canEditCasterComparison(featureFlags, visualizationMetaInformation, appState)) {
      return
    }

    const { selectedComparisonCasters } = viewsObject[viewId] || {}

    if (comparisonCasterId === currentSimulationCase._id || this.waitingForComparisonCaster) {
      return
    }

    const cleanSelectedComparisonCasters = [
      ...(selectedComparisonCasters || []).filter(id =>
        currentProject.simulationCases.map(simulationCase => simulationCase._id)
          .includes(id)),
    ]

    const index = cleanSelectedComparisonCasters.indexOf(comparisonCasterId)

    if (index === -1) {
      if (cleanSelectedComparisonCasters.length >= 5) {
        enqueueSnackbar('Only 5 casters selectable simultaneously', { autoHideDuration: 4000, variant: 'warning' })

        return
      }

      cleanSelectedComparisonCasters.push(comparisonCasterId)

      if (!comparisonCasters[comparisonCasterId]) {
        const { data: { root } } = await ApiClient
          .get(`${Network.URI}/caster_data/${comparisonCasterId}?format=js`)

        addComparisonCaster({ simulationCaseId: comparisonCasterId, root }, cleanSelectedComparisonCasters, false)
      }

      this.waitingForComparisonCaster = comparisonCasterId
      setComparisonCasters(viewId, cleanSelectedComparisonCasters)

      return
    }

    if (cleanSelectedComparisonCasters.length === amountOfComparisonCasterColumns) {
      setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns - 1)

      if (currentCasterDialogWidth !== 335) {
        setCurrentCasterDialogWidth(currentCasterDialogWidth - 77)
      }
    }

    setComparisonCasters(viewId, cleanSelectedComparisonCasters.filter(id => id !== comparisonCasterId))
  }

  handleComparisonCasterSelectViaOption = (e: any) => {
    if (!e.target.value || !e.target.value.length) {
      return
    }

    this.handleComparisonCasterSelectViaCheckbox(false, e.target.value[e.target.value.length - 1])
  }

  getSimulationCaseNameFormatted = (name: string, isReference = false) => {
    const maxLen = isReference ? 18 : 20

    if (name.length > maxLen) {
      return `${name.substring(0, maxLen)}...`
    }

    return name
  }

  render () {
    const {
      viewId,
      viewsObject,
      children,
      layout,
      size,
      currentDashboard,
      tileConfigs,
      isEditModeOn,
      simple,
      t,
      currentProject,
      currentSimulationCase,
      openDialogs,
      featureFlags,
      appState,
      visualizationMetaInformation,
    } = this.props

    const simulationCases: { key: string, value: ReactElement, disabled?: boolean }[] =
      Object.values(currentProject.simulationCases || {})
        .map((simulationCase, index) => {
          if (simulationCase._id === currentSimulationCase._id) {
            const referenceName =
              <>
                <span className='dropdown-selectors'>{`C${index + 1}(R)`}</span>
                <span>{this.getSimulationCaseNameFormatted(simulationCase.name, true)}</span>
              </>

            return {
              key: simulationCase._id,
              value: referenceName,
              title: simulationCase.name,
              disabled: true,
              checked: true,
            }
          }

          const name =
            <>
              <span className='dropdown-selectors'>{`C${index + 1}`}</span>
              <span>{this.getSimulationCaseNameFormatted(simulationCase.name)}</span>
            </>

          return {
            key: simulationCase._id,
            value: name,
            title: simulationCase.name,
            disabled: !FeatureFlags.canEditCasterComparison(featureFlags, visualizationMetaInformation, appState),
          }
        })

    let resizeAllowed = true
    const childLength = React.Children.count(children)

    let totalHeight = 0

    for (const plotLayout of layout) {
      totalHeight += plotLayout.h
    }

    if (totalHeight > Logic.GRID_ROWS) {
      resizeAllowed = false
    }

    const minimalSplitWidth = Logic.GRID_STEP_SIZE * 10 // TODO: this needs to be checked/fixed

    const { name } = ((viewsObject[viewId] || {}).dashboards || {})[currentDashboard] || {}
    const { selectedComparisonCasters } = viewsObject[viewId] || {}
    const cleanSelectedComparisonCasters = (selectedComparisonCasters || [])
      .filter(id =>
        currentProject.simulationCases.map(simCase => simCase._id)
          .includes(id))
    const selectedComparisonCastersNames = cleanSelectedComparisonCasters
      .map((comparisonCasterId: string) =>
        currentProject.simulationCases.find(simCase => simCase._id === comparisonCasterId)?.name || '')
    const canDeleteTab =
      FeatureFlags.canDeleteTabInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canEditDashboardOptions =
      FeatureFlags.canEditDashboardOptionsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canMovePlot =
      FeatureFlags.canMovePlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canResizePlot =
      FeatureFlags.canResizePlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canAddPlot =
      FeatureFlags.canAddPlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canViewExportPlotButton = FeatureFlags.canViewExportPlots(featureFlags)
    const canExportPlot = FeatureFlags.canUseExportPlots(featureFlags)

    return (
      <GridViewContainer simple={simple}>
        <DashboardMenu
          title={`${name}; ${childLength} ${childLength === 1 ? 'Plot' : 'Plots'}`}
          isEditModeOn={isEditModeOn}
          showPlotInfo={size.width > minimalSplitWidth + 140}
          infoAction={size.width <= minimalSplitWidth}
        >
          {size.width > minimalSplitWidth && <span>{name}</span>}
          {
            size.width > minimalSplitWidth &&
              <span>
                {childLength} {t('viewGrid.counter', { pl: childLength === 1 ? '' : 's' })}
              </span>
          }
          {
            simple && canDeleteTab &&
              <DashboardMenuAction onClick={this.handleDeleteDashboard} smaller={size.width <= minimalSplitWidth}>
                <i className='pe-7s-trash pe-fw' title={t('viewGrid.closeTab')} />
              </DashboardMenuAction>
          }
          {
            isEditModeOn && canEditDashboardOptions &&
              <DashboardMenuAction onClick={this.handleEditDashboard} smaller={size.width <= minimalSplitWidth}>
                <i className='pe-7s-config pe-fw' title={t('viewGrid.options')} />
              </DashboardMenuAction>
          }
          {/* FIXME: this is temporarily disabled */}
          {/* {isEditModeOn &&
            <DashboardMenuAction onClick={this.handleAutoscaling} smaller={size.width <= minimalSplitWidth}>
              <i
                className='pe-7s-exapnd2 pe-fw'
                title={autoscaled ? t('viewGrid.undoAutoscaling') : t('viewGrid.autoscaling')}
              />
            </DashboardMenuAction>
          } */}
          {
            canViewExportPlotButton &&
            <DashboardMenuAction
              onClick={this.handleExportPlot}
              smaller={size.width <= minimalSplitWidth}
              disabled={!canExportPlot}
            >
              <i
                className='pe-7s-download pe-fw'
                title={`${t('viewGrid.export')}${!canExportPlot ? ' - Insufficient permissions' : ''}`}
              />
            </DashboardMenuAction>
          }
          {
            canAddPlot &&
              <DashboardMenuAction onClick={this.handleAddPlot} doRotate smaller={size.width <= minimalSplitWidth}>
                <i className='pe-7s-close pe-fw' title={t('viewGrid.add')} />
              </DashboardMenuAction>
          }
          {
            canAddPlot && size.width <= minimalSplitWidth &&
              <DashboardMenuAction onClick={this.handleAddPlot} smaller>
                <i className='pe-7s-info pe-fw' />
              </DashboardMenuAction>
          }
        </DashboardMenu>
        {
          FeatureFlags.canViewCasterComparison(featureFlags) &&
          simulationCases.length > 1 &&
          openDialogs.includes('CasterTree') &&
          <ComparisonCasterMenu>
            <div style={{ width: '100%' }}>
              <Input
                value={selectedComparisonCastersNames || []}
                renderValue={
                  () =>
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                      Compare Caster Data:
                      <span
                        style={{ fontSize: '14px', paddingLeft: '2px', position: 'relative', top: '1px' }}
                      >
                        {selectedComparisonCastersNames.length} selected
                      </span>
                    </span> as any
                }
                style={{ background: 'none' }}
                noMarginTop
                name='comparisonCaster'
                type='select'
                selectors={simulationCases}
                selectedIds={cleanSelectedComparisonCasters}
                onChange={this.handleComparisonCasterSelectViaOption}
                onCheckboxClick={this.handleComparisonCasterSelectViaCheckbox}
                spaceBetween
                optionStyles={{ fontSize: '14px' }}
                checkbox
                multiple
                MenuProps={
                  {
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    disableEnforceFocus: true,
                    MenuListProps: {
                      disableEnforceFocus: true,
                    },
                  }
                }
              />
            </div>
          </ComparisonCasterMenu>
        }
        <GridScrollbar
          enableVerticalScrolling={size.height < Logic.FULL_HD_VIEW_HEIGHT}
          enableHorizontalScrolling={size.width < Logic.FULL_HD_VIEW_WIDTH}
          ref={this.handleWrapperRef}
          onWheel={this.handleWheel}
          className='grid-layout-scroll-container'
        >
          <div className='button-left scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use shift + mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-right scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use shift + mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-up scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-down scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use mousewheel to scroll</ScrollHint>
          </div>
          {/* {(childLength > 1 || isEditModeOn) && */}
          {/* with styles to make last div have margin-bottom: 32px */}
          <GridLayout
            className='layout'
            rowHeight={Logic.GRID_STEP_SIZE}
            cols={Logic.GRID_COLUMNS}
            onLayoutChange={this.handleLayoutChange}
            layout={layout}
            width={Logic.FULL_HD_VIEW_WIDTH}
            draggableHandle='.header_bar'
            isDraggable={canMovePlot}
            isResizable={canResizePlot && resizeAllowed}
            margin={[ 0, 0 ]}
            autoSize
          >
            {
              React.Children.map(children, child => {
                return (
                  <div key={child.props.id} style={{ position: 'relative' }}>
                    <GridItemContainer
                      tileId={child.props.tileId}
                      data={tileConfigs[child.key]}
                    >
                      {child}
                    </GridItemContainer>
                  </div>
                )
              })
            }
          </GridLayout>
          {/* } */}
          {/* TODO: Make this work without map */}
          {/* {childLength === 1 && !isEditModeOn && React.Children.map(children, child => {
            return (
              <GridItemContainer
                tileId={child.props.tileId}
                data={tileConfigs[child.key]}
                onlyChild
              >
                {child}
              </GridItemContainer>
            )
          })} */}
          {
            childLength === 0 && canAddPlot &&
              <AddPlotGridItem onClick={this.handleAddPlot}>
                <i className='pe-7s-plus pe-va' onClick={this.handleAddPlot} title={t('viewGrid.add')} />
                {t('viewGrid.addTile')}
              </AddPlotGridItem>
          }
        </GridScrollbar>
      </GridViewContainer>
    )
  }
}

const connected = connector(ViewGrid as any) as any

export default withNamespaces('visualization')(sizeMe({ monitorHeight: true })(withSnackbar(connected)) as any) as any
