import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux/'
import { withNamespaces } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Container, Draggable } from 'react-smooth-dnd'

import * as VisualizationActions from 'store/visualization/actions'

import {
  Content,
  List,
  ListItem,
  InnerText,
  I,
  Title,
  DragNDrop,
  DropContainer,
  ListTitle,
  PlotQuickConfig,
} from './Styles'
import { Button } from '../Dialogs/DialogStyles'
import ConfigDialogContent from '../ConfigDialogContent'
import VisUtil from '../../VisUtil'
import Input from 'react/specific/Input'
import { DefaultState } from 'types/state'
import FeatureFlags from 'react/FeatureFlags'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  featureFlags: state.application.main.authenticationData.featureFlags,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  appState: state.application.main.appState,
}), {
  mergePlots: VisualizationActions.mergePlots,
  showConfigDialog: VisualizationActions.showConfigDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: any
  editMode: boolean,
  edit: string | boolean,
  configName: string,
  plotType: string,
  selectedPlots: Array<any>,
  plotObject: any
};

class PlotContent extends Component<Props, State> {
  state: State = {
    editMode: false,
    edit: '',
    configName: 'Merged Plot',
    plotType: 'line',
    selectedPlots: [],
    plotObject: {},
  }

  handleDropCard = (event: any) => {
    const { payload, addedIndex, removedIndex } = event
    const { selectedPlots, plotObject } = this.state

    const sortedSelectedPlots: any[] = [ ...selectedPlots ]

    if (removedIndex !== null) {
      sortedSelectedPlots.splice(removedIndex, 1)
    }

    if (addedIndex !== null) {
      sortedSelectedPlots.splice(addedIndex, 0, payload.id)
    }

    if (removedIndex === null && selectedPlots.includes(payload.id)) {
      return
    }

    this.setState({
      selectedPlots: sortedSelectedPlots,
      plotObject: {
        ...plotObject,
        [payload.id]: payload,
      },
    })
  };

  handleEdit = (editMode: boolean, event: any) => {
    const { showConfigDialog } = this.props

    this.setState({
      editMode: editMode,
      edit: editMode ? event.target.id : false,
    })

    if (editMode) {
      showConfigDialog(event.target.id)
    }
  };

  handleOpenEdit = this.handleEdit.bind(this, true);
  handleCloseEdit = this.handleEdit.bind(this, false);

  handleSubmit = () => {
    const { mergePlots } = this.props
    const { selectedPlots, configName, plotType } = this.state

    mergePlots(selectedPlots, configName, plotType)

    this.setState({
      selectedPlots: [],
      plotObject: {},
      configName: 'Merged Plot',
    })
  };

  handleDeletePlot = (event: any) => {
    const { selectedPlots, plotObject } = this.state
    const { id } = event.target

    let newSelectedPlots: any[] = []
    let newPlotObject: any = {}

    if (id !== 'all') {
      newSelectedPlots = [ ...selectedPlots ]
      newPlotObject = { ...plotObject }

      newSelectedPlots.splice(selectedPlots.indexOf(id), 1)
      delete newPlotObject[id]
    }

    this.setState({
      selectedPlots: newSelectedPlots,
      plotObject: newPlotObject,
    })
  };

  handleQuickSetting = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  setEditIdToNull = () => {
    this.setState({
      edit: false,
    })
  }

  render () {
    const { selectedPlots, plotObject, editMode, edit, configName } = this.state
    const { plotConfigs, t, featureFlags, visualizationMetaInformation, appState } = this.props

    let plots: any = {}

    Object.values(plotConfigs).forEach((plot: any) => {
      const group = plot.group || 'Merged Plots'

      if (!plot.group && !plot.key) {
        return
      }

      if (Object.keys(plots).includes(group)) {
        plots[group].push(plot)
      }
      else {
        plots = {
          ...plots,
          [group]: [ plot ],
        }
      }
    })

    // sort every group alphabetically
    Object.keys(plots).forEach((plotGroup) => {
      plots[plotGroup].sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    })

    return (
      <Content>
        <DragNDrop>
          <Title title={t('plotContent.availablePlot.title')}>{t('plotContent.availablePlot.title')}</Title>
          <List>
            {
              Object.keys(plots).map((plotGroup, index) => {
                return (
                  <div key={index}>
                    <ListTitle title={t('plotContent.availablePlot.group', { plotGroup })}>{plotGroup}</ListTitle>
                    <Container
                      groupName='1'
                      behaviour='copy'
                      getChildPayload={
                        // eslint-disable-next-line function-paren-newline
                        (i: number) => plots[plotGroup].filter(
                          // clean up/filter duplicate dynamicDataSource configs
                          ({ group, id, key }: { group: string, id: string, key: string }) =>
                            !(group === 'dynamicDataSource' && id === key))[i]
                      }
                      dragHandleSelector='.list_item_drag_handle'
                    >
                      {
                        // eslint-disable-next-line function-paren-newline
                        plots[plotGroup].filter(
                          // clean up/filter duplicate dynamicDataSource configs
                          ({ group, id, key }: { group: string, id: string, key: string }) =>
                            !(group === 'dynamicDataSource' && id === key)).map((plot: any, index: number) => {
                          const { key, name, group, id } = plot
                          const { value } = VisUtil.getConfigInfo(name, key, id, group, plot)

                          // TODO: FIX Merged Plot => cant merge merged plot with other plot
                          const merged = /merged/.test(id)

                          return (
                            <Draggable
                              key={index}
                              className={
                                !editMode &&
                                !merged &&
                                FeatureFlags.canMergeDynamicData(featureFlags, visualizationMetaInformation, appState)
                                  ? 'list_item_drag_handle'
                                  : ''
                              }
                            >
                              <ListItem
                                editMode={editMode || merged}
                                selected={id === edit}
                              >
                                <InnerText>
                                  {value}
                                </InnerText>
                                <I
                                  id={id}
                                  title={t(`plotContent.availablePlot.${id === edit ? 'close' : 'settings'}`, { id })}
                                  className={id === edit ? 'pe-7s-close' : 'pe-7s-config'}
                                  onClick={id === edit ? this.handleCloseEdit : this.handleOpenEdit}
                                  size='20px'
                                />
                              </ListItem>
                            </Draggable>
                          )
                        })
                      }
                    </Container>
                  </div>
                )
              })
            }
          </List>
        </DragNDrop>
        <DragNDrop float='right'>
          <Title style={{ marginLeft: '-20px' }} title={t('plotContent.configurePlot.title')}>
            {
              t(`plotContent.configurePlot.${editMode ? 'configure' : 'contained'}Label`, {
                id: editMode ? edit.toString() : '',
              }).toLocaleUpperCase()
            }
          </Title>
          {
            !editMode &&
              <I
                className='pe-7s-trash'
                id='all'
                title={t('plotContent.configurePlot.deleteAll')}
                onClick={this.handleDeletePlot}
                size='25px'
                style={{ right: '25px' }}
              />
          }
          {
            edit
              ? (
                <DropContainer editMode>
                  <ConfigDialogContent fullscreen allowDeletion setEditIdToNull={this.setEditIdToNull} />
                </DropContainer>
              )
              : (
                <DropContainer>
                  <PlotQuickConfig>
                    <Input
                      title={t('plotContent.configurePlot.configName.title')}
                      label={t('plotContent.configurePlot.configName.label')}
                      name='configName'
                      type='text'
                      onChange={this.handleQuickSetting}
                      value={configName}
                    />
                  </PlotQuickConfig>
                  <List hasQuickSettings>
                    <Container
                      groupName='1'
                      getChildPayload={(i: number) => plotObject[selectedPlots[i]]}
                      onDrop={this.handleDropCard}
                      style={{ height: '95%' }}
                      lockAxis='y'
                    >
                      {
                        selectedPlots.map((plot, index) => {
                          const { key, name, group, id } = plotObject[plot]
                          const { value } = VisUtil.getConfigInfo(name, key, id, group, plotObject[plot])

                          return (
                            <Draggable key={index}>
                              <ListItem>
                                <InnerText>
                                  {value}
                                </InnerText>
                                <I
                                  className='pe-7s-close'
                                  id={plot}
                                  title={t('plotContent.configurePlot.delete')}
                                  onClick={this.handleDeletePlot}
                                  size='25px'
                                />
                              </ListItem>
                            </Draggable>
                          )
                        })
                      }
                    </Container>
                  </List>
                  <Button
                    style={{ bottom: '10px' }}
                    onClick={this.handleSubmit}
                    disabled={!selectedPlots.length}
                    title={t('plotContent.configurePlot.create.title')}
                  >
                    {t('plotContent.configurePlot.create.label')}
                  </Button>
                </DropContainer>
              )
          }
        </DragNDrop>
      </Content>
    )
  }
}

export default withNamespaces('visualization')(connector(PlotContent as any) as any) as any
