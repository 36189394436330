import FeatureFlags from 'react/FeatureFlags'
import { AnalyzeTime } from 'Util'
import MainView from '.'
import Util from '../../logic/Util'

export default class EventHandlers {
  @AnalyzeTime(0)
  static handleKeyCode (view: MainView, event: any) {
    switch (event.keyCode) {
      case 65: // A
        // TODO: if dialog open or focus in input (filter/edit), do not select all elements
        // CTRL + A
        if (view.amountOfComparisonCasterColumns) {
          event.preventDefault()

          return
        }

        if (!event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          const paths = view.clickableObjects
            .filter(object => Util.isVisible(object))
            .map(object => object.path)

          if (view.selectedElement) {
            view.selectedElement() // TODO: this affects performance too much, tree view rerenders
            view.selectedElement(paths, true)
          }
        }

        // CTRL + SHIFT + A
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          if (view.selectedElement) {
            view.selectedElement()
          }
        }

        break
      case 70: // F
        // CTRL + F
        if (!event.shiftKey && event.ctrlKey) {
          if (!FeatureFlags.canCenter3DPosition(view.featureFlags)) {
            return
          }

          event.preventDefault()

          view.jumpToFiltered()
        }

        // CTRL + SHIFT + F
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          view.setTerm('')

          if (view.selectedElement) {
            view.selectedElement()
          }
        }

        break
      case 67: // C
        // CTRL + SHIFT + C
        if (event.shiftKey && event.ctrlKey) {
          event.preventDefault()

          view.controls.enableRotate = true
        }

        break
      case 191: // #
        // CTRL + #
        if (event.ctrlKey) {
          event.preventDefault()

          view.controls.autoRotate = !view.controls.autoRotate
          view.controls.update()
        }

        break
      default:
    }
  }
}
