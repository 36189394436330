import React, { PureComponent } from 'react'
import type { ElementsHashes } from 'types/state'
import { CCElementComponent } from './CCElement'

type Props = {
  index: number,
  ccGroup: CCElement[]
  groupKey: number | 'default'
  selectedDataArray: string[]
  elementsHashes: ElementsHashes
  selectElementPerAttributeUUID: (uuid: string) => void
}

export class CCGroup extends PureComponent<Props> {
  render () {
    const {
      ccGroup,
      index: groupIndex,
      selectElementPerAttributeUUID,
      groupKey,
      elementsHashes,
      selectedDataArray,
    } = this.props

    const groupStyle = { borderRight: '4px solid #fcc203', marginBottom: '15px', marginTop: '5px', paddingRight: '5px' }

    if (groupKey === 'default' || ccGroup.length < 2) {
      groupStyle.borderRight = '4px solid #22282e'
    }

    return (
      <div
        key={groupIndex}
        style={groupStyle}
      >
        {
          ccGroup.map((ccElement, index) => (
            <CCElementComponent
              key={index}
              ccElement={ccElement}
              index={index + groupIndex}
              groupKey={groupKey}
              selectElementPerAttributeUUID={selectElementPerAttributeUUID}
              selectedDataArray={selectedDataArray}
              elementsHashes={elementsHashes}
            />
          ))
        }
      </div>
    )
  }
}
