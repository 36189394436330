import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'clone-deep'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResizeAware from 'react-resize-aware'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'

import * as MatrixActions from 'store/matrix/actions'
import * as ApplicationActions from 'store/application/main/actions'

import Icon from 'react/specific/Icon'

import { Th } from './Styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect((state: DefaultState) => ({
  columns: state.matrix.columns,
  selections: state.matrix.selections,
  currentProject: state.application.main.currentProject,
}), {
  setSelections: MatrixActions.setSelections,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  columnWidths: any
};

class SheetRenderer extends Component<Props, State> {
  columnWidths: any = {};
  timeoutId?: number;

  state = {
    columnWidths: {},
  }

  // @AnalyzeTime(0)
  handleAllSelectionChange = (event: any) => {
    const { selections, setSelections } = this.props

    const newSelections = cloneDeep(selections)
    const selectionsList = Object.keys(newSelections)

    for (let i = 0; i < selectionsList.length; i++) {
      newSelections[selectionsList[i]] = event.target.checked
    }

    setSelections(newSelections)
  };

  // @AnalyzeTime(0)
  handleResizeStart = () => {
    const table = document.querySelector('.matrix-sheet')
    const scrollWrapper = (table || {}).parentNode

    if (scrollWrapper) {
      (scrollWrapper as any).style.display = 'inline-block';
      (scrollWrapper as any).style.width = `${(table || {}).clientWidth || 0 + 500}px`
    }
  };

  // @AnalyzeTime(0)
  handleResize = (ref: any, key: string) => {
    const width = ref.current.offsetWidth

    if (width > 500) {
      ref.current.style.width = '500px'
    }

    if (!key || this.columnWidths[key] === width) {
      return
    }

    this.handleResizeStart()
    this.setWidth(key, width)
  };

  // @AnalyzeTime(0)
  setWidth = (key: string, width: number) => {
    const { currentProject, setCurrentProject } = this.props

    this.columnWidths[key] = width

    clearTimeout(this.timeoutId)

    this.timeoutId = window.setTimeout(() => {
      ApiClient.post(
        `${Network.URI}/project/matrix_columns/${currentProject._id}`,
        { data: { widths: this.columnWidths } },
      )
        .then(() => {
          const project = cloneDeep(currentProject)

          project.matrixColumnWidths = cloneDeep(this.columnWidths)

          setCurrentProject(project)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }, 1000)
  };

  // @AnalyzeTime(0)
  renderLabel = ({ label, labelType }:any) => {
    if (labelType === 'icon') {
      return <Icon {...label} />
    }

    return <div>{label}</div>
  };

  @AnalyzeTime(0)
  render () {
    const { currentProject, columns, selections, children, t } = this.props
    const columnWidths = currentProject.matrixColumnWidths || {}

    const allChecked = Object.values(selections).every(s => s)

    return (
      <table className='matrix-sheet'>
        <thead className='data-header'>
          <tr>
            <Th className='row-number-cell cell full-sticky' />
            <Th className='selection-cell cell full-sticky'>
              <input type='checkbox' checked={allChecked} onChange={this.handleAllSelectionChange} />
            </Th>
            <Th className='action-cell cell full-sticky'>
              {t(`${PRE_TRANS}.label.actions`)}
            </Th>
            {
              columns.map(column => ({ column, ref: React.createRef() })).map(({ column, ref }) =>
                <Th
                  className={`cell ${column.className || ''}`}
                  style={{ width: (columnWidths as any)[column.key] || column.width }}
                  key={uuid()}
                  title={column.title || ''}
                  onMouseDown={this.handleResizeStart}
                  ref={ref as any}
                >
                  <ResizeAware onlyEvent onResize={() => this.handleResize(ref, column.key)}>
                    {this.renderLabel(column)}
                  </ResizeAware>
                </Th>)
            }
          </tr>
        </thead>
        <tbody className='data-body'>
          {children}
        </tbody>
      </table>
    )
  }
}

export default withNamespaces('application')(connector(SheetRenderer as any) as any) as any
