import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import ThreeManager from '../three/ThreeManager'

import DataActions from '../store/data/actions'
import * as FilterActions from '../store/filter/actions'
import * as ApplicationActions from '../store/application/main/actions'
import * as UtilActions from '../store/util/actions'
import FeatureFlags from './FeatureFlags'
import UpdatesDisplay from './UpdatesDisplay'
import { AppState } from '../store/application/main/consts'

import { DefaultState, ElementsHashes } from 'types/state'
import ProjectDataDialog from './dialogs/project/ProjectDataDialog'
import { Info } from 'logic/Info'

const CasterFrame = styled.div<{CasterTree: boolean,
  CasterDialog: boolean,
  CasterDashboard: boolean,
  blur: boolean,
  currentDashboardWidth: number,
  currentCasterDialogWidth: number,
}>`${({
  CasterTree,
  CasterDialog,
  CasterDashboard: CasterDb,
  blur,
  currentDashboardWidth,
  currentCasterDialogWidth,
}: any) => css`
  position: absolute;
  top: 50px;
  right: ${CasterDialog ? '335px' : '0'};
  bottom: 0;
  left: ${CasterTree ? (CasterDb ? `${currentDashboardWidth}px` : '280px') : '0'};
  filter: blur(${blur ? '5px' : '0'});
  overflow: hidden;
  background-color: #000000;
  text-align: center;
  width: calc(100vw - ${CasterDialog ? `${currentCasterDialogWidth || 335}px` : '0px'} -
  ${CasterTree ? (CasterDb ? `${currentDashboardWidth}px` : '280px') : '0px'});
`}`

interface TooltipContainerProps {
  mousePos: {
    x: number
    y: number
  }
}

const TooltipContainer = styled.div.attrs<TooltipContainerProps>(({ mousePos }) => ({
  style: {
    top: `${mousePos.y}px`,
    left: `${mousePos.x}px`,
  },
}))<TooltipContainerProps>`
  position: absolute;
  text-align: left;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  pointer-events: none;
  z-index: 200;
`

const Tooltip = styled.div<{key: string, position: Positions}>(({ position }) => {
  let transform = ''

  switch (position) {
    case 'top': transform = `translate(-50%, -20px)`
      break
    case 'right': transform = `translate(20px, -50%)`
      break
    case 'bottom': transform = `translate(-50%, 20px)`
      break
    case 'left': transform = `translate(-20px, -50%)`
      break
  }

  return css<any>`
    transform: ${transform};
    display: inline-block;
    background-color: rgba(100, 100, 100, 0.95);
    padding: 10px;
    border: 1px solid rgba(150, 150, 150, 0.95);
    border-radius: 5px;
    margin: 5px;
    white-space: nowrap;
    user-select: none;
  `
})

const connector = connect(({
  data,
  application,
  filter,
  visualization,
  util,
  AirLoop,
  CoolingLoop,
  CoolingZone,
  LoopAssignment,
  Nozzle,
  Roller,
  RollerBearing,
  RollerBody,
  SupportPoint,
  Segment,
  SegmentGroup,
  SensorPoint,
  DataPoint,
  DataLine,
  StrandGuide,
  coolingLoopPasslnCoordMap,
}: DefaultState) => ({
  additionalData: data.additionalData,
  amountOfComparisonCasterColumns: visualization.amountOfComparisonCasterColumns,
  appState: application.main.appState,
  coolingLoopPasslnCoordMap: coolingLoopPasslnCoordMap,
  createValid: data.createValid,
  currentCasterDialogWidth: visualization.currentCasterDialogWidth,
  currentCasterRoot: application.main.currentCasterRoot,
  currentDashboardWidth: visualization.currentDashboardWidth,
  currentProject: application.main.currentProject,
  currentSimpleDashboardTabIndex: application.main.currentSimpleDashboardTabIndex,
  currentSimulationCase: application.main.currentSimulationCase,
  dirtyDeletePaths: data.dirtyDeletePaths,
  dirtyPaths: data.dirtyPaths,
  editElements: data.editElements,
  editValues: data.editValues,
  featureFlags: FeatureFlags.getRealFeatureFlags({ application }),
  filterControlDefinitions: filter.filterControlDefinitions,
  hasChanges: data.hasChanges,
  hidePaths: data.hidePaths,
  isLoggedIn: FeatureFlags.isLoggedIn({ application }),
  newCopiedElementsToDraw: data.newCopiedElementsToDraw,
  openDialogs: application.main.openDialogs,
  parentPath: data.parentPath,
  rollerChildren: util.rollerChildren,
  selectedDataArray: data.selectedDataArray,
  target: filter.target,
  term: filter.term,
  termDisabled: filter.termDisabled,
  tooltip: application.main.tooltip,
  updatesCount: data.updatesCount,
  xmlData: data.rootData,
  elementsHashes: {
    AirLoop: AirLoop,
    CoolingLoop: CoolingLoop,
    CoolingZone: CoolingZone,
    LoopAssignment: LoopAssignment,
    Nozzle: Nozzle,
    Roller: Roller,
    RollerBearing: RollerBearing,
    RollerBody: RollerBody,
    SupportPoint: SupportPoint,
    SegmentGroupSupportPoints: SupportPoint,
    Segment: Segment,
    SegmentGroup: SegmentGroup,
    SensorPoint: SensorPoint,
    DataPoint: DataPoint,
    DataLine: DataLine,
    StrandGuide: StrandGuide,
  },
}), {
  clearDirtyPaths: DataActions.clearDirtyPaths,
  openDialog: ApplicationActions.openDialog,
  removeDeletePaths: DataActions.removeDeletePaths,
  resetTarget: FilterActions.resetTarget,
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setNewCopiedElementsToDraw: DataActions.setNewCopiedElementsToDraw,
  setRollerVisible: UtilActions.setRollerVisible,
  setTerm: FilterActions.setTerm,
  setTermDisabled: FilterActions.setTermDisabled,
  setTooltip: ApplicationActions.setTooltip,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isNewCaster: boolean
  redraw: boolean
  blur: boolean
}

type State = {
  mousePos: any
};

export type PassedData = {
  additionalData: any
  amountOfComparisonCasterColumns: number,
  clearDirtyPaths: typeof DataActions.clearDirtyPaths
  coolingLoopPasslnCoordMap: CoolingLoopPasslnCoordMap
  createValid: CreateValid
  dirtyDeletePaths: string[]
  dirtyPaths: string[]
  editElements: EditElements
  // eslint-disable-next-line @typescript-eslint/ban-types
  editValues: Record<CasterElementNames | 'General', Object>
  elementsHashes: ElementsHashes,
  featureFlags: Record<string, boolean>
  filterControlDefinitions: FilterControlDefinition[]
  hasChanges: boolean
  hidePaths: string[]
  isNewCaster: boolean
  newCopiedElementsToDraw: boolean,
  parentPath: string
  redraw: boolean
  removeDeletePaths: typeof DataActions.removeDeletePaths
  resetTarget: typeof FilterActions.resetTarget
  rollerChildren: number
  rootData: RootData,
  selectedDataArray: string[]
  selectedMultiEditElements: (selectedData?: any, multiSelect?: boolean, massSelect?: boolean) => void
  setLoadingStatus: typeof ApplicationActions.setLoadingStatus
  setNewCopiedElementsToDraw: typeof DataActions.setNewCopiedElementsToDraw
  setRollerVisible: typeof UtilActions.setRollerVisible
  setTerm: typeof FilterActions.setTerm
  setTermDisabled: typeof FilterActions.setTermDisabled
  setTooltip: typeof ApplicationActions.setTooltip
  target: string
  term: string
  termDisabled: boolean
  tooltip: Record<'UIView' | 'SectionView', Tooltip[]>
  UIButtonsMarginRight: number
}

export class Caster extends Component<Props, State> {
  prevAppState: number | null = null;
  mountRef: any;

  state = {
    mousePos: { x: 0, y: 0 },
  }

  componentDidMount () {
    ThreeManager.base.mount()

    window.addEventListener('pointermove', this.setMousePos, true)
  }

  shouldComponentUpdate (nextProps: Props) {
    // TODO: rework
    // if (JSON.stringify(this.props) === JSON.stringify(nextProps)) {
    //   return false
    // }

    const {
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      coolingLoopPasslnCoordMap,
      createValid,
      currentCasterDialogWidth,
      currentDashboardWidth,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      elementsHashes,
      featureFlags,
      filterControlDefinitions,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      openDialogs,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      selectedDataArray,
      selectedMultiEditElements,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      setTermDisabled,
      setTooltip,
      target,
      term,
      termDisabled,
      tooltip,
      xmlData,
    } = nextProps

    const casterDialogWidth = openDialogs.includes('CasterDialog') ? currentCasterDialogWidth : 0
    const dashboardWidth = openDialogs.includes('CasterTree')
      ? Math.min(currentDashboardWidth ?? 500, window.innerWidth - 390)
      : 0

    const UIButtonsMarginRight = (window.innerWidth - casterDialogWidth - dashboardWidth) * 0.35

    const data = {
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      coolingLoopPasslnCoordMap,
      createValid,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      elementsHashes,
      featureFlags,
      filterControlDefinitions,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      rootData: xmlData,
      selectedDataArray,
      selectedMultiEditElements,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      setTermDisabled,
      setTooltip,
      target,
      term,
      termDisabled,
      tooltip,
      UIButtonsMarginRight,
    }

    // TODO: could verify if props changed
    ThreeManager.base.setData(data)

    return true
  }

  componentDidUpdate () {
    const {
      currentProject,
      currentSimulationCase,
      openDialog,
      appState,
      currentCasterRoot,
      isLoggedIn,
      featureFlags,
    } = this.props

    const info = Info.getRecentlyUsedInfo()

    if (
      this.prevAppState !== appState &&
      appState === AppState.Caster &&
      isLoggedIn &&
      currentProject &&
      currentProject._id &&
      currentSimulationCase &&
      currentSimulationCase._id &&
      !info.projectId &&
      !info.caseId &&
      !Object.entries(currentCasterRoot).length &&
      !FeatureFlags.usesSlimVersion(featureFlags)
    ) {
      openDialog(ProjectDataDialog.NAME)
    }

    if (this.prevAppState !== appState) {
      this.prevAppState = appState
    }
  }

  componentWillUnmount () {
    ThreeManager.base.unmount()
    ThreeManager.killBase()

    window.removeEventListener('pointermove', this.setMousePos, true)
  }

  setMountRef = (mountRef: any) => {
    if (this.mountRef) {
      return
    }

    this.mountRef = mountRef

    ThreeManager.init(this.mountRef)
    ThreeManager.base.init(this.mountRef)
  };

  // Tried to use event as MouseEvent type but there were missing properties
  setMousePos = (event: MouseEvent):any => {
    if (!this.mountRef) {
      return
    }

    this.setState({
      mousePos: {
        x: event.clientX,
        y: event.clientY - 30,
      },
    })
  };

  render () {
    const { mousePos } = this.state
    const {
      openDialogs,
      tooltip,
      blur,
      updatesCount,
      isLoggedIn,
      currentSimpleDashboardTabIndex,
      currentDashboardWidth,
      currentCasterDialogWidth,
      featureFlags,
    } = this.props

    const tooltips = Object.values(tooltip || {}).reduce((list: Tooltip[], current) => ([
      ...list,
      ...current,
    ]), [])

    return (
      <>
        <CasterFrame
          ref={this.setMountRef}
          blur={blur}
          CasterTree={openDialogs.includes('CasterTree')}
          CasterDialog={openDialogs.includes('CasterDialog')}
          CasterDashboard={currentSimpleDashboardTabIndex > 0}
          currentDashboardWidth={Math.min(currentDashboardWidth ?? 500, window.innerWidth - 390)}
          currentCasterDialogWidth={currentCasterDialogWidth}
        >

          {isLoggedIn && FeatureFlags.canToggleLiveMode(featureFlags) && <UpdatesDisplay updatesCount={updatesCount} />}
        </CasterFrame>
        {
          tooltips && tooltips.length > 0 &&
            <TooltipContainer mousePos={mousePos}>
              {tooltips.map(({ tooltip, position }) => <Tooltip key={uuid()} position={position}>{tooltip}</Tooltip>)}
            </TooltipContainer>
        }
      </>
    )
  }
}

export default connector(Caster as any) as any
