import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import cloneDeep from 'clone-deep'

import * as ApplicationActions from 'store/application/main/actions'
import Button from 'react/components/Button/index'
import Input from 'react/specific/Input'
import FeatureFlags from 'react/FeatureFlags/index'

import Logic, { PRE_TRANS as PRE_TRANS_PARENT } from './Logic'
import { FileName } from './styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = `${PRE_TRANS_PARENT}.strandGuide`

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  uploadType: string
};

export class StrandGuide extends Component<Props, State> {
  state = {
    uploadType: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleData()
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleData()
  }

  // @AnalyzeTime(0)
  handleData = () => {
    if (this.state.uploadType !== '') {
      return
    }

    const { currentSimulationCase: { inputData: { strandGuide: { uploadType } } } } = this.props

    if (!uploadType) {
      return
    }

    this.setState({ uploadType })
  };

  // @AnalyzeTime(0)
  handleChangeUploadType = (event: any) => this.setState({ uploadType: event.target.value });

  // @AnalyzeTime(0)
  handleUploadStrandGuideProjectFile = () => {
    const { uploadType: uType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.prj', 'strandGuide', 'fileProject', currentSimulationCase, setCurrentSimulationCase, uType)
  };

  // @AnalyzeTime(0)
  handleUploadStrandGuideMA1File = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.dat', 'strandGuide', 'fileMA1', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadStrandGuideMA6File = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'strandGuide', 'fileMA6', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleGenerate = async () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    const data = await Logic.handleGenerateStrandGuide(currentSimulationCase._id, uploadType)

    if (!Object.keys(data).length) {
      return
    }

    const simulationCase = cloneDeep(currentSimulationCase)

    simulationCase.inputData.strandGuide = data

    setCurrentSimulationCase(simulationCase)
  };

  @AnalyzeTime(0)
  render () {
    const { uploadType } = this.state
    const { authenticationData, currentSimulationCase, t } = this.props
    const {
      inputData: { strandGuide, nozzles },
      miscLockedBy,
      nozzlesLockedBy,
      rollersLockedBy,
      simulationStartedAt,
    } = currentSimulationCase
    const { fileMA1, fileMA6, fileProject, generateStartedBy } = strandGuide
    const { generateStartedBy: otherGenerateStartedBy } = nozzles

    const uploadTypeSelectors = [
      { key: '1', value: t(`${PRE_TRANS}.uploadType.1`) },
      { key: '2', value: t(`${PRE_TRANS}.uploadType.2`) },
      { key: '3', value: t(`${PRE_TRANS}.uploadType.3`) },
    ]

    const noFile = t(`${PRE_TRANS_PARENT}.noFile`)

    const { isLocked: generalLocked } =
      FeatureFlags.getLockedStatus('General', currentSimulationCase, authenticationData)
    const { isLocked: rollerLocked } = FeatureFlags.getLockedStatus('Roller', currentSimulationCase, authenticationData)
    const { isLocked: nozzlesLocked } =
      FeatureFlags.getLockedStatus('Nozzles', currentSimulationCase, authenticationData)

    // FIXME: This is a temporary fix to prevent false locking
    const isLocked = false
    // const isLocked =
    //   (miscLockedBy && generalLocked) || (rollersLockedBy && rollerLocked) || (nozzlesLockedBy && nozzlesLocked)

    const disabled =
      simulationStartedAt ||
      generateStartedBy ||
      otherGenerateStartedBy ||
      isLocked ||
      (uploadType === '1' && !fileProject) ||
      (uploadType === '2' && !fileMA1) ||
      (uploadType === '3' && (!fileMA1 || !fileMA6))

    return (
      <div>
        <Input
          name='uploadType'
          type='select'
          label={t(`${PRE_TRANS}.uploadType.label`)}
          title={t(`${PRE_TRANS}.uploadType.title`)}
          value={uploadType}
          selectors={uploadTypeSelectors}
          onChange={this.handleChangeUploadType}
        />
        <div>
          {
            uploadType === '1' &&
              <div>
                <Button
                  title={t(`${PRE_TRANS}.uploadProjectFile.title`)}
                  onClick={this.handleUploadStrandGuideProjectFile}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${PRE_TRANS}.uploadProjectFile.label`)}
                </Button>
                <FileName title={fileProject || noFile}>{fileProject || noFile}</FileName>
              </div>
          }
          {
            (uploadType === '2' || uploadType === '3') &&
              <div>
                <Button
                  title={t(`${PRE_TRANS}.uploadMA1File.title`)}
                  onClick={this.handleUploadStrandGuideMA1File}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${PRE_TRANS}.uploadMA1File.label`)}
                </Button>
                <FileName title={fileMA1 || noFile}>{fileMA1 || noFile}</FileName>
              </div>
          }
          {
            uploadType === '3' &&
              <div>
                <Button
                  title={t(`${PRE_TRANS}.uploadMA6File.title`)}
                  onClick={this.handleUploadStrandGuideMA6File}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${PRE_TRANS}.uploadMA6File.label`)}
                </Button>
                <FileName title={fileMA6 || noFile}>{fileMA6 || noFile}</FileName>
              </div>
          }
        </div>
        <Button
          title={isLocked ? t(`${PRE_TRANS}.generate.titleLocked`) : t(`${PRE_TRANS}.generate.title`)}
          onClick={this.handleGenerate}
          disabled={disabled}
          loading={generateStartedBy}
          noSuccessIcon
          type='secondary'
          bold
        >
          {t(`${PRE_TRANS}.generate.label`)}
        </Button>
      </div>
    )
  }
}

export default withNamespaces('application')(connector(StrandGuide as any) as any) as any
