import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import cloneDeep from 'clone-deep'

import * as ApplicationActions from 'store/application/main/actions'
import * as VisualizationActions from 'store/visualization/actions'
import CommandUtil from 'react/visualization/CommandUtil'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'

import { CellInput } from '../Styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const mapStateToProps = (state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
})

const mapDispatchToProps = {
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  updateCommandTile: VisualizationActions.updateCommandTile,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  commands: Array<any>,
  value: any | string,
  cell: any,
  currentProject: Project,
  currentSimulationCase: SimulationCase,
  selectedCellData: any,
  onCommit: () => void,
}

type State = {
  defaultSteelGrades: Array<any>,
  customSteelGrades: Array<any>,
  inputValue: string,
  currentCommandFileId: string,
  currentSelectedCommand: any,
  mounted: boolean
};

class InlineEditor extends Component<Props, State> {
  state:State = {
    defaultSteelGrades: [],
    customSteelGrades: [],
    inputValue: '',
    currentCommandFileId: '',
    currentSelectedCommand: {},
    mounted: false,
  }

  saveInterval: number | undefined = undefined;

  @AnalyzeTime(0)
  async componentDidMount () {
    const { value, cell, selectedCellData } = this.props

    if (value) {
      this.setState({ inputValue: cell.value })
    }

    const editSimulationCaseId = selectedCellData[0].simulationCaseId || ''

    if (cell.editType === 'command') {
      const { selectedCommand, commandFileId } = await ApiClient.get(
        `${Network.URI}/visualization_command/first_command`,
        { params: { simulationCaseId: editSimulationCaseId, command: cell.name } },
      )

      let _defaultSteelGrades = []
      let _customSteelGrades = []

      if (selectedCommand.command === 'init_material') {
        // TODO: add error handling!!!
        const { defaultSteelGrades, customSteelGrades } = await CommandUtil.getSteelGrades(editSimulationCaseId)

        _defaultSteelGrades = defaultSteelGrades
        _customSteelGrades = customSteelGrades
      }

      this.setState({
        defaultSteelGrades: _defaultSteelGrades,
        customSteelGrades: _customSteelGrades,
        currentCommandFileId: commandFileId,
        currentSelectedCommand: selectedCommand,
        mounted: true,
      })
    }
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    const { inputValue, currentSelectedCommand, currentCommandFileId } = this.state
    const { cell, currentProject, selectedCellData, commands } = this.props
    const editSimulationCaseId = selectedCellData[0].simulationCaseId || ''
    const oldProject = cloneDeep(currentProject)
    let project = cloneDeep(currentProject)

    if (cell.editType === 'command') {
      const command = CommandUtil.getCommandByName(currentSelectedCommand.command, commands)
      const { parameter } = currentSelectedCommand

      if (!command || !parameter) {
        return null
      }

      const rawParameter = inputValue.split(';')
      let newParameter = []
      let newShortName

      if (currentSelectedCommand.command === 'init_material') {
        const shortName = rawParameter[0]

        if (shortName.toLocaleLowerCase() === 'custom' || !/^[CD]\d+/i.test(shortName)) {
          return null
        }

        const { defaultSteelGrades, customSteelGrades } = this.state
        const steelGradeInfo = CommandUtil.getSteelGradeInfo(shortName, command, defaultSteelGrades, customSteelGrades)

        if (!steelGradeInfo) {
          return null
        }

        newShortName = steelGradeInfo.shortName
        newParameter = steelGradeInfo.parameter
      }
      else {
        newParameter = CommandUtil.applyParameterList(command, rawParameter)
      }

      project = CommandUtil.updateProjectDataWithCommandData(
        project,
        {
          [editSimulationCaseId]: {
            [currentSelectedCommand.command]: { parameter: newParameter, shortName: newShortName },
          },
        },
      )

      this.handleUpdateData(project)

      ApiClient
        .post(`${Network.URI}/visualization_command/${currentCommandFileId}/${currentSelectedCommand._id}`, {
          data: {
            command: {
              parameter: newParameter,
              shortName: newShortName,
            },
            simulationCaseId: editSimulationCaseId,
            selectedCellData,
            keepVerification: true,
          },
        })
        .then(() => {
          // ok
        })
        .catch(() => {
          this.handleUpdateData(oldProject, true)
        })
    }
    else {
      this.handleUpdateData(project)

      ApiClient
        .patch(`${Network.URI}/simulation_case/${editSimulationCaseId}`, { data: { name: inputValue } })
        .then(() => {
          // ok
        })
        .catch(() => {
          this.handleUpdateData(oldProject, true)
        })
    }
  }

  // @AnalyzeTime(0)
  handleUpdateData = (project: Project, reset = false) => {
    const { inputValue, currentSelectedCommand } = this.state
    const {
      cell,
      setCurrentProject,
      currentSimulationCase,
      setCurrentSimulationCase,
      selectedCellData,
      updateCommandTile,
    } = this.props
    const editSimulationCaseId = selectedCellData[0].simulationCaseId || ''

    if (cell.editType === 'command') {
      setCurrentProject(project)

      if (editSimulationCaseId === currentSimulationCase._id) {
        const simCase = project.simulationCases.find(simulationCase => simulationCase._id === editSimulationCaseId)

        if (simCase) {
          setCurrentSimulationCase(simCase)
        }

        updateCommandTile(currentSelectedCommand._id)
      }
    }
    else {
      if (!reset) {
        project.simulationCases.forEach(simulationCase => {
          if (simulationCase._id === editSimulationCaseId) {
            simulationCase.name = inputValue
          }
        })
      }

      setCurrentProject(project)
    }
  };

  // @AnalyzeTime(0)
  handleInput = ({ target: { value } }:any) => this.setState({ inputValue: value });

  // @AnalyzeTime(0)
  handleSaveInterval = () => {
    const { mounted } = this.state

    if (mounted) {
      clearInterval(this.saveInterval)

      this.props.onCommit()
    }
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      const { mounted } = this.state

      if (mounted) {
        this.props.onCommit()
      }
      else {
        this.saveInterval = window.setInterval(this.handleSaveInterval.bind(this), 50)
      }
    }
  };

  // @AnalyzeTime(0)
  bindRef = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  };

  @AnalyzeTime(0)
  render () {
    const { inputValue } = this.state

    return (
      <CellInput
        ref={this.bindRef}
        value={inputValue}
        onChange={this.handleInput}
        onKeyDown={this.handleKeyDown}
      />
    )
  }
}
export default connector(InlineEditor)
