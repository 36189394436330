import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'

import { Form } from '../Dialogs/DialogStyles'

import DataTab from './Tabs/Data'
import MergedTab from './Tabs/Merged'
import EditTab from './Tabs/Edit'
import VerticalTab from './Tabs/Vertical'
import GeneralTab from './Tabs/General'
import AdditionalTab from './Tabs/Additional'
import ShowTab from './Tabs/Show'
import ExtendedTab from './Tabs/Extended'
import EditableFilesTab from './Tabs/EditableFiles'
import CommandSettings from './Tabs/CommandSettings'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

type Props = {
  allowDeletion?: boolean
  fullscreen?: boolean,
  activeTab: number,
  tabs: Array<any>,
  t: Translation
  setEditIdToNull: () => void
};

class PlotConfig extends Component<Props> {
  // @AnalyzeTime(0)
  renderTab = () => {
    const { activeTab, fullscreen, tabs, t, allowDeletion, setEditIdToNull } = this.props

    switch ((tabs[activeTab] || {}).title) {
      case 'general':
        return <GeneralTab fullscreen={fullscreen} />
      case 'merged':
        return <MergedTab fullscreen={fullscreen} allowDeletion={allowDeletion} />
      case 'data':
        return <DataTab fullscreen={fullscreen} />
      case 'extended':
        return <ExtendedTab fullscreen={fullscreen} allowDeletion={allowDeletion} setEditIdToNull={setEditIdToNull} />
      case 'vertical':
        return <VerticalTab fullscreen={fullscreen} />
      case 'show':
        return <ShowTab fullscreen={fullscreen} />
      case 'edit':
        return <EditTab fullscreen={fullscreen} />
      case 'additional':
        return <AdditionalTab fullscreen={fullscreen} />
      case 'editable_files':
        return <EditableFilesTab fullscreen={fullscreen} />
      case 'edit_command':
        return <CommandSettings fullscreen={fullscreen} />
      default:
        return <Form>{t('plotConfig.selectItem')}</Form>
    }
  };

  @AnalyzeTime(0)
  render () {
    const { fullscreen } = this.props

    return (
      <Form fullscreen={fullscreen}>
        {this.renderTab()}
      </Form>
    )
  }
}

export default withNamespaces('visualization')(PlotConfig as any) as any
