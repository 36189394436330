import { AnalyzeTime } from 'Util'

export default class TabsUtil {
  static _tileConfig: any = {};
  static _plotConfig: any = {};

  @AnalyzeTime(0)
  static setConfigs (tileConfig: any, plotConfig: any) {
    TabsUtil._tileConfig = tileConfig
    TabsUtil._plotConfig = plotConfig
  }

  @AnalyzeTime(0)
  static _getValue (value: any, alternative: any): any {
    return value || value === 0 ? value : alternative
  }

  @AnalyzeTime(0)
  static getValue (key: string, alternative?: any): any {
    if (alternative || alternative === 0 || alternative === '') {
      return TabsUtil._getValue(TabsUtil._tileConfig[key], alternative)
    }

    return TabsUtil._getValue(TabsUtil._tileConfig[key], TabsUtil._plotConfig[key])
  }
}
