import type { PassedData } from 'react/Caster'
import type { ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'
import DrawUtil from '../../logic/DrawUtil'
import Util from '../../logic/Util'

export default class UpdateTransformHandler {
  @AnalyzeTime(0)
  static updateTransformPhantoms (
    elementList: any,
    phantomElementList: any,
    SegmentGroup: SegmentGroupHash,
    elementsHashes: ElementsHashes,
  ) {
    const ids = Object.keys(SegmentGroup).filter(key => !key.includes('hasChanges')).map(key => parseInt(key))

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
      ids,
      null,
      '',
      elementsHashes,
      (path: string, type: string) => {
        if (
          type !== 'Segment' &&
            DrawUtil.isDrawable(type) &&
            elementList[type] &&
            elementList[type][path] &&
            elementList[type][path].updateTransform
        ) {
          elementList[type][path].updateTransform()

          if (
            Util.isPhantom(type as CasterElementNames) &&
            phantomElementList[type] &&
            phantomElementList[type][path]
          ) {
            phantomElementList[type][path].updateTransform()
          }
        }
      },
    )
  }

  @AnalyzeTime(0)
  static updateTransformNewPhantoms (phantomElementList: any) {
    Util.phantomTypes.forEach(type => {
      if (Object.keys(phantomElementList[type] || {}).includes('new')) {
        phantomElementList[type].new.updateTransform()
      }
    })
  }

  @AnalyzeTime(0)
  static updateTransformElements (elementList: any, data: PassedData) {
    const { SegmentGroup } = data.elementsHashes
    const ids = Object.keys(SegmentGroup).filter(key => !key.includes('hasChanges')).map(key => parseInt(key))

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
      ids,
      null,
      '',
      data.elementsHashes,
      (path: string, type: string) => {
        if (
          type !== 'Segment' &&
            DrawUtil.isDrawable(type) &&
            elementList[type] &&
            elementList[type][path] &&
            elementList[type][path].updateTransform
        ) {
          elementList[type][path].updateTransform()
        }
      },
    )
  }

  @AnalyzeTime(0)
  static updateTransformSegments (containerListSegment: any, elementListSegment: any) {
    Object.keys(containerListSegment || {}).forEach(path => {
      elementListSegment[path].updateTransform()
    })
  }

  static updateTransformDataPoints (strand: any, dataPoints: any) {
    (strand.children || []).forEach((child: any) => {
      if (child.type === 'DataPoint' && dataPoints[child.path]) {
        dataPoints[child.path].updateTransform()
      }
    })
  }

  static updateTransformDataLines (strand: any, dataLines: any) {
    (strand.children || []).forEach((child: any) => {
      if (child.type === 'DataLine' && dataLines[child.path]) {
        dataLines[child.path].updateTransform()
      }
    })
  }
}
