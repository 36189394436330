import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from '../../../components/Button'
import { Form } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import { FixedButton } from '../../DialogStyles'
import Input from '../../../specific/Input'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from '../../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.cloneDialog'

const connector = connect((state: DefaultState) => ({
  cloneSimulationCaseId: state.matrix.cloneSimulationCaseId,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  cloneCount: string,
  error: string,
  loading: boolean
};

class CloneDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    cloneCount: '1',
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CloneDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleCloneCountChange = (event: any) => this.setState({ cloneCount: String(event.target.value).replace('-', '') });

  // @AnalyzeTime(0)
  handleCloneSubmit = () => {
    const { cloneCount } = this.state
    const { cloneSimulationCaseId, setCurrentProject } = this.props

    this.setState({ loading: true })

    ApiClient.post(`${Network.URI}/simulation_case/clone/${cloneSimulationCaseId}`, { data: { cloneCount } })
      .then(({ project }) => {
        if (project && project._id) {
          setCurrentProject(project)

          this.handleClose()

          const content = (document.querySelector('.data-grid-container') || {}).parentNode as any

          if (content) {
            content.scrollTop = content.scrollHeight
          }
        }

        this.setState({ loading: false })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ loading: false, error: 'ERROR' })
      })
  };

  @AnalyzeTime(0)
  render () {
    const { cloneCount, error, loading } = this.state
    const { t } = this.props

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-photo-gallery'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            autoFocus
            label={t(`${PRE_TRANS}.label`)}
            title={t(`${PRE_TRANS}.name`)}
            name='name'
            type='number'
            value={cloneCount}
            onChange={this.handleCloneCountChange}
          />
          <FixedButton>
            <Button
              type='primary'
              disabled={!cloneCount || cloneCount === '0'}
              onClick={this.handleCloneSubmit}
              error={error}
              loading={loading}
              icon='pe-7s-photo-gallery'
              isRef
            >
              {t(`${PRE_TRANS}.clone`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(CloneDialog as any) as any) as any
