import { AnalyzeTime } from 'Util'

export default class SVG {
  static NAMESPACE_URI = 'http://www.w3.org/2000/svg';

  @AnalyzeTime(0)
  static getElement (type: string) {
    return document.createElementNS(SVG.NAMESPACE_URI, type)
  }

  @AnalyzeTime(0)
  static getSVG () {
    return SVG.getElement('svg')
  }

  @AnalyzeTime(0)
  static getG () {
    return SVG.getElement('g')
  }

  @AnalyzeTime(0)
  static getPath () {
    return SVG.getElement('path')
  }
}
