/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SegmentGroup = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    _passln_coord:     { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    _id:               { type: 'number', category: 2, defaultValue: 0, disabled: true },
    _name:             { type: 'text', category: 2, defaultValue: '' },
    _numericId:        { type: 'number', hidden: true },
    _originalId:       { type: 'number', hidden: true },
    _parentId:         { type: 'number', hidden: true },
    _EndPassLnCoord:   { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    _StartPassLnCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
  },
}
/* eslint-enable key-spacing */
