import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import TypeHeader from './TypeHeader'
import AllInOne from '../AllInOne'

import DataActions from '../../../store/data/actions'
import * as FilterActions from '../../../store/filter/actions'
import { DefaultState } from 'types/state'
import CCElementList from '../CCElementList'
import Modules from '../Modules'
import SegmentGroup from '../SegmentGroup'
import FeatureFlags from 'react/FeatureFlags'
import ThreeManager from 'three/ThreeManager'

const connector = connect((store: DefaultState) => ({
  CCElement: store.CCElement,
  loadingStatus: store.application.main.loadingStatus,
  filterElement: store.filter.filterElement,
  rootData: store.data.rootData,
  featureFlags: store.application.main.authenticationData.featureFlags,
}), {
  setEditValues: DataActions.setEditValues,
  setFilterValues: FilterActions.setFilterValues,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
  key: string
  type: CasterDialogElementType | 'ConsistencyCheck' | 'Modules' | 'Elements'
  paths: string[]
  areElementsShown: boolean
  toggleElements: () => void
}

type State = {
  isExpandedMain: boolean,
  isSelected: boolean
};

export class TypeBuilder extends Component<Props, State> {
  constructor (props: Props) {
    super(props)
    const { paths } = props

    if (!paths || !paths.length) {
      this.state = {
        isExpandedMain: false,
        isSelected: false,
      }

      return
    }

    this.state = {
      isExpandedMain: true,
      isSelected: true,
    }
  }

  componentDidUpdate (prevProps: Props) {
    const { paths, type } = this.props

    if (type === 'ConsistencyCheck' || type === 'Modules') {
      return
    }

    if (!prevProps.paths.equals(paths)) {
      let isOpen = true

      if (!paths || !paths.length) {
        isOpen = false
      }

      this.setState({
        isExpandedMain: isOpen,
        isSelected: isOpen,
      })
    }
  }

  handleToggleMain = (event: any) => {
    event.preventDefault()

    this.setState({
      isExpandedMain: !this.state.isExpandedMain,
    })

    const { type, setEditValues, setFilterValues, filterElement } = this.props

    if (type === 'Modules') {
      return
    }

    setFilterValues({
      ...filterElement,
      [type]: {},
    })

    setEditValues({ [type]: {} })
  };

  getCorrespondingTypeComponent (type: string, paths: string[]) {
    switch (type) {
      case 'ConsistencyCheck':
        return <CCElementList paths={paths} />
      case 'Modules':
        return <Modules paths={paths} />
      case 'Elements':
        return null
      case 'SegmentGroup':
        return <SegmentGroup paths={paths} />
      default:
        return <AllInOne paths={paths} type={type} />
    }
  }

  render () {
    const { isExpandedMain, isSelected } = this.state
    const {
      CCElement,
      type,
      paths,
      loadingStatus,
      rootData,
      t,
      areElementsShown,
      toggleElements,
      featureFlags,
    } = this.props

    switch (type) {
      case 'ConsistencyCheck':
        if (Object.keys(CCElement || {}).length === 0) {
          return null
        }

        break
      case 'Modules':
        if (!FeatureFlags.canViewModules(featureFlags)) {
          return null
        }

        break
      case 'Nozzle':
        if (!FeatureFlags.canViewNozzlesIn3D(featureFlags)) {
          return null
        }

        break
      case 'Roller':
      case 'RollerBearing':
      case 'RollerBody':
        if (!FeatureFlags.canViewRollersIn3D(featureFlags)) {
          return null
        }

        break
      case 'SensorPoint':
        if (!ThreeManager.base.views?.mainView?.elementList?.SensorPoint) {
          return null
        }

        break
      case 'SupportPoint':
      case 'SegmentGroup':
        if (!FeatureFlags.canViewSupportPointsIn3D(featureFlags)) {
          return null
        }

        break
    }

    return (
      <div>
        <TypeHeader
          title={t(`type.${type}.title`)}
          type={t(`type.${type}.label`)}
          isExpanded={
            !loadingStatus &&
            rootData.Caster &&
            (type === 'Elements' ? areElementsShown : isExpandedMain)
          }
          isSelected={!loadingStatus && rootData.Caster && isSelected}
          onClick={type === 'Elements' ? toggleElements : this.handleToggleMain}
        />
        {
          !loadingStatus &&
          rootData.Caster &&
          isExpandedMain &&
          this.getCorrespondingTypeComponent(type, paths)
        }
      </div>
    )
  }
}

export default withNamespaces('caster')(connector(TypeBuilder as any) as any) as any
