import * as THREE from 'three'
import { Vector2 } from 'three'
import { Views } from 'three/ThreeBase'
import { AnalyzeTime } from 'Util'

import BaseView from '../BaseView'
import CubeHandlers from './CubeHandlers'

export default class ViewsView extends BaseView {
  constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    ViewsView.staticClassName = 'ViewsView'
    this.className = 'ViewsView'

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000)
    this.camera.position.z = 3

    this.cube = CubeHandlers.initCube()
    this.cube.name = 'ViewCube'

    this.clickableObjects.push(this.cube)
    this.scene.add(this.cube)
  }

  cube

  @AnalyzeTime(0)
  resize (width: number, _height: number) {
    const size = 100

    this.viewport = {
      x: width - size,
      y: 0,
      width: size,
      height: size,
    }
  }

  @AnalyzeTime(1)
  animate () {
    if (!this.views || !this.camera || !this.views.mainView) {
      return
    }

    const { camera, controls } = this.views.mainView

    this.camera?.position.copy(camera.position)
    this.camera?.position.sub(controls.target)
    this.camera?.position.setLength(3)
    this.camera?.lookAt(this.scene.position)
  }

  @AnalyzeTime(0)
  handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    if (intersects.length &&
      intersects[0].object.name === this.cube.name &&
      intersects[0].face && (intersects[0].face || {}).materialIndex !== undefined) {
      this.views?.mainView?.setView(intersects[0].face.materialIndex)
    }
  }

  @AnalyzeTime(0)
  hide () {
    this.cube.visible = false
  }

  @AnalyzeTime(0)
  show () {
    this.cube.visible = true
  }
}
