import ThreeManager from 'three/ThreeManager'
import { AnalyzeTime } from 'Util'
import SectionView from '.'
import ToggleHandlers from './ToggleHandlers'

export default class EventHandlers {
  @AnalyzeTime(0)
  static handleActions (intersects: any[], view: SectionView) {
    for (let i = 0; i < intersects.length; i++) {
      const { type, userData } = intersects[i].object

      if (type === 'Button' && userData.action) {
        const similarActions = [ 'toggleLooseSide', 'toggleFixedSide', 'toggleNarrowFaceRight', 'toggleNarrowFaceLeft' ]

        if (similarActions.includes(userData.action)) {
          ToggleHandlers.toggleSides(view, userData.action.replace('toggle', ''))
        }
        else {
          (ToggleHandlers as any)[userData.action](view)
        }
      }
    }

    ThreeManager.base.renderScene()
  }
}
