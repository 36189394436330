import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import symbols from 'plotly.js/src/components/drawing/symbol_defs'

import * as VisualizationActions from '../../../../../store/visualization/actions'
import Input from '../../../../specific/Input'
import { FormWrapper, InputWrapper, CheckListHeader, CheckListItem, Marker } from '../../Dialogs/DialogStyles'
import ColorPicker from '../../ColorPicker'
import VisUtil from '../../../VisUtil'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import { Spacer } from 'react/dialogs/project/OpenProjectDialog/Styles'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean,
  t: Translation
}

type State = {
  tileConfig: any
};

class ShowTab extends Component<Props, State> {
  timeoutRef?: number;

  constructor (props: Props) {
    super(props)
    const { tileConfigs, currentTileId } = props

    this.state = {
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
    }
  }

  // @AnalyzeTime(0)
  componentDidUpdate = (pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        this.timeoutRef = undefined
      }, 1000)
    }
  };

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  };

  // @AnalyzeTime(0)
  handleCheckList = (event: any) => {
    const { id } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions || {}

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id]: {
            ...((legendOptions || {})[id] || {}),
            hide: !((legendOptions || {})[id] || {}).hide,
          },
        },
      },
    })
  };

  // @AnalyzeTime(0)
  handleLegendOptions = (event: any, fallbackId?: string) => {
    const { id, name, value } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions || {}

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id || fallbackId]: {
            ...(legendOptions[id || fallbackId] || {}),
            [name]: value,
          },
        },
      },
    })
  };

  handleHideLine = (event: any) => {
    const { id } = event.target
    const { tileConfig } = this.state
    const legendOptions = tileConfig.legendOptions || {}
    const markerSymbolConfig = ((legendOptions || {})[id] || {}).markerSymbol

    this.setState({
      tileConfig: {
        ...tileConfig,
        legendOptions: {
          ...legendOptions,
          [id]: {
            ...((legendOptions || {})[id] || {}),
            hideLine: !((legendOptions || {})[id] || {}).hideLine,
            markerSymbol: markerSymbolConfig === undefined || markerSymbolConfig === 'none'
              ? 'circle' : markerSymbolConfig,
          },
        },
      },
    })
  };

  onMarkerSizeChange = (event: any, fallbackId?: string) => {
    if (event.target.value < 5 || event.target.value > 50) {
      return
    }

    this.handleLegendOptions(event, fallbackId)
  };

  @AnalyzeTime(0)
  render () {
    const { editDialogConfigId, plotConfigs, fullscreen, t } = this.props
    const { tileConfig } = this.state

    if (!tileConfig) {
      return null
    }

    const { configIds } = plotConfigs[editDialogConfigId]
    const { configId, legendOptions } = tileConfig
    const typeIsLine = Boolean(tileConfig.type === 'line')

    let markerSizes: Array<{ key: number, value: number }> | undefined
    let markerSymbols: Array<{ key: string, value: any }> | undefined

    if (typeIsLine) {
      markerSizes = [ 1, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 36 ]
        .map(number => ({
          key: number,
          value: number,
        }))

      markerSymbols = Object.keys(symbols)
        .filter((key) => !symbols[key].needLine)
        .map(key => ({
          key,
          value: (
            <span title={key}>
              <svg viewBox='-16 -16 32 32' width='24' height='24' className='ply-icon'>
                <path d={symbols[key].f(12)} />
              </svg>
            </span>
          ),
        }))

      markerSymbols.unshift({
        key: 'none',
        value: (
          <span title='None'>
            <svg viewBox='0 0 16 16' width='24' height='24' className='ply-icon-none'>
              <line x1='2' y1='14' x2='14' y2='2' />
            </svg>
          </span>
        ),
      })
    }

    return (
      <FormWrapper>
        <CheckListHeader width='99px'>{t('plotConfig.display')}</CheckListHeader>
        <CheckListHeader width={typeIsLine ? '190px' : '311px'}>{t('plotConfig.legend')}</CheckListHeader>
        <CheckListHeader width={typeIsLine ? '190px' : '311px'}>{t('plotConfig.trace')}</CheckListHeader>
        <CheckListHeader width={typeIsLine ? '51px' : '35px'}>{t('plotConfig.color')}</CheckListHeader>
        {
          typeIsLine && (
            <CheckListHeader width='76px'>{t('plotConfig.hideLine')}</CheckListHeader>
          )
        }
        {
          typeIsLine && (
            <CheckListHeader>{t('plotConfig.marker')}</CheckListHeader>
          )
        }
        {
          (configIds || [ configId ]).map((id, index) => (
            <InputWrapper key={`${id}-${index}`} fullscreen={fullscreen}>
              <CheckListItem
                id={id}
                onClick={this.handleCheckList}
                active={((legendOptions || {})[id] || {}).hide}
              >
                {t('plotConfig.index', { index: index + 1 })}
              </CheckListItem>
              <Input
                id={id}
                placeholder={t('plotConfig.label.label')}
                title={t('plotConfig.label.title')}
                name='label'
                type='text'
                value={((legendOptions || {})[id] || {}).label || ''}
                onChange={this.handleLegendOptions}
              />
              <Input
                id={id}
                placeholder={t('plotConfig.tooltip.label')}
                title={t('plotConfig.tooltip.title')}
                name='tooltip'
                type='text'
                value={((legendOptions || {})[id] || {}).tooltip || ''}
                onChange={this.handleLegendOptions}
              />
              <ColorPicker
                color={((legendOptions || {})[id] || {}).color || VisUtil.TRACE_COLORS[index] || ''}
                colors={VisUtil.TRACE_COLORS}
                id={id}
                name='color'
                onChange={this.handleLegendOptions}
              />
              {
                typeIsLine && (
                  <Marker>
                    <Spacer w={30} />
                    <CheckListItem
                      style={{ width: '28px', padding: 0 }}
                      id={id}
                      onClick={this.handleHideLine}
                      active={!((legendOptions || {})[id] || {}).hideLine}
                    />
                    <Spacer w={30} />
                    <div style={{ width: '64px' }}>
                      <Input
                        name='markerSymbol'
                        title={t('plotConfig.markerSymbol')}
                        type='select'
                        value={((legendOptions || {})[id] || {}).markerSymbol || 'none'}
                        selectors={markerSymbols}
                        onChange={(event) => this.handleLegendOptions(event, id)}
                      />
                    </div>
                    <Spacer w={20} />
                    <div style={{ width: '64px' }}>
                      <Input
                        id={id}
                        title={t('plotConfig.markerSize')}
                        name='markerSize'
                        type='select'
                        selectors={markerSizes}
                        value={((legendOptions || {})[id] || {}).markerSize || '6'}
                        onChange={(event) => this.handleLegendOptions(event, id)}
                      />
                    </div>
                    <Spacer w={15} />
                    <ColorPicker
                      color={
                        ((legendOptions || {})[id] ||
                        {}).markerColor ||
                        ((legendOptions || {})[id] || {}).color ||
                        VisUtil.TRACE_COLORS[index] ||
                        ''
                      }
                      colors={VisUtil.TRACE_COLORS}
                      id={id}
                      name='markerColor'
                      onChange={this.handleLegendOptions}
                    />
                  </Marker>
                )
              }
            </InputWrapper>
          ))
        }
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(ShowTab as any) as any) as any
