import { Vector2, Vector3 } from 'three'
import { AnalyzeTime } from 'Util'
import MainView from '.'

type bou = boolean | undefined
export default class ConditionUtil {
  @AnalyzeTime(0)
  static shouldBeRedrawing (
    redraw: bou,
    data: RootData | undefined,
    incomingData: any,
    hasChanges: bou,
    isRedrawing: boolean,
    dataChanged = false,
  ) {
    const condition = ((
      redraw ||
      !data ||
      !data.Caster ||
      (hasChanges !== undefined && hasChanges && !incomingData.hasChanges)
    ) &&
      !isRedrawing &&
      dataChanged)

    return condition
  }

  @AnalyzeTime(0)
  static isCameraInPlace (pos: Vector3, target: Vector3, position: Vector3, center: Vector3) {
    return (pos.clone().sub(position).length() < 0.0000001 && target.clone().sub(center).length() < 0.0000001)
  }

  @AnalyzeTime(0)
  static selectionIsOnScreen (min: Coord, max: Coord, posOnScreen: Vector2) {
    return (posOnScreen.x >= min.x && posOnScreen.x <= max.x && posOnScreen.y >= min.y && posOnScreen.y <= max.y)
  }

  @AnalyzeTime(0)
  static editElementsOrParentPathAreDifferent (view: MainView, data: any) {
    const { editElements: viewEditElements, parentPath: viewParentPath } = view
    const { editElements, parentPath } = data

    return (!editElements.equals(viewEditElements) || !parentPath.equals(viewParentPath))
  }
}
