import cloneDeep from 'clone-deep'

import { InputDataDialog } from 'react/dialogs/project/InputDataDialog'
import ProjectMatrixDialog from 'react/dialogs/ProjectMatrixDialog'

import { ApplicationMainActionsEnum as AppMainActions } from '../consts'
import OpenProjectDialog from 'react/dialogs/project/OpenProjectDialog'
import ProjectDataDialog from 'react/dialogs/project/ProjectDataDialog'

const setReducers = {
  [AppMainActions.ACTION_SET_LOADING_STATUS]: (state:ApplicationMainState, action:{type: string, status:boolean}) => ({
    ...state,
    loadingStatus: action.status,
  }),

  [AppMainActions.ACTION_SET_TOOLTIP]: (
    state:ApplicationMainState,
    { key, visible, tooltips }:{key: string, visible:boolean, tooltips:Tooltip[]},
  ) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      [key]: visible ? tooltips : [],
    },
  }),

  [AppMainActions.ACTION_SET_REQUIRED_COMMAND_FILES]: (
    state:ApplicationMainState,
    { nonexistentCommandFiles, nonexistentCommandFileMapping, visualizationConfigId }:
    {nonexistentCommandFiles:{[key: string]:{fileName: string, commands: string[]}},
    nonexistentCommandFileMapping: string[],
    visualizationConfigId: string},
  ) => ({
    ...state,
    nonexistentCommandFiles,
    nonexistentCommandFileMapping,
    latestUploadedVisualizationConfigId: visualizationConfigId,
  }),

  [AppMainActions.ACTION_SET_CURRENT_PROJECT]:
  (state:ApplicationMainState, { currentProject }: {currentProject:Project}) => ({
    ...state,
    currentProject,
  }),

  [AppMainActions.ACTION_SET_CURRENT_SIMULATION_CASE]: (
    state:ApplicationMainState,
    {
      currentSimulationCase,
      preventDefault,
    }:{currentSimulationCase:SimulationCase, preventDefault:boolean},
  ) => {
    const { currentProject } = state
    const updatedProject = cloneDeep(currentProject)

    updatedProject.simulationCases = currentProject.simulationCases.map((sc:SimulationCase) =>
      sc._id === currentSimulationCase._id ? currentSimulationCase : sc)

    const additionalDialogs = []

    if (state.openAppDialogs.includes(InputDataDialog.NAME)) {
      additionalDialogs.push(InputDataDialog.NAME)
    }

    const matrixOpened = state.openAppDialogs.includes(ProjectMatrixDialog.NAME)

    return ({
      ...state,
      currentSimulationCase,
      currentProject: updatedProject,
      openAppDialogs: !currentSimulationCase || !currentSimulationCase._id
      // if no project is opened show OpenProjectDialog
        ? [ OpenProjectDialog.NAME ]
      // else
        : (!preventDefault && !currentSimulationCase.currentCasterRoot && !matrixOpened &&
            (!currentSimulationCase.visualizationConfigList.length ||
                 !currentSimulationCase.visualizationDataList.length)
        // if there is no caster or vis data in the project show ProjectDataDialog
          ? [ ProjectDataDialog.NAME, ...additionalDialogs ]
        // else keep dialogs open
          : [ ...state.openAppDialogs ]
        ),
    })
  },

  [AppMainActions.ACTION_SET_CURRENT_CASTER_ROOT]: (state:ApplicationMainState, { root }:{root:Root}) => ({
    ...state,
    currentCasterRoot: root,
  }),

  [AppMainActions.ACTION_SET_AUTHENTICATION_DATA]: (state:ApplicationMainState, { data }: {data:AuthData}) => {
    if (!data) {
      return {
        ...state,
        authenticationData: null,
      }
    }

    return {
      ...state,
      authenticationData: { ...data },
    }
  },

  [AppMainActions.ACTION_SET_THEME]: (state:ApplicationMainState, { darkTheme }:{darkTheme:boolean}) => ({
    ...state,
    darkTheme: darkTheme || !state.darkTheme,
  }),

  [AppMainActions.ACTION_SET_EDIT_PROJECT_ID]: (state:ApplicationMainState, { projectId }:{projectId: string}) => ({
    ...state,
    editProjectId: projectId,
  }),

  [AppMainActions.ACTION_SET_EDIT_SIMULATION_CASE_ID]: (state: ApplicationMainState, { simulationCaseId }:
    {simulationCaseId: string}) => ({
    ...state,
    editSimulationCaseId: simulationCaseId,
  }),

  [AppMainActions.ACTION_SET_EDIT_CONFIG_ID]: (state:ApplicationMainState, { configId }:{configId: string}) => ({
    ...state,
    editConfigId: configId,
  }),

  [AppMainActions.ACTION_SET_APP_STATE]: (
    state:ApplicationMainState,
    { mode, avoidDashboard }:{mode:number, avoidDashboard?: boolean},
  ) => {
    if (avoidDashboard) {
      return {
        ...state,
        appState: mode,
      }
    }

    return {
      ...state,
      appState: mode,
      currentSimpleDashboardTabIndex: 0,
    }
  },

  [AppMainActions.ACTION_SET_SIMPLE_DASHBOARD_TAB_INDEX]:
  (state:ApplicationMainState, { tabIndex }:{tabIndex:number}) => ({
    ...state,
    currentSimpleDashboardTabIndex: tabIndex,
  }),

  [AppMainActions.ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG]:
  (state:ApplicationMainState, { defaultCasterDashboardConfigId }:{defaultCasterDashboardConfigId:number}) => ({
    ...state,
    currentSimulationCase: { ...state.currentSimulationCase, defaultCasterDashboardConfigId },
  }),

  [AppMainActions.ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID]:
  (state:ApplicationMainState, { lastLoadedCasterCatalogId }:{lastLoadedCasterCatalogId:string}) => ({
    ...state,
    currentSimulationCase: { ...state.currentSimulationCase, lastLoadedCasterCatalogId },
  }),
}

export default setReducers
