import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'

import ball from '../icon/waiting-1.svg'
import innerCircle from '../icon/waiting-2.svg'
import middleCircle from '../icon/waiting-3.svg'
import outerCircle from '../icon/waiting-4.svg'
import { DefaultState } from 'types/state'

const CasterHeader = styled.div`
  position:   absolute;
  width:      100%;
  background: #22282e;
  height:     50px;
`

const Logo = styled.div`
  display:        inline-block;
  width:          30px;
  height:         30px;
  vertical-align: middle;
  margin:         10px;
  position:       relative;
  user-select:    none;
`

const Image = styled.img`
  position:       absolute;
  user-select:    none;
  pointer-events: none;
`

const TitleBox = styled.div`
  color:       #EEEEEE;
  font-weight: bold;
  padding:     0 5px;
`

const Title = styled.h2`
  color:          #EEEEEE;
  font-weight:    bold;
  padding:        0 5px;
  margin:         13px 0;
  display:        inline-block;
  vertical-align: sub;
  user-select:    none;
  pointer-events: none;
`

const connector = connect((state: DefaultState) => ({
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
  onScrollToTop: () => void // TODO: remove
}

class CasterOptions extends Component<Props> {
  render () {
    const { onScrollToTop, t } = this.props

    return (
      <CasterHeader>
        <TitleBox>
          <Logo onClick={onScrollToTop}>
            <Image src={ball} alt='Logo' />
            <Image src={innerCircle} alt='Logo' />
            <Image src={middleCircle} alt='Logo' />
            <Image src={outerCircle} alt='Logo' />
          </Logo>
          <Title>{t('title')}</Title>
        </TitleBox>
      </CasterHeader>
    )
  }
}

export default withNamespaces('caster')(connector(CasterOptions as any) as any) as any
