import { AppState } from 'store/application/main/consts'
import Util from '../../../../logicHandlers/ServerLogic/actions/Util'
// import CreateCommandFileDialog from '../CreateCommandFileDialog'
import { Props } from '.'
import { AnalyzeTime } from 'Util'
import ThreeManager from 'three/ThreeManager'

export default class Logic {
  @AnalyzeTime(0)
  static init (dialogInstance: any): void {
    Logic.dialogInstance = dialogInstance
  }

  static dialogInstance: {props: Props};

  @AnalyzeTime(0)
  static handleUploadCasterData (
    action: 'replaceCaster' | 'replaceNozzles' | 'mergeNozzles' = 'replaceCaster',
    setLoading: any,
    setError: any,
  ): void {
    const {
      currentSimulationCase,
      setCurrentSimulationCase,
      setCurrentCasterRoot,
      setAppState,
      resetReducer,
    } = Logic.dialogInstance.props

    if (currentSimulationCase._id) {
      Util.openUploadFileDialog('.xml', '/caster_data', (formData: any) => {
        setError('CasterData')
        setLoading('CasterDataIsLoading', true)

        formData.append('simulationCaseId', currentSimulationCase._id)
        formData.append('action', action)
        ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
      }).then(({ simulationCase }) => {
        setAppState(AppState.Caster)
        resetReducer(false, true)
        setCurrentCasterRoot()
        setCurrentSimulationCase(simulationCase)
        setError('CasterData')
        setLoading('CasterDataIsLoading', false)
      }).catch(({ status }) => {
        setError('CasterData', status)
        setLoading('CasterDataIsLoading', false)
      })
    }
  }

  @AnalyzeTime(0)
  static handleUploadCasterCatalog (
    callback: (catalogId?: string) => void,
    setError: (type: string, error?: string) => void,
    props?: any,
  ): void {
    const { currentSimulationCase, setCurrentSimulationCase } = props || Logic.dialogInstance.props

    if (currentSimulationCase._id) {
      Util.openUploadFileDialog('.csv', '/caster_catalog', (formData: any) => {
        formData.append('simulationCaseId', currentSimulationCase._id)
      }).then(({ simulationCase, fileName }: { simulationCase: SimulationCase, fileName: string }) => {
        setCurrentSimulationCase(simulationCase)

        const catalogs = simulationCase.casterCatalogList.filter(catalog => catalog.name === fileName)

        setError('catalog')
        callback(catalogs.slice(-1)[0].catalogId)
      }).catch(({ status }) => {
        setError('catalog', status)

        callback('default')
      })
    }
    else {
      callback('default')
    }
  }

  @AnalyzeTime(0)
  static handleUploadVisualizationConfig (
    callback: any,
    setError: (type: string, error?: string) => void,
    usedAppState: number,
  ): void {
    const {
      currentSimulationCase,
      setCurrentSimulationCase,
      setRequiredCommandFiles,
      logout,
    }: {
      currentSimulationCase: SimulationCase,
      setCurrentSimulationCase: (simCase: SimulationCase) => void,
      openDialog: (dialogName: string) => void,
      setRequiredCommandFiles: any
      logout: () => void
    } = Logic.dialogInstance.props

    if (currentSimulationCase._id) {
      Util
        .openUploadFileDialog('.json', '/visualization_config', (formData: any) => {
          formData.append('simulationCaseId', currentSimulationCase._id)
          formData.append('appState', usedAppState)
        })
        .then(({
          simulationCase,
          fileName,
          nonexistentCommandFiles,
          nonexistentCommandFileMapping,
          visualizationConfigId,
        }: {
          simulationCase: SimulationCase,
          fileName: string,
          nonexistentCommandFiles: Record<string, NonexistentCommandFile>
          nonexistentCommandFileMapping: string[] | null
          visualizationConfigId: string
        }) => {
          setCurrentSimulationCase(simulationCase)

          if (Object.keys(nonexistentCommandFiles).length > 0) {
            setRequiredCommandFiles(nonexistentCommandFiles, nonexistentCommandFileMapping, visualizationConfigId)
            // openDialog(CreateCommandFileDialog.NAME)
          }

          const configs = simulationCase.visualizationConfigList.filter(config => config.name === fileName)

          setError('config')
          callback('config', (configs.slice(-1)[0] || {}).configId, usedAppState)
        })
        .catch(({ status }) => {
          setError('config', status)

          // TODO: move to middleware
          if (status === 'Unauthorized') {
            logout()

            // TODO: notification
          }

          callback('config', 'default', usedAppState)
        })
    }
    else {
      callback('config', 'default', usedAppState)
    }
  }

  // @AnalyzeTime(0)
  static getSelectors = (
    list: Array<any>,
    key: string,
    value: string,
    defaultObj?: any | null,
    showIndex?: boolean | null,
  ): Array<any> => {
    const selectors = (list || []).map((entry, index) => ({
      key: entry[key],
      value: `${showIndex ? `${index + 1}. ` : ''}${entry[value]}`,
    }))

    if (defaultObj) {
      selectors.unshift({ key: 'default', notRemovable: true, ...defaultObj })
    }

    return selectors
  };

  @AnalyzeTime(0)
  static getSelectedConfig (selectedConfig: string | undefined | null, configs: Array<any>): string {
    if (selectedConfig === 'default' || (selectedConfig && !configs.length)) {
      return 'default'
    }

    if (selectedConfig && configs.findIndex(config => config.configId === selectedConfig) === -1) {
      return (configs[0] || {}).configId || 'default'
    }

    return selectedConfig || (configs[0] || {}).configId || 'default'
  }

  static markDefaultDashboardConfig (
    configSelectors: Array<any>,
    defaultCaseConfigId?: string,
    defaultGroupConfigId?: string,
  ): void {
    if (!defaultGroupConfigId && !defaultCaseConfigId) {
      return
    }

    if (defaultCaseConfigId) {
      const defaultCaseConfig = configSelectors.find(configSelectors => configSelectors.key === defaultCaseConfigId)

      if (defaultCaseConfig) {
        defaultCaseConfig.value += ' (case def.)'
      }

      return
    }

    if (defaultGroupConfigId) {
      const defaultGroupConfig = configSelectors.find(configSelectors => configSelectors.key === defaultGroupConfigId)

      if (defaultGroupConfig) {
        defaultGroupConfig.value += ' (group def.)'
      }
    }
  }

  @AnalyzeTime(0)
  static getSelectedCatalog (selectedCatalog: string | undefined | null, catalogs: Array<any>): string {
    const catalogList = catalogs || []

    if (selectedCatalog && !catalogList.length) {
      return 'default'
    }

    if (selectedCatalog && catalogList.findIndex(config => config.catalogId === selectedCatalog) === -1) {
      return (catalogList[0] || {}).catalogId || 'default'
    }

    return selectedCatalog || (catalogList[0] || {}).catalogId || 'default'
  }
}
