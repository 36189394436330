import React from 'react'
import { Tooltip, withStyles } from '@material-ui/core'

import ApiClient from '../../../../store/apiClient'
import { Network } from '../../../../network/Network'

import SheetRenderer from '../SheetRenderer'
import RowRenderer from '../RowRenderer'
import { ProjectMatrixDialog } from '../.'

import Getter from './getters'
import Checker from './checkers'
import Builder from './builders'
import Handler from './handlers'

import Renderer from './renderers'
import { AnalyzeTime } from 'Util'

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    maxHeight: 300,
    overflow: 'auto',
    userSelect: 'none',
  },
}))(Tooltip)

export default class Logic {
  static PRE_TRANS = 'projectMatrixDialog'
  static dialogInstance: ProjectMatrixDialog;

  static init (dialogInstance: ProjectMatrixDialog): void {
    Logic.dialogInstance = dialogInstance
  }

  @AnalyzeTime(0)
  static async prepare () {
    // TODO: this should be stored in the store not requested over and over again...
    const { commands } = await ApiClient.get(`${Network.URI}/visualization_command/commands`)

    // TODO: error handling

    Logic.dialogInstance.setState({ commands })
  }

  static getMissingData = Getter.getMissingData;

  static buildColumns = Builder.buildColumns;

  static buildGrid = Builder.buildGrid;

  static checkIfVerified = Checker.checkIfVerified;

  static getStatus = Getter.getStatus;

  static handleClose = Handler.handleClose;

  static handleConfigure = Handler.handleConfigure;

  static handleSimulateMultiple = Handler.handleSimulateMultiple;

  static handleStopSelected = Handler.handleStopSelected;

  static handleResetSelected = Handler.handleResetSelected;

  static sheetRenderer = (renderProps: any) => <SheetRenderer {...renderProps} />;

  static rowRenderer = (renderProps: any) => <RowRenderer {...renderProps} />;

  static valueRenderer = Renderer.valueRenderer;

  static renderDataEditor = Renderer.renderDataEditor;

  static handleDataEditor = Handler.handleDataEditor;

  static handleSelect = Handler.handleSelect;

  static handleCopy = Handler.handleCopy

  static handlePaste = Handler.handlePaste
}
