import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import Button from '@material-ui/core/Button'

import Icon from 'react/specific/Icon'
import * as ApplicationActions from 'store/application/main/actions'
import StopSimulationDialog from '../StopSimulationDialog'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isSelected: boolean,
  stopSimulationLoading: boolean,
  isSelectedAndRunning: boolean,
  setSelections: any, // TODO: remove
  t: Translation
}

class StopSelected extends Component<Props> {
  // @AnalyzeTime(0)
  handleClick = () => {
    const { openDialog } = this.props

    openDialog(StopSimulationDialog.NAME)
  };

  // @AnalyzeTime(0)
  getTitle = () => {
    const { isSelected, stopSimulationLoading, isSelectedAndRunning, t } = this.props

    if (!isSelected) {
      return t(`${PRE_TRANS}.titles.stopSelected.nothingSelected`)
    }

    if (stopSimulationLoading) {
      return t(`${PRE_TRANS}.titles.stopSelected.loading`)
    }

    if (!isSelectedAndRunning) {
      return t(`${PRE_TRANS}.titles.stopSelected.notRunning`)
    }

    return t(`${PRE_TRANS}.titles.stopSelected.default`)
  };

  @AnalyzeTime(0)
  render () {
    // restProps should not contain currentProject
    const { isSelected, stopSimulationLoading, isSelectedAndRunning, t, ...restProps } = this.props
    const disabled = !isSelected || stopSimulationLoading || !isSelectedAndRunning

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <Icon icon='times' />
      </Button>
    )
  }
}

export default withNamespaces('application')(connector(StopSelected as any) as any) as any
