import styled, { css } from 'styled-components'

export const SpacerGroup = styled.div<{spacer: string}>`${({ spacer }) => css`
  margin-left: ${spacer}px;
`}`

export const RenameInput = styled.input`${() => css`
  width: 50%;
  height: 20px;
  font-size: 14px;
  background-color: transparent;
  color: white;
  border: none;
  padding-left: 5px;

  &:focus {
    outline: 1px solid #BB1B1B;
  }   
`}`

export const Elements = styled.div<any>`${({
  spacer, treeStatus, selected, targetButtonFilterAndJump, targetButtonJump, renameButton,
}) => css`
  background: ${selected ? '#424345' : 'transparent'};
  display: ${treeStatus};
  margin: 3px 0 3px ${spacer || 0}px;
  color: #FFFFFF;
  padding: 3px 0 3px 5px;
  cursor: pointer;
  user-select: none;
  font-size: 0.9em;
  
  .elementName {
    width: ${targetButtonFilterAndJump ? 'calc(100% - 75px)' : targetButtonJump ? 'calc(100% - 50px)' : 'unset'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  &:hover {
    background: #6b6d71;
    .elementName {
      width: calc(100% - ${renameButton ? '75' : '50'}px);
    }
    
    button {
      opacity: 1;
    }
  }

  button {
    padding-left: 6px;
    padding-right: 6px;
  }
`}`

export const ChildrenElements = styled.div``

export const Counter = styled.div`
  display: inline-block;
  width: 35px;
  text-align: center;
  float: right;
  border-left: #424345 solid 2px;
`

export const Spacer = styled.div`
  display: inline-block;
  width: 10px;
  text-align: center;
`

export const TargetButton = styled.button<{active?: boolean}>`${({ active }) => css`
  display: inline-block;
  float: right;
  text-align: center;
  color: ${active ? '#BB1B1B' : '#CCCCCC'};
  border: none;
  background-color: inherit;
  cursor: pointer;
  opacity: ${active ? 1 : 0};
  
  &:focus {
    outline: 0;
  }
`}`

export const RenameButton = styled.button<{active?: boolean}>`${({ active }) => css`
  display: inline-block;
  float: right;
  text-align: center;
  color: ${active ? '#BB1B1B' : '#CCCCCC'};
  border: none;
  background-color: inherit;
  cursor: pointer;
  opacity: ${active ? 1 : 0};
  
  &:focus {
    outline: 0;
  }
`}`
