// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { applyMiddleware, compose, createStore } from 'redux'

import makeRootReducer, { injectReducer } from './reducers'
import createMiddleware from './asyncMiddleware'
import ApiClient from './apiClient'
import NetworkManager from '../network/NetworkManager'
import deepMerge from 'deepmerge'

export default (initialState = {}) => {
  const middleware = [
    createMiddleware(ApiClient),
  ]

  let composeEnhancers = typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

  if (window.meta.DEV) {
    // eslint-disable-next-line no-underscore-dangle
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension
    }
  }

  const hor = (reducers) => {
    return (state = {}, action) => {
      const initialStateHOR = reducers({}, {})

      switch (action.type) {
        case 'UPDATE_WHOLE_STATE':
          return {
            ...state,
            ...action.data,
          }
        case 'ACTION_SWITCH_PROJECT':
          return {
            ...initialStateHOR,
            util: state.util,
            application: {
              ...initialStateHOR.application,
              main: {
                ...initialStateHOR.application.main,
                authenticationData: state.application.main.authenticationData,
                appState: state.application.main.appState,
              },
            },
            visualization: {
              ...initialStateHOR.visualization,
            },
          }
        case 'ACTION_RESET_STORE':
          NetworkManager.logout()

          return {
            ...initialStateHOR,
            util: state.util,
          }
        default:
          return reducers(state, action)
      }
    }
  }

  const reducers = makeRootReducer()

  const store = createStore(
    hor(makeRootReducer()),
    deepMerge(reducers({}, {}), initialState),
    composeEnhancers(applyMiddleware(...middleware)),
  )

  store.asyncReducers = {}

  store.injectReducer = params => {
    injectReducer(store, params)
  }

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const reducers = require('./reducers').default

      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
