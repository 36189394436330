export enum coolingZoneActionsEnum {
  ACTION_ADD_COOLING_ZONE = 'CoolingZone/ACTION_ADD_COOLING_ZONE',
  DELETE = 'CoolingZone/DELETE',
  DELETE_MULTIPLE = 'CoolingZone/DELETE_MULTIPLE',
  REMOVE_CHILD_ID = 'CoolingZone/REMOVE_CHILD_ID',
  UPDATE = 'CoolingZone/UPDATE',
}

export function addCoolingZone (coolingZoneHash: Record<number, CoolingZone & Parent>) {
  return {
    type: coolingZoneActionsEnum.ACTION_ADD_COOLING_ZONE,
    coolingZoneHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const coolingZoneReducers: Record<string, Function | undefined> = {
  [coolingZoneActionsEnum.ACTION_ADD_COOLING_ZONE]:
  (_state: CoolingZoneHash, action: {coolingZoneHash: CoolingZoneHash}) => (
    action.coolingZoneHash
  ),

  [coolingZoneActionsEnum.DELETE]: (state: CoolingZoneHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [coolingZoneActionsEnum.DELETE_MULTIPLE]: (state: CoolingZoneHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [coolingZoneActionsEnum.REMOVE_CHILD_ID]:
  (state: CoolingZoneHash, action: {id: number, child: {type: string, id: number}}) => {
    const newState = { ...state }
    const element: any = newState[action.id]

    element[`#${action.child.type}Ids`] = element[`#${action.child.type}Ids`]
      .filter((childId: number) => childId !== action.child.id)

    return newState
  },

  [coolingZoneActionsEnum.UPDATE]: (state: CoolingZoneHash, action: {elements: CoolingZoneHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (state: CoolingZoneHash = {}, action: any) {
  const handler = coolingZoneReducers[action.type]

  return handler ? handler(state, action) : state
}
