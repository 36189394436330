import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { FormWrapper, CheckListHeader, ListItem } from '../../Dialogs/DialogStyles'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import Delete from 'react/specific/Delete'
import ApiClient from 'store/apiClient'
import { AppState } from 'store/application/main/consts'
import { Network } from 'network/Network'
import * as VisualizationActions from 'store/visualization/actions'
import { InnerText } from '../Styles'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  visualizationMetaInformation: visualization.visualizationMetaInformation,
}), {
  setConfig: VisualizationActions.setConfig,
  savePlotConfig: VisualizationActions.savePlotConfig,
  removePlotConfig: VisualizationActions.removePlotConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  allowDeletion?: boolean,
  fullscreen: boolean,
  t: Translation
}

class MergedTab extends Component<Props> {
  handleRemoveDataSourceFromMergedPlot = (dataSourceId: string) => {
    const { visualizationMetaInformation, editDialogConfigId, removePlotConfig, savePlotConfig } = this.props
    const { config: configId } = (visualizationMetaInformation || {})[AppState.Caster] || {}

    ApiClient.patch(
      `${Network.URI}/visualization_config/remove_source_from_merged_plot/${configId}`,
      {
        data: {
          dataSourceId,
          mergedPlotId: editDialogConfigId,
        },
      },
    ).then(mergedPlot => {
      if (!mergedPlot) {
        removePlotConfig(editDialogConfigId)

        return
      }

      savePlotConfig(mergedPlot)
    })
  }

  @AnalyzeTime(0)
  render () {
    const {
      editDialogConfigId,
      plotConfigs,
      fullscreen,
      t,
      allowDeletion,
    } = this.props

    const plotConfig = plotConfigs[editDialogConfigId]

    if (!plotConfig) {
      return null
    }

    const { configIds } = plotConfig

    return (
      <FormWrapper fullscreen={fullscreen}>
        <CheckListHeader>{t('plotConfig.mergedIds')}</CheckListHeader>
        <div>
          {
            configIds && configIds.map((configId, i) => {
              const config = plotConfigs[configId]

              if (!config) {
                <ListItem key={i} title=''>No info available</ListItem>
              }

              return (
                <ListItem
                  style={
                    {
                      display: 'flex',
                      alignItems: 'flex-end',
                    }
                  }
                  key={configId}
                  title={configId}
                >
                  {
                    allowDeletion ? (
                      <Delete
                        onClick={() => this.handleRemoveDataSourceFromMergedPlot(configId)}
                      />
                    ) : null
                  }
                  <InnerText style={{ position: 'static' }}>{`${config.name} - ${configId}`}</InnerText>
                </ListItem>
              )
            })
          }
        </div>
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(MergedTab as any) as any) as any
