import React from 'react'
import styled, { css } from 'styled-components'
import { AnalyzeTime } from 'Util'

const TabPageView = styled.div<any>`${({ fixedHeight }) => css`
  ${fixedHeight && `height: ${fixedHeight}px`}
`}`

interface Props {
  title: string | any // used in TabView,
  tooltip?: string // used in TabView,
  fixedHeight?: number,
  children?: any
  hide?: any
}

export default class TabPage extends React.Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { children, fixedHeight, hide } = this.props

    if (hide) {
      return null
    }

    return (
      <TabPageView fixedHeight={fixedHeight}>
        {children}
      </TabPageView>
    )
  }
}
