import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import * as ApplicationActions from 'store/application/main/actions'
import * as DataActions from 'store/data/actions'

import BaseDialog from '../../BaseDialog'
import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import { Form, Text } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import Input from 'react/specific/Input'
import Button from 'react/components/Button'
import { Spacer } from 'react/dialogs/project/OpenProjectDialog/Styles'
import Util from 'logicHandlers/ServerLogic/actions/Util'
import Menu from 'TitleBar/react/MenuBuilder/logic'
import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import FeatureFlags from 'react/FeatureFlags'
import StepComponent from './Step'
import ParameterComponent from './Parameter'
import StepWithOptions from './StepWithOptions'
import Logic from './logic'

export const submitButtonColor = '#41a880'
export const submitButtonColorHover = '#3a9773'

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  params: state.application.main.params,
  executionStateDictionary: state.data.executionStateDictionary,
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  closeDialog: ApplicationActions.closeDialog,
  executeExecutableDefinition: DataActions.executeExecutableDefinition,
  setExecutionState: DataActions.setExecutionState,
  setManyExecutionStates: DataActions.setManyExecutionStates,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar,
  t: Translation & ArrayOfTranslations
}

type State = {
  selectedCase: string | null
  selectedStepCaseOptionRecord: Record<number, number>
  error: string
  parameterValues: Record<string, Record<string, any>>
  uuid: string,
  newCaseName: string,
  newCaseDescription: string,
  waitingForExecutions: string[],
  createNewCaseParam: CreateNewCaseDefinition | null,
  initialCreateNewCaseParam: CreateNewCaseDefinition | null,
  filePaths: Record<string, {path: string, action: 'use' | 'store'}>,
  dynamicOptions: Record<string, Option[]>
  stepIsExpanded: Record<number, boolean>
  stepIsValid: Record<number, boolean>
  lastCompletedStepIndex: number
  lastChangedStep: Record<string, number | string | undefined> | {index: number}
  lastUUIDsUsedInExecutables: string[]
}

const PRE_TRANS = 'executableDialog'

export class ExecutableDialog extends Component<Props, State> {
  static NAME = uuid()

  state: State = {
    selectedCase: null,
    selectedStepCaseOptionRecord: {},
    error: '',
    parameterValues: {},
    uuid: uuid(),
    newCaseName: '',
    newCaseDescription: '',
    waitingForExecutions: [],
    // createNewCaseParam: null,
    createNewCaseParam: {
      autoLoad3D: true,
      hidden: false,
      value: true,
      caseNameTitle: '',
      caseDescriptionTitle: '',
    },
    // FIXME: this is temporarily enforced
    // initialCreateNewCaseParam: null,
    initialCreateNewCaseParam: {
      autoLoad3D: true,
      hidden: false,
      value: true,
      caseNameTitle: '',
      caseDescriptionTitle: '',
    },
    filePaths: {},
    dynamicOptions: {},
    stepIsExpanded: {},
    stepIsValid: {},
    lastCompletedStepIndex: -1,
    lastChangedStep: { index: -1 },
    lastUUIDsUsedInExecutables: [],
  }

  async componentDidMount () {
    hotkeys('Escape', this.handleClose)

    const selectedCaseId = this.selectPreviousUsedCase()

    const { lastCompletedStepIndex, selectedStepCaseOptionRecord } = this.handleInitInputsWithPreviousSubmission()

    await this.handleInitDynamicOptions(selectedCaseId, lastCompletedStepIndex, selectedStepCaseOptionRecord)
    this.handleInitStepsExpandedState()
  }

  async componentDidUpdate (prevProps: Props, prevState: State) {
    // the problem could be that another thing is updating, could return if new exec state is equal to the old one
    const { executionStateDictionary } = prevProps
    const { executionStateDictionary: currentExecutionStateDictionary, params } = this.props
    const { selectedCase, parameterValues } = this.state
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id

    values[trueSelectedCase] = values[trueSelectedCase] || {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (prevState.selectedCase !== this.state.selectedCase) {
      const { lastCompletedStepIndex, selectedStepCaseOptionRecord } = this.handleInitInputsWithPreviousSubmission()

      await this.handleInitDynamicOptions(trueSelectedCase, lastCompletedStepIndex, selectedStepCaseOptionRecord)
      this.handleInitStepsExpandedState()
      this.setState({ lastChangedStep: { index: -1 } })
    }

    if (executionStateDictionary.equals(currentExecutionStateDictionary)) {
      return
    }

    const { waitingForExecutions } = this.state

    waitingForExecutions.forEach(key => {
      if (!currentExecutionStateDictionary[key]) {
        return
      }

      if (currentExecutionStateDictionary[key] === 'done') {
        if (currentCase?.steps && key.includes('-step')) {
          const stepIndex = Number(key.split('-step')[1])

          if (stepIndex !== currentCase.steps.length - 1) {
            const waitingForExecutionsStateCopy = this.state.waitingForExecutions.filter(id => id !== key)

            this.setState({
              lastChangedStep: { index: -1 },
              lastCompletedStepIndex: stepIndex,
              waitingForExecutions: waitingForExecutionsStateCopy,
            })

            // fetch next step's dynamic options if it has them
            this.handleLoadNextStepsDynamicOptions(stepIndex + 1)

            return
          }
        }

        this.setState({
          newCaseName: '',
          newCaseDescription: '',
          createNewCaseParam: { ...(this.state.initialCreateNewCaseParam || {}) },
        })
      }
      else if (currentExecutionStateDictionary[key] === 'error') {
        const waitingForExecutionsStateCopy = this.state.waitingForExecutions.filter(id => id !== key)

        this.setState({
          waitingForExecutions: waitingForExecutionsStateCopy,
        })
      }
    })
  }

  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  handleInitDynamicOptions = async (
    caseId: string,
    lastCompletedStepIndex: number | undefined,
    selectedStepCaseOptionRecord: Record<number, number>,
  ) => {
    const { params, currentProject, currentSimulationCase, enqueueSnackbar } = this.props
    const { executionInputs } = currentSimulationCase
    const { cases, name: definitionName } = (params?.definition || {}) as ExecutableDefinition
    const currentCase = cases.find(definitionCase => definitionCase.id === caseId)

    if (!cases) {
      return
    }

    let lastUsedUUIDs

    if (
      currentCase &&
      executionInputs &&
      executionInputs[definitionName] &&
      executionInputs[definitionName][currentCase.name] &&
      executionInputs[definitionName][currentCase.name].lastUsedUUIDs &&
      Array.isArray(executionInputs[definitionName][currentCase.name].lastUsedUUIDs)
    ) {
      lastUsedUUIDs = executionInputs[definitionName][currentCase.name].lastUsedUUIDs
    }

    const caseObject = cases.find(definitionCase => definitionCase.id === caseId)
    const { parameters = [], steps = [] } = caseObject || {}
    const parametersWithOptions = this.getParametersWithDynamicOptions(
      steps,
      parameters,
      lastCompletedStepIndex,
      lastUsedUUIDs,
    )

    if (Object.keys(selectedStepCaseOptionRecord).length) {
      this.getParametersWithDynamicsOptionsFromSelectedCaseOptions(
        steps,
        parametersWithOptions,
        selectedStepCaseOptionRecord,
        lastCompletedStepIndex || 0,
        lastUsedUUIDs,
      )
    }

    if (!parametersWithOptions.length) {
      this.setState({ lastUUIDsUsedInExecutables: lastUsedUUIDs || [] })

      return
    }

    try {
      const { options, errors } = await ApiClient.post(
        `${Network.URI}/executables/dynamic_options`,
        {
          data: {
            parameters: parametersWithOptions.map(parameter => ({ ...parameter, context: caseObject?.context })),
            projectId: currentProject._id,
            caseId: currentSimulationCase._id,
          },
        },
      )

      if (errors && errors.length) {
        enqueueSnackbar(
          'Error while fetching dynamic options, watch logs for details',
          { variant: 'error', autoHideDuration: 3000 },
        )
        // eslint-disable-next-line no-console
        console.log('error paths: ', errors)
      }

      this.setState({ dynamicOptions: options, lastUUIDsUsedInExecutables: lastUsedUUIDs || [] })
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('error fetching dynamic options')
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  handleLoadNextStepsDynamicOptions = (stepIndex: number) => {
    const { selectedCase, lastUUIDsUsedInExecutables } = this.state
    const { params, currentProject, currentSimulationCase, enqueueSnackbar } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition

    if (!cases) {
      return
    }

    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || !currentCase?.steps) {
      return
    }

    const currentStep = currentCase.steps[stepIndex]

    if (!currentStep) {
      return
    }

    const { parameters = [], caseOptions } = currentStep

    let parametersWithOptions: (Parameter | {context?: string})[]

    if (caseOptions) {
      const { chosenCaseOption } = this.getChosenCaseOption(currentStep, stepIndex)

      if (!chosenCaseOption || !chosenCaseOption.parameters) {
        return
      }

      parametersWithOptions = chosenCaseOption.parameters.filter(param => (!param.hidden && param.optionsFile))
    }
    else {
      parametersWithOptions = parameters.filter(param => (!param.hidden && param.optionsFile))
    }

    if (!parametersWithOptions.length) {
      return
    }

    parametersWithOptions = parametersWithOptions.map(parameter => ({ ...parameter, context: currentCase.context }))

    if (stepIndex > 0 && lastUUIDsUsedInExecutables && lastUUIDsUsedInExecutables[stepIndex - 1]) {
      parametersWithOptions = parametersWithOptions
        .map(param => ({ ...param, previousStepUUID: lastUUIDsUsedInExecutables[stepIndex - 1] }))
    }

    ApiClient
      .post(
      `${Network.URI}/executables/dynamic_options`,
      { data: { parameters: parametersWithOptions, projectId: currentProject._id, caseId: currentSimulationCase._id } },
      )
      .then(({ options, errors = [] }) => {
        if (errors.length) {
          enqueueSnackbar(
            'Error while fetching dynamic options, watch logs for details',
            { variant: 'error', autoHideDuration: 3000 },
          )
          // eslint-disable-next-line no-console
          console.log('path: ', errors)

          return
        }

        this.setState({
          dynamicOptions: {
            ...this.state.dynamicOptions,
            ...options,
          },
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('error fetching dynamic options')
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  handleInitStepsExpandedState = () => {
    const { selectedCase } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || !currentCase.steps || !Array.isArray(currentCase.steps)) {
      return
    }

    const stepIsExpanded: Record<number, boolean> = {}
    const stepArrLength = currentCase.steps.length

    for (let i = 0; i < stepArrLength; i++) {
      if (!i) {
        stepIsExpanded[i] = true
        continue
      }

      stepIsExpanded[i] = false
    }

    this.setState({ stepIsExpanded })
  }

  handleInitInputsWithPreviousSubmission = (): {
    lastCompletedStepIndex?: number,
    selectedStepCaseOptionRecord: Record<number, number>,
  } => {
    const { selectedCase, parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params, currentSimulationCase } = this.props
    const { cases, name: definitionName } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id

    values[trueSelectedCase] = values[trueSelectedCase] || {}
    const caseValues = values[trueSelectedCase] || {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)
    const createNewCaseParamFromDefinition = currentCase?.steps
      ? currentCase.steps[currentCase.steps.length - 1].createNewCase
      : currentCase?.createNewCase || null
    const { executionInputs } = currentSimulationCase
    const newSelectedStepCaseOptionRecordState: Record<number, number> = { ...selectedStepCaseOptionRecord }

    if (
      !executionInputs ||
      !currentCase ||
      !executionInputs[definitionName] ||
      !executionInputs[definitionName][currentCase.name]
    ) {
      this.handleUpdateCreateNewCaseKeys()

      return { selectedStepCaseOptionRecord: {} }
    }

    const previousInputs = executionInputs[definitionName][currentCase.name]
    const filePaths: any = {}

    Object.keys({ ...executionInputs.filePaths } || {}).forEach(key => {
      const filePathObject = (executionInputs.filePaths || {})[key] || {}
      const path = filePathObject.path

      if (!path) {
        return
      }

      filePaths[key] = { path, action: 'use' }
    })

    if (currentCase.steps) {
      currentCase.steps.forEach((step, index) => {
        if (!previousInputs[index]) {
          return
        }

        if (step.caseOptions) {
          if (previousInputs[index].chosenCaseOptionIndex !== undefined) {
            newSelectedStepCaseOptionRecordState[index] = previousInputs[index].chosenCaseOptionIndex
          }

          const optionIndex = previousInputs[index].chosenCaseOptionIndex
          const option = step.caseOptions[optionIndex]

          if (!previousInputs[index] || !previousInputs[index][optionIndex]) {
            return
          }

          for (const param of option.parameters) {
            if (previousInputs[index][optionIndex][param.name] !== undefined) {
              caseValues[param.id] = previousInputs[index][optionIndex][param.name]
            }

            if (param.type === 'file' && previousInputs[index][optionIndex][`${param.name}_filePath`]) {
              filePaths[`${param.name}_filePath`] = {
                path: previousInputs[index][optionIndex][`${param.name}_filePath`].path,
                action: 'use',
              }
            }
          }

          return
        }

        step.parameters.forEach(param => {
          if (!previousInputs[index]) {
            return
          }

          if (previousInputs[index][param.name] !== undefined) {
            caseValues[param.id] = previousInputs[index][param.name]
          }
        })
      })
    }
    else if (currentCase.parameters) {
      currentCase.parameters.forEach(param => {
        if (previousInputs[param.name] !== undefined) {
          caseValues[param.id] = previousInputs[param.name]
        }
      })
    }
    else {
      return { selectedStepCaseOptionRecord: {} }
    }

    const previousCreateCaseInputs = currentCase.steps
      ? (previousInputs[currentCase.steps.length - 1] || {}).newCaseData
      : previousInputs.newCaseData

    const createNewCaseParam: CreateNewCaseDefinition = {
      autoLoad3D: true,
      // autoLoad3D: previousCreateCaseInputs?.autoLoad3D !== undefined
      //   ? previousCreateCaseInputs.autoLoad3D
      //   : createNewCaseParamFromDefinition?.autoLoad3D,
      value: true,
      // value: previousCreateCaseInputs?.newCase !== undefined
      //   ? previousCreateCaseInputs.newCase
      //   : createNewCaseParamFromDefinition?.value,
      caseDescriptionTitle: createNewCaseParamFromDefinition?.caseDescriptionTitle || '',
      caseNameTitle: createNewCaseParamFromDefinition?.caseNameTitle || '',
      hidden: false,
      // hidden: createNewCaseParamFromDefinition?.hidden,
    }

    this.setState({
      filePaths,
      parameterValues: values,
      createNewCaseParam,
      initialCreateNewCaseParam: { ...createNewCaseParam },
      newCaseDescription: previousCreateCaseInputs?.newCaseDescription || '',
      newCaseName: previousCreateCaseInputs?.newCaseName || '',
      lastCompletedStepIndex: previousInputs.lastCompletedStepIndex ?? -1,
      selectedStepCaseOptionRecord: newSelectedStepCaseOptionRecordState,
    })

    return {
      lastCompletedStepIndex: previousInputs.lastCompletedStepIndex,
      selectedStepCaseOptionRecord: newSelectedStepCaseOptionRecordState,
    }
  }

  handleUpdateCreateNewCaseKeys = () => {
    const { params } = this.props
    const { selectedCase } = this.state
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const caseObject = cases.find((c) => c.id === trueSelectedCase)
    const createNewCaseParam = caseObject?.createNewCase || null

    // FIXME: this is temporarily enforced
    if (!createNewCaseParam) {
      return
    }

    this.setState({
      createNewCaseParam,
      initialCreateNewCaseParam: createNewCaseParam ? { ...createNewCaseParam } : null,
    })
  }

  handleCreateNewCaseChange = (event: Event & { target: HTMLInputElement }) => {
    const { featureFlags } = this.props

    if (!FeatureFlags.canEditExecutableDialog(featureFlags) || !FeatureFlags.canCreateCase(featureFlags)) {
      return
    }

    const { checked } = event.target

    this.setState({
      createNewCaseParam: {
        ...(this.state.createNewCaseParam || {}),
        // value: checked || false,
        value: true,
      },
    })
  }

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ExecutableDialog.NAME)
  }

  handleSelectCase = (event: Event & { target: HTMLSelectElement }) => {
    this.setState({ selectedCase: event.target.value })
  }

  handleNewCaseInputChange = (event: Event & { target: HTMLInputElement }) => {
    const { name, value, checked } = event.target

    // did this this way because of typescript type warning with [name]: value
    if (name === 'newCaseName') {
      this.setState({ newCaseName: value })
    }
    else if (name === 'newCaseDescription') {
      this.setState({ newCaseDescription: value })
    }
    else if (name === 'loadNewCase') {
      // this.setState({ createNewCaseParam: { ...(this.state.createNewCaseParam || {}), autoLoad3D: checked } })
    }
  }

  handleSubmit = () => {
    const { selectedCase, parameterValues, uuid, filePaths } = this.state

    const { params, executeExecutableDefinition, currentProject, currentSimulationCase, setExecutionState } = this.props
    const { cases, id } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id
    const caseValues = values[trueSelectedCase] || {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase) {
      return
    }

    const executionInputs: {[key: string]: any} = { filePaths: {} }

    Object.keys(caseValues).forEach(paramId => {
      const param = currentCase.parameters.find(param => (param.id === paramId && !param.hidden))

      if (!param || !param.name) {
        return
      }

      if (param.type === 'file') {
        const filePath = `${param.name}_filePath`

        if (filePaths[filePath]) {
          executionInputs.filePaths[filePath] = { path: filePaths[filePath].path, action: 'use' }
        }
      }

      executionInputs[param.name] = caseValues[paramId]
    })

    const parameters = currentCase?.parameters.map(parameter => ({
      ...parameter,
      value: caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value,
    })) || []

    setExecutionState(currentSimulationCase._id, id, trueSelectedCase, 'loading')
    const newCaseData = {
      newCaseDescription: this.state.newCaseDescription || '',
      newCaseName: this.state.newCaseName || '',
      autoLoad3D: this.state.createNewCaseParam?.autoLoad3D || false,
      newCase: this.state.createNewCaseParam?.value || false,
    }

    executionInputs.newCaseData = { ...newCaseData }

    this.setState({
      waitingForExecutions: [
        ...this.state.waitingForExecutions,
        `${currentSimulationCase._id}_${id}_${trueSelectedCase}`,
      ],
    })

    executeExecutableDefinition(
      currentProject._id,
      currentSimulationCase._id,
      id,
      trueSelectedCase,
      parameters,
      uuid,
      newCaseData,
      uuid,
      executionInputs,
    )

    this.reset()
  }

  handleSetSelectedStepCaseOptionRecord = (stepIndex: number, selectedCaseOptionIndex: number) => {
    // if changed then set lastChangedStep with selectedOptionIndex: ${oldSelectedStep}
    const newState: any = this.registerCaseOptionChange(stepIndex, selectedCaseOptionIndex)

    // fetch the new selected option's dynamic options
    this.fetchNewSelectedCaseOptionsDynamicOptions(stepIndex, selectedCaseOptionIndex)

    this.setState({
      selectedStepCaseOptionRecord: {
        ...this.state.selectedStepCaseOptionRecord,
        [stepIndex]: selectedCaseOptionIndex,
      },
      ...newState,
    })
  }

  fillExecutionInputs = (
    currentCase: Case,
    caseValues: Record<string, any>,
    executionInputs: Record<number | string, Record<string, any>>,
    lastStepIndex: number,
  ) => {
    const { selectedStepCaseOptionRecord, filePaths } = this.state

    if (!currentCase.steps) {
      return
    }

    for (let stepIndex = 0; stepIndex <= lastStepIndex; stepIndex++) {
      const currentStep = currentCase.steps[stepIndex]
      const isCaseOption = Boolean(currentStep.caseOptions)
      const chosenCaseOption = selectedStepCaseOptionRecord[stepIndex] || 0

      executionInputs[stepIndex] = {}

      Object.keys(caseValues).forEach(paramId => {
        let param: any

        if (isCaseOption) {
          param = (currentStep.caseOptions as CaseOption[])[chosenCaseOption].parameters
            .find((param: any) => (param.id === paramId && !param.hidden))

          executionInputs[stepIndex].chosenCaseOptionIndex = chosenCaseOption
        }
        else {
          param = currentStep.parameters.find(param => (param.id === paramId && !param.hidden))
        }

        if (param?.ref) {
          const { stepIndex, parameterIndex } = this.getStepAndParameterIndexesFromRef(param.ref)

          if (stepIndex === undefined || parameterIndex === undefined || !currentCase || !currentCase.steps) {
            return
          }

          const referencedStep = currentCase.steps[stepIndex]

          if (!referencedStep) {
            return
          }

          let referencedParameter: Parameter | undefined

          if (referencedStep.caseOptions) {
            const chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
            const chosenOption = referencedStep.caseOptions[chosenOptionIndex]

            if (!chosenOption) {
              return
            }

            referencedParameter = chosenOption.parameters[parameterIndex]
            referencedParameter.chosenOptionIndex = chosenOptionIndex
          }
          else {
            referencedParameter = referencedStep.parameters[parameterIndex]
          }

          param = referencedParameter
        }

        if (!param || !param.name) {
          return
        }

        if (param.type === 'file') {
          const filePath = `${param.name}_filePath`

          if (filePaths[filePath]) {
            if (!executionInputs.filePaths) {
              executionInputs.filePaths = {}
            }

            executionInputs.filePaths[filePath] = filePaths[filePath]
            executionInputs.filePaths[filePath].action = 'use'
          }
        }

        executionInputs[stepIndex][param.name] = caseValues[paramId]
      })
    }
  }

  mapParameterRefToParameter = (
    isCaseOption: boolean,
    currentCase: Case,
    currentStep: Step,
    caseValues: Record<string, any>,
    chosenCaseOption: number,
    executionInputs: Record<string, any>,
  ) => {
    const { enqueueSnackbar } = this.props
    const { filePaths, selectedStepCaseOptionRecord } = this.state
    let parameters = []
    let referenceError = false

    parameters = (
      isCaseOption
        ? (currentStep.caseOptions as CaseOption[])[chosenCaseOption]
        : currentStep
    )
      .parameters.map((parameter: Parameter) => {
        if (parameter.ref) {
          const referencedParameter = this.getParameterByRef(parameter.ref)
          const { stepIndex } = this.getStepAndParameterIndexesFromRef(parameter.ref)

          if (!referencedParameter) {
            enqueueSnackbar(
              'There was an error with a parameter reference, please review the executables.json file',
              { variant: 'error', autoHideDuration: 4000 },
            )
            referenceError = true

            return
          }

          if (
            stepIndex !== undefined &&
            currentCase.steps && currentCase.steps[stepIndex].caseOptions &&
            selectedStepCaseOptionRecord[stepIndex] !== undefined
          ) {
            referencedParameter.chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
          }

          if (referencedParameter.type === 'file') {
            const filePath = `${referencedParameter.name}_filePath`

            if (filePaths[filePath]) {
              executionInputs[filePath] = filePaths[filePath]
              executionInputs[filePath].action = 'use'
            }
          }

          return {
            ...parameter,
            ...referencedParameter,
            value: caseValues[referencedParameter.id] !== undefined
              ? caseValues[referencedParameter.id]
              : referencedParameter.value,
          }
        }

        return {
          ...parameter,
          value: caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value,
        }
      }) || []

    return { parameters, referenceError }
  }

  fetchNewSelectedCaseOptionsDynamicOptions = (stepIndex: number, selectedCaseOptionIndex: number) => {
    const { currentProject, currentSimulationCase, enqueueSnackbar } = this.props

    const { selectedCase, lastUUIDsUsedInExecutables } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || currentCase.steps === undefined) {
      return
    }

    const currentStep = (currentCase?.steps || [])[stepIndex]

    if (!currentStep || !currentStep.caseOptions) {
      return
    }

    const chosenCaseOption = currentStep.caseOptions[selectedCaseOptionIndex]

    let parametersWithOptions = chosenCaseOption?.parameters?.filter(param => (!param.hidden && param.optionsFile))

    if (!parametersWithOptions || !parametersWithOptions.length) {
      return
    }

    parametersWithOptions = parametersWithOptions.map(parameter => ({ ...parameter, context: currentCase.context }))

    if (stepIndex > 0 && lastUUIDsUsedInExecutables && lastUUIDsUsedInExecutables[stepIndex - 1]) {
      parametersWithOptions = parametersWithOptions
        .map(param => ({ ...param, previousStepUUID: lastUUIDsUsedInExecutables[stepIndex - 1] }))
    }

    ApiClient
      .post(
      `${Network.URI}/executables/dynamic_options`,
      { data: { parameters: parametersWithOptions, projectId: currentProject._id, caseId: currentSimulationCase._id } },
      )
      .then(({ options, errors = [] }) => {
        if (errors.length) {
          enqueueSnackbar(
            'Error while fetching dynamic options, watch logs for details',
            { variant: 'error', autoHideDuration: 3000 },
          )
          // eslint-disable-next-line no-console
          console.log('path: ', errors)

          return
        }

        this.setState({
          dynamicOptions: {
            ...this.state.dynamicOptions,
            ...options,
          },
        })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log('error fetching dynamic options')
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  onSubmitStep = (stepIndex: number) => {
    const { selectedCase, parameterValues, uuid, selectedStepCaseOptionRecord } = this.state

    const {
      params,
      currentProject,
      currentSimulationCase,
      setManyExecutionStates,
      executeExecutableDefinition,
    } = this.props

    const { cases, id } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id
    const caseValues = values[trueSelectedCase] || {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || currentCase.steps === undefined) {
      return
    }

    const currentStep = (currentCase?.steps || [])[stepIndex]
    const isCaseOption = Boolean(currentStep.caseOptions)
    const chosenCaseOption = selectedStepCaseOptionRecord[stepIndex] || 0

    if (!currentStep) {
      return
    }

    const isLastStep = currentCase.steps.length - 1 === stepIndex

    const executionInputs: Record<number, Record<string, any>> = {}

    // fill the execution inputs
    this.fillExecutionInputs(currentCase, caseValues, executionInputs, stepIndex)

    // map parameter ref to referenced parameter
    const { parameters, referenceError } = this.mapParameterRefToParameter(
      isCaseOption,
      currentCase,
      currentStep,
      caseValues,
      chosenCaseOption,
      executionInputs,
    )

    if (referenceError) {
      return
    }

    const newExecutionState: any = {}

    newExecutionState[`${currentSimulationCase._id}_${id}_${trueSelectedCase}-step${stepIndex}`] = 'loading'

    for (let i = currentCase.steps?.length || 0; i > stepIndex; i--) {
      newExecutionState[`${currentSimulationCase._id}_${id}_${trueSelectedCase}-step${i}`] = 'delete'
    }

    setManyExecutionStates(newExecutionState)

    const newCaseData = isLastStep
      ? {
        newCaseDescription: this.state.newCaseDescription || '',
        newCaseName: this.state.newCaseName || '',
        autoLoad3D: this.state.createNewCaseParam?.autoLoad3D || false,
        newCase: this.state.createNewCaseParam?.value || false,
      }
      : {
        newCaseDescription: '',
        newCaseName: '',
        autoLoad3D: false,
        newCase: false,
      }

    if (executionInputs[stepIndex]) {
      executionInputs[stepIndex].newCaseData = { ...newCaseData }
    }

    // delete next steps case values
    const newCaseValues = { ...caseValues }

    for (let i = stepIndex + 1; i < currentCase.steps.length; i++) {
      if (currentCase.steps[i].caseOptions) {
        const caseOptions = currentCase.steps[i].caseOptions

        if (!caseOptions) {
          continue
        }

        for (const option of caseOptions) {
          for (const param of option.parameters) {
            delete newCaseValues[param.id]
          }
        }

        continue
      }

      const params = currentCase.steps[i].parameters

      for (const param of params) {
        delete newCaseValues[param.id]
      }
    }

    const newSelectedStepCaseOptionRecord = { ...selectedStepCaseOptionRecord }

    Object.keys(newSelectedStepCaseOptionRecord).forEach(key => {
      const numberedKey = Number(key)

      if (numberedKey > stepIndex) {
        newSelectedStepCaseOptionRecord[numberedKey] = -1
      }
    })

    const newLastUUIDsUsedInExecutables = this.state.lastUUIDsUsedInExecutables.slice(0, stepIndex)

    newLastUUIDsUsedInExecutables.push(uuid)

    this.setState({
      parameterValues: {
        ...parameterValues,
        [trueSelectedCase]: {
          ...newCaseValues,
        },
      },
      waitingForExecutions: [
        ...this.state.waitingForExecutions,
        `${currentSimulationCase._id}_${id}_${trueSelectedCase}-step${stepIndex}`,
      ],
      selectedStepCaseOptionRecord: newSelectedStepCaseOptionRecord,
      lastUUIDsUsedInExecutables: newLastUUIDsUsedInExecutables,
    })

    executeExecutableDefinition(
      currentProject._id,
      currentSimulationCase._id,
      id,
      trueSelectedCase,
      parameters,
      uuid,
      newCaseData,
      newLastUUIDsUsedInExecutables,
      executionInputs,
      stepIndex,
      isCaseOption ? chosenCaseOption : undefined,
    )

    this.reset()
  }

  onInputChange = (event: Event & { target: HTMLInputElement }, stepIndex?: number) => {
    const { selectedCase, parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    const paramId = event.target.name
    const optionIndex = stepIndex !== undefined ? selectedStepCaseOptionRecord[stepIndex] : undefined
    const currentParam = this.getCurrentParam(currentCase, paramId, stepIndex, optionIndex)
    const rawValue = currentParam?.type?.startsWith('bool') ? event.target.checked : event.target.value

    values[trueSelectedCase] = values[trueSelectedCase] || {}

    const oldValue = values[trueSelectedCase][paramId]

    values[trueSelectedCase][paramId] = rawValue

    // if step index is the same as the lastChanged.index save the original values with paramId as key and original
    // value as value
    // if paramId already exists in lastChangedStep as key, if the new value is equal to the original value
    // delete the key, if no keys other than 'index' remain, then set index to -1
    // if stepIndex is different from lastChangedStep.index it must be smaller, because the next steps are disabled
    // then reset the current lastChangedStep with the keys stored in state, then set the new values
    if (stepIndex === undefined) {
      this.setState({ parameterValues: values, lastChangedStep: { index: -1 } })

      return
    }

    this.registerLastChangedStep(trueSelectedCase, values, currentParam, stepIndex, paramId, oldValue, rawValue)
  }

  onFileUpload = async (paramId: string, stepIndex?: number) => {
    const { selectedCase, parameterValues, uuid, filePaths, lastChangedStep } = this.state
    const { params, currentProject, currentSimulationCase } = this.props
    const { id, cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase) {
      return
    }

    const param = this.getParamById(currentCase, paramId)

    if (!param) {
      return
    }

    const { fileExtension, name: paramName } = param

    const result = await Util.openUploadFileDialog(fileExtension || '', '/executables/upload', (formData: any) => {
      formData.append('projectId', currentProject._id)
      formData.append('simulationCaseId', currentSimulationCase._id)
      formData.append('definitionId', id)
      formData.append('caseId', currentCase.id)
      formData.append('stepIndex', stepIndex)
      formData.append('paramId', paramId)
      formData.append('uuid', uuid)
    })

    parameterValues[trueSelectedCase] = parameterValues[trueSelectedCase] || {}
    parameterValues[trueSelectedCase][paramId] = result.fileName

    const newFilePaths: any = {
      ...filePaths,
      [`${paramName}_filePath`]: { path: result.absoluteFilePath, action: 'store' },
    }

    if (stepIndex === undefined) {
      this.setState({ parameterValues, filePaths: newFilePaths, lastChangedStep: { index: -1 } })

      return
    }

    const oldFilePath = (filePaths[`${paramName}_filePath`] || {}).path

    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, [paramId]: oldFilePath }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any)[paramId] === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          [paramId]: oldFilePath,
        }
      }
      else if ((lastChangedStep as any)[paramId] === result.absoluteFilePath) {
        const amountOfChangedInputs = Object.keys(lastChangedStep).length - 1 // -1 because of index

        if (amountOfChangedInputs > 1) {
          newLastChangedStep = { ...lastChangedStep }
          delete newLastChangedStep[paramId]
        }
        else {
          newLastChangedStep = { index: -1 }
        }
      }
      else {
        this.setState({ parameterValues, filePaths: newFilePaths })

        return
      }
    }
    else {
      // stepIndex is smaller
      Object.keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          parameterValues[trueSelectedCase][key] = (lastChangedStep as any)[key]
        })

      newLastChangedStep = { index: stepIndex, [paramId]: oldFilePath }
    }

    this.setState({ parameterValues, filePaths: newFilePaths, lastChangedStep: newLastChangedStep })
  }

  onToggleStep = (index: number) => {
    const { stepIsExpanded: expandedState } = this.state
    const newStepIsExpandedState = { ...expandedState }

    newStepIsExpandedState[index] = !newStepIsExpandedState[index]
    this.setState({ stepIsExpanded: newStepIsExpandedState })
  }

  registerCaseOptionChange = (stepIndex: number, newSelectedCaseOptionIndex: number) => {
    const { selectedCase, parameterValues, selectedStepCaseOptionRecord, lastChangedStep } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id
    const currentSelectedCaseOption = selectedStepCaseOptionRecord[stepIndex]
    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, selectedCaseOptionIndex: currentSelectedCaseOption }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any).selectedCaseOptionIndex === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          selectedCaseOptionIndex: currentSelectedCaseOption,
        }
      }
      else if ((lastChangedStep as any).selectedCaseOptionIndex === newSelectedCaseOptionIndex) {
        const amountOfChangedInputs = Object.keys(lastChangedStep).length - 1 // -1 because of index

        if (amountOfChangedInputs > 1) {
          newLastChangedStep = { ...lastChangedStep }
          delete newLastChangedStep.selectedCaseOptionIndex
        }
        else {
          newLastChangedStep = { index: -1 }
        }
      }
      // the original value should already be stored in lastChangedStep object in state
      else {
        return { parameterValues: values, lastChangedStep: { ...lastChangedStep } }
      }
    }
    else {
      // stepIndex is smaller
      Object.keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          values[trueSelectedCase][key] = (lastChangedStep as any)[key]
        })

      newLastChangedStep = { index: stepIndex, selectedCaseOptionIndex: currentSelectedCaseOption }
    }

    return { lastChangedStep: newLastChangedStep, parameterValues: values }
  }

  getChosenCaseOption = (step: Step, stepIndex: number):
  {
    chosenCaseOption: CaseOption | null,
    selectors: { key: string, value: string, disabled?: boolean | undefined }[]
  } => {
    const { selectedStepCaseOptionRecord } = this.state
    const { caseOptions } = step

    if (!caseOptions) {
      return { chosenCaseOption: null, selectors: [] }
    }

    const chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
    let chosenCaseOption: CaseOption | null = chosenOptionIndex !== undefined ? caseOptions[chosenOptionIndex] : null

    const selectors: {key: string, value: string, disabled?: boolean}[] = caseOptions
      .filter(option => !this.getIsOptionDisabled(option))
      .map(option => ({ key: option.name, value: option.name }))

    selectors.unshift({ key: 'default', value: 'Please select an option', disabled: true })

    if (chosenCaseOption && !selectors.map(option => option.key).includes(chosenCaseOption.name)) {
      chosenCaseOption = null
    }

    return { chosenCaseOption, selectors }
  }

  getIsOptionDisabled = ({ name, dependsOn }: CaseOption) => {
    if (!dependsOn) {
      return false
    }

    const { selectedStepCaseOptionRecord } = this.state

    try {
      const [ stepIndex, optionIndex ] = dependsOn.split('.').map((str, index) => {
        if (index === 0) {
          return Number(str.split('step')[1])
        }

        return Number(str.split('option')[1])
      })

      if (isNaN(stepIndex) || isNaN(optionIndex)) {
        // eslint-disable-next-line no-console
        console.log(`error with option dependency in option: ${name}`)
        // eslint-disable-next-line no-console
        console.log(`error parsing - make sure to use the example syntax: 'step1.option2'`)

        return true
      }

      return (selectedStepCaseOptionRecord[stepIndex - 1] !== optionIndex - 1)
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      // eslint-disable-next-line no-console
      console.log(`error with option dependency in option: ${name}`)
    }
  }

  registerLastChangedStep = (
    trueSelectedCase: string,
    values: Record<string, Record<string, any>>,
    currentParam: Parameter | undefined,
    stepIndex: number,
    paramId: string,
    oldValue:any,
    rawValue: boolean | string,
  ) => {
    const { lastChangedStep } = this.state
    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, [paramId]: oldValue ?? currentParam?.value }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any)[paramId] === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          [paramId]: oldValue ?? currentParam?.value,
        }
      }
      else if ((lastChangedStep as any)[paramId] === rawValue) {
        const amountOfChangedInputs = Object.keys(lastChangedStep).length - 1 // -1 because of index

        if (amountOfChangedInputs > 1) {
          newLastChangedStep = { ...lastChangedStep }
          delete newLastChangedStep[paramId]
        }
        else {
          newLastChangedStep = { index: -1 }
        }
      }
      // the original value should already be stored in lastChangedStep object in state
      else {
        this.setState({ parameterValues: values })

        return
      }
    }
    else {
      // stepIndex is smaller
      Object.keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          values[trueSelectedCase][key] = (lastChangedStep as any)[key]
        })

      newLastChangedStep = { index: stepIndex, [paramId]: oldValue ?? currentParam?.value }
    }

    // set new state
    this.setState({ lastChangedStep: newLastChangedStep, parameterValues: values })
  }

  getCurrentParam = (currentCase: Case | undefined, paramId: string, stepIndex?: number, optionIndex?: number) => {
    if (!currentCase) {
      return undefined
    }

    if (stepIndex === undefined) {
      return currentCase?.parameters?.find(param => param.id === paramId)
    }

    if (!currentCase.steps) {
      return currentCase?.parameters.find(param => param.id === paramId)
    }

    const currentStep = currentCase.steps[stepIndex]

    if (!currentStep) {
      return undefined
    }

    if (!currentStep.caseOptions) {
      return currentStep.parameters.find(param => param.id === paramId)
    }

    if (
      optionIndex === undefined ||
      !currentStep.caseOptions[optionIndex] ||
      !currentStep.caseOptions[optionIndex].parameters
    ) {
      return undefined
    }

    return currentStep.caseOptions[optionIndex].parameters.find(param => param.id === paramId)
  }

  getParamById = ({ parameters, steps }: Case, paramId: string) => {
    if (steps) {
      const auxArray: Parameter[] = []

      steps.forEach(step => {
        if (step.caseOptions) {
          for (const option of step.caseOptions) {
            if (!option.parameters) {
              continue
            }

            auxArray.push(...option.parameters)
          }

          return
        }

        auxArray.push(...step.parameters)
      })

      return auxArray.find(param => param.id === paramId)
    }

    return parameters?.find(param => param.id === paramId)
  }

  getParametersWithDynamicsOptionsFromSelectedCaseOptions = (
    steps: Step[],
    parametersWithOptions: (Parameter | { stepIndex?: number })[],
    selectedStepCaseOptionRecord: Record<number, number>,
    lastCompletedStepIndex: number,
    lastUsedUUIDs?: string[],
  ) => {
    Object.keys(selectedStepCaseOptionRecord)
      .filter(stepIndex => Number(stepIndex) <= lastCompletedStepIndex)
      .forEach(stepIndex => {
        const numberedStep = Number(stepIndex)
        const numberedStepIndex = numberedStep
        const step = steps[numberedStepIndex]

        if (!step || !step.caseOptions) {
          return
        }

        const option = step.caseOptions[selectedStepCaseOptionRecord[numberedStepIndex]]

        if (!option.parameters) {
          return
        }

        let paramsWithOptionsFile: any = option.parameters
          .filter(param => param.optionsFile)
          .map(param => ({ ...param, stepIndex }))

        if (lastUsedUUIDs && numberedStep > 0) {
          paramsWithOptionsFile = paramsWithOptionsFile
            .map((option: any) => ({ ...option, previousStepUUID: lastUsedUUIDs[numberedStep - 1] }))
        }

        parametersWithOptions.push(...paramsWithOptionsFile)
      })
  }

  getParametersWithDynamicOptions = (
    steps: Step[],
    parameters: Parameter[],
    lastCompletedStepIndex?: number,
    lastUsedUUIDs?: string[],
  ) => {
    const parametersWithOptions: Parameter | {stepIndex?: number}[] = []

    if (steps.length) {
      const stepsToLoad = lastCompletedStepIndex !== undefined
        ? lastCompletedStepIndex === steps.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1
        : 0

      for (let i = 0; i <= stepsToLoad; i++) {
        const step = steps[i]

        if (!step || step.caseOptions) {
          continue
        }

        if (!step.parameters || !Array.isArray(step.parameters)) {
          // fallback in case executables is not defined properly
          continue
        }

        for (let j = 0; j < step.parameters.length; j++) {
          const param = step.parameters[j]

          if (!param.hidden && param.optionsFile) {
            const parameterObject: any = { ...param, stepIndex: i }

            if (lastUsedUUIDs && i > 0) {
              parameterObject.previousStepUUID = lastUsedUUIDs[i - 1]
            }

            parametersWithOptions.push(parameterObject)
          }
        }
      }

      return parametersWithOptions
    }

    return parameters.filter(param => (!param.hidden && param.optionsFile))
  }

  getStepAndParameterIndexesFromRef = (ref: string) => {
    const [ stepPart, parameterPart ] = ref.split('.')
    const stepIndex = Number(stepPart.split('step')[1])
    const parameterIndex = Number(parameterPart.split('parameter')[1])

    if (isNaN(stepIndex) || isNaN(parameterIndex)) {
      return {}
    }

    return { stepIndex: stepIndex - 1, parameterIndex: parameterIndex - 1 }
  }

  getParameterByRef = (parameterRef: string) => {
    const { selectedCase, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    const { stepIndex, parameterIndex } = this.getStepAndParameterIndexesFromRef(parameterRef)

    if (stepIndex === undefined || parameterIndex === undefined || !currentCase || !currentCase.steps) {
      return null
    }

    const currentStep = currentCase.steps[stepIndex]

    if (currentStep.caseOptions) {
      const selectedOptionIndex = selectedStepCaseOptionRecord[stepIndex]
      const selectedOptions = currentStep.caseOptions[selectedOptionIndex]

      if (
        !selectedOptions ||
        !selectedOptions.parameters ||
        !selectedOptions.parameters[parameterIndex] ||
        selectedOptions.parameters[parameterIndex].ref
      ) {
        // eslint-disable-next-line no-console
        console.log(`error getting parameter by ref with ref: ${parameterRef}`)

        if (selectedOptions.parameters[parameterIndex].ref) {
          // eslint-disable-next-line no-console
          console.log('please use refs only to point to the original parameter, not another ref')
        }

        return null
      }

      return selectedOptions.parameters[parameterIndex] || null
    }

    if (!currentStep.parameters) {
      return null
    }

    return currentStep.parameters[parameterIndex]
  }

  selectPreviousUsedCase = () => {
    const { params, currentSimulationCase } = this.props
    const { executionInputs } = currentSimulationCase

    if (!executionInputs) {
      return
    }

    const lastDefinitionObject = executionInputs[params.definition.name]

    if (!lastDefinitionObject) {
      return
    }

    const lastCaseUsedName = lastDefinitionObject.lastUsed || Object.keys(lastDefinitionObject)[0]
    const cases = (params?.definition || {}).cases || []
    const selectedCase = cases.find((definitionCase: any) => definitionCase.name === lastCaseUsedName)

    if (!selectedCase) {
      return
    }

    this.setState({ selectedCase: selectedCase.id })

    return selectedCase.id
  }

  reset = () => {
    this.setState({
      uuid: uuid(),
    })
  }

  isValid = (stepIndex?: number) => {
    const { selectedCase, parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition || {}) as ExecutableDefinition
    const values = parameterValues || {}
    const trueSelectedCase = selectedCase || cases[0].id
    const caseValues = values[trueSelectedCase] || {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)
    const currentStep = stepIndex === undefined ? null : (currentCase?.steps || [])[stepIndex]

    if (!currentStep && stepIndex !== undefined) {
      return false
    }

    const isCaseOption = Boolean(currentStep?.caseOptions)
    const chosenCaseOptionIndex = selectedStepCaseOptionRecord[stepIndex || 0]

    if (isCaseOption && (chosenCaseOptionIndex === undefined || chosenCaseOptionIndex === -1)) {
      return false
    }

    const parameters = currentStep
      ? isCaseOption
        ? (currentStep.caseOptions as CaseOption[])[chosenCaseOptionIndex].parameters.filter(p => !p.ref)
        : currentStep.parameters.filter(p => !p.ref)
      : currentCase?.parameters
    let valid = true

    for (const param of parameters || []) {
      const value = caseValues[param.id] !== undefined ? caseValues[param.id] : param.value

      let valueValid = param.hidden || value !== undefined

      if (valueValid && !param.hidden) {
        if (param.options || param.optionsFile) {
          valueValid = value !== 'default'
        }
        else {
          switch (param.type) {
            case 'string':
              valueValid = typeof value === 'string' && value.length > 0
              break
            case 'number':
              valueValid = Logic.numberValid(value, param) // TODO: validate step
              break
            case 'bool':
            case 'boolean':
              valueValid = typeof value === 'boolean'
              break
            case 'file':
              valueValid = typeof value === 'string' && value.length > 0
              break
            default:
              valueValid = false
          }
        }
      }

      if (valid) {
        valid = valueValid
      }
    }

    return valid
  }

  getParamSelectors = (parameter: Parameter) => {
    if (parameter.optionsFile) {
      const a = ((this.state.dynamicOptions || {})[parameter.id] || []).map(({ name, name2, value }) => {
        const fullName = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName }
      })

      return a
    }
    else if (parameter.options) {
      return parameter.options.map(({ name, name2, value }) => {
        const fullName = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName }
      })
    }

    return []
  }

  getInputType = (parameter: Parameter) => {
    if (parameter.options || parameter.optionsFile) {
      return 'select'
    }

    switch (parameter.type) {
      case 'string':
        return 'text'
      case 'number':
        return 'number'
      case 'bool':
      case 'boolean':
        return 'checkbox'
      // file upload is handled by the upload button
      default:
        return 'text'
    }
  }

  getUploadButtonContent = (caseValues: Record<string, any>, param: Parameter) => {
    const { t } = this.props
    let fullContent = `${t(`${PRE_TRANS}.button.upload`)}`

    if (caseValues[param.id]) {
      fullContent += ` (${caseValues[param.id]})`
    }

    if (fullContent.length > 45) {
      fullContent = `${fullContent.substring(0, 42)}...`
    }

    return fullContent
  }

  shouldShowNewCaseInputs = () => {
    const { hidden = false, value = false } = this.state.createNewCaseParam || {}

    return (!hidden && value)
  }

  getNewCaseInputTitles = (showNewCaseInputs: boolean) => {
    if (!showNewCaseInputs) {
      return { caseDescriptionTitle: 'New Case Description', caseNameTitle: 'New Case Name' }
    }

    const {
      caseNameTitle = 'New Case Name',
      caseDescriptionTitle = 'New Case Description',
    } = this.state.createNewCaseParam || {}

    return { caseNameTitle, caseDescriptionTitle }
  }

  isSomeStepLoading = (fullCaseId: string) => {
    const { executionStateDictionary } = this.props
    const filteredKeys = Object.keys(executionStateDictionary).filter(key => key.includes(fullCaseId))

    return filteredKeys.some(key => executionStateDictionary[key] === 'loading')
  }

  isStepLoading = (fullCaseId: string, index: number) => {
    const { executionStateDictionary } = this.props
    const filteredKeys = Object.keys(executionStateDictionary).filter(key => key.includes(fullCaseId))
    const idWithStep = `${fullCaseId}-step${index}`

    return (filteredKeys.includes(idWithStep) && executionStateDictionary[idWithStep] === 'loading')
  }

  isStepDisabled = (stepIndex: number) => {
    // all previous steps must have been completed
    const { selectedCase, lastCompletedStepIndex, lastChangedStep } = this.state
    const { currentSimulationCase, params } = this.props
    const { id, cases } = (params?.definition || {}) as ExecutableDefinition
    const trueSelectedCase = selectedCase || cases[0].id
    const stateKey = `${currentSimulationCase._id}_${id}_${trueSelectedCase}`

    if (stepIndex === 0) {
      return this.isSomeStepLoading(stateKey)
    }

    const lastChangedStepIndex = lastChangedStep.index as number

    return (lastCompletedStepIndex < stepIndex - 1) ||
    (lastChangedStepIndex !== undefined && lastChangedStepIndex < stepIndex && lastChangedStep.index !== -1)
  }

  render () {
    const { selectedCase, parameterValues, error, createNewCaseParam, dynamicOptions } = this.state
    const { t, params, executionStateDictionary, currentSimulationCase, rootData, featureFlags } = this.props
    const { id, name, cases, selectCaseLabel } = (params?.definition || {}) as ExecutableDefinition

    if (!cases) {
      return null
    }

    const trueSelectedCase = selectedCase || cases[0].id
    const values = parameterValues || {}
    const caseValues = values[trueSelectedCase] || {}
    const caseObject = cases.find(definitionCase => definitionCase.id === trueSelectedCase)
    const { steps = [], parameters = [], createNewCase = {} } = caseObject || {}
    const { hidden: createNewCaseHidden } = createNewCase
    const autoLoad = createNewCaseParam?.autoLoad3D
    const showNewCaseInputs = this.shouldShowNewCaseInputs()
    const { caseNameTitle, caseDescriptionTitle } = this.getNewCaseInputTitles(showNewCaseInputs)
    const activeKeyValid = Menu.evaluateActiveKey(caseObject?.activeKey || '', rootData.Caster)
    const activeKeyMessage = caseObject?.activeKeyMessage ||
    `Case is not executable: condition ${caseObject?.activeKey} not met`

    const caseSelectors = cases.map(definitionCase => ({
      key: definitionCase.id,
      value: definitionCase.name,
      disabled: !Menu.evaluateActiveKey(definitionCase?.activeKey || '', rootData.Caster),
    }))

    const stateKey = `${currentSimulationCase._id}_${id}_${trueSelectedCase}`
    const loading = executionStateDictionary[stateKey] === 'loading'

    // TODO: translations

    // TODO: min/max/step validation (set on input)

    return (
      <BaseDialog
        title={name}
        icon='pe-7s-settings'
        header={name}
        onClose={this.handleClose}
        hasScroll
        small
        headerWidth='390px'
      >
        <Form>
          {
            !activeKeyValid && rootData.Caster && (
              <Text style={{ color: 'yellow' }}>{activeKeyMessage}</Text>
            )
          }
          {
            cases?.length > 1 && (
              <Input
                name='selectCase'
                type='select'
                label={selectCaseLabel || t(`${PRE_TRANS}.input.selectCase.label`)}
                title={t(`${PRE_TRANS}.input.selectCase.title`)}
                value={selectedCase || cases[0].id}
                selectors={caseSelectors}
                onChange={this.handleSelectCase}
                disabled={loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
              />
            )
          }
          {
            steps.length
              ? (
                steps.map((step, index) => (
                  step.caseOptions ? (
                    <StepWithOptions
                      handleRunStep={this.onSubmitStep}
                      valid={this.isValid(index)}
                      disabled={this.isStepDisabled(index) || this.isSomeStepLoading(stateKey)}
                      handleExpandStep={this.onToggleStep}
                      step={step}
                      stepIndex={index}
                      key={index}
                      caseValues={caseValues}
                      dynamicOptions={dynamicOptions}
                      featureFlags={featureFlags || {}}
                      loading={this.isStepLoading(stateKey, index)}
                      t={t}
                      handleInputChange={this.onInputChange}
                      handleUploadFile={this.onFileUpload}
                      expanded={this.state.stepIsExpanded[index]}
                      lastStep={index === steps.length - 1}
                      getParameterByRef={this.getParameterByRef}
                      onCaseOptionChange={this.handleSetSelectedStepCaseOptionRecord}
                      getChosenCaseOption={this.getChosenCaseOption}
                    />
                  )
                    : (
                      <StepComponent
                        handleRunStep={this.onSubmitStep}
                        valid={this.isValid(index)}
                        disabled={this.isStepDisabled(index) || this.isSomeStepLoading(stateKey)}
                        handleExpandStep={this.onToggleStep}
                        step={step}
                        stepIndex={index}
                        key={index}
                        caseValues={caseValues}
                        dynamicOptions={dynamicOptions}
                        featureFlags={featureFlags || {}}
                        loading={this.isStepLoading(stateKey, index)}
                        t={t}
                        handleInputChange={this.onInputChange}
                        handleUploadFile={this.onFileUpload}
                        expanded={this.state.stepIsExpanded[index]}
                        lastStep={index === steps.length - 1}
                        getParameterByRef={this.getParameterByRef}
                      />
                    )

                ))
              )
              : parameters?.filter(param => !param.hidden).map((param, index) => (
                <ParameterComponent
                  caseValues={caseValues}
                  dynamicOptions={dynamicOptions}
                  featureFlags={featureFlags || {}}
                  loading={loading}
                  parameter={param}
                  t={t}
                  handleInputChange={this.onInputChange}
                  handleUploadFile={this.onFileUpload}
                  key={index}
                />
              ))
          }
          {/* FIXME: this is temporarily enforced */}
          {
            !createNewCaseHidden && (
              <Input
                key='createNewCase'
                name='Create New Case'
                type='checkbox'
                label='Create New Case'
                title='Create New Case'
                value={FeatureFlags.canCreateCase(featureFlags) ? (createNewCaseParam?.value || false) : false}
                onChange={this.handleCreateNewCaseChange}
                disabled={
                  true ||
                  loading ||
                  !FeatureFlags.canEditExecutableDialog(featureFlags) ||
                  !FeatureFlags.canCreateCase(featureFlags)
                }
              />
            )
          }
          {
            showNewCaseInputs && (
              <div>
                {/* FIXME: this is temporarily enforced */}
                <Input
                  key='loadNewCase'
                  name='loadNewCase'
                  type='checkbox'
                  label='Load New Case'
                  title='Load New Case'
                  value={autoLoad}
                  onChange={this.handleNewCaseInputChange}
                  disabled={true || loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                />
                <Input
                  key='newCaseName'
                  name='newCaseName'
                  type='text'
                  label={caseNameTitle || 'New Case Name'}
                  title={caseNameTitle || 'New Case Name'}
                  value={this.state.newCaseName}
                  onChange={this.handleNewCaseInputChange}
                  disabled={loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                />
                <Input
                  key='newCaseDescription'
                  name='newCaseDescription'
                  type='text'
                  label={caseDescriptionTitle || 'New Case Description'}
                  title={caseDescriptionTitle || 'New Case Description'}
                  value={this.state.newCaseDescription}
                  onChange={this.handleNewCaseInputChange}
                  disabled={loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                />
              </div>
            )
          }
          <Spacer h={15} br />
          <Button
            overrideHoverColor={submitButtonColorHover}
            overrideColor={submitButtonColor}
            title={t(`${PRE_TRANS}.button.run`)}
            type='primary'
            disabled={
              (steps.length ? this.isStepLoading(stateKey, steps.length - 1) : loading) ||
              !(
                steps.length
                  ? (this.isValid(steps.length - 1) && !this.isStepDisabled(steps.length - 1))
                  : this.isValid()
              ) ||
              !activeKeyValid ||
              (showNewCaseInputs && (!this.state.newCaseDescription.length || !this.state.newCaseName.length))
            }
            onClick={steps.length ? () => this.onSubmitStep(steps.length - 1) : this.handleSubmit}
            error={error}
            loading={steps.length ? this.isStepLoading(stateKey, steps.length - 1) : loading}
          >
            {t(`${PRE_TRANS}.button.run`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(ExecutableDialog as any) as any) as any) as any
