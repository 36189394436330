// TODO: fix types
export enum SensorPointActionsEnum {
  ACTION_ADD_SENSOR_POINT = 'SensorPoint/ACTION_ADD_SENSOR_POINT',
  DELETE = 'SensorPoint/DELETE',
  DELETE_MULTIPLE = 'SensorPoint/DELETE_MULTIPLE',
  UPDATE = 'SensorPoint/UPDATE',
}

export function addSensorPoint (sensorPointHash: Record<number, SensorPoint & Parent>) {
  return {
    type: SensorPointActionsEnum.ACTION_ADD_SENSOR_POINT,
    sensorPointHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SensorPointReducers: Record<string, Function | undefined> = {
  [SensorPointActionsEnum.ACTION_ADD_SENSOR_POINT]:
  (_state: any, action: {sensorPointHash: any}) => (
    action.sensorPointHash
  ),

  [SensorPointActionsEnum.DELETE]: (state: any, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SensorPointActionsEnum.DELETE_MULTIPLE]: (state: any, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SensorPointActionsEnum.UPDATE]: (state: any, action: {elements: any}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (
  state: any = {},
  action: any,
) {
  const handler = SensorPointReducers[action.type]

  return handler ? handler(state, action) : state
}
