import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import * as VisualizationActions from 'store/visualization/actions'

import { Bottom, Header, Title, I, TabsWrapper, Tab } from './Dialogs/DialogStyles'

import PlotConfig from './PlotConfigurator/PlotConfig'
import NetworkManager from 'network/NetworkManager'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import { ApiService } from 'API'

const connector = connect(({ visualization, application }: DefaultState) => ({
  appState: application.main.appState,
  ...visualization, // TODO: connect the single values don't just use spread!
}), {
  showConfigDialog: VisualizationActions.showConfigDialog,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  setEditIdToNull: () => void,
  fullscreen: boolean,
  currentTileId?: string,
  editDialogConfigId: string,
  plotConfigs: any,
  tileConfigs: any,
  visualization: any,
  viewsObject: any,
  visualizationMetaInformation: any,
  allowDeletion?: boolean
  t: Translation,
}

type State = {
  activeTab: number
};

class ConfigDialogContent extends Component<Props, State> {
  state: State = {
    activeTab: 0,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleCloseConfigDialog)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleCloseConfigDialog)
  }

  // @AnalyzeTime(0)
  handleCloseConfigDialog = () => {
    const { showConfigDialog } = this.props

    showConfigDialog()

    const {
      appState,
      visualizationMetaInformation,
      setConfig,
      plotConfigs,
      tileConfigs,
      viewsObject,
    } = this.props

    const data = { viewsObject, plotConfigs, tileConfigs }

    ApiService.setVisConfig(((visualizationMetaInformation || {})[appState] || {}).config, data).then(() => {
      setConfig(data)
    })
  };

  // @AnalyzeTime(0)
  handleTabClick = (event: any) => {
    this.setState({
      activeTab: Number(event.target.id),
    })
  };

  @AnalyzeTime(0)
  render () {
    const {
      editDialogConfigId,
      fullscreen,
      plotConfigs,
      tileConfigs,
      currentTileId,
      t,
      allowDeletion,
    } = this.props
    const { activeTab } = this.state
    const { configIds, name } = plotConfigs[editDialogConfigId] || {}
    const currentPlot = (currentTileId ? (tileConfigs || {})[currentTileId] : plotConfigs[editDialogConfigId]) || {}
    const { type } = currentPlot

    if (!type) {
      return null
    }

    let tabs = type !== 'text' || fullscreen ? [
      { title: 'extended', icon: 'pe-7s-edit' },
    ] : []

    if (NetworkManager.isDataSourceServer) {
      tabs.unshift({ title: 'data', icon: 'pe-7s-graph1' })
    }

    if (currentTileId && !fullscreen) {
      tabs.unshift({ title: 'general', icon: 'pe-7s-graph2' })

      if (type === 'line' || type === 'bar' || type === 'area') {
        tabs.push({ title: 'vertical', icon: 'pe-7s-tools' })
      }

      if (
        type !== 'text' &&
        type !== 'contour' &&
        type !== 'gage'
      ) {
        tabs.push({ title: 'show', icon: 'pe-7s-drop' })
      }

      if (type === 'text' || type === 'gage') {
        tabs.push({ title: 'edit', icon: 'pe-7s-display2' })
      }

      if (/^(line|area)$/.test(type) && configIds) {
        tabs.push({ title: 'additional', icon: 'pe-7s-display1' })
      }
    }

    if (/merged/.test(editDialogConfigId)) {
      tabs.push({ title: 'merged', icon: 'pe-7s-menu' })
    }

    if (type === 'edit_box') {
      tabs = [ { title: 'editable_files', icon: 'pe-7s-note2' } ]
    }

    if (type === 'command') {
      tabs = [ { title: 'edit_command', icon: 'pe-7s-note2' } ]
    }

    let displayName = name

    if (currentTileId) {
      displayName = ((tileConfigs || {})[currentTileId] || {}).name || name
    }

    if (!plotConfigs[currentPlot.configId]) {
      tabs = tabs.filter((tab) => tab.title === 'general')
    }

    return (
      <div>
        {
          !fullscreen &&
            <Header title={t('configDialogContent.title', { name: displayName })}>
              <Title>{t('configDialogContent.label', { name: displayName })}</Title>
              <I
                title={t('configDialogContent.close')}
                className='pe-7s-close'
                onClick={this.handleCloseConfigDialog}
              />
            </Header>
        }
        <PlotConfig
          activeTab={activeTab}
          fullscreen={fullscreen}
          tabs={tabs}
          allowDeletion={allowDeletion}
          setEditIdToNull={this.props.setEditIdToNull}
        />
        <Bottom>
          <TabsWrapper>
            {
              tabs.map((tab, i) =>
                <Tab
                  key={i}
                  title={t(`configDialogContent.tabs.${tab.title}`)}
                  activeTab={activeTab === i}
                  onClick={this.handleTabClick}
                  id={i.toString()}
                >
                  <i className={tab.icon} />
                </Tab>)
            }
          </TabsWrapper>
        </Bottom>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(ConfigDialogContent as any) as any) as any
