import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import { Form, Button } from '../../visualization/dashboard/Dialogs/DialogStyles'

import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import * as LoadingActions from 'store/LoadingStore'
import DataActions from 'store/data/actions'

import BaseDialog from '../BaseDialog'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import { CasterColumnLogic } from '../project/ProjectDataDialog/CasterColumnLogic'
import { OptionsObject, withSnackbar } from 'notistack'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  closeDialog: ApplicationActions.closeDialog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  openDialog: ApplicationActions.openDialog,
  resetReducer: DataActions.resetReducer,
  setAppState: ApplicationActions.setAppState,
  setError: ErrorActions.setError,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  t (key: string, params?: Record<string, unknown>): string
}

export class CasterHandleDataDialog extends Component<Props> {
  static NAME: string = uuid()

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleClose as any)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CasterHandleDataDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleButtonPress = (action: 'replaceCaster' | 'replaceNozzles' | 'mergeNozzles') => {
    const { closeDialog } = this.props

    CasterColumnLogic.handleUploadCasterData(action, this.props as any, true)
    closeDialog(CasterHandleDataDialog.NAME)
  };

  handleButtonPressMergeNozzles = this.handleButtonPress.bind(this, 'mergeNozzles');

  handleButtonPressReplaceNozzles = this.handleButtonPress.bind(this, 'replaceNozzles');

  handleButtonPressReplaceCaster = this.handleButtonPress.bind(this, 'replaceCaster');

  @AnalyzeTime(0)
  render () {
    const { t } = this.props

    return (
      <BaseDialog
        title={t('casterHandleDataDialog.title')}
        icon='pe-7s-folder'
        header={t('casterHandleDataDialog.header')}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Button onClick={this.handleButtonPressMergeNozzles}>
            <div><i className='pe-7s-shuffle' /></div>
            <span>{t('casterHandleDataDialog.mergeNozzles')}</span>
          </Button>
          <Button onClick={this.handleButtonPressReplaceNozzles}>
            <div><i className='pe-7s-refresh-2' /></div>
            <span>{t('casterHandleDataDialog.replaceNozzles')}</span>
          </Button>
          <Button onClick={this.handleButtonPressReplaceCaster}>
            <div><i className='pe-7s-loop' /></div>
            <span>{t('casterHandleDataDialog.replaceCaster')}</span>
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

// eslint-disable-next-line max-len
export default withNamespaces('application')(withSnackbar(connector(CasterHandleDataDialog as any) as any) as any) as any
