import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css, ThemeProvider } from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core'
import { OptionsObject, withSnackbar } from 'notistack'
import { getCurrentDashboardEntry, initHotkeys, isConsistencyCheckURL } from './util'
import Util from '../logic/Util'

import AllActions from '../store/actions'
import DataActions from '../store/data/actions'
import * as ErrorActions from '../store/application/error/actions'
import * as DataActions2 from '../store/data/actions'
import * as ApplicationActions from '../store/application/main/actions'
import * as UtilActions from '../store/util/actions'
import * as FilterActions from '../store/filter/actions'
import * as VisualizationActions from '../store/visualization/actions'
import * as ComparisonCastersActions from 'store/comparison-casters'
import * as LoadingActions from 'store/LoadingStore'

import CasterTree from '../react/CasterTree'
import Caster from '../react/Caster'
import CasterDialog from '../react/CasterDialog'
import CasterFilter from '../react/CasterFilter'
import Icon from '../react/specific/Icon'
import ThreeManager from '../three/ThreeManager'
import LoadingScreen from '../react/specific/LoadingScreen'
import DeleteDialog from '../react/specific/DeleteDialog'
import Dashboard from '../react/visualization/dashboard/Dashboard/index'
import PlotConfigurator from '../react/visualization/dashboard/PlotConfigurator' // TODO: this does not belong here!
import CommandMapping from '../react/visualization/dashboard/CommandMapping'
import DialogManager from '../react/dialogs/DialogManager'

import StyleConfig from '../react/visualization/dashboard/config/StyleConfig'
import NetworkManager from '../network/NetworkManager'
import IpcManager from '../IpcManager'
import FeatureFlags from '../react/FeatureFlags'
import { AppState } from '../store/application/main/consts'
import Handlers from './handlers'
import { DefaultState, ElementsHashes } from 'types/state'
import FilterHandler from 'three/logic/FilterHandler'
import { addAllElements, deleteElement as deleteElementTest } from 'store/elements/actions'

const connector = connect((state: DefaultState) => ({
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  appState: state.application.main.appState,
  authenticationData: state.application.main.authenticationData,
  catalog: state.data.catalog,
  comparisonCasters: state.ComparisonCasters,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  currentCasterRoot: state.application.main.currentCasterRoot,
  currentDashboard: state.visualization.currentDashboard,
  currentDashboardWidth: state.visualization.currentDashboardWidth,
  currentProject: state.application.main.currentProject,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  currentSimulationCase: state.application.main.currentSimulationCase,
  darkTheme: state.application.main.darkTheme,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  executableDefinitions: state.data.executableDefinitions,
  AirLoop: state.AirLoop,
  CoolingLoop: state.CoolingLoop,
  CoolingZone: state.CoolingZone,
  LoopAssignment: state.LoopAssignment,
  Nozzle: state.Nozzle,
  Roller: state.Roller,
  RollerBearing: state.RollerBearing,
  RollerBody: state.RollerBody,
  SupportPoint: state.SupportPoint,
  Segment: state.Segment,
  SegmentGroupSupportPoints: state.SupportPoint,
  SegmentGroup: state.SegmentGroup,
  SensorPoint: state.SensorPoint,
  StrandGuide: state.StrandGuide,
  DataPoint: state.DataPoint,
  DataLine: state.DataLine,
  featureFlags: state.application.main.authenticationData.featureFlags,
  hasChanges: state.data.hasChanges,
  isCommandMappingOpen: state.visualization.isCommandMappingOpen,
  isLoggedIn: FeatureFlags.isLoggedIn(state),
  isPlotListOpened: state.visualization.isPlotListOpened,
  loadingStatus: state.application.main.loadingStatus,
  networkStatus: state.visualization.networkStatus,
  openAppDialogs: state.application.main.openAppDialogs,
  openDialogs: state.application.main.openDialogs,
  parsedJsonData: state.data.parsedJsonData,
  pendingDeleteList: state.data.pendingDeleteList,
  plotConfigs: state.visualization.plotConfigs,
  rootData: state.data.rootData,
  selectedDataArray: state.data.selectedDataArray,
  term: state.filter.term,
  tileConfigs: state.visualization.tileConfigs,
  viewsObject: state.visualization.viewsObject,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
}), {
  addComparisonCaster: ComparisonCastersActions.addComparisonCaster,
  addDirtyPath: DataActions.addDirtyPath,
  addHdf5Schema: VisualizationActions.addHdf5Schema,
  applyChanges: DataActions.applyChanges,
  clearDirtyDeletePaths: DataActions.clearDirtyDeletePaths,
  closeDialog: ApplicationActions.closeDialog,
  deleteElements: DataActions.deleteElements,
  mergeNew: DataActions.mergeNew,
  openDialog: ApplicationActions.openDialog,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  openSelectSourceDialog: VisualizationActions.openSelectSourceDialog,
  overwriteAll: DataActions.overwriteAll,
  parseJsonXml: DataActions.parseJsonXml,
  removeHdf5Schema: VisualizationActions.removeHdf5Schema,
  resetHasChanges: DataActions.resetHasChanges,
  resetReducer: DataActions.resetReducer,
  saveAndParseXmlData: DataActions.saveAndParseXmlData,
  saveCatalog: DataActions.saveCatalog,
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
  setAmountOfComparisonCasterColumns: VisualizationActions.setAmountOfComparisonCasterColumns,
  setAppState: ApplicationActions.setAppState,
  setAuthenticationData: ApplicationActions.setAuthenticationData,
  setConfig: VisualizationActions.setConfig,
  setCurrentCasterDialogWidth: VisualizationActions.setCurrentCasterDialogWidth,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setDataSources: VisualizationActions.setDataSources,
  setError: ErrorActions.setError,
  setExecutionState: DataActions2.setExecutionState,
  setHdf5Schema: VisualizationActions.setHdf5Schema,
  setIsConsistencyCheck: UtilActions.setIsConsistencyCheck,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setOpenDialogs: ApplicationActions.setOpenDialogs,
  setPlotsMeta: VisualizationActions.setPlotsMeta,
  setSavePath: UtilActions.setSavePath,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  setTerm: FilterActions.setTerm,
  setTheme: ApplicationActions.setTheme,
  setUserSettings: UtilActions.setUserSettings,
  setVisualizationData: VisualizationActions.setData,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  showPlotList: VisualizationActions.showPlotList,
  storeNetworkStatus: VisualizationActions.storeNetworkStatus,
  updateNotification: DataActions.updateNotification,
  switchProject: ApplicationActions.switchProject,
  addAllElements: addAllElements,
  deleteElementTest: deleteElementTest,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  toggleEditMode: VisualizationActions.toggleEditMode,
  updateWholeState: UtilActions.updateWholeState,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string,
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  closeSnackbar (key?: OptionsObject['key']) : void;
}

const AppContainer = styled.div`
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
`
// TODO: move down
const DialogButton = styled.div<{
  orientation: 'left' | 'right',
  isOpen: boolean,
  isBig: boolean,
  dashboardWidth?: number
  currentCasterDialogWidth?: number
}>`
  ${({ orientation, isOpen, isBig, dashboardWidth, currentCasterDialogWidth }) => css`
    position: absolute;
    top: calc(50% - 5px);
    width: 100px;
    height: 10px;
    background: #212427;
    border-radius: 7px;
    color: #CCCCCC;
    font-size: 24px;
    padding: 15px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    ${orientation === 'left' && 'text-align: right;'}
    ${orientation === 'left' && 'justify-content: flex-end;'}
    ${orientation === 'right'
      ? `right: ${isOpen ? `${(currentCasterDialogWidth || 335) - 77}px` : '-80px'};`
      : `left: ${isOpen ? (isBig ? `${(dashboardWidth || 500) - 80}px` : '200px') : '-80px'};`}
  `}
`

const ComparisonControllerButton = styled.div<{
  orientation: 'left' | 'right',
  isOpen: boolean,
  isBig: boolean,
  dashboardWidth?: number
  topOffset: number
  currentCasterDialogWidth: number
  disabled: boolean
}>`
  ${({ orientation, isOpen, isBig, dashboardWidth, topOffset, currentCasterDialogWidth, disabled }) => css`
    position: absolute;
    top: calc(50% - ${topOffset}px);
    width: 100px;
    height: 10px;
    background: ${disabled ? '#033b5e' : '#0777BD'};
    border-radius: 7px;
    color: ${disabled ? '#6b6b6b' : '#CCCCCC'};
    font-size: 18px;
    padding: 15px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    ${orientation === 'left' && 'text-align: right;'}
    ${orientation === 'left' && 'justify-content: flex-end;'}
    ${orientation === 'right'
      ? `right: ${isOpen ? `${currentCasterDialogWidth - 77}px` : '-80px'};`
      : `left: ${isOpen ? (isBig ? `${(dashboardWidth || 500) - 80}px` : '200px') : '-80px'};`}
  `}
`

const CasterLoadingScreen = styled.div<{
  CasterTree?: boolean,
  CasterDialog?: boolean,
  CasterDashboard?: boolean,
  children?: React.ReactElement,
}>`
  ${({ CasterDialog, CasterDashboard: CasterDb }) => css`
    position: absolute;
    top: 50px;
    right: ${CasterDialog ? '335px' : '0'};
    bottom: 0;
    left: ${CasterTree ? (CasterDb ? '500px' : '280px') : '0'};
    overflow: hidden;
    background-color: #000000;
    width: calc(100vw - ${CasterDialog ? '335px' : '0px'} - ${CasterTree ? (CasterDb ? '500px' : '280px') : '0px'});
  `}
`

type State = {
  isNewCaster: boolean,
  redraw: boolean,
  openDashboard: boolean,
  electronReady: boolean,
  loggingInToLocalServer: boolean
  resizeDashboardWidth: number
};

export class App extends Component<Props, State> {
  wasFullScreen: boolean | null;
  timeoutHandle: string | null; // TODO: Is this necessary?
  handlers: Handlers
  selectionQueue: string

  constructor (props: Props) {
    super(props)
    this.handlers = new Handlers({ props })
    this.wasFullScreen = null
    this.timeoutHandle = null
    this.selectionQueue = ''
    AllActions.AppErrorActions.resetErrors()
    Util.bindMethods(this.handlers, this)

    IpcManager.init(this)

    initHotkeys()
  }

  state = {
    isNewCaster: false,
    redraw: false,
    openDashboard: true,
    electronReady: false,
    loggingInToLocalServer: true,
    resizeDashboardWidth: 0,
  }

  static getDerivedStateFromProps (nextProps: Props) {
    const { openAppDialogs, appState } = nextProps

    if ((openAppDialogs.length || appState !== AppState.Caster) && !ThreeManager.base.skipRendering) {
      ThreeManager.base.skipRendering = true
    }
    else if (!openAppDialogs.length && appState === AppState.Caster && ThreeManager.base.skipRendering) {
      ThreeManager.base.skipRendering = false
    }

    return null
  }

  componentDidMount () {
    NetworkManager.init(this.handlers)
    IpcManager.appLockCaster = this.handlers.handleLockCaster

    IpcManager.registerEvents(this.props, this.forceUpdate.bind(this))

    if (document.body) {
      document.body.addEventListener('contextmenu', (event: Event) => {
        event.preventDefault()
      })
    }

    const consistencyCheckCaseId = isConsistencyCheckURL()

    if (consistencyCheckCaseId) {
      this.props.setLoadingStatus(true)
      this.props.setIsConsistencyCheck()
      IpcManager.internal.send('loadConsistencyCheckCase', null, consistencyCheckCaseId)
    }
  }

  componentDidUpdate (prevProps: Props) {
    IpcManager.updateProps(this.props)

    this.handlers.handleLockCaster(prevProps)
    const {
      openDialogs,
      currentDashboardWidth,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      selectedDataArray,
      setAmountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      selectedMultiEditElements,
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SupportPoint,
      Segment,
      SegmentGroupSupportPoints,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
      DataPoint,
      DataLine,
    } = this.props

    const elementsHashes: ElementsHashes = {
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SupportPoint,
      Segment,
      SegmentGroupSupportPoints,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
      DataPoint,
      DataLine,
    }

    const prevElementsHashes = {
      AirLoop: prevProps.AirLoop,
      CoolingLoop: prevProps.CoolingLoop,
      CoolingZone: prevProps.CoolingZone,
      LoopAssignment: prevProps.LoopAssignment,
      Nozzle: prevProps.Nozzle,
      Roller: prevProps.Roller,
      RollerBearing: prevProps.RollerBearing,
      RollerBody: prevProps.RollerBody,
      SupportPoint: prevProps.SupportPoint,
      SegmentGroupSupportPoints: prevProps.SegmentGroupSupportPoints,
      Segment: prevProps.Segment,
      SegmentGroup: prevProps.SegmentGroup,
      SensorPoint: prevProps.SensorPoint,
      StrandGuide: prevProps.StrandGuide,
      DataPoint: prevProps.DataPoint,
      DataLine: prevProps.DataLine,
    }

    const elementsChanged = Object.keys(elementsHashes).some((key) => {
      const elementType = (elementsHashes as any)[key]
      const prevElementType = (prevElementsHashes as any)[key]

      return !elementType.equals(prevElementType)
    })

    if (
      prevProps.term !== this.props.term ||
      prevProps.selectedDataArray.length !== this.props.selectedDataArray.length ||
      !prevProps.selectedDataArray.equals(this.props.selectedDataArray) ||
      (prevProps.loadingStatus !== this.props.loadingStatus && !this.props.loadingStatus)
    ) {
      ThreeManager.base.renderScene()
    }

    const {
      openDialogs: prevOpenDialogs,
      hasChanges: prevHasChanges,
      currentDashboardWidth: prevDashboardWidth,
      currentCasterDialogWidth: prevCasterDialogWidth,
    } = prevProps

    if (this.props.hasChanges && !prevHasChanges && IpcManager.both) {
      IpcManager.both.send('changes')
    }

    if (
      openDialogs !== prevOpenDialogs ||
      currentDashboardWidth !== prevDashboardWidth ||
      currentCasterDialogWidth !== prevCasterDialogWidth
    ) {
      setTimeout(ThreeManager.base.resize, 1)
    }

    const isFullScreen = !!document.fullscreenElement

    if (this.wasFullScreen !== isFullScreen) {
      this.wasFullScreen = isFullScreen

      this.handlers.handleFullScreenNotification(isFullScreen)
    }

    if (!selectedDataArray.length) {
      if (amountOfComparisonCasterColumns) {
        setAmountOfComparisonCasterColumns(0)
        setCurrentCasterDialogWidth(335)
      }
    }

    if (this.selectionQueue && elementsChanged) {
      const filteredElements = FilterHandler
        .getFilteredElements(elementsHashes, this.selectionQueue, false) as Record<string, FilterableElementType>

      const paths = Object.entries(filteredElements)
        .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
        .map(([ path ]) => path)

      selectedMultiEditElements(paths, true)
      this.selectionQueue = ''
    }
  }

  onResizeCasterTree =(width: number) => {
    this.setState({
      resizeDashboardWidth: width,
    })
  }

  render () {
    const {
      isLoggedIn,
      openDialogs,
      loadingStatus,
      rootData,
      pendingDeleteList,
      selectedDataArray,
      isPlotListOpened,
      isCommandMappingOpen,
      appState,
      darkTheme,
      t,
      currentSimpleDashboardTabIndex,
      currentDashboardWidth,
      viewsObject,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      currentDashboard,
      featureFlags,
      currentSimulationCase,
    } = this.props

    const realFeatureFlags = FeatureFlags
      .getRealFeatureFlags({ application: { main: { authenticationData: { featureFlags }, currentSimulationCase } } })
    const { isNewCaster, redraw, resizeDashboardWidth } = this.state
    const openCasterTree = openDialogs.includes('CasterTree')
    const openCasterDialog = openDialogs.includes('CasterDialog')
    const openCasterDashboard = currentSimpleDashboardTabIndex > 0

    let openPendingDeleteDialog = false

    pendingDeleteList.length > 0 && pendingDeleteList.forEach(pendingElement => {
      openPendingDeleteDialog = !selectedDataArray.includes(pendingElement)
    })

    const isDialogOpen = openPendingDeleteDialog

    // TODO: fix this: electronReady gets reset to false on logout, but electron is obviously ready
    // const hasAccess = !(!isLoggedIn && ((window.isElectron && electronReady) || !window.isElectron))
    const hasAccess = !(!isLoggedIn && ((window.isElectron) || !window.isElectron))

    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const isComparingCasters = Boolean(viewObject?.selectedComparisonCasters?.length)
    const canCompareCasters = FeatureFlags.canCompareCastersInCasterDialog(realFeatureFlags)

    if (window.isElectron && this.state.loggingInToLocalServer) {
      return (
        <MuiThemeProvider theme={StyleConfig.muiTheme(darkTheme)}>
          <AppContainer className={darkTheme ? undefined : 'light'}>
            <CasterLoadingScreen>
              <LoadingScreen />
            </CasterLoadingScreen>
          </AppContainer>
        </MuiThemeProvider>
      )
    }

    return (
      <MuiThemeProvider theme={StyleConfig.muiTheme(darkTheme)}>
        <AppContainer className={darkTheme ? undefined : 'light'}>
          {hasAccess ? 'true' : 'false'}
          {
            hasAccess ? <div>
              {
                !loadingStatus && (isPlotListOpened || isCommandMappingOpen) &&
                  <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
                    <div>
                      {isPlotListOpened && <PlotConfigurator />}
                      {isCommandMappingOpen && <CommandMapping />}
                    </div>
                  </ThemeProvider>
              }
              <DialogManager />
              {appState !== AppState.Caster && <Dashboard />}
              <div
                style={
                  appState === AppState.Caster && !isPlotListOpened
                    ? { background: 'black', height: '100vh' }
                    : { display: 'none' }
                }
              >
                <Caster isNewCaster={isNewCaster} redraw={redraw} blur={isDialogOpen} />
                {
                  (loadingStatus || !(rootData.Caster)) &&
                  <CasterLoadingScreen
                    CasterTree={openCasterTree}
                    CasterDialog={openCasterDialog}
                    CasterDashboard={openCasterDashboard}
                  >
                    <LoadingScreen />
                  </CasterLoadingScreen>
                }
                <CasterFilter />
                {openPendingDeleteDialog && <DeleteDialog />}
                <DialogButton
                  dashboardWidth={Math.min(resizeDashboardWidth ?? currentDashboardWidth, window.innerWidth - 390)}
                  orientation='left'
                  onClick={() => this.handlers.handleClose('CasterTree')}
                  isOpen={openCasterTree}
                  isBig={openCasterDashboard}
                  title={t(`treeView.${openCasterTree ? 'hide' : 'show'}`)}
                >
                  {
                    openCasterTree
                      ? <Icon icon='angle-left' />
                      : <Icon icon='angle-right' />
                  }
                </DialogButton>
                <DialogButton
                  orientation='right'
                  onClick={() => this.handlers.handleClose('CasterDialog')}
                  isOpen={openCasterDialog}
                  title={t(`dialog.${openCasterDialog ? 'hide' : 'show'}`)}
                  isBig={false}
                  currentCasterDialogWidth={currentCasterDialogWidth}
                >
                  {
                    openCasterDialog
                      ? <Icon icon='angle-right' />
                      : <Icon icon='angle-left' />
                  }
                </DialogButton>
                {
                  canCompareCasters && isComparingCasters && selectedDataArray.length && (
                    <>
                      <ComparisonControllerButton
                        orientation='right'
                        onClick={() => this.handlers.handleShowMore()}
                        isOpen={openCasterDialog}
                        title={t('dialog.showMore')}
                        isBig={false}
                        topOffset={-30}
                        disabled={viewObject?.selectedComparisonCasters?.length === amountOfComparisonCasterColumns}
                        currentCasterDialogWidth={currentCasterDialogWidth}
                      >
                        <Icon icon='plus' />
                      </ComparisonControllerButton>
                      <ComparisonControllerButton
                        orientation='right'
                        onClick={() => this.handlers.handleShowLess()}
                        isOpen={openCasterDialog}
                        title={t('dialog.showLess')}
                        isBig={false}
                        topOffset={-65}
                        disabled={amountOfComparisonCasterColumns === 0}
                        currentCasterDialogWidth={currentCasterDialogWidth}
                      >
                        <Icon icon='minus' />
                      </ComparisonControllerButton>
                      <ComparisonControllerButton
                        orientation='right'
                        disabled={!amountOfComparisonCasterColumns}
                        onClick={() => this.handlers.handleSetDefaultView()}
                        isOpen={openCasterDialog}
                        title={t('dialog.defaultView')}
                        isBig={false}
                        topOffset={-100}
                        currentCasterDialogWidth={currentCasterDialogWidth}
                      >
                        <Icon icon='dot-circle' />
                      </ComparisonControllerButton>
                    </>
                  )
                }
                <CasterTree handleResizeCasterTree={this.onResizeCasterTree} isOpen={openCasterTree} />
                <CasterDialog />
              </div>
              {/*  eslint-disable-next-line react/jsx-closing-tag-location */}
            </div>
              : <DialogManager login />
          }

        </AppContainer>
      </MuiThemeProvider>
    )
  }
}

export default withNamespaces('caster')(withSnackbar(connector(App as any) as any) as any) as any
