import cloneDeep from 'clone-deep'

import NetworkManager, { NetworkStatusEnum } from '../../../../network/NetworkManager'
import CalcUtil from '../../../../network/CalcUtil'
import type { Props } from '.'
import { AnalyzeTime } from 'Util'

export default class logic {
  static didCreate = false

  @AnalyzeTime(0)
  static connect (
    prevProps: Props,
    props: Props,
    setData: ({ tileId, definitions }: {tileId: string, definitions: any[]}) => void,
  ) {
    const {
      tileId,
      xRange,
      valueRange,
      frequency,
      configId,
      plotConfigs,
      data,
      tileConfigs,
      isNewData,
      updatedPlot,
      networkStatus,
      shapeIds,
    } = props

    let dataConfigIds = [ configId ]

    const {
      frequency: prevFrequency,
      // type: prevType,
      xRange: prevXRange,
      valueRange: prevValueRange,
    } = prevProps

    const { configIds: prevConfigIds } = prevProps.plotConfigs[tileConfigs[tileId].configId]
    const { configIds } = plotConfigs[tileConfigs[tileId].configId]

    const prevDataRange = [ ...(prevXRange || []) ]
    const dataRange = [ ...xRange ]

    prevDataRange[1] = Math.min(prevDataRange[1])
    dataRange[1] = Math.min(dataRange[1])

    let redraw = prevConfigIds && prevConfigIds.length && !prevConfigIds.equals(configIds)

    redraw = redraw || (prevXRange && prevXRange.length && !prevDataRange.equals(dataRange))
    redraw = redraw || (prevValueRange && prevValueRange.length && !prevValueRange.equals(valueRange))
    redraw = redraw || (prevFrequency !== frequency)
    // redraw = redraw || (prevType !== type)
    redraw = redraw || (prevProps.configId !== configId)
    redraw = redraw || !prevProps.tileConfigs[tileId].equals(tileConfigs[tileId])

    if (redraw) {
      if (networkStatus === NetworkStatusEnum.CONNECTED) {
        NetworkManager.removePlot(prevProps.tileId)
      }

      logic.didCreate = false
    }

    if (
      !NetworkManager._shouldBeConnected &&
      NetworkManager._previousStatus !== null &&
      NetworkManager._previousStatus === 'disconnected' &&
      isNewData
    ) {
      updatedPlot()
      logic.didCreate = false
    }

    // eslint-disable-next-line
    if (!props.equals(prevProps)) {
      logic.didCreate = false
    }

    if (!logic.didCreate) {
      logic.didCreate = true

      if (configIds) {
        dataConfigIds = cloneDeep(configIds)
      }

      const dataRange = [ ...xRange ]

      dataRange[1] = Number(xRange[1])

      NetworkManager.setPlot(
        {
          tileId,
          type: Float32Array.name,
          xRange: dataRange,
          valueRange,
          frequency,
          plotIds: dataConfigIds,
          shapeIds,
        },
        setData,
      )

      if (data && Object.keys(data).length) {
        setData({
          tileId,
          definitions: dataConfigIds.map(pId => {
            const { group, key } = plotConfigs[pId]
            const values = ((data as any)[group] || {})[key] || []

            const { min: xMin, max: xMax } = CalcUtil.getMinMax(xRange)

            const start = Math.max(xMin, 0)
            const end = Math.min(xMax, values.length)

            const newData = []

            for (let j = start; j < end; j++) {
              newData.push(values[j])
            }

            return {
              id: pId,
              length: values.length,
              bytes: null,
              data: newData,
            }
          }),
        })
      }
    }
  }
}
