import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import tinycolor from 'tinycolor2'

import { withTheme, MenuItem, FormControl, Select, Checkbox } from '@material-ui/core'
import Delete from 'react/specific/Delete'

const MdFormControl = styled(FormControl)<any>`${({ disabled }) => css`
  width: 100%;
  ${disabled && css`cursor: not-allowed;`}

  > div {
    ${disabled && css`pointer-events: none;`}
  }
`}`

const Badge = styled.div`${({ theme }) => css`
  margin: 0 5px;
  border: 1px solid ${theme.colors.swatch13};
  border-radius: 5px;
  font-size: 14px!important;
  padding: 0 5px;
  color: ${theme.colors.swatch13};
  text-transform: capitalize;
`}`

const Option = styled(({ notRemovable, spaceBetween, ...restProps }) =>
  <MenuItem {...restProps} />)`${({ theme, notRemovable, spaceBetween }) => css`
  display: flex;
  ${spaceBetween && 'justify-content: space-between !important;'}

  span {
    ${!spaceBetween && 'flex: 15;'};
  }

  div {
    flex: 1;
    ${!notRemovable && 'display: inline-block !important;'};
    font-size: 24px;
  }

  &[data-selected-helper="true"] ${Badge} {
    color: ${theme.colors.swatch15};
  }
`}`

const StyledCheckbox = styled(Checkbox)`
  height: 10px;
  width: 10px;
  padding-right: 5px;
  .test span:hover {
    color: blue;
    background-color: transparent !important;
  }
`

const OptGroup = styled(Option)`${({ theme, disabled }) => css`
  outline: none;
  font-family: 'Roboto', sans-serif;
  border-top: 1px solid ${theme.primary.font}!important;
  padding-left: 16px!important;
  font-weight: 600!important;
  ${disabled && css`pointer-events: none !important;`}
  
  span {
    padding-left: 5px;
  }
  
  & ~ ${Option} {
    padding-left: 30px;
  }
`}`

const Default = styled.div<{active: boolean}>`${({ theme, active }) => css`
  margin: 0 5px;
  ${active && 'color: #e0b60f'}

  &:hover {
    color: ${active ? tinycolor('#e0b60f').darken(10).toString() : theme.primary.light}
  }
`}`

const Download = styled.div`${({ theme }) => css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme.primary.light}
  }
`}`

const Edit = styled.div`${({ theme }) => css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme.primary.light}
  }
`}`

const HideUser = styled.div`${({ theme }) => css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme.primary.light}
  }
`}`

const defaultOptionDescribers = [ '(default)', '(case def.)', '(group def.)' ]

type Props = {
  name: string
  value?: number | string | boolean | string[]
  selectors: Array<any>
  onChange?: (e: any) => void
  onEdit?: (e: any) => void
  onDelete?: (name: string, key: string) => void
  onDownload?: (key: string) => void
  onSetDefault?: (key: string) => void
  theme?: any
  classes?: any
  noDeleteCurrent?: boolean
  noDeleteGiven?: Array<string>
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  disabled?: boolean
  error?: string
  title?: string // TODO: is this used?
  placeholder?: string // TODO: is this used?
  multiple?: boolean
  checkbox?: boolean
  onCheckboxClick?: (e: any, comparisonCasterName: string) => void
  renderValue?: any
  selectedIds?: string[]
  optionStyles?: any
  spaceBetween?: boolean
  MenuProps?: any
  onUserHide?: (key: string) => void
  canHideUser?: boolean
};

class SelectPlot extends Component<Props> {
  handleEdit = (key: string) => {
    const { onEdit } = this.props

    if (onEdit) {
      onEdit(key)
    }
  };

  handleDelete = (key: string) => {
    const { onChange, name, onDelete } = this.props

    if (onDelete) {
      onDelete(name, key)
    }

    if (onChange) {
      onChange({ target: { name, value: '' } })
    }
  };

  handleDownload = (key: string) => {
    const { onDownload } = this.props

    if (onDownload) {
      onDownload(key)
    }
  };

  handleSetDefault = (key: string) => {
    const { onSetDefault } = this.props

    if (onSetDefault) {
      onSetDefault(key)
    }
  }

  getIsChecked = (ids: any[], option: any) => {
    const index = ids.indexOf(option.key || option)

    return (index !== -1 || option.checked || false)
  }

  getTooltipText = (option: Record<string, string> | string | boolean): string => {
    if (option instanceof Object) {
      if (React.isValidElement(option.value)) {
        return ''
      }

      return String(option.value)
    }

    if (React.isValidElement(option)) {
      return ''
    }

    return String(option)
  }

  getLabel = (option: Record<string, string> | string | boolean) => {
    if (option instanceof Object) {
      if (React.isValidElement(option.value)) {
        return option.value
      }

      return String(option.value)
    }

    if (React.isValidElement(option)) {
      return option
    }

    return String(option)
  }

  render () {
    const {
      value,
      selectors,
      name,
      half,
      error,
      noDeleteCurrent,
      noDeleteGiven,
      checkbox,
      selectedIds,
      optionStyles,
      spaceBetween,
      onChange,
      onDelete,
      onEdit,
      onDownload,
      onSetDefault,
      onCheckboxClick,
      canHideUser,
      onUserHide,
      ...restProps
    } = this.props

    let groups = selectors.map(selector => selector.group)

    groups = groups.filter((group, index) => groups.indexOf(group) === index)

    return (
      <MdFormControl half={half} error={!!error} disabled={restProps.disabled}>
        <Select
          onChange={onChange}
          name={name}
          displayEmpty
          error={!!error}
          value={value}
          {...restProps}
        >
          {
            groups && groups.length && groups[0]
              ? groups.map(group => {
                if (group !== 'disabled') {
                  const Groupname = (
                    <OptGroup key={group} disabled>
                      {group}
                    </OptGroup>
                  )

                  // TODO: translate badge
                  const Options = selectors.filter(selector => selector.group === group).map((option, index) =>
                    <Option
                      key={index}
                      value={option.key || option}
                      title={this.getTooltipText(option)}
                      disabled={option.disabled || false}
                      data-selected-helper={value === (option.key || option)}
                    >
                      <span>{this.getLabel(option)}</span>
                      {option.badge && <div style={{ display: 'none' }}><Badge>{option.badge}</Badge></div>}
                    </Option>)

                  return ([ Groupname, ...Options ])
                }
                else {
                  return selectors.filter(selector => selector.group === group).map((option, index) =>
                    <Option
                      key={option.key || option}
                      value={option.key || option}
                      title={this.getTooltipText(option)}
                      disabled
                      data-selected-helper={value === (option.key || option)}
                    >
                      <span>{this.getLabel(option)}</span>
                      {option.badge && <div style={{ display: 'none' }}><Badge>{option.badge}</Badge></div>}
                    </Option>)
                }
              })
              : selectors.map((option) =>
                <Option
                  key={option.key || option}
                  value={option.key || option}
                  disabled={option.disabled}
                  notRemovable={option.notRemovable}
                  style={optionStyles || {}}
                  spaceBetween={spaceBetween}
                  title={option.title || ''}
                >
                  <span>{this.getLabel(option)}</span>
                  <div
                    style={{ display: 'none' }}
                    onClick={
                      (event) => {
                        event.stopPropagation()
                      }
                    }
                  >
                    {
                      onSetDefault && (
                        <Default
                          onClick={() => this.handleSetDefault(option.key)}
                          title={
                            defaultOptionDescribers.some(describer => option.value.includes(describer))
                              ? 'Remove default'
                              : 'Set as default'
                          }
                          active={defaultOptionDescribers.some(describer => option.value.includes(describer))}
                        >
                          <i className='pe-7s-pin' />
                        </Default>
                      )
                    }
                    {
                      onDelete && (
                        <Delete
                          disabled={
                            (noDeleteCurrent && value === (option.key || option)) ||
                          (noDeleteGiven && noDeleteGiven.includes(option.key || option))
                          }
                          onClick={() => this.handleDelete(option.key)}
                        />)
                    }
                    {
                      onDownload &&
                      <Download
                        onClick={() => this.handleDownload(option.key)}
                      >
                        <i className='pe-7s-cloud-download' />
                      </Download>
                    }
                    {onEdit && <Edit onClick={() => this.handleEdit(option.key)}><i className='pe-7s-pen' /></Edit>}
                    {
                      canHideUser && onUserHide && (
                        <HideUser onClick={() => onUserHide(option.key)}><i className='pe-7s-look' /></HideUser>
                      )
                    }
                  </div>
                  {
                    checkbox && Array.isArray(value) &&
                    <StyledCheckbox
                      className='test'
                      disabled={option.disabled}
                      checked={this.getIsChecked(selectedIds || [], option)}
                      onChange={(e) => onCheckboxClick ? onCheckboxClick(e.target.checked, option.key) : null}
                    />
                  }
                </Option>)
          }
        </Select>
      </MdFormControl>
    )
  }
}

export default withTheme(SelectPlot) as any
