// consts.js
enum LoadingActions {
  ACTION_LOADING_CURSOR = 'loading/ACTION_LOADING_CURSOR',
  ACTION_RESET_LOADING_CURSOR = 'loading/ACTION_RESET_LOADING_CURSOR',
  ACTION_SET_FILE_UPLOAD_LOADING = 'loading/ACTION_SET_FILE_UPLOAD_LOADING',
}

enum LoadingTypes {
  APPLY = 'apply',
  CLONE = 'clone',
  CREATE = 'create',
  MIRROR = 'mirror',
  PATTERN = 'pattern',
}

// index.js
const initialState: LoadingState = {
  loading: {} as LoadingElements,
  uploadLoading: {
    CasterDataIsLoading: false,
    VisualizationDataIsLoading: false,
  },
}

// action.js
export function resetLoadingCursor () {
  return {
    type: LoadingActions.ACTION_RESET_LOADING_CURSOR,
  }
}

export function setApplyLoadingCursor (elementType: string) {
  return {
    type: LoadingActions.ACTION_LOADING_CURSOR,
    loadingType: LoadingTypes.APPLY,
    elementType,
  }
}

export function setCloneLoadingCursor (elementType: string) {
  return {
    type: LoadingActions.ACTION_LOADING_CURSOR,
    loadingType: LoadingTypes.CLONE,
    elementType,
  }
}

export function setCreateLoadingCursor (elementType: string) {
  return {
    type: LoadingActions.ACTION_LOADING_CURSOR,
    loadingType: LoadingTypes.CREATE,
    elementType,
  }
}

export function setMirrorLoadingCursor (elementType: string) {
  return {
    type: LoadingActions.ACTION_LOADING_CURSOR,
    loadingType: LoadingTypes.MIRROR,
    elementType,
  }
}

export function setPatternLoadingCursor (elementType: string) {
  return {
    type: LoadingActions.ACTION_LOADING_CURSOR,
    loadingType: LoadingTypes.PATTERN,
    elementType,
  }
}

export function setFileUploadLoadingStatus (uploadType: string, status: boolean) {
  return {
    type: LoadingActions.ACTION_SET_FILE_UPLOAD_LOADING,
    uploadType,
    status,
  }
}

const actionHandlers = {
  [LoadingActions.ACTION_RESET_LOADING_CURSOR]: () => ({
    ...initialState,
  }),
  [LoadingActions.ACTION_LOADING_CURSOR]: (
    state: LoadingState,
    { elementType, loadingType }: { elementType: string, loadingType: string},
  ) => ({
    ...state,
    loading: {
      ...state.loading,
      [elementType]: {
        ...(state.loading as any)[elementType],
        [loadingType]: true,
      },
    },
  }),
  [LoadingActions.ACTION_SET_FILE_UPLOAD_LOADING]: (
    state: LoadingState,
    { uploadType, status }: { uploadType: string, status: boolean},
  ) => ({
    ...state,
    uploadLoading: {
      ...state.uploadLoading,
      [uploadType]: status,
    },
  }),
}

export default function (state = initialState, action: any) {
  const handler = (actionHandlers as any)[action.type]

  return handler ? handler(state, action) : state
}
