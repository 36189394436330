import * as THREE from 'three'

import Util from '../../logic/Util'
import BaseView from '../BaseView'
import { Views } from '../../ThreeBase'
import { PerspectiveCamera } from 'three'
import { AnalyzeTime } from 'Util'

export default class AxisView extends BaseView {
  axesHelper: THREE.Group
  camera: PerspectiveCamera

  @AnalyzeTime(0)
  static getArrow (x: number, y: number, z: number, color: string, length = 0.85) {
    const direction = new THREE.Vector3(x, y, z)

    direction.normalize()

    return new THREE.ArrowHelper(direction, new THREE.Vector3(), length, color, 0.2, 0.1)
  }

  @AnalyzeTime(0)
  static getLetter (x: number, y: number, z: number, letter: string, color = '#FFFFFF', size = 0.2) {
    const letterMesh = Util.getText(letter, size, false, true)

    letterMesh.position.set(x, y, z)
    letterMesh.name = `letter_${letter}`

    return letterMesh
  }

  constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    AxisView.staticClassName = 'AxisView'
    this.className = 'AxisView'

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000)
    this.camera.position.z = 3

    this.axesHelper = new THREE.Group()

    this.axesHelper.add(AxisView.getArrow(1, 0, 0, '#FF0000'))
    this.axesHelper.add(AxisView.getArrow(0, 1, 0, '#00FF00'))
    this.axesHelper.add(AxisView.getArrow(0, 0, 1, '#0000FF'))
    this.axesHelper.add(AxisView.getLetter(1, 0, 0, 'x'))
    this.axesHelper.add(AxisView.getLetter(0, 1.1, 0, 'y'))
    this.axesHelper.add(AxisView.getLetter(0, 0, 1, 'z'))

    this.scene.add(this.axesHelper)
  }

  @AnalyzeTime(0)
  resize (width: number, height: number) {
    const size = 125

    this.viewport = {
      x: 0,
      y: height - size,
      width: size,
      height: size,
    }
  }

  @AnalyzeTime(1)
  animate () {
    const { mainView } = this.views || {}

    if (mainView) {
      this.camera.position.copy(mainView.camera.position)
      this.camera.position.sub(mainView.controls.target)
    }

    this.camera.position.setLength(3)
    this.camera.lookAt(this.scene.position)

    this.axesHelper.getObjectByName('letter_x')?.quaternion.copy(this.camera.quaternion)
    this.axesHelper.getObjectByName('letter_y')?.quaternion.copy(this.camera.quaternion)
    this.axesHelper.getObjectByName('letter_z')?.quaternion.copy(this.camera.quaternion)
  }

  @AnalyzeTime(0)
  hide () {
    this.axesHelper.visible = false
  }

  @AnalyzeTime(0)
  show () {
    this.axesHelper.visible = true
  }
}
