import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import Button from '@material-ui/core/Button'

import Icon from 'react/specific/Icon'

import * as ApplicationActions from 'store/application/main/actions'
import SimulateDialog from '../SimulateDialog'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isSelected: boolean,
  simulateLoading: boolean,
  simulationRunning: boolean,
  isSelectedAndNotSimulated: boolean,
  t: Translation,
}

class SimulateSelected extends Component<Props> {
  // @AnalyzeTime(0)
  handleClick = () => {
    const { openDialog } = this.props

    openDialog(SimulateDialog.NAME)
  };

  // @AnalyzeTime(0)
  getTitle = () => {
    const { isSelected, simulateLoading, simulationRunning, isSelectedAndNotSimulated, t } = this.props

    if (!isSelected) {
      return t(`${PRE_TRANS}.titles.simulateSelected.nothingSelected`)
    }

    if (!isSelectedAndNotSimulated) {
      return t(`${PRE_TRANS}.titles.simulateSelected.nothingSimulatableSelected`)
    }

    if (simulateLoading) {
      return t(`${PRE_TRANS}.titles.simulateSelected.loading`)
    }

    if (simulationRunning) {
      return t(`${PRE_TRANS}.titles.simulateSelected.running`)
    }

    return t(`${PRE_TRANS}.titles.simulateSelected.default`)
  };

  @AnalyzeTime(0)
  render () {
    const { isSelected, simulateLoading, simulationRunning, isSelectedAndNotSimulated, t, ...restProps } = this.props
    const disabled = !isSelected || simulateLoading || simulationRunning || !isSelectedAndNotSimulated

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <Icon icon='tasks' />
      </Button>
    )
  }
}

export default withNamespaces('application')(connector(SimulateSelected as any) as any) as any
