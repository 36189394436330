import IpcManager from '../IpcManager'
const globalContext = (window as any)

globalContext.ENABLE_ANALYZE_TIME = true
globalContext.ENABLE_PASSED_THRESHOLD_LOGS = false

globalContext.PERFORMANCE_DATA = { thresholdPassed: [], data: [] }
globalContext.ANALYZING_DATA = false
globalContext.logInterval = 5
globalContext.filename = 'PerformanceAnalysis'
globalContext.excludedFunctions = []

globalContext.startAnalyztingTime = (filename: string, excludedFunctions: string[]) => {
  globalContext.ANALYZING_DATA = true
  globalContext.excludedFunctions = (excludedFunctions && excludedFunctions.length) ? excludedFunctions : []
  globalContext.filename = filename

  return 'Performance analysis started'
}

globalContext.endAnalyztingTime = () => {
  globalContext.ANALYZING_DATA = false
  IpcManager.send('writePerformanceAnalysisResults', ({
    data: globalContext.PERFORMANCE_DATA.data,
    filename: globalContext.filename,
  }))
  globalContext.PERFORMANCE_DATA.data = []

  return 'Performance analysis finished'
}

if (globalContext.ENABLE_ANALYZE_TIME && globalContext.ENABLE_PASSED_THRESHOLD_LOGS) {
  setInterval(() => {
    IpcManager.send('writePerformanceData', globalContext.PERFORMANCE_DATA.thresholdPassed)
    // eslint-disable-next-line no-console
    console.table(globalContext.PERFORMANCE_DATA.thresholdPassed)
    globalContext.PERFORMANCE_DATA.thresholdPassed = []
  }, globalContext.logInterval * 1000)
}

function addThresholdPassedLog (className: string, propertyKey: string, timespan: number, threshold: number): void {
  if (!globalContext.excludedFunctions.includes(propertyKey)) {
    globalContext.PERFORMANCE_DATA.thresholdPassed.push([
      `${className}:${propertyKey}`,
      timespan.toString(),
      `${new Date().toISOString().replace('T', ' ').replace('Z', '')}`,
      `${threshold}\n` ].join(','))
  }
}

function addPerformanceAnalysisData (
  className: string,
  propertyKey: string,
  timespan: number,
  threshold: number,
  // fps: number,
): void {
  if (!globalContext.excludedFunctions.includes(propertyKey)) {
    globalContext.PERFORMANCE_DATA.data.push(`${[
      `${className}:${propertyKey}`,
      timespan.toString(),
      `${new Date().toISOString().replace('T', ' ').replace('Z', '')}`,
      `${threshold}`,
      // fps,
     ].join(',')}\n`)
  }
}

export function AnalyzeTime (threshold: number) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    // if (!globalContext.ENABLE_ANALYZE_TIME) {
    // TODO: comment out if AnalyzeTime is needed
    return descriptor
    // }

    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
      const startTime = performance.now()

      const result = originalMethod.apply(this, args)

      if (!globalContext) {
        return
      }

      const endTime = performance.now()
      // const fps = (ThreeBase || {}).actualFPS || -1
      const timespan = endTime - startTime

      if (globalContext.ENABLE_PASSED_THRESHOLD_LOGS && timespan > threshold) {
        if (this === undefined) {
          addThresholdPassedLog(target.name, propertyKey, timespan, threshold)
        }
        else {
          // browser
          addThresholdPassedLog(this.constructor.name, propertyKey, timespan, threshold)
        // console.log('%c[AnalyzeTime]', 'color: red;', this.constructor.name+':'+propertyKey, timespan)
        }
        // node
        // console.log('\x1b[31m[AnalyzeTime]', '\x1b[0m'+this.constructor.name+':'+propertyKey, timespan)
      }

      if (globalContext.ANALYZING_DATA) {
        if (this === undefined) {
          addPerformanceAnalysisData(
            target.name,
            propertyKey,
            timespan,
            threshold,
            // fps,
          )
        }
        else {
          addPerformanceAnalysisData(
            this.constructor.name,
            propertyKey,
            timespan,
            threshold,
            // fps,
          )
        }
      }

      return result
    }

    return descriptor
  }
}
