import { VisualizationActionsEnum as VisActionsEnum } from './consts'

import { v4 as uuid } from 'uuid'

import Util from './util/Util'
import { Network } from 'network/Network'
import { DeleteDialogPlot, EditDialogDashboardData, TileConfig } from 'types/visualization'
import { DefaultState } from 'types/state'

export function setSecondarySize (viewId: string, size: number) {
  return {
    type: VisActionsEnum.ACTION_SET_SECONDARY_SIZE,
    viewId,
    size,
  }
}

export function setPlotsMeta (meta: any) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_META,
    meta,
  }
}

export function setData ({
  data,
  meta,
  clear,
}: any) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_DATA,
    data,
    meta,
    clear,
  }
}

export function setDataSources (dataSources: Array<any>) {
  const plotConfigs: any = {}

  dataSources.map(el => {
    const configKey = `config_${Util.generateConfigHash('dynamicDataSource', el._id)}`

    plotConfigs[configKey] = {
      ...el,
      id: configKey,
      key: configKey,
      group: 'dynamicDataSource',
    }
  })

  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_DATASOURCE,
    plotConfigs,
  }
}

export function showPlotList () {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_PLOT_LIST,
  }
}

export function handleCommandView () {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING,
  }
}

export function toggleEditMode (mode: boolean) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_EDIT_MODE,
    mode,
  }
}

export function showConfigDialog (editDialogConfigId = '', openConfigDialogWindow = false) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_CONFIG_DIALOG,
    editDialogConfigId,
    openConfigDialogWindow,
  }
}

export function setCurrentDashboardWidth (width: number) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_DASHBOARD_WIDTH,
    width,
  }
}

export function setCurrentCasterDialogWidth (width: number) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_CASTER_DIALOG_WIDTH,
    width,
  }
}

export function setAmountOfComparisonCasterColumns (n: number) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_AMOUNT_OF_COMPARISON_CASTER_COLUMNS,
    amountOfComparisonCasterColumns: n,
  }
}

export function showDeleteDialog (deleteDialogPlot: DeleteDialogPlot = {}, openDeleteDialogWindow = false) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_DELETE_DIALOG,
    deleteDialogPlot,
    openDeleteDialogWindow,
  }
}

export function showEditDashboardDialog (
  editDialogDashboardData: EditDialogDashboardData = {},
  openDashboardWindow = false,
) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_EDIT_DASHBOARD_DIALOG,
    editDialogDashboardData,
    openDashboardWindow,
  }
}

export function showDerivePlotDialog (openDerivePlotDialog: boolean) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_DERIVE_DIALOG,
    openDerivePlotDialog,
  }
}

export function showAddPlotDialog (addPlotViewId = '', openAddPlotDialogWindow = false) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SHOW_ADD_PLOT_DIALOG,
    addPlotViewId,
    openAddPlotDialogWindow,
  }
}

export function savePlotConfig (plotConfig: any) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SAVE_PLOT_CONFIG,
    plotConfig,
  }
}

export function removePlotConfig (plotConfigId: string) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_REMOVE_PLOT_CONFIG,
    plotId: plotConfigId,
  }
}

export function setLoadingButtonStatus (status: boolean, buttonType: string) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_LOADING_BUTTON_STATUS,
    status,
    buttonType,
  }
}

export function openContextMenu (dType: string, data: any) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_OPEN_CONTEXT_MENU,
    dType,
    data,
  }
}

export function closeContextMenu () {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_CLOSE_CONTEXT_MENU,
  }
}

export function derivePlot (configId: string, xIndex: number, viewId: string, x: number) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_DERIVE_PLOT,
    configId,
    xIndex,
    viewId,
    x,
  }
}

export function mergePlots (configIds: Array<string>, name?: string, plotType?: string) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_MERGE_PLOTS,
    configIds,
    name,
    plotType,
  }
}

export function saveCurrentTile (currentTileId: string) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_TILE_ID,
    currentTileId,
  }
}

// Dashboard
export function setConfig (layout: any) {
  const newLayout = Util.updateConfig(layout)

  return {
    type: VisActionsEnum.ACTION_SET_CONFIG,
    ...newLayout,
  }
}

export function setDashboardObject (
  key: string,
  dashboardObject: any,
  dashboardId: string,
  storeOnly = false,
) {
  return {
    types: [
      VisActionsEnum.ACTION_UPDATE_DASHBOARD_REQUEST,
      VisActionsEnum.ACTION_UPDATE_DASHBOARD_SUCCESS,
      VisActionsEnum.ACTION_UPDATE_DASHBOARD_ERROR,
    ],
    promise: (
      client: any,
      { visualization, application: { main: { authenticationData, appState } } }: DefaultState,
    ) => {
      const viewsObjectCopy = { ...(visualization.viewsObject[key] || {}) }

      viewsObjectCopy.dashboards = {
        ...((visualization.viewsObject[key] || {}).dashboards || {}),
        [dashboardId]: dashboardObject,
      }

      const viewsObject = {
        ...visualization.viewsObject,
        [key]: {
          ...viewsObjectCopy,
        },
      }

      const currentDashboard = {
        ...visualization.currentDashboard,
        [key]: dashboardId,
      }

      const { config } = (visualization.visualizationMetaInformation || {})[appState] || {}

      return storeOnly || (!config && window.isElectron && (!authenticationData || !authenticationData.featureFlags))
        ? Promise.resolve({
          viewsObject,
          currentDashboard,
        })
        : client
          .patch(`${Network.URI}/visualization_config/${config}/viewsObject`, {
            data: {
              d: 3,
              viewsObject,
            },
          })
          .then(() => ({
            viewsObject,
            currentDashboard,
          }))
          .catch((error: any) => {
            throw new Error(error)
          })
    },
  }
}

export function setComparisonCasters (
  key: string,
  selectedComparisonCasters: string[],
  storeOnly = false,
) {
  return {
    types: [
      VisActionsEnum.ACTION_UPDATE_COMPARISON_CASTERS_REQUEST,
      VisActionsEnum.ACTION_UPDATE_COMPARISON_CASTERS_SUCCESS,
      VisActionsEnum.ACTION_UPDATE_COMPARISON_CASTERS_ERROR,
    ],
    promise: (
      client: any,
      { visualization, application: { main: { authenticationData, appState } } }: DefaultState,
    ) => {
      const viewsObjectCopy = { ...(visualization.viewsObject[key] || {}) }

      const viewsObject = {
        ...visualization.viewsObject,
        [key]: {
          ...viewsObjectCopy,
          selectedComparisonCasters,
        },
      }

      const { config } = (visualization.visualizationMetaInformation || {})[appState] || {}

      return storeOnly || (!config && window.isElectron && (!authenticationData || !authenticationData.featureFlags))
        ? Promise.resolve({
          viewsObject,
        })
        : client
          .patch(`${Network.URI}/visualization_config/set_comparison_casters/${config}`, {
            data: {
              d: 3.5,
              data: viewsObject, // TODO: this is wrong! wrap with {}
            },
          })
          .then(() => ({
            viewsObject,
          }))
          .catch((error: any) => {
            throw new Error(error)
          })
    },
  }
}

export function addPlotTile (viewId: string, dashboardId: string, plotParams: any) {
  const tileId = `tile_${uuid()}`

  return {
    type: VisActionsEnum.ACTION_ADD_PLOT_TILE,
    viewId,
    dashboardId,
    tileId,
    plotParams: {
      tileId,
      ...Util.defaultTileConfig,
      ...plotParams,
      id: tileId,
    },
  }
}

export function deletePlotTile (tileId: string) {
  return {
    type: VisActionsEnum.ACTION_DELETE_PLOT_TILE,
    tileId,
  }
}

export function splitView (key: string, direction: string) {
  return {
    type: VisActionsEnum.ACTION_SPLIT_VIEW,
    key,
    direction,
  }
}

export function deleteSplitView (key: string) {
  return {
    type: VisActionsEnum.ACTION_DELETE_SPLIT_VIEW,
    key,
  }
}

export function setCurrentDashboard (key: string, dashboardId: string) {
  return {
    type: VisActionsEnum.ACTION_SET_CURRENT_DASHBOARD,
    key,
    dashboardId,
  }
}

export function deleteDashboard (key: string, dashboardId: string) {
  return {
    type: VisActionsEnum.ACTION_DELETE_DASHBOARD,
    key,
    dashboardId,
  }
}

export function showDeleteDashboardDialog (key?: string, dashboardId?: string) {
  const deleteDashboardObject:any = {}

  if (key && dashboardId) {
    deleteDashboardObject.key = key
    deleteDashboardObject.dashboardId = dashboardId
  }

  return {
    type: VisActionsEnum.ACTION_SHOW_DELETE_DASHBOARD_DIALOG,
    deleteDashboardObject,
  }
}

export function changeTabs (
  addedIndex: number,
  removedIndex: number,
  viewId: string,
  dashboardId: string,
  dashboardData: any,
) {
  return {
    type: VisActionsEnum.ACTION_CHANGE_TABS,
    addedIndex,
    removedIndex,
    viewId,
    dashboardId,
    dashboardData,
  }
}

export function saveTileConfig (tileConfig: Partial<TileConfig>) {
  return {
    type: VisActionsEnum.ACTION_SAVE_TILE_CONFIG,
    tileConfig,
  }
}

export function openSelectSourceDialog (open = false) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG,
    open,
  }
}

export function openPlotExportDialog (open = true) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG,
    open,
  }
}

export function storeNetworkStatus (status: string) {
  return {
    type: VisActionsEnum.ACTION_STORE_NETWORK_STATUS,
    status,
  }
}

export function updatedPlot (isNewData = false) {
  return {
    type: VisActionsEnum.ACTION_SET_NEW_DATA_STATUS,
    isNewData,
  }
}

export function addHdf5Schema (hdf5Schema: string) {
  return {
    type: VisActionsEnum.ACTION_ADD_HDF5_SCHEMA,
    hdf5Schema,
  }
}

export function setHdf5Schema (hdf5Schema: string) {
  return {
    type: VisActionsEnum.ACTION_SET_HDF5_SCHEMA,
    hdf5Schema,
  }
}

export function removeHdf5Schema (hdf5Schema: string) {
  return {
    type: VisActionsEnum.ACTION_REMOVE_HDF5_SCHEMA,
    hdf5Schema,
  }
}

export function autoscaleGrid (
  origLayout: Array<any>,
  viewId: string,
  dashboardId: string,
  fullHeight: number,
  fullWidth: number,
  height: number,
  width: number,
  fullGridSize: number,
) {
  return {
    type: VisActionsEnum.ACTION_AUTOSCALE_GRID,
    origLayout,
    viewId,
    dashboardId,
    fullHeight,
    fullWidth,
    height,
    width,
    fullGridSize,
  }
}

export function resetAutoscaling (
  origLayout: Array<any>,
  viewId: string,
  dashboardId: string,
) {
  return {
    type: VisActionsEnum.ACTION_RESET_AUTOSCALING,
    origLayout,
    viewId,
    dashboardId,
  }
}

export function setVisualizationMetaInformation (name: string, value: string, appState: number) {
  return {
    type: VisActionsEnum.ACTION_VISUALIZATION_META_INFORMATION,
    name,
    value,
    appState,
  }
}

export function updateCommandTile (commandId: string) {
  return {
    type: VisActionsEnum.ACTION_UPDATE_COMMAND_TILE,
    commandId,
  }
}

export function resetVisData () {
  return {
    type: VisActionsEnum.ACTION_RESET_VIS_DATA,
  }
}

export function resetViewObject () {
  return {
    type: VisActionsEnum.ACTION_RESET_VIEW_OBJECT,
  }
}
