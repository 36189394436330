import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from 'store/visualization/actions'
import { FormWrapper, InputWrapper } from '../../Dialogs/DialogStyles'
import Input from 'react/specific/Input'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import Button from 'react/components/Button'
import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import { AppState } from 'store/application/main/consts'
import FeatureFlags from 'react/FeatureFlags'
import { PlotConfig, TileConfig } from 'types/visualization'

const connector = connect(({ visualization, application }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  visualizationMetaInformation: visualization.visualizationMetaInformation,
  featureFlags: application.main.authenticationData.featureFlags,
  appState: application.main.appState,
}), {
  savePlotConfig: VisualizationActions.savePlotConfig,
  saveTileConfig: VisualizationActions.saveTileConfig,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  setEditIdToNull?: () => void
  allowDeletion?: boolean
  fullscreen: boolean
  t: Translation
}

type State = {
  tileConfig: TileConfig,
  plotConfig: PlotConfig
  preventSave: boolean,
};

class ExtendedTab extends Component<Props, State> {
  tileConfigTimeout?: number;
  plotConfigTimeout?: number;

  constructor (props: Props) {
    super(props)
    const { tileConfigs, currentTileId = '', plotConfigs, editDialogConfigId } = props

    this.state = {
      tileConfig: { ...((tileConfigs || {})[currentTileId || ''] || {}), id: currentTileId },
      plotConfig: plotConfigs[editDialogConfigId],
      preventSave: false,
    }
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props, prevState: State) {
    const { saveTileConfig, savePlotConfig, plotConfigs, editDialogConfigId } = this.props
    const { tileConfig, plotConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.tileConfigTimeout)

      this.tileConfigTimeout = window.setTimeout(() => {
        if (tileConfig && tileConfig.id) {
          saveTileConfig(tileConfig)
          this.tileConfigTimeout = undefined
        }
      }, 1000)
    }

    if (!plotConfig.equals(prevState.plotConfig)) {
      clearTimeout(this.plotConfigTimeout)

      if (!plotConfigs[editDialogConfigId]) {
        return
      }

      this.plotConfigTimeout = window.setTimeout(() => {
        if (plotConfig && plotConfig.id) {
          savePlotConfig(plotConfig)
          this.plotConfigTimeout = undefined
        }
      }, 1000)
    }
  }

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig, preventSave } = this.state

    if (tileConfig && tileConfig.id && this.tileConfigTimeout) {
      clearTimeout(this.tileConfigTimeout)
      saveTileConfig(tileConfig)
    }

    if (plotConfig && plotConfig.id && !preventSave && this.plotConfigTimeout) {
      clearTimeout(this.plotConfigTimeout)
      savePlotConfig(plotConfig)
    }
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value, checked } = event.target

    this.setState(({ plotConfig }) => ({
      plotConfig: {
        ...plotConfig,
        [name]: name === 'flipAxes' ? checked : value,
      },
    }))
  };

  // @AnalyzeTime(0)
  handleInputDataTile = (event: any) => {
    const { name, value, checked } = event.target

    let val: any = value

    const booleanValues = [ 'colorBar', 'showLine', 'showLegend', 'followPasslnCoord' ]

    if (booleanValues.includes(name)) {
      val = checked
    }

    this.setState(({ tileConfig }) => ({
      tileConfig: {
        ...tileConfig,
        [name]: val,
      },
    }))
  };

  handleDeleteMergedPlot = () => {
    const { visualizationMetaInformation, setConfig, editDialogConfigId, setEditIdToNull } = this.props
    const { config: configId } = (visualizationMetaInformation || {})[AppState.Caster] || {}

    ApiClient
      .del(`${Network.URI}/visualization_config/delete_merged_plot/${configId}/${editDialogConfigId}`)
      .then((visualizationConfig) => {
        this.setState({ preventSave: true })
        setConfig(visualizationConfig.data)
      })

    if (setEditIdToNull) {
      setEditIdToNull()
    }
  }

  @AnalyzeTime(0)
  render () {
    const {
      fullscreen,
      t,
      allowDeletion,
      editDialogConfigId,
      plotConfigs,
      featureFlags,
      visualizationMetaInformation,
      appState,
    } = this.props
    const { plotConfig, tileConfig } = this.state

    if (!plotConfig || !tileConfig) {
      return null
    }

    const { gageLabel, flipAxes } = plotConfig
    const {
      type,
      showLine,
      colorBar,
      showLegend,
      followPasslnCoord,
      xLabel,
      yLabel,
      numberOfPointsBeforeAndAfterPasslnCoord = 1,
      xAxisPadding = 0,
      yAxisPadding = 0,
    } = tileConfig

    if (type === 'gage') {
      return (
        <FormWrapper fullscreen={fullscreen}>
          <InputWrapper fullscreen={fullscreen}>
            <Input
              label={t('plotConfig.gageLabel.label')}
              title={t('plotConfig.gageLabel.title')}
              name='gageLabel'
              type='text'
              value={gageLabel || ''}
              onChange={this.handleInput}
            />
          </InputWrapper>
        </FormWrapper>
      )
    }

    const currentConfig = plotConfigs[editDialogConfigId]
    const disabledEdition = !FeatureFlags.canEditDynamicData(featureFlags, visualizationMetaInformation, appState)

    return (
      <FormWrapper fullscreen={fullscreen}>
        {
          !fullscreen &&
            <InputWrapper fullscreen={fullscreen}>
              <Input
                label={t('plotConfig.xLabel.label')}
                title={t('plotConfig.xLabel.title')}
                name='xLabel'
                type='text'
                value={xLabel || ''}
                onChange={this.handleInputDataTile}
              />
              <Input
                label={t('plotConfig.yLabel.label')}
                title={t('plotConfig.yLabel.title')}
                name='yLabel'
                type='text'
                value={yLabel || ''}
                onChange={this.handleInputDataTile}
              />
            </InputWrapper>
        }
        <InputWrapper fullscreen={fullscreen}>
          <Input
            label={t('plotConfig.flipAxes.label')}
            title={t('plotConfig.flipAxes.title')}
            name='flipAxes'
            type='checkbox'
            value={flipAxes || false}
            onChange={this.handleInput}
            disabled={disabledEdition}
            style={{ paddingLeft: '10px' }}
          />
          <Input
            label={t('plotConfig.showLine.label')}
            title={t('plotConfig.showLine.title')}
            name='showLine'
            type='checkbox'
            value={showLine || false}
            onChange={this.handleInputDataTile}
            disabled={disabledEdition}
            style={{ paddingLeft: '10px' }}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            label={t(`plotConfig.${type === 'contour' ? 'showColorBar' : 'showLegend'}.label`)}
            title={t(`plotConfig.${type === 'contour' ? 'showColorBar' : 'showLegend'}.title`)}
            name={type === 'contour' ? 'colorBar' : 'showLegend'}
            type='checkbox'
            value={(type === 'contour' ? colorBar : showLegend) || false}
            onChange={this.handleInputDataTile}
            disabled={disabledEdition}
            style={{ paddingLeft: '10px' }}
          />
          <Input
            label={t('plotConfig.followPasslnCoord.checkboxLabel')}
            title={t('plotConfig.followPasslnCoord.checkboxTitle')}
            name='followPasslnCoord'
            type='checkbox'
            value={followPasslnCoord || false}
            onChange={this.handleInputDataTile}
            disabled={disabledEdition}
            style={{ paddingLeft: '10px' }}
          />
        </InputWrapper>
        <InputWrapper half={!followPasslnCoord}>
          <Input
            label='Axes Padding'
            title={t('plotConfig.followPasslnCoord.amountOfPointsTitle')}
            name='axesPadding'
            type='axesPadding'
            xAxisPadding={xAxisPadding}
            yAxisPadding={yAxisPadding}
            onChange={this.handleInputDataTile}
            disabled={disabledEdition}
          />
          {
            followPasslnCoord && (
              <>
                <Input
                  label={t('plotConfig.followPasslnCoord.amountOfPointsLabel')}
                  title={t('plotConfig.followPasslnCoord.amountOfPointsTitle')}
                  name='numberOfPointsBeforeAndAfterPasslnCoord'
                  type='number'
                  value={numberOfPointsBeforeAndAfterPasslnCoord || 0}
                  onChange={this.handleInputDataTile}
                  disabled={disabledEdition}
                />
              </>
            )
          }
        </InputWrapper>
        {
          allowDeletion && currentConfig.isMergedDynamicDataSource && (
            <Button
              title='Delete Merged Plot'
              onClick={this.handleDeleteMergedPlot}
              disabled={!FeatureFlags.canDeleteDynamicData(featureFlags, visualizationMetaInformation, appState)}
            >
              Delete Merged Plot
            </Button>)
        }
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(ExtendedTab as any) as any) as any
