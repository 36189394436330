import TextureUtil from 'three/logic/TextureUtil'
import Mold from 'three/objects/Mold'
import { AnalyzeTime } from 'Util'
import SectionView from '.'
import DrawHandlers from './DrawHandlers'

export default class ToggleHandlers {
  @AnalyzeTime(0)
  static expandSectionView (view: SectionView) {
    // toggles the expanded section view
    if (!view.strand || !view.views || !view.views.mainView) {
      return
    }

    view.sectionViewExpanded = !view.sectionViewExpanded
    view.strand.visible = view.sectionViewExpanded

    if (view.minStrand) {
      view.minStrand.visible = view.sectionViewExpanded && !!Mold.minWidth
    }

    if (view.maxStrand) {
      view.maxStrand.visible = view.sectionViewExpanded && !!Mold.maxWidth
    }

    const { width, height } = view.views.mainView.viewport

    view.resize(width, height)

    view.buttons.expandSectionViewButton.userData.tooltip = view.sectionViewExpanded ? 'Collapse' : 'Expand'

    DrawHandlers.drawSwitcherIcon(
      view,
      null,
      null,
      'expandSectionViewButton',
      'expandSectionView',
      view.sectionViewExpanded,
      TextureUtil.load('textures/ui/collapse.png'),
      TextureUtil.load('textures/ui/expand.png'),
      view.sectionViewExpanded ? 'Collapse' : 'Expand',
      false,
    )

    Object.keys(view.buttons).forEach(name => {
      if (name === 'expandSectionViewButton') {
        return
      }

      if (name === 'RollerNozzleSwitcher' || name === 'switcherPrevNextButton') {
        return (view.buttons[name].visible = view.viewMode && view.sectionViewExpanded)
      }

      view.buttons[name].visible = view.sectionViewExpanded
    })
  }

  @AnalyzeTime(0)
  static togglePrevNextElements (view: SectionView) {
    // Toggles if the extra elements shown are from the next layer or the previous
    view.statusPrevNext = !view.statusPrevNext

    DrawHandlers.drawSwitcherWithBackground(
      view,
      null,
      null,
      'switcherPrevNextButton',
      'togglePrevNextElements',
      view.statusPrevNext,
          `Additional Layer: ${!view.statusPrevNext ? 'Next' : 'Prev'}`,
          TextureUtil.load('textures/ui/toggle-prev.png'),
          TextureUtil.load('textures/ui/toggle-next.png'),
    )

    DrawHandlers.drawNextElements(view)
  }

  @AnalyzeTime(0)
  static toggleShowedElements (view: SectionView) {
    // toggles if the shown elements are nozzles or rollers
    view.additionalLayerType = view.additionalLayerType === 'Nozzle' ? 'Roller' : 'Nozzle'

    DrawHandlers.drawSwitcherWithBackground(
      view,
      null,
      null,
      'RollerNozzleSwitcher',
      'toggleShowedElements',
      view.additionalLayerType === 'Roller',
          `Additional Layer Type: ${view.additionalLayerType}`,
          TextureUtil.load('textures/ui/toggle-roller.png'),
          TextureUtil.load('textures/ui/toggle-nozzle.png'),
    )

    DrawHandlers.drawNextElements(view)
  }

  @AnalyzeTime(0)
  static toggleSides (view: SectionView, side: string) {
    // Toggles the using of sides of snaps
    (view.side as any)[side] = !(view.side as any)[side]

    DrawHandlers.drawSwitcher(
      view,
      null,
      0,
      side,
      `toggle${side}`,
      (view.side as any)[side],
      false,
      false,
      `Use passLineCoordinates, ${side}`,
    )
  }

  @AnalyzeTime(0)
  static toggleViewMode (view: SectionView) {
    // Toggles the details section in section view
    view.viewMode = !view.viewMode

    DrawHandlers.drawSwitcherIcon(
      view,
      null,
      null,
      'ViewMode',
      'toggleViewMode',
      view.viewMode,
      TextureUtil.load('textures/ui/eyeOpen.png'),
      TextureUtil.load('textures/ui/eyeClose.png'),
      'Details',
      false,
    )

    view.buttons.RollerNozzleSwitcher.visible = view.viewMode
    ToggleHandlers.toggleSpecificElement(
      view.buttons.RollerNozzleSwitcher.children,
      'RollerNozzleSwitcherSwitcher',
      view.viewMode,
    )
    view.buttons.switcherPrevNextButton.visible = view.viewMode
    ToggleHandlers.toggleSpecificElement(
      view.buttons.switcherPrevNextButton.children,
      'switcherPrevNextButtonSwitcher',
      view.viewMode,
    )

    if (view.additionalLayerType === 'Roller') {
      view.rollerGroup.visible = view.viewMode
    }
    else {
      view.nozzleGroup.visible = view.viewMode
    }
  }

  @AnalyzeTime(0)
  static toggleSpecificElement (children: any, name: string, visible: boolean) {
    for (let i = 0; i < children.length; i++) {
      if (children[i].name === name) {
        children[i].visible = visible
        break
      }
    }
  }
}
