/* eslint-env browser */

import { AnalyzeTime } from 'Util'
import SVG from './SVG'

type AdditionalData = {
  xDomain: Array<number>,
  yDomain: Array<number>,
  flipAxes: boolean,
  traceCount: number
};

export default class FastBase {
  static TRACE_MISSING = 'TRACE_MISSING';

  constructor (
    traceClassName: string,
    traceGroupClassName: string,
    traceContainerClassName: string,
    subElementName?: string,
  ) {
    this.previousValues = []

    this.traceClassName = traceClassName // 'js-line' | 'js-fill' | 'point'
    this.traceGroupClassName = traceGroupClassName // 'lines' | 'fills' | 'points'
    this.traceContainerClassName = traceContainerClassName // 'trace'
    this.subElementName = subElementName || '' // 'path' | ''
  }

  previousValues: Array<Array<number>>;
  additionalData?: AdditionalData
  traceClassName: string;
  traceGroupClassName: string;
  traceContainerClassName: string;
  subElementName: string;

  xMin?: number
  yMin?: number
  xRange?: number
  yRange?: number
  xScale?: number
  yScale?: number
  xOffset?: number
  yOffset?: number

  @AnalyzeTime(0)
  _calcNeededValues (plot: any, data: any) {
    if (!this.additionalData) {
      return
    }

    const { xDomain, yDomain } = this.additionalData

    const [ xMin, xMax ] = xDomain
    const [ yMin, yMax ] = yDomain

    this.xMin = xMin
    this.yMin = yMin

    this.xRange = xMax - xMin
    this.yRange = yMax - yMin

    const xAxis: string = (data.xaxis || 'x').replace(/([xy])(\d*)/, '$1axis$2')
    const yAxis: string = (data.yaxis || 'y').replace(/([xy])(\d*)/, '$1axis$2')
    const [ xMinL, xMaxL ] = plot.layout[xAxis].range
    const [ yMinL, yMaxL ] = plot.layout[yAxis].range

    this.xScale = this.xRange / (xMaxL - xMinL)
    this.yScale = this.yRange / (yMaxL - yMinL)

    this.xOffset = xMinL - xMin
    this.yOffset = yMinL - yMin
  }

  @AnalyzeTime(0)
  _calcX (x: number, width: number) {
    const { xMin = 0, xRange = 0, xScale = 0, xOffset = 0 } = this
    const denominator = xRange / width

    // instead of returning infinity return 0
    if (!denominator) {
      return 0
    }

    return ((x - xOffset - xMin) / denominator) * xScale
  }

  @AnalyzeTime(0)
  _calcY (y: number, height: number) {
    const { yMin = 0, yRange = 0, yScale = 0, yOffset = 0 } = this
    const denominator = yRange / height

    // instead of returning infinity return 0
    if (!denominator) {
      return 0
    }

    return height - (((y - yOffset - yMin) / denominator) * yScale)
  }

  @AnalyzeTime(0)
  _buildPathD (_x: Array<number>, _y: Array<number>, _width: number, _height: number): string {
    throw new Error('FastBase _buildPathD is abstract!')
  }

  @AnalyzeTime(0)
  _createPathPart (
    container: Element,
    x: Array<number>,
    y: Array<number>,
    style: string,
    width: number,
    height: number,
    traceIndex: number,
    partIndex: number,
  ): void {
    const path = SVG.getPath()

    path.setAttribute('id', `fast-update-${this.traceClassName}-${traceIndex}-${partIndex}`)
    path.setAttribute('class', `${this.traceClassName} fast-update`)
    path.setAttribute('style', style)

    path.setAttributeNS(null, 'd', this._buildPathD(x, y, width, height))

    container.appendChild(path)
  }

  @AnalyzeTime(0)
  _getStyles (plot: HTMLElement): Array<string> {
    const lines = plot.querySelectorAll(`.${this.traceClassName}:not(.fast-update) ${this.subElementName}`)

    return Array.prototype.map.call(lines, line => line.getAttribute('style')) as string[]
  }

  @AnalyzeTime(0)
  _getMeta (plot: HTMLElement): {
    width: number,
    height: number
  } {
    const plotElement = plot.getElementsByClassName('bglayer')

    const { width, height } = plotElement[0].getBoundingClientRect()

    return {
      width,
      height,
    }
  }

  @AnalyzeTime(0)
  _createTrace (plot: HTMLElement, linesContainer: Element, data: any, style: string, traceIndex: number) {
    const { x, y } = data
    const { width, height } = this._getMeta(plot)
    const length: number = y.length
    const step = Math.ceil(length / 3)

    this._calcNeededValues(plot, data)

    let prevEnd = 0

    for (let i = 0; i < length; i += step) {
      const len = i + step < length ? i + step : length
      const partIndex = Math.floor(i / step)

      this._createPathPart(
        linesContainer,
        x.slice(prevEnd, len),
        y.slice(prevEnd, len),
        style,
        width,
        height,
        traceIndex,
        partIndex,
      )

      prevEnd += i === 0 ? step - 1 : step
    }
  }

  @AnalyzeTime(0)
  _getSortedData (data: Array<any>, plot: HTMLElement): Array<any> {
    const traces = plot.querySelectorAll(`.${this.traceContainerClassName} ${this.subElementName}`)

    return Array.prototype.map.call(traces || [], (trace: HTMLElement) => {
      const d = data.filter(d => Array.prototype.includes.call(
        trace.classList || [],
        `${this.traceContainerClassName}${d.uid}`,
      ))[0]

      if (!d) {
        throw new Error(FastBase.TRACE_MISSING)
      }

      return d
    })
  }

  @AnalyzeTime(0)
  _create (plot: HTMLElement, data: Array<any>) {
    const linesContainer = plot.getElementsByClassName(this.traceGroupClassName)
    const styles = this._getStyles(plot)
    const sortedData: Array<any> = this._getSortedData(data, plot)

    for (let i = 0; i < sortedData.length; i++) {
      this._createTrace(plot, linesContainer[i], sortedData[i], styles[i].replace(/visibility: ?hidden;?/, ''), i)

      plot.querySelectorAll<HTMLElement>(`
        .${this.traceContainerClassName}:nth-of-type(${i + 1})
        .${this.traceClassName}:not(.fast-update) ${this.subElementName}
      `).forEach(line => {
        line.style.visibility = 'hidden'
      })
    }

    this.previousValues = sortedData.map(({ y }) => y)
  }

  @AnalyzeTime(0)
  _hasChanges (start: number, end: number, previousValues: Array<number>, data: any) {
    if (previousValues) {
      const axisToCompare: Array<number> = this.additionalData?.flipAxes ? data.x : data.y

      for (let j = start; j < end; j++) {
        if (axisToCompare[j] !== previousValues[j]) {
          return true
        }
      }
    }

    return false
  }

  @AnalyzeTime(0)
  _updateTrace (plot: HTMLElement, data: any, previousValues: Array<number>, traceIndex: number) {
    const { x, y } = data
    const { width, height } = this._getMeta(plot)
    const length: number = y.length
    const step = Math.ceil(length / 3)

    this._calcNeededValues(plot, data)

    let prevEnd = 0

    for (let i = 0; i < length; i += step) {
      const end = i + step < length ? i + step : length

      if (this._hasChanges(prevEnd, end, previousValues, data)) {
        const partIndex = Math.floor(i / step)
        const line = plot
          .querySelector(`#fast-update-${this.traceClassName}-${traceIndex}-${partIndex} ${this.subElementName}`)

        if (!line) {
          return
        }

        line.setAttributeNS(
          null,
          'd',
          this._buildPathD(x.slice(prevEnd, end), y.slice(prevEnd, end), width, height),
        )
      }

      prevEnd += i === 0 ? step - 1 : step
    }
  }

  @AnalyzeTime(0)
  _update (plot: HTMLElement, data: Array<any>) {
    const sortedData: Array<any> = this._getSortedData(data, plot)

    for (let i = 0; i < sortedData.length; i++) {
      this._updateTrace(plot, sortedData[i], this.previousValues[i], i)
    }

    this.previousValues = sortedData.map(({ y }) => y)
  }

  @AnalyzeTime(0)
  draw (
    plot: HTMLElement,
    data: Array<any>,
    xDomain: Array<number>,
    yDomain: Array<number>,
    flipAxes: boolean,
    _layout?: any, // needed because class shape extends this one
  ) {
    this.additionalData = {
      xDomain,
      yDomain,
      flipAxes,
      traceCount: data.length,
    }

    const rawLines = plot.querySelectorAll(`.${this.traceClassName}.fast-update`)
    const lines = Array.from(rawLines)

    if (lines.length === 0) {
      return this._create(plot, data)
    }

    this._update(plot, data)
  }
}
