import ConditionUtil from './ConditionUtil'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TWEEN from '@tweenjs/tween.js'
import * as THREE from 'three'
import Util from '../../logic/Util'
import FilterHandler from '../../logic/FilterHandler'
import CalculationUtil from './CalculationUtil'
import MainView from '.'
import { Material, PerspectiveCamera, Vector3 } from 'three'
import { AnalyzeTime } from 'Util'
import type { ElementsHashes } from 'types/state'

export default class Getters {
  @AnalyzeTime(0)
  static getContainer (view: MainView, container: any, caster: THREE.Group, path: string, type: string, element: any) {
    if (type === 'SegmentGroup' || type === 'Strand') {
      container = caster
    }

    if (type === 'Strand') {
      if (!view.containerList[type]) {
        view.containerList[type] = new THREE.Group()
        view.containerList[type].userData = {
          type,
        }
      }

      container.add(view.containerList[type])

      return view.containerList[type]
    }

    if (!view.containerList[type]) {
      view.containerList[type] = {}
    }

    if (!view.containerList[type][path]) {
      view.containerList[type][path] = new THREE.Group()
      view.containerList[type][path].userData = {
        path,
        type,
      }

      if (type === 'Segment') {
        view.containerList[type][path].userData.side = element._FixedLooseSide
        view.containerList[type][path].userData.angle = Util.sides2Angles(element._FixedLooseSide)
        Getters.generateSideLabel(view, Util.sides2Angles(element._FixedLooseSide))
      }

      if (container.userData.type === type) {
        const parentInfo = Util.getParentInfo(path)

        view.containerList[parentInfo.type][parentInfo.path].add(view.containerList[type][path])
      }
      else {
        container.add(view.containerList[type][path])
      }
    }

    return view.containerList[type][path]
  }

  @AnalyzeTime(0)
  static getSelectedElementsPaths (camera: PerspectiveCamera, min: Coord, max: Coord, clickableObjects: any[]) {
    const frustum = new THREE.Frustum()
    const cameraViewProjectionMatrix = new THREE.Matrix4()

    camera.updateMatrixWorld()
    camera.matrixWorldInverse.getInverse(camera.matrixWorld)
    cameraViewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    (frustum as any).setFromMatrix(cameraViewProjectionMatrix)

    const elementPaths = clickableObjects
      .filter(object => frustum.intersectsObject(object))
      .filter(object => Util.isVisible(object))
      .map(object => {
        const vector = new THREE.Vector3()

        object.updateMatrixWorld()
        vector.setFromMatrixPosition(object.matrixWorld)

        vector.project(camera);

        (object as any).posOnScreen = {
          x: vector.x,
          y: -vector.y,
        }

        return object
      })
      .filter(({ posOnScreen }: any) => ConditionUtil.selectionIsOnScreen(min, max, posOnScreen))
      .filter(object => object.type !== 'Strand' && object.type !== 'SegmentLabel')
      .map(object => object.path)

    return elementPaths
  }

  @AnalyzeTime(0)
  static generateSideName (font: any, fontMaterial: Material, plHeight: number, n: number) {
    const sideName = Util.getText(Util.angles2Sides(n.toString()), 0.15, !!font, !!fontMaterial)

    sideName.position.set(0, plHeight + 0.45 + 0.5, 0)

    return sideName
  }

  @AnalyzeTime(0)
  static generateAngleText (font: any, fontMaterial: Material, plHeight: number, n: number) {
    const angleText = Util.getText(`${n}°`, 0.3, !!font, !!fontMaterial)

    angleText.position.set(0.06, plHeight + 0.45, 0)

    return angleText
  }

  @AnalyzeTime(0)
  static generateLine (labelLineMaterial: Material, plHeight: number) {
    const geometry = new (THREE as any).Geometry()

    geometry.vertices.push(
      new THREE.Vector3(0, 0, -1),
      new THREE.Vector3(0, plHeight + 0.25, -1),
    )

    return new THREE.Line(geometry, labelLineMaterial)
  }

  @AnalyzeTime(0)
  static generateGroupWithSideLabel (
    font: any,
    fontMaterial: Material,
    plHeight: number,
    n: number,
    labelLineMaterial: Material,
    side: string,
  ) {
    const group = new THREE.Group()

    group.visible = false
    group.name = `SideLabel_${side}`

    group.add(Getters.generateSideName(font, fontMaterial, plHeight, n))
    group.add(Getters.generateAngleText(font, fontMaterial, plHeight, n))
    group.add(Getters.generateLine(labelLineMaterial, plHeight))

    return group
  }

  @AnalyzeTime(0)
  static getSegmentsFromSegmentGroup (
    elementsHashes: ElementsHashes,
    term: string,
    containerList: any,
  ) {
    const filteredElements = FilterHandler
      .getFilteredElements(elementsHashes, term, false) as Record<string, FilterableElementType>

    const segmentElements = Object.entries(filteredElements)
      .filter(([ _path, type ]) => type === 'Segment')
      .map(([ path ]) => ({ path }))

    return segmentElements.map((element: any) => containerList.Segment[element.path])
  }

  @AnalyzeTime(0)
  static getCameraTween (
    view: MainView,
    pos: Vector3,
    target: Vector3,
    xAngle: number | undefined,
    yAngle: number | undefined,
    center: Vector3,
    position: Vector3,
    duration: number,
    callback: () => void,
  ) {
    const calc = CalculationUtil.calcTween(pos, target, xAngle, yAngle, center, position)

    return new TWEEN.Tween(calc.tweenData)
      .to(calc.tweenTargetData, duration)
      .onUpdate(() => {
        const { xC, yC, zC, len, xR, yR } = calc.tweenData
        const c = new THREE.Vector3(xC, yC, zC)
        let p = calc.currentPosInCenter.clone()

        p.applyAxisAngle(Util.yAxis, -calc.currentRotations.y)
        p.applyAxisAngle(Util.xAxis, xR)
        p.applyAxisAngle(Util.yAxis, yR + calc.currentRotations.y)

        p = p.setLength(len).add(c).clone()

        view.setCameraPosition(p, c, view.camera, view.controls)
      })
      .onStop(callback)
      .onComplete(() => {
        if (view.camera) {
          view.oldCameraPosition = view.camera.position.clone()
        }

        view.tweenActive = false

        callback()
      })
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  @AnalyzeTime(0)
  static generateSideLabel (view: MainView, n: number) {
    const side = Util.angles2Sides(n.toString())

    if (view.sideLabels[side]) {
      view.scene.remove(view.sideLabels[side])
    }

    const group = Getters.generateGroupWithSideLabel(
      view.font,
      view.fontMaterial,
      view.plHeight,
      n,
      view.labelLineMaterial,
      side,
    )

    Util.addOrReplace(view.scene, group)
    view.sideLabels[side] = group
  }
}
