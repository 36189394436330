import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

import Btn, { ConfirmWrapper, BtnWrapper, ErrorMessage, Label } from './styles'

type Props = {
  children: any
  type?: 'primary' | 'error' | 'secondary'
  overrideColor?: string
  overrideHoverColor?: string
  confirm?: string | true
  title?: string
  error?: string
  icon?: string
  label?: string
  loading?: boolean
  disabled?: boolean
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  right?: boolean
  noMargin?: boolean
  nextToDropDown?: boolean
  noSuccessIcon?: boolean
  hideError?: boolean
  isRef?: boolean
  bold?: boolean
  textPaddingLeft?: boolean
  height?: number
  ref?: React.RefObject<unknown>
  onClick: (event: React.MouseEvent) => void
  onBeginConfirm?: (event?: React.MouseEvent) => void
  onCancel?: (event?: React.MouseEvent) => void
  t: Translation
};

type State = {
  success: boolean,
  error: boolean,
  confirmActive: boolean
};

class Button extends Component<Props, State> {
  static defaultProps = {
    type: 'primary',
    isRef: false,
  }

  state = {
    success: false,
    error: false,
    confirmActive: false,
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: any, prevState: any): void {
    const { loading, error } = this.props

    if (!loading && prevProps.loading) {
      let timeout = 1000

      if (error) {
        this.setState({
          error: true,
        })

        timeout = 5000
      }
      else {
        this.setState({
          success: true,
        })
      }

      setTimeout(() => {
        this.setState({
          success: false,
          error: false,
        })
      }, timeout)
    }
  }

  // @AnalyzeTime(0)
  handleClick = (event: React.MouseEvent) => {
    const { confirmActive } = this.state
    const { confirm, onClick, onBeginConfirm } = this.props

    if (confirm && !confirmActive) {
      this.setState({
        confirmActive: true,
      })

      if (onBeginConfirm) {
        onBeginConfirm(event)
      }

      return
    }

    this.setState({
      success: false,
      error: false,
      confirmActive: false,
    })

    onClick(event)
  };

  // @AnalyzeTime(0)
  handleCancel = (event?: React.MouseEvent) => {
    const { onCancel } = this.props

    this.setState({ confirmActive: false })

    if (onCancel) {
      onCancel(event)
    }
  };

  // @AnalyzeTime(0)
  setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  };

  @AnalyzeTime(0)
  render () {
    const { confirmActive } = this.state
    const {
      children,
      confirm,
      title,
      disabled,
      half,
      oneThird,
      twoThirds,
      noMargin,
      right,
      nextToDropDown,
      noSuccessIcon,
      error: errorCode,
      loading,
      type,
      icon,
      label,
      hideError,
      isRef,
      bold,
      textPaddingLeft,
      height,
      ref,
      overrideColor,
      overrideHoverColor,
      t,
    } = this.props

    if (confirmActive) {
      return (
        <ConfirmWrapper>
          <Button
            title={title}
            onClick={this.handleClick}
            half
            t={t}
            type='secondary'
            bold={bold}
            height={height}
          >
            {typeof confirm === 'string' ? confirm : t('button.confirm')}
          </Button>
          <Button
            onClick={this.handleCancel}
            half
            t={t}
            bold={bold}
            height={height}
          >
            {t('button.cancel')}
          </Button>
        </ConfirmWrapper>
      )
    }

    const { success, error } = this.state

    const IconHandler = success ? 'pe-7s-check' : error ? 'pe-7s-close-circle' : icon

    return (
      <BtnWrapper half={half} oneThird={oneThird} twoThirds={twoThirds} right={right} nextToDropDown={nextToDropDown}>
        {label && <Label>{label}</Label>}
        <Btn
          overrideHoverColor={overrideHoverColor}
          overrideColor={overrideColor}
          onClick={this.handleClick}
          title={errorCode || title}
          disabled={disabled}
          isLoading={loading}
          hasError={error}
          isSuccessful={success}
          type={type}
          icon={icon}
          textPaddingLeft={((icon && half) || textPaddingLeft)as boolean}
          noMargin={Boolean(noMargin || label)}
          ref={ref || (isRef ? this.setFocus : null)}
          height={height}
          bold={bold}
        >
          {(icon || error || success) && !noSuccessIcon && <div><i className={IconHandler} /></div>}
          <span>{children}</span>
        </Btn>
        {!hideError && errorCode && <ErrorMessage>{t([ `error.${errorCode}`, 'error.default' ])}</ErrorMessage>}
      </BtnWrapper>
    )
  }
}

export default withNamespaces('application')(Button as any) as any
