import UiViewHandlers from './UiViewHandlers'
import Util from '../../logic/Util'
import UIView from '.'
import Getters from './Getters'
import CalculationUtil from './CalculationUtil'
import { Vector2 } from 'three'
import { AnalyzeTime } from 'Util'
import MainUtil from '../MainView/MainHandlers'
import FeatureFlags from 'react/FeatureFlags'
import ThreeManager from 'three/ThreeManager'

export default class EventHandlers {
  @AnalyzeTime(0)
  static handleMouseDownAction (name: string, view: UIView) {
    if (!view.views || !view.views.sectionView || !view.views.mainView) {
      return
    }

    let updatePlots = false

    switch (name) {
      case 'scrollBarSlider':
        view.scrollBarButtonGroupActive = true
        view.views.mainView.controls.enableRotate = false
        break
      case 'scrollBarBtnUp':
        view.views.sectionView.jumpUp = true
        view.views.sectionView.animate()
        updatePlots = true
        break
      case 'scrollBarBtnDown':
        view.views.sectionView.jumpDown = true
        view.views.sectionView.animate()
        updatePlots = true
        break
      case 'switchScrollElement':
        UiViewHandlers.handleSwitchScrollElement(view)
        break
      default:
    }

    ThreeManager.base.renderScene()

    if (updatePlots) {
      window.dispatchEvent(new CustomEvent('FinishedScrollingPasslnCoord'))
    }
  }

  @AnalyzeTime(0)
  static handleSelectionEnd (view: UIView, mouseOnCanvas: Vector2) {
    view.views?.mainView?.handleSelection(view.downMousePosition, mouseOnCanvas)

    UiViewHandlers.hideSelectionRect(view.selectionRect)
  }

  @AnalyzeTime(0)
  static handleMouseUpAction (action: string, view: UIView, shiftClick?: boolean) {
    if (!view.views || !view.views.mainView || !view.views.sectionView) {
      return
    }

    switch (action) {
      case 'center':
        if (shiftClick) {
          view.views.mainView.setCenter()

          return
        }

        ThreeManager.base.jumpToFiltered(true)
        break
      case 'deselect':
        if (view.select) {
          view.select()
        }

        break
      case 'section':
        if (!FeatureFlags.canJumpOverSomeElement(view.featureFlags) ||
        !UiViewHandlers.getAmountOfJumpableElements(view)) {
          return
        }

        view.setButtonPos()
        view.sectionViewHandler(!view.isSectionActive)
        break
      case 'rollerSwitcher':
        if (view.select) {
          view.select()
        }

        Util.RollerMode = Util.RollerMode === 1 ? 2 : 1
        view.views.mainView.updateRoller(Util.RollerMode)

        if (Util.RollerMode === 2) {
          MainUtil.reloadFilteredElements(view.views.mainView)
        }

        if (view.setRollerVisible) {
          view.setRollerVisible(Util.RollerMode)
        }

        break
      case 'bend':
        view.views.mainView.applyCurve = !view.views.mainView.applyCurve
        view.views.mainView.updateTransforms()
        view.views.sectionView.setView(undefined, true)
        break
      case 'jumpToSection':
        UiViewHandlers.handleJumpToSection(view)
        break
      case 'toggleFilter':
        if (view.setTermDisabled) {
          view.setTermDisabled(!view.termDisabled)
        }

        break
      default:
    }

    ThreeManager.base.renderScene()
  }

  @AnalyzeTime(0)
  static handleTooltips (view: UIView, _event: any, mouseOnCanvas: Vector2) {
    const { x, y, width, height } = view.viewport
    const tooltipList = (view.tooltip as any || {})[UIView.className] || []

    if (mouseOnCanvas.x > width + x || mouseOnCanvas.y > height + y) {
      if (tooltipList.length && view.setTooltip) {
        view.setTooltip(UIView.className, false)
      }

      return
    }

    const mouse = Getters.getMouse(mouseOnCanvas, width, height, x, y)

    if (view.camera) {
      view.raycaster.setFromCamera(mouse, view.camera)
    }

    const tooltipObjects = view.tooltipObjects.filter(object => object.visible)

    const intersects = view.raycaster.intersectObjects(tooltipObjects)
    const tooltips = Getters.getIntersectedTooltips(intersects)

    if (tooltips.length) {
      if (!tooltipList.equals(tooltips) && view.setTooltip) {
        view.setTooltip(UIView.className, true, tooltips)
      }

      return
    }

    if (tooltipList.length && view.setTooltip) {
      view.setTooltip(UIView.className, false)
    }
  }

  @AnalyzeTime(0)
  static handleSelection (view: UIView, mouseOnCanvas: Vector2) {
    const x = Math.abs(view.downMousePosition.x - mouseOnCanvas.x)
    const y = Math.abs(view.downMousePosition.y - mouseOnCanvas.y)

    // direction of the selection in each axis
    const sX = view.downMousePosition.x > mouseOnCanvas.x ? 1 : -1
    const sY = view.downMousePosition.y < mouseOnCanvas.y ? 1 : -1

    view.selectionRect.scale.x = x || 0.00001
    view.selectionRect.scale.y = y || 0.00001

    view.selectionRect.position.x = -view.viewport.width / 2 + (view.downMousePosition.x - x / 2 * sX)
    view.selectionRect.position.y = view.viewport.height / 2 - (view.downMousePosition.y + y / 2 * sY)
  }

  @AnalyzeTime(0)
  static handleScroll (view: UIView, mouseOnCanvas: Vector2) {
    view.scrolling = true
    const { height, min } = view.scrollBarButtonGroup.userData

    const max = CalculationUtil.calcMaxPosition(min, 150, 50, height, view.viewport.height)

    const y = mouseOnCanvas.y - height / 2
    let newScrollValue = CalculationUtil.calcNewScrollValue(min, max, y)

    if (newScrollValue < 0) {
      newScrollValue = 0
    }
    else if (newScrollValue > 1) {
      newScrollValue = 1
    }

    const targetHeight = CalculationUtil.calcTargetHeight(newScrollValue, view.plHeight || 0)

    view.views?.sectionView?.setView(targetHeight)
  }
}
