/* eslint-env browser */

import React, { Component } from 'react'
import Plotly from 'plotly.js'
import { connect, ConnectedProps } from 'react-redux'
import sizeMe from 'react-sizeme'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'

import Icon from '../../specific/Icon'

import * as VisualizationActions from '../../../store/visualization/actions'
import * as FilterActions from '../../../store/filter/actions'
import DataActions from '../../../store/data/actions'

import Logic from '../PlotWrapper/EditBoxWrapper/Logic'
import { ViewLogic } from './ViewLogic'
import { PlotTitle, Menu, Name, Container, Wrapper, TopBar, Hyphen } from './PlotStyles'

import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { PlotConfig } from 'types/visualization'
import styled, { css } from 'styled-components'
import FeatureFlags from 'react/FeatureFlags'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  isEditModeOn: state.visualization.isEditModeOn,
  currentSimulationCase: state.application.main.currentSimulationCase,
  term: state.filter.term,
  tileWarnings: state.tileWarnings,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
}), {
  showConfigDialog: VisualizationActions.showConfigDialog,
  showDeleteDialog: VisualizationActions.showDeleteDialog,
  saveCurrentTile: VisualizationActions.saveCurrentTile,
  setTerm: FilterActions.setTerm,
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
})

type PropsFromRedux = ConnectedProps<typeof connector>

const StyledFilterIcon = styled(Icon)`${() => css`
  cursor: pointer;
  font-size: 19px;
`}`

const WarningMessage = styled.div`${() => css`
  height: 20px;
  line-height: 20px;
  width: fit-content;
  color: #BB1B1B;
  cursor: help;
`}`

interface Props extends PropsFromRedux {
  children: any,
  tileId: string,
  onlyChild?: boolean,
  data: any,
  size: any,
  t: Translation,
  enqueueSnackbar: enqueueSnackbar,
}

type State = {
  closed: boolean,
  childState: any | undefined | null
};

class GridItemContainer extends Component<Props, State> {
  state: State = {
    closed: true,
    childState: null,
  }

  handleOpenSettings = () => {
    const { childState } = this.state
    const { showConfigDialog, tileId, saveCurrentTile, enqueueSnackbar, t, tileConfigs } = this.props

    if (childState && childState.isEditing) {
      return enqueueSnackbar(t(`gridItemContainer.edit.warning`), { autoHideDuration: 3000, variant: 'info' })
    }

    const { configId } = (tileConfigs || {})[tileId] || {}

    if (configId) {
      showConfigDialog(configId, true)
    }

    saveCurrentTile(tileId)
  };

  handleDownload = async () => {
    const { tileId, enqueueSnackbar, tileConfigs, plotConfigs } = this.props
    const plot: any = document.getElementById(`plot_${tileId}`)
    const tileConfig = (tileConfigs || {})[tileId] || {}
    let filename = tileConfig.name

    if (filename === 'New Plot' || !filename) {
      const config = (plotConfigs || {})[tileConfig.configId] || {}

      filename = config.name ?? 'export'
    }

    // replace white spaces with underscores
    filename = filename.replace(/\s/g, '_')

    if (!plot || !plot.data || !plot.layout) {
      enqueueSnackbar('No plot to download', { autoHideDuration: 3000, variant: 'error' })

      return
    }

    const tempDiv = document.createElement('tmp-div')
    const imageHeight = plot.layout.height * 2
    const imageWidth = plot.layout.width * 2

    const newPlot = await ViewLogic.clonePlotAndChangeFontColorToBlack(plot, tempDiv, imageHeight, imageWidth)

    await Plotly.relayout(newPlot, { width: imageWidth, height: imageHeight })

    Plotly.downloadImage(newPlot, {
      format: 'jpeg',
      width: imageWidth,
      height: imageHeight,
      filename,
    })

    tempDiv.remove()
  }

  handleFilter = (event: any) => {
    event.stopPropagation()

    const { plotConfigs, setTerm, selectedMultiEditElements, term, data } = this.props
    const config = plotConfigs[data.configId] || {}
    const isMerged = config.isMergedDynamicDataSource

    const ctrl = event.ctrlKey || event.metaKey

    if (isMerged) {
      const filtersArray = this.getMergedPlotsFilter(config, plotConfigs) // Array of all filters of the MergedPlot
      const concatenatedFilters = filtersArray.map(filter => `(${filter})`).join(' || ')

      // if all elements are contained in the term, we should erase them
      if (filtersArray.every(filter => ~term.indexOf(filter))) {
        // we split the actual term and then set a new one without the mergedPlot's filters
        // TODO: rework this, after the filter rework it has become too complex
        // const splitTerm = term.split(' ')
        // const newFilters = splitTerm.filter(filter => !filtersArray.includes(filter)).join(' || ') || ''

        setTerm('')
      }
      else {
        /* if only some filters are present we should verify which ones, and depending if ctrl was pressed we should add
        the missing filters, if it was not the new filter is simply all mergedPlot's filters */
        // const splitTerm = term.split(' ')
        // let newFilters = ''

        // if (ctrl) {
        //   for (const filter of filtersArray) {
        //     if (!splitTerm.includes(filter)) {
        //       splitTerm.push(filter)
        //     }
        //   }

        //   newFilters = splitTerm.join(' || ')
        // }
        // else {
        //   newFilters = concatenatedFilters
        // }

        setTerm(concatenatedFilters)
      }
    }
    else {
      const filter = config.filter

      if (~term.indexOf(filter)) {
        setTerm(filter, ctrl, false, true)
      }
      else {
        setTerm(filter, ctrl, false, true)
      }
    }

    selectedMultiEditElements()
  }

  handleDeletePlot = () => {
    const { showDeleteDialog, children } = this.props

    showDeleteDialog(children.props, true)
  };

  handleResize = ({ width, height }: {width: number, height: number}) => {
    const { tileId } = this.props
    const plot: any = document.getElementById(`plot_${tileId}`)

    if (plot && plot.layout) {
      try {
        Plotly.relayout(plot, { ...plot.layout, width, height })
      }
      catch (e) {}
    }
  };

  handleOpenTopBar = () => {
    this.setState({
      closed: false,
    })
  };

  handleCloseTopBar = () => {
    this.setState({
      closed: true,
    })
  };

  handleChildState = (childState: any) => this.setState({ childState });

  handleEditEditBox = (event: any) => {
    const { id } = event.currentTarget
    const editableContent = document.querySelector(`#editable_content_${id}`)

    if (editableContent) {
      // TODO: check if this works in other browsers than chrome/electron
      const doubleClickEvent = new MouseEvent('dblclick', { view: window, bubbles: true, cancelable: true })

      editableContent.dispatchEvent(doubleClickEvent)
    }
  };

  getMergedPlotsFilter (mergedConfig: PlotConfig, plotConfigs: Record<string, PlotConfig>): string[] {
    const filters: string[] = []

    if (!mergedConfig.configs || !mergedConfig.configs.length) {
      return []
    }

    for (const configId of mergedConfig.configIds) {
      const config = plotConfigs[configId]

      if (config.filter && config.filter.length) {
        filters.push(config.filter)
      }
    }

    return filters
  }

  isFilterable (config: PlotConfig) {
    if (config.isMergedDynamicDataSource) {
      return config.configs.some((config: PlotConfig) => (config.filter || '').length)
    }

    return config.filter && config.filter.length
  }

  getTileWarningMessage = (config: PlotConfig) => {
    const { tileWarnings } = this.props

    if (config.isMergedDynamicDataSource) {
      let warningMessage = ''

      // append to message the warning message of each merged plot
      config.configs.forEach((cfg: PlotConfig) => {
        if (tileWarnings && tileWarnings[cfg.id] && Object.keys(tileWarnings[cfg.id]).length) {
          const message = `No data found for config ${cfg.name} for cases: ` +
          `${Object.keys(tileWarnings[cfg.id]).join(', ')}\n`

          warningMessage += message
        }
      })

      return warningMessage
    }

    if (tileWarnings && tileWarnings[config.id] && Object.keys(tileWarnings[config.id]).length) {
      return `No data found for config ${config.name} for cases: ` +
      `${Object.keys(tileWarnings[config.id]).join(', ')}`
    }
  }

  render () {
    const { closed: closedRaw } = this.state
    const {
      currentSimulationCase,
      children,
      tileId,
      data,
      plotConfigs,
      tileConfigs,
      isEditModeOn,
      term,
      featureFlags,
      t,
      appState,
      visualizationMetaInformation,
    } = this.props
    const config = plotConfigs[data.configId] || {}
    const tileConfig = tileConfigs[tileId] || {}
    const closed = closedRaw && !(isEditModeOn || (!isEditModeOn && tileConfig.name.length > 0))
    const type = tileConfig.type || config.type
    const { configId } = tileConfig
    const filterable = this.isFilterable(config)
    const filterActive = config.isMergedDynamicDataSource
      ? this.getMergedPlotsFilter(config, plotConfigs)
        .map(filter => `(${filter})`)
        .every(filter => ~term.indexOf(filter))
      : ~term.indexOf(config.filter)
    const warningMessage = this.getTileWarningMessage(config)

    const extendedChildren = React.Children.map(children, child =>
      React.cloneElement(child, { onChildState: this.handleChildState }))

    let displayName = tileConfig.name || ''
    let displayTitle = ''

    if ((!displayName || displayName === 'New Plot') && type === 'edit_box') {
      displayName = configId.substr(configId.lastIndexOf('/') + 1)
      displayTitle = configId
    }

    if (!displayName) {
      displayTitle = displayName = (config.name ?? 'Unnamed Plot') +
        (config.isMergedDynamicDataSource ? ' (Various Plots)' : '')
    }

    const canActivateFilterPlot = FeatureFlags.canActivateFilterPlot(featureFlags)
    const canDeletePlot =
      FeatureFlags.canDeletePlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canEditPlot =
      FeatureFlags.canEditPlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canMovePlot =
      FeatureFlags.canMovePlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canResizePlot =
      FeatureFlags.canResizePlotsInCurrentDashboardType(featureFlags, visualizationMetaInformation, appState)
    const canDownloadPlotImage = FeatureFlags.canDownloadPlotImage(featureFlags)

    return (
      <Wrapper>
        <TopBar
          className='header_bar'
          canMove={canMovePlot}
          closed={closed}
          onMouseLeave={this.handleCloseTopBar}
        >
          <PlotTitle closed={closed}>
            <Name isEditModeOn={isEditModeOn} title={displayTitle}>{displayName}</Name>
            <Menu>
              {
                !!warningMessage &&
                (
                  <WarningMessage>
                    <Icon
                      icon='exclamation-circle'
                      title={warningMessage}
                      type='fas'
                      fixedWidth
                    />
                  </WarningMessage>
                )
              }
              {canDownloadPlotImage && <Hyphen />}
              {
                canDownloadPlotImage && (
                  <StyledFilterIcon
                    icon='download'
                    className='pe-7s-download'
                    onClick={this.handleDownload}
                    title='Export plot'
                  />
                )
              }
              {filterable && canActivateFilterPlot && <Hyphen />}
              {
                filterable &&
                canActivateFilterPlot &&
                  <StyledFilterIcon
                    icon='filter'
                    className='pe-7s-filter'
                    onClick={this.handleFilter}
                    title='Toggle Filter'
                    style={{ color: filterActive ? '#BB1B1B' : '#CCCCCC' }}
                  />
              }
              {type === 'edit_box' && <Hyphen />}
              {
                type === 'edit_box' && (
                  !Logic.isFrozen(currentSimulationCase) && !Logic.isOnlyViewable(currentSimulationCase, configId)
                    ? (
                      <i
                        className='pe-7s-pen'
                        onClick={this.handleEditEditBox}
                        id={tileId}
                        title={t(`gridItemContainer.editable`)}
                      />
                    )
                    : (
                      <Icon
                        fixedWidth
                        icon='lock'
                        title={t(`gridItemContainer.not_editable`)}
                      />
                    )
                )
              }
              {canDeletePlot && <Hyphen />}
              {
                canDeletePlot &&
                  <i
                    className='pe-7s-trash'
                    onClick={this.handleDeletePlot}
                    id={tileId}
                    title={t('gridItemContainer.trash')}
                  />
              }
              {canEditPlot && <Hyphen />}
              {
                canEditPlot &&
                  <i
                    className='pe-7s-config'
                    onClick={this.handleOpenSettings}
                    id={tileId}
                    title={t('gridItemContainer.config')}
                  />
              }
            </Menu>
          </PlotTitle>
        </TopBar>
        <Container closed={closed ? 1 : 0} onResize={canResizePlot && this.handleResize}>
          {extendedChildren}
        </Container>
      </Wrapper>
    )
  }
}

const connected = connector(GridItemContainer as any) as any

export default withSnackbar(withNamespaces('visualization')(sizeMe()(connected) as any) as any) as any
