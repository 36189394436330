/* eslint-disable camelcase */
/* eslint-disable max-len */

import type { ElementsHashes } from 'types/state'

/* eslint-disable key-spacing */
const getFraction = (element: any, elementsHashes: ElementsHashes) => {
  const loopAmount = Object.values(elementsHashes.Nozzle || {})
    .filter((nozzle: any) => Number((nozzle || {})._loop) === Number(element._loop)).length

  return (1 / (loopAmount || 1)).toFixed(4)
}

export const Nozzle = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    _passln_coord:        { type: 'number', category: 1, defaultValue: 0, positive: true },
    _width_coord:         { type: 'number', category: 1, defaultValue: 0 },
    _height:              { type: 'number', category: 1, defaultValue: 0, positive: true },
    _anglelength:         { type: 'number', category: 1, defaultValue: 0, positive: true },
    _anglewidth:          { type: 'number', category: 1, defaultValue: 0, positive: true },
    _format:              { type: 'select', category: 1, defaultValue: 'conic', options: [ 'conic', 'rectangular' ] },
    _loop:                { type: 'number', category: 2, defaultValue: 0, positive: true },
    _water_flux_fraction: { type: 'number', category: 2, defaultValue: 1, disabled: true, generate: getFraction, positive: true },
    _id:                  { type: 'number', category: 2, defaultValue: 0, disabled: true },
    _coolWidthIndex:      { type: 'number', category: 2, defaultValue: 0, positive: true },
    _coolZoneIndex:       { type: 'number', category: 2, defaultValue: 0, positive: true },
    _nozzletype:          { type: 'number', category: 2, defaultValue: 0, positive: true },
    _name:                { type: 'text', category: 2, defaultValue: '' },
    _numericId:           { type: 'number', hidden: true },
    _originalId:          { type: 'number', hidden: true },
    _parentId:            { type: 'number', hidden: true },
  },
}
/* eslint-enable key-spacing */
