import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import styled, { css } from 'styled-components'

import * as ApplicationActions from 'store/application/main/actions'

import AppSwitch from './components/AppSwitch'
import FeatureFlags from 'react/FeatureFlags'
import ProjectMatrixDialog from 'react/dialogs/ProjectMatrixDialog'
import CasterHistoryDialog from 'react/dialogs/caster/CasterHistoryDialog'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import ProjectDataDialog from 'react/dialogs/project/ProjectDataDialog'

const Wrapper = styled.div<{showProjectName?: string}>`${({ showProjectName }) => css`
  white-space: nowrap;
  color: #dddddd;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 30px;
  padding-left: 10px;
  height: 32px;
  ${showProjectName && css`border-left: 1px solid #373f49;`}
`}`

const Project = styled.div`${() => css`
  display: inline-block;
  vertical-align: top;
`}`

const I = styled.i <{ disabled?: boolean }>`${({ disabled }) => css`
  font-size: 22px;
  top: 4px;
  margin-right: 6px;
  position: relative;
  font-weight: 600;
  color: #dddddd;
  cursor: pointer;

  &:hover {
    color: ${disabled ? '#dddddd' : '#a2a6a9'};
  }
`}`

const IconContainer = styled.div`${() => css`
  display: inline-block;
  margin: 0 12px;
`}`

const PRE_TRANS = 'projectActions'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  openAppDialogs: state.application.main.openAppDialogs,
  authenticationData: state.application.main.authenticationData,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isConsistencyCheck: state.util.isConsistencyCheck,
}), {
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  switchProject: ApplicationActions.switchProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

class ProjectActions extends Component<Props> {
  // @AnalyzeTime(0)
  handleProjectSettingsClick = () => {
    const { openDialog } = this.props

    this.handleCloseMatrix()

    openDialog(ProjectDataDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleCloseMatrix = () => {
    const { openAppDialogs, closeDialog } = this.props

    if (openAppDialogs.includes(ProjectMatrixDialog.NAME)) {
      closeDialog(ProjectMatrixDialog.NAME)

      return true
    }

    return false
  };

  // @AnalyzeTime(0)
  handleProjectMatrixClick = () => {
    const { openDialog } = this.props

    if (!this.handleCloseMatrix()) {
      openDialog(ProjectMatrixDialog.NAME)
    }
  };

  // @AnalyzeTime(0)
  handleHistoryClick = () => {
    const { openDialog } = this.props

    openDialog(CasterHistoryDialog.NAME)
  };

  @AnalyzeTime(0)
  render () {
    const {
      appState,
      authenticationData,
      currentProject,
      currentSimulationCase,
      featureFlags,
      openAppDialogs,
      t,
      isConsistencyCheck,
    } = this.props
    const showProjectName = authenticationData && currentProject && currentProject._id
    const disabled = !!openAppDialogs.length

    let caseInfo = ''

    if (currentProject && currentProject._id) {
      const caseNumber = currentProject.simulationCases.findIndex((sc) => sc._id === currentSimulationCase._id) + 1

      caseInfo = `C${caseNumber}(R) - ${currentSimulationCase.name}`
    }

    const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

    return (
      <Wrapper showProjectName={showProjectName}>
        {
          authenticationData && currentProject && currentProject._id &&
            <Project>
              {currentProject.name || ''}&nbsp;/&nbsp;
              {caseInfo || ''}&nbsp;&nbsp;
              {
                !slimVersion &&
                !isConsistencyCheck &&
                <IconContainer>
                  <I
                    title={t(`${PRE_TRANS}.project.data`)}
                    className='pe-7s-folder'
                    onClick={!disabled ? this.handleProjectSettingsClick : () => null}
                    disabled={disabled}
                  />
                  <I
                    title={t(`${PRE_TRANS}.project.matrix`)}
                    className='pe-7s-menu'
                    onClick={!disabled ? this.handleProjectMatrixClick : () => null}
                    disabled={disabled}
                  />
                </IconContainer>
              }
            </Project>
        }
        {
          !slimVersion && !isConsistencyCheck &&
          FeatureFlags.canViewCaster(featureFlags) &&
            <AppSwitch />
        }
      </Wrapper>
    )
  }
}

export default withNamespaces('titlebar')(connector(ProjectActions as any) as any) as any
