import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import cloneDeep from 'clone-deep'

import { AppState } from 'store/application/main/consts'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import * as MatrixActions from 'store/matrix/actions'
import * as ApplicationActions from 'store/application/main/actions'
import * as VisualizationActions from 'store/visualization/actions'
import DataActions from 'store/data/actions'

import Icon from 'react/specific/Icon'

import CloneDialog from '../CloneDialog'

import { ActionButton } from './Styles'
import Logic from '../Logic'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import ProjectDataDialog from 'react/dialogs/project/ProjectDataDialog'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect((state: DefaultState) => ({
  selections: state.matrix.selections,
  grid: state.matrix.grid,
  currentProject: state.application.main.currentProject,
  simulationCases: state.application.main.currentProject.simulationCases,
}), {
  resetReducer: DataActions.resetReducer,
  setSelections: MatrixActions.setSelections,
  setCloneSimulationCaseId: MatrixActions.setCloneSimulationCaseId,
  openDialog: ApplicationActions.openDialog,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  row: number,
  children: any,
  t: Translation,
}

type State = {
  shiftKey: boolean
};

class RowRenderer extends Component<Props, State> {
  state = {
    shiftKey: false,
  }

  // @AnalyzeTime(0)
  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyDown, false)
    window.addEventListener('keyup', this.handleKeyUp, false)
  };

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyDown, false)
    window.removeEventListener('keyup', this.handleKeyUp, false)
  };

  // @AnalyzeTime(0)
  handleKeyDown = ({ shiftKey }: any) => {
    if (shiftKey) {
      this.setState({ shiftKey })
    }
  };

  // @AnalyzeTime(0)
  handleKeyUp = ({ shiftKey }: any) => {
    if (!shiftKey) {
      this.setState({ shiftKey })
    }
  };

  // @AnalyzeTime(0)
  handleSelectionChange = (event: any) => {
    const { row, grid, selections, setSelections } = this.props
    const { shiftKey } = this.state
    const newSelections = cloneDeep(selections)
    const selectionValueArray = Object.values(selections)

    if (shiftKey && selectionValueArray.filter(val => val).length > 0) {
      const index = selectionValueArray.indexOf(true)

      const start = Math.min(index, row)
      const end = Math.max(index, row)

      Object.keys(newSelections).forEach((key, index) => {
        if (index >= start && index <= end) {
          newSelections[key] = event.target.checked
        }
      })
    }
    else {
      const { id } = (grid[row].find(cell => cell.key === 'simulation_case') || {}).data || {}

      newSelections[id] = event.target.checked
    }

    setSelections(newSelections)
  };

  // @AnalyzeTime(0)
  handleCloneClick = () => {
    const { row, grid, setCloneSimulationCaseId, openDialog } = this.props
    const { id } = (grid[row].find(cell => cell.key === 'simulation_case') || {}).data || {}

    if (id) {
      setCloneSimulationCaseId(id)
      openDialog(CloneDialog.NAME)
    }
  };

  // @AnalyzeTime(0)
  handleVerifyProcessingParameters = (simulationCaseId: string) => {
    const { currentProject, setCurrentProject } = this.props

    ApiClient
      .patch(`${Network.URI}/simulation_case_verify/processing_parameters/${simulationCaseId}`)
      .then(({ verificationList }) => {
        const project = cloneDeep(currentProject)
        const simulationCase = project.simulationCases.find(simulationCase => simulationCase._id === simulationCaseId)

        if (simulationCase) {
          simulationCase.processingParametersVerifications = verificationList

          setCurrentProject(project)
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  };

  // @AnalyzeTime(0)
  handleSwitchToCase = () => {
    const {
      row,
      grid,
      openDialog,
      setCurrentSimulationCase,
      resetReducer,
      setCurrentCasterRoot,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
    } = this.props
    const { id } = (grid[row].find(cell => cell.key === 'simulation_case') || {}).data || {}

    const {
      currentCasterVerification,
      currentCatalogVerification,
      currentProcessingParametersVerification,
    } = this.getVerifications(id)

    if (currentCasterVerification && currentCatalogVerification && !currentProcessingParametersVerification) {
      this.handleVerifyProcessingParameters(id)

      return
    }

    ApiClient
      .get(`${Network.URI}/simulation_case/${id}`)
      .then(({ simulationCase }) => {
        resetReducer()
        setCurrentCasterRoot()

        const { dataId } = (simulationCase.visualizationDataList || []).slice(-1)[0] || {}

        setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
        setVisualizationMetaInformation('data', dataId || '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.Caster)
        setCurrentCatalogId()

        setCurrentSimulationCase(simulationCase)
        openDialog(ProjectDataDialog.NAME)

        Logic.handleClose()
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  };

  // @AnalyzeTime(0)
  getVerifications = (simulationCaseId: string) => {
    const { simulationCases } = this.props

    const {
      processingParametersVerifications,
      catalogVerifications,
      casterVerifications,
    } = simulationCases.filter(sc => sc._id === simulationCaseId)[0] || {}

    const currentProcessingParametersVerification =
      (processingParametersVerifications[processingParametersVerifications.length - 1] || {}).isVerified || false
    const currentCatalogVerification = (catalogVerifications[catalogVerifications.length - 1] || {}).isVerified || false
    const currentCasterVerification = (casterVerifications[casterVerifications.length - 1] || {}).isVerified || false

    return {
      currentCasterVerification,
      currentCatalogVerification,
      currentProcessingParametersVerification,
    }
  };

  @AnalyzeTime(0)
  render () {
    const { grid, selections, row, children, t, simulationCases } = this.props

    if (row >= grid.length) {
      return null
    }

    const { id } = (grid[row].find(cell => cell.key === 'simulation_case') || {}).data || {}
    const simulationCase = simulationCases.filter(sc => sc._id === id)[0] || {}
    const isVerified = (simulationCase.simulationStartedAt || '').length > 0 || Logic.checkIfVerified(simulationCase)

    return (
      <tr className='data-row'>
        <td className='row-number-cell cell'>{row + 1}</td>
        <td className='selection-cell cell'>
          <input type='checkbox' checked={selections[id]} onChange={this.handleSelectionChange} />
        </td>
        <td className='action-cell cell'>
          <ActionButton
            variant='contained'
            color='primary'
            onClick={this.handleCloneClick}
            title={t(`${PRE_TRANS}.button.clone`)}
          >
            <Icon icon='clone' />
          </ActionButton>
          <ActionButton
            variant='contained'
            color='primary'
            onClick={this.handleSwitchToCase}
            title={t(`${PRE_TRANS}.button.${isVerified ? 'view' : 'verify'}`)}
          >
            {isVerified ? <Icon icon='eye' /> : <Icon icon='check' />}
          </ActionButton>
        </td>
        {children}
      </tr>
    )
  }
}

export default withNamespaces('application')(connector(RowRenderer as any) as any) as any
