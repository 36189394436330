export enum elementActionEnum {
  ACTION_ADD_ELEMENTS = 'elements/ACTION_ADD_ELEMENTS',
  ACTION_DELETE_ELEMENT = 'elements',
}

export const idsToBeDeletedObj: Record<
  'AirLoop' |
  'CoolingLoop' |
  'CoolingZone' |
  'LoopAssignment' |
  'Nozzle' |
  'Roller' |
  'RollerBearing' |
  'RollerBody' |
  'SupportPoint' |
  'Segment' |
  'SegmentGroup' |
  'SensorPoint',
  number[]
> = {
  AirLoop: [],
  CoolingLoop: [],
  CoolingZone: [],
  LoopAssignment: [],
  Nozzle: [],
  Roller: [],
  RollerBearing: [],
  RollerBody: [],
  SupportPoint: [],
  Segment: [],
  SegmentGroup: [],
  SensorPoint: [],
}

export const elementsHashesEmptyObject = {
  AirLoop: {},
  CoolingLoop: {},
  CoolingZone: {},
  LoopAssignment: {},
  Nozzle: {},
  Roller: {},
  RollerBearing: {},
  RollerBody: {},
  SupportPoint: {},
  Segment: {},
  SegmentGroup: {},
  SensorPoint: {},
  DataPoint: {},
  DataLine: {},
}
