import cloneDeep from 'clone-deep'
import { AnalyzeTime } from 'Util'

export default class AutoScale {
  @AnalyzeTime(0)
  static getTilesByX (tiles: Array<any>, x: number): Array<any> {
    return tiles.filter(tile => tile.x + tile.w > x && tile.x <= x).sort((tileA, tileB) => tileA.y - tileB.y)
  }

  @AnalyzeTime(0)
  static setHeights (tiles: Array<any>): void {
    for (let i = 0; i < tiles.length; i++) {
      tiles[i].y = isNaN(tiles[i].yPos) ? tiles[i].y : tiles[i].yPos
      tiles[i].h = isNaN(tiles[i].height) ? tiles[i].h : Math.round(tiles[i].height)

      delete tiles[i].yPos
      delete tiles[i].height
    }
  }

  @AnalyzeTime(0)
  static getAccumulatedTileHeight (tiles: Array<any>): number {
    let height = 0

    for (let i = 0; i < tiles.length; i++) {
      height += tiles[i].h
    }

    return height
  }

  @AnalyzeTime(0)
  static calculateTileHeights (tiles: Array<any>, gridMeta: any): void {
    const { gridHeight, gridWidth } = gridMeta

    for (let x = 0; x < gridWidth; x++) {
      const xTiles = AutoScale.getTilesByX(tiles, x)
      let accumulatedTileHeight = AutoScale.getAccumulatedTileHeight(xTiles)

      for (let i = 0; i < xTiles.length; i++) {
        const tile = xTiles[i]

        tile.height = Math.min(tile.height || Infinity, tile.h / accumulatedTileHeight * gridHeight)

        if (tile.height < 2) {
          tile.height = 2
          accumulatedTileHeight = accumulatedTileHeight - 1
        }
      }
    }

    const previous: any = {}
    const nextTiles: any = {}

    for (let r = 0; r < 2; r++) {
      for (let x = 0; x < gridWidth; x++) {
        const xTiles = AutoScale.getTilesByX(tiles, x)

        for (let i = 0; i < xTiles.length; i++) {
          const tile = xTiles[i]
          const prev = xTiles[i - 1]
          const next = xTiles[i + 1]

          if (!previous[tile.i]) {
            previous[tile.i] = []
            previous[tile.i][0] = 1
          }

          if (!nextTiles[tile.i]) {
            nextTiles[tile.i] = []
          }

          if (prev && !previous[tile.i].includes(prev)) {
            previous[tile.i].push(prev)

            if (prev.height > previous[tile.i][0]) {
              previous[tile.i][0] = prev.height
            }
          }

          if (next && !nextTiles[tile.i].includes(next.i)) {
            nextTiles[tile.i].push(next.i)
          }

          tile.yPos = Math.min(tile.yPos || Infinity, i ? (Math.round(prev.height) + Math.round(prev.yPos)) : 0)

          while (tile.height + tile.yPos > gridHeight) {
            tile.height--
          }

          if (tile.height < 2) {
            tile.height = 2
          }
        }
      }
    }

    tiles.forEach(tile => {
      if ((!nextTiles[tile.i] || !nextTiles[tile.i].length) && gridHeight - tile.yPos >= 2) {
        tile.height = gridHeight - tile.yPos
      }
    })

    AutoScale.setHeights(tiles)
  }

  @AnalyzeTime(0)
  static getTilesByY (tiles: Array<any>, y: number): Array<any> {
    return tiles.filter(tile => tile.y + tile.h > y && tile.y <= y).sort((tileA, tileB) => tileA.x - tileB.x)
  }

  @AnalyzeTime(0)
  static setWidths (tiles: Array<any>): void {
    for (let i = 0; i < tiles.length; i++) {
      tiles[i].x = isNaN(tiles[i].xPos) ? tiles[i].x : tiles[i].xPos
      tiles[i].w = isNaN(tiles[i].width) ? tiles[i].w : Math.round(tiles[i].width)

      delete tiles[i].xPos
      delete tiles[i].width
    }
  }

  @AnalyzeTime(0)
  static getAccumulatedTileWidthFromY (tiles: Array<any>): number {
    let width = 0

    for (let i = 0; i < tiles.length; i++) {
      const prevW = ((tiles[i - 1] || {}).w || 0) + ((tiles[i - 1] || {}).x || 0) || tiles[i].x

      width += tiles[i].w + (tiles[i].x - prevW)
    }

    return width
  }

  @AnalyzeTime(0)
  static getAccumulatedTileWidth (tiles: Array<any>): number {
    let width = 0

    for (let i = 0; i < tiles.length; i++) {
      width += tiles[i].w
    }

    return width
  }

  @AnalyzeTime(0)
  static calculateTileWidths (tiles: Array<any>, gridMeta: any): void {
    const { gridWidth, gridHeight } = gridMeta

    for (let y = 0; y < gridHeight; y++) {
      const yTiles = AutoScale.getTilesByY(tiles, y)

      let accumulatedTileWidth = AutoScale.getAccumulatedTileWidth(yTiles)

      for (let i = 0; i < yTiles.length; i++) {
        const tile = yTiles[i]

        tile.width = Math.min(tile.width || Infinity, tile.w / accumulatedTileWidth * gridWidth)

        if (tile.width < 2) {
          tile.width = 2
          accumulatedTileWidth = accumulatedTileWidth - 2
        }
      }
    }

    for (let r = 0; r < 2; r++) {
      for (let y = 0; y < gridHeight; y++) {
        const yTiles = AutoScale.getTilesByY(tiles, y)

        for (let i = 0; i < yTiles.length; i++) {
          const tile = yTiles[i]
          const prev = yTiles[i - 1]

          tile.xPos = Math.max(tile.xPos || -Infinity, i ? (Math.round(prev.width) + Math.round(prev.xPos)) : 0)

          while (tile.width + tile.xPos > gridWidth) {
            tile.width--
          }

          if (tile.width < 2) {
            tile.width = 2
          }
        }
      }
    }

    AutoScale.setWidths(tiles)
  }

  @AnalyzeTime(0)
  static fillWidthGaps (tiles: Array<any>, gridMeta: any): void {
    const { gridHeight, gridWidth } = gridMeta

    for (let y = 0; y < gridHeight; y++) {
      const yTiles = AutoScale.getTilesByY(tiles, y)

      for (let i = 0; i < yTiles.length; i++) {
        const tile = yTiles[i]
        const next = yTiles[i + 1]

        if (next) {
          tile.width = next.x - tile.x
        }
        else {
          tile.width = gridWidth - tile.x
        }
      }
    }

    AutoScale.setWidths(tiles)
  }

  // TODO: Fixme in some cases gaps aren't closed
  @AnalyzeTime(0)
  static fillHeightGaps (tiles: Array<any>, gridMeta: any): void {
    const { gridHeight, gridWidth } = gridMeta

    for (let x = 0; x < gridWidth; x++) {
      const xTiles = AutoScale.getTilesByX(tiles, x)

      for (let i = 0; i < xTiles.length; i++) {
        const tile = xTiles[i]
        const next = xTiles[i + 1]

        if (next) {
          // tile.height = next.y - tile.y
        }

        if (!next) {
          tile.height = gridHeight - tile.y
        }
      }
    }

    AutoScale.setHeights(tiles)
  }

  @AnalyzeTime(0)
  static handleTiles (tiles: Array<any>, gridMeta: any): Array<any> {
    const tilesCopy: Array<any> = cloneDeep(tiles)

    AutoScale.calculateTileHeights(tilesCopy, gridMeta)
    AutoScale.calculateTileWidths(tilesCopy, gridMeta)
    AutoScale.fillWidthGaps(tilesCopy, gridMeta)
    AutoScale.fillHeightGaps(tilesCopy, gridMeta)

    return tilesCopy
  }
}
