import moment from 'moment'
import { AnalyzeTime } from 'Util'

export default class TimeUtil {
  @AnalyzeTime(0)
  static getDisplayDate (date: string) {
    return moment(date).format(moment.HTML5_FMT.DATE)
  }

  @AnalyzeTime(0)
  static getDisplayDateTime (date: string) {
    return moment(date).format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME}`)
  }
}
