import type { ElementsHashes } from 'types/state'

export function deleteElement (type: CasterElementNames, id: number, elementsHashes: ElementsHashes): void {
  if (!elementsHashes[type] || !elementsHashes[type][id]) {
    return
  }

  const element = elementsHashes[type][id]

  Object.keys(element).filter(key => key.includes('Ids')).forEach(childTypeKey => {
    const childrenType = childTypeKey.slice(1, -3)

    elementsHashes[type][id][childTypeKey].forEach((childId: number) => {
      deleteElement(childrenType as CasterElementNames, childId, elementsHashes)
    })
  })

  const { type: parentType, id: parentId }:{type: string, id: number} = element['#parent']

  // delete element from 'data' via parent element
  const parent = (elementsHashes as any)[parentType][parentId]

  if (Array.isArray(parent[`#${type}Ids`])) {
    // TODO: not changed inplace
    // TODO: Verify, could be bug
    parent[`#${type}Ids`] = parent[`#${type}Ids`].filter((id: number) => id !== element._id)
    ;(elementsHashes as any)[parentType].hasChanges = true
  }

  elementsHashes[type]['#hasChanges'] = true
  delete elementsHashes[type][id]
}

export function deleteAllElements (
  xmlGenParam: XMLGenParams,
  dataTypes: CasterElementNames[],
  elementsHashes: ElementsHashes,
) {
  dataTypes.forEach(type => {
    const elements = elementsHashes[type] || {}
    const typeIds = Object.keys(elements).filter(key => key[0] !== '#')

    for (let i = 0; i < typeIds.length; i++) {
      const { _id } = (elements as any)[typeIds[i]]

      deleteElement(type, _id, elementsHashes)
    }

    (xmlGenParam as any)[`_lastTypeId_${type}`] = '0'
  })
}
