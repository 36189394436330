import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import CasterHistoryDialog from './caster/CasterHistoryDialog'
import CasterHandleDataDialog from './caster/CasterHandleDataDialog'
import LoginDialog from './LoginDialog'
import ConfigureDialog from './ProjectMatrixDialog/ConfigureDialog'
import CloneDialog from './ProjectMatrixDialog/CloneDialog'
import DeleteDialog from './ProjectMatrixDialog/DeleteDialog'
import SimulateDialog from './ProjectMatrixDialog/SimulateDialog'
import StopSimulationDialog from './ProjectMatrixDialog/StopSimulationDialog'
import ResetSimulationDialog from './ProjectMatrixDialog/ResetSimulationDialog'
import ProjectMatrixDialog from './ProjectMatrixDialog'

import NewProjectDialog from './project/NewProjectDialog'
import OpenProjectDialog from './project/OpenProjectDialog'
import ProjectDataDialog from './project/ProjectDataDialog'
import EditProjectDialog from './project/EditProjectDialog'
import NewSimulationCaseDialog from './project/NewSimulationCaseDialog'
import EditSimulationCaseDialog from './project/EditSimulationCaseDialog'
import EditConfigDialog from './project/EditConfigDialog'
import CreateCommandFileDialog from './project/CreateCommandFileDialog'
import InputDataDialog from './project/InputDataDialog'
import ManageDynamicDataSourcesDialog from './project/ManageDynamicDataSourcesDialog'
import SelectDashboardDialog from './project/SelectDashboardDialog'
import ExecutableDialog from './executables/ExecutableDialog'

import * as ApplicationActions from 'store/application/main/actions'
import DuplicateSimulationCaseDialog from './ProjectMatrixDialog/DuplicateSimulationCaseDialog'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import SelectCatalogDialog from './project/ProjectDataDialog/SelectCatalogDialog'
import UsageTimeExceededDialog from './UsageTimeExceededDialog'
import DownloadXMLDialog from './executables/DownloadXMLDialog'

const connector = connect((state: DefaultState) => ({
  openAppDialogs: state.application.main.openAppDialogs,
  authenticationData: state.application.main.authenticationData,
  isConsistencyCheck: state.util.isConsistencyCheck,
}), {
  clearOpenAppDialogs: ApplicationActions.clearOpenAppDialogs,
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  login?: boolean
}

class DialogManager extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { openAppDialogs, login, clearOpenAppDialogs } = this.props

    if (openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
      return <UsageTimeExceededDialog />
    }

    // CASTER DIALOGS
    if (openAppDialogs.includes(CasterHistoryDialog.NAME)) {
      return <CasterHistoryDialog />
    }

    if (openAppDialogs.includes(CasterHandleDataDialog.NAME)) {
      return <CasterHandleDataDialog />
    }

    if (openAppDialogs.includes(SelectCatalogDialog.NAME)) {
      return <SelectCatalogDialog />
    }

    // APPLICATION DIALOGS
    if (openAppDialogs.includes(CreateCommandFileDialog.NAME)) {
      return <CreateCommandFileDialog />
    }

    if (login || openAppDialogs.includes(LoginDialog.NAME)) {
      return <LoginDialog hideCloseButton={login} />
    }

    if (openAppDialogs.includes(EditProjectDialog.NAME)) {
      return <EditProjectDialog />
    }

    if (openAppDialogs.includes(NewSimulationCaseDialog.NAME)) {
      return <NewSimulationCaseDialog />
    }

    if (openAppDialogs.includes(EditSimulationCaseDialog.NAME)) {
      return <EditSimulationCaseDialog />
    }

    if (openAppDialogs.includes(EditConfigDialog.NAME)) {
      return <EditConfigDialog />
    }

    if (openAppDialogs.includes(ManageDynamicDataSourcesDialog.NAME)) {
      return <ManageDynamicDataSourcesDialog />
    }

    if (openAppDialogs.includes(SelectDashboardDialog.NAME)) {
      return <SelectDashboardDialog />
    }

    if (openAppDialogs.includes(ExecutableDialog.NAME)) {
      return <ExecutableDialog />
    }

    if (openAppDialogs.includes(DownloadXMLDialog.NAME)) {
      return <DownloadXMLDialog />
    }

    if (openAppDialogs.includes(NewProjectDialog.NAME)) {
      return <NewProjectDialog />
    }

    if (openAppDialogs.includes(OpenProjectDialog.NAME)) {
      return <OpenProjectDialog />
    }

    let additionalMatrixDialog

    if (openAppDialogs.includes(ConfigureDialog.NAME)) {
      additionalMatrixDialog = <ConfigureDialog />
    }

    if (openAppDialogs.includes(CloneDialog.NAME)) {
      additionalMatrixDialog = <CloneDialog />
    }

    if (openAppDialogs.includes(DeleteDialog.NAME)) {
      additionalMatrixDialog = <DeleteDialog />
    }

    if (openAppDialogs.includes(SimulateDialog.NAME) || openAppDialogs.includes(SimulateDialog.ALLNAME)) {
      additionalMatrixDialog = <SimulateDialog all={openAppDialogs.includes(SimulateDialog.ALLNAME)} />
    }

    if (openAppDialogs.includes(StopSimulationDialog.NAME)) {
      additionalMatrixDialog = <StopSimulationDialog />
    }

    if (openAppDialogs.includes(ResetSimulationDialog.NAME)) {
      additionalMatrixDialog = <ResetSimulationDialog />
    }

    if (openAppDialogs.includes(DuplicateSimulationCaseDialog.NAME)) {
      additionalMatrixDialog = <DuplicateSimulationCaseDialog />
    }

    if (openAppDialogs.includes(ProjectMatrixDialog.NAME)) {
      return <div><ProjectMatrixDialog />{additionalMatrixDialog}</div>
    }

    let additionalDataDialog

    if (openAppDialogs.includes(InputDataDialog.NAME)) {
      additionalDataDialog = <InputDataDialog />
    }

    if (openAppDialogs.includes(ProjectDataDialog.NAME)) {
      return <div><ProjectDataDialog />{additionalDataDialog}</div>
    }

    if (openAppDialogs.length) {
      clearOpenAppDialogs()
    }

    return null
  }
}

export default connector(DialogManager as any) as any
