import { getChildrenArrayByIds } from 'store/elements/logic'
import * as THREE from 'three'
import type { ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'

import Mold from '../objects/Mold'
import SectionView from '../views/SectionView'

import Util from './Util'

export default class SectionLogic {
  @AnalyzeTime(0)
  static handleRoller (views: any, elementsHashes: ElementsHashes) {
    if (!views.mainView.elementList.Roller) {
      return
    }

    const nextRoller = Object.values(views.mainView.elementList.Roller)
      .filter((roller: any) => {
        if (views.sectionView.statusPrevNext) {
          return roller.plCoord < views.sectionView.plHeight * views.uiView.scrollValue
        }

        return roller.plCoord > views.sectionView.plHeight * views.uiView.scrollValue
      })
      .sort((rollerOne: any, rollerTwo: any) => {
        if (views.sectionView.statusPrevNext) {
          return rollerTwo.plCoord - rollerOne.plCoord
        }

        return rollerOne.plCoord - rollerTwo.plCoord
      })
      .filter((roller: any, _, rollerArray: any[]) =>
        rollerArray[0].plCoord === roller.plCoord && !~views.mainView.hideList.indexOf(roller.path))

    views.sectionView.rollerGroup = new THREE.Group()
    views.sectionView.rollerGroup.visible = false
    views.sectionView.rollerGroup.name = 'PrevOrNextElements'

    nextRoller.forEach(roller => SectionLogic.drawSectionViewRoller(roller, views, elementsHashes))

    Util.addOrReplace(views.sectionView.sectionPlaneFolded, views.sectionView.rollerGroup)
  }

  @AnalyzeTime(0)
  static drawSectionViewRoller (roller: any, views: any, elementsHashes: ElementsHashes) {
    const { thickness, width } = Mold

    const RollerBearing = getChildrenArrayByIds<RollerBearing>(
      elementsHashes,
      'RollerBearing',
      roller.rollerData['#RollerBearingIds'],
    )
    const RollerBody = getChildrenArrayByIds<RollerBody>(
      elementsHashes,
      'RollerBody',
      roller.rollerData['#RollerBodyIds'],
    )
    const { _diameter: rawDiameter } = roller.rollerData
    const diameter = rawDiameter / 1000

    RollerBody && RollerBody.forEach((rollerBody: RollerBody, index: number) => {
      const bodyWidth = rollerBody._width / 1000
      const bodyWidthStart = rollerBody._width_start / 1000
      const bodyMid = bodyWidthStart + bodyWidth / 2

      if (~views.mainView.hideList.indexOf(`${roller.path}/RollerBody:${rollerBody._id}`)) {
        return
      }

      const geometry = new THREE.PlaneBufferGeometry(diameter, bodyWidth)

      const plane = new THREE.Mesh(geometry, SectionView.rollerBodyMaterial)
      // TODO: Geometry is deprecated in new THREE releases
      const lineGeometry = new (THREE as any).Geometry()

      lineGeometry.vertices.push(
        new THREE.Vector3(0, -bodyWidth / 2, 0),
        new THREE.Vector3(diameter, -bodyWidth / 2, 0),
        new THREE.Vector3(diameter, bodyWidth / 2, 0),
        new THREE.Vector3(0, bodyWidth / 2, 0),
        new THREE.Vector3(0, -bodyWidth / 2, 0),
      )

      const line = new THREE.Line(lineGeometry, SectionView.rollerLineMaterial)

      plane.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001
      line.position.z = views.sectionView.statusPrevNext ? 0.00041 : 0.000011

      const { type, id } = Util.getParentInfo(roller.objects.roller.path)
      const { _FixedLooseSide: side } = views.sectionView.elementsHashes[type][id]

      switch (side) {
        case 'FixedSide':
          plane.position.x = -(thickness + diameter / 2)
          plane.position.y = bodyMid

          line.position.x = -thickness - diameter
          line.position.y = bodyMid
          break
        case 'LooseSide':
          plane.position.x += diameter / 2
          plane.position.y = bodyMid

          line.position.y = bodyMid
          break
        case 'NarrowFaceLeft':
          plane.rotation.z += Util.RAD90
          plane.position.y = (width + diameter) / 2
          plane.position.x = bodyMid - thickness

          line.rotation.z += Util.RAD270
          line.position.y = (width) / 2 + diameter
          line.position.x = bodyMid - thickness
          break
        case 'NarrowFaceRight':
          plane.rotation.z += Util.RAD90
          plane.position.y = -(width + diameter) / 2
          plane.position.x = bodyMid - thickness

          line.rotation.z += Util.RAD270
          line.position.y = -width / 2
          line.position.x = bodyMid - thickness
          break
        default:
      }

      plane.name = `rollerBodyPlane_${side}_${index}`
      line.name = `rollerBodyLine_${side}_${index}`
      Util.addOrReplace(views.sectionView.rollerGroup, plane)
      Util.addOrReplace(views.sectionView.rollerGroup, line)
    })

    RollerBearing && RollerBearing.forEach((rollerBearing: RollerBearing, index: number) => {
      const bearingWidth = rollerBearing._width / 1000
      const bearingWidthStart = rollerBearing._width_start / 1000
      const bearingMid = bearingWidthStart + bearingWidth / 2

      const geometry = new THREE.PlaneBufferGeometry(diameter * 0.8, bearingWidth)

      if (~views.mainView.hideList.indexOf(`${roller.path}/RollerBearing:${rollerBearing._id}`)) {
        return
      }

      const rollerBearingPlane = new THREE.Mesh(geometry, SectionView.rollerBearingMaterial)
      // TODO: Geometry is deprecated in new THREE releases
      const lineGeometry = new (THREE as any).Geometry()

      lineGeometry.vertices.push(
        new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
        new THREE.Vector3(diameter * 0.9, -bearingWidth / 2, 0),
        new THREE.Vector3(diameter * 0.9, bearingWidth / 2, 0),
        new THREE.Vector3(diameter * 0.1, bearingWidth / 2, 0),
        new THREE.Vector3(diameter * 0.1, -bearingWidth / 2, 0),
      )

      const rollerBearingLine = new THREE.Line(lineGeometry, SectionView.rollerBearingLineMaterial)

      rollerBearingPlane.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001
      rollerBearingLine.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001

      const { type, id } = Util.getParentInfo(roller.objects.roller.path)
      const { _FixedLooseSide: side } = views.sectionView.elementsHashes[type][id]

      rollerBearingPlane.position.y = bearingMid

      switch (side) {
        case 'FixedSide':
          rollerBearingPlane.position.x = -(thickness + diameter / 2)

          rollerBearingLine.position.x = -thickness - diameter
          rollerBearingLine.position.y = bearingMid
          break
        case 'LooseSide':
          rollerBearingPlane.position.y = bearingMid
          rollerBearingPlane.position.x += diameter / 2

          rollerBearingLine.position.y = bearingMid
          break
        case 'NarrowFaceLeft':
          rollerBearingPlane.rotation.z += Util.RAD90
          rollerBearingPlane.position.y = (width + diameter) / 2
          rollerBearingPlane.position.x = bearingMid - thickness

          rollerBearingLine.rotation.z += Util.RAD270
          rollerBearingLine.position.y = width / 2 + diameter
          rollerBearingLine.position.x = bearingMid - thickness

          break
        case 'NarrowFaceRight':
          rollerBearingPlane.rotation.z += Util.RAD90
          rollerBearingPlane.position.y = -(width + diameter) / 2
          rollerBearingPlane.position.x = bearingMid - thickness

          rollerBearingLine.rotation.z += Util.RAD90
          rollerBearingLine.position.y = -(width / 2 + diameter)
          rollerBearingLine.position.x = bearingMid - thickness

          break
        default:
      }

      rollerBearingLine.name = `rollerBearingLine_${side}_${index}`
      rollerBearingPlane.name = `rollerBearingPlane_${side}_${index}`

      Util.addOrReplace(views.sectionView.rollerGroup, rollerBearingLine)
      Util.addOrReplace(views.sectionView.rollerGroup, rollerBearingPlane)
    })
  }

  @AnalyzeTime(0)
  static handleNozzle (views: any) {
    const selectedSide: any[] = []

    Object.keys(views.sectionView.side).forEach(side => {
      if (views.sectionView.side[side]) {
        selectedSide.push(...Object.values(views.sectionView.elementList.Nozzle)
          .filter((nozzle: any) => nozzle.container.userData.side === side))
      }
    })

    const plCoord = Number((views.sectionView.plHeight * views.uiView.scrollValue).toFixed(5))

    const passlnCoord = selectedSide
      .filter(nozzle => {
        if (views.sectionView.statusPrevNext) {
          return nozzle.plCoord.toFixed(5) < plCoord
        }

        return nozzle.plCoord.toFixed(5) > plCoord
      })
      .sort((nozzleOne, nozzleTwo) => {
        if (views.sectionView.statusPrevNext) {
          return nozzleTwo.plCoord - nozzleOne.plCoord
        }

        return nozzleOne.plCoord - nozzleTwo.plCoord
      })
      .filter(nozzle => nozzle.plCoord !== plCoord)[0] || []

    const nextNozzle = Object.values(views.sectionView.elementList.Nozzle)
      .filter((nozzle: any) => passlnCoord.plCoord === nozzle.plCoord)

    views.sectionView.nozzleGroup = new THREE.Group()
    views.sectionView.nozzleGroup.visible = false
    views.sectionView.nozzleGroup.name = 'PrevOrNextElements'

    nextNozzle.forEach((nozzle: any, index) => {
      if (!nozzle.objects.nozzle.visible) {
        return
      }

      const wireFrame = nozzle.objects.nozzle.getObjectByName('WireFrame').clone()

      wireFrame.position.copy(nozzle.objects.nozzle.position)

      const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

      const { type, id } = Util.getParentInfo(nozzle.objects.nozzle.path)
      const { _FixedLooseSide: side } = views.sectionView.elementsHashes[type][id] || {}
      const x = wireFrame.position.x

      switch (side) {
        case 'FixedSide':
          newSectionRotation.set(Util.RAD90, Util.RAD90, 0)
          wireFrame.position.x = -Mold.thickness
          wireFrame.position.y = x
          wireFrame.position.z = 0

          break
        case 'LooseSide':
          newSectionRotation.set(Util.RAD90, -Util.RAD90, 0)
          wireFrame.position.x = 0
          wireFrame.position.y = x
          wireFrame.position.z = 0

          break
        case 'NarrowFaceLeft':
          newSectionRotation.set(Util.RAD90, 0, 0)
          wireFrame.position.y = Mold.width / 2
          wireFrame.position.x = wireFrame.position.z - Mold.thickness
          wireFrame.position.z = 0

          break
        case 'NarrowFaceRight':
          newSectionRotation.set(Util.RAD90, Util.RAD180, 0)
          wireFrame.position.y = -Mold.width / 2
          wireFrame.position.x = wireFrame.position.z - Mold.thickness
          wireFrame.position.z = 0

          break
        default:
      }

      wireFrame.name = `NozzleWireFrame_${side}_${index}`

      wireFrame.rotation.copy(newSectionRotation)

      views.sectionView.nozzleGroup.position.z = views.sectionView.statusPrevNext ? 0.0004 : 0.00001

      Util.addOrReplace(views.sectionView.nozzleGroup, wireFrame)
    })

    Util.addOrReplace(views.sectionView.sectionPlaneFolded, views.sectionView.nozzleGroup)
  }
}
