
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from '../../components/Button'
import { Form } from '../../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../../specific/Input'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from '../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import ProjectDataDialog from './ProjectDataDialog'
import OpenProjectDialog from './OpenProjectDialog'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'

const PRE_TRANS = 'newProjectDialog'

const connector = connect(state => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  switchProject: ApplicationActions.switchProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string | boolean
  name: string,
  description: string,
  error: string,
  loading: boolean
};

export class NewProjectDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    name: '',
    description: '',
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(NewProjectDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name, description } = this.state
    const {
      featureFlags,
      openDialog,
      closeDialog,
      setCurrentProject,
      setCurrentSimulationCase,
      switchProject,
      enqueueSnackbar,
      t,
    } = this.props

    if (name && description) {
      this.setState(({
        loading: true,
      }))

      ApiClient
        .post(`${Network.URI}/create_project`, { data: { name, description } })
        .then(({ project }) => {
          const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

          switchProject()
          setCurrentProject(project)
          setCurrentSimulationCase(project.simulationCases[0], slimVersion)

          closeDialog(NewProjectDialog.NAME)

          if (!slimVersion) {
            openDialog(ProjectDataDialog.NAME)
          }
        })
        .catch((response) => {
          const { error } = response

          this.setState(({
            error: error ? error.status : 'unknown',
            loading: false,
          }))

          enqueueSnackbar(t(`${PRE_TRANS}.create.error`), { autoHideDuration: 4000, variant: 'error' })
        })
    }
  };

  // @AnalyzeTime(0)
  handleSwitchToOpen = () => {
    const { openDialog, closeDialog } = this.props

    closeDialog(NewProjectDialog.NAME)
    openDialog(OpenProjectDialog.NAME)
  };

  @AnalyzeTime(0)
  render () {
    const { name, description, error, loading } = this.state
    const { t } = this.props
    const submitDisabled = loading || !(name.length > 0 && description.length > 0)

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-folder'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t(`${PRE_TRANS}.name.label`)}
            title={t(`${PRE_TRANS}.name.label`)}
            placeholder={t(`${PRE_TRANS}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Input
            label={t(`${PRE_TRANS}.description.label`)}
            title={t(`${PRE_TRANS}.description.label`)}
            placeholder={t(`${PRE_TRANS}.description.placeholder`)}
            name='description'
            type='text'
            rows={8}
            value={description}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            type='primary'
            title={submitDisabled ? t(`${PRE_TRANS}.create.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            icon='pe-7s-plus'
            isRef
          >
            {t(`${PRE_TRANS}.create.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleSwitchToOpen}
            title={t(`${PRE_TRANS}.open`)}
          >
            {t(`${PRE_TRANS}.open`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(NewProjectDialog as any) as any)as any) as any
