import * as THREE from 'three'

import BaseObject from './BaseObject'
import DrawUtil from '../logic/DrawUtil'
import Util from '../logic/Util'
import { AnalyzeTime } from 'Util'

export default class PhantomObject extends BaseObject {
  @AnalyzeTime(0)
  static createPhantomObject (type: string, path: string, elementList: any, container: any, newElement: any) {
    const phantomObject = new THREE.Group()

    phantomObject.name = `PhantomObject_${newElement ? `new_${type}` : path}`

    const { path: parentPath, type: parentType } = Util.getParentInfo(path)

    phantomObject.userData.path = parentPath

    const parent = elementList[parentType][parentPath]

    Util.addOrReplace(container, phantomObject)

    return DrawUtil.getNewElementByType(
      type,
      parent,
      phantomObject,
      [],
      [],
      false,
      phantomObject,
    )
  }
}
