export enum ApplicationMainActionsEnum {
  ACTION_SET_OPEN_DIALOGS = 'application/ACTION_SET_OPEN_DIALOGS,',
  ACTION_SET_LOADING_STATUS = 'application/ACTION_SET_LOADING_STATUS,',
  ACTION_SET_TOOLTIP = 'application/ACTION_SET_TOOLTIP',
  ACTION_UPDATE_HISTORY = 'application/ACTION_UPDATE_HISTORY',
  ACTION_OPEN_DIALOG = 'application/ACTION_OPEN_DIALOG',
  ACTION_CLOSE_DIALOG = 'application/ACTION_CLOSE_DIALOG',
  ACTION_CLEAR_DIALOGS = 'application/ACTION_CLEAR_DIALOGS',
  ACTION_SET_REQUIRED_COMMAND_FILES = 'application/ACTION_SET_REQUIRED_COMMAND_FILES',
  ACTION_SET_CURRENT_PROJECT = 'application/ACTION_SET_CURRENT_PROJECT',
  ACTION_SET_CURRENT_SIMULATION_CASE = 'application/ACTION_SET_CURRENT_SIMULATION_CASE',
  ACTION_SET_CURRENT_CASTER_ROOT = 'application/ACTION_SET_CURRENT_CASTER_ROOT',
  ACTION_UPDATE_CURRENT_PROJECT_SCHEMA = 'application/ACTION_UPDATE_CURRENT_PROJECT_SCHEMA',
  ACTION_SET_AUTHENTICATION_DATA = 'application/ACTION_SET_AUTHENTICATION_DATA',
  ACTION_SET_THEME = 'application/ACTION_SET_THEME',
  ACTION_SET_FULL_PROJECT_PROCESSING = 'application/ACTION_SET_FULL_PROJECT_PROCESSING',
  ACTION_SET_EDIT_PROJECT_ID = 'application/ACTION_SET_EDIT_PROJECT_ID',
  ACTION_SET_EDIT_SIMULATION_CASE_ID = 'application/ACTION_SET_EDIT_SIMULATION_CASE_ID',
  ACTION_SET_EDIT_CONFIG_ID = 'application/ACTION_SET_EDIT_CONFIG_ID',
  ACTION_SET_APP_STATE = 'application/ACTION_SET_APP_STATE',
  ACTION_SET_SIMPLE_DASHBOARD_TAB_INDEX = 'application/ACTION_SET_SIMPLE_DASHBOARD_TAB_INDEX',
  ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG = 'application/ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG',
  ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID = 'application/ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID',
  openDashboard = 'application/ACTION_SET_APP_STATE',
  selectDataSource = 'visualization/ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG',
  exportPlots = 'visualization/ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG',
  configurePlots = 'visualization/ACTION_VISUALIZATION_SHOW_PLOT_LIST',
  mapCommands = 'visualization/ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING',
  editMode = 'visualization/ACTION_VISUALIZATION_SET_EDIT_MODE',
}

export const actionList = [
  'application/ACTION_SET_OPEN_DIALOGS,',
  'application/ACTION_SET_LOADING_STATUS,',
  'application/ACTION_SET_TOOLTIP',
  'application/ACTION_UPDATE_HISTORY',
  'application/ACTION_OPEN_DIALOG',
  'application/ACTION_CLOSE_DIALOG',
  'application/ACTION_CLEAR_DIALOGS',
  'application/ACTION_SET_REQUIRED_COMMAND_FILES',
  'application/ACTION_SET_CURRENT_PROJECT',
  'application/ACTION_SET_CURRENT_SIMULATION_CASE',
  'application/ACTION_SET_CURRENT_CASTER_ROOT',
  'application/ACTION_UPDATE_CURRENT_PROJECT_SCHEMA',
  'application/ACTION_SET_AUTHENTICATION_DATA',
  'ACTION_RESET_STORE',
  'ACTION_SWITCH_PROJECT',
  'application/ACTION_SET_THEME',
  'application/ACTION_SET_FULL_PROJECT_PROCESSING',
  'application/ACTION_SET_EDIT_PROJECT_ID',
  'application/ACTION_SET_EDIT_SIMULATION_CASE_ID',
  'application/ACTION_SET_EDIT_CONFIG_ID',
  'application/ACTION_SET_APP_STATE',
  'application/ACTION_SET_SIMPLE_DASHBOARD_TAB_INDEX',
  'application/ACTION_SET_APP_STATE',
  'visualization/ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG',
  'visualization/ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG',
  'visualization/ACTION_VISUALIZATION_SHOW_PLOT_LIST',
  'visualization/ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING',
  'visualization/ACTION_VISUALIZATION_SET_EDIT_MODE' ]

export enum AppState {
  Caster,
  ParamDashboard,
  ResultDashboard,
}

export const initialState: ApplicationMainState = {
  openDialogs: [ 'CasterTree' ],
  loadingStatus: false,
  tooltip: [] as unknown as Record<'UIView' | 'SectionView', Tooltip[]>,
  openAppDialogs: [],
  params: null,
  nonexistentCommandFiles: {},
  nonexistentCommandFileMapping: [],
  latestUploadedVisualizationConfigId: '',
  currentProject: {} as Project,
  currentSimulationCase: {} as SimulationCase,
  currentCasterRoot: {},
  editProjectId: null,
  editSimulationCaseId: null,
  editConfigId: null,
  darkTheme: true,
  authenticationData: {} as AuthData,
  appState: AppState.Caster,
  currentSimpleDashboardTabIndex: 0,
}
