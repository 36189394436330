import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Container } from 'react-smooth-dnd'

import {
  Content,
  List,
  ListItem,
  InnerText,
  I,
  DraggableItem,
  DragNDrop,
  DropContainer,
} from './Styles'
import ApiClient from '../../../../store/apiClient'
import { Network } from '../../../../network/Network'
import { withStyles } from '@material-ui/core'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '0 0 5px 0 rgba(127,127,127,.5)',
  },
}))(Tooltip)

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  commands: any,
  selectedFile: any,
  t: Translation
}

type State = {
  selectedCommands: any[]
};

class DragAndDrop extends Component<Props, State> {
  state: State = {
    selectedCommands: [],
  }

  componentDidMount () {
    const { selectedFile } = this.props
    const assignedCommands = (selectedFile || {}).assignedCommands || []

    this.setState({
      selectedCommands: [ ...assignedCommands ],
    })
  }

  componentDidUpdate (prevProps: Props) {
    const { selectedFile } = this.props

    if (prevProps.selectedFile._id !== selectedFile._id) {
      this.setState({
        selectedCommands: [ ...selectedFile.assignedCommands ],
      })
    }
  }

  componentWillUnmount () {
    this.handleApiRequest(true)
  }

  handleTimeout?: number;

  handleDropCard = (event: any) => {
    const { payload, addedIndex, removedIndex } = event
    const { selectedCommands } = this.state

    const sortedSelectedCommand: any[] = [ ...selectedCommands ]

    if (removedIndex !== null) {
      sortedSelectedCommand.splice(removedIndex, 1)
    }

    if (addedIndex !== null) {
      sortedSelectedCommand.splice(addedIndex, 0, payload)
    }

    if (removedIndex === null && selectedCommands.includes(payload)) {
      return
    }

    this.setState({
      selectedCommands: sortedSelectedCommand,
    })

    this.handleApiRequest()
  };

  handleDelete = (event: any) => {
    const { selectedCommands } = this.state
    const { id } = event.target

    let newSelectedCommands: any[] = []

    if (id !== 'all') {
      newSelectedCommands = [ ...selectedCommands ]

      newSelectedCommands.splice(selectedCommands.indexOf(id), 1)
      delete newSelectedCommands[id]
    }

    this.setState({
      selectedCommands: newSelectedCommands,
    })

    this.handleApiRequest()
  };

  handleApiRequest = (instant?: boolean) => {
    clearTimeout(this.handleTimeout)

    this.handleTimeout = window.setTimeout(() => {
      const { currentSimulationCase, selectedFile } = this.props
      const { selectedCommands } = this.state

      ApiClient.post(`${Network.URI}/visualization_command/file/${selectedFile._id}`, {
        data: {
          assignedCommands: selectedCommands,
          simulationCaseId: currentSimulationCase._id,
        },
      })
    }, instant ? 0 : 1000)
  };

  handleSortCommands = (commandA: any, commandB: any) => {
    if (commandA.command < commandB.command) {
      return -1
    }

    if (commandA.command > commandB.command) {
      return 1
    }

    return 0
  };

  render () {
    const { selectedCommands } = this.state
    const { t, commands } = this.props

    return (
      <Content>
        <DragNDrop>
          <List>
            <Container
              groupName='123'
              behaviour='copy'
              getChildPayload={(i: number) => commands[i]._id}
              dragHandleSelector='.list_item_drag_handle'
            >
              {
                commands.sort(this.handleSortCommands).map((com: any) => {
                  const disabled = com.parameter.flat().includes('')

                  return (
                    <StyledTooltip
                      key={com._id}
                      title={
                        disabled
                          ? 'This command can not be assigned because there are missing values for some parameters.'
                          : ''
                      }
                    >
                      <DraggableItem
                        id={com._id}
                        className={disabled ? undefined : 'list_item_drag_handle'}
                        disabled={disabled}

                      >
                        <ListItem>
                          <InnerText>
                            {com.command}
                          </InnerText>
                        </ListItem>
                      </DraggableItem>
                    </StyledTooltip>
                  )
                })
              }
            </Container>
          </List>
        </DragNDrop>
        <DragNDrop float='right'>
          <DropContainer>
            <List>
              <Container
                groupName='123'
                getChildPayload={(i: number) => selectedCommands[i]}
                onDrop={this.handleDropCard}
                style={{ height: '100%' }}
                lockAxis='y'
              >
                {
                  selectedCommands.map((selectedCommandId, index) => {
                    const currentCommand = commands.filter((com: any) => com._id === selectedCommandId)[0] || {}

                    return (
                      <DraggableItem key={index} id={index}>
                        <ListItem>
                          <InnerText>
                            {currentCommand.command}
                          </InnerText>
                          <I
                            className='pe-7s-close'
                            id={selectedCommandId}
                            title={t('plotContent.configurePlot.delete')}
                            onClick={this.handleDelete}
                            size='25px'
                          />
                        </ListItem>
                      </DraggableItem>
                    )
                  })
                }
              </Container>
            </List>
          </DropContainer>
        </DragNDrop>
      </Content>
    )
  }
}

export default withNamespaces('visualization')(connector(DragAndDrop as any) as any) as any
