/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const ConsistencyCheck = {
  categories: {
    1: 'general',
  },
  fields: {
    _uuid: { type: 'string', category: 1, defaultValue: '' },
    _old_value: { type: 'string', category: 1, defaultValue: 0 },
    _new_value: { type: 'string', category: 1, defaultValue: 0 },
    _comment: { type: 'string', category: 1, defaultValue: '' },
  },
}
/* eslint-enable key-spacing */
