import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'clone-deep'
import hotkeys from 'hotkeys-js'

import Button from 'react/components/Button/index'
import { Form, Text, TextBody, List } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import { FixedButton } from 'react/dialogs/DialogStyles'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from '../../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.deleteDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  selections: state.matrix.selections,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string,
  loading: boolean
};

class DeleteDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Enter', this.handleDeleteSubmit)
    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleDeleteSubmit)
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DeleteDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleDeleteSubmit = () => {
    const {
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentSimulationCase,
    } = this.props

    const selectedSimulationCases = this.getSelectedNotRunningSimulationCases()
    const ids = selectedSimulationCases.map(({ simulationCase }) => simulationCase._id)

    this.setState({ loading: true })

    ApiClient.del(`${Network.URI}/simulation_case/delete`, { data: { simulationCaseIds: ids } })
      .then(() => {
        const project = cloneDeep(currentProject)

        project.simulationCases = project.simulationCases
          .filter(simulationCase => !ids.includes(simulationCase._id))

        if (ids.includes(currentSimulationCase._id)) {
          setCurrentSimulationCase(project.simulationCases[0])
        }

        setCurrentProject(project)

        const content = (document.querySelector('.data-grid-container') || {}).parentNode

        if (content) {
          (content as any).scrollTop = 0
        }

        this.handleClose()
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ loading: false, error: 'ERROR' })
      })
  };

  // @AnalyzeTime(0)
  getSelectedNotRunningSimulationCases = () => {
    const { currentProject, selections } = this.props

    return currentProject.simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => (
        ((selections || {}) as any)[simulationCase._id] &&
        !(simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt)
      ))
  };

  @AnalyzeTime(0)
  render () {
    const { error, loading } = this.state
    const { t } = this.props

    const simulationCases = this.getSelectedNotRunningSimulationCases()

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-trash'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${PRE_TRANS}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                simulationCases.map(({ num, simulationCase }) =>
                  <li key={simulationCase._id}>{num}. {simulationCase.name}</li>)
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleDeleteSubmit}
              error={error}
              loading={loading}
              icon='pe-7s-trash'
              isRef
            >
              {t(`${PRE_TRANS}.delete`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(DeleteDialog as any) as any) as any
