import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import ApiClient from '../../../../../store/apiClient'
import * as VisualizationActions from '../../../../../store/visualization/actions'
import { Network } from '../../../../../network/Network'
import Input from '../../../../specific/Input'
import VisUtil from '../../../VisUtil'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization, application }: DefaultState) => ({
  tileConfigs: visualization.tileConfigs,
  currentTileId: visualization.currentTileId,
  currentSimulationCase: application.main.currentSimulationCase,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

type State = {
  [key: string]: string | boolean | any[]
  loading: boolean,
  name: string,
  command: string,
  fileName: string,
  commandSelectors: Array<any>
};

class CommandSettings extends Component<Props, State> {
  timeoutRef?: number
  timeoutTileRef?: number

  state: State = {
    loading: true,
    name: '',
    command: '',
    fileName: '',
    commandSelectors: [],
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleData()
  }

  // @AnalyzeTime(0)
  handleData = async () => {
    const { tileConfigs, currentTileId } = this.props
    const { commands } = await ApiClient.get(`${Network.URI}/visualization_command/commands`)

    const commandSelectors = commands
      .filter((command: any) => command['#OMSCommand'] !== '')
      .map((command: any) => {
        return ({ key: command['#OMSCommand'], value: command['#OMSCommand'] })
      })
      .sort(VisUtil.sortStringsASC)

    const { name = '', command = '', fileName = '' } = currentTileId ? tileConfigs[currentTileId] : {}

    this.setState({
      commandSelectors,
      loading: false,
      name,
      command,
      fileName,
    })
  };

  // @AnalyzeTime(0)
  handleTileNameInput = (event: any) => {
    clearTimeout(this.timeoutTileRef)
    const { name, value } = event.target
    const { saveTileConfig, tileConfigs, currentTileId } = this.props

    this.setState({
      [name]: value,
    })

    this.timeoutTileRef = window.setTimeout(() => {
      if (currentTileId) {
        saveTileConfig({
          ...tileConfigs[currentTileId],
          [name]: value,
        })
      }
    }, 1000)
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    clearTimeout(this.timeoutRef)
    const { name, value } = event.target
    const { saveTileConfig, tileConfigs, currentTileId, currentSimulationCase } = this.props

    if (!currentTileId) {
      return
    }

    const currentTile = tileConfigs[currentTileId]

    this.setState({
      [name]: value,
    })

    this.timeoutRef = window.setTimeout(() => {
      saveTileConfig({
        ...currentTile,
        [name]: value,
      })

      ApiClient.post(
        `${Network.URI}/visualization_command/${currentTile.fileId}/${currentTile.commandId}`,
        {
          data: {
            command: {
              command: value,
              parameter: [],
            },
            simulationCaseId: currentSimulationCase._id,
          },
        },
      )
    }, 1000)
  };

  @AnalyzeTime(0)
  render () {
    const { loading, name, command, fileName, commandSelectors } = this.state
    const { t } = this.props

    if (loading) {
      return (<div>Loading...</div>)
    }

    return (
      <div>
        <Input
          label={t('addPlotDialog.tileName.label')}
          title={t('addPlotDialog.tileName.title')}
          name='name'
          type='text'
          value={name}
          onChange={this.handleTileNameInput}
          data-tile
        />
        <Input
          label={t('addPlotDialog.fileName.label')}
          title={t('addPlotDialog.fileName.title')}
          name='fileName'
          type='text'
          value={fileName}
          data-tile
          disabled
        />
        <Input
          label={t('addPlotDialog.command.command.label')}
          title={t('addPlotDialog.command.command.title')}
          name='command'
          type='select'
          value={command || commandSelectors[0].key}
          selectors={commandSelectors}
          onChange={this.handleInput}
          data-tile
        />
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(CommandSettings as any) as any) as any
