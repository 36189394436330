import { ModeBarDefaultButtons } from 'plotly.js'

// possible buttons to remove
// 'lasso2d'
// 'select2d'
// 'sendDataToCloud'
// 'zoom2d'
// 'pan2d'
// 'zoomIn2d'
// 'zoomOut2d'
// 'autoScale2d'
// 'resetScale2d'
// 'hoverClosestCartesian'
// 'hoverCompareCartesian'
// 'zoom3d'
// 'pan3d'
// 'orbitRotation'
// 'tableRotation'
// 'resetCameraDefault3d'
// 'resetCameraLastSave3d'
// 'hoverClosest3d'
// 'zoomInGeo'
// 'zoomOutGeo'
// 'resetGeo'
// 'hoverClosestGeo'
// 'hoverClosestGl2d'
// 'hoverClosestPie'
// 'toggleHover'
// 'toImage'
// 'resetViews'
// 'toggleSpikelines';

export const config:{
  responsive: boolean
  displaylogo: boolean
  modeBarButtonsToRemove: ModeBarDefaultButtons[]
} = {
  responsive: true,
  displaylogo: false,
  modeBarButtonsToRemove: [
    'sendDataToCloud',
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    // 'zoomIn2d',
    // 'zoomOut2d',
    'autoScale2d',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
    // 'zoom3d',
    'pan3d',
    'orbitRotation',
    'tableRotation',
    // 'handleDrag3d',
    'hoverClosest3d',
    // 'zoomInGeo',
    // 'zoomOutGeo',
    'hoverClosestGeo',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'toggleSpikelines',
  ],
}
