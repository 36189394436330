import { VisualizationState } from 'types/visualization'
import { VisualizationActionsEnum as VisActionsEnum } from '../consts'

const hdf5Reducers = {
  [VisActionsEnum.ACTION_ADD_HDF5_SCHEMA]: (state: VisualizationState, { hdf5Schema }: any) => {
    if (state.hdf5Schemas.includes(hdf5Schema)) {
      return {
        ...state,
        hdf5Schema,
      }
    }

    return {
      ...state,
      hdf5Schemas: [
        ...state.hdf5Schemas,
        hdf5Schema,
      ],
      hdf5Schema,
    }
  },

  [VisActionsEnum.ACTION_SET_HDF5_SCHEMA]: (state: VisualizationState, { hdf5Schema }: any) => ({
    ...state,
    hdf5Schema,
  }),

  [VisActionsEnum.ACTION_REMOVE_HDF5_SCHEMA]: (state: VisualizationState, { hdf5Schema }: any) => {
    const hdf5Schemas = state.hdf5Schemas.filter((path) => {
      return path !== hdf5Schema
    })

    return {
      ...state,
      hdf5Schemas,
    }
  },
}

export default hdf5Reducers
