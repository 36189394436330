import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { FormWrapper, InputWrapper, CheckListHeader, IButton } from '../../Dialogs/DialogStyles'
import Input from '../../../../specific/Input'

import VisUtil from '../../../VisUtil'

import * as VisualizationActions from '../../../../../store/visualization/actions'

import type { ChangeEvent } from 'react'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { PlotConfig, TileConfig } from 'types/visualization'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
}), {
  savePlotConfig: VisualizationActions.savePlotConfig,
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean,
  t: Translation
}

type State = {
  plotConfig: any,
  tileConfig: any
};

class EditTab extends Component<Props, State> {
  tileConfigTimeout?: number;
  plotConfigTimeout?: number;

  constructor (props: Props) {
    super(props)
    const { plotConfigs, editDialogConfigId, currentTileId, tileConfigs } = props

    this.state = {
      plotConfig: currentTileId ? plotConfigs[editDialogConfigId] : {},
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
    }
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props, prevState: State) {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.tileConfigTimeout)

      this.tileConfigTimeout = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        this.tileConfigTimeout = undefined
      }, 1000)
    }

    if (!plotConfig.equals(prevState.plotConfig)) {
      clearTimeout(this.plotConfigTimeout)

      this.plotConfigTimeout = window.setTimeout(() => {
        savePlotConfig(plotConfig)
        this.plotConfigTimeout = undefined
      }, 1000)
    }
  }

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig, savePlotConfig } = this.props
    const { tileConfig, plotConfig } = this.state

    if (this.tileConfigTimeout) {
      clearTimeout(this.tileConfigTimeout)
      saveTileConfig(tileConfig)
      this.tileConfigTimeout = undefined
    }

    if (this.plotConfigTimeout) {
      clearTimeout(this.plotConfigTimeout)
      savePlotConfig(plotConfig)
      this.plotConfigTimeout = undefined
    }
  };

  // @AnalyzeTime(0)
  handleRemoveConfigId = (event: any) => {
    const { id } = event.target
    const { plotConfig, tileConfig } = this.state
    const configIds = [ ...plotConfig.configIds ]
    const label = { ...tileConfig.label }

    configIds.splice(configIds.indexOf(id), 1)

    delete label[id]

    this.setState({
      tileConfig: { ...tileConfig, label: { ...label } },
      plotConfig: { ...plotConfig, configIds },
    })
  };

  // @AnalyzeTime(0)
  handleAddConfigId = (): void => {
    const { plotConfigs } = this.props
    const { plotConfig } = this.state
    const configIds = [ ...plotConfig.configIds ]

    configIds.push(Object.keys(plotConfigs).filter(configId => !~configIds.indexOf(configId))[0])

    this.setState({
      plotConfig: {
        ...plotConfig,
        configIds,
      },
    })
  };

  // @AnalyzeTime(0)
  handlePlotLabel = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const { tileConfig } = this.state
    const { label } = tileConfig

    this.setState({
      tileConfig: {
        ...tileConfig,
        label: {
          ...label,
          [name]: value,
        },
      },
    })
  };

  // @AnalyzeTime(0)
  handleInputDataTile = (event: any) => {
    const { tileConfig } = this.state
    const { value, name } = event.target

    this.setState({
      tileConfig: {
        ...tileConfig,
        [name]: value,
      },
    })
  };

  @AnalyzeTime(0)
  render () {
    const { plotConfig, tileConfig } = this.state
    const {
      plotConfigs,
      fullscreen,
      t,
      data,
    } = this.props

    const { configIds } = plotConfig
    const { label, configId } = tileConfig

    const allConfigIds = VisUtil.getConfigSelectors(data, plotConfigs)
    const allConfigIdsNoMerged = allConfigIds.filter(configId => configId.group !== VisUtil.GROUP_MERGED)
      .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    return (
      <FormWrapper fullscreen={fullscreen}>
        <CheckListHeader>{t('plotConfig.labelAndPlot')}</CheckListHeader>
        <div>
          <hr />
          {
            configIds && configIds.length > 0
              ? configIds.map((configId: string, index: number) => (
                <div key={configId}>
                  <InputWrapper fullscreen={fullscreen}>
                    <Input
                      label={t('plotConfig.editLabel.label')}
                      title={t('plotConfig.editLabel.title')}
                      name={configId}
                      type='text'
                      value={(label || {})[configId] || ''}
                      onChange={this.handlePlotLabel}
                    />
                    <Input
                      label={t('plotConfig.configId.label')}
                      title={t('plotConfig.configId.title')}
                      name={configId}
                      type='select'
                      value={configId}
                      selectors={allConfigIdsNoMerged}
                      onChange={this.handleInputDataTile}
                    // disabled={fullscreen}
                    />
                  </InputWrapper>
                  {
                    configIds.length > 1 &&
                      <IButton
                        className='pe-7s-less'
                        onClick={this.handleRemoveConfigId}
                        id={configId}
                        title={t('plotConfig.removeValue')}
                      />
                  }
                  {configIds.length - 1 > index && <hr />}
                </div>
              ))
              : (
                <div key={configId}>
                  <Input
                    label={t('plotConfig.editLabel.label')}
                    title={t('plotConfig.editLabel.title')}
                    name={configId}
                    type='text'
                    value={(label || {})[configId] || ''}
                    onChange={this.handlePlotLabel}
                  />
                  <Input
                    label={t('plotConfig.configId.label')}
                    title={t('plotConfig.configId.title', { selected: configId })}
                    name={configId}
                    type='select'
                    value={configId}
                    selectors={allConfigIdsNoMerged}
                    disabled
                    readonly
                  />
                  {configIds && <hr />}
                </div>
              )
          }
          {configIds && <IButton className='pe-7s-plus' onClick={this.handleAddConfigId} title={t('plotConfig.add')} />}
        </div>
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(EditTab as any) as any) as any
