import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import CommandWrapper from 'react/visualization/PlotWrapper/CommandWrapper'
import { AnalyzeTime } from 'Util'

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  selectedCellData: Array<any>,
  simulationCaseId: string,
  simulationStartedAt?: Date
  name: string,
  id: string,
  type: string
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCommand: any,
  commandFileId: string
};

export class CommandEditor extends Component<Props, State> {
  state:State = {
    selectedCommand: {},
    commandFileId: '',
  }

  // @AnalyzeTime(0)
  componentDidMount = async () => {
    const { simulationCaseId, name } = this.props

    const { selectedCommand, commandFileId } = await ApiClient.get(
      `${Network.URI}/visualization_command/first_command`,
      { params: { simulationCaseId, command: name } },
    )

    this.setState({ selectedCommand, commandFileId })
  };

  @AnalyzeTime(0)
  render () {
    const { simulationCaseId, simulationStartedAt, selectedCellData } = this.props
    const { commandFileId, selectedCommand } = this.state

    if (!commandFileId || !selectedCommand || !selectedCommand._id) {
      return null
    }

    return (
      <div style={{ height: '500px' }}>
        <CommandWrapper
          currentSimulationCase={{ _id: simulationCaseId, simulationStartedAt }}
          selectedCellData={selectedCellData}
          commandId={selectedCommand._id}
          currentCommand={selectedCommand}
          fileId={commandFileId}
        />
      </div>
    )
  }
}

export default connector(CommandEditor)
