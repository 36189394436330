import { AnalyzeTime } from 'Util'

export default class BaseObject {
  container: any
  parent: any
  objects: any
  isHidden: boolean
  path?: string
  constructor (container: any, parent?: any) {
    this.container = container
    this.parent = parent
    this.objects = {}
    this.isHidden = false
  }

  @AnalyzeTime(0)
  static getPasslineHeight (caster: Caster) {
    const { Passline } = caster

    return Passline.section[Passline.section.length - 1]._passln_coord / 1000
  }

  @AnalyzeTime(0)
  setValues (path: string, isHidden = false) {
    this.path = path
    this.isHidden = isHidden

    if (isHidden) {
      this.hide()
    }
  }

  @AnalyzeTime(0)
  show (skipRollerChildren = false) {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true

    if (this.parent) {
      this.parent.show()
    }
  }

  @AnalyzeTime(0)
  hide () {
    Object.values(this.objects).forEach((object: any) => {
      object.visible = false
    })
  }

  @AnalyzeTime(0)
  dispose () {
    // remove from phantom object
    if (this.container.parent && this.container.parent.remove) {
      this.container.parent.remove(this.container)
    }
  }
}
