import React, { Component } from 'react'
import styled from 'styled-components'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import ScrollBar from '../specific/ScrollBar'
import Button from './Button'
import SecondaryButton from './SecondaryButton'
import Icon from './Icon'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

export const DialogBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(30,30,30,0.6);
  filter: blur(5px);
`

export const DialogWrapper = styled.div`
  position: absolute;
  top: calc(50% - 190px);
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  background-color: #22282e;
  z-index: 5;
  width: 40%;
  max-width: 600px;
`

export const CardHeader = styled.div`
  border-bottom: 1px solid #CCCCCC;
  font-size: 1.25rem;
  color: #FFFFFF;
  margin: 0 auto;
  width: 90%;
`

export const CardBody = styled(ScrollBar)`
  color: #FFFFFF;
  margin: 15px auto 80px;
  max-height: 300px;
  overflow: auto;
`

export const CardContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`

export const WarningBox = styled.div`
  background: #FF0;
`

export const Buttons = styled.div`
  position: absolute;
  bottom: 15px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
`

export const Cancel = styled<any>(Icon)`
  color: #FFFFFF;
  font-size: 15px;
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
`

type Props = {
  title: string,
  message: string,
  submitTitle: string,
  onSubmit: (e: any) => void,
  t: Translation,
  onDecline: any,
  onCancel?: any,
  declineTitle: string,
  warningMessage?: string
};

export class Dialog extends Component<WithNamespaces & Props> {
  @AnalyzeTime(0)
  render () {
    const { title, message, onSubmit, submitTitle, onDecline, declineTitle, warningMessage, onCancel, t } = this.props

    return (
      <div>
        <DialogBackground />
        <DialogWrapper>
          <CardHeader>
            <h3>{t(title)}</h3>
          </CardHeader>
          {onCancel && <Cancel icon='minus' onClick={onCancel} />}
          <CardContainer>
            <CardBody>
              <div>{t(message)}</div>
              {warningMessage && <WarningBox>{warningMessage}</WarningBox>}
            </CardBody>
          </CardContainer>
          <Buttons>
            <Button onClick={onSubmit} value={submitTitle} small />
            <SecondaryButton onClick={onDecline} value={declineTitle} small />
          </Buttons>
        </DialogWrapper>
      </div>
    )
  }
}

export default withNamespaces('caster')(Dialog as any) as any
