import { DataActionsEnum } from '../consts'

const additionalDataReducers = {
  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_REQUEST]: (state: DataState) => state,
  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_ERROR]: (state: DataState) => state,

  [DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_SUCCESS]:
  (state: DataState, { result: { additionalData, rootData } }: any) => {
    if (!state.rootData || !rootData) {
      return { ...state }
    }

    return {
      ...state,
      additionalData: {
        ...state.additionalData,
        ...additionalData,
      },
      rootData,
      hasChanges: true,
      loading: false,
    }
  },
}

export default additionalDataReducers
