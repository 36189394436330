
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from '../../components/Button'
import { Form } from '../../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../../specific/Input'

import * as ApplicationActions from '../../../store/application/main/actions'
import DataActions from 'store/data/actions'
import * as VisualizationActions from '../../../store/visualization/actions'

import BaseDialog from '../BaseDialog'
import ApiClient from '../../../store/apiClient'
import { Network } from '../../../network/Network'

import Logic from './ProjectDataDialog/Logic'
import { AppState } from 'store/application/main/consts'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'
import TimeUtil from 'logic/TimeUtil'

const PRE_TRANS = 'newSimulationCaseDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  resetReducer: DataActions.resetReducer,
  setAppState: ApplicationActions.setAppState,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string | undefined | null
  name: string,
  description: string,
  fromSimulationCaseId: string | undefined | null,
  error: string,
  loading: boolean
};

export class NewSimulationCaseDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    name: '',
    description: '',
    fromSimulationCaseId: null,
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(NewSimulationCaseDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name, description, fromSimulationCaseId } = this.state
    const {
      currentProject,
      setCurrentProject,
      setCurrentSimulationCase,
      setCurrentCasterRoot,
      resetReducer,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
      enqueueSnackbar,
      t,
      featureFlags,
    } = this.props

    if (name) {
      this.setState(({
        loading: true,
      }))

      ApiClient
        .patch(
          `${Network.URI}/project/${currentProject._id}/add_simulation_case`,
          {
            data: {
              name,
              description,
              fromSimulationCaseId: fromSimulationCaseId !== 'default' ? fromSimulationCaseId : null,
            },
          },
        )
        .then(({ project, simulationCase }) => {
          const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

          resetReducer()
          setCurrentCasterRoot()
          setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
          setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
          setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
          setVisualizationMetaInformation('config', '', AppState.Caster)
          setCurrentCatalogId()
          setCurrentProject(project)
          setCurrentSimulationCase(simulationCase, slimVersion)

          this.handleClose()

          enqueueSnackbar(t(`${PRE_TRANS}.create.success`), { autoHideDuration: 3000, variant: 'success' })
        })
        .catch((response) => {
          const { error } = response

          this.setState(({
            error: error ? error.status : 'unknown',
            loading: false,
          }))

          enqueueSnackbar(t(`${PRE_TRANS}.create.error`), { autoHideDuration: 4000, variant: 'error' })
        })
    }
  };

  // @AnalyzeTime(0)
  handleChangeSimulationCase = (event: any) => {
    this.setState({
      fromSimulationCaseId: event.target.value,
    })
  };

  @AnalyzeTime(0)
  render () {
    const { name, description, fromSimulationCaseId, error, loading } = this.state
    const { currentProject, t, currentSimulationCase } = this.props
    const submitDisabled = !(name.length > 0 && description.length > 0)

    const simulationCaseSelectors: {
      value: string,
      key: string,
      notRemovable?: boolean
    }[] = [
      ...currentProject.simulationCases.map(({ name, authorName, hideUser, createdAt, _id }, index) => ({
        value: `
          C${index + 1}${currentSimulationCase._id === _id ? '(R)' : ''} -
          ${name} -
          ${hideUser ? 'System' : authorName}
          (${TimeUtil.getDisplayDate(createdAt)})
        `,
        key: _id,
      })),
    ]

    simulationCaseSelectors.unshift({ key: 'default', value: t(`${PRE_TRANS}.default`), notRemovable: true })

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-folder'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='fromSimulationCaseId'
            type='select'
            label={t(`${PRE_TRANS}.simulationCase.label`)}
            title={t(`${PRE_TRANS}.simulationCase.title`)}
            value={fromSimulationCaseId || simulationCaseSelectors[0].key}
            selectors={simulationCaseSelectors}
            onChange={this.handleChangeSimulationCase}
          />
          <Input
            label={t(`${PRE_TRANS}.name.label`)}
            title={t(`${PRE_TRANS}.name.label`)}
            placeholder={t(`${PRE_TRANS}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Input
            label={t(`${PRE_TRANS}.description.label`)}
            title={t(`${PRE_TRANS}.description.label`)}
            placeholder={t(`${PRE_TRANS}.description.placeholder`)}
            name='description'
            type='text'
            rows={8}
            value={description}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            type='primary'
            title={submitDisabled ? t(`${PRE_TRANS}.create.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            icon='pe-7s-plus'
            isRef
          >
            {t(`${PRE_TRANS}.create.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t(`${PRE_TRANS}.cancel`)}
          >
            {t(`${PRE_TRANS}.cancel`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(NewSimulationCaseDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
