import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from 'react/components/Button/index'
import { Form, Text, TextBody, List } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import { FixedButton } from '../../DialogStyles'
import * as ApplicationActions from 'store/application/main/actions'
import * as MatrixActions from 'store/matrix/actions'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'

import BaseDialog from '../../BaseDialog'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.duplicateSimulationCaseDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  error: string,
  loading: boolean,
  duplicateLoading: boolean,
  duplicateError: string
};

class DuplicateSimulationCaseDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    error: '',
    loading: false,
    duplicateLoading: false,
    duplicateError: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Enter', this.handleCloneSubmit)
    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleCloneSubmit)
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DuplicateSimulationCaseDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleCloneSubmit = () => {
    const { selections, setCurrentProject, setLoadingStatus, setSelections, closeDialog } = this.props
    const simulationCaseIds = Object.keys(selections).filter(id => selections[id])
    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })

    setLoadingStatus(true)
    this.setState({ duplicateLoading: true, duplicateError: '' })
    closeDialog(DuplicateSimulationCaseDialog.NAME)
    setSelections(deselect)

    ApiClient
      .post(`${Network.URI}/simulation_case/duplicate`, { data: { simulationCaseIds } })
      .then(({ project }) => {
        if (project && project._id) {
          setCurrentProject(project)
        }

        this.setState({ duplicateLoading: false })
        setLoadingStatus(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        // TODO: handle error message ...
        this.setState({ duplicateLoading: false, duplicateError: 'ERROR' })
      })
  };

  @AnalyzeTime(0)
  render () {
    const { error, loading, duplicateLoading } = this.state
    const { currentProject, selections, t } = this.props

    const simulationCases = currentProject.simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => selections[simulationCase._id])

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-albums'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${PRE_TRANS}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                simulationCases.map(({ num, simulationCase }) =>
                  <li key={simulationCase._id}>{num}. {simulationCase.name}</li>)
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleCloneSubmit}
              error={error}
              loading={loading}
              disabled={duplicateLoading}
              icon='pe-7s-albums'
              isRef
            >
              {t(`${PRE_TRANS}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(DuplicateSimulationCaseDialog as any) as any) as any
