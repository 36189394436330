import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import Util from 'three/logic/Util'
import ThreeManager from 'three/ThreeManager'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import DataActions from '../../../store/data/actions'
import * as FilterActions from '../../../store/filter/actions'
import { SectionContainer } from '../AllInOne/styles'
import { CCGroup } from './CCGroup'
import { getElementFromPath } from 'store/elements/logic/getters'

const connector = connect((state: DefaultState) => ({
  CCElement: state.CCElement,
  elementsHashes: {
    AirLoop: state.AirLoop,
    CoolingLoop: state.CoolingLoop,
    CoolingZone: state.CoolingZone,
    LoopAssignment: state.LoopAssignment,
    Nozzle: state.Nozzle,
    Roller: state.Roller,
    RollerBearing: state.RollerBearing,
    RollerBody: state.RollerBody,
    SupportPoint: state.SupportPoint,
    Segment: state.Segment,
    SegmentGroupSupportPoints: state.SupportPoint,
    SegmentGroup: state.SegmentGroup,
    SensorPoint: state.SensorPoint,
    DataPoint: state.DataPoint,
    DataLine: state.DataLine,
    StrandGuide: state.StrandGuide,
  },
  selectedDataArray: state.data.selectedDataArray,
  rollerChildren: state.util.rollerChildren,
}), {
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type State = {
  //
};

export class CCElementList extends Component<PropsFromRedux, State> {
  timeoutHandle?: NodeJS.Timeout;

  constructor (props: PropsFromRedux) {
    super(props)
  }

  state = {
    //
  }

  selectElementPerAttributeUUID = (uuid: string) => {
    const { elementsHashes, selectedMultiEditElements, rollerChildren, setTerm } = this.props
    let selectedPaths: string[] = []

    if (!uuid) {
      selectedMultiEditElements()

      return
    }

    const elementTypes = Object.keys(elementsHashes)
    const isElementTypeSelected: any = {
      Nozzle: false,
      Roller: false,
      RollerBearing: false,
      RollerBody: false,
      SensorPoint: false,
      DataPoint: false,
      DataLine: false,
    }

    for (const type of elementTypes) {
      Object.values((elementsHashes as any)[type]).forEach((element: any) => {
        const matchedUUID = Object.keys(element || {})
          .filter(key => (key.includes('uuid') && uuid.includes(element[key])))

        if (matchedUUID.length) {
          const path = Util.getElementPath(element, elementsHashes, `${type}:${element._id}`)

          isElementTypeSelected[type] = true

          selectedPaths.push(path)
        }
      })
    }

    const action = this.shouldRollerViewGetToggled(isElementTypeSelected, rollerChildren)

    switch (action) {
      case 'FILTER_ONLY_ROLLER':
        selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER')
        break
      case 'FILTER_ONLY_ROLLER_CHILDREN':
        selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER_CHILDREN')
        break
      case 'TOGGLE':
        // toggle roller children
        ThreeManager.toggleRollerView()
        break
      default:
        break
    }

    // filter out elements which are invisible because of the filter
    setTerm('')
    selectedMultiEditElements()
    selectedMultiEditElements(selectedPaths, true)
    ThreeManager.base.jumpToFilter(selectedPaths[0])
  }

  filteredSelectedPaths (selectedPaths: string[], selectedType: 'ROLLER' | 'ROLLER_CHILDREN') {
    return selectedPaths.filter((path: string) => {
      const { type } = Util.getElementInfo(path)

      if (selectedType === 'ROLLER' && (type === 'RollerBearing' || type === 'RollerBody')) {
        return false
      }

      if (selectedType === 'ROLLER_CHILDREN' && type === 'Roller') {
        return false
      }

      return true
    })
  }

  shouldRollerViewGetToggled = (isElementTypeSelected: any, rollerChildren: any) => {
    if (
      rollerChildren === 1 &&
      isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'FILTER_ONLY_ROLLER'
    }

    if (
      rollerChildren !== 1 &&
      isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'FILTER_ONLY_ROLLER_CHILDREN'
    }

    if (
      rollerChildren === 1 &&
      !isElementTypeSelected.Roller &&
      (
        isElementTypeSelected.RollerBearing ||
        isElementTypeSelected.RollerBody
      )
    ) {
      return 'TOGGLE'
    }

    if (
      rollerChildren !== 1 &&
      isElementTypeSelected.Roller &&
      !isElementTypeSelected.RollerBearing &&
      !isElementTypeSelected.RollerBody
    ) {
      return 'TOGGLE'
    }

    return ''
  }

  @AnalyzeTime(0)
  render () {
    const { CCElement, elementsHashes, selectedDataArray } = this.props

    // cc elements grouped by group
    const ccElementsByGroup: any = { default: [] }
    const ccElements = Object.values((CCElement || {}))
    const elementsLength = ccElements.length

    for (let i = 0; i < elementsLength; i++) {
      if (ccElements[i]._group === undefined) {
        ccElementsByGroup.default.push(ccElements[i])
        continue
      }

      if (!ccElementsByGroup[ccElements[i]._group]) {
        ccElementsByGroup[ccElements[i]._group] = []
      }

      ccElementsByGroup[ccElements[i]._group].push(ccElements[i])
    }

    let ccElementAmountCounter = 0

    return (
      <div>
        <SectionContainer style={{ paddingTop: '5px' }}>
          {
            CCElement && Object.keys(ccElementsByGroup).map((groupKey, index) => {
              const groupIndex = index === 0 ? 0 : ccElementAmountCounter

              ccElementAmountCounter += (ccElementsByGroup[groupKey].length || 0)

              return (
                <CCGroup
                  key={index}
                  index={groupIndex}
                  ccGroup={ccElementsByGroup[groupKey]}
                  groupKey={groupKey as any}
                  selectElementPerAttributeUUID={this.selectElementPerAttributeUUID}
                  elementsHashes={elementsHashes}
                  selectedDataArray={selectedDataArray}
                />
              )
            })
          }
        </SectionContainer>
      </div>
    )
  }
}

export default withNamespaces('caster')(connector(CCElementList as any) as any) as any
