import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { FromField, FormLabel, InlineButton } from '../InputStyles'

import FilterButton from '../../../specific/FilterButton'
import SelectorField from './SelectorField'
import NumberField from './NumberField'
import InputField from './InputField'
import Icon from '../../../specific/Icon'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import styled from 'styled-components'

const connector = connect((store: DefaultState) => ({
  term: store.filter.term,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

const NoFilterSpacer = styled.div`
display: inline-block;
width: 20px;
text-align: center;
`

interface Props extends PropsFromRedux {
  onlyPositiveNumbers?: boolean,
  hideValue?: boolean
  error?: boolean,
  hasFilter?: boolean,
  disabled?: boolean,
  name: string,
  title?: string,
  elementType: string,
  value: string,
  label: any
  type: 'select' | 'number' | 'text',
  min?: number,
  max?: number,
  options: Array<any>,
  onChange: (e: any) => void,
  handleUndo: (event: any) => void,
  t: Translation
  key: any
  elementKey?: string,
  onCommentChange?: (typeAndKey: {type?: string, key?: string}, e: any) => void,
  isComparingCasters?: boolean
}

type State = {
  noMass?: boolean
};

export class Input extends Component<Props, State> {
  textInput: any = null;

  state = {
    noMass: true,
  }

  static getDerivedStateFromProps (nextProps: Props) {
    return {
      noMass: nextProps.error,
    }
  }

  componentDidMount () {
    this.setState({
      noMass: this.props.error,
    })
  }

  handleInputFocus = () => {
    this.focusTextInput()
  };

  handleCommentChange = (e: any) => {
    const { onCommentChange, elementType, elementKey } = this.props

    if (onCommentChange) {
      onCommentChange({ type: elementType, key: elementKey }, e)
    }
  }

  getInput = () => {
    const {
      type,
      min,
      max,
      value,
      label,
      disabled,
      options,
      name,
      hideValue,
      onChange,
      t,
      onCommentChange,
      isComparingCasters,
      onlyPositiveNumbers,
    } = this.props
    const { noMass } = this.state
    const mass = noMass === false
    const massValue = mass ? 'multiple Values' : value
    const placeholder = (mass && !hideValue) ? 'Multi-Value' : (typeof label === 'string' ? label : name.substring(1))

    if (isComparingCasters) {
      return (
        <InputField
          isDisabled
          massValue={hideValue ? undefined : massValue}
          placeholder={placeholder}
          onChange={!onCommentChange ? onChange : this.handleCommentChange}
          name={t(name)}
          inputRef={this.setTextInputRef}
          style={
            {
              maxWidth: '57px',
              paddingRight: '3px',
              marginRight: '10px',
            }
          }
        />
      )
    }

    switch (type) {
      case 'select':
        return (
          <SelectorField
            isDisabled={disabled}
            title={hideValue ? '' : t(massValue)}
            massValue={hideValue ? undefined : massValue !== undefined ? massValue.toString() : massValue}
            selectors={hideValue ? [] : options}
            onChange={onChange}
            name={t(name)}
            style={{ maxWidth: '75px' }}
          />
        )
      case 'number':
        return (
          <NumberField
            isDisabled={disabled}
            massValue={hideValue ? undefined : mass ? '' : value}
            placeholder={placeholder}
            onChange={onChange}
            mass={hideValue ? undefined : mass}
            value={hideValue ? undefined : value}
            name={t(name)}
            inputRef={this.setTextInputRef}
            style={{ maxWidth: '75px' }}
            onlyPositiveNumbers={onlyPositiveNumbers}
            min={min}
            max={max}
          />
        )
      default:
        return (
          <InputField
            isDisabled={disabled}
            massValue={hideValue ? undefined : massValue}
            placeholder={placeholder}
            onChange={!onCommentChange ? onChange : this.handleCommentChange}
            name={t(name)}
            inputRef={this.setTextInputRef}
            style={{ maxWidth: '75px' }}
          />
        )
    }
  };

  setTextInputRef = (element: any) => {
    this.textInput = element
  };

  focusTextInput = () => {
    if (this.textInput) {
      this.textInput.focus()
      this.textInput.select()
    }
  };

  render () {
    const { noMass } = this.state
    const {
      label,
      hasFilter,
      elementType,
      name,
      value,
      type,
      disabled,
      handleUndo,
      t,
      isComparingCasters,
    } = this.props

    return (
      <FromField
        style={
          {
            maxWidth: isComparingCasters ? '217px' : '275px',
            minWidth: isComparingCasters ? '217px' : '275px',
          }
        }
      >
        {
          hasFilter ? (
            <FilterButton
              elementType={elementType}
              value={value}
              name={name}
              disabled={noMass === false}
            />)
            : <NoFilterSpacer />
        }
        {
          label &&
            <FormLabel title={typeof label === 'object' ? null : label}>
              {label}
            </FormLabel>
        }
        {this.getInput()}
        {
          !isComparingCasters &&
            (
              <div>
                <InlineButton
                  onClick={this.handleInputFocus}
                  disabled={type === 'select' || disabled}
                  title={t('input.select')}
                >
                  <Icon icon='pencil-alt' />
                </InlineButton>
                <InlineButton onClick={handleUndo} value={t(name)} disabled={disabled} title={t('input.reset')}>
                  <Icon icon='reply' />
                </InlineButton>
              </div>
            )
        }
      </FromField>
    )
  }
}
// TODO: fix export type
// https://medium.com/knerd/typescript-tips-series-proper-typing-of-react-redux-connected-components-eda058b6727d
export default withNamespaces('caster')(connector(Input as any) as any) as any
