/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SensorPoint = {
  categories: {
    1: 'general',
  },
  fields: {
    _passln_coord:        { type: 'number', category: 1, defaultValue: 0, positive: true },
    _width_coord:         { type: 'number', category: 1, defaultValue: 0 },
    _id:                  { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _numericId:           { type: 'number', hidden: true },
    _originalId:          { type: 'number', hidden: true },
    _parentId:            { type: 'number', hidden: true },
  },
}
/* eslint-enable key-spacing */
