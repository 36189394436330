import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import cloneDeep from 'clone-deep'

import * as VisualizationActions from 'store/visualization/actions'
import { FormWrapper, InputWrapper } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import Input from 'react/specific/Input'
import VisUtil from 'react/visualization/VisUtil'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
  showConfigDialog: VisualizationActions.showConfigDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean,
  t: Translation,
}

type State = {
  typeSelectors: Array<{ key: string, value: string}>,
  allConfigIds: any[],
  config: any,
  tileConfig: any
};

class GeneralTab extends Component<Props, State> {
  timeoutRef?: number;

  constructor (props: Props) {
    super(props)
    const { editDialogConfigId, plotConfigs, currentTileId, tileConfigs, data } = props
    const { configId } = tileConfigs[currentTileId || ''] || {}

    const group = /_derived_/.test(configId)
      ? 'derived'
      : !/_merged_/.test(configId) ? plotConfigs[configId]?.group : ''

    const typeSelectors = VisUtil.getTypeSelectors(group)

    // clean up duplicate data sources
    const cleanPlotConfigs = cloneDeep(plotConfigs)

    for (const key in cleanPlotConfigs) {
      const config = cleanPlotConfigs[key]

      if (config.group === 'dynamicDataSource' && config.id === config.key) {
        delete cleanPlotConfigs[key]
      }
    }

    const allConfigIds = VisUtil.getConfigSelectors(data, cleanPlotConfigs)

    const config = allConfigIds.filter((config) => {
      return config.key === (configId || editDialogConfigId)
    })[0]

    this.state = {
      typeSelectors,
      config,
      allConfigIds,
      tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
    }
  }

  // @AnalyzeTime(0)
  componentDidUpdate = (pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        this.timeoutRef = undefined
      }, 1000)
    }
  };

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  };

  // @AnalyzeTime(0)
  handleInputDataTile = (event: any) => {
    const { tileConfig, typeSelectors } = this.state
    const { plotConfigs, editDialogConfigId, saveTileConfig, showConfigDialog } = this.props
    const { name, value } = event.target
    const val: any = value

    let editTileConfig = {
      ...tileConfig,
      [name]: val,
    }

    let plotType: typeof typeSelectors | string = [ ...typeSelectors ]

    if (name === 'configId') {
      if (!val) {
        return
      }

      delete editTileConfig.isDynamicData
      delete editTileConfig.isMergedDynamicData
      editTileConfig.legendOptions = {}
      // TODO: keep some settings from the previous configId, like colors and stuff

      // because if it is not a merged plot, it cant have an additional axis, but if the info is still on the object
      // the plot will render smaller because of the space previously used by the extra axis info
      if (editTileConfig.additionalYAxes) {
        editTileConfig.additionalYAxes = []
      }

      if ((plotConfigs[val] || {}).isMergedDynamicDataSource) {
        editTileConfig.isMergedDynamicData = true
        editTileConfig.isDynamicData = false
      }

      if ((plotConfigs[val] || {}).group === 'dynamicDataSource') {
        editTileConfig.isDynamicData = true
      }

      const group = /_derived_/.test(val)
        ? 'derived'
        : !/_merged_/.test(val) ? plotConfigs[val]?.group : ''

      const typeSelectors = VisUtil.getTypeSelectors(group) || []
      const type = editTileConfig.type || plotConfigs[editDialogConfigId].type

      plotType = typeSelectors.map(type => type.key).includes(type) ? type : typeSelectors[0].key

      editTileConfig = {
        ...editTileConfig,
        type: plotType,
      }

      this.setState({ typeSelectors })
      saveTileConfig(editTileConfig)

      // change the editDialogConfigId to the new configId
      showConfigDialog(val, true)
    }

    if (name === 'type') {
      plotType = typeSelectors.map(type => type.key).includes(val) ? val : typeSelectors[0].key

      editTileConfig = {
        ...editTileConfig,
        type: plotType,
      }

      saveTileConfig(editTileConfig)
    }

    this.setState({
      tileConfig: editTileConfig,
    })
  };

  @AnalyzeTime(0)
  render () {
    const {
      editDialogConfigId,
      plotConfigs,
      fullscreen,
      t,
    } = this.props

    const { tileConfig, typeSelectors, config, allConfigIds } = this.state

    const alphabeticallySortedConfigIds = allConfigIds
      .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    const { decimals: plotConfigDecimals } = plotConfigs[editDialogConfigId] || {}

    const {
      type,
      name,
      configId,
      xDomainMin,
      xDomainMax,
      yDomainMin,
      yDomainMax,
      decimals,
    } = tileConfig

    const plotName = allConfigIds
      .find(selector => selector.key === (configId || editDialogConfigId))?.value || 'Unnamed Plot'

    return (
      <FormWrapper fullscreen={fullscreen}>
        <InputWrapper fullscreen={fullscreen}>
          <Input
            label={t('plotConfig.name.name')}
            name='name'
            type='text'
            title={name || ''}
            value={name || ''}
            placeholder={plotName}
            onChange={this.handleInputDataTile}
          />
          <Input
            label={t('plotConfig.type.label')}
            name='type'
            type='select'
            title={type || (typeSelectors[0] || {}).value}
            value={type || (typeSelectors[0] || {}).key || ''}
            selectors={typeSelectors}
            onChange={this.handleInputDataTile}
          />
        </InputWrapper>
        <InputWrapper fullscreen={fullscreen}>
          <Input
            label={t('plotConfig.configId.label')}
            name='configId'
            type='select'
            title={config && config.value}
            value={configId || editDialogConfigId}
            selectors={alphabeticallySortedConfigIds}
            onChange={this.handleInputDataTile}
          />
        </InputWrapper>
        {
          type !== 'gage' && type !== 'text' && type !== 'contour' &&
            <InputWrapper fullscreen={fullscreen}>
              <Input
                label={t('plotConfig.yDomain.label')}
                title={t('plotConfig.yDomain.title')}
                name='yDomain'
                type='range'
                min={yDomainMin}
                max={yDomainMax}
                onChange={this.handleInputDataTile}
              />
              <Input
                label={t('plotConfig.xDomain.label')}
                title={t('plotConfig.xDomain.title')}
                name='xDomain'
                type='range'
                min={xDomainMin}
                max={xDomainMax}
                onChange={this.handleInputDataTile}
              />
            </InputWrapper>
        }
        {
          type === 'gage' &&
            <InputWrapper fullscreen={fullscreen}>
              <Input
                label={t('plotConfig.decimals.label')}
                title={t('plotConfig.decimals.title')}
                name='decimals'
                type='select'
                value={decimals || decimals === 0 ? decimals : plotConfigDecimals}
                selectors={Array(10).fill(0).map((v, i) => i)} // TODO: test this, it used to be fill()
                onChange={this.handleInputDataTile}
              />
            </InputWrapper>
        }
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(GeneralTab as any) as any) as any
