import BaseObject from './BaseObject'
import Util from '../logic/Util'
import LodUtil from '../logic/LodUtil'
import * as THREE from 'three'

import Roller from './Roller'
import { AnalyzeTime } from 'Util'

export default class RollerBearing extends BaseObject {
  static _3DGeometryCache: Record<string, THREE.CylinderGeometry> = {}
  static _2DLineGeometryCache: Record<string, THREE.BufferGeometry> = {}
  static _2DPlaneGeometryCache: Record<string, THREE.PlaneGeometry> = {}

  static defaultPlaneSectionView = new THREE.MeshBasicMaterial({
    color: '#383838',
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  })

  static defaultLineSectionView = new THREE.LineBasicMaterial({ color: '#6a4dff' })
  static defaultMaterial = new THREE.MeshStandardMaterial({ color: '#383838' })
  static phantomMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.4 })
  static selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41' }) // , transparent: true, opacity: 0.75
  static deletedMaterial = new THREE.MeshStandardMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })
  static selectedDeletedMaterial = new THREE.MeshStandardMaterial({ color: '#da4515', transparent: true, opacity: 0.6 })

  static DetailDistance = [ 0, 3, 8 ]

  @AnalyzeTime(0)
  static get3DGeometry (radius: number, width: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${radius}_${width}_${radial}`
    let geometry = RollerBearing._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(radius, radius, width, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD);

      (THREE.GeometryUtils as any).computeVertexNormals(geometry, 80)

      RollerBearing._3DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  @AnalyzeTime(0)
  static get2DGeometry (bodyWidthStart: number, bodyWidth: number, diameter: number) {
    const lineGeometryKey = `${bodyWidthStart}_${bodyWidth}_${diameter}`
    const planeGeometryKey = `${bodyWidth}_${diameter}`

    let lineGeometry = RollerBearing._2DLineGeometryCache[lineGeometryKey]
    let planeGeometry = RollerBearing._2DPlaneGeometryCache[planeGeometryKey]

    if (!lineGeometry) {
      lineGeometry = new (THREE as any).Geometry()

      ;(lineGeometry as any).vertices.push(
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      )

      RollerBearing._2DLineGeometryCache[lineGeometryKey] = lineGeometry
    }

    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneBufferGeometry(bodyWidth, diameter)

      RollerBearing._2DPlaneGeometryCache[planeGeometryKey] = planeGeometry
    }

    return { lineGeometry, planeGeometry }
  }

  clickableObjects: any
  parentContainer: any
  isPhantom?: boolean
  sectionDetail?: any
  width?:number
  startWidth?:number
  radius?:number

  constructor (container: any, parent: any, clickableObjects: any, phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.parentContainer = phantomGroup || parent.objects.roller

    if (phantomGroup) {
      const parentRoller = this.parent.objects.roller
      const parentPosition = parentRoller.position
      const parentRotation = parentRoller.rotation

      this.parentContainer.position.copy(parentPosition)
      this.parentContainer.rotation.copy(parentRotation)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore

  setValues (
    rollerBearingData: any,
    _plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _: any,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.isPhantom = isPhantom
    this.sectionDetail = sectionDetail

    if (this.sectionDetail) {
      this._renderSectionDetail(rollerBearingData, path)
    }
    else {
      this._renderNormal(rollerBearingData, path, isDeleted, isHidden)
    }

    this.setSelected((this.objects[LodUtil.LOD_ROLLER_BEARING] || this.objects.rollerBearingPlane).userData.selected)
  }

  private disposeChildren (parent: any): void {
    for (const child of parent.children) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      parent.remove(child)
    }
  }

  @AnalyzeTime(0)
  dispose (): void {
    // const name = `${this.path}_Phantom`
    super.dispose()

    if (this.container) {
      for (const child of this.container.children) {
        if (child.children.length) {
          this.disposeChildren(child)
        }
      }

      this.disposeChildren(this.container)
    }

    this.objects = {}
    this.clickableObjects = []
    this.container = undefined
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    const { deleted } = this.parentContainer.userData

    if (this.isPhantom || (!this.objects[LodUtil.LOD_ROLLER_BEARING] && !this.objects.rollerBearingPlane)) {
      return
    }

    if (this.sectionDetail) {
      const material = isSelected
        ? (deleted ? RollerBearing.selectedDeletedMaterial : RollerBearing.selectedMaterial)
        : (deleted ? RollerBearing.deletedMaterial : RollerBearing.defaultPlaneSectionView)

      this.objects.rollerBearingPlane.material = material.clone()

      return
    }

    const element = this.objects[LodUtil.LOD_ROLLER_BEARING] || this.objects.rollerBearingPlane

    element.userData.selected = isSelected

    element.children.forEach((child: any) => {
      if (child.name.includes('Roller_Bearing')) {
        return
      }

      const visible = child.material.visible

      const material = isSelected
        ? (deleted ? RollerBearing.selectedDeletedMaterial : RollerBearing.selectedMaterial)
        : (deleted
          ? RollerBearing.deletedMaterial
          : RollerBearing.defaultMaterial
        )

      child.material = material.clone()
      child.material.visible = visible
    })
  }

  @AnalyzeTime(0)
  _setVisibility (visible: boolean) {
    const {
      [LodUtil.LOD_ROLLER_BEARING]: lod,
      rollerGroupText,
      rollerGroupBacksideText,
      rollerBearingLine,
      rollerBearingPlane,
    } = this.objects

    if (!this.isPhantom && !this.sectionDetail) {
      rollerGroupText.material.visible = visible
      rollerGroupBacksideText.material.visible = visible
    }

    if (lod) {
      lod.visible = visible
    }

    if (rollerBearingLine && rollerBearingPlane) {
      rollerBearingLine.visible = visible
      rollerBearingPlane.visible = visible
    }
  }

  @AnalyzeTime(0)
  setVisibility (displayType: number) {
    if (this.isHidden) {
      return
    }
    else if (this.sectionDetail) {
      displayType = Roller.DisplayTypes.RollerChildren
    }

    switch (displayType) {
      case Roller.DisplayTypes.All:
        this._setVisibility(true)
        break
      case Roller.DisplayTypes.Roller:
        this._setVisibility(false)
        break
      case Roller.DisplayTypes.RollerChildren:
        this._setVisibility(true)
        break
      default:
    }
  }

  @AnalyzeTime(0)
  show () {
    if (this.isHidden) {
      return
    }

    super.show()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = true
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = true
      }
    })
  }

  @AnalyzeTime(0)
  hide () {
    super.hide()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => { // TODO: review
          child.material.visible = false
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = false
      }
    })
  }

  @AnalyzeTime(0)
  _renderNormal (rollerBearingData: any, path: string, isDeleted: boolean, isHidden: boolean) {
    // eslint-disable-next-line camelcase
    const { _width, _width_start, _numericId } = rollerBearingData

    this.width = _width / 1000
    // eslint-disable-next-line camelcase
    this.startWidth = _width_start / 1000
    this.radius = this.parent.radius * 0.8

    const lod = new THREE.LOD()

    // if (this.isPhantom) {
    //   const lodPos = this.parent.objects.roller.position

    //   lod.position.set(lodPos.x, lodPos.y, lodPos.z)
    // }

    for (let i = 0; i < 3; i++) {
      const geometry = RollerBearing.get3DGeometry(this.radius, this.width, i, this.isPhantom)

      const material = !this.isPhantom
        ? RollerBearing.defaultMaterial
        : RollerBearing.phantomMaterial

      const mesh = new THREE.Mesh(geometry, material.clone())

      mesh.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
      mesh.type = 'RollerBearing';
      (mesh as any).path = path

      lod.addLevel(mesh, RollerBearing.DetailDistance[i])

      const oldElement = this.objects[LodUtil.LOD_ROLLER_BEARING]
        ? this.objects[LodUtil.LOD_ROLLER_BEARING].getObjectByName(mesh.name)
        : null

      // Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      if (oldElement) {
        for (const oldMesh of oldElement.children) {
          Util.addOrReplaceInList(oldMesh, mesh, this.clickableObjects)
        }
      }
      else {
        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerBearingLoD = lod

    rollerBearingLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`;
    (rollerBearingLoD as any).type = 'RollerBearing';
    (rollerBearingLoD as any).path = path

    rollerBearingLoD.userData.deleted = isDeleted
    rollerBearingLoD.userData.hidden = isHidden
    rollerBearingLoD.userData.selected = this.objects[LodUtil.LOD_ROLLER_BEARING]?.userData?.selected

    this.objects[LodUtil.LOD_ROLLER_BEARING] = rollerBearingLoD

    const rollWidth = this.parent.rollerData._roll_width / 1000 / 2

    const side = this.container.userData.side

    switch (side) {
      case 'NarrowFaceRight':
        rollerBearingLoD.position.x = -(this.startWidth + this.width / 2 - rollWidth)
        break
      case 'NarrowFaceLeft':
        rollerBearingLoD.position.x = this.startWidth + this.width / 2 - rollWidth
        break
      default:
        rollerBearingLoD.position.x = this.startWidth + this.width / 2
    }

    if (!this.isPhantom) {
      const { number } = this.parent.objects.rollerGroupText.userData

      const rollerGroupText = Util.getText(number, 0.06, false, true)
      const rollerGroupBacksideText = rollerGroupText.clone()

      rollerGroupText.name = `Roller_Bearing_Number_${number}_${_numericId}`
      rollerGroupBacksideText.name = `Roller_Bearing_Number_Backside${number}_${_numericId}`

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.position.set(rollerBearingLoD.position.x + this.width / 2 + 0.0001, 0, 0)
      rollerGroupBacksideText.rotateY(-Util.RAD90)
      rollerGroupBacksideText.position.set(rollerBearingLoD.position.x - (this.width / 2 + 0.0001), 0, 0)

      this.objects.rollerGroupText = rollerGroupText
      this.objects.rollerGroupBacksideText = rollerGroupBacksideText

      Util.addOrReplace(this.parentContainer, rollerGroupText)
      Util.addOrReplace(this.parentContainer, rollerGroupBacksideText)
    }

    Util.addOrReplace(this.parentContainer, rollerBearingLoD)
  }

  @AnalyzeTime(0)
  _renderSectionDetail (rollerBearing: any, path: string) {
    const bodyWidthStart = rollerBearing._width_start / 1000
    const bodyWidth = rollerBearing._width / 1000
    const bearingDiameter = this.parent.radius * 2 * 0.9

    const { lineGeometry, planeGeometry } = RollerBearing.get2DGeometry(bodyWidthStart, bodyWidth, bearingDiameter)

    const line = new THREE.Line(lineGeometry, RollerBearing.defaultLineSectionView)

    line.name = `RollerBearingLine_${path}`

    const plane = new THREE.Mesh(
      planeGeometry,
      !this.isPhantom ? RollerBearing.defaultPlaneSectionView : RollerBearing.phantomMaterial,
    )

    plane.name = `RollerBearingPlane_${path}`
    plane.type = `RollerBearing`;
    (plane as any).path = path

    const rollWidth = this.parent.rollerData._roll_width / 1000 / 2
    const side = this.container.userData.side

    // if (this.isPhantom) {
    //   line.position.copy(this.parent.objects.roller.position)
    //   plane.position.copy(this.parent.objects.roller.position)
    //   line.rotation.copy(this.parent.objects.roller.rotation)
    //   plane.rotation.copy(this.parent.objects.roller.rotation)
    // }

    switch (side) {
      case 'NarrowFaceRight':
        line.rotation.z += Util.RAD180
        line.position.x = rollWidth
        plane.position.x = -(bodyWidth / 2 + bodyWidthStart - rollWidth)
        break
      case 'NarrowFaceLeft':
        line.position.x = -rollWidth
        plane.position.x = bodyWidth / 2 + bodyWidthStart - rollWidth
        break
      case 'LooseSide':
        plane.rotation.x += Util.RAD180
        plane.position.x = bodyWidth / 2 + bodyWidthStart
        break
      default:
        plane.position.x = bodyWidth / 2 + bodyWidthStart
    }

    this.objects.rollerBearingLine = line
    this.objects.rollerBearingPlane = plane

    Util.addOrReplace(this.parentContainer, line)
    Util.addOrReplace(this.parentContainer, plane, this.clickableObjects)
  }
}
