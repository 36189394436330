export enum coolingLoopActionsEnum {
  ACTION_ADD_COOLING_LOOP = 'CoolingLoop/ACTION_ADD_COOLING_LOOP',
  DELETE = 'CoolingLoop/DELETE',
  DELETE_MULTIPLE = 'CoolingLoop/DELETE_MULTIPLE',
  UPDATE = 'CoolingLoop/UPDATE',
}

export function addCoolingLoops (coolingLoopHash: CoolingZoneHash) {
  return {
    type: coolingLoopActionsEnum.ACTION_ADD_COOLING_LOOP,
    coolingLoopHash,
  }
}

const coolingLoopReducers = {
  [coolingLoopActionsEnum.ACTION_ADD_COOLING_LOOP]:
  (_state:CoolingLoopHash, action: {coolingLoopHash:CoolingLoopHash}) => (
    action.coolingLoopHash
  ),

  [coolingLoopActionsEnum.DELETE]: (state: CoolingLoopHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [coolingLoopActionsEnum.DELETE_MULTIPLE]: (state: CoolingLoopHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [coolingLoopActionsEnum.UPDATE]: (state: CoolingLoopHash, action: {elements: CoolingLoopHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (state:CoolingLoopHash = {}, action: any) {
  const handler = coolingLoopReducers[action.type as coolingLoopActionsEnum]

  return handler ? handler(state, action) : state
}
