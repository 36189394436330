import styled, { css } from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

export const Wrapper = styled.div`${({ theme, id }) => css`
  height: 100%;
  position: relative;
`}`

export const Content = styled.div<{isFrozen?: boolean}>`${({ theme, isFrozen }) => css`
  position: absolute;
  padding: 2px 2px 0 2px;
  top: 10px;
  right: 10px;
  bottom: 30px;
  left: 10px;
  overflow-y: auto;
  white-space: pre-wrap;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
  cursor: ${isFrozen ? 'default' : 'pointer'};
  word-break: break-word;
`}`

export const Change = styled.div`${({ theme }) => css`
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  height: 12px;
  font-size: 12px;
  color: ${theme.colors.swatch14}
  text-align: right;
`}`

export const EditContent = styled.textarea`${({ theme }) => css`
  position: absolute;
  top: 10px;
  bottom: 63px;
  left: 10px;
  width: calc(100% - 20px);
  word-wrap: break-word;
  font-size: 14px;
  border: none;
  background: ${theme.colors.swatch6};
  color: ${theme.colors.swatch9};
  outline: none;
  resize: none;
  font-family: 'Roboto', sans-serif;
  letter-spacing: -0.01em;
`}`

export const ButtonWrapper = styled.div`${({ theme }) => css`
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  text-align: left;
`}`

export const Spinner = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`
