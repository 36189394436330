import { initialState, DataActionsEnum } from './consts'
import additionalDataReducers from './reducers/additionalDataReducers'
import editionReducers from './reducers/editionReducers'
import parseReducers from './reducers/parseReducers'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  ...additionalDataReducers,
  ...editionReducers,
  ...parseReducers,

  [DataActionsEnum.ACTION_RESET_GAP_WARNINGS]: (state: DataState) => ({
    ...state,
    gapWarnings: initialState.gapWarnings,
  }),
}

export default function (state: DataState = initialState, action: any) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
