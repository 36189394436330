import type { ElementsHashes } from 'types/state'

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case 'FixedSide': distance2passline = radius
      break
    case 'LooseSide': distance2passline = radius + thickness
      break
    case 'NarrowFaceLeft':
    case 'NarrowFaceRight': distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}

export function getNewIdForType (data: XMLData, type: string): number {
  const key = `_lastTypeId_${type}`
  const { XML_Generation_Parameters: parameters } = data.root

  ;(parameters as any)[key] = Number((parameters as any)[key] || '0') + 1

  return (parameters as any)[key]
}

export function getChildrenArrayByIds<Type> (elementsHashes: ElementsHashes, type: CasterElementNames, ids: number[]) {
  if (!elementsHashes[type] || !ids) {
    return []
  }

  const elements: Type[] = []

  ids.forEach(id => {
    const element = elementsHashes[type][id]

    if (elementsHashes[type][id]) {
      elements.push(element)
    }
  })

  return elements
}
