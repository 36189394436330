import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import { AnalyzeTime } from 'Util'

const styles = (theme: any) => ({
  paper: {
    display: 'inline-block',
  },
  tabs: {
    minHeight: 32,
    // width: 356, // TODO: check if this is still needed
  },
  tab: {
    minWidth: 0,
    minHeight: 32,
    padding: '3px 12px',
    '& span': {
      textTransform: 'none',
      fontSize: '0.8125rem',
    },
  },
  disabled: {
    cursor: 'not-allowed',
  },
})

type Option = {
  label?: string,
  title?: string
};

type Props = {
  value: number,
  disabled?: boolean,
  options: Array<Option>,
  classes: any,
  onChange: (value: number) => void
};

class MultiStateSwitch extends Component<Props> {
  // @AnalyzeTime(0)
  handleChange = (event: any, newValue: number) => {
    const { value, onChange } = this.props

    if (value === newValue) {
      return
    }

    onChange(newValue)
  };

  @AnalyzeTime(0)
  render () {
    const { value, options, classes, disabled } = this.props

    let realValue = value || 0

    realValue = realValue > options.length - 1 ? options.length - 1 : realValue

    return (
      <Paper className={classes.paper} square elevation={0}>
        <Tabs
          className={disabled ? [ classes.tabs, classes.disabled ].join(' ') : classes.tabs}
          value={realValue}
          data-value={realValue}
          indicatorColor='primary'
          textColor='primary'
          onChange={this.handleChange}
        >
          {
            options && options.map(({ label, title }, index) =>
              <Tab key={index} label={label} title={title} className={classes.tab} disabled={disabled} />)
          }
        </Tabs>
      </Paper>
    )
  }
}

export default withStyles(styles, { withTheme: true })(MultiStateSwitch as any) as any
