import { Network } from 'network/Network'
import React, { PureComponent } from 'react'
import { withNamespaces } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import ApiClient from 'store/apiClient'
import type { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import Util from 'three/logic/Util'

import { renameElement } from 'store/elements/actions'

import Icon from '../../specific/Icon'

import { Elements, RenameButton, Spacer, TargetButton, RenameInput } from '../ElementGroupStyles'
import FeatureFlags from 'react/FeatureFlags'
import { OptionsObject, withSnackbar } from 'notistack'
import ThreeManager from 'three/ThreeManager'
import SectionView from 'three/views/SectionView'

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  currentSimulationCase: state.application.main.currentSimulationCase,
  elementsHashesFromRedux: {
    AirLoop: state.AirLoop,
    CoolingLoop: state.CoolingLoop,
    CoolingZone: state.CoolingZone,
    LoopAssignment: state.LoopAssignment,
    Nozzle: state.Nozzle,
    Roller: state.Roller,
    RollerBearing: state.RollerBearing,
    RollerBody: state.RollerBody,
    Segment: state.Segment,
    SegmentGroup: state.SegmentGroup,
    SensorPoint: state.SensorPoint,
    StrandGuide: state.StrandGuide,
    DataPoint: state.DataPoint,
    DataLine: state.DataLine,
  },
}), {
  renameElement: renameElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  type?: string,
  elementName: string,
  element: any,
  treeStatus: any,
  selected: boolean,
  childrenElement: any,
  name: string,
  visible: boolean,
  active: boolean,
  target: boolean,
  hasChildren: boolean,
  depth: number,
  fullPath: string
  onClick: (event: any, name: string) => void,
  onFilter: (ctrl: boolean, name: string, fullPath: string) => void,
  onTarget: (name: string, fullPath: string) => void,
  t: Translation
}

type State = {
  isRenaming: boolean,
  element: any,
  nameValue: string,
}

class ElementsComponent extends PureComponent<Props, State> {
  inputRef: any = null
  constructor (props: Props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      isRenaming: false,
      element: props.element,
      nameValue: `${props.elementName || props.name}`,
    }
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.element !== this.props.element) {
      this.setState({
        element: this.props.element,
        isRenaming: false,
      })
    }
  }

  // @AnalyzeTime(0)
  handleClick = (event: any) => {
    event.stopPropagation()

    if (event.target === event.currentTarget) {
      const { onClick, name, element, hasChildren, fullPath } = this.props

      hasChildren ? onClick(element, `${name}:${element._id}`) : onClick(event, fullPath)
    }
  };

  handleFilter = (event: any) => {
    event.stopPropagation()

    const { onFilter, name, fullPath } = this.props

    const ctrl = event.ctrlKey || event.metaKey

    onFilter(ctrl, name, fullPath)
  }

  handleTarget = (event: any) => {
    event.stopPropagation()

    const { onTarget, name, fullPath } = this.props

    onTarget(name, fullPath)
  }

  handleSetRenaming = (e: any) => {
    e.stopPropagation()

    if (this.state.isRenaming) {
      this.setState({ isRenaming: false })
    }
    else {
      this.setState({ isRenaming: true })

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
          }
        })
      })
    }
  }

  handleBlur = () => {
    this.setState({ isRenaming: false, nameValue: this.getElementName() })
  }

  handleRenameInputClick = (e: any) => {
    e.stopPropagation()
  }

  handleRenameInputChange = (e: any) => {
    this.setState({ nameValue: e.target.value })
  }

  handleSubmitNameChange = async (newName: string) => {
    const { currentSimulationCase, fullPath, renameElement, enqueueSnackbar } = this.props
    const { type, id } = Util.getElementInfo(fullPath)

    if (!type) {
      return
    }

    if (!newName) {
      enqueueSnackbar('Error: empty new name', { variant: 'error', autoHideDuration: 2000 })

      return
    }

    try {
      await ApiClient.patch(
        `${Network.URI}/caster_data/${currentSimulationCase._id}/rename`,
        { data: { newName, targetPath: fullPath } },
      )
      // update name in redux

      renameElement(type as any, id, newName)

      if (type === 'SegmentGroup') {
        const { elementsHashesFromRedux } = this.props
        const segmentGroup = elementsHashesFromRedux.SegmentGroup[id]

        if (!segmentGroup) {
          return
        }

        const segmentIds = (segmentGroup as any)['#SegmentIds']

        if (!segmentIds) {
          return
        }

        // build each segment's path with its id and the segment group's path
        const segmentPaths = segmentIds.map((segmentId: string) => {
          return `${fullPath}/Segment:${segmentId}`
        })

        if (SectionView.currentSegmentGroup.id === id) {
          SectionView.currentSegmentGroupNameChanged = true
        }

        ThreeManager.base.updateSegments(segmentPaths, newName)
      }
    }
    catch (error) {
      // display error
      enqueueSnackbar('Error: could not rename element', { variant: 'error', autoHideDuration: 2000 })
      console.log('error', error)
    }
  }

  handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      this.setState({ isRenaming: false })
      this.handleSubmitNameChange(e.target.value)
    }
  }

  getElementNameField = () => {
    const { isRenaming } = this.state
    const { hasChildren, visible } = this.props
    const arrow = visible
      ? <Spacer onClick={this.handleClick}>&#9662;</Spacer>
      : <Spacer onClick={this.handleClick}>&#9656;</Spacer>

    return (
      <div
        className='elementName'
        onClick={this.handleClick}
        title={this.getElementName() + this.getElementDescription()}
      >
        {hasChildren ? arrow : <Spacer />} {
          isRenaming
            ? (
              <RenameInput
                onChange={this.handleRenameInputChange}
                onClick={this.handleRenameInputClick}
                onBlur={this.handleBlur}
                onKeyUp={this.handleKeyUp}
                ref={this.inputRef}
                value={this.state.nameValue}
              />
            )
            : this.getElementName()
        }
        {this.getElementDescription()}
      </div>
    )
  }

  getElementName = () => {
    const { elementName, name } = this.props

    return `${elementName || name}`
  }

  getElementDescription = () => {
    const { element, type, name } = this.props

    if (type === 'Segment' && !!name) {
      const side = element._FixedLooseSide.includes('Face')
        ? element._FixedLooseSide.replace('Face', 'Side')
        : element._FixedLooseSide

      return ` [${side}]`
    }
    else if (type === 'SegmentGroup') {
      return ''
    }

    return (element && element._FixedLooseSide ? ` [${element._FixedLooseSide}]` : `:${element._id}`)
  }

  @AnalyzeTime(0)
  render () {
    const {
      treeStatus,
      selected,
      target,
      active,
      depth,
      t,
      type,
      featureFlags,
    } = this.props

    const canRenameElements = FeatureFlags.canRenameElements(featureFlags)
    const canJumpToElement = FeatureFlags.canJumpToElement(featureFlags)
    const canSetFilterAndJumpToElement = FeatureFlags.canSetFilterAndJumpToElement(featureFlags)

    return (
      <Elements
        treeStatus={treeStatus}
        selected={selected}
        onClick={this.handleClick}
        spacer={depth * 15}
        targetButtonFilterAndJump={active}
        targetButtonJump={target}
        renameButton={(type === 'Segment' || type === 'SegmentGroup') && canRenameElements}
      >
        {this.getElementNameField()}
        {
          (type === 'Segment' || type === 'SegmentGroup') && canRenameElements && (
            <RenameButton onMouseDown={this.handleSetRenaming} active={this.state.isRenaming} title='Rename'>
              <Icon icon='pencilAlt' />
            </RenameButton>
          )
        }
        {
          canJumpToElement && (
            <TargetButton onClick={this.handleTarget} active={target} title={t('treeView.jumpToElement')}>
              <Icon icon='crosshairs' />
            </TargetButton>
          )
        }
        {
          canSetFilterAndJumpToElement && (
            <TargetButton onClick={this.handleFilter} active={active} title={t('treeView.setFilter')}>
              <Icon icon='filter' />
            </TargetButton>
          )
        }
      </Elements>
    )
  }
}

export default withSnackbar(withNamespaces('caster')(connector(ElementsComponent as any)) as any) as any
