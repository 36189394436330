/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const Segment = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    _FixedLooseSide: {
      type: 'select',
      category: 1,
      defaultValue: 'FixedSide',
      options: [ 'FixedSide', 'LoseSide', 'NarrowFaceLeft', 'NarrowFaceRight' ],
    },
    _segtype: {
      type: 'select',
      category: 2,
      defaultValue: 'foot',
      options: [ 'foot', 'bow' ],
    },
    _passln_coord:  { type: 'number', category: 1, defaultValue: 0, positive: true },
    _id:            { type: 'number', category: 2, defaultValue: 0, disabled: true },
    _name:          { type: 'text', category: 2, defaultValue: '' },
    _numericId:     { type: 'number', hidden: true },
    _originalId:    { type: 'number', hidden: true },
    _parentId:      { type: 'number', hidden: true },
  },
}
/* eslint-enable key-spacing */
