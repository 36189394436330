import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import { Form, Button } from '../DialogStyles'
import Input from 'react/specific/Input'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

type Props = {
  onInput: (e: any) => void,
  onSubmit: (e?: any) => void,
  t: Translation,
  name: string,
  type: string,
  selectedId: string,
  allConfigIds: Array<any>,
  allFiles: Array<any>,
  allDataSources: Array<any>,
  typeSelectors: Array<any>
};

class EditBox extends Component<Props> {
  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Enter', this.handleEnter)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleEnter)
  }

  // @AnalyzeTime(0)
  handleEnter = () => this.props.onSubmit();

  @AnalyzeTime(0)
  render () {
    const {
      onInput: handleInput,
      onSubmit: handleSubmit,
      name,
      selectedId,
      allConfigIds,
      allFiles,
      allDataSources,
      typeSelectors,
      type,
      t,
    } = this.props

    const sortedSelectors = [ ...allConfigIds, ...allFiles, ...allDataSources ]
      .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    return (
      <Form>
        <Input
          label={t('addPlotDialog.tileName.label')}
          title={t('addPlotDialog.tileName.title')}
          name='name'
          type='text'
          value={name}
          autoFocus
          onChange={handleInput}
        />
        <Input
          label={t('addPlotDialog.configId.label')}
          title={t('addPlotDialog.configId.title')}
          name='configId'
          type='select'
          value={selectedId}
          selectors={sortedSelectors}
          onChange={handleInput}
        />
        <Input
          label={t('addPlotDialog.type.label')}
          title={t('addPlotDialog.type.title')}
          name='type'
          type='select'
          value={type || typeSelectors[0].key}
          selectors={typeSelectors}
          onChange={handleInput}
          data-tile
        />
        <Button
          onClick={handleSubmit}
          disabled={!selectedId}
          title={t('addPlotDialog.button.title')}
        >
          <div className='cut'><i className='pe-7s-check' /></div>
          <span>{t('addPlotDialog.button.label')}</span>
        </Button>
      </Form>
    )
  }
}

export default withNamespaces('visualization')(EditBox as any) as any
