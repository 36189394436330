import {
  positivePropertiesAndInKeyList,
  getElementFromPath,
} from 'store/elements/logic/index'
import { DataActionsEnum, initialState } from '../consts'
import {
  addPathsIfNotAlreadyInList,
  ensureActionPathIsArray,
  handleDirtyDeletePaths,
  handleUndoChanges,
  setEditValuesForEachType,
  updateListWithSelectedData,
} from '../logic'

const editionReducers = {
  [DataActionsEnum.ACTION_RESET_CHANGES]: (state: DataState) => ({
    ...state,
    hasChanges: initialState.hasChanges,
    hasEditChanges: initialState.hasEditChanges,
  }),

  [DataActionsEnum.ACTION_RESET_ALL]: (state: DataState, { avoidExecutables }: { avoidExecutables?: boolean }) => {
    if (avoidExecutables) {
      return {
        ...initialState,
        executableDefinitions: state.executableDefinitions,
      }
    }

    return { ...initialState }
  },

  [DataActionsEnum.ACTION_SAVE_ELEMENT_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_SAVE_ELEMENT_ERROR]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_SAVE_ELEMENT_SUCCESS]: (
    state: DataState,
    { result }: any,
  ) => {
    // const xmlData = cloneDeep(state.xmlData)
    // const registry: any = {}
    // if(xmlData){
    //   buildRegistry(xmlData, registry)
    // }

    // const { paths, targetArray, editElements: editVal } = result
    // const dirtyPaths = [ ...state.dirtyPaths ]
    // const dirtyDeletePaths = [ ...state.dirtyDeletePaths ]
    // const hidePaths = [ ...state.hidePaths ]
    // const editElements = { ...editVal }
    // const loopCounter = { ...state.loopCounter }
    // let selectedDataArray = [ ...state.selectedDataArray ]

    // const parentPath = targetArray && targetArray.length ? getPathFromPathArray(targetArray) : null

    // handleSuccessfullySavedPaths(paths,
    //   result,
    //   parentPath,
    //   targetArray,
    //   dirtyPaths,
    //   dirtyDeletePaths,
    //   selectedDataArray,
    //   hidePaths,
    //   editElements,
    //   registry,
    //   loopCounter)

    // if (result.type === 'delete') {
    //   selectedDataArray = []
    // }

    return {
      ...state,
      dirtyPaths: result.dirtyPaths,
      dirtyDeletePaths: result.dirtyDeletePaths,
      hidePaths: result.hidePaths,
      editElements: result.editElements,
      // only update editElementsInitial for paths that are in the result.paths
      editElementsInitial: {
        ...state.editElementsInitial,
        ...Object.keys(result.editElements)
          .filter((path) => result.paths.includes(path))
          .reduce((acc, path) => {
            acc[path] = result.editElements[path]

            return acc
          }, {} as Record<string, any>),
      },
      selectedDataArray: result.selectedDataArray,
      loopCounter: result.loopCounter,
      hasChanges: true,
      loading: false,
    }
  },

  [DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS]: (
    state: DataState,
    { ...props }: any,
  ) => {
    return {
      ...state,
      ...props,
    }
  },

  [DataActionsEnum.ACTION_SAVE_CATALOG]: (state: DataState, action: any) => {
    if (!action.catalogId) {
      return {
        ...state,
        catalog: action.catalog,
      }
    }

    return {
      ...state,
      catalog: action.catalog,
      currentCatalogId: action.catalogId,
    }
  },

  [DataActionsEnum.ACTION_SET_CATALOG_ELEMENT]: (state: DataState, action: any) => ({
    ...state,
    catalogElement: action.element ? action.element : initialState.catalogElement,
  }),

  [DataActionsEnum.ACTION_EDIT_CHANGES]: (state: DataState, action: any) => ({
    ...state,
    hasEditChanges: {
      ...state.hasEditChanges,
      [action.elementType]: action.hasEditChanges,
    },
  }),

  [DataActionsEnum.ACTION_VALIDATION_INPUT]: (state: DataState, action: any) => {
    const { editValues, parentPath } = state
    let createValid = false

    if (editValues && (editValues as any)[action.elementType]) {
      const keys = Object.keys((editValues as any)[action.elementType])

      createValid = positivePropertiesAndInKeyList(editValues, keys, action.elementType)
      console.log('create valid in reducer', createValid)
    }

    if (Object.keys(parentPath).length <= 1) {
      console.log('no parent paths')
      createValid = false
    }

    return {
      ...state,
      createValid: {
        ...state.createValid,
        [action.elementType]: createValid,
      },
    }
  },
  [DataActionsEnum.ACTION_MERGE_NEW_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_MERGE_NEW_ERROR]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_MERGE_NEW_SUCCESS]: (state: DataState, { result: { xmlData, loopCounter } }: any) => {
    return {
      ...state,
      xmlData,
      dirtyPaths: [],
      dirtyDeletePaths: [],
      hidePaths: [],
      hasChanges: true,
      loopCounter,
    }
  },

  [DataActionsEnum.ACTION_SET_CURRENT_CATALOG_ID]: (state: DataState, { currentCatalogId }: any) => ({
    ...state,
    currentCatalogId,
  }),

  [DataActionsEnum.ACTION_SET_UPDATE_NOTIFICATIONS]: (state: DataState, { updatesCount, clear }: any) => ({
    ...state,
    updatesCount: clear ? 0 : state.updatesCount + updatesCount,
  }),

  [DataActionsEnum.ACTION_SET_LIVE_MODE]: (state: DataState, { liveMode }: { liveMode?: boolean}) => ({
    ...state,
    liveMode: liveMode !== undefined ? liveMode : !state.liveMode,
  }),
  [DataActionsEnum.ACTION_ADD_DIRTY_PATH]: (state: DataState, action: any) => {
    const paths = ensureActionPathIsArray(action)
    const dirtyPaths = [ ...state.dirtyPaths ]

    addPathsIfNotAlreadyInList(dirtyPaths, paths)

    return {
      ...state,
      dirtyPaths,
    }
  },

  [DataActionsEnum.ACTION_CLEAR_DIRTY_PATHS]: (state: DataState) => ({
    ...state,
    dirtyPaths: [],
  }),

  [DataActionsEnum.ACTION_SELECT_EDIT_DATA]: (
    state: DataState,
    { selectedData, massSelect, multiSelect }:
    { selectedData: any, massSelect?: boolean, multiSelect?: boolean},
  ) => {
    let list = [ ...state.selectedDataArray ]

    // reset
    if (!selectedData) {
      return {
        ...state,
        editElements: {},
        editElementsInitial: {},
        selectedDataArray: [
          ...initialState.selectedDataArray,
        ],
      }
    }

    list = updateListWithSelectedData(list, selectedData, massSelect, multiSelect)

    return {
      ...state,
      selectedDataArray: list,
      editElements: {
        ...Object.keys(state.editElements ?? {})
          .filter(key => list.includes(key))
          .reduce((acc, key) => {
            acc[key] = state.editElements?.[key]

            return acc
          }, {} as Record<string, any>),
      },
    }
  },

  [DataActionsEnum.ACTION_SET_ELEMENTS]: (state: DataState, action: any) => {
    if (action.element) {
      if (action.path instanceof Array) {
        const elements: any = {}

        action.path.forEach((path: string, i: number) => {
          elements[path] = {
            ...(state.editElements[path] || {}),
            ...(action.element instanceof Array ? action.element[i] : action.element),
          }
        })

        return {
          ...state,
          editElements: {
            ...state.editElements,
            ...elements,
          },
        }
      }

      return {
        ...state,
        editElements: {
          ...state.editElements,
          [action.path]: {
            ...(state.editElements[action.path] || {}),
            ...action.element,
          },
        },
      }
    }

    const data = { ...state.rootData }
    const paths = ensureActionPathIsArray(action)

    const elements: any = {}

    paths.forEach((path: string) => {
      if (!state.editElements[path] && data) {
        elements[path] = { ...getElementFromPath(path, action.elementsHashes) || {} }
        // delete elements[path]['#parent']
      }
    })

    if (Object.keys(elements).length) {
      return {
        ...state,
        editElements: {
          ...state.editElements,
          ...elements,
        },
        editElementsInitial: {
          ...state.editElementsInitial,
          ...elements,
        },
      }
    }

    if (!action.element && !action.path) {
      return {
        ...state,
        editElements: {},
      }
    }

    return {
      ...state,
    }
  },

  [DataActionsEnum.ACTION_SET_PARENT_PATH]: (state: DataState, action: any) => ({
    ...state,
    parentPath: action.parentPath,
  }),

  [DataActionsEnum.ACTION_SET_NEW_ELEMENTS_TO_DRAW]: (state: DataState, { newCopiedElementsToDraw }: any) => ({
    ...state,
    newCopiedElementsToDraw,
  }),

  [DataActionsEnum.ACTION_DELETE_ELEMENTS]: (state: DataState, { xmlData }: any) => { // TODO: is this removable?
    if (!state.rootData) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      // xmlData,
    }
  },

  [DataActionsEnum.ACTION_ADD_DIRTY_DELETE_PATH]: (state: DataState, action: any) => {
    const dirtyDeletePaths = [ ...state.dirtyDeletePaths ]
    const paths = ensureActionPathIsArray(action)

    handleDirtyDeletePaths(dirtyDeletePaths, paths)

    return {
      ...state,
      dirtyDeletePaths,
      hasChanges: true,
    }
  },

  [DataActionsEnum.ACTION_ADD_PENDING_DELETE_LIST]: (state: DataState, action: any) => ({
    ...state,
    pendingDeleteList: action.path,
  }),

  [DataActionsEnum.ACTION_CLEAR_DIRTY_DELETE_PATHS]: (state: DataState) => ({
    ...state,
    dirtyDeletePaths: initialState.dirtyDeletePaths,
    hidePaths: initialState.hidePaths,
  }),

  [DataActionsEnum.ACTION_SET_ACTIVE_EDIT_TAB]: (state: DataState, action: any) => ({
    ...state,
    activeEditTab: action.activeEditTab,
  }),

  [DataActionsEnum.ACTION_EDIT_VALUES]: (state: DataState, action: any) => {
    const types = Object.keys(action.editValues)

    const editValues = setEditValuesForEachType({ ...state.editValues }, action, types as any)

    return {
      ...state,
      editValues,
    }
  },

  [DataActionsEnum.ACTION_UNDO_ELEMENT_CHANGES]: (state: DataState, action: any) => {
    const editElements = { ...state.editElements }
    const editElementsInitial = { ...state.editElementsInitial }

    handleUndoChanges(editElements, action, editElementsInitial)

    return {
      ...state,
      editElements,
    }
  },

  [DataActionsEnum.ACTION_OVERWRITE_ALL_SUCCESS]: (
    state: DataState,
    { loopCounter }: { loopCounter: LoopCounter},
  ) => {
    if (!state.rootData || loopCounter) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      dirtyPaths: [],
      dirtyDeletePaths: [],
      hidePaths: [],
      hasChanges: true,
      loopCounter,
    }
  },
  [DataActionsEnum.ACTION_APPLY_CHANGES_SUCCESS]: (
    state: DataState,
    {
      dirtyPaths,
      hidePaths,
      dirtyDeletePaths,
    }: any,
  ) => {
    if (!state.rootData) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      dirtyPaths,
      hidePaths,
      dirtyDeletePaths,
      hasChanges: true,
    }
  },

  [DataActionsEnum.ACTION_REMOVE_DELETE_PATHS]: (state: DataState, { paths }: any) => ({
    ...state,
    dirtyDeletePaths: state.dirtyDeletePaths.filter(path => !paths.includes(path)),
  }),

  [DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS]: (state: DataState, action: any) => ({
    ...state,
    executableDefinitions: action.result.executableDefinitions,
  }),

  [DataActionsEnum.ACTION_SET_EXECUTION_STATE]: (state: DataState, action: any) => ({
    ...state,
    executionStateDictionary: {
      ...state.executionStateDictionary,
      [`${action.simulationCaseId}_${action.definitionId}_${action.caseId}`]: action.state,
    },
  }),

  [DataActionsEnum.ACTION_SET_MANY_EXECUTION_STATES]:
  (state: DataState, { newValues }:{ newValues: Record<string, string>}) => {
    const executionStateCopy = { ...state.executionStateDictionary }

    Object.entries(newValues).forEach(([ key, value ]) => {
      if (value === 'delete') {
        delete executionStateCopy[key]

        return
      }

      executionStateCopy[key] = value
    })

    return {
      ...state,
      executionStateDictionary: executionStateCopy,
    }
  },
}

export default editionReducers
