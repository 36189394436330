import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from '../../../../../store/visualization/actions'
import { FormWrapper, InputWrapper } from '../../Dialogs/DialogStyles'
import Input from '../../../../specific/Input'
import VisUtil from '../../../VisUtil'
import ApiClient from '../../../../../store/apiClient'
import { Network } from '../../../../../network/Network'
import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization, application }: DefaultState) => ({
  currentSimulationCase: application.main.currentSimulationCase,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean,
  t: Translation
}

type State = {
  typeSelectors: Array<any>,
  allConfigIds: Array<any>,
  config: any,
  tileConfig: any
};

class EditableFilesTab extends Component<Props, State> {
  timeoutRef?: number;

  state: State = {
    typeSelectors: [],
    allConfigIds: [],
    config: {},
    tileConfig: {},
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { editDialogConfigId, currentTileId, tileConfigs, currentSimulationCase } = this.props
    const { configId } = tileConfigs[currentTileId || ''] || {}

    ApiClient
      .get(`${Network.URI}/simulation_case/editable_files/${currentSimulationCase._id}`)
      .then(({ files }) => {
        const typeSelectors = VisUtil.getTypeSelectors('Editable Files')
        const allEditableFiles = VisUtil.getEditableFileSelectors(files)
        const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase)
        const allConfigIds = [ ...allEditableFiles, ...allViewableFiles ]

        const config = allConfigIds.filter((config) => {
          return config.key === (configId || editDialogConfigId)
        })[0]

        this.setState({
          typeSelectors,
          config,
          allConfigIds,
          tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
        })
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  // @AnalyzeTime(0)
  componentDidUpdate = (pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
      }, 1000)
    }
  };

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    clearTimeout(this.timeoutRef)
    saveTileConfig(tileConfig)
  };

  // @AnalyzeTime(0)
  handleInputDataTile = (event: any) => {
    const { name, value } = event.target
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props

    const val: any = value

    const editTileConfig = {
      ...tileConfig,
      [name]: val,
    }

    if (name === 'configId') {
      saveTileConfig({
        ...editTileConfig,
        type: 'edit_box',
      })
    }

    this.setState({
      tileConfig: editTileConfig,
    })
  };

  @AnalyzeTime(0)
  render () {
    const { tileConfig, config, allConfigIds } = this.state
    const { editDialogConfigId, fullscreen, t } = this.props
    const { name, configId } = tileConfig

    const sortedConfigIds = allConfigIds.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    return (
      <FormWrapper fullscreen={fullscreen}>
        <InputWrapper>
          <Input
            label={t('plotConfig.name.name')}
            name='name'
            type='text'
            title={name || ''}
            value={name || ''}
            onChange={this.handleInputDataTile}
          />
          <Input
            label={t('plotConfig.configId.label')}
            name='configId'
            type='select'
            title={config && config.value}
            value={configId || editDialogConfigId}
            selectors={sortedConfigIds}
            onChange={this.handleInputDataTile}
          />
        </InputWrapper>
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(EditableFilesTab as any) as any) as any
