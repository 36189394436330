import * as THREE from 'three'
import { Vector3 } from 'three'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Mold from './Mold'
import { AnalyzeTime } from 'Util'

export default class SupportPoint extends BaseObject {
  static lineMaterial1 = new THREE.LineBasicMaterial({ color: '#ff9200' })
  static lineMaterial2 = new THREE.LineBasicMaterial({ color: '#00b8ff' })
  static selectedLineMaterial = new THREE.LineBasicMaterial({ color: '#7eda41' })

  static outerCubeMaterial1 = new THREE.MeshStandardMaterial({ color: '#ff9200', transparent: true, opacity: 0.75 })
  static outerCubeMaterial2 = new THREE.MeshStandardMaterial({ color: '#00b8ff', transparent: true, opacity: 0.75 })
  static selectedOuterCubeMaterial =
    new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.75 })

  // maybe use two inner cubes with different colors to show actual and propose values separately
  static innerCubeMaterial1 = new THREE.MeshStandardMaterial({ color: '#ffe500' })
  static innerCubeMaterial2 = new THREE.MeshStandardMaterial({ color: '#00fbff' })
  static selectedInnerCubeMaterial = new THREE.MeshStandardMaterial({ color: '#4fda41' })

  static cubeGeometryCache: Record<string, THREE.BoxGeometry> = {}

  static lineLength = 0.5
  static cubeSize = 0.1
  static innerCubeSize = 0.095

  data?: any
  sectionDetail?: boolean
  isSegmentGroupIDEven = false

  thickness?: number

  @AnalyzeTime(0)
  static get3DGeometry (size: number) {
    const geometryKey = `${size}`
    let geometry = SupportPoint.cubeGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.BoxGeometry(size, size, size) // no Buffer!
      geometry.translate(0, size / 2, 0)

      SupportPoint.cubeGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  constructor (container: any, parent: any, clickableObjects: any) {
    super(container, parent)

    const outerGeometry = SupportPoint.get3DGeometry(SupportPoint.cubeSize)

    this.objects.supportPoint = new THREE.Mesh(
      outerGeometry,
      this.isSegmentGroupIDEven ? SupportPoint.outerCubeMaterial1 : SupportPoint.outerCubeMaterial2,
    )
    this.objects.supportPoint.type = 'SupportPoint'

    const innerGeometry = SupportPoint.get3DGeometry(SupportPoint.innerCubeSize)

    this.objects.supportPointInner = new THREE.Mesh(
      innerGeometry,
      this.isSegmentGroupIDEven ? SupportPoint.innerCubeMaterial1 : SupportPoint.innerCubeMaterial2,
    )

    clickableObjects.push(this.objects.supportPoint)
    container.add(this.objects.supportPoint)
    container.add(this.objects.supportPointInner)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    data: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _loopColorIndex: number,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.data = data
    this.sectionDetail = sectionDetail

    const parentInfo = Util.getParentInfo(path)

    this.isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    this.updateTransform()
  }

  @AnalyzeTime(0)
  updateTransform () {
    const fixedSide = this.container.children
      .find(({ userData }: any) => userData.type === 'Segment' && userData.side === 'FixedSide')

    const { plMaxCoordByRoller, plMinCoordByRoller } = fixedSide?.userData ?? {}

    if (!plMaxCoordByRoller || !plMinCoordByRoller) {
      return
    }

    // eslint-disable-next-line camelcase
    const { _name, _shim_min, _shim_max, _shim_actual, _shim_propose } = this.data ?? {}

    const plCoord = _name?.startsWith('IN') ? plMinCoordByRoller : plMaxCoordByRoller
    const widthCoord = (_name?.endsWith('R') ? -1 : 1) * (Mold.width / 2)

    const { position, angleX, normal } =
      PasslineCurve.getInfoAtPlCoord(plCoord ?? 0, this.sectionDetail ? true : undefined)

    const { supportPoint, supportPointInner } = this.objects

    supportPoint.material =
      this.isSegmentGroupIDEven ? SupportPoint.outerCubeMaterial1 : SupportPoint.outerCubeMaterial2

    supportPoint.path = this.path

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const size = this.sectionDetail ? SupportPoint.cubeSize : 0

    newSectionRotation.x = -Util.RAD90

    newPosition.set(-position.z + size / 2, position.y + 0.00001, widthCoord)

    const lineStart = newPosition.clone()

    newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(SupportPoint.lineLength))
    newRotation.set(0, 0, -Util.RAD90 - angleX)

    supportPoint.position.copy(newPosition)
    supportPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    // inner

    supportPointInner.material =
      this.isSegmentGroupIDEven ? SupportPoint.innerCubeMaterial1 : SupportPoint.innerCubeMaterial2

    // TODO: use propose?
    // eslint-disable-next-line camelcase
    const percentage = (_shim_actual - _shim_min) / (_shim_max - _shim_min)
    const offset = (SupportPoint.innerCubeSize * (1 - percentage)) +
      ((SupportPoint.cubeSize - SupportPoint.innerCubeSize) / 2)

    ;(supportPointInner as THREE.Mesh).scale.set(1, percentage || 0.0001, 1)

    const newInnerPosition = newPosition.clone()

    newInnerPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(offset))
    supportPointInner.position.copy(newInnerPosition)
    supportPointInner.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    // line
    const lineGeometry = new THREE.BufferGeometry().setFromPoints([ lineStart, newPosition ])
    const line = new THREE.Line(
      lineGeometry,
      this.isSegmentGroupIDEven ? SupportPoint.lineMaterial1 : SupportPoint.lineMaterial2,
    )

    line.name = `SupportPointLine_${_name}`

    this.objects.supportPointLine = line

    Util.addOrReplace(this.container, line)
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    this.objects.supportPoint.userData.selected = isSelected

    this.objects.supportPoint.material = isSelected
      ? SupportPoint.selectedOuterCubeMaterial
      : this.isSegmentGroupIDEven ? SupportPoint.outerCubeMaterial1 : SupportPoint.outerCubeMaterial2

    this.objects.supportPointInner.material = isSelected
      ? SupportPoint.selectedInnerCubeMaterial
      : this.isSegmentGroupIDEven ? SupportPoint.innerCubeMaterial1 : SupportPoint.innerCubeMaterial2

    if (!this.objects.supportPointLine) {
      return
    }

    this.objects.supportPointLine.material = isSelected
      ? SupportPoint.selectedLineMaterial
      : this.isSegmentGroupIDEven ? SupportPoint.lineMaterial1 : SupportPoint.lineMaterial2
  }

  show () {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true
  }
}
