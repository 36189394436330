import type { PlotConfig, VisualizationData } from 'types/visualization'
import { AnalyzeTime } from 'Util'

export default class VisUtil {
  static GROUP_MERGED = 'Merged Plots'
  static GROUP_DERIVED = 'Derived Plots'

  static TRACE_COLORS = [
    '#1f77b4', // muted blue
    '#ff7f0e', // safety orange
    '#2ca02c', // cooked asparagus green
    '#d62728', // brick red
    '#9467bd', // muted purple
    '#8c564b', // chestnut brown
    '#e377c2', // raspberry yogurt pink
    '#7f7f7f', // middle gray
    '#bcbd22', // curry yellow-green
    '#17becf', // blue-teal
  ]

  @AnalyzeTime(0)
  static getConfigInfo (name: string, key: string, configId: string, rawGroup: string, config?: PlotConfig) {
    let group = ''
    let value = ''
    const isVerticalLine = !!(config && config.isVerticalLine)

    if (/_derived_/.test(configId)) {
      value = `${name} (Plot derived at x = ${Number(configId.split('_').splice(-1)[0].replace('🍓', '.')).toFixed(2)})`
      group = VisUtil.GROUP_DERIVED
    }
    else if (rawGroup === 'dynamicDataSource') {
      let detail = ''

      if (!config?.filter && !config?.elements.length) {
        detail = 'All Elements'
      }
      else if (!config.filter) {
        detail = 'Selection'
      }
      else {
        detail = config.filter.length > 30 ? `${config.filter.substring(0, 30)}...` : config.filter
      }

      value = `${name} (${detail})`
      group = rawGroup
    }
    else if (rawGroup) {
      value = `${name} (${key})`
      group = rawGroup
    }
    else if (/_merged_/.test(configId)) {
      value = `${name} (Various Plots)`
      group = VisUtil.GROUP_MERGED
    }

    return {
      group,
      value,
      isVerticalLine,
    }
  }

  @AnalyzeTime(0)
  static getConfigSelectors (data: VisualizationData, configs:Record<string, PlotConfig>, list?:any[]) {
    return (list || Object.keys(configs || {}))
      .filter(configId =>
        !/^xCoordinates_/.test(configs[configId].key || '') &&
        !/^yCoordinates_/.test(configs[configId].key || ''))
      .map(configId => {
        const { name, key, group: rawGroup } = configs[configId]
        const { value, group, isVerticalLine } = VisUtil.getConfigInfo(name, key, configId, rawGroup, configs[configId])

        const result:{
          key: string
          value: string
          group: string
          isVerticalLine: boolean
          badge?: string
        } = {
          key: configId,
          group,
          value,
          isVerticalLine,
        }

        if (data && data[group] && data[group][key]) {
          result.badge = 'schema'
        }

        return result
      })
      .filter(config => config.key && config.group)
  }

  @AnalyzeTime(0)
  static getEditableFileSelectors (editableFiles: any[]) {
    return editableFiles.map(editableFile => ({
      key: editableFile,
      value: editableFile,
      group: 'Editable Files',
    }))
  }

  @AnalyzeTime(0)
  static getViewableFileSelectors (simulationCase: SimulationCase) {
    const { casterFileName, casterCatalogList, commandFiles } = simulationCase || {}
    const viewableFiles = []

    // Caster
    if (casterFileName) {
      viewableFiles.push(`SCI/Input-OMS/PlantData/${casterFileName}`)
    }

    // Catalogs
    if (casterCatalogList && casterCatalogList.length) {
      viewableFiles.push(...casterCatalogList.map(catalog => `SCI/Input-DSC/Nozzles/${catalog.name}`))
    }

    // CommandFiles
    if (commandFiles && commandFiles.length) {
      viewableFiles.push(...commandFiles.map(commandFile => `SCI/Input-OMS/ProcessData/${commandFile.name}.txt`))
    }

    return viewableFiles.map(viewableFile => ({
      key: viewableFile,
      value: viewableFile,
      group: 'Viewable Files',
    }))
  }

  @AnalyzeTime(0)
  static getTypeSelectors (group?: string) {
    let typeSelectors = []

    switch (group) {
      case '2DData_MidThickPlane':
        typeSelectors = [
          { key: 'contour', value: 'Contour' },
        ]
        break
      case 'LengthWiseQuantities':
      case 'WidthWiseTemperatures':
      case 'ProcessParameters':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'pie', value: 'Pie' },
          { key: 'message', value: 'Message' },
        ]
        break
      case 'MachineData':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
        ]
        break
      case 'PostProcessedQuantities':
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
        ]
        break
      case 'derived':
      case 'ProcessParameters_ActualMinMaxAimminAimmax':
        typeSelectors = [
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
        ]
        break
      case 'Editable Files':
      case 'Viewable Files':
        typeSelectors = [
          { key: 'edit_box', value: 'Edit Box' },
        ]
        break
      default:
        typeSelectors = [
          { key: 'line', value: 'Line' },
          { key: 'bar', value: 'Bar' },
          { key: 'area', value: 'Area' },
          { key: 'pie', value: 'Pie' },
          { key: 'gage', value: 'Gauge' },
          { key: 'text', value: 'Text' },
          { key: 'message', value: 'Message' },
          { key: 'radar', value: 'Radar' },
        ]
    }

    return typeSelectors
  }

  @AnalyzeTime(0)
  static sortStringsASC (a: any, b: any) {
    if (a.value > b.value) {
      return 1
    }

    if (a.value < b.value) {
      return -1
    }

    return 0
  }
}
