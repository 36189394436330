import { AnalyzeTime } from 'Util'
import MainView from '.'
import CalculationUtil from './CalculationUtil'
import CameraHandlers from './CameraHandlers'

export default class JumpHandlers {
  @AnalyzeTime(0)
  static jumpToSegments (
    view: MainView,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    segments: any, callback = () => {},
    playAnimation = true,
    isJumpToSectionView = false,
  ) {
    if (!segments || !segments.length) {
      return
    }

    const { position, center, xAngle, yAngle, side } = CalculationUtil.calcJumpToSegments(
      segments,
      view.applyCurve,
      view.plHeight,
    )

    if (playAnimation) {
      if (isJumpToSectionView) {
        switch (side) {
          case 'LooseSide':
            return CameraHandlers.tweenCamera(
              view,
              position,
              center,
              xAngle > 0.2 ? xAngle : 0.4,
              1.2,
              1000,
              callback,
            )
          case 'FixedSide':
            return CameraHandlers.tweenCamera(view, position, center, xAngle, -1.7, 1000, callback)
          default:
            return CameraHandlers.tweenCamera(view, position, center, xAngle, yAngle, 1000, callback)
        }
      }

      return CameraHandlers.tweenCamera(view, position, center, xAngle, yAngle, 1000, callback)
    }

    view.setCameraPosition(position, center, view.camera, view.controls)
  }
}
