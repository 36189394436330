import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { withSnackbar } from 'notistack'

import { Form, Text } from '../visualization/dashboard/Dialogs/DialogStyles'
import Button from '../specific/SubmitButton'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from './BaseDialog'
import IpcManager from '../../IpcManager'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import { LoginDialog } from './LoginDialog'
import { displayTime } from 'Util/time'

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideCloseButton?: boolean,
  t (key: string, params?: Record<string, unknown>): string
  enqueueSnackbar (message: string, options: any): void
}

type State = {
  loading: boolean
};

export class UsageTimeExceededDialog extends Component<Props, State> {
  static NAME = uuid()

  intervalId: number | undefined

  state: State = {
    loading: false,
  }

  // @AnalyzeTime(0)
  handleTryUnlockApp = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    const { closeDialog, enqueueSnackbar, t } = this.props

    this.setState({ loading: true })

    const { intervalStartedAt } = window.usageTimeInfo ?? {}

    this.intervalId = window.setInterval(() => {
      const { intervalStartedAt: newIntervalStartedAt, usageTimeExceeded } = window.usageTimeInfo ?? {}

      if (intervalStartedAt !== newIntervalStartedAt) {
        clearInterval(this.intervalId)

        this.setState({ loading: false })

        if (!usageTimeExceeded) {
          closeDialog(UsageTimeExceededDialog.NAME)

          return
        }

        enqueueSnackbar(t('usageTimeExceededDialog.noNewUsageTime'), { variant: 'info' })
      }
    }, 1000)

    IpcManager.electron.send('tryUnlockApp')
  }

  handleShowLogin = () => {
    const { loading } = this.state

    if (loading) {
      return
    }

    const { closeDialog, openDialog } = this.props

    closeDialog(UsageTimeExceededDialog.NAME)
    openDialog(LoginDialog.NAME)
  }

  @AnalyzeTime(0)
  render () {
    const { loading } = this.state
    const { t } = this.props

    const { usageTimeLimit } = window.usageTimeInfo ?? {}

    return (
      <BaseDialog
        title={t('usageTimeExceededDialog.title')}
        icon='pe-7s-door-lock'
        header={t('usageTimeExceededDialog.header')}
        small
        hideCloseButton
      >
        <Form>
          <Text>
            {t('usageTimeExceededDialog.message', { usageTimeLimit: displayTime(usageTimeLimit * 60, true) })}
          </Text>
          <br />
          <Button onClick={this.handleTryUnlockApp} isLoading={loading}>
            <div><i className='pe-7s-key' style={{ fontSize: '23px', transform: 'translate(0,-6px)' }} /></div>
            <span>{t('usageTimeExceededDialog.tryUnlock')}</span>
          </Button>
          <Button onClick={this.handleShowLogin} isLoading={loading}>
            <div className='cut'><i className='pe-7s-angle-right-circle' /></div>
            <span>{t('usageTimeExceededDialog.loginInstead')}</span>
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withSnackbar(withNamespaces('login')(connector(UsageTimeExceededDialog as any) as any) as any) as any
