/* eslint-env browser */

import ApiClient from '../../../store/apiClient'
import { Network } from '../../../network/Network'

import Util from './Util'
import * as Visualization from './visualization'
import { Translation } from 'types/translation'

export function openVisualizationFile (currentSimulationCase: any): void {
  Visualization.openFile(currentSimulationCase)
}

export function sendToConsistencyCheck (simulationCaseId: string, notification: enqueueSnackbar, t: Translation): void {
  if (!simulationCaseId) {
    notification('Error: No simulation case loaded', { variant: 'error', autoHideDuration: 3000 })
  }

  ApiClient
    .get(`${Network.URI}/caster_consistency_check/${simulationCaseId}`)
    .then(() => {
      notification('File sent successfully', { variant: 'success' })
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)

      notification(t([ 'download.failed' ]), { variant: 'error' })
    })
}

export function openAboutDialog (): void {
  if (window.isElectron) {
    return
  }

  ApiClient.get(`${Network.URI}/info`).then(({ version }) => {
    alert(`Version: ${version}`) // TODO: use custom dialog
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
  })
}

export function openHdf5SchemaFile (): void {
  Visualization.openHdf5Schema()
}

export function setHdf5Schema (hdf5SchemaPath: string): void {
  Visualization.setHdf5SchemaPath(hdf5SchemaPath)
}

export function plotExportImages (
  plotImages: Array<any>,
  exportType: string,
  currentProject: Project,
  currentSimulationCase: SimulationCase,
  visualizationMetaInfo: any,
): void {
  Visualization.visualizationPlotExportImages(
    plotImages,
    exportType,
    currentProject._id,
    currentSimulationCase._id,
    visualizationMetaInfo,
  )
}

export function saveSimulationData (data: string, name: string, notification: enqueueSnackbar, t: Translation): void {
  if (window.isElectron) {
    notification(('Download initiated...'), { variant: 'info', autoHideDuration: 3000 })
  }

  Util.openDownloadFileDialog(data, name || 'simulation_data.zip')
    .then(() => {
      if (!window.isElectron) {
        notification(t('download.successful'), { variant: 'success' })
      }
    })
    .catch(() => {
      notification(t('download.failed'), { variant: 'error' })
    })
}

export const functionIndex: {
  [functionName: string]: any
} = {
  sendToConsistencyCheck,
  openVisualizationFile,
  openAboutDialog,
  openHdf5SchemaFile,
  setHdf5Schema,
  plotExportImages,
  saveSimulationData,
}
