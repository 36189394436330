/* eslint-disable key-spacing */
export const General = {
  categories: {
    1: 'mold',
  },
  fields: {
    _width:  { type: 'number', category: 1, defaultValue: 0, positive: true },
  },
}
/* eslint-enable key-spacing */
