import Mold from 'three/objects/Mold'
import type { ElementsHashes } from 'types/state'
/* eslint-disable camelcase */

const getDistToPassline = (element: any, elementsHashes: ElementsHashes) => {
  if (element._numericId && Mold.thickness && Mold.width) {
    const segmentId = element['#parent'].id
    const segment = elementsHashes.Segment[segmentId]
    const { _FixedLooseSide } = segment || {}
    const radius = element._diameter / 2

    switch (_FixedLooseSide) {
      case 'FixedSide': return radius
      case 'LooseSide': return radius + (Mold.thickness * 1000)
      case 'NarrowFaceLeft':
      case 'NarrowFaceRight': return radius + ((Mold.width / 2) * 1000)
      default:
    }
  }

  return -1
}

/* eslint-disable max-len */
/* eslint-disable key-spacing */
export const Roller = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    _diameter:                  { type: 'number', category: 1, defaultValue: 0, positive: true },
    _dist2passline:             { type: 'number', category: 1, defaultValue: 0, disabled: true, generate: getDistToPassline, positive: true },
    _jaws_size:                 { type: 'number', category: 1, defaultValue: 0, positive: true },
    _passln_coord:              { type: 'number', category: 1, defaultValue: 0, positive: true },
    _penetration_length:        { type: 'number', category: 1, defaultValue: 0, positive: true },
    _roll_width:                { type: 'number', category: 1, defaultValue: 0, positive: true },
    _drive:                     { type: 'select', category: 2, defaultValue: 0, options: [ 0, 1 ] },
    _is_cooled:                 { type: 'select', category: 2, defaultValue: 0, options: [ 0, 1 ] },
    _id:                        { type: 'number', category: 2, defaultValue: 0, disabled: true },
    _internalCoolWaterFlowRate: { type: 'number', category: 2, defaultValue: 0, positive: true },
    _name:                      { type: 'text', category: 2, defaultValue: '' },
    _numericId:                 { type: 'number', hidden: true },
    _originalId:                { type: 'number', hidden: true },
    _parentId:                  { type: 'number', hidden: true },
  },
}
/* eslint-enable max-len */
/* eslint-enable key-spacing */
