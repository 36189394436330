import * as THREE from 'three'
import { AnalyzeTime } from 'Util'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'

export default class Mold extends BaseObject {
  static material = new THREE.MeshStandardMaterial({ color: '#ff7e34' })
  static sideDistance: any = {}
  static height: number
  static width: number
  static minWidth: number | undefined
  static maxWidth: number | undefined
  static originalWidth: number
  static thickness: number
  static passlnCoord: number
  static copperThickness: number
  static innerShape: THREE.Shape
  static fixedSideShape: THREE.Shape
  static looseSideShape: THREE.Shape
  static leftSideShape: THREE.Shape
  static rightSideShape: THREE.Shape

  isFirstTime = true

  static getWidestWidth () {
    return Math.max(Mold.width || 0, Mold.originalWidth || 0)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0) // @ts-ignore
  setValues (caster: Caster, additionalData: any) {
    const { Mold: mold } = caster

    if (!mold._height || !mold._width || !mold._thickness || !mold._copper_thickness) {
      // eslint-disable-next-line no-console
      console.error('Cannot build Mold, one or more mold values are missing')
    }

    Mold.height = mold._height / 1000
    Mold.width = mold._width / 1000
    Mold.minWidth = mold._widthMin ? mold._widthMin / 1000 : undefined
    Mold.maxWidth = mold._widthMax ? mold._widthMax / 1000 : undefined
    Mold.thickness = mold._thickness / 1000
    Mold.passlnCoord = mold._passln_coord / 1000
    Mold.copperThickness = mold._copper_thickness / 1000

    if (this.isFirstTime) {
      this.isFirstTime = false

      Mold.originalWidth = Mold.width
    }

    const { width, thickness, copperThickness } = Mold

    Mold.sideDistance = {
      FixedSide: new THREE.Vector3(0, 0, 0),
      LooseSide: new THREE.Vector3(0, 0, -thickness),
      NarrowFaceRight: new THREE.Vector3(-width / 2, 0, -0),
      NarrowFaceRightWidest: new THREE.Vector3(-Mold.getWidestWidth() / 2, 0, -0),
      NarrowFaceLeft: new THREE.Vector3(width / 2, 0, 0),
      NarrowFaceLeftWidest: new THREE.Vector3(Mold.getWidestWidth() / 2, 0, 0),
    }

    const { Genaral: strand } = additionalData || {}
    const { _width: addWidth, _height: addHeight } = strand || {}

    const halfWidth = (addWidth || width) / 2
    const nHeight = (addHeight || thickness)

    const innerShape = new THREE.Shape()

    innerShape.moveTo(-halfWidth, 0)
    innerShape.lineTo(-halfWidth, -nHeight)
    innerShape.lineTo(halfWidth, -nHeight)
    innerShape.lineTo(halfWidth, 0)
    innerShape.lineTo(-halfWidth, 0)

    Mold.innerShape = innerShape
    const moldWidth = Mold.maxWidth || Mold.originalWidth
    const fixedSideShape = new THREE.Shape()

    fixedSideShape.moveTo(-moldWidth / 2 - copperThickness, 0)
    fixedSideShape.lineTo(-moldWidth / 2 - copperThickness, copperThickness)
    fixedSideShape.lineTo(moldWidth / 2 + copperThickness, copperThickness)
    fixedSideShape.lineTo(moldWidth / 2 + copperThickness, 0)
    fixedSideShape.lineTo(-moldWidth / 2 - copperThickness, 0)

    Mold.fixedSideShape = fixedSideShape

    const looseSideShape = new THREE.Shape()

    looseSideShape.moveTo(-moldWidth / 2 - copperThickness, -thickness - copperThickness)
    looseSideShape.lineTo(-moldWidth / 2 - copperThickness, -thickness)
    looseSideShape.lineTo(moldWidth / 2 + copperThickness, -thickness)
    looseSideShape.lineTo(moldWidth / 2 + copperThickness, -thickness - copperThickness)
    looseSideShape.lineTo(-moldWidth / 2 - copperThickness, -thickness - copperThickness)

    Mold.looseSideShape = looseSideShape

    const leftSideShape = new THREE.Shape()

    leftSideShape.moveTo(halfWidth, -thickness)
    leftSideShape.lineTo(halfWidth, 0)
    leftSideShape.lineTo(halfWidth + copperThickness, 0)
    leftSideShape.lineTo(halfWidth + copperThickness, -thickness)
    leftSideShape.lineTo(halfWidth, -thickness)

    Mold.leftSideShape = leftSideShape

    const rightSideShape = new THREE.Shape()

    rightSideShape.moveTo(-halfWidth - copperThickness, -thickness)
    rightSideShape.lineTo(-halfWidth - copperThickness, 0)
    rightSideShape.lineTo(-halfWidth, 0)
    rightSideShape.lineTo(-halfWidth, -thickness)
    rightSideShape.lineTo(-halfWidth - copperThickness, -thickness)

    Mold.rightSideShape = rightSideShape

    const extrudeOptions = this.getExtrudeOptions()

    if (!extrudeOptions) {
      return
    }

    const moldGroup = new THREE.Group()

    moldGroup.name = 'Mold'
    moldGroup.position.set(0, PasslineCurve.plHeight, 0)

    this.createSideFromShape(moldGroup, fixedSideShape, extrudeOptions, 'FixedSide')
    this.createSideFromShape(moldGroup, looseSideShape, extrudeOptions, 'LooseSide')
    this.createSideFromShape(moldGroup, leftSideShape, extrudeOptions, 'NarrowFaceLeft')
    this.createSideFromShape(moldGroup, rightSideShape, extrudeOptions, 'NarrowFaceRight')
    const labelWidth = Mold.maxWidth || Mold.getWidestWidth()

    this.generateLabel(labelWidth, Mold.height, Mold.copperThickness, Mold.thickness, 'FixedSide')
    this.generateLabel(labelWidth, Mold.height, Mold.copperThickness, Mold.thickness, 'LooseSide')

    Util.addOrReplace(this.container, moldGroup)
  }

  getExtrudeOptions (): THREE.ExtrudeGeometryOptions | null {
    const start = Mold.passlnCoord / PasslineCurve.plHeight
    const end = (Mold.height + Mold.passlnCoord) / PasslineCurve.plHeight

    const steps = 8

    const points = []
    const step = (end - Math.max(0, start)) / (steps - 1)

    for (let i = Math.max(0, start); i < end; i += step) {
      points.push(PasslineCurve.getCurve().getPointAt(i))
    }

    points.splice(-1)
    points.push(PasslineCurve.getCurve().getPointAt(end))

    if (start < 0) {
      const first = points[0].clone()

      first.y += Mold.passlnCoord * -1
      points.unshift(first)
    }

    if (points.length < 2) {
      // eslint-disable-next-line no-console
      console.error('Cannot build Mold, not enough points in curve!')

      return null
    }

    const curve = new THREE.CatmullRomCurve3(points)
    const extrudeSettings = {
      steps,
      bevelEnabled: false,
      extrudePath: curve,
    }

    return extrudeSettings
  }

  @AnalyzeTime(0)
  createSideFromShape (container: any, shape: THREE.Shape, options: THREE.ExtrudeGeometryOptions, side: string) {
    const geometry = new THREE.ExtrudeBufferGeometry(shape, options)

    // geometry.translate(0, PasslineCurve.plHeight, 0)

    const moldMesh = new THREE.Mesh(geometry, Mold.material)

    moldMesh.name = `Mold_${side}`

    this.objects.moldMesh = moldMesh

    Util.addOrReplace(container, moldMesh)
  }

  @AnalyzeTime(0)
  generateLabel (width: number, height: number, copperThickness: number, thickness: number, side: string) {
    const backgroundMaterial = new THREE.MeshStandardMaterial({ color: '#22282e' })
    const buttonMaterial = new THREE.MeshStandardMaterial({ color: '#474b4e' })

    const label = Util.getText(
      Util.angles2Sides(`${Mold.getWidestWidth() * 1000} x ${thickness * 1000}`),
      0.10,
      false,
      true,
    )

    label.position.z += 0.02
    label.position.y -= 0.01

    const backgroundWidth = 1
    const geometry = new THREE.PlaneBufferGeometry(backgroundWidth + 0.1, 0.3, 1)
    const textBackgroundGeometry = new THREE.PlaneBufferGeometry(backgroundWidth, 0.2, 1)

    const background = new THREE.Mesh(geometry, backgroundMaterial)
    const textBackground = new THREE.Mesh(textBackgroundGeometry, buttonMaterial)

    textBackground.position.z += 0.01

    const group = new THREE.Group()

    group.name = `Mold_Label_${side}`

    group.add(label)
    group.add(background)
    group.add(textBackground)

    switch (side) {
      case 'FixedSide':
        group.position.set(
          copperThickness,
          PasslineCurve.plHeight - height / 1000 / 2 - 0.1,
          -(width / 2 + copperThickness + backgroundWidth / 2 + 0.05),
        )

        group.rotation.y = Util.RAD90

        break
      default:
        group.position.set(
          -copperThickness - thickness,
          PasslineCurve.plHeight - height / 1000 / 2 - 0.1,
          width / 2 + copperThickness + backgroundWidth / 2 + 0.05,
        )

        group.rotation.y = Util.RAD270
    }

    Util.addOrReplace(this.container, group)
  }
}
