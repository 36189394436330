import * as THREE from 'three'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Mold from './Mold'
import { AnalyzeTime } from 'Util'
import type { ElementsHashes } from 'types/state'
import FeatureFlags from 'react/FeatureFlags'

export default class Segment extends BaseObject {
  static _planeGeometryCache: Record<string, THREE.PlaneBufferGeometry> = {}

  static backgroundMaterial = new THREE.MeshStandardMaterial({ color: '#22282e' })
  static buttonMaterial = new THREE.MeshStandardMaterial({ color: '#474b4e' })
  static buttonDisabledMaterial = new THREE.MeshStandardMaterial({ color: '#373a3c' })
  static highlightMaterial = new THREE.MeshStandardMaterial({ color: '#BB1B1B' })
  static textMaterial = new THREE.MeshBasicMaterial({ color: '#CCCCCC' })
  static textDisabledMaterial = new THREE.MeshBasicMaterial({ color: '#555555' })
  static defaultMaterial = new THREE.MeshBasicMaterial({ color: '#C1BBC6', transparent: true, opacity: 0.3 })
  static sgRim: any

  static readonly maxSegmentGroupTextLength = 16
  static readonly maxSegmentGroupTextLengthShort = 13

  static readonly sideButtonWidth = 0.25
  static readonly sideButtonHeight = 0.175
  static readonly sideButtonFontSize = 0.0235
  static readonly sideButtonBarHeight = 0.02
  static readonly sideButtonMarginRight = 0.05

  static readonly supportPointButtonWidth = 0.25 - 0.025
  static readonly supportPointButtonHeight = 0.15
  static readonly supportPointButtonFontSize = 0.0235
  static readonly supportPointButtonMargin = 0.025

  static sidesAngleOrder = [
    'NarrowFaceRight',
    'LooseSide',
    'NarrowFaceLeft',
    'FixedSide',
  ]

  static sideAcronyms = [
    'NSR',
    'LS',
    'NSL',
    'FS',
  ]

  static prevSideLabelHeight = {}
  static prevSideLabelWidth = {}
  static prevSideLabelPosition = {}

  sectionDetail?: any
  clickableObjects?: any
  canViewSupportPoints: boolean
  plHeight?: number
  elementList?: any
  sgHasSupportPoints?: boolean
  isSelected?: boolean

  @AnalyzeTime(0)
  static reset () {
    Segment.prevSideLabelHeight = {}
    Segment.prevSideLabelWidth = {}
  }

  @AnalyzeTime(0)
  static getButton (text: string, x: number, side: string, width: number, height: number) {
    const group = new THREE.Group()

    const rect = new THREE.Mesh(Segment.getPlaneBufferGeometry(width, height), Segment.buttonMaterial)

    rect.position.set(x, -height / 2, 0.0001)
    rect.name = `Label_${side}`
    group.add(rect)

    const content = Util.getText(text, 0.06, true)

    content.position.set(x, -(height / 2 + 0.03), 0.001)
    content.name = `Text_${side}`
    group.add(content)

    group.position.z = 0.0001
    group.name = 'ButtonGroup'

    return group
  }

  @AnalyzeTime(0)
  static getSideButton (sideAcronym: string, side: string, index: number, active: boolean) {
    // TODO: optimize these values and make sure they are available where they are needed
    const xPos = index * (Segment.sideButtonWidth + Segment.sideButtonMarginRight)
    const group = new THREE.Group()

    const button = Segment.getButton(sideAcronym, xPos, side, Segment.sideButtonWidth, Segment.sideButtonHeight)

    group.add(button)

    if (active) {
      const activeBar = new THREE.Mesh(
        Segment.getPlaneBufferGeometry(Segment.sideButtonWidth, Segment.sideButtonBarHeight),
        Segment.highlightMaterial,
      )

      activeBar.position.set(
        xPos,
        -(Segment.sideButtonHeight + Segment.sideButtonFontSize + (Segment.sideButtonBarHeight / 2)),
        0.0001,
      )
      activeBar.name = `ActiveBar_${side}`
      group.add(activeBar)
    }
    else {
      const activeBar = new THREE.Mesh(
        Segment.getPlaneBufferGeometry(Segment.sideButtonWidth, Segment.sideButtonBarHeight),
        Segment.textMaterial,
      )

      activeBar.position.set(
        xPos,
        -(Segment.sideButtonHeight + Segment.sideButtonFontSize + (Segment.sideButtonBarHeight / 2)),
        0.0001,
      )
      activeBar.name = `ActiveBar_${side}`
      group.add(activeBar)
    }

    group.position.z = 0.0001
    group.name = side

    return group
  }

  static sections = {}

  @AnalyzeTime(0)
  static getSections (container: any) {
    if ((Segment.sections as any)[container.userData.side]) {
      return (Segment.sections as any)[container.userData.side]
    }

    // segment group label
    const upperSection = new THREE.Group()

    upperSection.name = 'UpperSection'
    const width = (Segment.sideButtonWidth + Segment.sideButtonMarginRight) * 4 - Segment.sideButtonMarginRight
    const rim = 0.1

    Segment.sgRim = rim

    const backgroundGeometry = Segment.getPlaneBufferGeometry(width + rim, 0.3)
    const background = new THREE.Mesh(backgroundGeometry, Segment.backgroundMaterial)

    background.rotateY(Util.RAD180)
    background.name = 'Background'
    background.position.set(-(width + rim) / 2, -(0.3 / 2), 0)

    upperSection.add(background)

    const geometryGroupLabel = Segment.getPlaneBufferGeometry(width, 0.2)
    const segmentGroupLabel = new THREE.Mesh(geometryGroupLabel, Segment.buttonMaterial)

    segmentGroupLabel.rotateY(Util.RAD180)
    segmentGroupLabel.name = 'Label'
    segmentGroupLabel.position.set(-width / 2 - (rim / 2), -(0.2 / 2) - (rim / 2), -0.0001)

    const segmentGroup = new THREE.Group()

    segmentGroup.name = 'SegmentGroup'

    segmentGroup.add(segmentGroupLabel)

    upperSection.add(segmentGroup)
    upperSection.name = 'UpperSection'

    // segment labels
    const lowerSection = new THREE.Group()

    lowerSection.name = 'LowerSection'

    // TODO: use variables
    const height = 0.16 + Segment.sideButtonFontSize + 0.02 + 0.02 + rim

    const background2Geometry = Segment.getPlaneBufferGeometry(width + rim, height)
    const background2 = new THREE.Mesh(background2Geometry, Segment.backgroundMaterial)

    background2.rotateY(Util.RAD180)
    background2.name = 'Background'
    background2.position.set(-(width + rim) / 2, -(height / 2 + 0.3), 0)

    lowerSection.add(background2)

    const segments = new THREE.Group()

    segments.name = 'Segments'

    // TODO: use real value
    segments.position.x -= (width - (0.15 + 0.1) * (Segment.sidesAngleOrder.length - 1) - 0.15) / 2
    segments.position.y -= 0.2 + 0.1

    Segment.sidesAngleOrder.forEach((side, index) => {
      const sideButton = Segment.getSideButton(
        Segment.sideAcronyms[index],
        side,
        index,
        container.userData.side === side,
      )

      sideButton.rotateY(Util.RAD180)
      sideButton.position.set(-(rim / 2), -(rim / 2), -0.0001)

      segments.add(sideButton)
    })

    lowerSection.add(segments)

    ;(Segment.sections as any)[container.userData.side] = {
      upperSection,
      lowerSection,
    }

    return (Segment.sections as any)[container.userData.side]
  }

  @AnalyzeTime(0)
  static getPlaneBufferGeometry (width: number, height: number) {
    const geometryKey = `${width}_${height}`
    let geometry = Segment._planeGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.PlaneBufferGeometry(width, height, 1)

      Segment._planeGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  constructor (
    container: any,
    parent: any,
    clickableObjects: any,
    sectionDetail: any,
    featureFlags: Record<string, boolean>,
  ) {
    super(container, parent)

    this.sectionDetail = sectionDetail
    this.clickableObjects = clickableObjects
    this.canViewSupportPoints = FeatureFlags.canViewSupportPointsIn3D(featureFlags)

    if (this.sectionDetail) {
      return
    }

    const { upperSection, lowerSection } = Segment.getSections(container)

    const upper = upperSection.clone()
    const lower = lowerSection.clone()

    const sgLabel = upper.getObjectByName('SegmentGroup').getObjectByName('Label')

    clickableObjects.push(sgLabel)

    const segments = lower.getObjectByName('Segments')

    Util.sides.forEach(side => {
      const button = segments.getObjectByName(side).getObjectByName('ButtonGroup').getObjectByName(`Label_${side}`)

      clickableObjects.push(button)
    })

    container.add(upper)
    container.add(lower)

    this.objects.upperSection = upper
    this.objects.lowerSection = lower
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    data: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    elementList: any,
    elementsHashes: ElementsHashes,
  ) {
    super.setValues(path, isHidden)

    if (this.sectionDetail) {
      return
    }

    this.plHeight = plHeight
    this.elementList = elementList

    const { upperSection, lowerSection } = this.objects

    const parentInfo = Util.getParentInfo(path)
    const parentSg = elementsHashes.SegmentGroup[parentInfo.id] ?? {} as SegmentGroup

    this.sgHasSupportPoints =
      this.canViewSupportPoints &&
      data.side === 'FixedSide' &&
      // TODO: # in name not working with TS?
      Boolean((parentSg as any)['#SupportPointIds']?.length)

    this.updateName(parentSg._name || `Seg-${parentInfo.id}`)
    this.updateSupportPointButton()

    this.updateTransform()

    const segmentGroup = upperSection.getObjectByName('SegmentGroup')

    segmentGroup.getObjectByName('Label').userData.filter = `${parentInfo.path}/*`

    const availableSides = this.container.parent.children
      .filter((child: any) =>
        child.children.filter((cld: any) => cld.name !== 'UpperSection' && cld.name !== 'LowerSection').length > 0)
      .map((child: any) => child.userData.side)

    const segmentArray = (elementsHashes.SegmentGroup[parentInfo.id] || {} as any)['#SegmentIds'] || []

    Util.sides.forEach((side, index) => {
      const segment = lowerSection.getObjectByName('Segments').getObjectByName(side)
      const button = segment.getObjectByName(`Label_${side}`)
      const text = segment.getObjectByName(`Text_${side}`)
      const activeBar = segment.getObjectByName(`ActiveBar_${side}`)

      if (!~availableSides.indexOf(side)) {
        button.userData.disabled = true
        button.material = Segment.buttonDisabledMaterial
        text.material = Segment.textDisabledMaterial
        activeBar.material = Segment.textDisabledMaterial
      }
      else {
        button.userData.disabled = false
        button.material = Segment.buttonMaterial
        text.material = Segment.textMaterial
        activeBar.material = this.container.userData.side === side ? Segment.highlightMaterial : Segment.textMaterial
      }

      button.userData.filter = `${parentInfo.path}/Segment:${(elementsHashes.Segment[segmentArray[index]] || {})._id}/*`
    })
  }

  updateName (name: string) {
    const { upperSection } = this.objects
    const maxLength = this.sgHasSupportPoints
      ? Segment.maxSegmentGroupTextLengthShort
      : Segment.maxSegmentGroupTextLength
    const text = name.length > maxLength ? `${name.slice(0, maxLength - 3)}...` : name
    const newText = Util.getText(text, 0.08, false, true)

    newText.rotateY(Util.RAD180)
    newText.name = 'Text'

    const width = (Segment.sideButtonWidth + Segment.sideButtonMarginRight) * 4 - Segment.sideButtonMarginRight

    const centerShift = this.sgHasSupportPoints
      ? -(Segment.supportPointButtonWidth / 2 + Segment.supportPointButtonMargin / 2)
      : 0

    newText.position.set(-width / 2 - (Segment.sgRim / 2) - centerShift, -0.1 - (Segment.sgRim / 2), -0.0002)

    const segmentGroup = upperSection.getObjectByName('SegmentGroup')

    Util.addOrReplace(segmentGroup, newText)
  }

  updateSupportPointButton () {
    if (!this.sgHasSupportPoints) {
      return
    }

    // TODO use or remove Segment.supportPointButtonFontSize

    const contentWidth = (Segment.sideButtonWidth + Segment.sideButtonMarginRight) * 4 - Segment.sideButtonMarginRight

    const button = Segment.getButton(
      'SP',
      0,
      'SegmentGroupDetails',
      Segment.supportPointButtonWidth,
      Segment.supportPointButtonHeight,
    )

    const rect = button.getObjectByName('Label_SegmentGroupDetails')

    if (rect && rect instanceof THREE.Mesh) {
      rect.material = this.isSelected ? Segment.highlightMaterial : Segment.backgroundMaterial
    }

    (rect as any).userData.type = 'SegmentGroupDetails'
    ;(rect as any).path = this.parent.path
    // TODO: show selected when path is in selections!

    button.rotateY(Util.RAD180)

    const x = Segment.supportPointButtonWidth / 2 + Segment.sgRim / 2 + contentWidth -
      Segment.supportPointButtonWidth - Segment.supportPointButtonMargin
    const y = Segment.sgRim / 2 + Segment.supportPointButtonMargin

    button.position.set(-x, -y, -0.0002)

    const { upperSection } = this.objects

    const segmentGroup = upperSection.getObjectByName('SegmentGroup')
    const oldElement = segmentGroup?.getObjectByName('ButtonGroup')?.getObjectByName('Label_SegmentGroupDetails')

    Util.addOrReplaceInList(oldElement, rect, this.clickableObjects)
    Util.addOrReplace(segmentGroup, button)
  }

  setSegmentGroupSelected (isSelected: boolean) {
    this.isSelected = isSelected

    const { upperSection } = this.objects
    const segmentGroup = upperSection?.getObjectByName('SegmentGroup')
    const spButton = segmentGroup?.getObjectByName('ButtonGroup')?.getObjectByName('Label_SegmentGroupDetails')

    if (!spButton) {
      return
    }

    spButton.material = isSelected ? Segment.highlightMaterial : Segment.backgroundMaterial
  }

  @AnalyzeTime(0)
  updateTransform () {
    if (this.sectionDetail) {
      return
    }

    this.container.userData = {
      ...this.container.userData,
      heightMin: Infinity,
      heightMax: -Infinity,
    }

    let plMinCoord = this.plHeight || 0
    let plMaxCoord = 0
    let plMinCoordByRoller = this.plHeight || 0
    let plMaxCoordByRoller = 0
    let count = 0

    this.container.children.forEach((child: any) => {
      const { type, path } = child

      if ((type === 'Nozzle' || type === 'Roller') && path) {
        count++

        const { heightMin, heightMax } = this.elementList[type][path].getMeasures()

        plMinCoord = Math.min(this.elementList[type][path].plCoord || Infinity, plMinCoord)
        plMaxCoord = Math.max(this.elementList[type][path].plCoord || 0, plMaxCoord)

        this.container.userData = {
          ...this.container.userData,
          heightMin: Math.min(this.container.userData.heightMin, heightMin || Infinity),
          heightMax: Math.max(this.container.userData.heightMax, heightMax || -Infinity),
          plMinCoord,
          plMaxCoord,
        }
      }

      if (type === 'Roller') {
        plMinCoordByRoller = Math.min(this.elementList[type][path].plCoord || Infinity, plMinCoordByRoller)
        plMaxCoordByRoller = Math.max(this.elementList[type][path].plCoord || 0, plMaxCoordByRoller)

        this.container.userData = {
          ...this.container.userData,
          plMinCoordByRoller,
          plMaxCoordByRoller,
        }
      }
    })

    const { heightMax, side } = this.container.userData
    const { upperSection, lowerSection } = this.objects

    if (count === 0) {
      upperSection.visible = false
      lowerSection.visible = false

      return
    }

    upperSection.visible = true
    lowerSection.visible = true

    ;(Segment.prevSideLabelHeight as any)[side] = heightMax

    const { position, normal, angleX } = PasslineCurve.getInfoAtPlCoord(plMinCoord)
    const { FixedSide, LooseSide, NarrowFaceRightWidest, NarrowFaceLeftWidest } = Mold.sideDistance

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const space = 0.5
    let dis

    switch (side) {
      case 'FixedSide':
        dis = NarrowFaceRightWidest.x - space
        newPosition.set(dis, position.y, position.z + FixedSide.x)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-angleX, 0, 0)
        break
      case 'LooseSide':
        dis = NarrowFaceLeftWidest.x + space
        newPosition.set(dis, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(-angleX, Util.RAD180, 0)
        break
      case 'NarrowFaceRight':
        dis = LooseSide.z - space
        newPosition.set(NarrowFaceRightWidest.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(dis))
        newRotation.set(-angleX, Util.RAD90, 0)
        break
      case 'NarrowFaceLeft':
        dis = FixedSide.z + space
        newPosition.set(NarrowFaceLeftWidest.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(dis))
        newRotation.set(-angleX, -Util.RAD90, 0)
        break
      default:
    }

    // TODO: use x, y, z distance instead of vector distance
    const distance =
    ((Segment.prevSideLabelPosition as any)[side] || new THREE.Vector3(0, 0, 0)).distanceTo(newPosition)

    // TODO: 1.21 + 0.1 ? get real width and space vars!?
    if (distance < 1.21 + 0.1) {
      const offset = new THREE.Vector3(-1.31, 0, 0)

      offset.applyEuler(newRotation)

      newPosition.add(offset)
    }

    (Segment.prevSideLabelPosition as any)[side] = newPosition

    upperSection.position.copy(newPosition)
    lowerSection.position.copy(newPosition)

    upperSection.rotation.copy(newRotation)
    lowerSection.rotation.copy(newRotation)

    lowerSection.position.y -= 0.01
  }

  @AnalyzeTime(0)
  hide () {
    super.hide()

    this.container.visible = false

    this.container.children.forEach((child: any) => {
      child.visible = false
    })
  }
}
