export enum airLoopActionsEnum {
  ACTION_ADD_AIR_LOOP = 'AirLoop/ACTION_ADD_AIR_LOOP',
  DELETE = 'AirLoop/DELETE',
  DELETE_MULTIPLE = 'AirLoop/DELETE_MULTIPLE',
  UPDATE = 'AirLoop/UPDATE'
}

export function addAirLoops (airLoopHash: Record<number, AirLoop & Parent>) {
  return {
    type: airLoopActionsEnum.ACTION_ADD_AIR_LOOP,
    airLoopHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const airLoopReducers: Record<string, Function | undefined> = {
  [airLoopActionsEnum.ACTION_ADD_AIR_LOOP]:
  (_state: AirLoopHash, action: { airLoopHash: AirLoopHash }) => action.airLoopHash,

  [airLoopActionsEnum.DELETE]: (state: AirLoopHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [airLoopActionsEnum.DELETE_MULTIPLE]: (state: AirLoopHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [airLoopActionsEnum.UPDATE]: (state: AirLoopHash, action: {elements: AirLoopHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (state: AirLoopHash = {}, action: any) {
  const handler = airLoopReducers[action.type]

  return handler ? handler(state, action) : state
}
