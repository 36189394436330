import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import cloneDeep from 'clone-deep'

import * as ApplicationActions from 'store/application/main/actions'
import Button from 'react/components/Button/index'
import Input from 'react/specific/Input'
import FeatureFlags from 'react/FeatureFlags/index'

import Logic, { PRE_TRANS as PRE_TRANS_PARENT } from './Logic'
import { FileName } from './styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = `${PRE_TRANS_PARENT}.nozzles`

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  uploadType: string
};

export class Nozzles extends Component<Props, State> {
  state = {
    uploadType: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleData()
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleData()
  }

  // @AnalyzeTime(0)
  handleData = () => {
    if (this.state.uploadType !== '') {
      return
    }

    const { currentSimulationCase: { inputData: { nozzles: { uploadType } } } } = this.props

    if (!uploadType) {
      return
    }

    this.setState({ uploadType })
  };

  // @AnalyzeTime(0)
  handleChangeUploadType = (event: any) => this.setState({ uploadType: (event.target.value as any) });

  // @AnalyzeTime(0)
  handleUploadNozzlesProjectFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.prj', 'nozzles', 'fileProject', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadNozzlesLooseSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileLooseSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadNozzlesFixedSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileFixedSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadNozzlesRightSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileRightSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadNozzlesLeftSideFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileLeftSide', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleUploadNozzlesCatalogFile = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'nozzles', 'fileCatalog', currentSimulationCase, setCurrentSimulationCase, uploadType)
  };

  // @AnalyzeTime(0)
  handleGenerate = async () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    const data = await Logic.handleGenerateNozzles(currentSimulationCase._id, uploadType)

    if (!Object.keys(data).length) {
      return
    }

    const simulationCase = cloneDeep(currentSimulationCase)

    simulationCase.inputData.nozzles = data

    setCurrentSimulationCase(simulationCase)
  };

  @AnalyzeTime(0)
  render () {
    const { uploadType } = this.state
    const { authenticationData, currentSimulationCase, t } = this.props
    const {
      inputData: { strandGuide, nozzles },
      miscLockedBy,
      nozzlesLockedBy,
      simulationStartedAt,
      currentCasterRoot,
    } = currentSimulationCase
    const {
      fileProject,
      fileLooseSide,
      fileFixedSide,
      fileRightSide,
      fileLeftSide,
      fileCatalog,
      generateStartedBy,
    } = nozzles
    const { generateStartedBy: otherGenerateStartedBy } = strandGuide

    const uploadTypeSelectors = [
      { key: '4', value: t(`${PRE_TRANS}.uploadType.4`) },
      { key: '5', value: t(`${PRE_TRANS}.uploadType.5`) },
    ]

    const noFile = t(`${PRE_TRANS_PARENT}.noFile`)

    const { isLocked: generalLocked } =
      FeatureFlags.getLockedStatus('General', currentSimulationCase, authenticationData)
    const { isLocked: nozzlesLocked } =
      FeatureFlags.getLockedStatus('Nozzles', currentSimulationCase, authenticationData)

    // FIXME: This is a temporary fix to prevent false locking
    const isLocked = false
    // const isLocked = (miscLockedBy && generalLocked) || (nozzlesLockedBy && nozzlesLocked)

    const disabled =
      !currentCasterRoot ||
      simulationStartedAt ||
      generateStartedBy ||
      otherGenerateStartedBy ||
      isLocked ||
      (uploadType === '4' && !fileProject) ||
      (uploadType === '5' && (!fileLooseSide || !fileFixedSide || !fileRightSide || !fileLeftSide || !fileCatalog))

    return (
      <div>
        <Input
          name='uploadType'
          type='select'
          label={t(`${PRE_TRANS}.uploadType.label`)}
          title={t(`${PRE_TRANS}.uploadType.title`)}
          value={uploadType}
          selectors={uploadTypeSelectors}
          onChange={this.handleChangeUploadType}
        />
        {
          uploadType === '4' &&
            <div>
              <Button
                title={t(`${PRE_TRANS}.uploadProjectFile.title`)}
                onClick={this.handleUploadNozzlesProjectFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadProjectFile.label`)}
              </Button>
              <FileName title={fileProject || noFile}>{fileProject || noFile}</FileName>
            </div>
        }
        {
          uploadType === '5' &&
            <div>
              <Button
                title={t(`${PRE_TRANS}.uploadLooseSideFile.title`)}
                onClick={this.handleUploadNozzlesLooseSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadLooseSideFile.label`)}
              </Button>
              <FileName title={fileLooseSide || noFile}>{fileLooseSide || noFile}</FileName>
              <Button
                title={t(`${PRE_TRANS}.uploadFixedSideFile.title`)}
                onClick={this.handleUploadNozzlesFixedSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadFixedSideFile.label`)}
              </Button>
              <FileName title={fileFixedSide || noFile}>{fileFixedSide || noFile}</FileName>
              <Button
                title={t(`${PRE_TRANS}.uploadRightSideFile.title`)}
                onClick={this.handleUploadNozzlesRightSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadRightSideFile.label`)}
              </Button>
              <FileName title={fileRightSide || noFile}>{fileRightSide || noFile}</FileName>
              <Button
                title={t(`${PRE_TRANS}.uploadLeftSideFile.title`)}
                onClick={this.handleUploadNozzlesLeftSideFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadLeftSideFile.label`)}
              </Button>
              <FileName title={fileLeftSide || noFile}>{fileLeftSide || noFile}</FileName>
              <Button
                title={t(`${PRE_TRANS}.uploadCatalogFile.title`)}
                onClick={this.handleUploadNozzlesCatalogFile}
                disabled={simulationStartedAt}
                type='tertiary'
                icon='pe-7s-cloud-upload'
                textPaddingLeft
              >
                {t(`${PRE_TRANS}.uploadCatalogFile.label`)}
              </Button>
              <FileName title={fileCatalog || noFile}>{fileCatalog || noFile}</FileName>
            </div>
        }
        <Button
          title={
            isLocked
              ? t(`${PRE_TRANS}.generate.titleLocked`)
              : (
                !currentCasterRoot
                  ? t(`${PRE_TRANS}.generate.titleMissingCaster`)
                  : t(`${PRE_TRANS}.generate.title`)
              )
          }
          onClick={this.handleGenerate}
          disabled={disabled}
          loading={generateStartedBy}
          noSuccessIcon
          type='tertiary'
          bold
        >
          {t(`${PRE_TRANS}.generate.label`)}
        </Button>
      </div>
    )
  }
}

export default withNamespaces('application')(connector(Nozzles as any) as any) as any
