import IpcManager from 'IpcManager'
import Util from 'logicHandlers/ServerLogic/actions/Util'
import { Network } from 'network/Network'
import CasterHandleDataDialog from 'react/dialogs/caster/CasterHandleDataDialog'
import FeatureFlags from 'react/FeatureFlags'
import ApiClient from 'store/apiClient'
import { AppState } from 'store/application/main/consts'
import ThreeManager from 'three/ThreeManager'
import { AnalyzeTime } from 'Util'
import type { Props } from './CasterColumn'
import { Info } from 'logic/Info'

export class CasterColumnLogic {
  @AnalyzeTime(0)
  static async catalogCallback (props: Props) {
    const { currentCatalogId, saveCatalog, setError } = props

    if (!currentCatalogId) {
      return
    }

    const { casterCatalog } = await ApiClient
      .get(`${Network.URI}/caster_catalog/${currentCatalogId}`)
      .catch(({ error }) => {
        setError('openCatalog', error.status)
        // this.setState({ loading: { openCaster: false } })

        return {}
      })

    saveCatalog(((casterCatalog || {}).data))
  }

  static async handleLoadCasterConfigWithId (
    props: Props,
    configId: string,
    tabIndex: string,
  ) {
    const {
      setConfig,
      setDataSources,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      appState,
    } = props

    if (!FeatureFlags.canViewCasterDashboard(props.featureFlags)) {
      return
    }

    const { visualizationConfig } = await ApiClient
      .get(`${Network.URI}/visualization_config/${configId}?rid=5.2&emptySelectedComparisonCasters=1`)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        // setError('openDashboard', error.status)

        return {}
      })

    if (!visualizationConfig) {
      // this.setState({ loading: { openVis: false } })

      return
    }

    setVisualizationMetaInformation('config', configId, appState)

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources)
    const tabIndexNumber = Number(tabIndex)

    if (tabIndex === '' || isNaN(tabIndexNumber)) {
      setSimpleDashboardTabIndex(1)

      return
    }

    let numberOfTabs = 0
    const viewsObject = (visualizationConfig.data.viewsObject || {})
    const currentViewObject = viewsObject[(Object.keys(viewsObject) || [])[0]] || {}

    numberOfTabs = (Object.keys(currentViewObject.dashboards || {})).length

    setSimpleDashboardTabIndex(numberOfTabs ? (tabIndexNumber > numberOfTabs ? 1 : tabIndexNumber) : 0)
  }

  @AnalyzeTime(0)
  static async handleLoadCasterDashboard (props: Props) {
    const {
      setConfig,
      setDataSources,
      visualizationMetaInformation,
      currentSimpleDashboardTabIndex,
      setSimpleDashboardTabIndex,
    } = props
    const { config } = (visualizationMetaInformation || {})[AppState.Caster] || {}

    if (!config) {
      return
    }

    const { visualizationConfig } = await ApiClient
      .get(`${Network.URI}/visualization_config/${config}?rid=5`)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        // setError('openDashboard', error.status)

        return {}
      })

    if (!visualizationConfig) {
      // this.setState({ loading: { openVis: false } })

      return
    }

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources)

    if (currentSimpleDashboardTabIndex && setSimpleDashboardTabIndex) {
      setSimpleDashboardTabIndex(currentSimpleDashboardTabIndex)
    }
  }

  @AnalyzeTime(0)
  static async openCaster (props: Props) {
    const {
      currentProject,
      currentCasterRoot,
      currentSimulationCase,
      setAppState,
      setCurrentCasterRoot,
      setError,
      onClose,
      appState,
    } = props

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const close = onClose ?? (() => {})

    Info.setRecentlyUsedInfo({ projectId: currentProject._id, caseId: currentSimulationCase._id })

    const { root } = await ApiClient
      .get(`${Network.URI}/caster_data_json/${currentSimulationCase._id}?r=4`)
      .catch(({ error }) => {
        setError('openCaster', error.status)

        return {}
      })

    if (root) {
      setAppState(AppState.Caster)

      setCurrentCasterRoot(root)

      IpcManager.both.send('loading', null, true, true)

      setTimeout(async () => {
        IpcManager.internal.send(
          'data',
          null,
          { root },
          true,
          null,
          CasterColumnLogic.catalogCallback.bind(null, props),
        )

        await this.handleLoadCasterDashboard(props)

        IpcManager.both.send('loading', null, false, true)

        // TODO: load catalog here?
      }, 100)

      close()
    }

    if (Object.entries(currentCasterRoot).length && appState !== AppState.Caster) {
      setAppState(AppState.Caster)

      close()
    }
  }

  @AnalyzeTime(0)
  static handleUploadCasterData (
    action: 'replaceCaster' | 'replaceNozzles' | 'mergeNozzles' = 'replaceCaster',
    props: Props,
    force = false,
    setLoadingStatus?: (loading: boolean) => void,
  ): void {
    const {
      currentSimulationCase,
      setCurrentSimulationCase,
      setCurrentCasterRoot,
      resetReducer,
      setError,
      setFileUploadLoadingStatus,
      openDialog,
      closeDialog,
      featureFlags,
      enqueueSnackbar,
      setAppState,
    } = props

    if (!force && currentSimulationCase.currentCasterRoot) {
      openDialog(CasterHandleDataDialog.NAME)

      return
    }

    closeDialog(CasterHandleDataDialog.NAME)

    if (currentSimulationCase && currentSimulationCase._id) {
      Util.openUploadFileDialog(
        '.xml', '/caster_data', (formData: any) => {
          setAppState(AppState.Caster)
          IpcManager.both.send('setLoading', null, true, 'VisualizationIsLoading')
          resetReducer(false, true)
          setCurrentCasterRoot()
          setError('CasterData')
          setFileUploadLoadingStatus('CasterDataIsLoading', true)

          formData.append('simulationCaseId', currentSimulationCase._id)
          formData.append('action', action)
          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
        }
        // eslint-disable-next-line function-call-argument-newline
        , setLoadingStatus,
      ).then(async ({ simulationCase }) => {
        setCurrentSimulationCase(simulationCase)
        setError('CasterData')
        setFileUploadLoadingStatus('CasterDataIsLoading', false)

        if (FeatureFlags.usesSlimVersion(featureFlags)) {
          await CasterColumnLogic.openCaster(props)
        }
      }).catch(({ status }) => {
        if (setLoadingStatus) {
          setLoadingStatus(false)
        }

        enqueueSnackbar(
          'Error uploading xml file, please upload another',
          { variant: 'error' },
        )
        setError('CasterData', status)
        setFileUploadLoadingStatus('CasterDataIsLoading', false)
      })
    }
  }
}
