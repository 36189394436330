import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import BaseDialog from '../../BaseDialog'

import SimulationCaseNameEditor from './SimulationCaseNameEditor'

import { Wrapper } from './Styles'
import CommandEditor from './CommandEditor'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  columns: state.matrix.columns,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  col: number,
  selectedCellData: Array<any>,
  simulationCaseId: string,
  simulationStartedAt?: Date,
  cell: any,
  onClose: () => void,
  t: Translation
}

class Editor extends Component<Props> {
  // @AnalyzeTime(0)
  handleClose = () => this.props.onClose();

  @AnalyzeTime(0)
  render () {
    const { col, cell, columns, simulationCaseId, simulationStartedAt, selectedCellData } = this.props
    const title = columns[col].label

    return (
      <Wrapper className='allowScroll'>
        <BaseDialog medium icon='pe-7s-note' header={title} title={title} onClose={this.handleClose}>
          {
            cell.editType === 'simulationCaseName' &&
              <SimulationCaseNameEditor {...cell} selectedCellData={selectedCellData} />
          }
          {
            cell.editType === 'command' &&
              <CommandEditor
                {...cell}
                selectedCellData={selectedCellData}
                simulationCaseId={simulationCaseId}
                simulationStartedAt={simulationStartedAt}
              />
          }
        </BaseDialog>
      </Wrapper>
    )
  }
}

export default connector(Editor)
