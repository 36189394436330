import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from 'react/components/Button/index'
import { Form, Text, TextBody, List } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import { FixedButton } from '../../DialogStyles'
import * as ApplicationActions from 'store/application/main/actions'
import * as MatrixActions from 'store/matrix/actions'

import Logic from '../Logic'

import BaseDialog from '../../BaseDialog'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'

const PRE_TRANS = 'projectMatrixDialog.simulateDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  selections: state.matrix.selections,
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  setSelections: MatrixActions.setSelections,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  all: boolean,
  t: Translation,
}

type State = {
  error: string,
  loading: boolean
};

class SimulateDialog extends Component<Props, State> {
  static NAME = uuid()
  static ALLNAME = uuid()

  state = {
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Enter', this.handleStartSimulation)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Enter', this.handleStartSimulation)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog, all } = this.props

    closeDialog(all ? SimulateDialog.ALLNAME : SimulateDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleStartSimulation = () => {
    const { selections, setSelections } = this.props
    const verifiedSimulationCases = this.handleGetVerified()
    const ids = verifiedSimulationCases.map(({ simulationCase }) => simulationCase._id)

    const deselect = { ...selections }

    Object.keys(selections).forEach(key => {
      deselect[key] = false
    })
    setSelections(deselect)

    Logic.handleSimulateMultiple(ids)
    this.handleClose()
  };

  // @AnalyzeTime(0)
  handleGetVerified = () => {
    const { currentProject, selections, all } = this.props

    return currentProject.simulationCases
      .map((simulationCase, index) => ({ num: index + 1, simulationCase }))
      .filter(({ simulationCase }) => all || selections[simulationCase._id])
      .filter(({ simulationCase }) => {
        const {
          simulationStartedAt,
          processingParametersVerifications,
          catalogVerifications,
          casterVerifications,
        } = simulationCase

        const currentProcessingParametersVerification =
          (processingParametersVerifications[processingParametersVerifications.length - 1] || {}).isVerified || false
        const currentCatalogVerification = (catalogVerifications[catalogVerifications.length - 1] || {}).isVerified ||
         false
        const currentCasterVerification = (casterVerifications[casterVerifications.length - 1] || {}).isVerified ||
         false

        return !simulationStartedAt &&
         currentProcessingParametersVerification &&
         currentCatalogVerification &&
         currentCasterVerification
      })
  };

  @AnalyzeTime(0)
  render () {
    const { error, loading } = this.state
    const { t, featureFlags } = this.props

    const verifiedSimulationCases = this.handleGetVerified()
    const canStartSimulation = FeatureFlags.canStartSimulation(featureFlags)

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-display1'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Text>
            {t(`${PRE_TRANS}.message`)}
          </Text>
          <TextBody>
            <List>
              {
                verifiedSimulationCases.map(({ num, simulationCase }) =>
                  <li key={simulationCase._id}>{num}. {simulationCase.name}</li>)
              }
            </List>
          </TextBody>
          <FixedButton>
            <Button
              type='primary'
              onClick={this.handleStartSimulation}
              error={error}
              loading={loading}
              icon='pe-7s-display1'
              isRef
              disabled={!canStartSimulation}
            >
              {t(`${PRE_TRANS}.button`)}
            </Button>
          </FixedButton>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(SimulateDialog as any) as any) as any
