import * as THREE from 'three'

export default class TextureUtil {
  static _textureLoader: THREE.TextureLoader | null = null;
  static _textureRegistry: {
    [x: string]: THREE.Texture
  } = {};

  static init (): void {
    TextureUtil._textureLoader = new THREE.TextureLoader()
    TextureUtil._textureRegistry = {}
  }

  static load (path: string, force = false): THREE.Texture {
    const { _textureRegistry, _textureLoader } = TextureUtil

    if (_textureLoader && (force || !_textureRegistry[path])) {
      _textureRegistry[path] = _textureLoader.load(path)
    }

    return _textureRegistry[path]
  }
}
