import cloneDeep from 'clone-deep'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import Util from 'logicHandlers/ServerLogic/actions/Util'
import { AnalyzeTime } from 'Util'

export const PRE_TRANS = 'inputDataDialog'

export default class Logic {
  @AnalyzeTime(0)
  static async handleUpload (
    ext: string,
    type: string,
    fileType: string,
    currentSimulationCase: SimulationCase,
    setCurrentSimulationCase: (simCase: SimulationCase) => void,
    uploadType: string,
  ) {
    try {
      const data = await
      Util.openUploadFileDialog(ext, `/caster_input_data/${type}/${fileType}`, (formData: FormData) => {
        formData.append('simulationCaseId', currentSimulationCase._id)
        formData.append('uploadType', uploadType)
      })

      const simulationCase = cloneDeep(currentSimulationCase)

      simulationCase.inputData = data.inputData

      setCurrentSimulationCase(simulationCase)
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  @AnalyzeTime(0)
  static async handleGenerateStrandGuide (simulationCaseId: string, uploadType: string): Promise<any> {
    try {
      const { data } =
        await ApiClient.post(`${Network.URI}/caster_generate/strandGuide`, { data: { simulationCaseId, uploadType } })

      return data
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return {}
  }

  @AnalyzeTime(0)
  static async handleGenerateNozzles (simulationCaseId: string, uploadType: string): Promise<any> {
    try {
      const { data } =
        await ApiClient.post(`${Network.URI}/caster_generate/nozzles`, { data: { simulationCaseId, uploadType } })

      return data
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return {}
  }
}
