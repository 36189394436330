import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Menu, { SubMenu, MenuItem, Divider } from 'rc-menu'
import { withNamespaces } from 'react-i18next'

import 'rc-menu/assets/index.css'
import './rcMenuStyles.css'

import * as ApplicationActions from 'store/application/main/actions'
import * as DataActions from 'store/data/actions'
import { getFilterControlDefinitions } from 'store/filter/actions'

import logic from './logic/'
import { MenuBar, Label, Shortcut } from './styles'
import HotKeyHandler from './HotKeyHandler'
import FeatureFlags from 'react/FeatureFlags'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  executableDefinitions: state.data.executableDefinitions,
  filterControlDefinitions: state.filter.filterControlDefinitions,
  openAppDialogs: state.application.main.openAppDialogs,
  featureFlags: state.application.main.authenticationData.featureFlags,
  authenticationData: state.application.main.authenticationData,
  state,
}), {
  handleMenuAction: ApplicationActions.handleMenuAction,
  getExecutableDefinitions: DataActions.getExecutableDefinitions,
  getFilterControlDefinitions: getFilterControlDefinitions,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

class MenuBuilder extends Component<Props> {
  state = {
    openKeys: [],
    dontClose: false,
    alreadyFetchedDefinitions: false,
    alreadyFetchedFilterControlDefinitions: false,
  }

  @AnalyzeTime(0)
  componentDidMount (): void {
    const { menu } = logic
    const { appState, executableDefinitions, state, featureFlags } = this.props

    menu(appState, executableDefinitions, featureFlags).forEach((item: any) => {
      const isDisabled = item.disabled !== undefined ? item.disabled(state) : false

      if (!isDisabled) {
        HotKeyHandler.handleShortcuts(true, item, this.handleClick, state)
      }
    })
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props): void {
    const { menu } = logic
    const { alreadyFetchedDefinitions, alreadyFetchedFilterControlDefinitions } = this.state
    const {
      state,
      appState,
      executableDefinitions,
      featureFlags,
      filterControlDefinitions,
      getExecutableDefinitions,
      getFilterControlDefinitions,
      authenticationData,
    } = this.props

    let refetchExecutablesDefinitions = false
    let refetchFilterControlDefinitions = false

    if (prevProps.executableDefinitions.length && !this.props.executableDefinitions.length) {
      refetchExecutablesDefinitions = true
    }

    if (prevProps.filterControlDefinitions.length && !this.props.filterControlDefinitions.length) {
      refetchFilterControlDefinitions = true
    }

    if (prevProps.authenticationData.name !== authenticationData.name) {
      refetchExecutablesDefinitions = true
      refetchFilterControlDefinitions = true
    }

    if (
      !executableDefinitions?.length &&
      state.application.main.authenticationData.name &&
      (!alreadyFetchedDefinitions || refetchExecutablesDefinitions)
    ) {
      this.setState({ alreadyFetchedDefinitions: true })
      getExecutableDefinitions()
    }

    if (
      !filterControlDefinitions.length &&
      state.application.main.authenticationData.name &&
      (!alreadyFetchedFilterControlDefinitions || refetchFilterControlDefinitions)
    ) {
      this.setState({ alreadyFetchedFilterControlDefinitions: true })
      getFilterControlDefinitions()
    }

    if (prevProps.appState !== appState) {
      menu(prevProps.appState, executableDefinitions, featureFlags)
        .forEach(item => HotKeyHandler.handleShortcuts(false, item))
    }

    menu(appState, executableDefinitions, featureFlags)
      .forEach((item: any) => {
        const featureFlags = state.application.main.authenticationData.featureFlags
        const isVisible = (item.visible ? item.visible(featureFlags, state) : true) || true
        const isDisabled = item.disabled ? item.disabled(state) : false

        if (!isVisible || isDisabled) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
          HotKeyHandler.handleShortcuts(false, item, () => {}, state)
        }
        else {
          HotKeyHandler.handleShortcuts(true, item, this.handleClick, state)
        }
      })
  }

  @AnalyzeTime(0)
  componentWillUnmount (): void {
    const { menu } = logic
    const { appState, executableDefinitions, featureFlags } = this.props

    menu(appState, executableDefinitions, featureFlags).forEach(item => HotKeyHandler.handleShortcuts(false, item))
  }

  handleCloseAllKeys = () => {
    this.setState({
      openKeys: [],
      dontClose: false,
    })
  }

  handleUpdateOpenKeys = (e: { key: string, domEvent: any}) => {
    if (!(this.state.openKeys[0] === e.key)) {
      this.setState({
        openKeys: e.key ? [ e.key ] : [],
      })

      return
    }

    this.setState({
      openKeys: [],
    })
  }

  // @AnalyzeTime(0)
  handleClick = ({ action, params: rawParams, disabled }: any) => {
    const { handleMenuAction, state } = this.props
    const isDisabled = disabled !== undefined ? disabled(state) : false

    if (isDisabled) {
      return
    }

    const params = typeof rawParams === 'function' ? rawParams(state) : rawParams

    handleMenuAction(action, params)
  };

 handleBlur = () => {
   setTimeout(() => {
     if (this.state.dontClose) {
       return
     }

     this.setState({
       openKeys: [],
     })
   }, 100)
 };

 handleMenuItemMouseDown = (item: any) => {
   this.setState({ dontClose: true })
   this.handleClick(item)
   this.handleCloseAllKeys()
 }

 @AnalyzeTime(0)
 getSubMenu (item: any, index: any) {
   const { t, state } = this.props
   const { label, type, shortcut, disabled, submenu, visible } = item
   const isDisabled = disabled !== undefined ? disabled(state) : false
   const featureFlags = FeatureFlags.getRealFeatureFlags(state)
   const isVisible = visible !== undefined ? visible(featureFlags, state) : true

   if (!isVisible) {
     return
   }

   if (type === 'separator') {
     return <Divider key={index} />
   }

   if (submenu) {
     return (
     // to disable tooltip in submenus the title has to be an html element
     // https://github.com/ant-design/ant-design/issues/12277
       <SubMenu
         title={<span>{label(t, state)}</span>}
         key={index}
         onTitleClick={this.handleUpdateOpenKeys}
         disabled={isDisabled}
       >
         {
           submenu.map((subMenu: any, childIndex: number) =>
             this.getSubMenu(subMenu, `${index}-${childIndex}`))
         }
       </SubMenu>
     )
   }

   return (
     <MenuItem
       key={index}
       disabled={isDisabled}
       onMouseDown={() => this.handleMenuItemMouseDown(item)}
     >
       <Label>{label(t, state)}</Label><Shortcut disabled={isDisabled}>{shortcut}</Shortcut>
     </MenuItem>
   )
 }

 @AnalyzeTime(0)
 render () {
   const { appState, executableDefinitions, openAppDialogs, featureFlags } = this.props
   const { openKeys } = this.state
   const { menu } = logic
   const props: any = openAppDialogs.length ? { openKeys: [] } : { openKeys }

   // to open submenus with clicks instead of hovering
   props.triggerSubMenuAction = 'click'

   return (
     <MenuBar>
       <Menu mode='horizontal' {...props} onBlur={this.handleBlur}>
         {menu(appState, executableDefinitions, featureFlags).map((item, index) => this.getSubMenu(item, index))}
       </Menu>
     </MenuBar>
   )
 }
}

export default withNamespaces('titlebar')(connector(MenuBuilder as any) as any) as any
