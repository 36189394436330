import { Network } from 'network/Network'
import superagent from 'superagent'
import { AnalyzeTime } from 'Util'

type HttpMethods = 'get' | 'post' | 'put' | 'patch' | 'del'
export default class ApiClient {
  @AnalyzeTime(0)
  static request (
    method: HttpMethods,
    path: string,
    options: Partial<{
      params: any,
      data: any,
      form: any,
      files: any[],
      getHeadersOnly: boolean,
      headers: any
    }> = {},
  ): Promise<any> {
    const { params, data, form, files, getHeadersOnly, headers } = { ...Network.options, ...options }

    return new Promise((resolve, reject) => {
      const request = superagent[method](path)

      request
        .set('Accept', 'application/json, text/javascript, */*; q=0.01')
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()

      if (params) {
        request.query(params)
      }

      if (form) {
        request.type('form')
      }

      if (data) {
        request.send(data)
      }

      if (files) {
        request.set('Content-Type', 'multipart/form-data')
        files.map(file => request.attach(file.field, file.chunk))
      }

      if (headers) {
        for (const header in headers) {
          request.set(header, headers[header])
        }
      }

      request.end((error, response) => {
        const { body = {}, status } = response || {}

        if (error) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ error, body, status })

          return
        }

        if (getHeadersOnly) {
          resolve(response.headers || {})

          return
        }

        resolve(body)
      })
    })
  }

  @AnalyzeTime(0)
  static get (path: string, options?: any): Promise<any> {
    return ApiClient.request('get', path, options)
  }

  @AnalyzeTime(0)
  static post (path: string, options?: any): Promise<any> {
    return ApiClient.request('post', path, options)
  }

  @AnalyzeTime(0)
  static put (path: string, options?: any): Promise<any> {
    return ApiClient.request('put', path, options)
  }

  @AnalyzeTime(0)
  static patch (path: string, options?: any): Promise<any> {
    return ApiClient.request('patch', path, options)
  }

  @AnalyzeTime(0)
  static del (path: string, options?: any): Promise<any> {
    return ApiClient.request('del', path, options)
  }
}
