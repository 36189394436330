import React from 'react'
import styled, { css } from 'styled-components'
import DropDown from '../visualization/dashboard/SelectPlot'
import { FormControlLabel, FormControl, Checkbox, TextField } from '@material-ui/core'
import NumericInput from 'react-numeric-input'

import { getPercentage } from '../components/Button/styles'

type InputType =
  'text' |
  'select' |
  'selectRange' |
  'range' |
  'number' |
  'defaultRange' |
  'password' |
  'checkbox' |
  'axesPadding'

type InputWrapperProps = {
  half?: boolean,
  oneThird?: boolean,
  twoThirds?: boolean,
  noMarginTop?: boolean
};

export const InputWrapper = styled.div<InputWrapperProps>`${({ half, oneThird, twoThirds, noMarginTop }) => css`
    display: inline-block;
    margin-top: ${noMarginTop ? 0 : '17px'};
    white-space: nowrap;
    flex: 1;
    width: ${!(half || oneThird || twoThirds)
      ? '100%'
      : `calc(${getPercentage(half, oneThird, twoThirds)}% - 7px)!important`};
    
    ${(half || oneThird || twoThirds) && 'margin-right: 15px !important;'}

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  `}`

const CheckboxWrapper = styled.div`${({ theme }) => css`
  display:      flex;
  align-items:  center;
  width:        100%;
  padding-top:  16px;
  
  label span {
    color: ${theme.input.color};
  }
  
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`}`

export const Label = styled.label`${({ theme }) => css`
  display:          block;
  text-align:       left;
  color:            ${theme.input.color};
  font-size:        14px;
  align-self:       center;
  flex:             1;
  overflow:         hidden;
  user-select:      none;
  margin-bottom:    10px;
`}`

const InputRange = styled(({ half, ...restProps }) => <TextField {...restProps} />)`${
  ({ theme, half, disabled, readOnly }) => css`
  width: ${!half ? '100%' : 'calc(50% - 7px)!important'};
  ${disabled || readOnly ? 'cursor: not-allowed' : ''}

  input {
    text-align: right;
  }
`}`

const StyledNumericInput = styled(({ ...restProps }) => <NumericInput {...restProps} />)`${
  ({ theme }) => css`
    background: ${theme.input.background};
    color: ${theme.input.color} !important;
    border-radius: 5px !important;
    width: calc(100% - 9px);
    text-shadow: none !important;
  `
}`

const numericInputStyle = {
  wrap: {
    width: '100%',
  },
  'input:not(.form-control)': {
    border: 'none',
    font: 'inherit',
    height: '100%',
    margin: 0,
    padding: '10px 0 10px 10px',
    letterSpacing: 'inherit',
    boxSizing: 'content-box',
  },
  'input:focus': { outline: 'none' },
  btn: {
    border: 'none',
    outline: 'none',
    background: 'none',
    boxShadow: 'none',
  },
  'btn:hover': {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  arrowDown: {
    borderColor: '#a2a6a9 transparent transparent',
  },
  arrowUp: {
    borderColor: 'transparent transparent #a2a6a9',
  },
}

const InWrapper = styled.span`
  position:  relative;
  line-height: 38px;
  
  :first-of-type {
    margin-right: 14px;
  }
`

const InLabel = styled.span`${({ theme }) => css`
  position:    absolute;
  left:        10px;
  top:         50%;
  transform:   translate(0, -50%);
  color:       ${theme.input.placeholder};
  user-select: none;
`}`

type Props = {
  name: string
  secondName?: string
  value?: number | string | boolean | string[]
  min?: number
  max?: number
  step?: number
  precision?: number
  rows?: number
  label?: string
  placeholder?: string
  secondPlaceholder?: string
  title?: string
  titleMin?: string
  titleMax?: string
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  noMarginTop?: boolean
  type: InputType
  selectors?: Array<any>
  onChange?: (e: any) => void,
  onLabelRightClick?: (e: any) => void,
  inputRef?: any
  error?:any
  onDelete?:any
  onSetDefault?: (key: string) => void
  onUserHide?: (key: string) => void
  canHideUser?: boolean
  onDownload?:any
  onEdit?:any
  onKeyDown?:any
  disabled?: boolean
  noDeleteCurrent?:boolean
  noDeleteGiven?:any[]
  autoFocus?:any
  style?: any
  id?: string
  readonly?: boolean
  className?: string
  multiple?: boolean
  checkbox?: boolean
  selectedIds?: string[]
  optionStyles?: any
  spaceBetween?: boolean
  MenuProps?: any
  xAxisPadding?: number
  yAxisPadding?: number
  onCheckboxClick?: (e: any, comparisonCasterName: string) => void
  renderValue?: (values: []) => string
};

export default class InputComponent extends React.Component<Props> {
  getMinAndMax = () => {
    const { min, max } = this.props

    if (min !== undefined && max !== undefined) {
      return ` (${min} to ${max})`
    }
    else if (min !== undefined) {
      return ` (min: ${min})`
    }
    else if (max !== undefined) {
      return ` (max: ${max})`
    }

    return ''
  }

  render () {
    const {
      step,
      min,
      max,
      precision,
      rows,
      value,
      type,
      onChange,
      onLabelRightClick,
      selectors,
      label,
      placeholder,
      name,
      secondName,
      secondPlaceholder,
      title,
      titleMin,
      titleMax,
      half,
      oneThird,
      twoThirds,
      noMarginTop,
      xAxisPadding,
      yAxisPadding,
      ...restProps
    } = this.props

    switch (type) {
      case 'select':
        return (
          <InputWrapper noMarginTop={noMarginTop} title={title} half={half} oneThird={oneThird} twoThirds={twoThirds}>
            {label && <Label onContextMenu={onLabelRightClick}>{label}</Label>}
            <DropDown
              name={name || label || ''}
              value={value}
              selectors={selectors || []}
              onChange={onChange}
              {...restProps}
            />
          </InputWrapper>
        )
      case 'range':
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  half
                  name={`${name}Min`}
                  value={min}
                  title={titleMin}
                  placeholder='min'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>min</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  half
                  name={`${name}Max`}
                  value={max}
                  title={titleMax}
                  placeholder='max'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>max</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'axesPadding':
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  half
                  name='xAxisPadding'
                  value={xAxisPadding}
                  title='X Axis Padding'
                  placeholder='X Axis Padding'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>X Axis</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  half
                  name='yAxisPadding'
                  value={yAxisPadding}
                  title='Y Axis Padding'
                  placeholder='Y Axis Padding'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>Y Axis</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'selectRange':
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <DropDown
                  half
                  name={`${name}Min`}
                  title={titleMin}
                  value={min}
                  placeholder='min'
                  selectors={selectors || []}
                  onChange={onChange}
                  {...restProps}
                />
              </InWrapper>
              <InWrapper>
                <DropDown
                  half
                  name={`${name}Max`}
                  title={titleMax}
                  value={max}
                  placeholder='max'
                  onChange={onChange}
                  selectors={selectors || []}
                  {...restProps}
                />
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'defaultRange':
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  half
                  name={`${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                  title={titleMin}
                  value={min}
                  placeholder={placeholder}
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>{`${name.charAt(0).toUpperCase()}${name.slice(1)}`}</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  half
                  name={`${(secondName || '').charAt(0).toUpperCase()}${(secondName || '').slice(1)}`}
                  title={titleMax}
                  value={max}
                  placeholder={secondPlaceholder}
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>{`${(secondName || '').charAt(0).toUpperCase()}${(secondName || '').slice(1)}`}</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'checkbox':
        return (
          <CheckboxWrapper>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={onChange}
                  color='default'
                  checked={typeof value === 'string' ? value === 'true' : Boolean(value)}
                  name={name || label}
                />
              }
              label={title}
              {...restProps}
            />
          </CheckboxWrapper>
        )
      case 'number':
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}{this.getMinAndMax()}</Label>}
            <FormControl style={{ justifyContent: 'center', padding: '0 1px 0 0' }}>
              <StyledNumericInput
                value={value as any}
                min={min}
                max={max}
                step={step}
                name={name}
                style={numericInputStyle}
                precision={precision}
                disabled={this.props.disabled}
                onChange={(value: any) => onChange ? onChange({ target: { name: name, value: value } }) : undefined}
                {...restProps}
              />
            </FormControl>
          </InputWrapper>
        )
      default:
        return (
          <InputWrapper title={title || ''}>
            {label && <Label>{label}</Label>}
            <TextField
              variant={undefined} // TODO: had to send something, is this correct?
              type={type}
              name={name || label}
              value={value}
              placeholder={placeholder || label}
              onChange={onChange}
              multiline={!!rows}
              minRows={rows}
              maxRows={rows}
              inputProps={
                {
                  min: min,
                  max: max,
                  step: step,
                }
              }
              {...restProps}
            />
          </InputWrapper>
        )
    }
  }
}
