import { AnalyzeTime } from 'Util'
import type { Data, Options } from './type'
import Util from './Util'

export default class FastGage {
  static DEFAULT_OPTIONS: Options = {
    width: 250,
    height: 250,
    startAngle: -90,
    endAngle: 90,
    unit: '',
    label: '',

    currentValueFontFamily: 'Roboto, Arial',
    currentValueFontSize: 14,
    currentValueFontWeight: 600,
    currentValueColor: '#30a4f2',
    currentValueDecimals: 2,
    minValueFontFamily: 'Roboto, Arial',
    minValueFontSize: 5.5,
    minValueFontWeight: 500,
    minValueColor: '#c3c3c3',
    minValueDecimals: 0,
    maxValueFontFamily: 'Roboto, Arial',
    maxValueFontSize: 5.5,
    maxValueFontWeight: 500,
    maxValueColor: '#c3c3c3',
    maxValueDecimals: 0,
    unitTextFontFamily: 'Roboto, Arial',
    unitTextFontSize: 8,
    unitTextFontWeight: 500,
    unitTextColor: '#c3c3c3',
    labelTextFontFamily: 'Roboto, Arial',
    labelTextFontSize: 5.5,
    labelTextFontWeight: 500,
    labelTextColor: '#30a4f2',

    aimMinColor: '#00c434',
    aimMaxColor: '#ff0b30',
    foregroundColor: '#2392de',
    imageBackgroundColor: '#353c44',

    primaryThickness: 34,
    primaryBackgroundColor: '#2b4a61',

    secondaryThickness: 17,
    secondaryBackgroundColor: '#355e7d',
  };

  @AnalyzeTime(0)
  static _getPreparedOptions (rawOptions: Options) {
    // TODO: check this logic
    return Object.keys(FastGage.DEFAULT_OPTIONS).reduce<any>((object: any, key: string) => ({
      ...object,
      [key]: (rawOptions as any)[key] ||
      (rawOptions as any)[key] === 0
        ? (rawOptions as any)[key]
        : (FastGage.DEFAULT_OPTIONS as any)[key],
    }), {})
  }

  @AnalyzeTime(0)
  static _createPaths (g: Element, data: Data, options: any) {
    const {
      currentValue,
      min,
      max,
      aimMin,
      aimMax,
    } = data

    const {
      startAngle,
      endAngle,

      aimMinColor,
      aimMaxColor,
      foregroundColor,

      primaryThickness,
      primaryBackgroundColor,

      secondaryThickness,
      secondaryBackgroundColor,
    } = options

    const { radius, innerRadius, scale } = Util.calcValues(options, data)

    g.appendChild(Util.getCirclePath(
      'background',
      radius,
      startAngle,
      endAngle,
      primaryBackgroundColor,
      primaryThickness,
    ))

    g.appendChild(Util.getCirclePath(
      'current',
      radius,
      startAngle,
      Util.cutInRange(currentValue, min, max) * scale + startAngle,
      foregroundColor,
      primaryThickness,
    ))

    const hasAimMin = Util.hasValue(aimMin)
    const hasAimMax = Util.hasValue(aimMax)

    if (hasAimMin || hasAimMax) {
      g.appendChild(Util.getCirclePath(
        'backgroundArea',
        innerRadius,
        startAngle,
        endAngle,
        secondaryBackgroundColor,
        secondaryThickness,
      ))
    }

    if (hasAimMin) {
      g.appendChild(Util.getCirclePath(
        'minArea',
        innerRadius,
        startAngle,
        Util.cutInRange(aimMin || 0, min, max) * scale + startAngle,
        aimMinColor,
        secondaryThickness,
      ))
    }

    if (hasAimMax) {
      g.appendChild(Util.getCirclePath(
        'maxArea',
        innerRadius,
        endAngle,
        Util.cutInRange(aimMax || 0, min, max) * scale + startAngle,
        aimMaxColor,
        secondaryThickness,
        true,
      ))
    }
  }

  @AnalyzeTime(0)
  static _createTexts (g: Element, data: Data, options: any) {
    const {
      currentValue,
      min,
      max,
    } = data

    const {
      startAngle,
      endAngle,

      unit,
      label,

      currentValueFontFamily,
      currentValueFontSize,
      currentValueFontWeight,
      currentValueColor,
      currentValueDecimals,
      minValueFontFamily,
      minValueFontSize,
      minValueFontWeight,
      minValueColor,
      minValueDecimals,
      maxValueFontFamily,
      maxValueFontSize,
      maxValueFontWeight,
      maxValueColor,
      maxValueDecimals,
      unitTextFontFamily,
      unitTextFontSize,
      unitTextFontWeight,
      unitTextColor,
      labelTextFontFamily,
      labelTextFontSize,
      labelTextFontWeight,
      labelTextColor,

      secondaryThickness,
    } = options

    const { halfSize, radius } = Util.calcValues(options, data)
    const { x: startX, y: startY } = Util.getPointOnCircle(startAngle, radius)
    const { x: endX, y: endY } = Util.getPointOnCircle(endAngle, radius)

    g.appendChild(Util.getText(
      'current',
      Util.getValue(currentValue, currentValueDecimals),
      halfSize,
      halfSize - Util.SIZE * 0.15 * 0.35,
      Util.SIZE * currentValueFontSize / 100,
      currentValueFontFamily,
      currentValueFontWeight,
      currentValueColor,
    ))

    g.appendChild(Util.getText(
      'min',
      Util.getValue(min, minValueDecimals),
      startX + secondaryThickness / 2,
      startY + Util.SIZE * maxValueFontSize / 100 * 2,
      Util.SIZE * minValueFontSize / 100,
      minValueFontFamily,
      minValueFontWeight,
      minValueColor,
    ))

    g.appendChild(Util.getText(
      'max',
      Util.getValue(max, maxValueDecimals, 1),
      endX - secondaryThickness / 2,
      endY + Util.SIZE * maxValueFontSize / 100 * 2,
      Util.SIZE * maxValueFontSize / 100,
      maxValueFontFamily,
      maxValueFontWeight,
      maxValueColor,
    ))

    g.appendChild(Util.getText(
      'unit',
      unit,
      halfSize,
      halfSize,
      Util.SIZE * unitTextFontSize / 100,
      unitTextFontFamily,
      unitTextFontWeight,
      unitTextColor,
    ))

    g.appendChild(Util.getText(
      'label',
      label,
      halfSize,
      startY + Util.SIZE * labelTextFontSize / 100 * 2,
      Util.SIZE * labelTextFontSize / 100,
      labelTextFontFamily,
      labelTextFontWeight,
      labelTextColor,
    ))
  }

  constructor (options?: Options) {
    this.options = options || {}
  }

  data?: Data;
  options: Options;
  prevData?: Data;
  prevOptions?: Options;

  @AnalyzeTime(0)
  _createGage () {
    if (!this.data) {
      return
    }

    const { id } = this.data
    const gage = document.getElementById(id)

    if (!gage) {
      return
    }

    const options = FastGage._getPreparedOptions(this.options)
    const { width, height, minValueFontSize } = options

    const g = document.createElementNS(Util.NAMESPACE_URI, 'g')

    FastGage._createPaths(g, this.data, options)
    FastGage._createTexts(g, this.data, options)

    const svg = document.createElementNS(Util.NAMESPACE_URI, 'svg')

    svg.appendChild(g)

    const attribute = Number(svg.querySelector('text.min')?.getAttributeNS(null, 'y'))

    const h = attribute + minValueFontSize + Util.PADDING

    svg.setAttributeNS(null, 'viewBox', `0 0 ${Util.SIZE} ${h}`)
    svg.setAttributeNS(null, 'width', `${width}`)
    svg.setAttributeNS(null, 'height', `${height}`)
    svg.setAttributeNS(null, 'shape-rendering', 'geometricPrecision')
    svg.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet')

    svg.setAttribute('class', 'fast-gage')
    // svg.setAttribute('style', `background-color: ${imageBackgroundColor}`)
    svg.setAttribute('data-width', `${Util.SIZE}`)
    svg.setAttribute('data-height', h)

    // TODO: use imageBackgroundColor

    gage.appendChild(svg)
  }

  @AnalyzeTime(0)
  _updatePath (
    gage: HTMLElement,
    className: string,
    value: number,
    radius: number,
    options: any,
    reverse = false,
  ) {
    const current = gage.querySelector(`path.${className}`)

    if (!current || !this.data) {
      return
    }

    const { startAngle, endAngle } = options
    const { scale } = Util.calcValues(options, this.data)
    const scaledValue = value * scale + startAngle
    const start = reverse ? scaledValue : startAngle
    const end = reverse ? endAngle : scaledValue

    Util.setDashArray(current, radius, start, end)
  }

  @AnalyzeTime(0)
  _updateText (gage: HTMLElement, className: string, value: string | number) {
    const text = gage.querySelector(`text.${className}`)

    if (!text) {
      return
    }

    text.textContent = typeof value === 'string' ? value : (value || 0).toFixed(2)
  }

  @AnalyzeTime(0)
  _updateGage () {
    if (!this.data) {
      return
    }

    const { id, currentValue, min, max, aimMin, aimMax } = this.data
    const gage = document.getElementById(id)

    if (!gage) {
      return
    }

    const current = gage.querySelector('path.current')

    if (!current) {
      return this._createGage()
    }

    const options = FastGage._getPreparedOptions(this.options)
    const { width, height, unit, label, currentValueDecimals, minValueDecimals, maxValueDecimals } = options
    const { radius, innerRadius } = Util.calcValues(options, this.data)

    this._updatePath(gage, 'current', Util.cutInRange(currentValue, min, max), radius, options)

    if (Util.hasValue(aimMin)) {
      this._updatePath(gage, 'minArea', Util.cutInRange(aimMin || 0, min, max) + 0.0000001, innerRadius, options)
    }

    if (Util.hasValue(aimMax)) {
      this._updatePath(gage, 'maxArea', Util.cutInRange(aimMax || 0, min, max) - 0.0000001, innerRadius, options, true)
    }

    this._updateText(gage, 'current', Util.getValue(currentValue, currentValueDecimals))
    this._updateText(gage, 'min', Util.getValue(min, minValueDecimals))
    this._updateText(gage, 'max', Util.getValue(max, maxValueDecimals, 1))
    this._updateText(gage, 'unit', unit)
    this._updateText(gage, 'label', label)

    const svg = gage.querySelector('.fast-gage')

    if (!svg) {
      return
    }

    svg.setAttributeNS(null, 'width', `${width}`)
    svg.setAttributeNS(null, 'height', `${height}`)

    // svg.setAttribute('style', `background-color: ${imageBackgroundColor}`)
  }

  @AnalyzeTime(0)
  update (data: any, options?: Options) {
    if (!data.max && data.max !== 0) {
      data.max = 1
    }

    if (!data.aimMax && data.aimMax !== 0) {
      data.aimMax = data.max
    }

    if (this.data) {
      this.prevData = { ...this.data }
    }

    this.data = {
      ...this.data,
      ...data,
    }

    if (options) {
      this.prevOptions = { ...this.options }
      this.options = {
        ...this.options,
        ...options,
      }
    }

    this._updateGage()
  }
}
