import styled, { css, keyframes } from 'styled-components'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResizeAware from 'react-resize-aware' // TODO: types included in new version
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Draggable } from 'react-smooth-dnd'

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

function getIcon ({ icon }: any, color: string) {
  // eslint-disable-next-line no-undef
  return btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
      <path fill="${color}" d="${icon[4]}" class=""></path>
    </svg>
  `)
}

export const StatusBarWrapper = styled.div`${({ theme }) => css`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: ${theme.mainBackground};
  display: flex;
  flex: 6;
  padding: 16px 16px 0;
  user-select: none;
`}`

export const StatusTile = styled.div`${({ theme }) => css`
  position: relative;
  display: flex;
  flex: 1;

  i {
    font-size: 30px;
    line-height: 32px;
    color: ${theme.mainFontColor}
  }
`}`

export const Label = styled.div`${({ theme }) => css`
  position: absolute;
  display: inline-block;
  font-size: 14px;
  margin-left: 10px;
  width: 50%;
  color: ${theme.mainFontColor};
`}`

export const SecondLabel = styled.div<{color?: string}>`${({ theme, color }) => css`
  position: absolute;
  bottom: 0;
  display: inline-block;
  font-size: 14px;
  margin-left: 10px;
  color: ${color || theme.primary.font};
`}`

export const DashboardWrapper = styled.div`${({ theme }) => css`
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 100;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: ${theme.mainBackground};
  border: solid ${theme.colors.swatch1};
  border-width: 16px;
`}`

const fade = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const pulse = css`${({ theme }) => keyframes`
  0% {
    color: ${theme.mainFontColor};
  }
  40% {
    color: ${theme.colors.swatch15};
  }
  60% {
    color: ${theme.colors.swatch15};
  }
  100% {
    color: ${theme.mainFontColor};
  }
`}`

export const NetworkStatusDisplay = styled.div`${({ theme }) => css`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 100000;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  background-color: ${theme.colors.swatch3};
  border: 1px solid ${theme.colors.swatch1};
  opacity: 0;

  &.connected {
    color: ${theme.colors.swatch12};
    opacity: 1;
  }

  &.connecting {
    opacity: 1;
    animation: ${pulse} 3s infinite;
  }

  &.disconnected {
    color: ${theme.colors.swatch16};
    animation: ${fade} 2s linear;
  }
`}`

export const CompactViewIcons = styled.div<{isSplit?: boolean, _compact?: any}>`${({ theme, isSplit, _compact }) => css`
  width: 46px;
  height: ${isSplit ? '180px' : '134px'};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 43px;
  right: 0;
  background-color: ${theme.colors.swatch4};
  z-index: 100;
  border: 1px solid ${theme.colors.swatch1};
`}`

export const ViewIconsWrapper = styled.div<{
  isSplit?: boolean,
  compact?: boolean
}>`${({ theme, isSplit, compact }) => css`
  height: 45px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${theme.colors.swatch1};
  ${!compact && `width: ${isSplit ? '150px' : '100px'};`}
`}`

export const ViewIcon = styled.div<{compact?: boolean}>`${({ theme, compact }) => css`
  fill: ${theme.colors.swatch19};
  stroke: ${theme.colors.swatch19};
  color: ${theme.colors.swatch19};
  cursor: pointer;
  padding: 3px;
  line-height: ${!compact ? '65' : '70'}px;
  text-align: center;
  stroke-width: 0;
  flex: 1;

  i {
    font-size: 28px;
    margin: 5px;
    vertical-align: super;
  }

  &:hover {
    color: ${theme.colors.swatch8};
  }

  &:hover {
    fill: ${theme.colors.swatch8};
    stroke: ${theme.colors.swatch8};
  }
`}`

export const GridViewContainer = styled.div<any>`${({ theme, simple }) => css`
  position: absolute;
  height: calc(100% - ${simple ? '0px' : '46px'});
  z-index: 10;
  background-color: ${theme.mainBackground};
  overflow: visible;
  width: 100%;
`}`

export const ScrollHint = styled.div<{direction?: string}>`${({ theme, direction }) => css`
  color: ${theme.colors.swatch9};
  font-size: 12px;
  user-select: none;
  background-color: ${theme.colors.swatch24};
  padding: 9px;
  display: none;
  position: relative;
`}`

export const GridScrollbar = styled.div<any>`${({ theme }) => css`
  overflow-y: scroll;
  overflow-x: scroll;
  height: calc(100% - 64px);
  width: 100%;

  .scroll-btn {
    position: absolute;
  }

  &:hover {
    &[data-arrow-up="on"] .button-up {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 9999999;

      ${ScrollHint} {
        margin-top: 20px;
      }
    }

    &[data-arrow-up="on"] .button-up .scroll-hint-trigger {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border: solid 10px transparent;
      border-bottom-color: ${theme.colors.swatch24};

      &:hover ~${ScrollHint} {
        display: inline-block;
      }
    }

    &[data-arrow-down="on"] .button-down {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 9999999;

      ${ScrollHint} {
        margin-bottom: 20px;
      }
    }

    &[data-arrow-down="on"] .button-down .scroll-hint-trigger {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      border: solid 10px transparent;
      border-top-color: ${theme.colors.swatch24};

      &:hover ~${ScrollHint} {
        display: inline-block;
      }
    }

    &[data-arrow-left="on"] .button-left {
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9999999

      ${ScrollHint} {
        margin-left: 20px;
      }
    }

    &[data-arrow-left="on"] .button-left .scroll-hint-trigger {
      left: 0;
      top: 50%;
      border: solid 10px transparent;
      position: absolute;
      border-right-color: ${theme.colors.swatch24};
      transform: translate(0, -50%);

      &:hover ~${ScrollHint} {
        display: inline-block;
      }
    }

    &[data-arrow-right="on"] .button-right {
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9999999;

      ${ScrollHint} {
        margin-right: 20px
      }
    }

    &[data-arrow-right="on"] .button-right .scroll-hint-trigger {
      right: 0;
      top: 50%;
      border: solid 10px transparent;
      position: absolute;
      border-left-color: ${theme.colors.swatch24};
      transform: translate(0, -50%);

      &:hover ~${ScrollHint} {
        display: inline-block;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`}`

export const AddPlotGridItem = styled.div`${({ theme }) => css`
  height: 64px;
  width: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.swatch9};
  border: 1px dashed ${theme.colors.swatch9};
  cursor: pointer;
  border-radius: 7px;
  user-select: none;
  cursor: pointer;

  i {
    font-size: 28px;
    margin: 5px;
  }

  &:hover {
    color: ${theme.colors.swatch8};
  }
`}`

export const DashboardMenu = styled.div<{
  isEditModeOn?: boolean,
  showPlotInfo?: boolean,
  infoAction?: boolean
}>`${({ theme, isEditModeOn, showPlotInfo, infoAction }) => css`
  color: ${theme.colors.swatch8};
  background: ${theme.colors.swatch4};
  height: 32px;
  margin: 16px 0;
  user-select: none;
  line-height: 32px;
  ${!infoAction && 'padding-left: 8px'};
  border-radius: 5px;

  span {
    font-size: 18px;
    ${!showPlotInfo && `max-width: ${isEditModeOn ? 'calc(100% - 120px)' : '100%'};`}
    ${showPlotInfo && `max-width: ${isEditModeOn ? 'calc(100% - 205px)' : 'calc(100% - 110px)'};`}
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    vertical-align: top;
  }

  span:nth-child(2) {
    font-size: 14px;
    margin-left: 14px;
    color: ${theme.colors.swatch9};
    user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px;
    line-height: 35px;
    ${!showPlotInfo && 'display: none;'}
  }
`}`

export const ComparisonCasterMenu = styled.div`${({ theme }) => css`
  position: absolute;
  right: 0px;
  top: 0px;
  color: ${theme.colors.swatch8};
  height: 32px;
  margin-top: -34px;
  margin-bottom: 16px;
  user-select: none;
  line-height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background: ${theme.colors.swatch4};

  div {
    color: ${theme.colors.swatch8};
    font-size: 16px;
    font-weight: 300;
    max-height: 32px;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    vertical-align: top;
  }

  *:focus {
    background: none !important;
  }
`}`

export const DashboardMenuAction = styled.div<{
  doRotate?: boolean,
  smaller?: boolean
  disabled?: boolean
}>`${({ theme, doRotate, smaller, disabled }) => css`
  float: right;
  font-size: 20px;
  position: relative;
  width: 40px; /* smaller */
  height: 100%;
  padding-top: 2px;
  text-align: center;
  color: ${theme.colors.swatch8};
  vertical-align: middle;
  border-left: 1px solid ${theme.colors.swatch6};
  cursor: pointer;
  ${disabled && 'opacity: 0.3;'}

  i {
    ${doRotate ? 'transform: rotate(45deg);' : ''}
  }

  &:hover {
    ${!disabled && `color: ${theme.colors.swatch9};`}
  }
`}`

export const AddDashboard = styled.div<{ compact?: boolean }>`${({ theme, compact }) => css`
  display: inline-block;
  padding: 0 0 0 3px;
  font-size: ${compact ? '22' : '20'}px;
  color: ${theme.colors.swatch19};
  height: 48px;
  line-height: 44px;
  position: sticky;
  right: 0;
  z-index: 2;
  background-color: ${compact ? theme.colors.swatch4 : theme.colors.swatch1};
  transform: none !important;
  vertical-align: top;
  cursor: pointer;

  i {
    font-size: 33px;
  }

  span {
    font-size: 13px;
  }

  &:hover {
  color: ${theme.colors.swatch8};
  }
`}`

export const TabsWrapper = styled(ResizeAware)<any>`${({ theme: { colors }, split, edit, compact }) => css`
  display: flex;
  position: relative;
  height: 48px;
  overflow: hidden;
  line-height: 48px;
  width: ${compact ? 'calc(100% - 45px);' : `calc(100% - ${edit ? (split ? 150 : 100) : 0}px);`}
  z-index: 100;

  .smooth-dnd-container.horizontal {
    ::-webkit-scrollbar {
      display: none !important;
    }

    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    &[data-arrow-right="on"].drag_over:after,
    &[data-arrow-left="on"].drag_over:before {
      visibility: hidden;
    }

    &[data-arrow-right="on"]:after,
    &[data-arrow-left="on"]:before {
      content: '';
      position: sticky;
      top: 0;
      min-width: 20px;
      z-index: 10;
      height: 48px;
      display: inline-block;
    }

    &[data-arrow-left="on"]:before {
      background:
        url('data:image/svg+xml;base64,${getIcon(faChevronLeft, colors.swatch9)}') ${colors.swatch2} no-repeat;
      background-position: center center;
      background-size: 40% auto;
      left: 0;
      border-right: 1px solid ${colors.swatch1};
    }

    &[data-arrow-right="on"]:after {
      background:
        url('data:image/svg+xml;base64,${getIcon(faChevronRight, colors.swatch9)}') ${colors.swatch2} no-repeat;
      background-position: center center;
      background-size: 40% auto;
      right: ${edit && !compact ? 36 : 0}px;
      border-left: 1px solid ${colors.swatch1};
    }

    &.drag_over ${AddDashboard} {
      visibility: hidden;
    }
  }
`}`

export const Tab = styled(Draggable)<any>`${({
  active,
  theme: { colors: { swatch1, swatch2, swatch3, swatch14, swatch8, swatch9 } },
  compact,
}) => css`
  padding: 0 14px;
  font-size: 13px;
  color: ${active ? swatch8 : swatch9};
  background-color: ${active ? swatch3 : swatch2};
  margin-right: 1px;
  position: relative;
  user-select: none;
  min-width: ${compact ? '134px' : '156px'};
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  ${active ? css`
    border-bottom: 2px solid ${swatch14};
  ` : css`
    border-bottom: 1px solid ${swatch1};
  `};

  span {
    max-width: ${compact ? '90px' : '114px'};
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    pointer-events: none;
    white-space: nowrap;
  }

  i {
    opacity:0;
    font-size: 22px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 10px;
  }

  &:hover {
    color: ${swatch8};

    i {
      opacity: 1;
    }
  }
`}`

export const Wrapper = styled.div`${({ theme }) => css`
  padding: 0;
  background-color: ${theme.colors.swatch1};
`}`

export const NoData = styled.div`${({ theme }) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 600;
  color: ${theme.mainFontColor};
  user-select: none;
  cursor: pointer;
  text-align: center;
  
  :hover {
    color: ${theme.primary.main};
  }
`}`
