import Util from 'three/logic/Util'
import SectionView from '.'
import ConditionUtil from './ConditionUtil'
import Getters from './Getters'
import SectionLogic from '../../logic/SectionLogic'
import Mold from 'three/objects/Mold'
import TextureUtil from 'three/logic/TextureUtil'
import * as THREE from 'three'
import { Mesh, Texture } from 'three'
import { AnalyzeTime } from 'Util'
import CalculationUtil from './CalculationUtil'
import UIView from '../UIView'
import FeatureFlags from 'react/FeatureFlags'

export default class DrawHandlers {
  @AnalyzeTime(0)
  static drawLine (x1: number, x2: number, y: number, name: string, strand: Mesh) {
    Util.drawLine(strand, x1, y, 0.0001, x2, y, 0.0001, name, SectionView.labelLineMaterial)
  }

  @AnalyzeTime(0)
  static drawLabel (text: string, x: number, y: number, strand: Mesh) {
    const label = Util.getText(text, 0.0375, true, true, true)

    label.name = `Label_${text}`
    label.position.set(x, y, 0.0001)

    Util.addOrReplace(strand, label)
  }

  @AnalyzeTime(0)
  static drawLineAndLabel (width: number, y: number, strand: Mesh) {
    DrawHandlers.drawLine(-width + 0.005, -width + 0.03, y, `Line_${width}_${y}_L`, strand)
    DrawHandlers.drawLine(-0.03, -0.005, y, `Line_${width}_${y}_R`, strand)
    DrawHandlers.drawLabel(String(Math.round(y * 1000)), -width / 2, y, strand)
  }

  @AnalyzeTime(0)
  static drawRuler (width: number, thickness: number, increment: number, strand: Mesh) {
    for (let i = 0; i < width / 2; i += increment) {
      DrawHandlers.drawLineAndLabel(thickness, -i, strand)

      if (i > 0) {
        DrawHandlers.drawLineAndLabel(thickness, i, strand)
      }
    }
  }

  @AnalyzeTime(0)
  static drawPassLineCoordinates (view: SectionView) {
    // Draws the number which indicates section height
    if (
      (
        !SectionView.currentSegmentGroupNameChanged &&
      ConditionUtil.scrollValueNotUpdatedOr0(view.lastDrawnScrollValue || 0, view.views?.uiView?.scrollValue || 0)
      ) ||
    !view.views?.uiView?.scrollValue || !view.sectionPlaneWidth || !view.sectionPlaneHeight
    ) {
      return
    }

    if (SectionView.currentSegmentGroupNameChanged) {
      SectionView.currentSegmentGroupNameChanged = false
    }

    view.lastDrawnScrollValue = view.views.uiView.scrollValue || 0

    const x = view.sectionPlaneWidth / 2
    const currentSegmentGroup = CalculationUtil.getActualSegmentGroup(
      view,
      UIView.currentPsslnPosition,
    )

    SectionView.currentSegmentGroup = currentSegmentGroup

    const text = `${(view.plHeight * 1000 * view.views.uiView.scrollValue).toFixed(2)} | ${currentSegmentGroup.name}`
    const passLineCoordinates = Util.getText(text, 0.05, true, true, false)

    passLineCoordinates.name = 'passLineCoordinates'

    passLineCoordinates.position.set(-x, view.sectionPlaneHeight / 2 + 0.13, 0.0002)

    view.passLineCoordinates = passLineCoordinates

    Util.addOrReplace(view.sectionPlaneFolded, passLineCoordinates)

    DrawHandlers.drawNextElements(view)
  }

  @AnalyzeTime(0)
  static drawStrandAndRuler (
    view: SectionView,
    thickness: number,
    width: number,
    minWidth?: number,
    maxWidth?: number,
  ) {
    const featureFlags = view.featureFlags
    // Draws the expanded section and the ruler for the measurements
    const strand = Getters.getStrand(width, thickness, SectionView.strandMaterial)
    const minStrand = (minWidth && FeatureFlags.canViewMoldMinWidth(featureFlags))
      ? Getters.getMinStrandLines(minWidth, thickness)
      : undefined
    const maxStrand = (maxWidth && FeatureFlags.canViewMoldMaxWidth(featureFlags))
      ? Getters.getMaxStrand(maxWidth, thickness)
      : undefined

    strand.name = 'Strand'
    Util.addOrReplace(view.sectionPlaneFolded, strand)

    if (minStrand) {
      minStrand.name = 'minStrand'
      Util.addOrReplace(view.sectionPlaneFolded, minStrand)
      minStrand.visible = view.strand ? view.strand.visible : false
      view.minStrand = minStrand
    }
    else {
      const minStrandObject = view.sectionPlaneFolded.getObjectByName('minStrand')

      if (minStrandObject) {
        view.sectionPlaneFolded.remove(minStrandObject)
      }
    }

    if (maxStrand) {
      maxStrand.name = 'maxStrand'
      Util.addOrReplace(view.sectionPlaneFolded, maxStrand)
      maxStrand.visible = view.strand ? view.strand.visible : false
      view.maxStrand = maxStrand
    }
    else {
      const maxStrandObject = view.sectionPlaneFolded.getObjectByName('maxStrand')

      if (maxStrandObject) {
        view.sectionPlaneFolded.remove(maxStrandObject)
      }
    }

    strand.visible = view.strand ? view.strand.visible : true
    view.strand = strand
    DrawHandlers.drawRuler((maxStrand && maxWidth) || width, thickness, 0.2, view.strand)
  }

  @AnalyzeTime(0)
  static drawNextElements (view: SectionView) {
    // Draws rollers or nozzles in the section view
    if (!view.elementList || !view.elementList.Roller || !view.elementList.Nozzle) {
      return
    }

    if (view.additionalLayerType === 'Roller') {
      return SectionLogic.handleRoller(view.views, view.elementsHashes)
    }

    SectionLogic.handleNozzle(view.views)
  }

  @AnalyzeTime(0)
  static drawAllSectionViewSwitches (view: SectionView) {
    const size = 0.15
    const space = 0.05
    const x = -Mold.thickness / 2 + (view.sectionPlaneWidth || 0) / 2
    const y = (view.sectionPlaneHeight || 0) / 2

    DrawHandlers.drawSwitcherIcon(
      view,
      x,
      y + 0.14,
      'expandSectionViewButton',
      'expandSectionView',
      view.sectionViewExpanded,
      TextureUtil.load('textures/ui/collapse.png'),
      TextureUtil.load('textures/ui/expand.png'),
      'Expand',
      false,
    )

    DrawHandlers.drawSwitcherIcon(
      view,
      x - (size + space),
      y + 0.14,
      'ViewMode',
      'toggleViewMode',
      view.viewMode,
      TextureUtil.load('textures/ui/eyeOpen.png'),
      TextureUtil.load('textures/ui/eyeClose.png'),
      'Details',
      false,
    )

    DrawHandlers.drawSwitcherWithBackground(
      view,
      x,
      y - 0.09,
      'switcherPrevNextButton',
      'togglePrevNextElements',
      view.statusPrevNext,
      `Additional Layer: ${!view.statusPrevNext ? 'Next' : 'Prev'}`,
      TextureUtil.load('textures/ui/toggle-prev.png'),
      TextureUtil.load('textures/ui/toggle-next.png'),
      view.viewMode,
    )

    DrawHandlers.drawSwitcherWithBackground(
      view,
      x,
      y - 0.29,
      'RollerNozzleSwitcher',
      'toggleShowedElements',
      view.additionalLayerType === 'Roller',
      `Additional Layer Type: ${view.additionalLayerType}`,
      TextureUtil.load('textures/ui/toggle-roller.png'),
      TextureUtil.load('textures/ui/toggle-nozzle.png'),
      view.viewMode,
    )

    DrawHandlers.drawSwitcher(
      view,
      x - 0.1,
      0,
      'LooseSide',
      'toggleLooseSide',
      view.side.LooseSide,
      false,
      false,
      `Use passLineCoordinates, LooseSide`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -x - Mold.thickness + 0.1,
      0,
      'FixedSide',
      'toggleFixedSide',
      view.side.FixedSide,
      false,
      false,
      `Use passLineCoordinates, FixedSide`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -Mold.thickness / 2,
      -(y - 0.3),
      'NarrowFaceRight',
      'toggleNarrowFaceRight',
      view.side.NarrowFaceRight,
      false,
      false,
      `Use passLineCoordinates, NarrowFaceRight`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -Mold.thickness / 2,
      y - 0.3,
      'NarrowFaceLeft',
      'toggleNarrowFaceLeft',
      view.side.NarrowFaceLeft,
      false,
      false,
      `Use passLineCoordinates, NarrowFaceLeft`,
      true,
    )
  }

  @AnalyzeTime(0)
  static drawSwitcherWithBackground (
    view: SectionView,
    x: number | null,
    y: number | null,
    name: string,
    action: any,
    switcherValue = false,
    tooltip: string,
    textureA: Texture,
    textureB: Texture,
    visible = true,
    pivot = 'right',
  ) {
    if (view.clickableObjects.includes(view.buttons[name])) {
      return
    }

    const size = 0.4
    const space = 0.02
    const switcherGeometry = new THREE.PlaneBufferGeometry(size, size / 2, 1)
    const xCoordinate = x || ((view.buttons[name] || {}).position || {}).x || 0
    const yCoordinate = y || ((view.buttons[name] || {}).position || {}).y || 0

    const buttonGroup = new THREE.Group()

    buttonGroup.name = name
    buttonGroup.position.set(xCoordinate, yCoordinate, 0.0002)

    if (x !== null) {
      switch (pivot) {
        case 'right':
          buttonGroup.position.x = xCoordinate - size / 2
          break
        case 'left':
          buttonGroup.position.x = xCoordinate + size / 2
          break
        default:
      }
    }

    buttonGroup.visible = visible

    const backgroundSize = size + space
    const buttonBackgroundGeometry = new THREE.PlaneBufferGeometry(backgroundSize, 0.2, 0)

    const backgroundOffset = space / 2

    buttonBackgroundGeometry.translate(-backgroundOffset, 0, -0.00001)
    view.buttonBackground = new THREE.Mesh(buttonBackgroundGeometry, SectionView.planeHeaderMaterial)
    view.buttonBackground.name = `${name}Background`

    const buttonBackgroundLineGeometry = new THREE.PlaneBufferGeometry(backgroundSize + 0.01, 0.21, 0)

    buttonBackgroundLineGeometry.translate(-backgroundOffset - 0.01, -0.005, -0.00002)
    view.buttonBackgroundLine = new THREE.Mesh(buttonBackgroundLineGeometry, SectionView.headerPartingMaterial)
    view.buttonBackgroundLine.name = `${name}BackgroundLine`

    Util.addOrReplace(buttonGroup, view.buttonBackground)
    Util.addOrReplace(buttonGroup, view.buttonBackgroundLine)

    const texture = switcherValue ? textureA : textureB

    texture.repeat.set(1, 1)
    texture.offset.set(0, 0)

    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
    const switcher = new THREE.Mesh(switcherGeometry, material)

    switcher.name = `${name}Switcher`
    switcher.type = 'Button'
    switcher.userData.action = action
    switcher.position.x -= space
    switcher.visible = visible

    if (tooltip) {
      switcher.userData.tooltip = tooltip
    }

    if (view.buttons[name]) {
      const oldElement = view.buttons[name].children.filter((child: any) => child.name === `${name}Switcher`)[0]

      Util.addOrReplaceInList(oldElement, switcher, view.tooltipObjects)
      Util.addOrReplaceInList(oldElement, switcher, view.clickableObjects)
    }

    Util.addOrReplace(buttonGroup, switcher)

    Util.addOrReplace(view.sectionPlaneFolded, buttonGroup)
    view.buttons = {
      ...view.buttons,
      [name]: buttonGroup,
    }
  }

  @AnalyzeTime(0)
  static drawSwitcherIcon (
    view: SectionView,
    x: number | null,
    y: number | null,
    name: string,
    action: any,
    switcherValue = false,
    enableIcon: any,
    disableIcon: any,
    tooltip: string,
    iconNextToSwitcher = true,
    visible = true,
    pivot: 'left' | 'right' = 'right',
  ) {
    if (view.clickableObjects.includes(view.buttons[name])) {
      return
    }

    const size = 0.15
    const buttonGroup = Getters.getButtonGroup(x, y, name, size, visible, view.buttons, pivot)

    buttonGroup.visible = visible

    const switcher = Getters.getSwitcherWithIcon(
      x,
      y,
      size,
      name,
      action,
      switcherValue,
      tooltip,
      view.buttons,
      pivot,
      iconNextToSwitcher,
      enableIcon,
      disableIcon,
      visible,
      buttonGroup,
    )

    if (view.buttons[name]) {
      const oldElement = view.buttons[name].children.filter((child: any) => child.name === `${name}Switcher`)[0]

      Util.addOrReplaceInList(oldElement, switcher, view.tooltipObjects)
      Util.addOrReplaceInList(oldElement, switcher, view.clickableObjects)
    }

    Util.addOrReplace(buttonGroup, switcher)

    Util.addOrReplace(view.sectionPlaneFolded, buttonGroup)

    view.buttons = {
      ...view.buttons,
      [name]: buttonGroup,
    }
  }

  @AnalyzeTime(0)
  static drawSwitcher (
    view: SectionView,
    x: number | null,
    y: number,
    name: string,
    action: any,
    switcherValue = false,
    labelValueOne: any,
    labelValueTwo: any,
    tooltip: string,
    visible = false,
  ) {
    const width = 0.15
    const switcherDto = {
      x,
      y,
      width,
      height: width,
      name,
      action,
      switcherValue,
      tooltip,
      buttons: view.buttons,
      setPosition: true,
      visible,
    }

    const switcher = Getters.getSwitcher(switcherDto)

    Util.addOrReplaceInList(view.buttons[name], switcher, view.tooltipObjects)
    Util.addOrReplace(view.sectionPlaneFolded, switcher, view.clickableObjects)

    view.buttons = {
      ...view.buttons,
      [name]: switcher,
    }
  }
}
