import Util from '../../logic/Util'
import TextureUtil from '../../logic/TextureUtil'
import * as THREE from 'three'
import { AnalyzeTime } from 'Util'

export default class CubeHandlers {
  @AnalyzeTime(0)
  static initCube () {
    const textures = []
    const materials = []

    textures.push(TextureUtil.load('textures/view_cube/fixed.png'))
    textures.push(TextureUtil.load('textures/view_cube/loose.png'))
    textures.push(TextureUtil.load('textures/view_cube/top.png'))
    textures.push(TextureUtil.load('textures/view_cube/bottom.png'))
    textures.push(TextureUtil.load('textures/view_cube/left.png'))
    textures.push(TextureUtil.load('textures/view_cube/right.png'))

    textures.forEach(texture => {
      texture.anisotropy = 16
    })

    textures[2].center.set(0.5, 0.5)
    textures[3].center.set(0.5, 0.5)
    textures[2].rotation = Util.RAD90
    textures[3].rotation = -Util.RAD90

    // order to add materials: x+,x-,y+,y-,z+,z-
    materials.push(new THREE.MeshBasicMaterial({ map: textures[0] }))
    materials.push(new THREE.MeshBasicMaterial({ map: textures[1] }))
    materials.push(new THREE.MeshBasicMaterial({ map: textures[2] }))
    materials.push(new THREE.MeshBasicMaterial({ map: textures[3] }))
    materials.push(new THREE.MeshBasicMaterial({ map: textures[4] }))
    materials.push(new THREE.MeshBasicMaterial({ map: textures[5] }))

    const geometry = new THREE.BoxGeometry(1, 1, 1)

    return new THREE.Mesh(geometry, materials)
  }
}
