/* eslint-disable no-undef */
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { List, ListItem, ListItemSecondaryAction, Grid, Checkbox, Typography } from '@material-ui/core'
import { StyledListItem, Wrapper, InfoBox, Bottom } from './styles'

import BaseDialog from 'react/dialogs/BaseDialog'
import SubmitButton from 'react/specific/SubmitButton'
import * as ApplicationActions from 'store/application/main/actions'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectCreateCommandFileDialog'

const connector = connect((state: DefaultState) => ({
  nonexistentCommandFiles: state.application.main.nonexistentCommandFiles,
  nonexistentCommandFileMapping: state.application.main.nonexistentCommandFileMapping,
  latestUploadedVisualizationConfigId: state.application.main.latestUploadedVisualizationConfigId,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCommandFiles: string[],
  confirmDialogOpen: boolean
};

class CreateCommandFileDialog extends Component<Props, State> {
  state = {
    selectedCommandFiles: [] as any[],
    confirmDialogOpen: false,
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CreateCommandFileDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleChecked = (event: any) => {
    const { value } = event.target

    const { selectedCommandFiles } = this.state

    const newSelectedCommandFiles = [ ...selectedCommandFiles ]

    if (newSelectedCommandFiles.includes(value)) {
      newSelectedCommandFiles.splice(newSelectedCommandFiles.indexOf(value), 1)
    }
    else {
      newSelectedCommandFiles.push(value)
    }

    this.setState({
      selectedCommandFiles: newSelectedCommandFiles,
    })
  };

  // @AnalyzeTime(0)
  handleOpenConfirm = () => {
    const { nonexistentCommandFiles } = this.props
    const { selectedCommandFiles } = this.state

    if (selectedCommandFiles.length === Object.keys(nonexistentCommandFiles).length) {
      this.handleSubmit()
    }
    else {
      this.setState({
        confirmDialogOpen: true,
      })
    }
  };

  // @AnalyzeTime(0)
  handleCloseConfirm = () => {
    this.setState({
      confirmDialogOpen: false,
    })
  };

  // @AnalyzeTime(0)
  handleSubmit = async () => {
    const {
      nonexistentCommandFiles,
      nonexistentCommandFileMapping,
      currentSimulationCase,
      latestUploadedVisualizationConfigId,
    } = this.props
    const { selectedCommandFiles } = this.state
    const commandIdsMapping = {}

    let firstFileId = ''

    for (const fileId in nonexistentCommandFiles) {
      const { commands, fileName } = nonexistentCommandFiles[fileId]
      const createFile = selectedCommandFiles.includes(fileId)

      let commandFile = {}

      if (createFile) {
        commandFile = await ApiClient.post(`${Network.URI}/visualization_command/create`, {
          data: {
            name: fileName,
            commands: commands,
            simulationCaseId: currentSimulationCase._id,
          },
        })

        if (!Object.keys(commandIdsMapping).length) {
          commands.forEach((command, i) => {
            (commandIdsMapping as any)[command.prevCommandId] =
             (((((commandFile as any).commands || {}) as any)[i] || {}) as any)._id
          })
        }
      }

      firstFileId = firstFileId || (commandFile ? (commandFile as any)._id : fileId)

      await ApiClient.patch(
        `${Network.URI}/visualization_config/update_config_by_command_file/${latestUploadedVisualizationConfigId}`,
        {
          data: {
            type: createFile ? 'CREATE' : 'DELETE',
            commandFile,
            oldFileId: fileId,
          },
        },
      )
    }

    if (Object.keys(commandIdsMapping).length && nonexistentCommandFileMapping) {
      await ApiClient.post(`${Network.URI}/visualization_command/file/${firstFileId}`, {
        data: {
          assignedCommands: nonexistentCommandFileMapping.map(id => (commandIdsMapping as any)[id] || id),
          simulationCaseId: currentSimulationCase._id,
        },
      })
    }

    this.handleClose()
  };

  static NAME = uuid()

  @AnalyzeTime(0)
  render () {
    const { t, nonexistentCommandFiles } = this.props
    const { selectedCommandFiles, confirmDialogOpen } = this.state

    return (
      <BaseDialog
        small
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-albums'
        header={t(`${PRE_TRANS}.header`)}
        hideCloseButton
      >
        <Wrapper>
          <Grid item xs={12} spacing={2}>
            <List>
              {
                Object.keys(nonexistentCommandFiles).map(fileId => (
                  <ListItem key={fileId}>
                    <StyledListItem>
                      {nonexistentCommandFiles[fileId].fileName}
                    </StyledListItem>
                    <ListItemSecondaryAction>
                      {/* TODO: value here and in handleChecked makes no sense, we need to check this! */}
                      <Checkbox
                        edge='end'
                        color='primary'
                        value={fileId}
                        checked={selectedCommandFiles.includes(fileId)}
                        onChange={this.handleChecked}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
            <Bottom>
              {
                confirmDialogOpen
                  ? <div>
                    <InfoBox>
                      <Typography variant='caption'>{t(`${PRE_TRANS}.info`)}</Typography>
                    </InfoBox>
                    <div>
                      <SubmitButton half type='error' onClick={this.handleCloseConfirm}>
                        {t(`${PRE_TRANS}.abort`)}
                      </SubmitButton>
                      <SubmitButton half onClick={this.handleSubmit}>
                        {t(`${PRE_TRANS}.apply`)}
                      </SubmitButton>
                    </div>
                    {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                  </div>
                  : <SubmitButton onClick={this.handleOpenConfirm}>{t(`${PRE_TRANS}.apply`)}</SubmitButton>
              }
            </Bottom>
          </Grid>
        </Wrapper>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(CreateCommandFileDialog as any) as any) as any
