import { Texture } from 'three'
import { AnalyzeTime } from 'Util'

export default class Util {
  @AnalyzeTime(0)
  static repeatTexture (texture: Texture, textureRepeat: {u:number, v: number}) {
    texture.repeat.set(textureRepeat.u, textureRepeat.v)
  }

  @AnalyzeTime(0)
  static offsetTexture (texture: Texture, textureOffset: {u:number, v: number}) {
    texture.offset.set(textureOffset.u, textureOffset.v)
  }

  @AnalyzeTime(0)
  static getJumpDirection (jumpUp: boolean | undefined, jumpDown: boolean | undefined) {
    return (jumpUp ? -1 : (jumpDown ? 1 : 0))
  }
}
