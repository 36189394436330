import { AnalyzeTime } from 'Util'
import ThreeBase from './ThreeBase'

export default class ThreeManager {
  private static _base?: ThreeBase | null
  private static _ref = null

  @AnalyzeTime(0)
  static init (ref: any) {
    ThreeManager._ref = ref
  }

  //  @AnalyzeTime(0)
  static get base () {
    if (!ThreeManager._base) {
      ThreeManager._base = new ThreeBase()
    }

    return ThreeManager._base
  }

  //  @AnalyzeTime(0)
  static killBase () {
    ThreeManager._base?.kill()

    delete ThreeManager._base

    ThreeManager._base = null
  }

  //  @AnalyzeTime(0)
  static reInit () {
    if (ThreeManager._ref && !ThreeManager.base.isInitialized) {
      ThreeManager._base?.init(ThreeManager._ref)
    }
  }

  static cleanViews (views: string[]) {
    ThreeManager._base?.cleanViews(views)
  }

  static toggleRollerView () {
    ThreeManager._base?.toggleRollerView()
  }
}
