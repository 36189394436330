import styled, { css } from 'styled-components'

export const Wrapper = styled.div`${({ theme }) => css`
  display: inline-block;
  position: absolute;
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
  background: ${theme.colors.swatch5 || '#2A3038'};
  z-index: 150;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 0 0 150px rgba(0, 0, 0, 0.5);
`}`

export const TopBar = styled.div`${({ theme }) => css`
  clear: both;
  height: 72px;
  padding: 5px 5px 0 5px;
  user-select: none;
`}`

export const Title = styled.div`${({ theme }) => css`
  display: inline-block;
  float: left;
  font-size: 25px;
  color: ${theme.mainFontColor};
`}`

export const SelectionInfo = styled.div`${({ theme }) => css`
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: ${theme.mainFontColor};
  vertical-align: bottom;
  margin-right: 10px;
  line-height: 31px;

  .loading {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
`}`

export const CellInput = styled.input`${({ theme }) => css`
  background: none;
  color: ${theme.mainFontColor};
  border: none;
  text-align: right;
  padding: 0 5px;
  outline: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
`}`

export const ButtonBar = styled.div`${({ theme }) => css`
  position: absolute;
  display: inline-block;
  top: 55px;
  right: 20px;

  button > span {
    text-transform: capitalize;
    color: #e5e9f2;
  }
`}`

const columnWidths = [ 43, 34, 76, 41, 57, 130 ]

const getBackgroundSVGColumnRects = (theme: any) => {
  let leftOffset = -1
  let lines = ''

  columnWidths.forEach(width => {
    leftOffset += width
    lines += `<rect x="${leftOffset}" y="0" width="1" height="100%" fill="${theme.colors.swatch4}" />`
  })

  const rect = `<rect x="0" y="0" width="${leftOffset}" height="100%" fill="${theme.colors.swatch5}" />`

  return rect + lines
}

const getBackgroundSVG = (theme: any) => {
  // eslint-disable-next-line no-undef
  return btoa(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="100%"
      height="100%"
    >
      <rect x="0" y="0" width="100%" height="26" fill="${theme.colors.swatch5}" />
      <rect x="0" y="26" width="100%" height="1" fill="${theme.colors.swatch4}" />
      ${getBackgroundSVGColumnRects(theme)}
    </svg>
  `)
}

export const Content = styled.div`${({ theme }) => css`
  display: inline-block;
  width: calc(100% - 10px);
  height: calc(100% - 75px - 10px);
  margin: 5px;
  border: 1px solid ${theme.colors.swatch4};
  user-select: none;
  background-color: ${theme.colors.swatch1};
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  background-image: url('data:image/svg+xml;base64,${getBackgroundSVG(theme)}');

  .data-grid-container {
    outline: none;
  }

  .matrix-sheet {
    border-collapse: collapse;
    color: ${theme.colors.swatch9};
    font-size: 14px;
    margin: -1px -1px 29px -1px;
  }

  .data-header th {
    background-color: ${theme.colors.swatch5};
    padding: 5px 10px;
    z-index: 10;
    height: 28px;
    overflow: hidden;
    resize: horizontal;

    :after {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      border: 1px solid ${theme.colors.swatch4};
      pointer-events: none;
    }

    > div {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.status-cell,
    &.author-cell,
    &.simulation-case-cell,
    &.action-cell,
    &.row-number-cell,
    &.selection-cell {
      z-index: 15;
      resize: none;
    }

    &.full-sticky {
      overflow: visible;
    }
  }

  .data-row {
    background-color: ${theme.colors.swatch1};

    :hover {
      background-color: ${theme.colors.swatch2};
    }
  }

  .cell {
    white-space: nowrap;
    border: 1px solid ${theme.colors.swatch4};
    /* TODO: check if necessary. maybe when editing/selected ... */
    /* text-overflow: ellipsis;
    overflow: hidden; */

    :hover {
      background-color: ${theme.colors.swatch4}
    }

    &.editing {
      position: relative;
    }

    &.editing > span,
    .value-viewer {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 5px 10px 3px 10px;
      height: 24px;

      > span {
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        width: calc(100% - 20px);
        line-height: 19px;
        left: 10px;
      }
    }

    &.editing > span:before,
    &.selected .value-viewer:before {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -4px;
      left: -1px;
      border: 1px solid ${theme.colors.swatch7};
      pointer-events: none;
      z-index: 1;
    }

    &.editing.read-only span:before,
    &.selected.read-only .value-viewer:before {
      border: 1px solid ${theme.colors.swatch23};
      pointer-events: none;
    }

    &.simulation-case-cell.pasted,
    &.cell-type-command.pasted {
      background: #67E629;
      color: black;
    }

    &.simulation-case-cell.pasted-same,
    &.cell-type-command.pasted-same {}

    &.simulation-case-cell.not-pasted,
    &.cell-type-command.not-pasted {
      background: #e62929;
      color: black;
    }

    &.cell-type-result {
      color: ${theme.colors.swatch14};
    }
  }

  .status-cell,
  .author-cell,
  .simulation-case-cell,
  .action-cell,
  .selection-cell,
  .row-number-cell {
    background-color: ${theme.colors.swatch5};
    z-index: 12;
    position: sticky;

    :after {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      border: 1px solid ${theme.colors.swatch4};
      pointer-events: none;
    }
  }

  .status-cell.manually {
    color: orange;
  }

  .row-number-cell {
    text-align: right;
    padding: 5px 5px;
    min-width: 43px;
    max-width: 43px;
    left: -1px;
  }

  .selection-cell {
    padding: 5px 10px;
    width: 34px;
    left: 42px;
  }

  .selection-cell input {
    top: 2px;
    left: 1px;
    position: relative;
    cursor: pointer;
  }

  .action-cell {
    padding: 3px 5px;
    width: 76px;
    left: 76px;
  }

  .status-cell {
    width: 41px;
    left: 152px;
  }

  .author-cell {
    min-width: 57px;
    max-width: 57px;
    left: 193px;
    text-align: center;

    .value-viewer {
      padding: 5px;
    }
  }

  .simulation-case-cell {
    min-width: 130px;
    max-width: 130px;
    left: 250px;

    &.editing {
      position: sticky;
    }
  }
`}`

export const I = styled.div`${({ theme }) => css`
  position: absolute;
  font-size: 40px;
  color: ${theme.colors.swatch9};
  right: 10px;
  top: 10px;
  cursor: pointer;
`}`
