import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { withNamespaces } from 'react-i18next'
import { AnalyzeTime } from 'Util'

export const SubmitButton = styled.button<any>`${({ small }) => css`
  background:  #474b4e;
  color:       #CCCCCC;
  font-size:   1em;
  margin:      10px 5px;
  padding:     0.25em 1em;
  border:      2px solid #474b4e;
  width:       ${small ? '40%' : '90%'};
  outline:     none;
  cursor:      pointer;
  user-select: none;
  transition:  .2s;
  
  &:hover {    
    transition: .2s;
  }
`}`

type Props = {
  onClick: (event: React.MouseEvent) => void,
  value: string,
  primary?: string,
  className?: string,
  title?: string,
  small?: boolean,
  disabled?: boolean
};

class SecondaryButton extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { onClick: handleClick, value, primary, small, className, disabled, title } = this.props || {}

    return (
      <SubmitButton
        className={className || ''}
        onClick={handleClick}
        primary={primary}
        small={small}
        value={value}
        title={title || value}
        disabled={disabled}
      >
        {value}
      </SubmitButton>
    )
  }
}

export default withNamespaces('caster')(SecondaryButton as any) as any
