/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { AppState } from 'store/application/main/consts'
import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import CasterActions from 'store/data/actions'
import * as LoadingActions from 'store/LoadingStore'
import * as VisualizationActions from 'store/visualization/actions'
import * as ComparisonCastersActions from 'store/comparison-casters'

import FeatureFlags from 'react/FeatureFlags/index'
// TODO: move this file or its contents
import { Form } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import Button from 'react/components/Button/index'
import InfoMarker from 'react/components/InfoMarker/index'
import Input from 'react/specific/Input'

import { Spacer } from '../OpenProjectDialog/Styles'
import { ConfirmWrapper } from 'react/components/Button/styles'
import { Title, Hr } from './Styles'

import Logic from './Logic'
import CasterHistoryDialog from '../../caster/CasterHistoryDialog'
import InputDataDialog from '../InputDataDialog'
import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'
import { CasterColumnLogic } from './CasterColumnLogic'
import { OptionsObject, withSnackbar } from 'notistack'

const PRE_TRANS = 'projectDataDialog.caster'

const connector = connect((state: DefaultState) => ({
  openAppDialogs: state.application.main.openAppDialogs,
  currentCasterRoot: state.application.main.currentCasterRoot,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  uploadLoading: state.loading.uploadLoading,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  currentProject: state.application.main.currentProject,
  authenticationData: state.application.main.authenticationData,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setAppState: ApplicationActions.setAppState,
  saveCatalog: CasterActions.saveCatalog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  setError: ErrorActions.setError,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  resetReducer: CasterActions.resetReducer,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  addComparisonCaster: ComparisonCastersActions.addComparisonCaster,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  onDeleteCatalog?: (type: any, key: any)=> null | undefined,
  onClose: (isClick?:any)=>void,
  onChange?: (event: any) => any,
  onDeleteConfig?: (type: any, key: any)=> null | undefined,
  t: Translation & ArrayOfTranslations
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  setVisualizationMetaInformation: (name: string, value: string, appState: number) => void;
}

type State = {
  loading: any,
  selectedConfig: string | undefined | null
};

export class CasterColumn extends Component<Props, State> {
  state = {
    loading: {
      openCaster: false,
    },
    selectedConfig: null,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { visualizationMetaInformation } = this.props

    if (!visualizationMetaInformation) {
      return
    }

    const meta = visualizationMetaInformation[AppState.Caster] || {}

    this.setState({
      selectedConfig: meta.config,
    })
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props) {
    const { visualizationConfigList: oldVisualizationConfigList } = prevProps.currentSimulationCase

    if (!oldVisualizationConfigList) {
      return
    }

    const { visualizationConfigList } = this.props.currentSimulationCase

    const oldVisualizationParamConfigList = oldVisualizationConfigList
      .filter(config => config.appState === AppState.Caster)

    const visualizationParamConfigList = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.Caster)

    if (oldVisualizationParamConfigList.length !== visualizationParamConfigList.length) {
      this.setState({
        selectedConfig: (visualizationParamConfigList.slice(-1)[0] || {}).configId,
      })
    }
  }

  // @AnalyzeTime(0)
  handleLoadCasterData = () => {
    CasterColumnLogic.handleUploadCasterData(undefined, this.props)
  }

  // @AnalyzeTime(0)
  handleOpenCaster = async () => {
    this.setState({ loading: { openCaster: true } })

    await CasterColumnLogic.openCaster(this.props)

    this.setState({ loading: { openCaster: false } })
  };

  // @AnalyzeTime(0)
  handleOpenHistory = () => {
    const { openDialog } = this.props

    openDialog(CasterHistoryDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleOpenInputDataDialog = () => {
    const { openDialog } = this.props

    openDialog(InputDataDialog.NAME)
  };

  // @AnalyzeTime(0)
  getSelectedConfig = () => {
    const { selectedConfig } = this.state
    const { currentSimulationCase: { visualizationConfigList } } = this.props
    const configs = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.Caster)

    return Logic.getSelectedConfig(selectedConfig, configs)
  };

  @AnalyzeTime(0)
  render () {
    const { loading } = this.state
    const {
      t,
      currentSimulationCase,
      featureFlags,
      currentCatalogId,
      uploadLoading,
      error,
      onDeleteCatalog,
      onChange,
      openAppDialogs,
    } = this.props

    const {
      casterFileName,
      casterCatalogList,
      simulationStartedAt,
      isCasterGenerated,
    } = currentSimulationCase

    const catalogSelectors = Logic.getSelectors(
      casterCatalogList,
      'catalogId',
      'name',
      { value: t(`${PRE_TRANS}.catalog.default`), disabled: true },
    )
    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, casterCatalogList)

    const inputDataIsOpen = openAppDialogs && openAppDialogs.includes(InputDataDialog.NAME)

    return (
      <Form maxWidth='300px' padding='18px 28px 20px'>
        <Title>
          {t(`${PRE_TRANS}.title`)}
          <InfoMarker
            message={t(`${PRE_TRANS}.info`, { returnObjects: true }).join('\n')}
            x={224}
            y={-23}
          />
        </Title>
        <ConfirmWrapper>
          <Button
            label={t(`${PRE_TRANS}.file`)}
            onClick={this.handleOpenInputDataDialog}
            title={t(`${PRE_TRANS}.inputData.title`)}
            disabled={simulationStartedAt || inputDataIsOpen || !FeatureFlags.canGenerateCasterFile(featureFlags)}
            icon={inputDataIsOpen ? 'pe-7s-left-arrow' : (casterFileName && isCasterGenerated ? 'pe-7s-check' : '')}
            half
          >
            {t(`${PRE_TRANS}.inputData.label`)}
          </Button>
          <Button
            onClick={this.handleLoadCasterData}
            loading={uploadLoading.CasterDataIsLoading}
            error={error.CasterData && t([ `error.${error.CasterData}`, 'error.default' ])}
            title={
              casterFileName
                ? t(`${PRE_TRANS}.uploaded`, { name: casterFileName })
                : t(`${PRE_TRANS}.data.title`)
            }
            icon={casterFileName && !isCasterGenerated ? 'pe-7s-check' : 'pe-7s-up-arrow'}
            disabled={simulationStartedAt}
            half
          >
            {t(`${PRE_TRANS}.data.label`)}
          </Button>
        </ConfirmWrapper>
        <Button
          onClick={this.handleOpenHistory}
          disabled={
            !currentSimulationCase.currentCasterRoot ||
            uploadLoading.CasterDataIsLoading ||
            !FeatureFlags.canSeeHistory(featureFlags)
          }
          title={t(`${PRE_TRANS}.history.title`)}
          icon='pe-7s-clock'
        >
          {t(`${PRE_TRANS}.history.label`)}
        </Button>
        <Input
          name='catalog'
          type='select'
          label={t(`${PRE_TRANS}.catalog.label`)}
          title={
            error.catalog
              ? t([ `error.${error.catalog}`, 'error.default' ])
              : t(`${PRE_TRANS}.catalog.title`)
          }
          error={error.catalog && t([ `error.${error.catalog}`, 'error.default' ])}
          value={selectedCatalog}
          selectors={
            FeatureFlags.canUploadNozzleCatalog(featureFlags)
              ? [
                ...catalogSelectors,
                {
                  key: 'add',
                  notRemovable: true,
                  value: t(`${PRE_TRANS}.catalog.add`),
                  disabled: simulationStartedAt,
                },
              ]
              : [ ...catalogSelectors ]
          }
          onChange={onChange}
          onDelete={FeatureFlags.canDeleteNozzleCatalog(featureFlags) && onDeleteCatalog}
          disabled={Boolean(simulationStartedAt)}
        />
        <Spacer h={17} br />
        <Hr />
        <Spacer h={55} br />
        <Button
          onClick={this.handleOpenCaster}
          disabled={!currentSimulationCase.currentCasterRoot || uploadLoading.CasterDataIsLoading}
          loading={loading.openCaster}
          title={
            error.openCaster
              ? t([ `error.${error.openCaster}`, 'error.default' ])
              : t(`${PRE_TRANS}.${simulationStartedAt ? 'view' : 'open'}.title`)
          }
          error={error.openCaster && t([ `error.${error.openCaster}`, 'error.default' ])}
          hideError
        >
          {t(`${PRE_TRANS}.${simulationStartedAt ? 'view' : 'open'}.label`)}
        </Button>
      </Form>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(CasterColumn) as any) as any) as any
