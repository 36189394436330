/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'

import { AppState } from 'store/application/main/consts'
import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import * as VisualizationActions from 'store/visualization/actions'
import DataActions from 'store/data/actions'
import * as LoadingActions from 'store/LoadingStore'

import TimeUtil from 'logic/TimeUtil'
import IpcManager from 'IpcManager'
import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import Input from 'react/specific/Input'
import Button from '../../../components/Button'
import InfoMarker from '../../../components/InfoMarker'

import { NewSimulationCaseDialog } from '../NewSimulationCaseDialog'
import { EditSimulationCaseDialog } from '../EditSimulationCaseDialog'

import { FullHR, Column, Label } from './Styles'
import Logic from './Logic'
import { Description } from '../OpenProjectDialog/Styles'
import { ConfirmWrapper } from 'react/components/Button/styles'
import cloneDeep from 'clone-deep'

import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectDataDialog.simulationCase'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  error: state.application.error,
}), {
  resetReducer: DataActions.resetReducer,
  openDialog: ApplicationActions.openDialog,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditSimulationCaseId: ApplicationActions.setEditSimulationCaseId,
  setAppState: ApplicationActions.setAppState,
  saveCatalog: DataActions.saveCatalog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  setError: ErrorActions.setError,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar
  t: Translation & ArrayOfTranslations
}

type State = {
  loading: any,
  selectedSimulationCase: string | undefined | null,
  isConfirmingStop: boolean
};

export class SimulationCaseComponent extends Component<Props, State> {
  state = {
    loading: {
      startSimulation: false,
      stopSimulation: false,
      resetSimulation: false,
    },
    selectedSimulationCase: null,
    isConfirmingStop: false,
  }

  @AnalyzeTime(0)
  static getDerivedStateFromProps (props: Props, state: State) {
    if (props.currentSimulationCase._id !== state.selectedSimulationCase) {
      return {
        selectedSimulationCase: props.currentSimulationCase._id,
      }
    }

    return null
  }

  // @AnalyzeTime(0)
  handleChangeSimulationCase = (event: any) => {
    const {
      currentProject,
      setCurrentSimulationCase,
      resetReducer,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
      setCurrentCasterRoot,
    } = this.props

    if (event.target.value === 'add') {
      return this.handleNewSimulationCase()
    }

    const selectedSimulationCase = event.target.value || currentProject.simulationCases[0]._id

    this.setState({
      selectedSimulationCase,
    })

    ApiClient
      .get(`${Network.URI}/simulation_case/${selectedSimulationCase}`)
      .then(({ simulationCase }) => {
        resetReducer()
        setCurrentCasterRoot()

        setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
        setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.Caster)
        setCurrentCatalogId()

        setCurrentSimulationCase(simulationCase)
      })
      .catch((response) => {
        // TODO: handle error
        // console.log(response)
      })
  };

  // @AnalyzeTime(0)
  handleNewSimulationCase = () => {
    const { openDialog } = this.props

    openDialog(NewSimulationCaseDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleDelete = (type: string, key: string) => {
    const {
      currentProject,
      currentSimulationCase,
      resetReducer,
      setCurrentProject,
      setCurrentSimulationCase,
    } = this.props

    ApiClient
      .del(`${Network.URI}/simulation_case/${key}`)
      .then(() => {
        currentProject.simulationCases = currentProject.simulationCases
          .filter(simulationCase => simulationCase._id !== key)

        resetReducer()
        setCurrentProject(currentProject)
        setCurrentSimulationCase(currentSimulationCase)
      })
      .catch((response) => {
        // TODO: handle error
        // console.log(response)
      })
  };

  // @AnalyzeTime(0)
  handleEditOpen = (key: string) => {
    const { openDialog, setEditSimulationCaseId } = this.props

    setEditSimulationCaseId(key)
    openDialog(EditSimulationCaseDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleDownload = async (key: string) => {
    const { currentSimulationCase: { _id } } = this.props
    const { data, fileName } = await ApiClient.get(`${Network.URI}/simulation_case/zip/${_id}`)

    // TODO: Fix, if download is cancelled, snackbar still shows up
    IpcManager.both.send('openSimulationDataDialog', data, fileName)
  };

  // @AnalyzeTime(0)
  handleStartSimulation = () => {
    const {
      currentSimulationCase,
      currentCatalogId,
      setCurrentSimulationCase,
      setError,
      enqueueSnackbar,
      t,
    } = this.props

    this.setState({
      loading: { startSimulation: true },
    })

    const selectedCatalog = currentCatalogId ||
    ((currentSimulationCase.casterCatalogList || [])[0] || {}).catalogId || 'default'

    ApiClient
      .post(
        `${Network.URI}/simulation_case/simulate/${currentSimulationCase._id}`,
        { data: { catalog: selectedCatalog } },
      )
      .then(({ simulationCase }) => {
        setCurrentSimulationCase(simulationCase)

        this.setState({
          loading: { startSimulation: false },
        })

        enqueueSnackbar(t(`${PRE_TRANS}.simulation.started`), { autoHideDuration: 3000, variant: 'success' })
      })
      .catch(({ status }) => {
        setError('startSimulation', status)

        this.setState({
          loading: { startSimulation: false },
        })

        enqueueSnackbar(t(`${PRE_TRANS}.simulation.error`), { autoHideDuration: 4000, variant: 'error' })
      })
  };

  // @AnalyzeTime(0)
  handleStopSimulation = () => {
    const { currentSimulationCase, setCurrentProject, setCurrentSimulationCase, setError } = this.props
    const { _id } = currentSimulationCase

    this.setState({
      loading: { stopSimulation: true },
      isConfirmingStop: false,
    })

    ApiClient.post(`${Network.URI}/simulation_case/stop_multiple`, { data: { simulationCaseIds: [ _id ] } })
      .then(({ project }: {project: Project}) => {
        if (!project || !Object.keys(project).length) {
          throw new Error('No project given')
        }

        setCurrentProject(project)

        const simulationCase = cloneDeep(currentSimulationCase)

        const newSimulationCase = project.simulationCases.find(simulationCase => simulationCase._id === _id)

        if (newSimulationCase) {
          simulationCase.simulationStartedAt = newSimulationCase.simulationStartedAt
          simulationCase.simulationDataReceivedAt = newSimulationCase.simulationDataReceivedAt
          simulationCase.wasStoppedManually = newSimulationCase.wasStoppedManually

          setCurrentSimulationCase(simulationCase)

          // TODO: maybe update currentSimulationCase

          this.setState({
            loading: { stopSimulation: false },
          })
        }
      })
      .catch(({ status }) => {
        setError('stopSimulation', status)

        this.setState({
          loading: { stopSimulation: false },
        })
      })
  };

  // @AnalyzeTime(0)
  handleBeginConfirmStop = () => {
    this.setState({ isConfirmingStop: true })
  };

  // @AnalyzeTime(0)
  handleCancelStop = () => {
    this.setState({ isConfirmingStop: false })
  };

  // @AnalyzeTime(0)
  handleResetSimulation = () => {
    const {
      currentSimulationCase,
      resetReducer,
      setCurrentSimulationCase,
      setCurrentCasterRoot,
      setVisualizationMetaInformation,
      setAppState,
      setError,
      enqueueSnackbar,
      t,
    } = this.props

    this.setState({
      loading: { resetSimulation: true },
      isConfirmingStop: false,
    })

    ApiClient
      .patch(`${Network.URI}/simulation_case/reset/${currentSimulationCase._id}`)
      .then(({ simulationCase }) => {
        resetReducer()
        setCurrentSimulationCase(simulationCase)
        setCurrentCasterRoot()
        setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)

        setAppState(AppState.Caster)

        this.setState({
          loading: { resetSimulation: false },
        })

        enqueueSnackbar(t(`${PRE_TRANS}.simulation.reset`), { autoHideDuration: 3000, variant: 'success' })
      })
      .catch(({ status }) => {
        setError('resetSimulation', status)

        this.setState({
          loading: { resetSimulation: false },
        })

        enqueueSnackbar(t(`${PRE_TRANS}.simulation.reset_error`), { autoHideDuration: 4000, variant: 'error' })
      })
  };

  @AnalyzeTime(0)
  render () {
    const { loading, selectedSimulationCase, isConfirmingStop } = this.state
    const { currentProject, currentSimulationCase, currentCatalogId, error, t } = this.props

    const simulationCaseSelectors = Logic.getSelectors(currentProject.simulationCases, '_id', 'name', null, true)

    simulationCaseSelectors.push({
      key: 'add',
      value: t(`${PRE_TRANS}.new.label`),
      notRemovable: true,
    })

    const selectedSimulationCaseId = selectedSimulationCase || (simulationCaseSelectors[0] || {}).key || 'default'

    const {
      processingParametersVerifications,
      casterVerifications,
      catalogVerifications,
      simulationStartedAt,
      simulationDataReceivedAt,
      casterCatalogList,
    } = currentSimulationCase

    const lastCasterVerification = (casterVerifications || []).slice(-1)[0]
    const lastProcessParametersVerification = (processingParametersVerifications || []).slice(-1)[0]

    const selectedCatalog = currentCatalogId || ((casterCatalogList || [])[0] || {}).catalogId || 'default'

    const lastCatalogVerification = (catalogVerifications || [])
      .filter(verification => verification.payload === selectedCatalog).slice(-1)[0]

    const isCasterVerified = Boolean(lastCasterVerification && lastCasterVerification.isVerified)
    const isProcessParametersVerified = Boolean(lastProcessParametersVerification &&
      lastProcessParametersVerification.isVerified)
    const isCatalogVerified = Boolean(lastCatalogVerification && lastCatalogVerification.isVerified)

    const isAllVerified = isCasterVerified && isProcessParametersVerified && isCatalogVerified

    const otherSimulationsRunning = currentProject.simulationCases.reduce((condition, simulationCase) => {
      return Boolean(condition || (simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt))
    }, false)

    return (
      <div>
        <Column width='33%'>
          <InfoMarker message={t(`${PRE_TRANS}.info`, { returnObjects: true }).join('\n')} x={222} y={11} size={20} />
          <Input
            name='selectedSimulationCase'
            type='select'
            label={t(`${PRE_TRANS}.case.label`)}
            title={t(`${PRE_TRANS}.case.title`)}
            value={selectedSimulationCaseId}
            selectors={simulationCaseSelectors}
            onChange={this.handleChangeSimulationCase}
            onDelete={this.handleDelete}
            noDeleteCurrent
            onEdit={this.handleEditOpen}
            onDownload={this.handleDownload}
          />
          {
            !simulationStartedAt &&
              <Button
                onClick={this.handleStartSimulation}
                disabled={!isAllVerified || otherSimulationsRunning}
                loading={loading.startSimulation}
                title={
                  error.startSimulation
                    ? t([ `error.${error.startSimulation}`, 'error.default' ])
                    : t(`${PRE_TRANS}.start.title`)
                }
                error={error.startSimulation && t([ `error.${error.startSimulation}`, 'error.default' ])}
                hideError
                height={61}
                bold
              >
                {t(`${PRE_TRANS}.start.label`)}
              </Button>
          }
          {
            simulationStartedAt && !simulationDataReceivedAt &&
              <ConfirmWrapper>
                {
                  !isConfirmingStop &&
                    <Button
                      loading
                      disabled
                      title={t(`${PRE_TRANS}.started`, { date: TimeUtil.getDisplayDateTime(simulationStartedAt) })}
                      icon='pe-7s-hourglass'
                      hideError
                      height={61}
                      bold
                      half
                    >
                      {t(`${PRE_TRANS}.loading.label`)}
                    </Button>
                }
                <Button
                  disabled={loading.stopSimulation}
                  loading={loading.stopSimulation}
                  onClick={this.handleStopSimulation}
                  onBeginConfirm={this.handleBeginConfirmStop}
                  onCancel={this.handleCancelStop}
                  hideError
                  height={61}
                  bold
                  half
                  confirm
                >
                  {t(`${PRE_TRANS}.stop.label`)}
                </Button>
              </ConfirmWrapper>
          }
          {
            simulationStartedAt && simulationDataReceivedAt &&
              <Button
                onClick={this.handleResetSimulation}
                loading={loading.resetSimulation}
                title={
                  error.resetSimulation
                    ? t([ `error.${error.resetSimulation}`, 'error.default' ])
                    : t(`${PRE_TRANS}.ended`, { date: TimeUtil.getDisplayDateTime(simulationDataReceivedAt) })
                }
                error={error.resetSimulation && t([ `error.${error.resetSimulation}`, 'error.default' ])}
                icon='pe-7s-lock'
                hideError
                height={61}
                bold
                confirm={t(`${PRE_TRANS}.reset.confirm`)}
              >
                {t(`${PRE_TRANS}.reset.label`)}
              </Button>
          }
        </Column>
        <Column width='66%'>
          <Label>{t(`${PRE_TRANS}.description.label`, { id: selectedSimulationCase })}</Label>
          <Description>
            {
              ((currentProject.simulationCases || [])
                .find(simulationCase => simulationCase._id === selectedSimulationCaseId) || {}).description || ''
            }
          </Description>
        </Column>
        <FullHR />
      </div>
    )
  }
}

const connected = connector(SimulationCaseComponent as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
