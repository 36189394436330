import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { ThemeProvider } from 'styled-components'

import StyleConfig from '../visualization/dashboard/config/StyleConfig'

import { Dialog, DialogBackground, Header, I, HeaderTitle, Content } from './DialogStyles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  title: string,
  icon: string,
  header: string,
  children: any,
  contentMinHeight?: number,
  darkTheme: boolean,
  hideCloseButton: boolean,
  small?: boolean,
  medium?: boolean,
  headerWidth?: string,
  hasScroll?: boolean,
  onClose: (e:any)=>void,
  t (key: string, params?: Record<string, unknown>): string
}

class BaseDialog extends Component<Props & WithNamespaces> {
  handleClick = (event: any) => {
    event.stopPropagation()
  }

  @AnalyzeTime(0)
  render () {
    const {
      t,
      icon,
      title,
      header,
      children,
      small,
      medium,
      headerWidth,
      hasScroll,
      darkTheme,
      hideCloseButton,
      contentMinHeight,
      onClose: handleClose,
    } = this.props

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <DialogBackground onClick={this.handleClick} />
          <Dialog small={small} medium={medium}>
            <div>
              <Header title={title}>
                <I className={icon} left />
                <HeaderTitle headerWidth={headerWidth}>{header}</HeaderTitle>
                {
                  !hideCloseButton &&
                    <I className='pe-7s-close' clickable onClick={handleClose} title={t('baseDialog.close')} />
                }
              </Header>
              <Content minHeight={contentMinHeight} hasScroll={hasScroll}>
                {children}
              </Content>
            </div>
          </Dialog>
        </div>
      </ThemeProvider>
    )
  }
}

export default withNamespaces('application')(connector(BaseDialog as any) as any) as any
