import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { withNamespaces } from 'react-i18next'
import tinycolor from 'tinycolor2'
import { withSnackbar } from 'notistack'

import Icon from './specific/Icon'

import * as FilterActions from '../store/filter/actions'
import DataActions from '../store/data/actions'

import { DefaultState } from 'types/state'
import FeatureFlags from './FeatureFlags'
import ThreeManager from 'three/ThreeManager'
import FilterHandler from 'three/logic/FilterHandler'
import ThreeUtil from '../three/logic/Util'
import { getCurrentDashboardEntry } from 'App/util'

const defaultBackgroundColor = '#5384db'

const darkenColor = (color: string, amount: number) => tinycolor(color).darken(amount).toString()

const FilterContainer = styled.div<{CasterTree: boolean,
  CasterDialog: boolean
  CasterDashboard: boolean
  disabled:boolean
  dashboardWidth:number
  casterDialogWidth: number
}>`${({
  CasterTree,
  CasterDialog,
  CasterDashboard,
  disabled,
  dashboardWidth,
  casterDialogWidth,
}: any) => css`
  position: absolute;
  top: 0;
  right: ${CasterDialog ? `${casterDialogWidth || 335}px` : '0'};
  height: 50px;
  left: ${CasterTree ? (CasterDashboard ? `${dashboardWidth}px` : '280px') : '0'};
  pointer-events: ${disabled ? 'none' : 'initial'};
  background: #22282e;
`}`

const Button = styled.button`
  position: absolute;
  color: #FFFFFF;
  top: 10px;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
`

const ClearButton = styled(Button)<{smallSearchInput: boolean}>`${({ smallSearchInput }: any) => css`
  top: 17px;
  right: ${smallSearchInput ? '35%' : '20px'};
`}`

const SearchInput = styled.input<{small: boolean, termDisabled: boolean}>`${({ small, termDisabled }: any) => css`
  width: ${small ? '65%' : 'calc(100% - 20px)'};
  margin: 0;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: #333a44;
  padding: 0 30px 0 10px;
  outline: none;
  color: ${termDisabled ? '#666666' : '#FFFFFF'};
  ${termDisabled && 'font-style: italic;'}
  margin-top: 9px;
  margin-left: 10px;
  
  &::placeholder{
    font-style: italic;
    user-select: none;
  }
`}`

const FilterControlInput = styled.input`
  height:             30px;
  font-size:          20px;
  width:              30%; 
  display:            flex;
  align-items:        center;
  justify-content:    center;
  text-align:         center;
  background:         none;
  border:             none;
  border-radius:      5px;
  color:              #FFFFFF;
  font-size:          16px;

  ::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }
  ::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }  
`

const FilterControlTitle = styled.span`
  width:              60%;
  overflow:           hidden;
  white-space:        nowrap;
  text-overflow:      ellipsis;
`

const ControlInputsContainer = styled.div`
  position:             absolute;
  top:                  9px;
  margin:               0 10px;
  padding:              0 5px 0 10px;
  right:                0;
  height:               32px;
  border-radius:        5px;
  color:                #FFFFFF;
  background:           #333a44;
  width:                calc(35% - 30px);
  display:              flex;
  font-size:            16px;
  align-items:          center;
  justify-content:      space-between;
  overflow:             hidden;
`

const UpAndDownArrowsContainer = styled.div`
  display:          flex;
  flex-direction:   column;
  margin-right:     15px;
  margin-top:       -10px;  
  color:            #FFFFFF;
  height:           32px;
  svg {
    height:         15px;
    display:        flex;
    align-items:    center;
    justify-content: center;
  }

  svg:hover {
    color:          ${tinycolor('#FFFFFF').darken(20).toString()};
  }
`

const FilterSelectorsContainer = styled.div`
  position:         absolute;
  top:              55px;
  left:             calc(65% + 15px);
  display:          flex;
  z-index:          100;
`

const FilterControl = styled.div<{
  backgroundColor?: string,
  active: boolean
}>`${({ backgroundColor, active }) => css`
  height:         22px;
  width:          22px;
  background:     ${active
    ? darkenColor(backgroundColor || defaultBackgroundColor, 10)
    : backgroundColor || defaultBackgroundColor
  };
  margin-left     5px;
  display:        flex;
  align-items     center;
  justify-content center;
  border:         ${active ? '2px solid #0353e9' : 'none'};
`}`

const Target = styled.div`
  display:        flex;
  flex-direction: column;
  color:          #FFFFFF;
  svg {
    height:       18px;
    font-size:    18px;
    border:       none;
  }
  svg:hover {
    color:       ${tinycolor('#FFFFFF').darken(20).toString()};
  }
`

const ShowMoreFilterControlsToggler = styled.div<{active: boolean}>`${({ active }) => css`
  height:         22px;
  width:          22px;
  background:     ${active ? darkenColor('#474B4E', 10) : '#474B4E'};
  margin-left     5px;
  display:        flex;
  align-items     center;
  justify-content center;

  svg {
    color: ${active ? darkenColor('#B0B1B2', 10) : '#B0B1B2'};
  }
`}`

const ExpandedFilterControlsContainer = styled.div`
  position:         absolute;
  top:              27px;
  display:          flex;
  width:            142px;
  background:       rgba(51, 58, 68, 0.5);
  flex-wrap:        wrap;
  padding:          0 5px 5px 0;

  div {
    margin-top:   5px;
  }
`

const connector = connect((state: DefaultState) => ({
  term: state.filter.term,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  activeFilterControlIndex: state.filter.activeFilterControlIndex,
  filterControlDefinitions: state.filter.filterControlDefinitions,
  currentFilterControlValue: state.filter.currentFilterControlValue,
  termDisabled: state.filter.termDisabled,
  openDialogs: state.application.main.openDialogs,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  featureFlags: state.application.main.authenticationData.featureFlags,
  rootData: state.data.rootData,
  currentDashboard: state.visualization.currentDashboard,
  viewsObject: state.visualization.viewsObject,
  elementsHashes: {
    AirLoop: state.AirLoop,
    CoolingLoop: state.CoolingLoop,
    CoolingZone: state.CoolingZone,
    LoopAssignment: state.LoopAssignment,
    Nozzle: state.Nozzle,
    Roller: state.Roller,
    RollerBearing: state.RollerBearing,
    RollerBody: state.RollerBody,
    SupportPoint: state.SupportPoint,
    SegmentGroupSupportPoints: state.SupportPoint,
    Segment: state.Segment,
    SegmentGroup: state.SegmentGroup,
    SensorPoint: state.SensorPoint,
    StrandGuide: state.StrandGuide,
    DataPoint: state.DataPoint,
    DataLine: state.DataLine,
  },
}), {
  setTerm: FilterActions.setTerm,
  setActiveFilterControlIndex: FilterActions.setActiveFilterControlIndex,
  setTarget: FilterActions.setTarget,
  setCurrentFilterControlValue: FilterActions.setCurrentFilterControlValue,
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
  enqueueSnackbar: enqueueSnackbar,
}

type State = {
  initialized: boolean
  inputValue?: number
  elementsIncludedInFilterControls: CasterElementNames[]
  minAndMaxPerFilterControl: Record<string, Record<string, {min?: number, max?: number}>>
  minOrMaxWithVariables: {
    elementType: CasterElementNames
    filter: string
  }[]
  showMoreFilterControls: boolean
  term: string
}

export class CasterFilter extends Component<Props, State> {
  changeFilterValueTimeout?: NodeJS.Timeout
  constructor (props: Props) {
    super(props)
    const { currentFilterControlValue } = this.props

    this.state = {
      initialized: false,
      inputValue: currentFilterControlValue,
      elementsIncludedInFilterControls: [],
      minOrMaxWithVariables: [],
      minAndMaxPerFilterControl: {},
      showMoreFilterControls: false,
      term: props.term || '',
    }
  }

  componentDidMount () {
    const { currentFilterControlValue } = this.props

    this.setState({ inputValue: currentFilterControlValue })
  }

  componentDidUpdate (prevProps: Props) {
    const { currentFilterControlValue, elementsHashes, termDisabled, setTerm } = this.props
    const { elementsIncludedInFilterControls, minOrMaxWithVariables, initialized } = this.state
    const newState: Partial<State> = {}
    const prevSegmentGroups = Object.keys(prevProps.elementsHashes.SegmentGroup)
    const currentSegmentGroups = Object.keys(elementsHashes.SegmentGroup)
    const segmentGroupsLoaded = prevSegmentGroups.length !== currentSegmentGroups.length

    if ((!termDisabled && prevProps.term !== this.props.term)) {
      newState.term = this.props.term
    }
    else if (this.state.term && prevProps.termDisabled && !this.props.termDisabled) {
      setTerm(this.state.term)
    }

    if (
      prevProps.filterControlDefinitions.length !== this.props.filterControlDefinitions.length ||
      prevProps.currentFilterControlValue !== currentFilterControlValue ||
      segmentGroupsLoaded
    ) {
      const {
        elementsIncludedInFilterControls,
        minOrMaxWithVariables,
      } = this.getElementsIncludedInFilterControls(this.props)

      newState.elementsIncludedInFilterControls = elementsIncludedInFilterControls
      newState.minOrMaxWithVariables = minOrMaxWithVariables
    }

    if (prevProps.currentFilterControlValue !== currentFilterControlValue) {
      if (currentFilterControlValue !== this.state.inputValue) {
        newState.inputValue = currentFilterControlValue
      }
    }

    const minAndMaxPerFilterControl: Record<string, Record<string, { min: number, max: number }>> = {}

    const elements = elementsIncludedInFilterControls?.length
      ? elementsIncludedInFilterControls
      : newState.elementsIncludedInFilterControls ?? []

    const minMax = minOrMaxWithVariables?.length
      ? minOrMaxWithVariables
      : newState.minOrMaxWithVariables ?? []

    for (let i = 0; i < elements.length; i++) {
      const elementType = elements[i]
      const prevElementTypeHash = prevProps.elementsHashes[elementType]
      const currentElementTypeHash = elementsHashes[elementType]

      if (
        !prevElementTypeHash ||
        !currentElementTypeHash ||
        (initialized && prevElementTypeHash.equals(currentElementTypeHash))
      ) {
        continue
      }

      const filtersContainingElementType = minMax
        .filter(filterVariable => filterVariable.elementType === elementType)

      for (let j = 0; j < filtersContainingElementType.length; j++) {
        const { elementType, filter } = filtersContainingElementType[j]

        if (
          minAndMaxPerFilterControl[elementType] &&
          minAndMaxPerFilterControl[elementType][filter]
        ) {
          continue
        }

        if (!minAndMaxPerFilterControl[elementType]) {
          minAndMaxPerFilterControl[elementType] = {}
        }

        const { min, max } = this.getKeyMinAndMaxFromElementType(elementType, filter)

        minAndMaxPerFilterControl[elementType][filter] = { min, max }
      }

      newState.minAndMaxPerFilterControl = minAndMaxPerFilterControl
    }

    if (Object.keys(newState).length) {
      this.setState({ ...newState as State, initialized: true })
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { setTerm, selectedMultiEditElements } = this.props

    this.setState({ term: event.target.value })
    setTerm(event.target.value)
    selectedMultiEditElements()
  };

  handleClearFilter = () => {
    const { setTerm, setActiveFilterControlIndex, setCurrentFilterControlValue } = this.props

    setTerm('')
    setActiveFilterControlIndex()
    setCurrentFilterControlValue()
    this.setState({ term: '' })
  };

  handleDeIncrementFilter = (definition: FilterControlDefinition, nextValue: number) => {
    const { setCurrentFilterControlValue, setTerm } = this.props
    const { filter } = definition
    const varName = definition.setVar

    if (varName && typeof varName === 'string') {
      window.dispatchEvent(new CustomEvent(`${varName}Changed`, { detail: { nextValue } }))

      if (!(window as any).filterControlVariables) {
        (window as any).filterControlVariables = {}
      }

      (window as any).filterControlVariables[varName] = nextValue
    }

    setCurrentFilterControlValue(nextValue)
    setTerm(this.getNextFilter(filter, nextValue))
  }

  handleIncrementFilter = () => {
    const { filterControlDefinitions, activeFilterControlIndex, currentFilterControlValue } = this.props

    if (activeFilterControlIndex === undefined || !filterControlDefinitions[activeFilterControlIndex]) {
      return
    }

    const currentDefinition = filterControlDefinitions[activeFilterControlIndex]
    const { maxValue = Infinity, valueStep = 1 } = currentDefinition
    const numberedCurrentValue = Number(currentFilterControlValue)
    const nextValue = numberedCurrentValue + valueStep
    const numberedMaxValue = typeof maxValue === 'number'
      ? maxValue
      : this.getMinOrMaxForFilterControlDefinition('max', currentDefinition)

    if (nextValue <= numberedMaxValue) {
      this.handleDeIncrementFilter(currentDefinition, nextValue)
    }
  }

  handleDecrementFilter = () => {
    const { filterControlDefinitions, activeFilterControlIndex, currentFilterControlValue } = this.props

    if (activeFilterControlIndex === undefined || !filterControlDefinitions[activeFilterControlIndex]) {
      return
    }

    const currentDefinition = filterControlDefinitions[activeFilterControlIndex]
    const { minValue = -Infinity, valueStep = 1 } = currentDefinition
    const numberedCurrentValue = Number(currentFilterControlValue)
    const nextValue = numberedCurrentValue - valueStep

    const numberedMinValue = typeof minValue === 'number'
      ? minValue
      : this.getMinOrMaxForFilterControlDefinition('min', currentDefinition)

    if (nextValue >= numberedMinValue) {
      this.handleDeIncrementFilter(currentDefinition, nextValue)
    }
  }

  handleInputChange = (event: any) => {
    this.setState({ inputValue: Number(event.target.value || 0) })
  }

  handleInputKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleChangeFilterWithInput()
    }
  }

  handleChangeFilterWithInput = () => {
    const {
      activeFilterControlIndex,
      filterControlDefinitions,
      currentFilterControlValue,
      setCurrentFilterControlValue,
      setTerm,
      enqueueSnackbar,
    } = this.props
    const value = this.state.inputValue

    const currentDefinition = activeFilterControlIndex !== undefined
      ? filterControlDefinitions[activeFilterControlIndex]
      : null

    if (!currentDefinition || value === undefined) {
      return
    }

    const {
      minValue = -Infinity,
      maxValue = Infinity,
      valueStep = 1,
      defaultValue = 1,
      setVar,
      filter,
    } = currentDefinition

    const numberedMinValue = Number(minValue)
    const numberedMaxValue = Number(maxValue)

    if (
      (minValue !== undefined && isNaN(numberedMinValue)) ||
      (maxValue !== undefined && isNaN(numberedMaxValue))) {
      enqueueSnackbar(
        'Min or max value is not a number',
        { variant: 'error', autoHideDuration: 3000 },
      )
    }

    if (value < numberedMinValue || value > numberedMaxValue || (value - defaultValue) % valueStep !== 0) {
      if (value < numberedMinValue) {
        enqueueSnackbar(
          'Value is smaller than the defined min value',
          { variant: 'error', autoHideDuration: 3000 },
        )
      }
      else if (value > numberedMaxValue) {
        enqueueSnackbar(
          'Value is greater than the defined max value',
          { variant: 'error', autoHideDuration: 3000 },
        )
      }
      else if ((value - defaultValue) % valueStep !== 0) {
        const closestSmaller = value - (value - defaultValue) % valueStep
        const closestBigger = closestSmaller + valueStep

        enqueueSnackbar(
          `Value is not compatible with step and default value
          nearest values are ${closestSmaller} and ${closestBigger}`,
          { variant: 'error', autoHideDuration: 3000 },
        )
      }

      this.setState({ inputValue: currentFilterControlValue })

      return
    }

    if (setVar) {
      window.dispatchEvent(new CustomEvent(`${setVar}Changed`, { detail: { nextValue: value } }))

      if (!(window as any).filterControlVariables) {
        (window as any).filterControlVariables = {}
      }

      (window as any).filterControlVariables[setVar] = value
    }

    setTerm(this.getNextFilter(filter, value))
    setCurrentFilterControlValue(value)
  }

  handleTargetClick = (_event: any) => {
    ThreeManager.base.jumpToFiltered(true)
  }

  handleToggleShowMoreFilterControls = () => {
    const { showMoreFilterControls } = this.state

    this.setState({ showMoreFilterControls: !showMoreFilterControls })
  }

  getKeyMinAndMaxFromElementType = (elementType: CasterElementNames, filter: string) => {
    const { elementsHashes } = this.props
    const typeHash = elementsHashes[elementType]
    const filteredElements = FilterHandler
      .getFilteredElements(elementsHashes, filter, false) as Record<string, FilterableElementType>
    const paths = Object.entries(filteredElements)
      .map(([ path ]) => path)
    const pathsLength = paths.length
    let min, max

    for (let i = 0; i < pathsLength; i++) {
      const path = paths[i]
      const { type, id } = ThreeUtil.getElementInfo(path)

      if (type !== elementType) {
        continue
      }

      const element = typeHash[id]

      if (!element) {
        continue
      }

      const key = `_${filter.split('#')[1]}`

      if (!key) {
        continue
      }

      const keyValue = element[key]
      const numberedKeyValue = typeof keyValue === 'number' ? keyValue : Number(keyValue)

      if (!keyValue === undefined || isNaN(numberedKeyValue)) {
        continue
      }

      // at the start, min and max are both undefined
      if (min === undefined || max === undefined) {
        min = keyValue
        max = keyValue
      }
      else if (min > keyValue) {
        min = keyValue
      }
      else if (max < keyValue) {
        max = keyValue
      }
    }

    return { min: min ?? -Infinity, max: max ?? Infinity }
  }

  getElementsIncludedInFilterControls = (props: Props) => {
    const elementTypes = [
      'StrandGuide',
      'AirLoop',
      'CoolingLoop',
      'CoolingZone',
      'LoopAssignment',
      'SegmentGroup',
      'Segment',
      'SupportPoint',
      'SegmentGroupSupportPoints',
      'RollerBody',
      'RollerBearing',
      'Nozzle',
      'Roller',
      'SensorPoint',
      'DataPoint',
      'DataLine',
    ]
    const { filterControlDefinitions } = props
    const elementsIncludedInFilterControls: CasterElementNames[] = []
    const minOrMaxWithVariables: {
      elementType: CasterElementNames,
      filter: string
    }[] = []

    for (let i = 0; i < filterControlDefinitions.length; i++) {
      const definition = filterControlDefinitions[i]
      const { minValue, maxValue } = definition

      if (typeof minValue === 'string' && !minValue.includes(' ')) {
        const [ type ] = minValue.split('#') as CasterElementNames[]
        const isTypeValid = elementTypes.indexOf(type) > -1
        const typeIsAlreadyIncluded = elementsIncludedInFilterControls.indexOf(type) > -1

        if (isTypeValid) {
          if (!typeIsAlreadyIncluded) {
            elementsIncludedInFilterControls.push(type)
          }

          const minAlreadyIncluded = minOrMaxWithVariables
            .findIndex(min => min.elementType === type && min.filter === minValue) > -1

          if (minAlreadyIncluded) {
            continue
          }

          minOrMaxWithVariables.push({
            elementType: type,
            filter: minValue,
          })
        }
      }

      if (typeof maxValue === 'string' && !maxValue.includes(' ')) {
        const [ type ] = maxValue.split('#') as CasterElementNames[]
        const isTypeValid = elementTypes.indexOf(type) > -1
        const typeIsAlreadyIncluded = elementsIncludedInFilterControls.indexOf(type) > -1

        if (isTypeValid) {
          if (!typeIsAlreadyIncluded) {
            elementsIncludedInFilterControls.push(type)
          }

          const maxAlreadyIncluded = minOrMaxWithVariables
            .findIndex(max => max.elementType === type && max.filter === maxValue) > -1

          if (maxAlreadyIncluded) {
            continue
          }

          minOrMaxWithVariables.push({
            elementType: type,
            filter: maxValue,
          })
        }
      }
    }

    return { elementsIncludedInFilterControls, minOrMaxWithVariables }
  }

  toggleFilter = (definition: FilterControlDefinition, index: number) => {
    const {
      activeFilterControlIndex,
      elementsHashes,
      setActiveFilterControlIndex,
      setTerm,
      selectedMultiEditElements,
      setCurrentFilterControlValue,
    } = this.props

    if (activeFilterControlIndex === index) {
      setActiveFilterControlIndex()
      setCurrentFilterControlValue()
      setTerm('')

      return
    }

    const term = this.getTermFromDefinition(definition)

    setCurrentFilterControlValue(definition.defaultValue || 1)
    selectedMultiEditElements()
    setActiveFilterControlIndex(index)
    setTerm(term)

    if (definition.selectAll) {
      const filteredElements = FilterHandler
        .getFilteredElements(elementsHashes, term, false) as Record<string, FilterableElementType>

      const paths = Object.entries(filteredElements)
        .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
        .map(([ path ]) => path)

      selectedMultiEditElements(paths, true)
    }
  }

  getTermFromDefinition = ({ filter, defaultValue = 1 }: FilterControlDefinition) => {
    return filter.replace('{VALUE}', String(defaultValue))
  }

  getNextFilter = (filter: string, nextValue: number) => filter.replace('{VALUE}', nextValue.toString())

  getInputTooltip = (definition: FilterControlDefinition | null) => {
    let tooltip = ''

    if (!definition) {
      return tooltip
    }

    const { minValue, maxValue, valueStep } = definition

    if (minValue !== undefined) {
      tooltip = `${tooltip} \nmin: ${minValue}`
    }

    if (maxValue !== undefined) {
      tooltip = `${tooltip} \nmax: ${maxValue}`
    }

    if (valueStep !== undefined) {
      tooltip = `${tooltip} \nstep: ${valueStep}`
    }

    return tooltip
  }

  getMinOrMaxValue = (filter: string, minOrMax: 'min' | 'max') => {
    const { minAndMaxPerFilterControl } = this.state
    const [ elementType ] = String(filter).split('#')

    return minAndMaxPerFilterControl[elementType]?.[filter]?.[minOrMax]
  }

  getFilterControlTooltip = (definition: FilterControlDefinition) => {
    const {
      title = '',
      filter = '',
      defaultValue,
      minValue,
      maxValue,
      valueStep,
    } = definition

    const minValueTooltip = typeof minValue === 'string'
      ? `${minValue} (${this.getMinOrMaxValue(minValue, 'min')})`
      : minValue
    const maxValueTooltip = typeof maxValue === 'string'
      ? `${maxValue} (${this.getMinOrMaxValue(maxValue, 'max')})`
      : maxValue

    let result = `${title}\n`

    if (filter !== undefined) {
      result += `\nfilter: ${filter}`
    }

    if (defaultValue !== undefined) {
      result += `\ndefault value: ${defaultValue}`
    }

    if (minValueTooltip !== undefined) {
      result += `\nmin value: ${minValueTooltip}`
    }

    if (maxValueTooltip !== undefined) {
      result += `\nmax value: ${maxValueTooltip}`
    }

    if (valueStep !== undefined) {
      result += `\nstep: ${valueStep}`
    }

    return result
  }

  getMinMaxAndStepForCurrentFilterControl = () => {
    const { activeFilterControlIndex, filterControlDefinitions } = this.props

    if (activeFilterControlIndex === undefined) {
      return {}
    }

    const definition = filterControlDefinitions[activeFilterControlIndex]

    if (!definition) {
      return {}
    }

    const { valueStep } = definition

    return {
      minValue: this.getMinOrMaxForFilterControlDefinition('min', definition),
      maxValue: this.getMinOrMaxForFilterControlDefinition('max', definition),
      valueStep: valueStep || 1,
    }
  }

  getMinOrMaxForFilterControlDefinition = (
    minOrMax: 'min' | 'max',
    definition: FilterControlDefinition,
  ) => {
    const { minAndMaxPerFilterControl } = this.state
    const { minValue, maxValue } = definition

    if (minOrMax === 'min' && typeof minValue === 'number') {
      return minValue
    }

    if (minOrMax === 'max' && typeof maxValue === 'number') {
      return maxValue
    }

    const filter = definition[`${minOrMax}Value`]
    const [ elementType ] = String(filter ?? '').split('#')

    if (
      !elementType ||
      !filter ||
      !minAndMaxPerFilterControl[elementType] ||
      minAndMaxPerFilterControl[elementType][filter] === undefined
    ) {
      return minOrMax === 'min' ? -Infinity : Infinity
    }

    return minAndMaxPerFilterControl[elementType][filter][minOrMax] || 0
  }

  render () {
    const {
      activeFilterControlIndex,
      currentCasterDialogWidth,
      currentDashboard,
      currentFilterControlValue,
      currentSimpleDashboardTabIndex,
      featureFlags,
      filterControlDefinitions,
      openDialogs,
      rootData,
      t,
      termDisabled,
      viewsObject,
    } = this.props

    const { inputValue, showMoreFilterControls } = this.state
    const filterControlDefs = filterControlDefinitions ?? []
    const currentDefinition = activeFilterControlIndex !== undefined && filterControlDefs
      ? filterControlDefs[activeFilterControlIndex] ?? null
      : null

    let dashboardWidth = 0
    const filterBarDisabled = !FeatureFlags.canUseFilterBar(featureFlags)
    const canViewFilterBar = FeatureFlags.canViewFilterBar(featureFlags)

    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]

    dashboardWidth = Math.min(viewObject?.dashboards?.[dashboardId]?.width ?? 500, window.innerWidth - 390)

    const showFilterControls = FeatureFlags.canUseFilterControls(featureFlags) &&
    filterControlDefs.length > 0 &&
    rootData.Caster
    const firstFiveFilterControlDefinitions = filterControlDefs.slice(0, 5)
    const expandedFilterControlDefinitions = filterControlDefs.slice(5)

    const disabledUpOrDownButtonStyle = { color: tinycolor('#FFFFFF').darken(50).toString() }

    const { minValue = -Infinity, maxValue = Infinity, valueStep = 1 } = this.getMinMaxAndStepForCurrentFilterControl()
    const upButtonDisabled = inputValue === undefined || inputValue + valueStep > maxValue
    const downButtonDisabled = inputValue === undefined || inputValue - valueStep < minValue
    const filterControlHasValue = currentDefinition?.filter?.includes('{VALUE}')

    return (
      <FilterContainer
        CasterTree={openDialogs.includes('CasterTree')}
        CasterDialog={openDialogs.includes('CasterDialog')}
        CasterDashboard={currentSimpleDashboardTabIndex > 0}
        disabled={!rootData.Caster}
        dashboardWidth={dashboardWidth}
        casterDialogWidth={currentCasterDialogWidth}
      >
        {
          canViewFilterBar &&
          <SearchInput
            termDisabled={termDisabled}
            small={activeFilterControlIndex !== undefined}
            placeholder={t('searchBar.typeToFilter')}
            onChange={this.handleChange}
            value={this.state.term}
            disabled={filterBarDisabled}
          />
        }
        {
          currentDefinition && (
            <ControlInputsContainer>
              <FilterControlTitle title={currentDefinition.title}>
                {currentDefinition.title}
              </FilterControlTitle>
              {
                showFilterControls && currentDefinition.isInputVisible !== false && filterControlHasValue && (
                  <FilterControlInput
                    type='number'
                    min={this.getMinOrMaxForFilterControlDefinition('min', currentDefinition)}
                    max={this.getMinOrMaxForFilterControlDefinition('max', currentDefinition)}
                    step={currentDefinition.valueStep}
                    value={inputValue ?? currentFilterControlValue}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                    onBlur={this.handleChangeFilterWithInput}
                    readOnly={currentDefinition.isInputUsable !== undefined && !currentDefinition.isInputUsable}
                  />
                )
              }
              {
                currentDefinition.hasUpDown && (
                  <UpAndDownArrowsContainer>
                    <Icon
                      icon='caretUp'
                      onClick={this.handleIncrementFilter}
                      viewBox='0 0 200 300'
                      style={upButtonDisabled ? disabledUpOrDownButtonStyle : {}}
                    />
                    <Icon
                      icon='caretDown'
                      onClick={this.handleDecrementFilter}
                      viewBox='0 0 200 300'
                      style={downButtonDisabled ? disabledUpOrDownButtonStyle : {}}
                    />
                  </UpAndDownArrowsContainer>
                )
              }
              <Target>
                <Icon icon='crosshairs' onClick={this.handleTargetClick} />
              </Target>
            </ControlInputsContainer>
          )
        }
        {
          canViewFilterBar &&
          <ClearButton
            onClick={this.handleClearFilter}
            title={t('searchBar.clearFilter')}
            smallSearchInput={activeFilterControlIndex !== undefined}
          >
            <Icon icon='times' />
          </ClearButton>
        }
        {
          showFilterControls && (
            <FilterSelectorsContainer>
              {
                firstFiveFilterControlDefinitions.map((definition, index) => (
                  <FilterControl
                    backgroundColor={definition.color}
                    key={`${filterControlDefs}-${index}`}
                    onClick={() => this.toggleFilter(definition, index)}
                    active={activeFilterControlIndex === index}
                    title={this.getFilterControlTooltip(definition)}
                  >
                    <Icon icon={definition.icon || 'circle'} />
                  </FilterControl>
                ))
              }
              {
                filterControlDefs.length > 5 && (
                  <ShowMoreFilterControlsToggler
                    active={showMoreFilterControls}
                    onClick={this.handleToggleShowMoreFilterControls}
                  >
                    <Icon icon='th' />
                  </ShowMoreFilterControlsToggler>
                )
              }
              {
                this.state.showMoreFilterControls && (
                  <ExpandedFilterControlsContainer>
                    {
                      expandedFilterControlDefinitions.map((definition, index) => (
                        <FilterControl
                          backgroundColor={definition.color}
                          key={`${filterControlDefs}-${index + 5}`}
                          onClick={() => this.toggleFilter(definition, index + 5)}
                          active={activeFilterControlIndex === index + 5}
                          title={this.getFilterControlTooltip(definition)}
                        >
                          <Icon icon={definition.icon || 'circle'} />
                        </FilterControl>
                      ))
                    }
                  </ExpandedFilterControlsContainer>
                )
              }
            </FilterSelectorsContainer>
          )
        }
      </FilterContainer>
    )
  }
}

export default withNamespaces('caster')(withSnackbar((connector(CasterFilter as any) as any)) as any) as any
