import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{
  overWrapper?: boolean
  isDataSourceServer?: boolean
}>`${({ overWrapper, isDataSourceServer }) => css`
  position: relative;
  height: 100%;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  ${isDataSourceServer && css`
    :not(.js-plotly-plot) {
      .modebar-container {
        visibility: hidden;
      }
    }

    .js-plotly-plot {
      .modebar-container {
        visibility: visible;
      }
    }

    .layer-above .shapelayer path:not(.fast-update),
    .bars .point:not(.fast-update),
    .js-fill:not(.fast-update),
    .js-line:not(.fast-update) {
      ${overWrapper && 'visibility: visible;'}
    }

    .svg-container .main-svg {
      position: absolute;
      top: 0;
    }
  `}
`}`

export const PlotInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.5;
  padding-bottom: 34px;

  ::before {
    content: 'ℹ';
    border: 1px solid white;
    border-radius: 50%;
    padding: 1px 4.75px 0;
    font-size: 12px;
    margin: 0 5px 2px 0; 
  }
`
