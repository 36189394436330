import { combineReducers } from 'redux'

import data from './data'
import matrix from './matrix'
import util from './util'
import filter from './filter'
import application from './application'
import loading from './LoadingStore'
import visualization from './visualization'
// import elements from './elements'
import AirLoop from './elements/airLoop'
import CoolingLoop from './elements/coolingLoop'
import CoolingZone from './elements/coolingZone'
import LoopAssignment from './elements/loopAssignment'
import Nozzle from './elements/nozzle'
import Roller from './elements/roller'
import RollerBody from './elements/rollerBody'
import RollerBearing from './elements/rollerBearing'
import SupportPoint from './elements/supportPoint'
import Segment from './elements/segment'
import SegmentGroup from './elements/segmentGroup'
import SensorPoint from './elements/sensorPoint'
import StrandGuide from './elements/strandGuide'
import DataPoint from './elements/dataPoint'
import DataLine from './elements/dataLine'
import ChangeItem from './consistencyCheck/edits'
import CCElement from './consistencyCheck/ccElements'
import ComparisonCasters from './comparison-casters'
import tileWarnings from './tileWarnings'
import modules from './modules'

export const makeRootReducer = (asyncReducers?: any) => {
  return combineReducers({
    application,
    data,
    matrix,
    filter,
    loading,
    util,
    visualization,
    AirLoop,
    CoolingLoop,
    CoolingZone,
    LoopAssignment,
    Nozzle,
    Roller,
    RollerBody,
    RollerBearing,
    SupportPoint,
    Segment,
    SegmentGroup,
    SensorPoint,
    StrandGuide,
    DataPoint,
    DataLine,
    ChangeItem,
    CCElement,
    ComparisonCasters,
    tileWarnings,
    modules,
    ...asyncReducers,
  })
}

export const injectReducer = (store: any, { key, reducer }: any) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return
  }

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
