/* eslint-disable camelcase */
import * as THREE from 'three'

import fontRobotoMedium from '../fonts/roboto-medium.json'
import { Material, Vector2, Vector3 } from 'three'
import type { ElementsHashes } from 'types/state'

export default class Util {
  static RollerMode = 2

  static RAD = Math.PI / 180
  static DEG = 180 / Math.PI

  static RAD90 = 90 * Util.RAD
  static RAD180 = 180 * Util.RAD
  static RAD270 = 270 * Util.RAD

  static xAxis = new Vector3(1, 0, 0)
  static yAxis = new Vector3(0, 1, 0)
  static zAxis = new Vector3(0, 0, 1)

  static sidesAngles = {
    NarrowFaceRight: 0,
    LooseSide: 90,
    NarrowFaceLeft: 180,
    FixedSide: 270,
  }

  static anglesSides = {
    0: 'NarrowFaceRight',
    90: 'LooseSide',
    180: 'NarrowFaceLeft',
    270: 'FixedSide',
  }

  static sides = [
    'FixedSide',
    'LooseSide',
    'NarrowFaceLeft',
    'NarrowFaceRight',
  ]

  static phantomTypes = [
    'Nozzle',
    'Roller',
    'RollerBearing',
    'RollerBody',
  ]

  static fallbackMaterial = new THREE.MeshBasicMaterial({ color: '#ff00ff' })
  static fontMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' })

  static fontRobotoMedium = new (THREE as any).Font(fontRobotoMedium)

  static shapeFontCache: any = {}
  static canvasFontCache: any = {}

  static nextPowerOf2 = (n: number) => Math.pow(2, Math.ceil(Math.log(n) / Math.log(2)))

  static drawText (text: string, size: number, color: string) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const font = `Bold ${size}px Roboto, Arial`

    if (!context) {
      return
    }

    context.font = font

    const width = context.measureText(text).width
    const height = size * 1.25

    canvas.width = Util.nextPowerOf2(width)
    canvas.height = Util.nextPowerOf2(height)

    context.textBaseline = 'middle'
    context.fillStyle = color
    context.font = font
    context.fillText(text, 1, size / 2 + size * 0.125)

    return { canvas, width, height }
  }

  static _getText (text: string, color: string, size: number, targetHeight: number) {
    const drawnText = Util.drawText(text, size, color)

    if (!drawnText) {
      return
    }

    const { canvas, width, height } = drawnText
    const texture = new THREE.Texture(canvas)

    texture.needsUpdate = true
    texture.repeat.set(width / canvas.width, height / canvas.height)
    texture.offset.set(0, 1 - height / canvas.height)
    texture.anisotropy = 8

    const s = height / (targetHeight || size)
    const newWidth = width / s
    const newHeight = targetHeight || (height / s)
    const geometry = new THREE.PlaneBufferGeometry(newWidth, newHeight)
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
    const plane = new THREE.Mesh(geometry, material)

    plane.userData.width = newWidth
    plane.userData.height = newHeight

    return plane
  }

  static getText (
    string: string,
    size: number,
    useShape = false,
    centerHeight = false,
    centerWidth = true,
    color = '#FFFFFF',
  ) {
    if (!useShape) {
      const key = `${size}_${string}`

      if (!Util.canvasFontCache[key]) {
        const nSize = size * 2

        Util.canvasFontCache[key] = Util._getText(string, color, Math.max(nSize, 48), nSize)
      }

      const textPlane = Util.canvasFontCache[key].clone()

      textPlane.material = textPlane.material.clone()

      if (!centerHeight) {
        textPlane.geometry.translate(0, textPlane.userData.height / 2, 0)
      }

      return textPlane
    }

    const key = `${centerHeight ? 1 : 0}_${size}_${string}`

    if (!Util.shapeFontCache[key]) {
      const textShape = new THREE.BufferGeometry()
      const shapes = Util.fontRobotoMedium.generateShapes(string, size)
      const geometry = new THREE.ShapeGeometry(shapes, 2) // no Buffer!

      geometry.computeBoundingBox()

      const { max, min } = geometry.boundingBox || {}
      const xDiff = (max || {}).x || 0 - ((min || {}).x || 0)
      const yDiff = (max || {}).y || 0 - ((min || {}).y || 0)

      const xMid = centerWidth ? -0.5 * xDiff : 0
      const yMid = centerHeight ? -0.5 * yDiff : 0

      geometry.translate(xMid, yMid, 0)
      ;(textShape as any).fromGeometry(geometry) // TODO: does fromGeometry exist?

      Util.shapeFontCache[key] = new THREE.Mesh(textShape, Util.fontMaterial)
    }

    return Util.shapeFontCache[key].clone()
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static runPerElement (path: string, type: string, element: any, previousElement: any, parentType: string, func: any) {
    if (element instanceof Array) {
      const sortedElements = element.sort((a, b) => (a._passln_coord || 0) - (b._passln_coord || 0))

      for (let i = 0; i < sortedElements.length; i++) {
        Util.runPerElement(
          `${path}:${sortedElements[i]._id}`,
          type,
          sortedElements[i],
          previousElement,
          parentType,
          func,
        )
      }

      return
    }

    const colon = ~path.substring(path.lastIndexOf('/') + 1).indexOf(':')
    const fullPath = colon ? path : `${path}:${element._id}`

    func(fullPath, type, element, previousElement, parentType)

    const children = Object.keys(element || {}).filter(key => (key[0] !== '_' && key[0] !== '#'))

    children.forEach(key => {
      Util.runPerElement(`${fullPath}/${key}`, key, element[key], element, type, func)
    })
  }

  static getElementPath (element: any, elementsHashes: ElementsHashes, path: string): string {
    const parentAtt = element['#parent']

    if (parentAtt && parentAtt.type === 'StrandGuide') {
      return path
    }

    const parentPath = parentAtt ? `${parentAtt.type}:${parentAtt.id}` : ''

    if (parentPath) {
      return this.getElementPath(
        (elementsHashes as any)[parentAtt.type][parentAtt.id],
        elementsHashes,
        `${parentPath}/${path}`,
      )
    }

    return path
  }

  static runPerElementHash (
    path: string,
    type: string,
    ids: number[],
    previousElement: any,
    parentType: string,
    elementsHashes: ElementsHashes,
    func: any,
  ) {
    const idsLength = ids.length

    if (!idsLength) {
      return
    }

    if (idsLength > 1) { // Could avoid sorting
      // const sortedElements = ids.sort( //TODO: if it works remove
      //   (a, b) =>(
      //   (elementsHashes as any)[type][a]._passln_coord || 0) - ((elementsHashes as any)[type][b]._passln_coord || 0))

      for (let i = 0; i < idsLength; i++) {
        Util.runPerElementHash(
          `${path}:${ids[i]}`,
          type,
          [ ids[i] ],
          previousElement,
          parentType,
          elementsHashes,
          func,
        )
      }

      return
    }

    const element = ((elementsHashes as any)[type] || {})[ids[0]] || {}
    const colon = ~path.substring(path.lastIndexOf('/') + 1).indexOf(':')
    const fullPath = colon ? path : `${path}:${ids[0]}`

    // TODO: could send only the path
    if (element) {
      func(fullPath, type, element, previousElement, parentType)
    }

    const children = Object.keys(element).filter(key => key.includes('Ids'))

    children.forEach(key => {
      const childType = key.slice(1, -3) // removes '#' and 'Ids' from key

      Util.runPerElementHash(
        `${fullPath}/${childType}`,
        childType,
        element[key],
        element,
        type,
        elementsHashes,
        func,
      )
    })
  }

  static runPerElementHashWithDepth (
    path: string,
    type: string,
    ids: number[],
    previousElement: any,
    parentType: string,
    elementsHashes: ElementsHashes,
    depth: number,
    func: any,
  ) {
    const idsLength = ids.length

    if (!idsLength) {
      return
    }

    if (idsLength > 1) { // Could avoid sorting
      // const sortedElements = ids.sort( //TODO: if it works remove
      //   (a, b) =>(
      //   (elementsHashes as any)[type][a]._passln_coord || 0) - ((elementsHashes as any)[type][b]._passln_coord || 0))

      for (let i = 0; i < idsLength; i++) {
        Util.runPerElementHashWithDepth(
          `${path}:${ids[i]}`,
          type,
          [ ids[i] ],
          previousElement,
          parentType,
          elementsHashes,
          depth,
          func,
        )
      }

      return
    }

    if (!(elementsHashes as any)[type]) {
      return
    }

    const element = (elementsHashes as any)[type][ids[0]] || {}
    const colon = ~path.substring(path.lastIndexOf('/') + 1).indexOf(':')
    const fullPath = colon ? path : `${path}:${ids[0]}`
    const children = Object.keys(element).filter(key => key.includes('Ids'))

    func(fullPath, type, element, depth, !!children.length, previousElement, parentType)

    children.forEach(key => {
      const childType = key.slice(1, -3) // removes '#' and 'Ids' from key

      Util.runPerElementHashWithDepth(
      `${fullPath}/${childType}`,
      childType,
      element[key],
      element,
      type,
      elementsHashes,
      depth + 2,
      func,
      )
    })
  }

  static closest (target: number, numbers: number[]) {
    return numbers.length
      ? numbers.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev))
      : 0
  }

  static sides2Angles (side: string) {
    return (Util.sidesAngles as any)[side] !== undefined ? (Util.sidesAngles as any)[side] : Number(side)
  }

  static angles2Sides (angle: string) {
    return (Util.anglesSides as any)[angle] || angle
  }

  static getRotation (a: number, b: number) {
    let rot = Math.atan2(a, b)

    rot += Math.PI

    if (rot < 0) {
      rot += (Math.PI * 2)
    }

    if (rot >= Math.PI * 2) {
      rot -= (Math.PI * 2)
    }

    return rot
  }

  static getCameraRotations (pos: Vector3) {
    const y = Util.getRotation(pos.x, pos.z)
    const p = pos.clone()
    const yAxis = new Vector3(0, 1, 0)

    p.applyAxisAngle(yAxis, -y)

    return {
      x: Util.getRotation(p.z, p.y) - Math.PI / 2,
      y,
    }
  }

  static getShortestRotation (angle: number) {
    if (angle > Math.PI * 1.01) {
      return angle - Math.PI * 2
    }

    if (angle < -Math.PI * 1.01) {
      return angle + Math.PI * 2
    }

    return angle
  }

  static getElementInfo (path: string): {type: CasterElementNames, id: number} {
    // takes 0.32 ms
    const element = path.substring(path.lastIndexOf('/') + 1)
    const colon = element.indexOf(':')
    const type = element.substring(0, colon) as CasterElementNames
    const id = Number(element.substring(colon + 1))

    // TODO: string operation are way faster than array operations! by 10x !? ANALYZE!
    // takes 2.21 ms because of Minor GC after split ~ 15 MB collected
    // const [ type, id ] = path.split('/').splice(-1)[0].split(':')

    return {
      type,
      id: Number(id),
    }
  }

  static getCompareElementByName (
    name: string,
    elementType: CasterElementNames,
    compareElementsHashes: ElementsHashes,
  ) {
    const elementsArray = Object.values(compareElementsHashes[elementType] || {})

    return elementsArray.find((element: any) => element._name === name) || null
  }

  static getCompareElementByNameAndParent (
    name: string,
    path: string,
    elementType: CasterElementNames,
    compareElementsHashes: ElementsHashes,
  ) {
    const { type, id } = Util.getParentInfo(path)

    const elementsArray = Object.values(compareElementsHashes[elementType] || {})

    return elementsArray.find((element: any) =>
      element._name === name && element['#parent'].type === type && element['#parent'].id === id) || null
  }

  static getCompareElementById (
    id: number,
    elementType: CasterElementNames,
    compareElementsHashes: ElementsHashes,
  ) {
    const elementsArray = Object.values(compareElementsHashes[elementType] || {})

    return elementsArray.find((element: any) => element._id === id) || null
  }

  static getCompareElementByWidthAndPassLnCoordAndSide (
    referenceElement: any,
    elementType: CasterElementNames,
    referenceElementsHashes: ElementsHashes,
    compareElementsHashes: ElementsHashes,
  ) {
    const widthVariable = (elementType === 'RollerBearing' || elementType === 'RollerBody')
      ? '_width_start'
      : '_width_coord'
    const widthVariableValue = referenceElement[widthVariable]
    const _passln_coord = referenceElement._passln_coord

    let parentInfo: {type: CasterElementNames, id: number} = referenceElement['#parent']

    if (!parentInfo) {
      return null
    }

    let parent = referenceElementsHashes[parentInfo.type][parentInfo.id]

    if (!parent) {
      return null
    }

    while (!parent._FixedLooseSide) {
      parentInfo = parent['#parent']

      if (!parentInfo) {
        return null
      }

      parent = referenceElementsHashes[parentInfo.type][parentInfo.id]

      if (!parent) {
        return null
      }
    }

    const side = parent._FixedLooseSide
    let compareParent: any = null
    let compareParentInfo: {type: CasterElementNames, id: number}

    const compareElement: any = Object.values(compareElementsHashes[elementType]).find((element: any) => {
      if (element[widthVariable] !== widthVariableValue || element._passln_coord !== _passln_coord) {
        return false
      }

      compareParentInfo = element['#parent']

      if (!compareParentInfo) {
        return false
      }

      compareParent = compareElementsHashes[parentInfo.type][parentInfo.id]

      if (!compareParent) {
        return false
      }

      while (!compareParent._FixedLooseSide) {
        compareParentInfo = parent['#parent']

        if (!compareParentInfo) {
          return false
        }

        parent = compareElementsHashes[compareParentInfo.type][compareParentInfo.id]

        if (!parent) {
          return false
        }
      }

      return compareParent._FixedLooseSide === side
    })

    return compareElement || null
  }

  static getParentInfo (path: string) {
    const parentPath = path.substring(0, path.lastIndexOf('/'))
    const { type, id } = Util.getElementInfo(parentPath)

    return {
      path: parentPath,
      type,
      id,
    }
  }

  static getTriangleMesh (a: Vector3, b: Vector3, c: Vector3, material?: THREE.Material) {
    const geometry = new (THREE as any).Geometry()

    geometry.vertices.push(a, b, c)
    geometry.faces.push(new (THREE as any).Face3(0, 1, 2))
    geometry.computeFaceNormals()

    return new THREE.Mesh(geometry, material || Util.fallbackMaterial)
  }

  static addOrReplaceInList (oldElement: any, element: any, list: any[]) {
    if (oldElement) {
      const index = list.indexOf(oldElement)

      if (index !== -1) {
        list.splice(index, 1)
      }
    }

    list.push(element)
  }

  static addOrReplace (container: any, element: any, list?: any) {
    const oldElement = container.getObjectByName(element.name) // TODO: use & create element registry

    if (oldElement) {
      element.visible = oldElement.visible

      container.remove(oldElement)
    }

    container.add(element)

    if (list) {
      Util.addOrReplaceInList(oldElement, element, list)
    }
  }

  static tooltipMarkerMaterial = new THREE.MeshBasicMaterial({ color: '#458cff' })
  static snapMaterial = new THREE.MeshBasicMaterial({ color: '#555555', transparent: true, opacity: 0.01 })

  static getCircle (radius: number, position: Vector3, rotateX: boolean, material: THREE.Material) {
    const geometry = new THREE.CircleBufferGeometry(radius, 8)
    const circle = new THREE.Mesh(geometry, material)

    if (rotateX) {
      circle.rotateX(-90 * Util.RAD)
    }

    circle.position.copy(position)

    return circle
  }

  static _createTooltipMarker (
    container: any,
    tooltipObjects: any[],
    name: string,
    position: Vector3,
    tooltip: Tooltip,
    _type?: string,
    radius = 0.01,
  ) {
    const tooltipMarker = Util.getCircle(radius, position, true, Util.tooltipMarkerMaterial)

    tooltipMarker.type = 'TooltipMarker'
    tooltipMarker.name = `TooltipMarker_${name}`
    tooltipMarker.userData.tooltip = tooltip

    Util.addOrReplace(container, tooltipMarker, tooltipObjects)
  }

  static createTooltipMarker (
    container: any,
    tooltipObjects: any[],
    name: string,
    position: Vector3,
    tooltip: any,
    hasSnap = true,
  ) {
    const pos = position.clone()

    pos.y += 0.0002

    Util._createTooltipMarker(container, tooltipObjects, name, pos, tooltip)

    if (hasSnap) {
      const pos = position.clone()

      pos.y -= 0.0002
      const snap = Util.getCircle(0.15, pos, true, Util.snapMaterial)

      snap.type = 'TooltipMarkerSnap'
      snap.name = `TooltipMarkerSnap_${name}`
      snap.userData.markerName = `TooltipMarker_${name}`

      Util.addOrReplace(container, snap, tooltipObjects)
    }
  }

  static getV2 (v3: Vector3) {
    return new THREE.Vector2(v3.x, v3.y)
  }

  static getV3 (v2: Vector2) {
    return new Vector3(v2.x, v2.y, 0)
  }

  static flipXZ (v: Vector3) {
    const x = v.x

    v.x = v.z
    v.z = x
  }

  static isPhantom (type: CasterElementNames, sectionDetail?: boolean) {
    const phantomTypes: CasterElementNames[] = [ 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing' ]
    const phantomTypesSectionDetail: CasterElementNames[] = [ 'Nozzle', 'RollerBody', 'RollerBearing' ]

    return !sectionDetail ? phantomTypes.includes(type) : phantomTypesSectionDetail.includes(type)
  }

  static lodVisible (lod: any) {
    let visible = false

    for (let i = 0; i < lod.children.length; i++) {
      visible = visible || (lod.children[i].visible && lod.children[i].material.visible)
    }

    return visible
  }

  static _isVisible (object: any) {
    if (object.visible) {
      if (object.parent) {
        return Util.isVisible(object.parent)
      }

      return true
    }

    return false
  }

  static isVisible (object: any): boolean {
    const isLOD = object.parent instanceof THREE.LOD

    return Util._isVisible(object) && (!isLOD || (isLOD && Util.lodVisible(object.parent)))
  }

  static drawLine (
    container: any,
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number,
    name: string,
    material: Material,
  ) {
    const geometry = new (THREE as any).Geometry()

    geometry.vertices.push(
      new Vector3(x1, y1, z1),
      new Vector3(x2, y2, z2),
    )

    const line = new THREE.Line(geometry, material)

    line.name = name

    Util.addOrReplace(container, line)
  }

  static getColorChannel (c: number, percent: number) {
    return ((0 | (1 << 8) + c + (256 - c) * percent / 100).toString(16)).substr(1)
  }

  static updateColor (hex: string, percent: number) {
    hex = hex.replace(/^\s*#|\s*$/g, '')

    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1')
    }

    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)

    return `#${Util.getColorChannel(r, percent)}${Util.getColorChannel(g, percent)}${Util.getColorChannel(b, percent)}`
  }

  // function that returns the amount of space separated values in a string'
  static getNumberOfValues (string: string) {
    return string.split(' ').length
  }
}
