import PasslineCurve from './PasslineCurve'
import SensorPoint from './SensorPoint'
import * as THREE from 'three'
import Util from '../logic/Util'
import Mold from './Mold'
import { Vector3 } from 'three'

export default class DataPoint extends SensorPoint {
  thickness: number
  constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent, clickableObjects, tooltipObjects)
    this.hiddenKeys.push('_originalId', '_passln_coord', '_width_coord')
    this.thickness = 0

    this.objects.sensorPoint.type = 'DataPoint'
  }

  updateTransform (): void {
    const { sensorPoint } = this.objects

    const { position, angleX, normal } =
      PasslineCurve.getInfoAtPlCoord(this.plCoord || 0, this.sectionDetail ? true : undefined, true)

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const size = this.sectionDetail ? this.size : 0

    newSectionRotation.x = -Util.RAD90

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    if (this.wCoord >= Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on left side
      newPosition.set(-position.z - this.size / 2, position.y + 0.00001, this.wCoord + this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.wCoord <= -Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on right side
      newPosition.set(-position.z - this.size / 2, position.y + 0.00001, this.wCoord - this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.thickness <= 0) {
      // Fixed side
      const diff = this.thickness < 0 ? this.thickness + Mold.thickness : 0 // + because this.thickness is negative

      newPosition.set(-position.z + size / 2, position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(diff))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else if (this.thickness >= Mold.thickness) {
      // Loose side
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x + size / 2), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
    }
    else if (this.thickness <= Mold.thickness / 2) {
      newPosition.set(-position.z - this.thickness, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
    }
    else {
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  setValues (
    pointData: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _loopColorIndex: number,
    sectionDetail: any,
    isPhantom?: boolean,
  ): void {
    this.thickness = pointData._thickness_coord / 1000 || 0

    super.setValues(
      pointData,
      plHeight,
      path,
      isDeleted,
      isHidden,
      _loopColorIndex,
      sectionDetail,
      isPhantom,
    )
  }
}
