import cloneDeep from 'clone-deep'
import { DefaultState, ElementsHashes } from 'types/state'
import { Network } from '../../network/Network'
import ThreeUtil from 'three/logic/Util'
import { setError } from '../application/error/actions'
import {
  resetLoadingCursor, setApplyLoadingCursor, setCloneLoadingCursor, setCreateLoadingCursor, setMirrorLoadingCursor,
  setPatternLoadingCursor,
} from '../LoadingStore'
import { DataActionsEnum } from './consts'
// import { getDefaultElement, getDifferenceElement,getPathFromPathArray } from './logic/getters'
import {
  actionMergeNew,
  actionNewData,
  actionOverwriteAll,
  countLoopIndex,
  deleteListFunction,
  getDefaultElement,
  getDifferenceElement,
  getElementFromPath,
  handleChangeType,
  handleCopyPaths,
  handleRepeatPaths,
  handleSuccessfullyAddedElements,
  handleSuccessfullySavedPaths,
  handleWaterFluxFraction,
  parsePasslineData,
  parseXmlData,
  setRollerDiameter,
  buildElementsHashes,
  setRollerPasslnCoord,
} from 'store/elements/logic/index'
import { updateElementsHashes } from 'store/elements/actions'
import Util from 'logic/Util'
import { addChangeItems } from 'store/consistencyCheck/edits'
import { setCCElements } from 'store/consistencyCheck/ccElements'
import { updateModules } from 'store/modules'
import FeatureFlags from 'react/FeatureFlags'
import { FilterActionsEnum } from 'store/filter/consts'
import ThreeManager from 'three/ThreeManager'
import { ApplicationMainActionsEnum } from 'store/application/main/consts'
import { getCurrentDashboardEntry } from 'App/util'

function resetHasChanges () {
  return {
    type: DataActionsEnum.ACTION_RESET_CHANGES,
  }
}

function resetReducer (avoidVisualization = false, avoidExecutables = false) {
  return {
    type: DataActionsEnum.ACTION_RESET_ALL,
    avoidVisualization,
    avoidExecutables,
  }
}

function addPendingDeleteList (path: Array<any> = []) {
  return {
    type: DataActionsEnum.ACTION_ADD_PENDING_DELETE_LIST,
    path,
  }
}

function saveElement (paths: string[], targetArray: any[], type: string, elementType?: string, comments?: any) {
  return {
    types: [
      DataActionsEnum.ACTION_SAVE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_SAVE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_SAVE_ELEMENT_ERROR,
    ],
    promise: (
      client: any,
      {
        data,
        application: { main: { currentSimulationCase, authenticationData } },
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }: DefaultState,
      dispatch: any,
    ) => {
      const elementsHashes = {
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        SegmentGroupSupportPoints: SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }

      // only send the elements that are going to be saved not just the whole state
      let editElements = Object.keys(cloneDeep(data.editElements))
        .filter(key => paths.includes(key))
        .reduce((obj, key) => {
          obj[key] = data.editElements[key]

          return obj
        }, {} as Record<string, any>)

      // Object.keys(editElements).forEach(key => {
      //   delete editElements[key]['#parent']
      // })
      if (elementType) {
        dispatch(setApplyLoadingCursor(elementType))
      }

      if (elementType === 'Roller') {
        const rollerChildrenPaths: string[] = []

        // TODO: could update diameter and passlncoord in one function!!
        editElements = setRollerDiameter(data.editElements, paths, elementsHashes, rollerChildrenPaths)
        /* send only edit elements because if we send data.editElements again
           we wont use the changes made in setRollerDiameter */
        editElements = setRollerPasslnCoord(editElements, paths, elementsHashes, rollerChildrenPaths)
        rollerChildrenPaths.forEach(childPath => paths.push(childPath))// careful not to push duplicates
      }

      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise.resolve({
          paths,
          editElements,
          targetArray,
          type,
        }).then(result => {
          dispatch(addPendingDeleteList())
          dispatch(resetLoadingCursor())

          return result
        })
        : client
          .patch(`${Network.URI}/caster_data/${currentSimulationCase._id}`, {
            data: {
              // TODO: some of the changed values are strings instead of numbers so make sure all fields are correct
              // TODO: before sending them, otherwise the history will show them as changes.
              // TODO: e.g. changing "80" to 81 and then to 80 yields 80 but as number not as string
              data: {
                ...(type === 'update' ? editElements : paths.reduce((acc, val) => ({
                  ...acc,
                  [val]: {},
                }), {})),
              },
              comments,
              action: type,
              targetPath: targetArray.length ? Util.getPathFromPathArray(targetArray) : null,
            },
          })
          .then(() => {
            dispatch(addPendingDeleteList())
            dispatch(resetLoadingCursor())
            // const xmlData = cloneDeep(data.xmlData)
            // const elementsHashes = {} as ElementsHashes
            // if(xmlData){
            //   buildElementsHashes(xmlData, elementsHashes)
            // }
            const dirtyPaths = [ ...data.dirtyPaths ]
            const dirtyDeletePaths = [ ...data.dirtyDeletePaths ]
            const hidePaths = [ ...data.hidePaths ]
            const editVal = { ...data.editElements, ...editElements }
            const loopCounter = { ...data.loopCounter }
            let selectedDataArray = [ ...data.selectedDataArray ]

            const parentPath = targetArray && targetArray.length ? Util.getPathFromPathArray(targetArray) : null

            selectedDataArray = handleSuccessfullySavedPaths(
              paths,
              type,
              parentPath,
              targetArray,
              dirtyPaths,
              dirtyDeletePaths,
              selectedDataArray,
              hidePaths,
              editVal,
              elementsHashes,
              loopCounter,
            )

            if (type === 'delete') {
              selectedDataArray = []
            }

            dispatch(updateElementsHashes(elementsHashes))
            dispatch(updateModules(elementsHashes))

            // TODO: this is a hack, find a better way to update the scene
            setTimeout(() => {
              ThreeManager.base.renderScene()
            }, 200)

            return ({
              dirtyPaths,
              dirtyDeletePaths,
              hidePaths,
              editElements: editVal,
              selectedDataArray,
              loopCounter,
              paths,
            })
          })
          .catch((error: any) => {
            dispatch(setError(`caster/apply`, error.status))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function saveAndParseXmlData (xmlData: XMLData, hasChanges = true, forceRebuildIds = false) {
  const parsedXmlData = cloneDeep(xmlData)
  const elementsHashes = {} as ElementsHashes
  const changeItemHash: ChangeItemHash = {}
  const ccElementHash: CCElementHash = {}

  actionNewData(parsedXmlData, elementsHashes, forceRebuildIds, changeItemHash, ccElementHash)
  const loopCounter = handleWaterFluxFraction(parsedXmlData)

  if (parsedXmlData.root.EditsBy3DCC) {
    delete parsedXmlData.root.EditsBy3DCC
  }

  delete parsedXmlData.root.Caster.StrandGuide
  const rootData = { ...parsedXmlData.root }

  return (dispatch: any, getState: any) => {
    dispatch(updateElementsHashes(elementsHashes, true))
    dispatch(updateModules(elementsHashes))
    dispatch({
      type: DataActionsEnum.ACTION_PARSE_XML_DATA,
      rootData,
      hasChanges,
      loopCounter,
    })

    dispatch({
      type: FilterActionsEnum.ACTION_SET_TERM,
      term: '',
    })

    const state: DefaultState = getState()

    if (Object.keys(changeItemHash).length || Object.keys(state.ChangeItem || {}).length) {
      dispatch(addChangeItems(changeItemHash))
    }

    if (Object.keys(ccElementHash).length || Object.keys(state.CCElement).length) {
      dispatch(setCCElements(ccElementHash))
    }
  }
}

function parseJsonXml (xmlData: RootData, out: { parsedJsonData: any }) {
  const parsedJsonData = { ...xmlData }

  parseXmlData(parsedJsonData)
  handleWaterFluxFraction(parsedJsonData)
  parsePasslineData(parsedJsonData.Caster.Passline)

  out.parsedJsonData = parsedJsonData

  return {
    type: DataActionsEnum.ACTION_PARSE_JSON_DATA,
    parsedJsonData,
  }
}

function saveCatalog (catalog: Catalog, catalogId?: string) {
  if (catalog) {
    catalog.shift()
  }

  return {
    type: DataActionsEnum.ACTION_SAVE_CATALOG,
    catalog,
    catalogId,
  }
}

function addDirtyPath (path: string | string[]) {
  return {
    type: DataActionsEnum.ACTION_ADD_DIRTY_PATH,
    path,
  }
}

function clearDirtyPaths () {
  return {
    type: DataActionsEnum.ACTION_CLEAR_DIRTY_PATHS,
  }
}

function canSelectElement (type: CasterElementNames, permissions: Record<string, boolean>) {
  switch (type) {
    case 'DataLine':
      return FeatureFlags.canSelectDataLine(permissions)
    case 'DataPoint':
      return FeatureFlags.canSelectDataPoint(permissions)
    case 'Nozzle':
      return FeatureFlags.canSelectNozzle(permissions)
    case 'Roller':
      return FeatureFlags.canSelectRoller(permissions)
    case 'RollerBody':
      return FeatureFlags.canSelectRollerBody(permissions)
    case 'RollerBearing':
      return FeatureFlags.canSelectRollerBearing(permissions)
    case 'SegmentGroup':
    case 'SupportPoint':
      return FeatureFlags.canSelectSupportPoint(permissions)
    default:
      // eslint-disable-next-line no-console
      console.log(`canSelectElement: ${type} not found`)

      return false
  }
}

function filterSelectedDataWithPermissions (
  selectedPaths: string[],
  permissions: Record<string, boolean>,
  elementsHashes: ElementsHashes,
) {
  const tempArray: string[] = []
  const sensorPointSelectionPermissions: any = {
    MoldFace: FeatureFlags.canSelectSensorPointInMoldFace(permissions),
    RollerBody: FeatureFlags.canSelectSensorPointInRollerBody(permissions),
    RollerBearing: FeatureFlags.canSelectSensorPointInRollerBearing(permissions),
    Roller: FeatureFlags.canSelectSensorPointInRoller(permissions),
    Segment: FeatureFlags.canSelectSensorPointInSegment(permissions),
    Nozzle: FeatureFlags.canSelectSensorPointInNozzle(permissions),
  }

  for (const path of selectedPaths) {
    if (!path) {
      continue
    }

    const { type, id } = ThreeUtil.getElementInfo(path)

    if (!type) {
      continue
    }

    if (type === 'SensorPoint') {
      const sensorPoint = elementsHashes.SensorPoint[id]

      if (!sensorPoint || !sensorPoint['#parent'] || !sensorPoint['#parent'].type) {
        continue
      }

      const parentType: string = sensorPoint['#parent'].type

      if (sensorPointSelectionPermissions[parentType]) {
        tempArray.push(path)
      }

      continue
    }

    if (canSelectElement(type, permissions)) {
      tempArray.push(path)
    }
  }

  return tempArray
}

function selectedMultiEditElements (selectedData?: any, multiSelect = false, massSelect = false) {
  const list = selectedData instanceof Array ? selectedData : [ selectedData ]

  return (dispatch: any, getState: any) => {
    const state: DefaultState = getState()

    // prevent multi select if segment group is/was selected
    const preventMultiSelect = [ ...list, ...state.data.selectedDataArray ]
      // TODO: this "?" is just a fallback we need to investigate why path is undefined!
      .some((path) => path?.startsWith('SegmentGroup:') && !path.includes('/'))

    multiSelect = multiSelect && !preventMultiSelect

    const { viewsObject, currentDashboard, amountOfComparisonCasterColumns } = state.visualization
    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const selectedComparisonCasters = viewObject?.selectedComparisonCasters || []
    const permissions = state.application.main.authenticationData.featureFlags || {}

    if (
      (amountOfComparisonCasterColumns && selectedComparisonCasters.length && selectedData?.length && (
        selectedData.length > 1 ||
        ((multiSelect || massSelect) && selectedData.length === 1 && state.data.selectedDataArray.length)
      )) ||
      !FeatureFlags.canSelectSomeElement(permissions)
    ) {
      return
    }

    const elementsHashes = {
      AirLoop: state.AirLoop,
      CoolingLoop: state.CoolingLoop,
      CoolingZone: state.CoolingZone,
      LoopAssignment: state.LoopAssignment,
      Nozzle: state.Nozzle,
      Roller: state.Roller,
      RollerBearing: state.RollerBearing,
      RollerBody: state.RollerBody,
      SupportPoint: state.SupportPoint,
      SegmentGroupSupportPoints: state.SupportPoint,
      Segment: state.Segment,
      SegmentGroup: state.SegmentGroup,
      SensorPoint: state.SensorPoint,
      StrandGuide: state.StrandGuide,
      DataPoint: state.DataPoint,
      DataLine: state.DataLine,
    }
    const filteredSelectedData = selectedData
      ? filterSelectedDataWithPermissions(list, permissions, elementsHashes)
      : []

    const validElements = filteredSelectedData.filter(element => Boolean(element))

    if (validElements.length && !state.application.main.openDialogs.includes('CasterDialog')) {
      dispatch({
        type: ApplicationMainActionsEnum.ACTION_SET_OPEN_DIALOGS,
        dialogName: 'CasterDialog',
      })
    }

    dispatch({
      type: DataActionsEnum.ACTION_SELECT_EDIT_DATA,
      selectedData: selectedData ? validElements : selectedData,
      multiSelect,
      massSelect,
    })
  }
}

function setElements (path: string | string[], element?: any) {
  return (dispatch: any, getState: any) => {
    const state: DefaultState = getState()
    const elementsHashes = {
      AirLoop: state.AirLoop,
      CoolingLoop: state.CoolingLoop,
      CoolingZone: state.CoolingZone,
      LoopAssignment: state.LoopAssignment,
      Nozzle: state.Nozzle,
      Roller: state.Roller,
      RollerBearing: state.RollerBearing,
      RollerBody: state.RollerBody,
      SupportPoint: state.SupportPoint,
      Segment: state.Segment,
      SegmentGroup: state.SegmentGroup,
      SensorPoint: state.SensorPoint,
      StrandGuide: state.StrandGuide,
      DataPoint: state.DataPoint,
      DataLine: state.DataLine,
    }

    dispatch({
      type: DataActionsEnum.ACTION_SET_ELEMENTS,
      path,
      element,
      elementsHashes,
    })
  }
}

function setParentPath (parentPath = {}) {
  return {
    type: DataActionsEnum.ACTION_SET_PARENT_PATH,
    parentPath,
  }
}

function setNewCopiedElementsToDraw (newCopiedElementsToDraw: boolean) {
  return {
    type: DataActionsEnum.ACTION_SET_NEW_ELEMENTS_TO_DRAW,
    newCopiedElementsToDraw,
  }
}

function deleteElements (path: string[]) {
  return (dispatch: any, getState: any) => {
    const state = getState()
    const elementsHashes = {
      AirLoop: state.AirLoop,
      CoolingLoop: state.CoolingLoop,
      CoolingZone: state.CoolingZone,
      LoopAssignment: state.LoopAssignment,
      Nozzle: state.Nozzle,
      Roller: state.Roller,
      RollerBearing: state.RollerBearing,
      RollerBody: state.RollerBody,
      SupportPoint: state.SupportPoint,
      SegmentGroupSupportPoints: state.SupportPoint,
      Segment: state.Segment,
      SegmentGroup: state.SegmentGroup,
      SensorPoint: state.SensorPoint,
      StrandGuide: state.StrandGuide,
      DataPoint: state.DataPoint,
      DataLine: state.DataLine,
    }

    deleteListFunction(path, elementsHashes)
    // const [ type, id ] = path.substring(path.lastIndexOf('/') + 1).split(':')
    // deleteElement(type,parseInt(id)) //TODO: DONT BUILD EVERYTHING
    dispatch(updateElementsHashes(elementsHashes))
    // dispatch({
    //   type: DataActionsEnum.ACTION_DELETE_ELEMENTS,
    // })
  }
}

function createElement (element: any, targetArray: Array<string>, elementType: string) {
  const newElement = getDefaultElement(element, elementType)
  const path = Util.getPathFromPathArray(targetArray)

  if (Object.keys(newElement).includes('parentPath')) {
    delete newElement.parentPath
  }

  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: (
      client: any,
      {
        data,
        application: { main: { currentSimulationCase, authenticationData } },
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }: DefaultState,
      dispatch: any,
    ) => {
      dispatch(setCreateLoadingCursor(elementType))

      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise.resolve({
          addedElements: {
            [`${path}/${elementType}:-1`]: newElement,
          },
          elementType,
        }).then(result => {
          dispatch(resetLoadingCursor())

          return result
        })
        : client
          .patch(`${Network.URI}/caster_data/${currentSimulationCase._id}`, {
            data: {
              data: {
                [`${path}/${elementType}:-1`]: newElement,
              },
              action: 'create',
            },
          })
          .then((result: any) => {
            dispatch(resetLoadingCursor())
            const elementsHashes = {
              AirLoop,
              CoolingLoop,
              CoolingZone,
              LoopAssignment,
              Nozzle,
              Roller,
              RollerBearing,
              RollerBody,
              SupportPoint,
              SegmentGroupSupportPoints: SupportPoint,
              Segment,
              SegmentGroup,
              SensorPoint,
              StrandGuide,
              DataPoint,
              DataLine,
            }

            const dirtyPaths = [ ...data.dirtyPaths ]
            const editElements: any = {}
            const selectedDataArray = [ ...data.selectedDataArray ]

            selectedDataArray.forEach(selectedPath => {
              editElements[selectedPath] = getElementFromPath(selectedPath, elementsHashes, true)
            })
            const rootData = { ...data.rootData }

            handleSuccessfullyAddedElements(
              result.addedElements,
              rootData,
              elementType as CasterElementNames,
              elementsHashes,
              editElements,
              dirtyPaths,
              selectedDataArray,
            )

            dispatch(updateElementsHashes(elementsHashes))
            dispatch(updateModules(elementsHashes))
            dispatch(setNewCopiedElementsToDraw(true))

            return ({
              ...result,
              rootData,
              dirtyPaths,
              selectedDataArray,
              hasChanges: true,
              editElements,
              editElementsInitial: editElements,
              elementType,
            })
          })
          .catch((error: any) => {
            dispatch(setError(`caster/create${elementType}`, error.status))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function copyElement (copyPath: string[], targetArray: any[], elementType: CasterElementNames) {
  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: (
      client: any,
      {
        application: { main: { currentSimulationCase, authenticationData } },
        data,
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }: DefaultState,
      dispatch: (action: any) => void,
    ) => {
      const elementsHashes: ElementsHashes = {
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        SegmentGroupSupportPoints: SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }

      dispatch(setCloneLoadingCursor(elementType))

      const { _passln_coord: diffPasslnCoord, _width_coord: diffWidthCoord } =
        getDifferenceElement(copyPath, data.editElements, elementsHashes)

      const addedElements = handleCopyPaths(
        copyPath,
        targetArray,
        elementsHashes,
        elementType,
        data.editElements,
        diffPasslnCoord,
        diffWidthCoord,
      )

      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise.resolve({
          addedElements: addedElements,
          elementType,
        }).then(result => {
          dispatch(resetLoadingCursor())

          return result
        })
        : client
          .patch(`${Network.URI}/caster_data/${currentSimulationCase._id}`, {
            data: {
              data: addedElements,
              action: 'create',
            },
          })
          .then((result: any) => {
            dispatch(resetLoadingCursor())
            // TODO: remove xmlData
            const dirtyPaths = [ ...data.dirtyPaths ]
            const editElements: any = {}
            const selectedDataArray = [ ...data.selectedDataArray ]

            selectedDataArray.forEach(selectedPath => {
              editElements[selectedPath] = getElementFromPath(selectedPath, elementsHashes, true)
            })
            const rootData = { ...data.rootData }

            handleSuccessfullyAddedElements(
              result.addedElements,
              rootData,
              elementType as CasterElementNames,
              elementsHashes,
              editElements,
              dirtyPaths,
              selectedDataArray,
            )

            dispatch(updateElementsHashes(elementsHashes, true))
            dispatch(updateModules(elementsHashes))
            dispatch(setNewCopiedElementsToDraw(true))

            return ({
              ...result,
              rootData,
              dirtyPaths,
              selectedDataArray,
              hasChanges: true,
              editElements,
              editElementsInitial: editElements,
              elementType,
            })
          })
          .catch((error: any) => {
            dispatch(setError(`caster/copy${elementType}`, error.status))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function addDirtyDeletePath (path: string | string[]) {
  return {
    type: DataActionsEnum.ACTION_ADD_DIRTY_DELETE_PATH,
    path,
  }
}

function clearDirtyDeletePaths () {
  return {
    type: DataActionsEnum.ACTION_CLEAR_DIRTY_DELETE_PATHS,
  }
}

function setActiveEditTab (activeEditTab: any) {
  return {
    type: DataActionsEnum.ACTION_SET_ACTIVE_EDIT_TAB,
    activeEditTab,
  }
}

function setEditChanges (hasEditChanges: boolean, elementType: string) {
  return {
    type: DataActionsEnum.ACTION_EDIT_CHANGES,
    hasEditChanges,
    elementType,
  }
}

function validateInput (elementType: string) {
  return {
    type: DataActionsEnum.ACTION_VALIDATION_INPUT,
    elementType,
  }
}

function setEditValues (editValues: any) {
  return {
    type: DataActionsEnum.ACTION_EDIT_VALUES,
    editValues,
  }
}

function setCatalogElement (element?: any) {
  return {
    type: DataActionsEnum.ACTION_SET_CATALOG_ELEMENT,
    element,
  }
}

function undoChanges (key: string | null, elementType: string) {
  return {
    type: DataActionsEnum.ACTION_UNDO_ELEMENT_CHANGES,
    key,
    elementType,
  }
}

function repeatElement (
  repeatPath: string[],
  targetArray: any[],
  elementType: CasterElementNames,
  amount: number,
  offset: number,
  mode: string,
) {
  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: (
      client: any,
      {
        application: { main: { currentSimulationCase, authenticationData } },
        data,
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }: DefaultState,
      dispatch: (action: any) => void,
    ) => {
      if (mode === 'mirror') {
        dispatch(setMirrorLoadingCursor(elementType))
      }
      else {
        dispatch(setPatternLoadingCursor(elementType))
      }

      const elementsHashes: ElementsHashes = {
        AirLoop,
        CoolingLoop,
        CoolingZone,
        LoopAssignment,
        Nozzle,
        Roller,
        RollerBearing,
        RollerBody,
        SupportPoint,
        SegmentGroupSupportPoints: SupportPoint,
        Segment,
        SegmentGroup,
        SensorPoint,
        StrandGuide,
        DataPoint,
        DataLine,
      }

      const addedElements = {}
      const { gapWarnings } = handleRepeatPaths(
        repeatPath,
        targetArray,
        elementsHashes,
        mode,
        addedElements,
        data.editElements,
        elementType,
        amount,
        offset,
      )

      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise.resolve({
          addedElements,
          elementType,
          gapWarnings,
        }).then(result => {
          dispatch(resetLoadingCursor())

          return result
        })
        : client
          .patch(`${Network.URI}/caster_data/${currentSimulationCase._id}`, {
            data: {
              data: addedElements,
              action: 'create',
            },
          })
          .then((result: any) => {
            dispatch(resetLoadingCursor())
            const dirtyPaths = [ ...data.dirtyPaths ]
            const editElements: any = {}
            const selectedDataArray = [ ...data.selectedDataArray ]

            selectedDataArray.forEach(selectedPath => {
              editElements[selectedPath] = getElementFromPath(selectedPath, elementsHashes, true)
            })
            const rootData = { ...data.rootData }

            handleSuccessfullyAddedElements(
              result.addedElements,
              rootData,
              elementType as CasterElementNames,
              elementsHashes,
              editElements,
              dirtyPaths,
              selectedDataArray,
            )

            dispatch(updateElementsHashes(elementsHashes, true))
            dispatch(updateModules(elementsHashes))
            dispatch(setNewCopiedElementsToDraw(true))

            return ({
              ...result,
              rootData,
              dirtyPaths,
              selectedDataArray,
              hasChanges: true,
              editElements,
              editElementsInitial: editElements,
              elementType,
              gapWarnings,
            })
          })
          .catch((error: any) => {
            dispatch(setError(`caster/${mode}${elementType}`, error.status))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function resetGapWarnings () {
  return {
    type: DataActionsEnum.ACTION_RESET_GAP_WARNINGS,
  }
}

// TODO: where is this used and does it work?
function overwriteAll (dataTypes: CasterElementNames[], newData: any) {
  return {
    types: [
      DataActionsEnum.ACTION_OVERWRITE_ALL_REQUEST,
      DataActionsEnum.ACTION_OVERWRITE_ALL_SUCCESS,
      DataActionsEnum.ACTION_OVERWRITE_ALL_ERROR,
    ],
    promise: (client: any, { application }: DefaultState, dispatch: any) => {
      return client.get(`${Network.URI}/caster_data_json/${application.main.currentSimulationCase._id}?r=5`)
        .then((xmlData: XMLData) => {
          const newXmlData = cloneDeep(newData)
          let elementsHashes = {} as ElementsHashes
          const loopCounter = {}

          // TODO: remove xmlData
          buildElementsHashes(xmlData, elementsHashes)
          actionOverwriteAll(xmlData, elementsHashes, dataTypes, newXmlData)
          elementsHashes = {} as ElementsHashes
          buildElementsHashes(xmlData, elementsHashes)
          countLoopIndex(loopCounter, elementsHashes)

          return Promise.resolve({
            xmlData, elementsHashes,
          }).then(() => {
            dispatch(updateElementsHashes(elementsHashes, true))
            dispatch(updateModules(elementsHashes))

            return {
              loopCounter,
              newData,
            }
          })
        })
        .catch((error: any) => {
          dispatch(setError(`caster/overwriteAll`, error.status))
          dispatch(setEditChanges(false, 'General'))
          dispatch(resetLoadingCursor())

          throw new Error(error)
        })
    },
  }
}

function mergeNew (dataTypes: CasterElementNames[], newData: any) {
  return {
    types: [
      DataActionsEnum.ACTION_MERGE_NEW_REQUEST,
      DataActionsEnum.ACTION_MERGE_NEW_SUCCESS,
      DataActionsEnum.ACTION_MERGE_NEW_ERROR,
    ],
    // eslint-disable-next-line @typescript-eslint/ban-types
    promise: (client: any, { application }: DefaultState, dispatch: Function) => {
      return client.get(`${Network.URI}/caster_data_json/${application.main.currentSimulationCase._id}?r=6`)
        .then((xmlData: XMLData) => {
          const newXmlData = cloneDeep(newData)
          const elementsHashes = {} as ElementsHashes

          buildElementsHashes(xmlData, elementsHashes)
          actionMergeNew(xmlData, dataTypes, newXmlData)
          const loopCounter = handleWaterFluxFraction(xmlData)

          return (
            Promise.resolve({
              xmlData,
              loopCounter,
            }).then(result => {
              const elementsHashes = {} as ElementsHashes

              buildElementsHashes(xmlData, elementsHashes)
              dispatch(updateElementsHashes(elementsHashes, true)) // TODO: update only changed elements
              dispatch(updateModules(elementsHashes))

              return result
            })
          )
        })
        .catch((error: any) => {
          dispatch(setError(`caster/mergeNew`, error.status))
          // dispatch(setEditChanges(false, 'General'))
          // dispatch(resetLoadingCursor())

          throw new Error(error)
        })
    },
  }
}

function setAdditionalData (additionalData: any) {
  return {
    types: [
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_REQUEST,
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_SUCCESS,
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_ERROR,
    ],
    promise: (
      client: any,
      { application: { main: { currentSimulationCase, authenticationData } }, data }: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      dispatch: Function,
    ) => {
      dispatch(setApplyLoadingCursor('General'))

      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise.resolve({
          additionalData,
        }).then(result => {
          dispatch(setEditChanges(false, 'General'))
          dispatch(resetLoadingCursor())

          return result
        })
        : client
          .patch(`${Network.URI}/caster_data/${currentSimulationCase._id}/general`, {
            data: additionalData,
          })
          .then((result: any) => {
            dispatch(setEditChanges(false, 'General'))
            dispatch(resetLoadingCursor())

            let rootData: any = null

            if (data.rootData) {
              rootData = cloneDeep(data.rootData)
              const { Mold: MoldData } = additionalData || {}

              Object.keys(MoldData).forEach(key => {
                (rootData.Caster.Mold as any)[key] = MoldData[key] || undefined
              })
            }

            return ({
              ...result,
              additionalData,
              rootData,
            })
          })
          .catch((error: any) => {
            dispatch(setError(`caster/create${'general'}`, error.status))
            dispatch(setEditChanges(false, 'General'))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function applyChanges (changes: Array<any>) {
  return {
    types: [
      DataActionsEnum.ACTION_APPLY_CHANGES_REQUEST,
      DataActionsEnum.ACTION_APPLY_CHANGES_SUCCESS,
      DataActionsEnum.ACTION_APPLY_CHANGES_ERROR,
    ],
    promise: (
      client: any,
      { data, application }: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      dispatch: Function,
    ) => {
      return client.get(`${Network.URI}/caster_data_json/${application.main.currentSimulationCase._id}?r=7`)
        .then((xmlData: XMLData) => {
          const changesToApply = [ ...changes ]
          const selectedDataArray = [ ...data.selectedDataArray ]
          const dirtyPaths = [ ...data.dirtyPaths ]
          const dirtyDeletePaths = [ ...data.dirtyDeletePaths ]
          const hidePaths = [ ...data.hidePaths ]
          const elementsHashes = {} as ElementsHashes

          buildElementsHashes(xmlData, elementsHashes)

          changesToApply.forEach(changes => handleChangeType(
            changes,
            elementsHashes,
            dirtyDeletePaths,
            dirtyPaths,
            selectedDataArray,
            hidePaths,
            xmlData,
          ))

          dispatch(updateElementsHashes(elementsHashes, true))
          dispatch(updateModules(elementsHashes))

          return {
            dirtyPaths,
            hidePaths,
            dirtyDeletePaths,
          }
        })
        .catch((error: any) => {
          dispatch(setError(`caster/applyChanges`, error.status))
          // dispatch(setEditChanges(false, 'General'))
          // dispatch(resetLoadingCursor())

          throw new Error(error)
        })
    },
  }
}

function setCurrentCatalogId (currentCatalogId?: string) {
  return {
    type: DataActionsEnum.ACTION_SET_CURRENT_CATALOG_ID,
    currentCatalogId,
  }
}

function updateNotification (updatesCount: number, clear = false) {
  return {
    type: DataActionsEnum.ACTION_SET_UPDATE_NOTIFICATIONS,
    updatesCount,
    clear,
  }
}

function setLiveMode (liveMode?: boolean) {
  return {
    type: DataActionsEnum.ACTION_SET_LIVE_MODE,
    liveMode,
  }
}

function removeDeletePaths (paths: Array<string>) {
  return {
    type: DataActionsEnum.ACTION_REMOVE_DELETE_PATHS,
    paths,
  }
}

export function getExecutableDefinitions () {
  return {
    types: [
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_REQUEST,
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS,
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_ERROR,
    ],
    promise: (
      client: any,
      _state: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      _dispatch: Function,
    ) => {
      return client.get(`${Network.URI}/executables/definitions`)
        .then(({ definitions }: { definitions: ExecutableDefinition[] }) => {
          return {
            executableDefinitions: definitions,
          }
        })
        .catch((error: any) => {
          throw new Error(error)
        })
    },
  }
}

export function setExecutionState (simulationCaseId: string, definitionId: string, caseId: string, state: string) {
  return {
    type: DataActionsEnum.ACTION_SET_EXECUTION_STATE,
    simulationCaseId,
    definitionId,
    caseId,
    state,
  }
}

export function setManyExecutionStates (newValues: Record<string, string>) {
  return {
    type: DataActionsEnum.ACTION_SET_MANY_EXECUTION_STATES,
    newValues,
  }
}

export function executeExecutableDefinition (
  projectId: string,
  simulationCaseId: string,
  definitionId: string,
  caseId: string,
  parameterData: any[],
  uuid: string,
  newCaseData: any,
  lastUUIDsUsedInExecutables: string | string[],
  executionInputs = {},
  stepNumber?: number,
  chosenCaseOption?: number,
) {
  return {
    types: [
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_REQUEST,
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_SUCCESS,
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_ERROR,
    ],
    promise: (
      client: any,
      _state: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      _dispatch: Function,
    ) => {
      return client.post(
        `${Network.URI}/executables/execute`,
        {
          data: {
            projectId,
            simulationCaseId,
            definitionId,
            caseId,
            parameterData,
            uuid,
            newCaseData,
            executionInputs,
            stepNumber,
            chosenCaseOption,
            lastUUIDsUsedInExecutables,
          },
        },
      )
        .then((result: any) => {
          // console.log(result)

          // return {
          // }
        })
        .catch((error: any) => {
          // set execution state as done
          // dispatch(setExecutionState(simulationCaseId, definitionId, caseId, 'done'))

          throw new Error(error)
        })
    },
  }
}

export default {
  resetHasChanges,
  resetReducer,
  saveElement,
  createElement,
  saveAndParseXmlData,
  parseJsonXml,
  saveCatalog,
  addDirtyPath,
  clearDirtyPaths,
  selectedMultiEditElements,
  setElements,
  setParentPath,
  deleteElements,
  copyElement,
  addDirtyDeletePath,
  addPendingDeleteList,
  clearDirtyDeletePaths,
  setActiveEditTab,
  setEditChanges,
  validateInput,
  setEditValues,
  setCatalogElement,
  undoChanges,
  repeatElement,
  resetGapWarnings,
  overwriteAll,
  mergeNew,
  setAdditionalData,
  setCurrentCatalogId,
  updateNotification,
  setLiveMode,
  applyChanges,
  removeDeletePaths,
  setNewCopiedElementsToDraw,
}
