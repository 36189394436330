/* eslint-env browser */

import SVG from './SVG'
import FastBase from './FastBase'
import { AnalyzeTime } from 'Util'

export default class Shape extends FastBase {
  constructor () {
    super('', '', '')
  }

  @AnalyzeTime(0)
  _getLinePathD ({
    x,
    y,
    width,
    height,
    xOffset,
    yOffset,
  }: {
    [x: string]: number
  }) {
    if (!this.additionalData?.flipAxes) {
      return `M${x},${height - yOffset}L${x},${-yOffset}`
    }

    return `M${-xOffset},${y}L${width - xOffset},${y}`
  }

  @AnalyzeTime(0)
  _getAreaPathD ({
    x,
    y,
    x2,
    y2,
    width,
    height,
    xOffset,
    yOffset,
  }: {
    [x: string]: number
  }) {
    if (!this.additionalData?.flipAxes) {
      return `M${x},${height - yOffset}H${x2}V${-yOffset}H${x}Z`
    }

    return `M${-xOffset},${y}H${width - xOffset}V${y2}H${-xOffset}Z`
  }

  @AnalyzeTime(0)
  _getPathD (
    plot: HTMLElement,
    { x0, y0, x1, y1 }: Record<string, number>,
    type: string,
  ) {
    const bgRect = plot.getElementsByClassName('bglayer')[0].getBoundingClientRect()
    const svgRect = plot.querySelector('svg:first-of-type')?.getBoundingClientRect()
    const { width, height } = this._getMeta(plot)
    const xOffset = (svgRect?.x || 0) - bgRect.x
    const yOffset = (svgRect?.y || 0) - bgRect.y
    const x = this._calcX(x0, width) - xOffset
    const y = this._calcY(y0, height) - yOffset
    const x2 = this._calcX(x1, width) - xOffset
    const y2 = this._calcY(y1, height) - yOffset

    if (type === 'line') {
      return this._getLinePathD({ x, y, width, height, xOffset, yOffset })
    }

    return this._getAreaPathD({ x, y, x2, y2, width, height, xOffset, yOffset })
  }

  @AnalyzeTime(0)
  _getLineStyle ({
    opacity,
    line: { width, color },
  }: any) {
    return `opacity: ${opacity}; stroke: ${color}; stroke-opacity: 1; fill-opacity: 0; stroke-width: ${width}px;`
  }

  @AnalyzeTime(0)
  _getAreaStyle ({
    opacity,
    fillcolor,
  }: any) {
    return `opacity: ${opacity}; stroke-opacity: 0; fill: ${fillcolor}; fill-opacity: 1; stroke-width: 0px;`
  }

  @AnalyzeTime(0)
  _getOrCreatePath (plot: HTMLElement, index: number) {
    const container = plot.querySelector('.layer-above .shapelayer')
    let path = container?.querySelector(`.fast-update[data-index="${index}"]`)

    if (!path) {
      path = SVG.getPath()

      path.setAttributeNS(null, 'data-index', `${index}`)
      path.setAttributeNS(null, 'fill-rule', 'evenodd')
      path.setAttributeNS(null, 'class', 'fast-update')
      container?.appendChild(path)
    }

    return {
      container,
      path,
    }
  }

  @AnalyzeTime(0)
  _drawSingle (
    plot: HTMLElement,
    shape: any,
    index: number,
  ) {
    const style = shape.type === 'line' ? this._getLineStyle(shape) : this._getAreaStyle(shape)
    const { container, path } = this._getOrCreatePath(plot, index)
    const originalPath = container?.querySelector(`[data-index="${index}"]:not(.fast-update)`) as HTMLElement

    if (!path.getAttributeNS(null, 'clip-path') && originalPath) {
      originalPath.style.visibility = 'hidden'
      path.setAttributeNS(null, 'clip-path', originalPath?.getAttributeNS(null, 'clip-path') || '')
    }

    const d = this._getPathD(plot, shape, shape.type)

    path.setAttributeNS(null, 'style', style)
    path.setAttributeNS(null, 'd', d)
  }

  @AnalyzeTime(0)
  draw (
    plot: HTMLElement,
    data: any[],
    xDomain: Array<number>,
    yDomain: Array<number>,
    flipAxes: boolean,
    layout: any,
  ) {
    if (!layout.shapes) {
      return
    }

    this.additionalData = {
      xDomain,
      yDomain,
      flipAxes,
      traceCount: layout.shapes.length,
    }

    this._calcNeededValues(plot, data)
    layout.shapes.forEach((shape: any, index: number) =>
      this._drawSingle(plot, shape, index))
  }
}
