/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'
import { OptionsObject, withSnackbar } from 'notistack'

import Logic from 'react/dialogs/project/ProjectDataDialog/Logic'
import { Form } from '../../visualization/dashboard/Dialogs/DialogStyles'

import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import { AppState } from 'store/application/main/consts'
import * as ErrorActions from 'store/application/error/actions'
import * as ApplicationActions from 'store/application/main/actions'
import * as VisualizationActions from 'store/visualization/actions'
import * as FilterActions from 'store/filter/actions'

import BaseDialog from '../BaseDialog'
import Input from 'react/specific/Input'
import Button from 'react/components/Button/index'
import { EditConfigDialog } from './EditConfigDialog'
import IpcManager from '../../../IpcManager'
import { DefaultState } from 'types/state'
import FeatureFlags from 'react/FeatureFlags'
import { Info } from 'logic/Info'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  darkTheme: state.application.main.darkTheme,
  authenticationData: state.application.main.authenticationData,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  openDialog: ApplicationActions.openDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditConfigId: ApplicationActions.setEditConfigId,
  closeDialog: ApplicationActions.closeDialog,
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setAppState: ApplicationActions.setAppState,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  setDefaultCasterDashboardConfig: ApplicationActions.setDefaultCasterDashboardConfig,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  resetViewObject: VisualizationActions.resetViewObject,
  setError: ErrorActions.setError,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  loading: any,
  selectedConfig: string
};

const TRANS = 'selectDashboardDialog'

export class SelectDashboardDialog extends Component<Props, State> {
  static NAME = uuid()

  constructor (props: Props) {
    super(props)

    Logic.init(this)
  }

  state = {
    loading: {
      openVis: false,
    },
    selectedConfig: 'default',
  }

  componentDidMount () {
    this.handleInit()

    hotkeys('Escape', this.handleClose)

    const { visualizationMetaInformation } = this.props
    const { config } = (visualizationMetaInformation || {})[AppState.Caster] || {}

    this.setState({
      selectedConfig: config,
    })
  }

  componentDidUpdate () {
    this.handleInit()
  }

  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleInit = () => {};

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectDashboardDialog.NAME)
  };

  handleChangeConfig = (event: any) => {
    const { setError } = this.props
    const { value } = event.target

    if (value === 'add') {
      try {
        Logic.handleUploadVisualizationConfig(
          (type: string, data: any, _appState:any) => {
            this.setState({
              selectedConfig: data,
            })
          },
          setError,
          AppState.Caster,
        )
      }
      catch (e) {}

      return
    }

    this.setState({
      selectedConfig: value,
    })
  };

  handleLoadConfig = async () => {
    const {
      setError,
      setTerm,
      currentSimulationCase,
      setCurrentSimulationCase,
      setConfig,
      setDataSources,
      resetViewObject,
      setAppState,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      enqueueSnackbar,
    } = this.props
    let config = this.getSelectedConfig()

    this.setState({ loading: { openVis: true } })

    if (!config || config === 'default') {
      const formData = new FormData()
      const fileName = `config_${Date.now()}.json`

      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }

      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      formData.append('file', file)
      formData.append('simulationCaseId', currentSimulationCase._id)
      formData.append('appState', AppState.Caster.toString())

      const response = await ApiClient
        .post(`${Network.URI}/visualization_config`, { data: formData })
        .catch(({ status }) => {
          setError('openDashboard', status)
        })

      if (!response) {
        this.setState({ loading: { openVis: false } })

        return
      }

      setCurrentSimulationCase(response.simulationCase)

      config = (response.simulationCase.visualizationConfigList.slice(-1)[0] || {}).configId
    }

    resetViewObject()
    this.handleChangeConfig({ target: { value: config } })
    setVisualizationMetaInformation('config', config, AppState.Caster)
    setAppState(AppState.Caster)

    Info.setRecentlyUsedInfo({ configId: config })

    const { visualizationConfig } = await ApiClient
      .get(`${Network.URI}/visualization_config/${config}?rid=3&emptySelectedComparisonCasters=1`)
      .catch((error) => {
        setError('openDashboard', error.status)

        return {}
      })

    if (!visualizationConfig) {
      this.setState({ loading: { openVis: false } })

      return
    }

    if (!visualizationConfig.data.viewsObject) {
      enqueueSnackbar('Error opening dashboard, file could be malformed', { autoHideDuration: 3000, variant: 'error' })

      return
    }

    setConfig(visualizationConfig.data)
    setDataSources(visualizationConfig.dataSources)
    setTerm('')

    this.handleClose()

    setSimpleDashboardTabIndex(1)
  };

  handleDelete = (type: string, key: string) => {
    const { updateCurrentProjectContainer, currentSimulationCase } = this.props
    const namespace = 'visualization'
    const name = 'visualizationConfigList'

    const data = currentSimulationCase[name].filter(val => val.configId !== key)

    if (currentSimulationCase.defaultCasterDashboardConfigId === key) {
      this.handleSetDefault(key)
    }

    ApiClient
      .del(`${Network.URI}/${namespace}_${type}/${key}`)
      .then(() => updateCurrentProjectContainer(name, data))
  };

  handleDownloadConfig = async (key: string) => {
    const { darkTheme, currentSimulationCase: { visualizationConfigList } } = this.props

    const { visualizationConfig } = await ApiClient.get(`${Network.URI}/visualization_config/${key}?rid=4`)

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme, dataSources: visualizationConfig.dataSources }),
      (visualizationConfigList.filter(config => config.configId === key)[0] || {}).name,
    )
  };

  handleEditConfig = (key: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  };

  handleSetDefault = async (key: string) => {
    const { setDefaultCasterDashboardConfig, currentSimulationCase, enqueueSnackbar } = this.props
    const setUndefined = currentSimulationCase.defaultCasterDashboardConfigId === key

    try {
      await ApiClient
        .post(
      `${Network.URI}/visualization_config/set_default_dashboard_config/${currentSimulationCase._id}`,
      { data: { configId: setUndefined ? undefined : key } },
        )

      setDefaultCasterDashboardConfig(setUndefined ? undefined : key)
    }
    catch (error) {
      enqueueSnackbar(
        setUndefined ? 'Error removing default' : 'Error setting config as default',
        { autoHideDuration: 3000, variant: 'error' },
      )
    }
  }

  getSelectedConfig = () => {
    const { selectedConfig } = this.state
    const {
      currentSimulationCase: { visualizationConfigList },
      authenticationData: { casterDashboardConfigs },
    } = this.props

    const configs = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.Caster)

    if (casterDashboardConfigs) {
      configs.push(...casterDashboardConfigs.map(config => ({ configId: config.id }) as any))
    }

    return Logic.getSelectedConfig(selectedConfig, configs)
  };

  render () {
    const {
      t,
      currentSimulationCase: { visualizationConfigList, defaultCasterDashboardConfigId },
      visualizationMetaInformation,
      authenticationData,
      featureFlags,
    } = this.props

    const canUploadConfig = FeatureFlags.canUploadCasterDashboardConfig(featureFlags)
    const canCreateConfig = FeatureFlags.canCreateCasterDashboardConfig(featureFlags)
    const canEditConfig = FeatureFlags.canEditCasterDashboardConfig(featureFlags)
    const canDeleteConfig = FeatureFlags.canDeleteCasterDashboardConfig(featureFlags)
    const canDownloadConfig = FeatureFlags.canDownloadCasterDashboard(featureFlags)
    const defaultConfigSelector = canCreateConfig
      ? {
        value: t(`${TRANS}.config.default`),
        disabled: false,
      }
      : null

    const configs = (visualizationConfigList || []).filter(config => config.appState === AppState.Caster)
    const configSelectors = Logic.getSelectors(configs, 'configId', 'name', defaultConfigSelector)

    if (authenticationData.casterDashboardConfigs) {
      configSelectors.push(...authenticationData.casterDashboardConfigs.map(config => ({
        key: config.id,
        value: config.fileName,
        notRemovable: true,
      }) as any))
    }

    if (canUploadConfig) {
      configSelectors.push({
        key: 'add',
        value: t(`${TRANS}.config.add`),
        notRemovable: true,
      })
    }

    const { config } = (visualizationMetaInformation || {})[AppState.Caster] || {}
    const currentConfig = configSelectors.find(({ key }) => key === config)

    if (currentConfig) {
      currentConfig.value += ' (current)'
    }

    Logic.markDefaultDashboardConfig(
      configSelectors,
      defaultCasterDashboardConfigId,
      authenticationData.defaultCasterDashboardConfig?.id,
    )

    return (
      <BaseDialog
        title={t(`${TRANS}.title`)}
        icon='pe-7s-server'
        header={t(`${TRANS}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='config'
            type='select'
            title={FeatureFlags.canViewCasterDashboard(featureFlags) ? t(`${TRANS}.config.title`) : 'Unauthorized'}
            value={this.getSelectedConfig()}
            selectors={configSelectors}
            onChange={this.handleChangeConfig}
            onDelete={canDeleteConfig && this.handleDelete}
            onDownload={canDownloadConfig && this.handleDownloadConfig}
            onEdit={canEditConfig && this.handleEditConfig}
            disabled={!FeatureFlags.canViewCasterDashboard(featureFlags)}
            onSetDefault={this.handleSetDefault}
          />
          <Button onClick={this.handleLoadConfig}>
            {t(`${TRANS}.config.btn`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(SelectDashboardDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
