import React from 'react'
import TitleBarWrapper from './react/TitleBarWrapper'
import { Provider } from 'react-redux'
import * as ReactDOMClient from 'react-dom/client'

import Index from '../store/'

const container = document.getElementById('titlebar-content')

if (!container) {
  throw new Error('Could not find titlebar container')
}

const root = ReactDOMClient.createRoot(container)

const content = (
  <Provider store={Index.getStore()}>
    <TitleBarWrapper />
  </Provider>
)

root.render(content)
