export default class CalcUtil {
  static getMinMax (range: Array<number>): {
    min: number,
    max: number
  } {
    const min = Math.min(range[0], range[1])
    const max = Math.max(range[0], range[1])

    return { min, max }
  }

  static _getDataScale (definition: {
      valueRange: Array<number>,
      type: string
    }): number {
    const { valueRange, type } = definition

    if (type.includes('Float')) {
      return 1
    }

    const { min, max } = CalcUtil.getMinMax(valueRange)
    const range = max - min
    const targetRange = Math.pow(2, (new (window as {[key: string]: any})[type](1)).byteLength * 8)
    const scale = range / targetRange

    return scale
  }
}
