import { ArrayOfTranslations, Translation } from 'types/translation'
import { withSnackbar } from 'notistack'
import { connect, ConnectedProps } from 'react-redux'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import Button from 'react/components/Button'
import BaseDialog from 'react/dialogs/BaseDialog'
import { Form, Text } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import { v4 as uuid } from 'uuid'
import { DefaultState } from 'types/state'

import * as ApplicationActions from 'store/application/main/actions'
import IpcManager from 'IpcManager'

const PRE_TRANS = 'downloadXMLDialog'

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  params: state.application.main.params,
  executionStateDictionary: state.data.executionStateDictionary,
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar,
  t: Translation & ArrayOfTranslations
}

export class DownloadXMLDialog extends React.Component<Props> {
  static NAME = uuid()

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(DownloadXMLDialog.NAME)
  }

  handleDownloadXML = () => {
    IpcManager.send('saveCasterXML')
  }

  render () {
    const { params, t } = this.props
    const { name, downloadCasterDescription } = (params?.definition || {}) as ExecutableDefinition

    return (
      <BaseDialog
        title={name}
        icon='pe-7s-settings'
        header={name}
        onClose={this.handleClose}
        hasScroll
        small
        contentMinHeight={150}
        headerWidth='390px'
      >
        <Form minHeight={150}>
          <Text>
            {downloadCasterDescription || 'Download Caster XML'}
          </Text>
          <Button
            title={t(`${PRE_TRANS}.button.download`)}
            type='primary'
            onClick={this.handleDownloadXML}
          >
            {t(`${PRE_TRANS}.button.download`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(DownloadXMLDialog as any) as any) as any) as any
