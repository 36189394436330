import ErrorActions from './consts'

const initialState = {}

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [ErrorActions.SET_ERROR]: (state: ErrorState, { actionName, error }: {actionName?: string, error?: string}) => {
    if (actionName) {
      return {
        ...state,
        [actionName]: error,
      }
    }
    else {
      return {
        ...state,
      }
    }
  },

  [ErrorActions.RESET_ERROR]: () => ({
    ...initialState,
  }),
}

export default function (
  state: ErrorState = initialState,
  action: { type: ErrorActions, actionName?: string, error?: string},
) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}
