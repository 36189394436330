import { Network } from '../network/Network'
import ApiClient from '../store/apiClient'
import ProjectMatrixDialog from '../react/dialogs/ProjectMatrixDialog'
import IpcManager from '../IpcManager'
import { AppState } from '../store/application/main/consts'
import type { Props } from '.'

import { AnalyzeTime } from 'Util'
import ExecutableDialog from 'react/dialogs/executables/ExecutableDialog'
import { CasterColumnLogic } from 'react/dialogs/project/ProjectDataDialog/CasterColumnLogic'
import { getCurrentDashboardEntry } from './util'

export default class Handlers {
  props: Props
  handlers: Handlers
  setState: (state: any) => void

  constructor (args: { props: Props }) {
    this.props = args.props
    this.handlers = this
    this.setState = () => false
  }

  @AnalyzeTime(0)
  handleNetworkStatus (status: string) {
    const { networkStatus, storeNetworkStatus } = this.props

    if (networkStatus !== status) {
      storeNetworkStatus(status)
    }
  }

  @AnalyzeTime(0)
  handleMetaData (meta: any) {
    const { setPlotsMeta } = this.props

    setPlotsMeta(meta)
  }

  @AnalyzeTime(0)
  handleNotAuthenticated () {
    const { setAuthenticationData } = this.props

    setAuthenticationData()
  }

  handleShowMore () {
    const {
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      setAmountOfComparisonCasterColumns,
      viewsObject,
      currentDashboard,
      selectedDataArray,
      selectedMultiEditElements,
    } = this.props

    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const selectedComparisonCasters = viewObject?.selectedComparisonCasters || []

    if (amountOfComparisonCasterColumns >= selectedComparisonCasters.length) {
      return
    }

    if (amountOfComparisonCasterColumns) {
      setCurrentCasterDialogWidth(currentCasterDialogWidth + 77)
    }

    if (selectedDataArray.length > 1) {
      selectedMultiEditElements(selectedDataArray[0])
    }

    setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns + 1)
  }

  handleShowLess () {
    const {
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      setAmountOfComparisonCasterColumns,
    } = this.props

    if (!amountOfComparisonCasterColumns) {
      return
    }

    if (amountOfComparisonCasterColumns === 1) {
      setAmountOfComparisonCasterColumns(0)

      return
    }

    setCurrentCasterDialogWidth(currentCasterDialogWidth - 77)
    setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns - 1)
  }

  handleSetDefaultView () {
    const { setCurrentCasterDialogWidth, setAmountOfComparisonCasterColumns } = this.props

    setCurrentCasterDialogWidth(335)
    setAmountOfComparisonCasterColumns(0)
  }

  @AnalyzeTime(0)
  lockChangeCallback () {
    const { currentProject, currentSimulationCase, setCurrentProject, setCurrentSimulationCase } = this.props

    if (!currentProject || !currentProject._id) {
      return
    }

    ApiClient
      .get(`${Network.URI}/project/${currentProject._id}`)
      .then(({ project }) => {
        setCurrentProject(project)

        let simulationCase = null

        if (currentSimulationCase?._id) {
          const foundCase = project.simulationCases.find((simCase: any) => simCase._id === currentSimulationCase._id)

          if (foundCase) {
            simulationCase = foundCase
          }
        }

        if (simulationCase) {
          setCurrentSimulationCase(simulationCase)
        }
        else {
          // eslint-disable-next-line no-console
          console.warn('Could not find simulation case in project', currentProject._id, currentSimulationCase?._id)
        }
      })
  }

  @AnalyzeTime(0)
  simulationUpdateCallback ({ simulationCaseId, state }: {simulationCaseId: string, state: 'done' | 'data' }) {
    const { openAppDialogs, currentProject, currentSimulationCase, enqueueSnackbar, t } = this.props
    const { _id } = currentSimulationCase || {}

    if (state && (simulationCaseId === _id || openAppDialogs.includes(ProjectMatrixDialog.NAME))) {
      this.handlers.updateSimulationCase(simulationCaseId)
        .then(() => {
          if (state === 'data') {
            return enqueueSnackbar(t(`simulation.dataUpdate`), { autoHideDuration: 3000, variant: 'success' })
          }

          // state === 'done'
          enqueueSnackbar(t(`simulation.completed`), { autoHideDuration: 3000, variant: 'success' })
        })
        .catch(() => {
          enqueueSnackbar(t(`simulation.completionError`), { autoHideDuration: 4000, variant: 'error' })
        })
    }
    else if (state) { // this is only reached when the server sends an update for not selected simulation cases!
      const { name: scName } = currentProject?.simulationCases
        .find(simulationCase => simulationCase._id === simulationCaseId) || {}
      const name = scName || simulationCaseId || ''

      if (!name) {
        // eslint-disable-next-line no-console
        console.error('Could not get simulation case name')
      }

      if (state === 'data') {
        enqueueSnackbar(t(`simulation.dataUpdateOther`, { name }), { autoHideDuration: 4000, variant: 'success' })

        return
      }

      // state === 'done'
      enqueueSnackbar(t(`simulation.completedOther`, { name }), { autoHideDuration: 4000, variant: 'success' })
    }
  }

  @AnalyzeTime(0)
  updatePushCallback ({ changes }: { changes: Array<{action: string, elementType: string, data:any}> }) {
    const { updateNotification, applyChanges } = this.props

    updateNotification(0, true)

    if (changes && changes.length) {
      applyChanges(changes)

      if (changes.filter((change: {action: string, elementType: string, data:any}) =>
        /^(replace(Nozzles|Caster)|mergeNozzles)$/.test(change.action)).length) {
        // TODO: find a better way...
        IpcManager.app.setState({
          redraw: true,
        })
        setTimeout(() => {
          IpcManager.app.setState({
            redraw: false,
          })
        }, 500)
      }
    }
  }

  @AnalyzeTime(0)
  updateNotificationCallback ({ updatesCount }: { updatesCount: number }) {
    const { updateNotification } = this.props

    updateNotification(updatesCount)
  }

  @AnalyzeTime(0)
  onCloseCallback () {
    const { enqueueSnackbar, t } = this.props

    enqueueSnackbar(
      t('reconnecting'),
      { autoHideDuration: 15000, variant: 'info', key: 'reconnecting', preventDuplicate: true },
    )
  }

  @AnalyzeTime(0)
  onOpenCallback () {
    const {
      closeSnackbar,
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentSimulationCase,
    } = this.props

    closeSnackbar('reconnecting')

    if (currentProject && currentProject._id) {
      const id = currentSimulationCase ? currentSimulationCase._id : 'none'

      ApiClient
        .get(`${Network.URI}/project/update/${currentProject._id}/${id}`)
        .then(({ project, simulationCase }: { project:Project, simulationCase:SimulationCase }) => {
          setCurrentProject(project)
          setCurrentSimulationCase(simulationCase)
        })
    }
  }

  @AnalyzeTime(0)
  generateDoneCallback ({ simulationCaseId, errorType }: { simulationCaseId: string, errorType?: string }) {
    const { currentSimulationCase, enqueueSnackbar, t, setAppState, resetReducer, setCurrentCasterRoot } = this.props

    if (errorType) {
      enqueueSnackbar(t(`generate.error.${errorType}`), { autoHideDuration: 4000, variant: 'error' })
    }

    if (currentSimulationCase && currentSimulationCase._id === simulationCaseId) {
      this.handlers.updateSimulationCase(simulationCaseId)
        .then(() => {
          if (!errorType) {
            setAppState(AppState.Caster)
            resetReducer(true, true)
            setCurrentCasterRoot()

            enqueueSnackbar(t(`generate.completed`), { autoHideDuration: 3000, variant: 'success' })
          }
        })
        .catch(() => enqueueSnackbar(t(`generate.completionError`), { autoHideDuration: 4000, variant: 'error' }))
    }
  }

  @AnalyzeTime(0)
  executionDoneCallback ({
    simulationCaseId,
    definitionId,
    caseId,
    errorType,
    newCaseId,
    loadNewCase,
    configId,
    configNotFoundError,
    errorMessage,
    tabIndex,
    chosenCaseOptionIndex,
    userName,
  }: {
    simulationCaseId: string,
    definitionId: string,
    caseId: string,
    errorType?: string
    newCaseId?: string
    loadNewCase?: boolean
    configId?: string
    configNotFoundError?: boolean
    errorMessage?: string
    tabIndex?: string
    chosenCaseOptionIndex: number
    userName: string
  }) {
    const {
      currentSimulationCase,
      enqueueSnackbar,
      t,
      setAppState,
      resetReducer,
      setExecutionState,
      executableDefinitions,
      visualizationMetaInformation,
      currentSimpleDashboardTabIndex,
      currentProject,
      authenticationData,
    } = this.props

    if (currentSimulationCase?._id !== simulationCaseId) {
      return
    }

    if (newCaseId && authenticationData.name !== userName) {
      return
    }

    let stepIndex: any, splitCaseId: string | undefined

    if (caseId.includes('-step')) {
      [ splitCaseId, stepIndex ] = caseId.split('-step')
      stepIndex = Number(stepIndex)
    }

    if (stepIndex !== undefined && isNaN(stepIndex)) {
      return
    }

    let autoLoad = false
    const currentTab = currentSimpleDashboardTabIndex

    const executableDefinition: ExecutableDefinition = executableDefinitions
      .find(definition => definition.id === definitionId)

    // could not use case because it is a reserved word
    let chosenOption: any, currentStep: any
    const currentCase: any = (executableDefinition.cases || []).find(defCase => {
      if (defCase.id === splitCaseId && defCase.steps) {
        return true
      }

      return defCase.id === caseId
    })

    if (currentCase?.steps) {
      currentStep = currentCase.steps[stepIndex]

      if (currentStep.caseOptions) {
        chosenOption = currentStep.caseOptions[chosenCaseOptionIndex]
      }
    }

    const selection = chosenOption?.selectionFilter ||
      currentStep?.selectionFilter ||
      currentCase?.selectionFilter ||
      ''
    const param = (chosenOption?.parameters || currentStep?.parameters || currentCase?.parameters || [])
      .find((param: any) => param.value === 'caster.xml')

    if (param) {
      if (param.autoLoad3D !== false) {
        autoLoad = true
      }
    }

    if (errorType) {
      if (errorType === 'file_not_found') {
        enqueueSnackbar(errorMessage, { autoHideDuration: 4000, variant: 'error' })
      }
      else {
        enqueueSnackbar(t(`execution.error.${errorType}`), { autoHideDuration: 4000, variant: 'error' })
      }

      setExecutionState(simulationCaseId, definitionId, caseId, 'error')

      return
    }

    setExecutionState(simulationCaseId, definitionId, caseId, 'done')

    if (currentSimulationCase && currentSimulationCase._id === simulationCaseId) {
      if (newCaseId) {
        // update current project to add new simulation case to list
        this.handlers.updateProject(currentProject._id)

        if (!loadNewCase) {
          enqueueSnackbar(t(`execution.completed`), { autoHideDuration: 3000, variant: 'success' })
          this.handlers.updateSimulationCase(simulationCaseId)
            .then(() => {
              // do nothing
            })
            .catch(() => enqueueSnackbar(t(`execution.completionError`), { autoHideDuration: 4000, variant: 'error' }))

          return
        }

        this.handlers.updateSimulationCase(newCaseId)
          .then(() => {
            if (!errorType) {
              enqueueSnackbar(t(`execution.completed`), { autoHideDuration: 3000, variant: 'success' })

              if (autoLoad) {
                resetReducer(false, true)
                setAppState(AppState.Caster)

                this.handlers.handleOpenCaster(
                  newCaseId,
                  false,
                  visualizationMetaInformation,
                  currentTab,
                  selection,
                  { configId, tabIndex },
                  configNotFoundError,
                )
              }
            }
          })
          .catch(() => enqueueSnackbar(t(`execution.completionError`), { autoHideDuration: 4000, variant: 'error' }))
      }
      else {
        this.handlers.updateSimulationCase(simulationCaseId)
          .then(() => {
            if (!errorType) {
              enqueueSnackbar(t(`execution.completed`), { autoHideDuration: 3000, variant: 'success' })

              if (autoLoad) {
                resetReducer(false, true)
                setAppState(AppState.Caster)
                this.handlers.handleOpenCaster(
                  simulationCaseId,
                  true,
                  visualizationMetaInformation,
                  currentTab,
                  selection,
                  { configId, tabIndex },
                  configNotFoundError,
                )
              }
            }
          })
          .catch(() => enqueueSnackbar(t(`execution.completionError`), { autoHideDuration: 4000, variant: 'error' }))
      }
    }
  }

  async handleOpenCaster (
    simulationCaseId: string,
    avoidVisualization = false,
    visualizationMetaInformation?: any,
    currentTab?: number,
    selection?: string,
    { configId, tabIndex }: {configId?: string, tabIndex?: string} = {},
    configNotFoundError = false,
  ) {
    const {
      setAppState,
      setCurrentCasterRoot,
      setError,
      closeDialog,
      setConfig,
      setDataSources,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      currentSimulationCase,
      appState,
      enqueueSnackbar,
      currentProject,
      addComparisonCaster,
      featureFlags,
      authenticationData,
    } = this.props

    this.setState({ loading: { openCaster: true } })
    const { root } = await ApiClient
      .get(`${Network.URI}/caster_data_json/${simulationCaseId}?r=2`)
      .catch(({ error }) => {
        setError('openCaster', error.status)
        this.setState({ loading: { openCaster: false } })

        return {}
      })

    if (root) {
      setAppState(AppState.Caster)

      setCurrentCasterRoot(root)

      IpcManager.both.send('loading', null, true, true)
      setTimeout(async () => {
        if (selection) {
          (this as any).selectionQueue = selection
        }

        IpcManager.internal.send(
          'data',
          null,
          { root },
          true,
          null,
          null,
        )

        const { id: defaultCasterDashboardConfigId } = await ApiClient
          .get(`${Network.URI}/visualization_config/get_default_dashboard_config_id/${simulationCaseId}`)
        const defaultConfigId = defaultCasterDashboardConfigId ??
      authenticationData.defaultCasterDashboardConfig?.id

        if (avoidVisualization && !configId && !defaultConfigId) {
          await CasterColumnLogic
            .handleLoadCasterDashboard({
              featureFlags,
              setConfig,
              setDataSources,
              visualizationMetaInformation,
              setSimpleDashboardTabIndex,
              currentSimpleDashboardTabIndex: currentTab,
            } as any)
        }
        else if (configId) {
          await CasterColumnLogic
            .handleLoadCasterConfigWithId({
              featureFlags,
              setConfig,
              setDataSources,
              setSimpleDashboardTabIndex,
              setVisualizationMetaInformation,
              currentSimulationCase,
              appState,
              currentProject,
              addComparisonCaster,
              authenticationData,
            } as any, configId, tabIndex || '')
        }
        else if (defaultConfigId && !configNotFoundError) {
          await CasterColumnLogic
            .handleLoadCasterConfigWithId({
              featureFlags,
              setConfig,
              setDataSources,
              setSimpleDashboardTabIndex,
              setVisualizationMetaInformation,
              currentSimulationCase,
              appState,
              currentProject,
              addComparisonCaster,
              authenticationData,
            } as any, defaultConfigId, tabIndex || '')
        }

        if (configNotFoundError) {
          enqueueSnackbar(
            'Config specified in executable definition not found',
            { autoHideDuration: 4000, variant: 'error' },
          )
        }
      }, 100)
    }

    closeDialog(ExecutableDialog.NAME)
    this.setState({ loading: { openCaster: false } })
  }

  @AnalyzeTime(0)
  handleFullScreenNotification (isFullScreen: boolean) {
    const { enqueueSnackbar, closeSnackbar, t } = this.props

    if (!isFullScreen || !window.isElectron) {
      return closeSnackbar('exitFullScreen')
    }

    enqueueSnackbar(t('howToExitFullScreen'), { autoHideDuration: 3000, preventDuplicate: true, key: 'exitFullScreen' })
  }

  @AnalyzeTime(0)
  handleLockCasterRequest (lock: boolean) {
    const {
      authenticationData,
      currentSimulationCase,
      setCurrentSimulationCase,
    } = this.props

    if (!authenticationData || !authenticationData.featureFlags) {
      return
    }

    const id = lock ? currentSimulationCase?._id : ''

    ApiClient.post(`${Network.URI}/caster_lock/${id}`)
      .then(({ simulationCase }) => {
        if (simulationCase && simulationCase._id) {
          setCurrentSimulationCase(simulationCase)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  @AnalyzeTime(0)
  handleLockCaster (prevProps?: Props) {
    const { appState, rootData, currentSimulationCase } = this.props

    // if currentSimulationCase changes
    if (
      prevProps && prevProps.currentSimulationCase && prevProps.currentSimulationCase._id &&
      (!currentSimulationCase || !currentSimulationCase._id)
    ) {
      this.handlers.handleLockCasterRequest(false)

      return
    }

    // if there is no data don't bother going ahead
    if (!rootData.Caster) {
      return
    }

    if (!prevProps) {
      // this is true when the page/app is reloaded and the caster is open and the caster data is available
      if (appState === AppState.Caster) {
        this.handlers.handleLockCasterRequest(true)
      }

      return
    }

    const { appState: wasDashboardOpened, rootData: prevRootData } = prevProps

    // if the caster gets data
    // or the caster is opened and has data
    if (!prevRootData.Caster || (wasDashboardOpened !== AppState.Caster && appState === AppState.Caster)) {
      this.handlers.handleLockCasterRequest(true)
    }

    // if the caster is closed and has data
    if (wasDashboardOpened === AppState.Caster && appState !== AppState.Caster) {
      this.handlers.handleLockCasterRequest(false)
    }
  }

  @AnalyzeTime(0)
  handleParse () {
    const { parseJsonXml, rootData } = this.props
    const out = { parsedJsonData: {} }

    parseJsonXml(rootData, out)

    this.handlers.handleGetData(out.parsedJsonData)
  }

  @AnalyzeTime(0)
  handleGetData (parsedJsonData: any) {
    const {
      deleteElements,
      dirtyDeletePaths,
      selectedDataArray,
      clearDirtyDeletePaths,
      addDirtyPath,
      selectedMultiEditElements,
      resetHasChanges,
    } = this.props

    const list = selectedDataArray.filter(path => ~dirtyDeletePaths.indexOf(path))

    deleteElements(dirtyDeletePaths)
    clearDirtyDeletePaths()
    addDirtyPath(dirtyDeletePaths)
    selectedMultiEditElements(list, true)
    resetHasChanges()

    if (parsedJsonData && parsedJsonData.root) {
      IpcManager.both.send('dataFromReact', parsedJsonData)
    }
  }

  @AnalyzeTime(0)
  handleClose (target: string) {
    const { setOpenDialogs } = this.props

    setOpenDialogs(target)
  }

  @AnalyzeTime(0)
  updateSimulationCase (simulationCaseId: string) {
    const { setCurrentSimulationCase } = this.props

    return ApiClient
      .get(`${Network.URI}/simulation_case/${simulationCaseId}`)
      .then(({ simulationCase }) => {
        setCurrentSimulationCase(simulationCase)
      })
  }

  @AnalyzeTime(0)
  updateProject (projectId: string) {
    const { setCurrentProject } = this.props

    return ApiClient
      .get(`${Network.URI}/project/${projectId}`)
      .then(({ project }) => {
        setCurrentProject(project)
      })
  }
}
