import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'clone-deep'

import * as ErrorActions from 'store/application/error/actions'
import * as ApplicationActions from 'store/application/main/actions'
import * as VisualizationActions from 'store/visualization/actions'
import * as ComparisonCastersActions from 'store/comparison-casters'
import DataActions from 'store/data/actions'
import { AppState } from 'store/application/main/consts'

import Input from '../../../specific/Input'

import ProjectDataDialog from '../ProjectDataDialog'
import EditProjectDialog from '../EditProjectDialog'

import BaseDialog from '../../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import NewProjectDialog from '../NewProjectDialog'

import { Empty, Loading, InfoMessage, Description, Spacer, Label } from './Styles'
import { Button, Form } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import FeatureFlags from '../../../FeatureFlags'
import TimeUtil from 'logic/TimeUtil'

import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import NewSimulationCaseDialog from '../NewSimulationCaseDialog'
import EditSimulationCaseDialog from '../EditSimulationCaseDialog'
import { OptionsObject, withSnackbar } from 'notistack'
import IpcManager from 'IpcManager'
import { CasterColumnLogic } from '../ProjectDataDialog/CasterColumnLogic'
import { Info } from 'logic/Info'
import { parseJWT } from 'Util/authUtil'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  authenticationData: state.application.main.authenticationData,
}), {
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  switchProject: ApplicationActions.switchProject,
  setEditProjectId: ApplicationActions.setEditProjectId,
  setEditSimulationCaseId: ApplicationActions.setEditSimulationCaseId,
  setAppState: ApplicationActions.setAppState,
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  setSimpleDashboardTabIndex: ApplicationActions.setSimpleDashboardTabIndex,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  addComparisonCaster: ComparisonCastersActions.addComparisonCaster,
  resetReducer: DataActions.resetReducer,
  setError: ErrorActions.setError,
  saveCatalog: DataActions.saveCatalog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar (message: string | React.ReactNode, options?: OptionsObject) : OptionsObject['key'] | null;
  t: Translation & ArrayOfTranslations
}

type State = {
  projectId: string | undefined | null,
  simulationCaseId: string | undefined | null,
  projects: any[],
  simulationCases: SimulationCase[],
  loading: boolean,
  error: string
}

const PRE_TRANS = 'OpenProjectDialog'

export class OpenProjectDialog extends Component<Props, State> {
  static NAME = uuid()

  state:State = {
    projectId: null,
    simulationCaseId: null,
    projects: [],
    simulationCases: [],
    loading: false,
    error: '',
  }

  componentDidMount () {
    const { currentProject, currentSimulationCase } = this.props

    this.setState({ projectId: currentProject?._id, simulationCaseId: currentSimulationCase?._id })

    this.handleUpdateList(currentProject?._id)

    hotkeys('Escape', this.handleClose)
  }

  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  handleUpdateList = (forceProjectId?: string) => {
    const { openDialog } = this.props

    this.setState({ loading: true })

    ApiClient
      .get(
        `${Network.URI}/projects`,
        { params: { isDefault: true, lastLoadedCasterCatalogId: true } },
      )
      .then(({ projects }) => {
        if (projects.length > 0) {
          const projectId = forceProjectId || this.state.projectId || projects[0]._id

          this.setState({ projects, loading: false, projectId })

          this.handleUpdateSimulationCases(projectId)
        }
        else {
          this.setState({ loading: false })
          openDialog(NewProjectDialog.NAME)
        }
      })
      .catch(({ status }) => {
        this.setState({
          loading: false,
          error: status,
        })
      })
  }

  handleUpdateSimulationCases = (pId?: string) => {
    const { projectId, projects } = this.state

    this.setState({ loading: true })

    ApiClient
      .get(`${Network.URI}/project/${pId || projectId || projects[0]?._id}`)
      .then(({ project }) => {
        if (!project) {
          this.setState({ loading: false })

          return
        }

        this.setState({ simulationCases: project.simulationCases, loading: false })
      })
      .catch(({ status }) => {
        this.setState({
          loading: false,
          error: status,
        })
      })
  }

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(OpenProjectDialog.NAME)
  }

  handleCreateDialog = () => {
    const { openDialog } = this.props

    openDialog(NewProjectDialog.NAME)
  }

  handleCreateSimulationCaseDialog = () => {
    const { projects, simulationCases, projectId } = this.state
    const { openDialog, setCurrentProject } = this.props

    const project = cloneDeep(projects.find(p => p._id === projectId))

    project.simulationCases = cloneDeep(simulationCases)

    setCurrentProject(project)

    openDialog(NewSimulationCaseDialog.NAME)
  }

  handleChange = (event: any) => {
    if (event.target.value === 'add') {
      this.handleCreateDialog()

      return
    }

    this.setState({
      projectId: event.target.value,
      simulationCaseId: null,
    })

    this.handleUpdateSimulationCases(event.target.value)
  }

  handleChangeSimulationCase = (event: any) => {
    if (event.target.value === 'add') {
      this.handleCreateSimulationCaseDialog()

      return
    }

    this.setState({
      simulationCaseId: event.target.value,
    })
  }

  handleDelete = (type: string, key: string) => {
    const { enqueueSnackbar } = this.props

    ApiClient
      .del(`${Network.URI}/project/${key}`)
      .then(() => {
        this.handleUpdateList()
      })
      .catch(() => {
        enqueueSnackbar('Error deleting project', { variant: 'error', autoHideDuration: 3000 })
      })
  }

  handleDeleteSimulationCase = (type: string, key: string) => {
    const { projectId, projects, simulationCases } = this.state
    const {
      currentSimulationCase,
      resetReducer,
      setCurrentProject,
      setCurrentSimulationCase,
      enqueueSnackbar,
    } = this.props

    ApiClient
      .del(`${Network.URI}/simulation_case/${key}`)
      .then(() => {
        const project = cloneDeep(projects.find(p => p._id === projectId))

        project.simulationCases = cloneDeep(simulationCases.filter(s => s._id !== key))

        setCurrentProject(project)

        if (currentSimulationCase?._id === key) {
          resetReducer()
          setCurrentSimulationCase(project.simulationCases[0])
        }

        this.setState({ simulationCaseId: null, simulationCases: project.simulationCases })
      })
      .catch((error) => {
        enqueueSnackbar('Error deleting project', { variant: 'error', autoHideDuration: 3000 })
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  handleOpen = async (event: any) => {
    event.preventDefault()

    const { projectId, simulationCaseId, projects, simulationCases } = this.state
    const {
      currentProject,
      currentSimulationCase,
      setCurrentProject,
      setCurrentSimulationCase,
      openDialog,
      closeDialog,
      switchProject,
      featureFlags,
      enqueueSnackbar,
    } = this.props

    const slimVersion = FeatureFlags.usesSlimVersion(featureFlags)

    if (currentProject?._id === projectId && currentSimulationCase?._id === simulationCaseId) {
      closeDialog(OpenProjectDialog.NAME)

      if (!slimVersion) {
        openDialog(ProjectDataDialog.NAME)
      }
      else {
        this.handleLoadCaster()
      }

      return
    }

    const selectedProjectId = projectId || ((projects || [])[0] || {})._id

    try {
      const simulationCase = simulationCaseId
        ? simulationCases.find(({ _id }: { _id: string }) => _id === simulationCaseId)
        : simulationCases[0]

      const { project } = await ApiClient.get(`${Network.URI}/project/${selectedProjectId}`)

      if (!simulationCase) {
        enqueueSnackbar('Error loading project', { variant: 'error', autoHideDuration: 3000 })

        return
      }

      switchProject()
      setCurrentProject(project)
      setCurrentSimulationCase(simulationCase, slimVersion)

      if (!simulationCase.currentCasterRoot) {
        return
      }

      if (slimVersion) {
        this.handleLoadCaster(simulationCase)
      }
    }
    catch (error) {
      enqueueSnackbar('Error loading project', { variant: 'error', autoHideDuration: 3000 })
      // eslint-disable-next-line no-console
      console.log(error)

      return
    }

    closeDialog(OpenProjectDialog.NAME)

    if (!slimVersion) {
      openDialog(ProjectDataDialog.NAME)
    }
  }

  handleLoadCaster = async (simulationCase?: SimulationCase) => {
    const {
      setAppState,
      setCurrentCasterRoot,
      setError,
      currentSimulationCase,
      authenticationData,
      featureFlags,
      setConfig,
      setDataSources,
      setSimpleDashboardTabIndex,
      setVisualizationMetaInformation,
      appState,
      currentProject,
      addComparisonCaster,
      saveCatalog,
    } = this.props

    setAppState(AppState.Caster)

    const { simulationCaseId, simulationCases, projectId, projects } = this.state

    const caseId = simulationCaseId ?? simulationCases[0]._id

    Info.setRecentlyUsedInfo({ projectId: projectId ?? projects[0]?._id, caseId })

    const res = await ApiClient
      .get(`${Network.URI}/caster_data_json/${caseId}?r=3`)
      .catch(({ error }) => {
        setError('openCaster', error.status)

        return {}
      })

    const { root } = res || {}

    if (!res || !root) {
      return
    }

    if (root) {
      setAppState(AppState.Caster)

      setCurrentCasterRoot(root)

      IpcManager.both.send('loading', null, true, true)

      setTimeout(async () => {
        IpcManager.internal.send(
          'data',
          null,
          { root },
          true,
          null,
        )

        const defaultConfigId = (simulationCase ?? currentSimulationCase).defaultCasterDashboardConfigId ??
          authenticationData.defaultCasterDashboardConfig?.id

        if (defaultConfigId) {
          await CasterColumnLogic
            .handleLoadCasterConfigWithId({
              featureFlags,
              setConfig,
              setDataSources,
              setSimpleDashboardTabIndex,
              setVisualizationMetaInformation,
              currentSimulationCase: simulationCase ?? currentSimulationCase,
              appState,
              currentProject,
              addComparisonCaster,
              authenticationData,
            } as any, defaultConfigId, '1')
        }
      }, 100)

      // timeout to avoid reset_all action
      setTimeout(async () => {
        if ((simulationCase || currentSimulationCase)?.lastLoadedCasterCatalogId) {
          const { casterCatalog } = await ApiClient
            .get(`${Network.URI}/caster_catalog/${(simulationCase || currentSimulationCase).lastLoadedCasterCatalogId}`)

          if (!casterCatalog?.data) {
            return
          }

          saveCatalog(casterCatalog.data, casterCatalog._id)
        }
      }, 1000)
    }
  }

  handleEditOpen = (key: string) => {
    const { openDialog, setEditProjectId } = this.props

    setEditProjectId(key)
    openDialog(EditProjectDialog.NAME)
  }

  handleEditOpenSimulationCase = (key: string) => {
    const { projects, simulationCases, projectId } = this.state
    const { openDialog, setCurrentProject, setEditSimulationCaseId } = this.props

    const project = cloneDeep(projects.find(p => p._id === projectId))

    project.simulationCases = cloneDeep(simulationCases)

    setCurrentProject(project)

    setEditSimulationCaseId(key)

    openDialog(EditSimulationCaseDialog.NAME)
  }

  handleSetDefaultCase = async (key: string) => {
    const { enqueueSnackbar } = this.props
    const { simulationCases, projects } = this.state
    const selectedCase = simulationCases.find(({ _id }: { _id: string }) => _id === key)

    if (!selectedCase) {
      return
    }

    try {
      await ApiClient.patch(`${Network.URI}/simulation_case/set_default/${key}`)

      const simulationCasesCopy = [ ...simulationCases ]
      const projectsCopy = [ ...projects ]

      simulationCasesCopy.map((simulationCase: SimulationCase) => {
        if (simulationCase._id === key) {
          simulationCase.isDefault = !simulationCase.isDefault
        }
        else {
          simulationCase.isDefault = false
        }
      })

      // to update the project selectors
      projectsCopy.forEach((project: Project) => {
        project.simulationCases.forEach((simulationCase: SimulationCase) => {
          if (simulationCase._id === key) {
            simulationCase.isDefault = !simulationCase.isDefault
          }
          else {
            simulationCase.isDefault = false
          }
        })
      })

      this.setState({ simulationCases: simulationCasesCopy, projects: projectsCopy })
    }
    catch (error) {
      enqueueSnackbar('Error toggling default', { autoHideDuration: 3000, variant: 'error' })
    }
  }

  handleHideUserProject = async (id: string) => {
    const { projects } = this.state
    const project = projects.find(({ _id }) => _id === id)

    if (!this.canHideUser() || !project) {
      return
    }

    project.hideUser = !project.hideUser

    try {
      await ApiClient.patch(`${Network.URI}/project/${id}/hide_user`)

      this.setState({ projects })
    }
    catch (error) {
      console.log(error)
    }
  }

  handleHideUserCase = async (id: string) => {
    const { simulationCases } = this.state
    const simulationCase = simulationCases.find(({ _id }) => _id === id)

    if (!this.canHideUser() || !simulationCase) {
      return
    }

    simulationCase.hideUser = !simulationCase.hideUser

    try {
      await ApiClient.patch(`${Network.URI}/simulation_case/${id}/hide_user`)

      this.setState({ simulationCases })
    }
    catch (error) {
      console.log(error)
    }
  }

  getProjectSelectors = () => {
    const { t, featureFlags } = this.props
    const { projects } = this.state

    const projectsSelectors: SelectorOption[] = [
      ...projects.map(({ name, authorName, hideUser, createdAt, _id }) => ({
        value: `${name} - ${hideUser ? 'System' : authorName} (${TimeUtil.getDisplayDate(createdAt)})`,
        key: _id,
      })),
    ]

    if (FeatureFlags.canCreateProject(featureFlags)) {
      projectsSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${PRE_TRANS}.select.add`),
      })
    }

    this.markDefaultProject(projects, projectsSelectors)

    return projectsSelectors
  }

  getCaseSelectors = () => {
    const { t, featureFlags, currentSimulationCase } = this.props
    const { simulationCases } = this.state

    const caseSelectors: SelectorOption[] = [
      ...simulationCases.map(({ name, authorName, hideUser, createdAt, _id }, index) => ({
        value: `
          C${index + 1}${currentSimulationCase._id === _id ? '(R)' : ''} -
          ${name} -
          ${hideUser ? 'System' : authorName}
          (${TimeUtil.getDisplayDate(createdAt)})
        `,
        key: _id,
      })),
    ]

    if (FeatureFlags.canCreateCase(featureFlags)) {
      caseSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${PRE_TRANS}.selectCase.add`),
      })
    }

    this.markDefaultCase(caseSelectors)

    return caseSelectors
  }

  markDefaultCase (caseSelectors: Array<any>): void {
    const { simulationCases } = this.state

    const defaultCase = simulationCases.find(simulationCase => simulationCase.isDefault)

    if (!defaultCase) {
      return
    }

    caseSelectors.forEach(caseSelector => {
      if (caseSelector.key === defaultCase._id) {
        caseSelector.value += ' (default)'
      }
    })
  }

  markDefaultProject (projects: Project[], projectSelectors: SelectorOption[]): void {
    if (!projects || !projectSelectors) {
      return
    }

    const projectId = projects
      .find(project => project.simulationCases.find(simulationCase => simulationCase.isDefault))?._id

    if (!projectId) {
      return
    }

    projectSelectors.forEach(projectSelector => {
      if (projectSelector.key === projectId) {
        projectSelector.value += ' (default)'
      }
    })
  }

  canHideUser = (): boolean => {
    const tokenDataRaw = localStorage.getItem('tokenData')

    if (!tokenDataRaw) {
      return false
    }

    const tokenData = JSON.parse(tokenDataRaw) as AuthResult
    const jwtData = parseJWT(tokenData.accessToken)

    return jwtData.groups.includes('AppAdmin')
  }

  render () {
    const { projects, simulationCases, loading, error, projectId, simulationCaseId } = this.state
    const { t, currentProject, featureFlags } = this.props

    const selectedProjectId = projectId || ((projects || [])[0] || {})._id
    const selectedProject = projects.find(project => project._id === selectedProjectId) || {}

    const selectedSimulationCaseId = simulationCaseId || ((simulationCases || [])[0] || {})._id
    const selectedSimulationCase =
      simulationCases.find(simulationCase => simulationCase._id === selectedSimulationCaseId) || {} as SimulationCase

    const projectsSelectors = this.getProjectSelectors()
    const caseSelectors = this.getCaseSelectors()
    const onSetDefaultCase = FeatureFlags.canSetDefaultCase(featureFlags) ? this.handleSetDefaultCase : undefined

    return (
      <BaseDialog
        title={t('OpenProjectDialog.title')}
        icon='pe-7s-folder'
        header={t('OpenProjectDialog.header')}
        hideCloseButton={!currentProject._id}
        onClose={this.handleClose}
        small
      >
        {
          loading
            ? <Loading type='spin' />
            : error && error.length > 0
              ? <InfoMessage>{t([ `error.${error}`, `error.default` ])}</InfoMessage>
              : (
                <Form>
                  {
                    projects && projects.length > 0
                      ? (
                        <div>
                          <Input
                            name='project'
                            type='select'
                            label={t(`${PRE_TRANS}.select.label`)}
                            title={t(`${PRE_TRANS}.select.title`)}
                            value={selectedProjectId}
                            selectors={projectsSelectors}
                            onChange={this.handleChange}
                            onDelete={FeatureFlags.canDeleteProject(featureFlags) && this.handleDelete}
                            onEdit={
                              (
                                FeatureFlags.canEditProjectDescription(featureFlags) ||
                              FeatureFlags.canRenameProject(featureFlags)
                              ) &&
                              this.handleEditOpen
                            }
                            onUserHide={this.handleHideUserProject}
                            canHideUser={this.canHideUser()}
                          />
                          <Spacer h={17} br />
                          <Label>{t(`${PRE_TRANS}.description.label`, { id: selectedProjectId })}</Label>
                          <Spacer h={10} br />
                          <Description>
                            {selectedProject.description}
                          </Description>
                        </div>
                      )
                      : <Empty>{t('OpenProjectDialog.empty')}</Empty>
                  }
                  {
                    simulationCases && simulationCases.length > 0
                      ? (
                        <div>
                          <Input
                            name='simulationCase'
                            type='select'
                            label={t(`${PRE_TRANS}.selectCase.label`)}
                            title={t(`${PRE_TRANS}.selectCase.title`)}
                            value={selectedSimulationCaseId}
                            selectors={caseSelectors}
                            onChange={this.handleChangeSimulationCase}
                            onDelete={FeatureFlags.canDeleteCase(featureFlags) && this.handleDeleteSimulationCase}
                            onEdit={
                              (
                                FeatureFlags.canEditCaseDescription(featureFlags) ||
                              FeatureFlags.canRenameCase(featureFlags)
                              ) &&
                              this.handleEditOpenSimulationCase
                            }
                            onSetDefault={onSetDefaultCase}
                            noDeleteCurrent
                            onUserHide={this.handleHideUserCase}
                            canHideUser={this.canHideUser()}
                          />
                          <Spacer h={17} br />
                          <Label>{t(`${PRE_TRANS}.descriptionCase.label`, { id: selectedSimulationCaseId })}</Label>
                          <Spacer h={10} br />
                          <Description>
                            {selectedSimulationCase.description}
                          </Description>
                        </div>
                      )
                      : <Empty>{t('OpenProjectDialog.emptyCases')}</Empty>
                  }
                  <div>
                    {
                      projects && projects.length > 0 &&
                      <Button
                        value=''
                        onClick={this.handleOpen}
                        title={t('OpenProjectDialog.open')}
                      >
                        {t('OpenProjectDialog.open')}
                      </Button>
                    }
                  </div>
                </Form>
              )
        }
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(withSnackbar(connector(OpenProjectDialog as any) as any) as any) as any
