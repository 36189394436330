import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Plotly from 'plotly.js'
import cloneDeep from 'clone-deep'

import NetworkManager from 'network/NetworkManager'
import Util from 'logic/Util'
import VisUtil from '../../VisUtil'

import logic from './logic'
import helpers from './helpers'

import FastBase from './fast/FastBase'
import Line from './fast/Line'
import Fill from './fast/Fill'
import Bar from './fast/Bar'
import Shape from './fast/Shape'
import { PlotInfo, Wrapper } from './styles'

import * as VisualizationActions from 'store/visualization/actions'
import * as TileWarningsActions from 'store/tileWarnings'
import { DefaultState } from 'types/state'
import { ViewLogic } from 'react/visualization/dashboard/ViewLogic'
import FeatureFlags from 'react/FeatureFlags'

const connector = connect(({
  AirLoop,
  application,
  ComparisonCasters,
  CoolingLoop,
  CoolingZone,
  DataLine,
  DataPoint,
  filter,
  LoopAssignment,
  Nozzle,
  Roller,
  RollerBearing,
  RollerBody,
  SupportPoint,
  Segment,
  SegmentGroup,
  SensorPoint,
  StrandGuide,
  tileWarnings,
  visualization,
}: DefaultState) => ({
  comparisonCasters: ComparisonCasters,
  currentProject: application.main.currentProject,
  currentSimpleDashboardTabIndex: application.main.currentSimpleDashboardTabIndex,
  currentSimulationCaseFromRedux: application.main.currentSimulationCase,
  data: visualization.data,
  elementsHashes: {
    AirLoop,
    CoolingLoop,
    CoolingZone,
    DataLine,
    DataPoint,
    LoopAssignment,
    Nozzle,
    Roller,
    RollerBearing,
    RollerBody,
    SupportPoint,
    SegmentGroupSupportPoints: SupportPoint,
    Segment,
    SegmentGroup,
    SensorPoint,
    StrandGuide,
  },
  featureFlags: application.main.authenticationData.featureFlags,
  filterControlVariables: filter.filterControlVariables,
  isEditModeOn: visualization.isEditModeOn,
  isNewData: visualization.isNewData,
  networkStatus: visualization.networkStatus,
  plotConfigs: visualization.plotConfigs,
  tileConfigs: visualization.tileConfigs,
  tileWarnings: tileWarnings,
  viewsObject: visualization.viewsObject,
  /*
    This is here so that the plots update after closing the plot list
    otherwise the plots don't resize until the user clicks on the plot
   */
  isPlotListOpened: visualization.isPlotListOpened,
}), {
  openContextMenu: VisualizationActions.openContextMenu,
  setTileWarnings: TileWarningsActions.setTileWarnings,
  updatedPlot: VisualizationActions.updatedPlot,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  tileId: string,
  featureFlags: Record<string, boolean>
  type: 'line' | 'bar' | 'gage' | 'contour' | 'pie' | 'area' | 'text' | 'edit_box' | 'command' | 'message' | 'radar',
  message?: string
  key?: string
  configId?: string
  xDomain?: number[]
  yDomain?: number[]
  xRange?: number[]
  xValues?: number[]
  yValueRange?: number[]
  valueRange?: number[]
  shapeIds?: string[]
  radiusDomain?: number[]
  radius0?: number
  radius?: number
  frequency?: number
  xLabel?: string
  yLabel?: string
  viewId?: string
  flipAxes?: boolean
  currentSimulationCase?: SimulationCase
  dynamicData?: Array<any>
  isDynamicData?: boolean
  dynamicDataList?: Array<Array<any>>
  isMergedDynamicData?: boolean
  hasNoData?: boolean
  isMultiLinePlot?: boolean
  shapeData?: any[]
  length?: number // TODO: verify this, this isn't being provided
  forceUpdateHandler?: () => void,
}

type State = {
  over: boolean
  overWrapper: boolean
  xIndex: number
  x: number
  hasNoData: boolean
};

class PlotlyWrapper extends Component<Props, State> {
  pauseDraw: boolean
  prevTileId?: string = undefined
  prevDefinitions: Array<any> = []
  value: Array<any>
  line: Line
  fill: Fill
  bar: Bar
  shape: Shape
  isPlotUpToDate: boolean
  prevLayout: any
  currentPasslnPosition: number
  filterControlVariableValues: Record<string, number | undefined> = {}

  constructor (props: Props) {
    super(props)

    this.pauseDraw = false
    this.value = []

    this.line = new Line()
    this.fill = new Fill()
    this.bar = new Bar()
    this.shape = new Shape()
    this.isPlotUpToDate = false
    this.prevLayout = {}
    this.state = {
      over: false,
      overWrapper: false,
      x: -1,
      xIndex: -1,
      hasNoData: props.hasNoData || false,
    }

    // initialize plot variables
    this.currentPasslnPosition = (window as any).currentPasslnPosition || 0

    if ((window as any).filterControlVariables) {
      this.filterControlVariableValues = { ...(window as any).filterControlVariables }
    }
  }

  componentDidMount () {
    logic.connect(
      {},
      this.props,
      this.setData,
      this.handleMouseOver,
      this.handleMouseOut,
      this.handleMouseDown,
      this.handleMouseUp,
    )

    const { filterControlVariables } = this.props

    window.addEventListener('CurrentPasslnPositionChanged', this.handlePlotVariableChanged)
    window.addEventListener('FinishedScrollingPasslnCoord', this.handlePlotVariableChanged)

    filterControlVariables.forEach(variable => {
      if (!(window as any).filterControlVariables) {
        (window as any).filterControlVariables = {}
      }

      window.addEventListener(`${variable}Changed`, this.handlePlotVariableChanged)
    })

    this.setState({ hasNoData: this.props.hasNoData || false })
  }

  shouldComponentUpdate (nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !(nextProps.equals(this.props) && nextState.equals(this.state))
  }

  componentDidUpdate (prevProps: Props) {
    logic.connect(
      prevProps,
      this.props,
      this.setData,
      this.handleMouseOver,
      this.handleMouseOut,
      this.handleMouseDown,
      this.handleMouseUp,
    )
  }

  componentWillUnmount () {
    const { tileId, filterControlVariables } = this.props

    NetworkManager.removePlot(tileId)

    Util.RemoveDelegatedEventListener('', 'pointerdown', `#plot_${tileId}`)
    document.removeEventListener('pointerup', this.handleMouseUp, true)
    window.removeEventListener('FinishedScrollingPasslnCoord', this.handlePlotVariableChanged)

    window.removeEventListener('CurrentPasslnPositionChanged', this.handlePlotVariableChanged)
    filterControlVariables.forEach(variable => {
      if (!(window as any).filterControlVariables) {
        (window as any).filterControlVariables = {}
      }

      window.removeEventListener(`${variable}Changed`, this.handlePlotVariableChanged)
    })
  }

  handlePlotVariableChanged = (event: any) => {
    const { currentPasslnPosition, nextValue } = event.detail || {}
    const eventType = event.type
    const stoppedScrolling = eventType === 'FinishedScrollingPasslnCoord'
    const {
      tileId,
      tileConfigs,
      plotConfigs,
      elementsHashes,
      viewId = '',
      viewsObject,
      comparisonCasters,
      tileWarnings,
      setTileWarnings,
      featureFlags,
      currentProject,
      filterControlVariables,
    } = this.props

    const plotConfig = plotConfigs[tileConfigs[tileId]?.configId]
    const canViewCasterComparison = FeatureFlags.canViewCasterComparison(featureFlags)
    const simulationCaseIds = currentProject.simulationCases.map(simCase => simCase._id)
    const tileConfig = tileConfigs[tileId]

    if (plotConfig) {
      if (
        (ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) && stoppedScrolling) ||
        ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)
      ) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            let definitions = this.prevDefinitions

            if (this.prevDefinitions.length > 0) {
              const comparisonCastersIds = ((viewsObject[viewId] || {}).selectedComparisonCasters || [])
                .filter(id => simulationCaseIds.includes(id)) // filter out deleted casters

              definitions = cloneDeep(this.prevDefinitions)

              const [ type, attrY ] = plotConfig.selectedY.split('|')
              const [ _, attrX ] = plotConfig.selectedX.split('|')

              const elements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, plotConfig, {})

              if (!elements) {
                return
              }

              const dynamicData: any = type === 'DataLine'
                ? ViewLogic.getDynamicDataFromDataLines(
                  elements,
                  elementsHashes,
                  'DataLine',
                  attrX,
                  attrY,
                  false,
                  true,
                )
                : ViewLogic.getDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY, false)

              if (!dynamicData) {
                return
              }

              this.setState({ hasNoData: !dynamicData.length })

              if (!dynamicData.length) {
                // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
                dynamicData.push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])
              }

              definitions[0] = definitions[0] || {}
              definitions[0].data = (canViewCasterComparison && comparisonCastersIds.length)
                ? dynamicData
                : dynamicData.map((el: any) => el.y)
              definitions[0].dynamicData = dynamicData
              definitions[0].length = dynamicData.length
              definitions[0].id = plotConfig.id

              // TODO: update X values!
              const comparisonCasterData: any[] = []
              const comparisonElements: string[][] = []

              if (canViewCasterComparison && comparisonCastersIds && comparisonCastersIds.length > 0) {
                for (const casterId of comparisonCastersIds) {
                  const data = comparisonCasters[casterId]

                  if (!data) {
                    continue
                  }

                  const casterIndex = simulationCaseIds.findIndex(key => casterId === key) + 1

                  data.casterName = `C${casterIndex}`

                  const elements = ViewLogic
                    .getDynamicElementsFromConfig(data.elementsHashes, plotConfig, {})

                  comparisonElements.push(elements)
                  comparisonCasterData.push(data)
                }

                for (let i = 0; i < comparisonCasterData.length; i++) {
                  const data = comparisonCasterData[i]
                  const elements = comparisonElements[i]
                  const compareDynamicData: any = type === 'DataLine'
                    ? ViewLogic.getDynamicDataFromDataLines(
                      elements,
                      data.elementsHashes,
                      'DataLine',
                      attrX,
                      attrY,
                      false,
                      true,
                    )
                    : ViewLogic.getDynamicDataFromElements(
                      elements,
                      data.elementsHashes,
                      type,
                      attrX,
                      attrY,
                      false,
                    )

                  if (tileWarnings && tileWarnings[plotConfig.id] && tileWarnings[plotConfig.id][data.casterName]) {
                    const tileWarningsCopy = { ...tileWarnings }

                    delete tileWarningsCopy[plotConfig.id][data.casterName]

                    setTileWarnings(tileWarningsCopy)
                  }

                  // add caster name info to every point of line
                  for (const el of compareDynamicData) {
                    (el as any).casterName = data.casterName
                  }

                  const j = i + 1

                  definitions[j] = definitions[j] || {}
                  definitions[j].data = compareDynamicData
                  definitions[j].dynamicData = compareDynamicData
                  definitions[j].length = compareDynamicData.length
                  definitions[j].id = plotConfig.id
                }
              }
            }

            this.setData({ tileId, definitions }, false)
            this.props.forceUpdateHandler?.()
          })
        })
      }
      else if (
        (ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) && stoppedScrolling) ||
        ViewLogic.isMergedDynamicDataSourceWithFilterControlVariableInFilter(
          plotConfig,
          filterControlVariables,
        )
      ) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            let definitions = this.prevDefinitions

            if (this.prevDefinitions.length > 0) {
              definitions = cloneDeep(this.prevDefinitions).filter(definition => definition.length > 0)

              // const xValues: number[] = []
              // const yValues: number[] = []
              const filteredElementCache = {}
              const dynamicDataList: any = []
              const hasNoDataList: boolean[] = []

              let definitionCounter = 0

              ;(plotConfig.configs || []).forEach((config: any, index: number) => {
                index += definitionCounter
                const [ type, attrY ] = config.selectedY.split('|')
                const [ _, attrX ] = config.selectedX.split('|')

                const elements = ViewLogic.getDynamicElementsFromConfig(elementsHashes, config, filteredElementCache)

                const dynamicData: any = type === 'DataLine'
                  ? ViewLogic.getDynamicDataFromDataLines(
                    elements,
                    elementsHashes,
                    'DataLine',
                    attrX,
                    attrY,
                    false,
                    true,
                  )
                  : ViewLogic.getDynamicDataFromElements(elements, elementsHashes, type, attrX, attrY, false)

                hasNoDataList.push(!dynamicData.length)

                if (!dynamicData.length) {
                  // Create plotly traces with fallback data for FastBase.
                  dynamicData.push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])
                }

                dynamicDataList.push(dynamicData)

                definitions[index] = definitions[index] || {}
                definitions[index].data = dynamicData.map((el: any) => el.y)
                definitions[index].dynamicData = dynamicData
                definitions[index].length = dynamicData.length
                definitions[index].id = config.id

                const comparisonCastersIds = ((viewsObject[viewId] || {}).selectedComparisonCasters || [])
                  .filter(id => simulationCaseIds.includes(id)) // filter out deleted casters

                const comparisonCasterData: any[] = []
                const comparisonElements: string[][] = []

                if (canViewCasterComparison && comparisonCastersIds && comparisonCastersIds.length > 0) {
                  for (const casterId of comparisonCastersIds) {
                    const data = comparisonCasters[casterId]

                    if (!data) {
                      continue
                    }

                    const casterIndex = simulationCaseIds.findIndex(key => casterId === key) + 1

                    data.casterName = `C${casterIndex}`

                    const elements = ViewLogic
                      .getDynamicElementsFromConfig(data.elementsHashes, config, {})

                    comparisonElements.push(elements)
                    comparisonCasterData.push(data)
                  }

                  for (let i = 0; i < comparisonCasterData.length; i++) {
                    const data = comparisonCasterData[i]
                    const elements = comparisonElements[i]
                    const compareDynamicData: any = type === 'DataLine'
                      ? ViewLogic.getDynamicDataFromDataLines(
                        elements,
                        data.elementsHashes,
                        type,
                        attrX,
                        attrY,
                        false,
                        true,
                      )
                      : ViewLogic.getDynamicDataFromElements(
                        elements,
                        data.elementsHashes,
                        type,
                        attrX,
                        attrY,
                        false,
                      )

                    if (!compareDynamicData.length) {
                      compareDynamicData.push({ x: 0, y: 0 })
                    }
                    else {
                      // delete tile warning
                      if (tileWarnings && tileWarnings[config.id] && tileWarnings[config.id][data.casterName]) {
                        const tileWarningsCopy = { ...tileWarnings }

                        delete tileWarningsCopy[config.id][data.casterName]

                        setTileWarnings(tileWarningsCopy)
                      }
                    }

                    // add caster name info to every point of line
                    for (const el of compareDynamicData) {
                      (el as any).casterName = data.casterName
                    }

                    dynamicDataList.push(compareDynamicData)

                    const j = i + 1

                    definitions[index + j] = definitions[index + j] || {}
                    definitions[index + j].data = compareDynamicData.map((el: any) => el.y)
                    definitions[index + j].dynamicData = compareDynamicData
                    definitions[index + j].length = compareDynamicData.length
                    definitions[index + j].id = config.id
                  }

                  definitionCounter += comparisonCasterData.length || 0
                }
              })

              this.setState({ hasNoData: hasNoDataList.reduce((a, b) => a && b, true) })
              this.setData({ tileId, definitions }, false)
              this.props.forceUpdateHandler?.()
            }
          })
        })
      }
      else if (tileConfig?.followPasslnCoord && stoppedScrolling) {
        this.props.forceUpdateHandler?.()
      }
    }

    if (
      stoppedScrolling &&
      (
        ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
        ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig)
      )
    ) {
      this.drawVerticalVariable('_passln_coord')

      return
    }

    if (eventType === 'CurrentPasslnPositionChanged') {
      this.currentPasslnPosition = currentPasslnPosition
    }
    else {
      const varName: string = eventType.split([ 'Changed' ])[0]

      ;(this as any).filterControlVariableValues[varName] = nextValue
    }

    // update vertical line passln coord
    this.handlePlotlyWrapperRelayout({ detail: { tileId, type: eventType } }, filterControlVariables)
  }

  handlePlotlyWrapperRelayout = (
    event: { detail: {tileId: string, type: string}},
    filterControlVariables: string[],
  ) => {
    const { tileId, tileConfigs, shapeIds } = this.props

    if (event.detail.tileId === tileId &&
      tileConfigs[tileId] &&
      shapeIds
    ) {
      if (shapeIds.includes('_passln_coord') && event.detail.type === 'CurrentPasslnPositionChanged') {
        this.drawVerticalVariable('_passln_coord')
      }
      else if (shapeIds.some(shapeId => filterControlVariables.includes(shapeId))) {
        const variableChanged = event.detail.type.split('Changed')[0]

        if (filterControlVariables.includes(variableChanged)) {
          this.drawVerticalVariable(variableChanged)
        }
      }
    }
  }

  handleMouseDown = (event: any) => {
    const { button, pageX, pageY } = event
    const { over, xIndex, x } = this.state
    const { configId, openContextMenu, viewId } = this.props

    this.pauseDraw = true

    if (button === 2 && over) { // right
      openContextMenu('plot', { xIndex, x, mousePos: { x: pageX, y: pageY }, configId, viewId })
    }
  };

  handleMouseUp = () => {
    this.pauseDraw = false
  };

  handleMouseOver = (data: any) => {
    const { over, xIndex } = this.state

    if (data && data.length && (xIndex !== data[0].pointIndex || !over)) {
      this.setState({
        x: data[0].x,
        xIndex: data[0].pointIndex,
        over: true,
      })
    }
  };

  handleMouseOut = () => {
    this.setState({
      over: false,
    })
  };

  handleMouseEnter = () => {
    this.setState({
      overWrapper: true,
    })
  };

  handleMouseLeave = (event: any) => {
    const { clientX, clientY } = event
    const wrapperRect = event.target.getBoundingClientRect()
    const containerRect = event.target.closest('.grid-layout-scroll-container').getBoundingClientRect()

    if (
      !logic.isInRect(clientX, clientY, wrapperRect) ||
      !logic.isInRect(clientX, clientY, containerRect) ||
      Array.prototype.includes.call(event.relatedTarget.classList || [], 'react-resizable-handle')
    ) {
      this.setState({
        overWrapper: false,
      })
    }
  };

  drawVerticalVariable = (variableName: string) => {
    const { tileId, tileConfigs, flipAxes = false, xDomain = [ 0, 0 ] } = this.props
    const currentShape = tileConfigs[tileId].shapeIds.find(shapeId => shapeId.id === variableName)
    const currentValue = variableName === '_passln_coord'
      ? this.currentPasslnPosition
      : this.filterControlVariableValues[variableName] || 0

    const shapes = [
      helpers.getLineShape({ flipAxes, currentVariablePosition: currentValue, currentShape }),
    ]

    document.querySelectorAll(`[id^="plot_${tileId}"]`).forEach((plot: any) => {
      this.shape.draw(plot, [], xDomain.sort((a, b) => a - b), [ 0, 0 ], false, { shapes })
    })
  }

  setData = (result: any = {}, forceFast = false) => {
    let {
      tileId,
      definitions,
    }: {
      tileId: string,
      definitions: {
        id: string,
        length: number,
        bytes: any,
        data: any,
        dynamicData?: any[],
        isDataLine?: boolean,
        lineIndex?: number
        comparisonLineIndex?: number,
        isVerticalLine?: boolean,
        isMergedDataLine?: boolean,
      }[]
    } = result
    const {
      currentProject,
      currentSimulationCaseFromRedux,
      filterControlVariables,
      isMergedDynamicData,
      isDynamicData,
      dynamicDataList,
    } = this.props

    const currentCasterIndex = currentProject.simulationCases
      .findIndex(simCase => simCase._id === currentSimulationCaseFromRedux._id)
    const referenceCasterTooltip = `C${currentCasterIndex + 1}(R)`

    if (!tileId) {
      tileId = this.prevTileId || ''
      definitions = this.prevDefinitions
    }

    this.prevTileId = tileId
    this.prevDefinitions = definitions

    if (this.pauseDraw || !tileId || !definitions || !definitions.length || !definitions[0].data) {
      return
    }

    const { type, xLabel, yLabel, flipAxes = false, tileConfigs, shapeIds, plotConfigs } = this.props
    const configs: Array<any> = Object.values(plotConfigs)

    const defaultLayout = logic.getLayout(this.props)
    const defaultData = logic.getData(this.props)

    let data: any[] = []
    let newLayout: any = {}
    let hasCurrentPsslnPositon = false
    const hasVariables: Record<string, boolean> = {}

    for (let i = 0; i < definitions.length; i++) {
      const currentDefinition = definitions[i]

      // filter out empty data, dynamic merge plots have empty data!
      if (!currentDefinition || (!currentDefinition.length && !currentDefinition.isVerticalLine)) {
        continue
      }

      const dataConfigId = currentDefinition.id

      if (currentDefinition.isVerticalLine) {
        if (
          (shapeIds && shapeIds.includes(dataConfigId)) ||
          (shapeIds && (shapeIds.includes('_passln_coord') ||
          shapeIds.some(shape => filterControlVariables.includes(shape))))
        ) {
          let shapes: unknown[] = []

          if (isMergedDynamicData) {
            const shapeData = this.props.shapeData || []
            const currentShape = tileConfigs[tileId].shapeIds.find(shapeId => shapeId.id === dataConfigId)
            const currentShapeData = (shapeData.find(data => data.shapeId === dataConfigId) || {}).data
            const xValues = (currentShapeData || []).map((el: any) => el.x)

            if (currentShape === undefined &&
              !(shapeIds.includes('_passln_coord') || shapeIds.some(shape => filterControlVariables.includes(shape)))) {
              return
            }

            if (currentShape?.type === 'rect') {
              shapes = []

              helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
            }
            else {
              shapes = (currentDefinition.data || [])
                .map((value: any) => (helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
            }

            newLayout.shapes = [
              ...(newLayout.shapes || []),
              ...shapes,
            ]

            continue
          }

          const shapeData = this.props.shapeData || []
          const currentShape = tileConfigs[tileId].shapeIds.find(shapeId => shapeId.id === dataConfigId)
          const currentShapeData = (shapeData.find(data => data.shapeId === dataConfigId) || {}).data
          const xValues = (currentShapeData || []).map((el: any) => el.x)

          if (currentShape === undefined && !shapeIds.includes('_passln_coord')) {
            return
          }

          if (currentShape?.type === 'rect') {
            shapes = []

            helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
          }
          else {
            shapes = currentShapeData
              .map((value: any) => (helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
          }

          newLayout.shapes = [
            ...(newLayout.shapes || []),
            ...shapes,
          ]
        }

        continue
      }

      const rawValues = !this.props.isMultiLinePlot
        ? currentDefinition.data
        : Object.values(currentDefinition.data).map((data: any) => data.y)
      const config = plotConfigs[dataConfigId]
      const uid = `uid-${dataConfigId}-${tileId}`

      switch (type) {
        case 'area':
        case 'line':
        case 'radar':
        case 'bar':
          const { additionalYAxes, legendOptions } = tileConfigs[tileId]

          if (!config || /^xCoordinates_/.test(config.key)) {
            continue
          }

          let x = []
          let casterNames = []

          const xConfigs = configs.filter(c => c.group === config.group && /^xCoordinates_/.test(c.key))[0]

          if (xConfigs && xConfigs.key) {
            x = (definitions.filter((def: any) => def.id === xConfigs.id)[0] || {}).data
          }

          let fallbackColor = ''

          if (isMergedDynamicData && dynamicDataList) {
            const { configId } = tileConfigs[tileId]
            const { configIds } = plotConfigs[configId]
            const index = (configIds || []).findIndex(id => id === dataConfigId) || 0

            fallbackColor = VisUtil.TRACE_COLORS[
              (index + (currentDefinition.lineIndex || 0)) % VisUtil.TRACE_COLORS.length
            ] || ''

            if (currentDefinition.isDataLine) {
              const { lineIndex, dynamicData } = currentDefinition
              const correspondingDataList = dynamicDataList[index][lineIndex ?? 0] ?? []

              const dataList = correspondingDataList.length
                ? correspondingDataList
                : (dynamicData ?? [])

              x = dataList.map((el: any) => el.x)
              casterNames = dataList.map((el: any) => el.casterName ?? '')
            }
            else if (currentDefinition.isMergedDataLine) {
              const dataList = dynamicDataList[index][currentDefinition.lineIndex ?? 0][0] ?? []

              x = dataList.map((el: any) => el.x)
              casterNames = dataList.map((el: any) => el.casterName ?? '')
            }
            else {
              x = (currentDefinition.dynamicData || dynamicDataList[index] || []).map((el: any) => el.x)
            }
          }

          if (isDynamicData && this.props.dynamicData && !this.props.isMultiLinePlot) {
            x = (currentDefinition.dynamicData || this.props.dynamicData).map((el: any) => el.x)
          }

          if (isDynamicData && this.props.dynamicData && this.props.isMultiLinePlot) {
            x = (currentDefinition.dynamicData || this.props.dynamicData[i]).map((el: any) => el.x)
            casterNames = (
              currentDefinition.dynamicData || this.props.dynamicData[i]).map((el: any) => el.casterName || '')
          }

          if (
            shapeIds &&
            (
              shapeIds.includes(dataConfigId) ||
              shapeIds.includes('_passln_coord') ||
              shapeIds.some(shape => filterControlVariables.includes(shape))
            )
          ) {
            let shapes: unknown[] = []

            if (shapeIds.includes('_passln_coord')) {
              if (!hasCurrentPsslnPositon) {
                const { currentPasslnPosition } = this
                const currentShape = tileConfigs[tileId].shapeIds.find(shapeId => shapeId.id === '_passln_coord')

                if (newLayout.shapes) {
                  newLayout.shapes.unshift(helpers.getLineShape({
                    flipAxes,
                    currentVariablePosition: currentPasslnPosition,
                    currentShape,
                  }))
                }
                else {
                  newLayout.shapes = [
                    helpers.getLineShape({
                      flipAxes,
                      currentVariablePosition: currentPasslnPosition,
                      currentShape,
                    }),
                  ]
                }

                hasCurrentPsslnPositon = true
              }
            }
            else if (shapeIds.some(shape => filterControlVariables.includes(shape))) {
              const variableContainedInShapes = shapeIds.find(shape => filterControlVariables.includes(shape))

              if (variableContainedInShapes && !hasVariables[variableContainedInShapes]) {
                const currentShape = tileConfigs[tileId].shapeIds
                  .find(shapeId => shapeId.id === variableContainedInShapes)

                if (newLayout.shapes) {
                  newLayout.shapes.unshift(helpers.getLineShape({
                    flipAxes,
                    currentVariablePosition: this.filterControlVariableValues[variableContainedInShapes] || 0,
                    currentShape,
                  }))
                }
                else {
                  newLayout.shapes = [
                    helpers.getLineShape({
                      flipAxes,
                      currentVariablePosition: this.filterControlVariableValues[variableContainedInShapes] || 0,
                      currentShape,
                    }),
                  ]
                }

                hasVariables[variableContainedInShapes] = true
              }
            }
            else {
              const shapeData = this.props.shapeData || []
              const currentShape = tileConfigs[tileId].shapeIds.find(shapeId => shapeId.id === dataConfigId)
              const currentShapeData = (shapeData.find(data => data.shapeId === dataConfigId) || {}).data
              const xValues = (currentShapeData || []).map((el: any) => el.x)

              if (currentShape === undefined && !shapeIds.includes('_passln_coord')) {
                return
              }

              if (currentShape?.type === 'rect') {
                shapes = []

                helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
              }
              else {
                shapes = currentShapeData
                  .map((value: any) => (helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
              }
            }

            newLayout.shapes = [
              ...(newLayout.shapes || []),
              ...shapes,
            ]
          }

          const {
            label,
            tooltip,
            color,
            hide,
            hideLine,
            markerSymbol,
            markerSize,
            markerColor,
          } = (legendOptions || {})[config.id] || {}

          const { showLegend } = tileConfigs[tileId]
          const text = tooltip || ''
          let yAxisIndex: any = (additionalYAxes || [])
            .map((additionalYAxis: any) => additionalYAxis.id).indexOf(config.id) + 2

          yAxisIndex = yAxisIndex < 2 ? '' : yAxisIndex

          const newData = helpers.getNewData(defaultData[0], hide, !!showLegend, fallbackColor, newLayout, color)

          const newLabel = `${label || ''}${casterNames && casterNames[0] ? ` caster: ${casterNames[0]}` : ''}`
          // eslint-disable-next-line max-len
          const name = `<span style="color: ${hide ? 'transparent' : (casterNames && casterNames[0] ? fallbackColor : color)}">${newLabel}</span>`

          if (type === 'bar') {
            const tracesXs: any = []
            const tracesYs: any = []
            const tracesCasters: any[] = []

            for (let i = 0; i < x.length; i++) {
              let traceIndex = 0
              const elementX = x[i]
              const elementY = rawValues[i]
              const casterTrace = casterNames[i]

              while (tracesXs[traceIndex] && tracesXs[traceIndex].includes(elementX)) {
                traceIndex++
              }

              if (!tracesXs[traceIndex]) {
                tracesXs[traceIndex] = []
                tracesYs[traceIndex] = []
                tracesCasters[traceIndex] = casterTrace
              }

              tracesXs[traceIndex].push(elementX)
              tracesYs[traceIndex].push(elementY)
            }

            for (let index = 0; index < tracesXs.length; index++) {
              const xList = tracesXs[index]
              const yList = tracesYs[index]
              let traceText = text || ''

              if (traceText.length) {
                traceText += ' -'
              }

              traceText += `${tracesCasters[index] ? `${tracesCasters[index]}` : referenceCasterTooltip}`

              if (!flipAxes) {
                data.push({
                  ...newData,
                  y: yList,
                  x: xList,
                  name,
                  text: traceText,
                  yaxis: `y${yAxisIndex}`,
                  uid: `${uid}-${index}`,
                  comparisonLineIndex: currentDefinition.comparisonLineIndex,
                })
              }
              else {
                data.push({
                  ...newData,
                  x: yList,
                  y: xList,
                  name,
                  text: traceText,
                  xaxis: `x${yAxisIndex}`,
                  uid: `${uid}-${index}`,
                  comparisonLineIndex: currentDefinition.comparisonLineIndex,
                })
              }
            }
          }
          else {
            const newText = []

            for (let i = 0; i < casterNames.length; i++) {
              const casterName = casterNames[i] ? casterNames[i] : referenceCasterTooltip

              newText.push(text ? `${text} - ${casterName}` : casterName)
            }

            let trace = {
              ...newData,
              mode: 'lines',
              name,
              text: newText.length ? newText : text,
              uid,
              comparisonLineIndex: currentDefinition.comparisonLineIndex,
            }

            const theta = x.map((value: any) => `(${value})`)
            const rValues = rawValues.map((value: any) => value)

            if (type === 'radar') {
              theta.push(theta[0])
              rValues.push(rValues[0])
              trace = {
                ...trace,
                type: 'scatterpolar',
                r: rValues,
                theta: theta,
              }
            }

            if (type === 'line') {
              trace = {
                ...trace,
                mode: hideLine ? 'markers' : markerSymbol === undefined || markerSymbol === 'none'
                  ? 'lines' : 'lines+markers',
                marker: {
                  symbol: markerSymbol,
                  size: markerSize || 8,
                  color: markerColor || color,
                },
              }
            }

            if (!flipAxes) {
              data.push({
                ...trace,
                y: rawValues,
                x,
                yaxis: `y${yAxisIndex}`,
              })
            }
            else {
              data.push({
                ...trace,
                x: rawValues,
                y: x,
                xaxis: `x${yAxisIndex}`,
              })
            }
          }

          break
        case 'pie':
          data.push({ ...defaultData[0], values: rawValues, name: dataConfigId, type })
          break
        case 'contour':
          const { colorBar } = tileConfigs[tileId]

          const d = data[0] || {}

          data[0] = {
            ...defaultData[0],
            x: d.x,
            y: d.y,
            z: d.z,
            zTmp: d.zTmp,
            showscale: colorBar || false,
          }

          if (colorBar) {
            data[0] = {
              ...data[0],
              colorbar: {
                tickfont: {
                  color: '#a2a6a9',
                },
              },
            }
          }

          if (/^xCoordinates_/.test(config.key)) {
            data[0].x = rawValues
          }
          else if (/^yCoordinates_/.test(config.key)) {
            data[0].y = rawValues
          }
          else {
            data[0].zTmp = rawValues
          }

          const { x: xValues, y: yValues, zTmp } = data[0]

          if (xValues && yValues && zTmp) {
            data[0].z = []

            for (let yIndex = 0; yIndex < yValues.length; yIndex++) {
              data[0].z[yIndex] = []

              for (let xIndex = 0; xIndex < xValues.length; xIndex++) {
                data[0].z[yIndex][xIndex] = zTmp[xIndex + (yIndex * xValues.length)]
              }
            }

            delete data[0].zTmp
          }

          break
        default:
      }
    }

    const plotElement = document.getElementById(`plot_${tileId}`)

    if (data && data.length && plotElement) {
      if (!plotElement.getElementsByClassName('plot-container').length) {
        plotElement.innerHTML = ''

        const config = {
          responsive: true,
          displaylogo: false,
        }

        try {
          Plotly.newPlot(`plot_${tileId}`, defaultData, defaultLayout, config)
        }
        catch (e) {}
      }

      document.querySelectorAll(`[id^="plot_${tileId}"]`).forEach((plot: any) => {
        // for some reason the axes are swapped so we need to set old.x = new.y and old.y = new.x
        try {
          plot._fullLayout.xaxis._anchorAxis._rangeInitial = defaultLayout.yaxis.range
          plot._fullLayout.yaxis._anchorAxis._rangeInitial = defaultLayout.xaxis.range
          data.forEach((d, i) => {
            if (!d.line.color) {
              d.line.color = VisUtil.TRACE_COLORS[i]
            }

            if (d.mode === 'lines+markers') {
              d.hoverlabel = { bgcolor: d.line.color }
            }

            if (d.comparisonLineIndex) {
              const originalLineIndex = data.findIndex(line =>
                (line.uid === d.uid && line.comparisonLineIndex === 0))

              if (originalLineIndex !== -1) {
                // get line color and dashed style
                d.line = logic.getLineStyle(
                  data[originalLineIndex].line.color || VisUtil.TRACE_COLORS[originalLineIndex],
                  d.comparisonLineIndex,
                )

                if (d.mode === 'lines+markers') {
                  d.hoverlabel = { bgcolor: d.line.color }
                }

                if (d.mode === 'lines+markers' || d.mode === 'markers') {
                  d.marker.color = logic.LightenDarkenColor(
                    data[originalLineIndex].marker.color,
                    50 * (d.comparisonLineIndex % 4),
                  )
                }

                if (d.type === 'bar') {
                  d.marker = logic.getLineStyle(
                    data[originalLineIndex].line.color || VisUtil.TRACE_COLORS[originalLineIndex],
                    d.comparisonLineIndex,
                  )
                }
              }
            }

            if (d.line.color === 'transparent') {
              d.marker.color = 'transparent'
              d.hoverinfo = 'none'
            }
            else {
              d.hoverinfo = (defaultData[i] || {}).hoverinfo || 'x+y+text'
            }
          })
        }
        catch (e) {
          console.log(e)
        }

        const trueXLabel = !flipAxes ? xLabel : yLabel
        const trueYLabel = !flipAxes ? yLabel : xLabel

        const margin = {
          l: (trueYLabel || []).length > 0 ? 60 : 40,
          b: (trueXLabel || []).length > 0 ? 60 : 40,
        }

        const { showLine } = tileConfigs[tileId]

        const plotLayout = plot.layout || {}

        newLayout = {
          ...plotLayout,
          ...defaultLayout,
          margin: {
            ...defaultLayout.margin,
            ...margin,
          },
          xaxis: {
            ...plotLayout.xaxis,
            title: trueXLabel,
            titlefont: {
              family: 'Roboto, sans-serif',
              size: 14,
              color: '#a2a6a9',
            },
            domain: defaultLayout.xaxis.domain,
          },
          yaxis: {
            ...plotLayout.yaxis,
            title: trueYLabel,
            showgrid: showLine || false,
            titlefont: {
              family: 'Roboto, sans-serif',
              size: 14,
              color: '#a2a6a9',
            },
            domain: defaultLayout.yaxis.domain,
          },
          polar: {
            ...plotLayout.polar,
            angularaxis: {
              tickfont: {
                color: '#a2a6a9',
              },
            },
          },
          ...newLayout,
        }

        if (this.state.hasNoData) {
          data = []
        }

        const newLayoutClone = cloneDeep(newLayout)

        // do not compare shapes
        delete newLayoutClone.shapes

        if (!newLayoutClone.equals(this.prevLayout || {})) {
          this.isPlotUpToDate = false
        }

        this.prevLayout = newLayoutClone

        const { overWrapper } = this.state
        const { xDomain = [ 0, 0 ], yDomain = [ 0, 0 ] } = this.props

        // const { xDomain, yDomain } = logic.getDomain(data, flipAxes)

        let didUsePlotly = false

        // eslint-disable-next-line no-undef
        const start = performance.now()

        try {
          if (
            forceFast ||
            (
              NetworkManager.isDataSourceServer &&
              /(line|area|bar)/.test(type) &&
              !overWrapper &&
              this.isPlotUpToDate
            )
          ) {
            if (/(line|area)/.test(type)) {
              this.line.draw(plot, data, xDomain, yDomain, flipAxes)

              if (type === 'area') {
                this.fill.draw(plot, data, xDomain, yDomain, flipAxes)
              }
            }
            else if (type === 'bar') {
              this.bar.draw(plot, data, xDomain, yDomain, flipAxes)
            }

            this.shape.draw(plot, data, xDomain, yDomain, flipAxes, newLayout)
          }
          else {
            this.isPlotUpToDate = true
            didUsePlotly = true

            Plotly.react(plot, data, newLayout)
          }
        }
        catch (e: any) {
          // eslint-disable-next-line no-console
          console.log(e)

          if (e.message === FastBase.TRACE_MISSING) {
            didUsePlotly = true

            Plotly.react(plot, data, newLayout)
          }
        }

        // eslint-disable-next-line no-undef
        const end = performance.now()

        if ((window as any).logPlot) {
          // eslint-disable-next-line no-console
          console.log(tileConfigs[tileId].name, 'drawn in', end - start, 'ms using', didUsePlotly ? 'Ploty' : 'Fast')
        }
      })
    }
  };

  render () {
    const { overWrapper, hasNoData } = this.state
    const { tileId } = this.props

    return (
      <>
        <Wrapper
          overWrapper={overWrapper}
          isDataSourceServer={NetworkManager.isDataSourceServer}
          id={`plot_${tileId}`}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
        {hasNoData && <PlotInfo>No Data available</PlotInfo>}
      </>
    )
  }
}

export default connector(PlotlyWrapper as any)
