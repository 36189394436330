/* eslint-env browser */
import ApiClient from '../../../store/apiClient'
import { Network } from '../../../network/Network'
import fileDialog from 'file-dialog'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const saveSync = require('save-file').saveSync

export default class Util {
  static async openUploadFileDialog (
    fileExtension: string | string[],
    uploadPath: string,
    prepareCallback?: (data?: any) => void,
    setLoadingStatus?: (loading: boolean) => void,
  ) {
    const files: FileList = await fileDialog({ accept: fileExtension as string })

    if (setLoadingStatus) {
      setLoadingStatus(true)
    }

    if (!files || !files[0]) {
      throw new Error('No file selected')
    }

    const data = new FormData()

    data.append('file', files[0])

    if (prepareCallback) {
      prepareCallback(data)
    }

    const response = await ApiClient.post(`${Network.URI}${uploadPath}`, { data })

    return {
      ...response,
      fileName: files[0].name,
      absoluteFilePath: response.isDocker ? response.filePath : files[0].path,
    }
  }

  static openDownloadFileDialog (data: string, fileName: string): Promise<void> {
    const buffer = Buffer.from(data, 'utf8')

    // don't use await save(buffer, fileName) since it does not resolve properly
    saveSync(buffer, fileName)

    return Promise.resolve()
  }
}
