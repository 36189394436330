import * as THREE from 'three'

import Util from '../logic/Util'
import LodUtil from '../logic/LodUtil'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Mold from './Mold'
import { AnalyzeTime } from 'Util'

export default class Roller extends BaseObject {
  static _3DGeometryCache: Record<string, THREE.CylinderGeometry> = {}
  static _2DGeometryCache: THREE.PlaneGeometry

  static defaultMaterial1 = new THREE.MeshStandardMaterial({ color: '#94bfe0', transparent: true, opacity: 0.6 })
  static defaultMaterial2 = new THREE.MeshStandardMaterial({ color: '#e09494', transparent: true, opacity: 0.6 })
  static phantomMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.4 })
  static selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.75 })
  static deletedMaterial = new THREE.MeshStandardMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })
  static selectedDeletedMaterial = new THREE.MeshStandardMaterial({ color: '#da4515', transparent: true, opacity: 0.6 })
  clickableObjects: any
  radius: number
  isPhantom?: boolean
  rollerData?: any
  sectionDetail?: any
  plCoord?: number
  type = 'Roller'

  static DisplayTypes = {
    All: 0,
    Roller: 1,
    RollerChildren: 2,
  }

  static DetailDistance = [ 0, 3, 8 ]

  @AnalyzeTime(0)
  static get3DGeometry (r: number, rollerWidth: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${r}_${rollerWidth}_${radial}`
    let geometry = Roller._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(r, r, rollerWidth, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD)

      ;(THREE.GeometryUtils as any).computeVertexNormals(geometry, 80)

      Roller._3DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  @AnalyzeTime(0)
  static get2DGeometry () {
    let geometry = Roller._2DGeometryCache

    if (!geometry) {
      geometry = new THREE.PlaneGeometry(1, 1, 1, 1)

      Roller._2DGeometryCache = geometry
    }

    return geometry
  }

  constructor (container: any, parent: any, clickableObjects: any, phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects

    this.objects.roller = new THREE.Group()

    this.radius = 0.0001

    container.add(this.objects.roller)
  }

  @AnalyzeTime(0)
  dispose (): void {
    super.dispose()

    for (const element of this.clickableObjects) {
      if (element.material && element.material.dispose) {
        element.material.dispose()
      }

      if (element.geometry && element.geometry.dispose) {
        element.geometry.dispose()
      }
    }

    for (const element of this.container.children) {
      if (element.material && element.material.dispose) {
        element.material.dispose()
      }

      if (element.geometry && element.geometry.dispose) {
        element.geometry.dispose()
      }

      this.container.remove(element)
    }

    this.objects = {}
    this.clickableObjects = []
    this.container = undefined
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    rollerData: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _: any,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.isPhantom = isPhantom
    this.rollerData = rollerData
    this.sectionDetail = sectionDetail

    if (sectionDetail) {
      let updateY = false

      if (this.plCoord !== plHeight) {
        this.plCoord = plHeight
        updateY = true
      }

      this._renderSectionDetail(rollerData, path, updateY)
    }
    else {
      this._renderNormal(rollerData, path, isDeleted)
    }
  }

  @AnalyzeTime(0)
  updateTransform () {
    const { roller } = this.objects
    const { side } = this.container.userData
    const { _roll_width: rollWidth } = this.rollerData
    const { position, angleX, normal } =
      PasslineCurve.getInfoAtPlCoord(this.plCoord || 0, this.sectionDetail ? true : undefined)
    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    // TODO: Use when needed
    // const rollWidthIs = this.sectionDetail ? this.rollerData._roll_width_is / 1000 : 0
    const rollWidthIs = 0

    switch (side) {
      case 'FixedSide':
        newPosition.set(0, position.y, position.z + FixedSide.x - rollWidthIs)
        newPosition.add(normal.clone().setLength(FixedSide.z + this.radius))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case 'LooseSide':
        newPosition.set(0, position.y, position.z + LooseSide.x + rollWidthIs)
        newPosition.add(normal.clone().setLength(LooseSide.z - this.radius))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        break
      case 'NarrowFaceRight':
        newPosition.set(NarrowFaceRight.x - this.radius - rollWidthIs, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - (rollWidth / 1000 / 2)))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        break
      case 'NarrowFaceLeft':
        newPosition.set(NarrowFaceLeft.x + this.radius + rollWidthIs, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - (rollWidth / 1000 / 2)))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        break
      default:
    }

    roller.position.copy(newPosition)
    roller.rotation.copy(newRotation)
  }

  @AnalyzeTime(0)
  getMeasures () {
    const { position: { y } } = this.objects.roller
    const { geometry } = this.objects[LodUtil.LOD_ROLLER].children[0]

    geometry.computeBoundingBox()

    const { min, max } = geometry.boundingBox

    return {
      heightMin: y + min.y,
      heightMax: y + max.y,
    }
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    const { deleted } = this.objects.roller.userData

    if (this.isPhantom || this.sectionDetail) {
      return
    }

    const parentInfo = Util.getParentInfo(this.container.userData.path)
    const isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    this.objects.roller.userData.selected = isSelected
    this.objects[LodUtil.LOD_ROLLER].children.forEach((child: any) => {
      const visible = child.material.visible

      child.material = (
        isSelected
          ? (deleted ? Roller.selectedDeletedMaterial : Roller.selectedMaterial)
          : (
            deleted
              ? Roller.deletedMaterial
              : (isSegmentGroupIDEven ? Roller.defaultMaterial1 : Roller.defaultMaterial2)
          )
      ).clone()

      child.material.visible = visible
    })
  }

  @AnalyzeTime(0)
  _setVisibility (
    roller: any,
    rollerTextA: any,
    rollerTextB: any,
    bodies: any,
    bearings: any,
    rollerVisible: boolean,
    textVisible: boolean,
    childrenVisible: boolean,
  ) {
    roller.children.forEach((child: any) => {
      child.material.visible = rollerVisible
    })

    if (!this.isPhantom && !this.sectionDetail) {
      rollerTextA.material.visible = textVisible
      rollerTextB.material.visible = textVisible
    }

    bodies.forEach((body: any) => {
      body.parent.children.filter((child: any) => /^Roller_Body_Number_/.test(child.name)).forEach((child: any) => {
        child.material.visible = childrenVisible
      })

      body.visible = childrenVisible
    })

    bearings.forEach((bearing: any) => {
      bearing.parent.children
        .filter((child: any) => /^Roller_Bearing_Number_/.test(child.name)).forEach((child: any) => {
          child.material.visible = childrenVisible
        })

      bearing.visible = childrenVisible
    })
  }

  @AnalyzeTime(0)
  setVisibility (displayType: number) {
    const { [LodUtil.LOD_ROLLER]: lod, roller, rollerGroupText, rollerGroupTextBackside } = this.objects

    const bodies = roller.children.filter((element: any) =>
      element.type === 'RollerBody' && !~element.name.indexOf('Phantom') && !element.userData.hidden)

    const bearings = roller.children.filter((element: any) =>
      element.type === 'RollerBearing' && !~element.name.indexOf('Phantom') && !element.userData.hidden)

    if (this.sectionDetail) {
      this._setVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, false, false, true)

      return
    }

    switch (displayType) {
      case Roller.DisplayTypes.All:
        this._setVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, true, false, true)
        break
      case Roller.DisplayTypes.Roller:
        this._setVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, true, true, false)
        break
      case Roller.DisplayTypes.RollerChildren:
        this._setVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, false, false, true)
        break
      default:
    }
  }

  @AnalyzeTime(0)
  _renderNormal (rollerData: any, path: string, isDeleted: boolean) {
    const { _diameter, _passln_coord: plCoord, _roll_width: rollWidth, _id } = rollerData
    const { roller } = this.objects

    const rollerWidth = rollWidth / 1000
    const passlnCoord = plCoord / 1000
    const r = _diameter / 1000 / 2

    this.plCoord = passlnCoord
    this.radius = r

    if (!this.isPhantom) {
      const rollerGroupText = Util.getText(_id, 0.08, false, true)
      const rollerGroupTextBackside = rollerGroupText.clone()

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.name = `Roller_${_id}`
      rollerGroupText.userData.number = _id
      rollerGroupText.position.set(rollerWidth / 2 + 0.0001, 0, 0)

      this.objects.rollerGroupText = rollerGroupText

      rollerGroupTextBackside.rotateY(-Util.RAD90)
      rollerGroupTextBackside.name = `Roller_Backside_${_id}`
      rollerGroupTextBackside.position.set((rollerWidth / 2 + 0.0001) * -1, 0, 0)

      this.objects.rollerGroupTextBackside = rollerGroupTextBackside

      Util.addOrReplace(roller, this.objects.rollerGroupText)
      Util.addOrReplace(roller, this.objects.rollerGroupTextBackside)
    }

    const parentInfo = Util.getParentInfo(this.container.userData.path)
    const isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works
    const material = (
      !this.isPhantom
        ? (isSegmentGroupIDEven ? Roller.defaultMaterial1 : Roller.defaultMaterial2)
        : Roller.phantomMaterial
    ).clone()

    const lod = new THREE.LOD()

    for (let i = 0; i < 3; i++) {
      const geometry = Roller.get3DGeometry(r, rollerWidth, i, this.isPhantom)

      const mesh = new THREE.Mesh(geometry, material)

      mesh.name = `${path}_${i}${this.isPhantom ? '_Phantom' : ''}`
      mesh.type = 'Roller'
      ;(mesh as any).path = path

      lod.addLevel(mesh, Roller.DetailDistance[i])

      if (!this.isPhantom) {
        const oldElement = this.objects[LodUtil.LOD_ROLLER]
          ? this.objects[LodUtil.LOD_ROLLER].getObjectByName(mesh.name)
          : null

        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerLoD = lod

    rollerLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    rollerLoD.rotation.x = -90 * Util.RAD;
    (rollerLoD as any).type = 'Roller';
    (rollerLoD as any).path = path // TODO: review

    this.objects[LodUtil.LOD_ROLLER] = rollerLoD

    roller.type = 'Roller'
    roller.path = path

    this.updateTransform()
    this.setVisibility(this.isPhantom ? Roller.DisplayTypes.Roller : Roller.DisplayTypes.RollerChildren)

    this.objects.roller.userData.deleted = isDeleted
    this.objects.roller.userData.width = rollWidth

    Util.addOrReplace(roller, this.objects[LodUtil.LOD_ROLLER])

    this.setSelected(this.objects.roller.userData.selected)
  }

  @AnalyzeTime(0)
  _renderSectionDetail (rollerData: any, path: string, updateY = false) {
    // TODO: this is not needed since roller don't have 2D ...

    const { roller } = this.objects
    const lod = new THREE.LOD()
    const { _diameter, _passln_coord: plCoord } = rollerData
    const passlnCoord = plCoord / 1000
    const r = _diameter / 1000 / 2

    this.plCoord = passlnCoord
    this.radius = r

    for (let i = 0; i < 3; i++) {
      // this is just dummy geometry so that the rest works ...
      const geometry = Roller.get2DGeometry()

      const mesh = new THREE.Mesh(geometry, Roller.defaultMaterial1.clone())

      mesh.name = `${path}_${i}${this.isPhantom ? '_Phantom' : ''}`
      mesh.type = 'Roller';
      (mesh as any).path = path

      lod.addLevel(mesh, Roller.DetailDistance[i])

      if (!this.isPhantom) {
        const oldElement = this.objects[LodUtil.LOD_ROLLER]
          ? this.objects[LodUtil.LOD_ROLLER].getObjectByName(mesh.name)
          : null

        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerLoD = lod

    rollerLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    rollerLoD.rotation.x = -90 * Util.RAD;
    (rollerLoD as any).type = 'Roller';
    (rollerLoD as any).path = path

    this.objects[LodUtil.LOD_ROLLER] = rollerLoD

    roller.type = 'Roller'
    roller.path = path

    if (updateY) {
      const { position } = PasslineCurve.getInfoAtPlCoord(this.plCoord || 0, this.sectionDetail ? true : undefined)

      roller.position.y = position.y
    }

    Util.addOrReplace(roller, this.objects[LodUtil.LOD_ROLLER])
  }
}
