import ThreeUtil from '../../../three/logic/Util'
/* eslint-disable camelcase */
import { PARENT } from '../../../react/context/AllInOne/consts'
import CalculateHelperFunctions from '../../../react/context/form/functions/CalculateHelperFunctions'
import Util from '../../../logic/Util'
import cloneDeep from 'clone-deep'
import type { ElementsHashes } from 'types/state'
import { deleteAllElements, deleteElement } from './deleters'
import { addAllElementsFromNew, _hasChildren, _prepareObject } from 'store/data/logic'
import { getChildrenArrayByIds } from '../logic'

export function getPathByTypeAndId (type: CasterElementNames, id: number, elementsHashes: ElementsHashes) {
  const path = []
  let currentType: CasterElementNames | undefined | null = type
  let currentId = id

  do {
    path.push(`${currentType}:${currentId}`)
    currentId = (elementsHashes[currentType] || {})[currentId]
    currentType = PARENT[currentType]
  } while (currentType)

  return path.reverse().join('/')
}

export function getNewIdForType (xmlGenParams: XMLGenParams, type: string): number {
  const key = `_lastTypeId_${type}`

  ;(xmlGenParams as any)[key] = Number((xmlGenParams as any)[key] || '0') + 1

  return (xmlGenParams as any)[key]
}

export function buildChangeItemHash (xmlData: XMLData, changeItemHash: ChangeItemHash) {
  let changeItems = xmlData.root.EditsBy3DCC?.ChangeItem

  if (!changeItems) {
    return
  }

  if (!Array.isArray(changeItems)) {
    changeItems = [ changeItems ]
  }

  for (const changeItem of changeItems) {
    changeItemHash[changeItem._uuid] = changeItem
  }
}

export function buildCCElementHash (xmlData: XMLData, ccElementHash: CCElementHash) {
  let ccElements = xmlData.root.CCResult?.CCElement

  if (!ccElements) {
    return
  }

  if (!Array.isArray(ccElements)) {
    ccElements = [ ccElements ]
  }

  for (const ccElement of ccElements) {
    ccElementHash[ccElement._uuid] = ccElement
  }
}

export function parseStrand (data: any) {
  if (!data.root?.Caster?.Strand) {
    return
  }

  const { DataPoint: dataPoints, DataLine: dataLines } = data.root.Caster.Strand

  const numberOfDataPoints = (dataPoints || []).length
  const numberOfDataLines = (dataLines || []).length

  for (let i = 0; i < numberOfDataPoints; i++) {
    if (dataPoints[i]._id === undefined) {
      const newId = getNewIdForType(data.root.XML_Generation_Parameters, 'DataPoint')

      dataPoints[i]._id = newId
      dataPoints[i]._numericId = newId
    }
  }

  for (let i = 0; i < numberOfDataLines; i++) {
    if (dataLines[i]._id === undefined) {
      const newId = getNewIdForType(data.root.XML_Generation_Parameters, 'DataLine')

      dataLines[i]._id = newId
      dataLines[i]._numericId = newId
    }

    const line = dataLines[i]

    if (line._ref) {
      continue
    }

    if (line._xCoords) {
      line._xCoords = line._xCoords.trim().split(' ').map((stringCoord: string) => Number(stringCoord))

      if (line._xCoords.length === 1 && isNaN(line._xCoords[0])) {
        line._xCoords = [ null ]
      }
    }
    else {
      line._xCoords = []
    }
  }
}

export function parseXmlData (data: any, elementData?: any) {
  const element = elementData || data.root.Caster.StrandGuide
  const keys = Object.keys(element)

  const fullSegment: any = [
    { _FixedLooseSide: 'FixedSide' },
    { _FixedLooseSide: 'LooseSide' },
    { _FixedLooseSide: 'NarrowFaceLeft' },
    { _FixedLooseSide: 'NarrowFaceRight' },
  ]

  for (let n = 0; n < keys.length; n++) {
    _prepareObject(element, keys[n])

    if (keys[n] === 'Segment' && element[keys[n]].length < 4) {
      const segment = [ ...fullSegment ]

      let index = 0

      fullSegment.forEach((seg: any) => {
        index = segment.indexOf(seg)

        let newElement = {}

        element[keys[n]].forEach((element: any) => {
          if (element._FixedLooseSide === seg._FixedLooseSide) {
            newElement = element
          }
        })

        segment[index] = Object.keys(newElement).length ? newElement : segment[index]

        if (segment[index]._id === undefined) {
          const newId = getNewIdForType(data, 'Segment')

          segment[index] = {
            ...element[keys[n]][0],
            ...seg,
            Nozzle: [],
            Roller: [],
            ...segment[index],
            _id: newId,
            _numericId: newId,
          }
        }
      })

      element[keys[n]] = segment
    }

    if (keys[n] === 'SegmentGroup') {
      const list = element[keys[n]]

      for (let i = 0; i < list.length; i++) {
        list[i].SupportPoint = list[i].SupportPoint || []
      }
    }

    if (keys[n] === 'Segment') {
      const list = element[keys[n]]

      for (let i = 0; i < list.length; i++) {
        list[i].Nozzle = list[i].Nozzle || []
        list[i].Roller = list[i].Roller || []
      }
    }

    if (_hasChildren(keys[n])) {
      for (let i = 0; i < element[keys[n]].length; i++) {
        parseXmlData(data, element[keys[n]][i])
      }
    }
  }
}

// TODO: refactor this
export function actionOverwriteAll (
  xmlData: XMLData,
  elementsHashes: ElementsHashes,
  dataTypes: CasterElementNames[],
  newXmlData: XMLData,
) {
  parseXmlData(newXmlData)
  deleteAllElements(xmlData.root.XML_Generation_Parameters, dataTypes, elementsHashes)
  addAllElementsFromNew(xmlData, newXmlData, dataTypes)
}

export function actionMergeNew (
  xmlData: XMLData,
  dataTypes: CasterElementNames[],
  newXmlData: XMLData,
) {
  parseXmlData(newXmlData)
  addAllElementsFromNew(xmlData, newXmlData, dataTypes)
}

export function updateSidesListWithRoller (
  sidesList: any[],
  _FixedLooseSide: any,
  path: string,
  element: any,
  previousElement: any,
) {
  sidesList[_FixedLooseSide] = sidesList[_FixedLooseSide] || {}

  sidesList[_FixedLooseSide][path] = {
    element,
    previousElement,
  }
}

export function setElementInElementsHashes (
  xmlGenParams: XMLGenParams,
  elementsHashes: ElementsHashes,
  type: CasterElementNames,
  element: any,
  previousElement: any,
  parentType: string,
  forceRebuildIds = false,
) {
  if (!(element instanceof Object)) {
    return
  }

  if ((forceRebuildIds || (!element._id && !element._index)) && type !== 'CoolingLoop') {
    element._id = getNewIdForType(xmlGenParams, type)
    element._numericId = element._id
  }

  // Each child type id array
  Object.keys(element).filter(key => (key[0] !== '_' && key[0] !== '#')).forEach(key => { // TODO: Fix '#' prefix
    if (!Array.isArray(element[key])) {
      element[key] = [ { ...element[key] } ]
    }

    element[`#${key}Ids`] = []

    element[key].forEach((child: any) => {
      if (key === 'CoolingLoop') {
        element[`#${key}Ids`].push(child._index)

        return
      }

      element[`#${key}Ids`].push(child._id)
    })
  })

  elementsHashes[type] = elementsHashes[type] || {} as any
  const filteredElement: any = {}

  Object.keys(element).filter(key => (key[0] === '_' || key[0] === '#')).forEach(key => {
    filteredElement[key] = element[key]
  }) // TODO: with this aproach xmlData doesnt change, all is passed as Value not reference

  const identifier = type === 'CoolingLoop' ? element._index : element._id

  ;(elementsHashes[type] as any)[identifier] = filteredElement

  if (previousElement) {
    (elementsHashes[type] as any)[identifier]['#parent'] = {
      id: previousElement._id,
      type: parentType,
    }
  }
}

function isHashableElementType (type: CasterElementNames) {
  return (
    [
      'StrandGuide',
      'AirLoop',
      'CoolingLoop',
      'CoolingZone',
      'LoopAssignment',
      'SegmentGroup',
      'Segment',
      'SupportPoint',
      'RollerBody',
      'RollerBearing',
      'Nozzle',
      'Roller',
      'SensorPoint',
      'DataPoint',
      'DataLine',
    ].includes(type)
  )
}

export function buildElementsHashes (xmlData: XMLData, elementsHashes: ElementsHashes, forceRebuildIds = false) {
  // eslint-disable-next-line camelcase
  const { Caster, XML_Generation_Parameters: parameters } = xmlData.root

  if (forceRebuildIds) {
    const keys = Object.keys(parameters || {})

    for (let i = 0; i < keys.length; i++) {
      if (/^_lastTypeId_/.test(keys[i])) {
        delete (parameters as any)[keys[i]]
      }
    }
  }

  if (Caster.Strand) {
    ThreeUtil.runPerElement(
      'Strand',
      'Strand',
      Caster.Strand,
      null,
      '',
      (path: string, type: CasterElementNames, element: any, previousElement: any, parentType: string) => {
        if (!(element instanceof Object)) {
          return
        }

        if (type as string === 'Strand') { // we don't need the Strand
          return
        }

        setElementInElementsHashes(
          parameters,
          elementsHashes,
          type,
          element,
          previousElement,
          parentType,
          forceRebuildIds,
        )
      },
    )
  }

  const sidesList: any = {}

  ThreeUtil.runPerElement(
    'StrandGuide',
    'StrandGuide',
    Caster.StrandGuide,
    null,
    '',
    (path: string, type: CasterElementNames, element: any, previousElement: any, parentType: string) => {
      if (!(element instanceof Object) || !isHashableElementType(type)) {
        return
      }

      if (type === 'Roller') {
        const { _FixedLooseSide } = elementsHashes.Segment[previousElement._id] || {}

        updateSidesListWithRoller(sidesList, _FixedLooseSide || '', path, element, previousElement)

        return
      }

      if ((forceRebuildIds || (!element._id && !element._index)) && type === 'CoolingLoop') {
        element._index = getNewIdForType(parameters, type)
      }

      setElementInElementsHashes(
        parameters,
        elementsHashes,
        type,
        element,
        previousElement,
        parentType,
        forceRebuildIds,
      )
    },
  )

  Caster.Mold.MoldFace?.forEach((moldFace, index) => {
    for (const sensorPoint of moldFace.SensorPoint) {
      setElementInElementsHashes(
        parameters,
        elementsHashes,
        'SensorPoint',
        sensorPoint,
        { _id: index },
        `MoldFace`,
        forceRebuildIds,
      )
    }

    for (const dataPoint of moldFace.DataPoint) {
      setElementInElementsHashes(
        parameters,
        elementsHashes,
        'DataPoint',
        dataPoint,
        { _id: index },
        `MoldFace`,
        forceRebuildIds,
      )
    }
  })

  ThreeUtil.sides.forEach(side => {
    const paths = Object.keys(sidesList[side] || {})

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i]
      const { element, previousElement } = sidesList[side][path]

      setElementInElementsHashes(
        parameters,
        elementsHashes,
        'Roller',
        element,
        previousElement,
        'Segment',
        forceRebuildIds,
      )
    }
  })
}

export function parsePasslineData (element: any) {
  const keys = Object.keys(element)

  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'section') {
      element[keys[i]] = element[keys[i]] instanceof Array ? element[keys[i]] : [ element[keys[i]] ]
    }
  }
}

function getElementByDataLineRef (ref: string, elementsHashes: any) {
  const refInfo = ref.split(':')

  if (refInfo.length !== 2) {
    return null
  }

  const [ type, id ] = refInfo

  const elementsTypeHash = elementsHashes[type]

  if (!elementsTypeHash) {
    return null
  }

  const element = elementsTypeHash[id] || null

  return element
}

function linkDataLinesWithElements (elementsHashes: ElementsHashes) {
  const dataLines = Object.keys(elementsHashes.DataLine || {})

  for (let i = 0; i < dataLines.length; i++) {
    const dataLine: any = elementsHashes.DataLine[Number(dataLines[i])]

    if (!dataLine || !dataLine._ref) {
      continue
    }

    const { _ref } = dataLine
    const element = getElementByDataLineRef(_ref, elementsHashes)

    if (
      !element ||
      typeof dataLine._xCoords !== 'string' ||
      typeof dataLine._yValues !== 'string' ||
      !element[`_${dataLine._xCoords}`] ||
      !element[`_${dataLine._yValues}`]
    ) {
      continue
    }

    dataLine[`_${dataLine._xCoords}`] = element[`_${dataLine._xCoords}`]
    dataLine[`_${dataLine._yValues}`] = element[`_${dataLine._yValues}`]
  }
}

export function actionNewData (
  xmlData: XMLData,
  elementsHashes: ElementsHashes,
  forceRebuildIds = false,
  changeItemHash?: ChangeItemHash,
  ccElementHash?: CCElementHash,
) {
  parseXmlData(xmlData)
  parseStrand(xmlData)

  if (changeItemHash) {
    buildChangeItemHash(xmlData, changeItemHash)
  }

  if (ccElementHash) {
    buildCCElementHash(xmlData, ccElementHash)
  }

  buildElementsHashes(xmlData, elementsHashes, forceRebuildIds)
  parsePasslineData(xmlData.root.Caster.Passline)
  linkDataLinesWithElements(elementsHashes)
}

export function checkPositivePropertyAndInKeyList (keys: string[], element: any, properties: string[]) {
  for (let i = 0; i < properties.length; i++) {
    if (!(keys.includes(properties[i]) && element[properties[i]] > 0)) {
      return false
    }

    return true
  }
  // properties.forEach(property => {
  //   if (!(keys.includes(property) && element[property] > 0)) {
  //     return false
  //   }
  // })
  // return true
}

export function positivePropertiesAndInKeyList (editValues: any, keys: string[], elementType: CasterElementNames) {
  let createValid = false

  switch (elementType) {
    case 'Nozzle':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Nozzle, [ '_height', '_anglewidth', '_anglelength' ])
      ) {
        createValid = true
      }

      break
    case 'Roller':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Roller, [ '_diameter', '_roll_width' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBody':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBody, [ '_diameter', '_width' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBearing':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBearing, [ '_width' ])
      ) {
        createValid = true
      }

      break
    default:
      createValid = false
  }

  return createValid
}

export function getElementFromPath (path: string | string[], elementsHashes: ElementsHashes, copy?: boolean) {
  if (!elementsHashes) {
    return undefined
  }

  const pathString = path instanceof Array ? Util.getPathFromPathArray(path) : path

  return Util.getElement(pathString, elementsHashes, copy)
}

export function getDifferenceElement (paths: string[], editElements: any, elementsHashes: ElementsHashes) {
  let highestElement: any = {}
  let highestElementOriginal: any = { _passln_coord: Infinity }

  paths.forEach(path => {
    const element = getElementFromPath(path, elementsHashes, true)

    if (highestElementOriginal._passln_coord > element._passln_coord) {
      highestElementOriginal = element
      highestElement = { ...editElements[path] }
    }
  })

  const differenceElement: any = { ...highestElement }

  differenceElement._passln_coord -= highestElementOriginal._passln_coord
  differenceElement._width_coord -= highestElementOriginal._width_coord

  return differenceElement
}

export function getPathArrayFromPath (path: string) {
  return path.split('/').reduce((list: any, part) => (parts => ([
    ...list,
    parts[0],
    Number(parts[1]),
  ]))(part.split(':')), [])
}

export function getSegmentByPasslnAndSide (segmentHash: SegmentHash, passln: number, side: StrandSides) {
  const segmentHashCopy = { ...segmentHash }

  delete (segmentHashCopy as any)['#hasChanges']

  return Object.values(segmentHashCopy)
    .filter((segment: any) => Number(segment._passln_coord) <= Number(passln) && segment._FixedLooseSide === side)
    .sort((a: any, b: any) => Number(b._passln_coord) - Number(a._passln_coord))[0]
}

export function getSegmentGroupByPassln (SegmentGroupHash: SegmentGroupHash, passln: number) {
  const segmentGroupHashCopy = { ...SegmentGroupHash }

  delete (segmentGroupHashCopy as any)['#hasChanges']

  return Object.values(segmentGroupHashCopy).filter((segGroup: any) => Number(segGroup._passln_coord) <= Number(passln))
    .sort((a: any, b: any) => Number(b._passln_coord) - Number(a._passln_coord))[0]
}

export function getSegmentByRollerPassln (rollerList: Array<any>, passln: number) {
  const rollers = rollerList
    .filter(roller => roller._passln_coord > passln)
    .sort((a, b) => b._passln_coord - a._passln_coord)

  return rollers[rollers.length - 1]
}

export function getDefaultElement (element: any, elementType: string) {
  let newElement = { ...element[elementType] }

  switch (elementType) {
    case 'Roller':

      // eslint-disable camelcase
      newElement = {
        ...newElement,
        _passln_coord: newElement._passln_coord || 0,
        RollerBearing: [],
        RollerBody: [],
      }

      // eslint-disable-next-line camelcase
      const { _roll_width: rollerWidth, _diameter, _passln_coord } = newElement
      const RollerBody = {
        _width: rollerWidth,
        _width_start: (rollerWidth / 2) * -1,
        _name: 'DSCRollerBody',
        _diameter,
        _passln_coord,
      }

      const RollerBearing = [
        {
          _width: 100,
          _width_start: rollerWidth / 2,
          _name: '',
          _passln_coord,
        },
        {
          _width: 100,
          _width_start: -(rollerWidth / 2) - 100,
          _name: '',
          _passln_coord,
        },
      ]
      // eslint-enable camelcase

      newElement.RollerBody.push(RollerBody) // TODO: make sure new elements get new ids
      newElement.RollerBearing.push(...RollerBearing) // TODO: make sure new elements get new ids

      break
    default:
  }

  return newElement
}

export function getGapPositions (
  elementsHashes: ElementsHashes,
  element: any,
  targetArray: any[],
  offset: number,
  amount: number,
) {
  const gapPositions = []
  const segmentSide = (elementsHashes.Segment[targetArray[3]] || {})._FixedLooseSide || ''

  const rollerList = CalculateHelperFunctions
    .getRollerList(elementsHashes, targetArray[1], segmentSide, [])
    .sort((a, b) => a._passln_coord - b._passln_coord)

  const startCoord = Number(element._passln_coord)
  let list = []

  if (offset > 0) {
    list = rollerList.filter(val => val._passln_coord > startCoord)
  }
  else {
    list = rollerList.filter(val => val._passln_coord < startCoord).reverse()
  }

  for (let i = Math.abs(offset) - 1; i < list.length - 1; i += Math.abs(offset)) {
    if (offset > 0) {
      const a = Number(list[i]._passln_coord) + Number(list[i]._diameter) / 2
      const b = Number(list[i + 1]._passln_coord) - Number(list[i + 1]._diameter) / 2

      gapPositions.push(a + ((b - a) / 2))
    }
    else {
      const a = Number(list[i]._passln_coord) - Number(list[i]._diameter) / 2
      const b = Number(list[i + 1]._passln_coord) + Number(list[i + 1]._diameter) / 2

      gapPositions.push(a - ((a - b) / 2))
    }

    if (gapPositions.length === amount) {
      break
    }
  }

  return { gapPositions, list }
}

export function getAddedObject (addedObject: any, rootData: RootData, elementType: CasterElementNames) {
  const object = addedObject

  object._numericId = getNewIdForType(rootData.XML_Generation_Parameters, elementType)
  object._id = object._numericId

  return object
}

export function deleteListFunction (deleteList: string[], elementsHashes: ElementsHashes) {
  deleteList.forEach(deletePath => {
    const [ type, id ] = deletePath.substring(deletePath.lastIndexOf('/') + 1).split(':')

    deleteElement(type as CasterElementNames, parseInt(id), elementsHashes)
  })
}

export function getDistanceToPassLine (
  initialValue: number,
  fixedLooseSide: string,
  radius: number,
  thickness: number,
  width: number,
) {
  let distance2passline = initialValue

  switch (fixedLooseSide) {
    case 'FixedSide': distance2passline = radius
      break
    case 'LooseSide': distance2passline = radius + thickness
      break
    case 'NarrowFaceLeft':
    case 'NarrowFaceRight': distance2passline = radius + (width / 2)
      break
    default:
  }

  return distance2passline
}

export function getAddedElementPathWithoutNewParent (
  parentPathArray: string[],
  elementType: CasterElementNames,
  counter: number,
) {
  const newParentPath = Util.getPathFromPathArray(parentPathArray)

  return `${newParentPath}/${elementType}:-${counter}`
}

export function handleWaterFluxFraction (data: any) {
  const { Mold } = data.root.Caster
  const { SegmentGroup } = data.root.Caster.StrandGuide
  const coolingLoopAssignments: any = {}

  ThreeUtil.runPerElement(
    'SegmentGroup',
    'SegmentGroup',
    SegmentGroup,
    null,
    '',
    (path: string, type: CasterElementNames, element: any) => {
      if (type === 'Nozzle') {
        coolingLoopAssignments[Number(element._loop)] = (coolingLoopAssignments[Number(element._loop)] || 0) + 1
      }
    },
  )

  ThreeUtil.runPerElement(
    'SegmentGroup',
    'SegmentGroup',
    SegmentGroup,
    null,
    '',
    (path: string, type: CasterElementNames, element: any, previous: any) => {
      if (type === 'Nozzle') {
      // eslint-disable-next-line camelcase
        element._water_flux_fraction = (1 / coolingLoopAssignments[Number(element._loop)]).toFixed(4)

        return
      }

      if (type === 'Roller') {
        const { _thickness, _width } = Mold
        const { _FixedLooseSide } = previous
        const radius = Number(element._diameter) / 2

        const distanceToPassline = getDistanceToPassLine(
          -1,
          _FixedLooseSide,
          radius,
          Number(_thickness),
          Number(_width),
        )

        element._dist2passline = distanceToPassline
      }
    },
  )

  return coolingLoopAssignments
}

export function saveRollerElements (
  type: string,
  newElement: any,
  path: string,
  elementsHashes: ElementsHashes,
  rollerChildrenPath: string[],
) {
  newElement[type].forEach((element: any) => {
    const rollerElementPath = `${path}/${type}:${element._id}`
    const originalRollerBody = getElementFromPath(rollerElementPath, elementsHashes)

    if (type === 'body') {
      originalRollerBody._diameter = newElement._diameter
    }

    // eslint-disable-next-line camelcase
    originalRollerBody._passln_coord = newElement._passln_coord

    rollerChildrenPath.push(rollerElementPath)
  })
}

export function completeTargetPathArray (
  rawPath: string | string[],
  targetPath: string | string[],
  elementsHashes: ElementsHashes,
) {
  const path = rawPath instanceof Array ? Util.getPathFromPathArray(rawPath) : rawPath
  const targetArray = targetPath instanceof Array ? targetPath : getPathArrayFromPath(targetPath)

  const targetArrayComplete = [ ...targetArray ]

  if (targetArrayComplete.length === 2) {
    const currentParentPath = path.substring(0, path.lastIndexOf('/'))
    const { _FixedLooseSide } = getElementFromPath(currentParentPath, elementsHashes)
    const [ sg, sgId ] = targetArrayComplete
    const parentParentPath = `${sg}:${sgId}`
    const parentParentElement = getElementFromPath(parentParentPath, elementsHashes)
    const parentElement = parentParentElement.Segment
      .filter((segment: any) => segment._FixedLooseSide === _FixedLooseSide)[0]

    targetArrayComplete.push('Segment')
    targetArrayComplete.push(parentElement._id)
  }

  return targetArrayComplete
}

export function setElement (
  elementsHashes: ElementsHashes,
  type: CasterElementNames,
  element: any,
  parentType: CasterElementNames,
  parentId: number,
  pushToList = true,
) {
  if (!elementsHashes[type]) {
    elementsHashes[type] = {}
  }

  if (!elementsHashes[parentType]) {
    elementsHashes[parentType] = {}
  }

  element['#parent'] = {
    type: parentType,
    id: parentId,
  }
  elementsHashes[type]['#hasChanges'] = true
  elementsHashes[type][element._id] = element
  // elementsHashes[parentType][element._id] = element

  if (pushToList) {
    if (!elementsHashes[parentType][parentId][`#${type}Ids`]) {
      elementsHashes[parentType][parentId][`#${type}Ids`] = []
    }

    elementsHashes[parentType][parentId][`#${type}Ids`].push(element._id)
  }
}

export function save (
  {
    elementsHashes,
    dirtyPaths,
    editElements,
    loopCounter,
  } : {
  elementsHashes: ElementsHashes,
  dirtyPaths: string[],
  editElements: any[],
  loopCounter: LoopCounter,
  },
  path: string,
  isRoller: boolean,
  targetArray?: any[],
) {
  const pathArray = getPathArrayFromPath(path)

  const originalElement = getElementFromPath(pathArray, elementsHashes)
  const newElement = editElements[path as any]
  const [ type, id ] = pathArray.slice(-2)

  if ((elementsHashes as any)[type]) {
    (elementsHashes as any)[type]['#hasChanges'] = true
  }

  const oldLoop = originalElement._loop
  const newLoop = newElement._loop

  if (oldLoop !== newLoop) {
    loopCounter[oldLoop] -= 1
    loopCounter[newLoop] = (loopCounter[newLoop] || 0) + 1
  }

  if (isRoller) {
    const rollerChildrenPath: string[] = []

    if (newElement.RollerBearing && newElement.RollerBearing.length) {
      saveRollerElements('RollerBearing', newElement, path, elementsHashes, rollerChildrenPath)
      ;(elementsHashes as any).RollerBearing['#hasChanges'] = true
    }

    if (newElement.RollerBody && newElement.RollerBody.length) {
      saveRollerElements('RollerBody', newElement, path, elementsHashes, rollerChildrenPath)
      ;(elementsHashes as any).RollerBody['#hasChanges'] = true
    }

    dirtyPaths.push(...rollerChildrenPath)
  }

  if (!targetArray) {
    // TODO: this should be outside this if, but is causes the value like passln_coord to jump back
    Object.keys(newElement).forEach(key => {
      originalElement[key] = newElement[key]
    })

    return
  }

  const targetArrayComplete = completeTargetPathArray(path, targetArray, elementsHashes)
  const [ parentType, parentId ] = targetArrayComplete.slice(-2)

  setElement(elementsHashes, type, newElement, parentType, parentId)

  const { type: oldParentType, id: oldParentId } = ThreeUtil.getParentInfo(path)
  const oldParentListIds = (elementsHashes as any)[oldParentType][oldParentId][`#${type}Ids`]

  oldParentListIds.splice(oldParentListIds.findIndex((oldId: number) => oldId === id), 1)

  const currentParentPath = Util.getPathFromPathArray(targetArrayComplete)

  return `${currentParentPath}/${type}:${newElement._id}`
}

export function handleSuccessfullySavedPaths (
  paths: string[],
  type: string,
  parentPath: string | null,
  targetArray: any[],
  dirtyPaths: string[],
  dirtyDeletePaths: string[],
  selectedDataArray: string[],
  hidePaths: string[],
  editElements: any,
  elementsHashes: ElementsHashes,
  loopCounter: LoopCounter,
) {
  const args = {
    elementsHashes,
    dirtyPaths,
    editElements,
    loopCounter,
  }

  paths.forEach(path => {
    const pathArray = getPathArrayFromPath(path)

    if (type !== 'delete') {
      if (!parentPath || path.indexOf(parentPath) === 0) {
        save(args, path, pathArray.slice(-2)[0] === 'Roller')
      }
      else {
        const newPath = save(args, path, pathArray.slice(-2)[0] === 'Roller', targetArray)

        if (!newPath) {
          return
        }

        dirtyPaths.push(newPath)
        dirtyDeletePaths.push(path)
        selectedDataArray = selectedDataArray.filter(sPath => sPath !== path)
        selectedDataArray.push(newPath)
      }

      if (~dirtyDeletePaths.indexOf(path) && !~hidePaths.indexOf(path)) {
        hidePaths.push(path)
        selectedDataArray = selectedDataArray.filter(sPath => sPath !== path)
        delete editElements[path]
      }
    }
    else {
      const [ type, id ] = pathArray.slice(-2)

      if (type === 'Nozzle') {
        const element = getElementFromPath(path, elementsHashes)

        loopCounter[element._loop] -= 1
      }

      deleteElement(type, id, elementsHashes)
      const index = dirtyDeletePaths.indexOf(path)

      if (~index) {
        dirtyDeletePaths.splice(index, 1)
      }
    }

    dirtyPaths.push(path)
  })

  return selectedDataArray
}

export function setRollerBearingData (
  object: any,
  rootData: RootData,
  elementsHashes: ElementsHashes,
  path: string,
  rollerChildrenPath: string[],
) {
  object.RollerBearing.forEach((rollerBearing: RollerBearing) => {
    // eslint-disable-next-line camelcase
    rollerBearing._passln_coord = object._passln_coord

    rollerBearing._numericId = getNewIdForType(rootData.XML_Generation_Parameters, 'RollerBearing')
    rollerBearing._id = rollerBearing._numericId

    setElement(elementsHashes, 'RollerBearing', rollerBearing, 'Roller', object._id, false)

    const rollerBearingPath = `${path}/RollerBearing:${rollerBearing._id}`

    rollerChildrenPath.push(rollerBearingPath)
  })
}

// TODO: fix
export function setRollerBodyData (
  object: any, rootData: RootData,
  elementsHashes: ElementsHashes,
  path: string,
  rollerChildrenPath: string[],
) {
  object.RollerBody.forEach((rollerBody: any) => {
    // eslint-disable-next-line camelcase
    rollerBody._passln_coord = object._passln_coord
    rollerBody._diameter = object._diameter

    rollerBody._numericId = getNewIdForType(rootData.XML_Generation_Parameters, 'RollerBody')
    rollerBody._id = rollerBody._numericId

    setElement(elementsHashes, 'RollerBody', rollerBody, 'Roller', object._id, false)

    const rollerBodyPath = `${path}/RollerBody:${rollerBody._id}`

    rollerChildrenPath.push(rollerBodyPath)
  })
}

export function setRollerChildrenData (
  rootData: RootData,
  parentId: number,
  elementsHashes: ElementsHashes,
  object: any,
  path: string,
  dirtyPaths: string[],
) {
  const { _thickness, _width } = rootData.Caster.Mold
  const { _FixedLooseSide } = elementsHashes.Segment[parentId] || {}
  const radius = Number(object._diameter) / 2

  object._dist2passline = getDistanceToPassLine(0, _FixedLooseSide || '', radius, _thickness, _width)

  const rollerChildrenPath: string[] = []

  if (object.RollerBearing && object.RollerBearing.length) {
    setRollerBearingData(object, rootData, elementsHashes, path, rollerChildrenPath)
  }

  if (object.RollerBody && object.RollerBody.length) {
    setRollerBodyData(object, rootData, elementsHashes, path, rollerChildrenPath)
  }

  dirtyPaths.push(...rollerChildrenPath)
}

export function handleSuccessfullyAddedElements (
  addedElements: any,
  rootData: RootData,
  elementType: CasterElementNames,
  elementsHashes: ElementsHashes,
  editElements: any,
  dirtyPaths: string[],
  selectedDataArray: string[],
) {
  Object.keys(addedElements).forEach(rawPath => {
    const { path: parentPath, type: parentType, id: parentId } = ThreeUtil.getParentInfo(rawPath)
    const object = getAddedObject(addedElements[rawPath], rootData, elementType)

    setElement(elementsHashes, elementType, object, parentType as CasterElementNames, parentId)

    const path = `${parentPath}/${elementType}:${object._id}`

    editElements[path] = cloneDeep(object)

    dirtyPaths.push(path)
    selectedDataArray.push(path)

    if (elementType === 'Roller') {
      setRollerChildrenData(rootData, parentId, elementsHashes, object, path, dirtyPaths)
    }
  })
}

export function compareAndUpdateElementKeys (data: any, element: any) {
  Object.keys(data).forEach(key => {
    if (!/^_(parent|numeric|original)?id$/i.test(key)) {
      element[key] = data[key]
    }
  })
}

function handleUpdate (
  change: { elementType: CasterElementNames,
    data: any},
  elementsHashes: ElementsHashes,
  dirtyDeletePaths: string[],
  dirtyPaths: string[],
  selectedDataArray: string[],
) {
  const { elementType, data } = change
  let element = (elementsHashes[elementType] as any)[data._numericId]

  if (data._parentId && data._parentId !== elementsHashes[elementType][data._numericId]) {
    const path = getPathByTypeAndId(elementType, data._numericId, elementsHashes)

    if (dirtyDeletePaths.indexOf(path) === -1) {
      dirtyDeletePaths.push(path)
    }

    element = cloneDeep(element)

    deleteElement(elementType, data._numericId, elementsHashes)
    setElement(elementsHashes, elementType, element, PARENT[elementType] as any, data._parentId)

    element = (elementsHashes[elementType] as any)[data._numericId]
  }

  compareAndUpdateElementKeys(data, element)

  const path = getPathByTypeAndId(elementType, data._numericId, elementsHashes)

  if (dirtyPaths.indexOf(path) === -1) {
    dirtyPaths.push(path)
  }

  // TODO: FIX this is a bug
  selectedDataArray = selectedDataArray.filter(selectedPath => selectedPath !== path)
}

function handleCreate (
  change: { elementType: CasterElementNames, data: any },
  elementsHashes: ElementsHashes,
  dirtyPaths: string[],
) {
  const { elementType, data } = change

  data._originalId = data._id
  data._id = data._numericId

  setElement(elementsHashes, elementType, data, PARENT[elementType] as any, data._parentId)

  const path = getPathByTypeAndId(elementType, data._numericId, elementsHashes)

  if (dirtyPaths.indexOf(path) === -1) {
    dirtyPaths.push(path)
  }
}

function handleDelete (
  change: { elementType: CasterElementNames, data: any },
  elementsHashes: ElementsHashes,
  dirtyDeletePaths: string[],
  selectedDataArray: string[],
) {
  const { elementType, data } = change
  const path = getPathByTypeAndId(elementType, data._numericId, elementsHashes)

  deleteElement(elementType, data._numericId, elementsHashes)

  if (dirtyDeletePaths.indexOf(path) === -1) {
    dirtyDeletePaths.push(path)
  }

  selectedDataArray = selectedDataArray.filter(selectedPath => selectedPath !== path) // TODO: possible Bug
}

export function emptyObjectByReference (object: any) {
  for (const property in object) {
    delete object[property]
  }
}

function handleReplaceNozzles (
  change: { elementType: CasterElementNames, data: any },
  elementsHashes: ElementsHashes,
  xmlData: XMLData,
  dirtyDeletePaths: string[],
  dirtyPaths: string[],
  hidePaths: string[],
) {
  const newData = cloneDeep(change.data)

  actionOverwriteAll(xmlData, elementsHashes, [ 'Nozzle' ], newData)
  // TODO: remove xmlData
  // to empty object by reference
  emptyObjectByReference(elementsHashes)
  buildElementsHashes(xmlData, elementsHashes)

  // to change array by reference
  dirtyPaths.length = 0
  dirtyDeletePaths.length = 0
  hidePaths.length = 0
}

function handleMergeNozzles (
  change: { elementType: CasterElementNames, data: any },
  dirtyDeletePaths: string[],
  dirtyPaths: string[],
  hidePaths: string[],
  xmlData: XMLData,
) {
  const newData = cloneDeep(change.data)

  actionMergeNew(xmlData, [ 'Nozzle' ], newData)

  dirtyPaths.length = 0
  dirtyDeletePaths.length = 0
  hidePaths.length = 0
}

function handleReplaceCaster (
  change: { elementType: CasterElementNames, data: any },
  elementsHashes: ElementsHashes,
  dirtyDeletePaths: string[],
  dirtyPaths: string[],
  hidePaths: string[],
  xmlData: XMLData,
) {
  xmlData = cloneDeep(change.data)
  // to empty object by reference
  emptyObjectByReference(elementsHashes)

  actionNewData(xmlData, elementsHashes)

  dirtyPaths.length = 0
  dirtyDeletePaths.length = 0
  hidePaths.length = 0
}

export function handleChangeType (
  change: { elementType: CasterElementNames, data: any, action: string },
  elementsHashes: ElementsHashes,
  dirtyDeletePaths: string[],
  dirtyPaths: string[],
  selectedDataArray: string[],
  hidePaths: string[],
  xmlData: XMLData,
) {
  switch (change.action) {
    // TODO: get parent change somehow...
    case 'update': return handleUpdate(change, elementsHashes, dirtyDeletePaths, dirtyPaths, selectedDataArray)
    case 'create': return handleCreate(change, elementsHashes, dirtyPaths)
    case 'delete': return handleDelete(change, elementsHashes, dirtyDeletePaths, selectedDataArray)
    case 'replaceNozzles': return handleReplaceNozzles(
      change,
      elementsHashes,
      xmlData,
      dirtyDeletePaths,
      dirtyPaths,
      hidePaths,
    )
    case 'mergeNozzles':
      return handleMergeNozzles(change, dirtyDeletePaths, dirtyPaths, hidePaths, xmlData)
    case 'replaceCaster':
      return handleReplaceCaster(change, elementsHashes, dirtyDeletePaths, dirtyPaths, hidePaths, xmlData)
    default: return true
  }
}

export function handleRepeatPaths (
  repeatPath: string[],
  targetArray: any[],
  elementsHashes: ElementsHashes,
  mode: string,
  addedElements: any,
  editElements: any,
  elementType: CasterElementNames,
  amount: number,
  offset: number,
) {
  let newParent = null
  let createElementCounter = 0
  let gapWarnings = 0

  repeatPath.forEach(rawPath => {
    const targetPath = targetArray.length > 3
      ? Util.getPathFromPathArray(targetArray)
      : ThreeUtil.getParentInfo(rawPath).path

    const element = getElementFromPath(rawPath, elementsHashes, true)

    switch (mode) {
      case 'mirror':

        addedElements[`${targetPath}/${elementType}:-${createElementCounter++}`] = {
          ...getElementFromPath(rawPath, elementsHashes, true),
          ...editElements[rawPath],
          // eslint-disable-next-line camelcase
          _width_coord: -Number(element._width_coord),
        }
        break
      case 'offset':
        for (let i = 1; i <= amount; i++) {
          let newParent = null
          let newPlCoord = 0

          newPlCoord = Number(element._passln_coord) + (Number(offset) * i)

          newParent = getSegmentByPasslnAndSide(elementsHashes.Segment, newPlCoord, element._FixedLooseSide)
          const parentPathArray = getPathArrayFromPath(targetPath)

          if (newParent !== undefined) {
            parentPathArray[3] = newParent._id
            parentPathArray[1] = (newParent as any)['#parent'].id
          }

          const addedElementPath = getAddedElementPathWithoutNewParent(
            parentPathArray,
            elementType,
            createElementCounter++,
          )

          addedElements[addedElementPath] = {
            ...getElementFromPath(rawPath, elementsHashes, true),
            ...editElements[rawPath],
            // eslint-disable-next-line camelcase
            _passln_coord: newPlCoord,
          }
        }

        break
      case 'gap offset':
        // TODO: rework this, use segment to get segment group
        const newTargetPath = getPathArrayFromPath(rawPath)
        const { gapPositions, list } = getGapPositions(elementsHashes, element, newTargetPath, offset, amount)

        for (let i = 0; i < amount; i++) {
          if (i >= gapPositions.length) {
            gapWarnings++

            continue
          }

          const rollerPassln = getSegmentByRollerPassln(list, gapPositions[i])

          newParent = getSegmentGroupByPassln(elementsHashes.SegmentGroup, rollerPassln._passln_coord)

          const parentPathArray = getPathArrayFromPath(targetPath)

          if (newParent) {
            const { _FixedLooseSide } = elementsHashes.Segment[Number(parentPathArray[3])] || {}

            const segments = ((newParent as any)['#SegmentIds'] || []).map((id: number) => elementsHashes.Segment[id])

            parentPathArray[3] = segments.filter((seg: any) =>
              seg._FixedLooseSide === _FixedLooseSide)[0]._numericId
            parentPathArray[1] = newParent._numericId
          }
          else {
            gapWarnings++

            continue
          }

          const addedElementPath = getAddedElementPathWithoutNewParent(
            parentPathArray,
            elementType,
            createElementCounter++,
          )

          addedElements[addedElementPath] = {
            ...getElementFromPath(rawPath, elementsHashes, true),
            ...editElements[rawPath],
            // eslint-disable-next-line camelcase
            _passln_coord: gapPositions[i],
          }
        }

        break
      default:
        break
    }
  })

  return { gapWarnings }
}

export function handleCopyPaths (
  copyPath: string[],
  targetArray: any[],
  elementsHashes: ElementsHashes,
  elementType: CasterElementNames,
  editElements: any,
  diffPasslnCoord: number,
  diffWidthCoord: number,
) {
  const addedElements: any = {}

  copyPath.forEach((rawPath, index) => {
    const targetArrayComplete = completeTargetPathArray(rawPath, targetArray, elementsHashes)

    const targetPath = targetArrayComplete.length
      ? Util.getPathFromPathArray(targetArrayComplete)
      : ThreeUtil.getParentInfo(rawPath).path
    const origElement = getElementFromPath(rawPath, elementsHashes, true)
    const { _passln_coord: orgPasslnCoord, _width_coord: orgWidthCoord } = origElement
    const element = {
      ...origElement,
      ...editElements[rawPath],
    }

    if (elementType === 'Roller') {
      // deleteChildrenId(elementType, element)
    }

    if (orgPasslnCoord) {
      // eslint-disable-next-line camelcase
      element._passln_coord = Number(orgPasslnCoord) + Number(diffPasslnCoord)
    }

    if (orgWidthCoord) {
      // eslint-disable-next-line camelcase
      element._width_coord = Number(orgWidthCoord) + Number(diffWidthCoord)
    }

    addedElements[`${targetPath}/${elementType}:-${index + 1}`] = element
  })

  return addedElements
}

export function countLoopIndex (loopCounter: LoopCounter, elementsHashes: ElementsHashes) {
  const { Nozzle } = elementsHashes
  const nozzleIds = Object.keys(Nozzle || {})

  for (let i = 0; i < nozzleIds.length; i++) {
    const loopId = Number((Nozzle[Number(nozzleIds[i])] || {})._loop || '0')

    loopCounter[loopId] = (loopCounter[loopId] || 0) + 1
  }
}

// TODO: fix bug, sometimes parentRoller is undefined
export function setRollerDiameter (
  editElements: any,
  paths: string[],
  elementsHashes: ElementsHashes,
  rollerChildrenPaths: string[],
) {
  const data = cloneDeep(editElements)

  paths.forEach(path => {
    const parentRoller = getElementFromPath(path, elementsHashes)

    // check if diameters are different
    if (Number(editElements[path]._diameter) !== Number(parentRoller._diameter)) {
      // update rollerBody's diameter
      getChildrenArrayByIds(elementsHashes, 'RollerBody', editElements[path]['#RollerBodyIds'])
        .forEach((rollerBody: any) => {
          if (Number(editElements[path]._diameter) !== Number(rollerBody._diameter)) {
            const rollerBodyPath = `${path}/RollerBody:${rollerBody._id}`

            if (!data[rollerBodyPath]) {
              data[rollerBodyPath] = {
                ...rollerBody,
              }
            }

            data[rollerBodyPath]._diameter = Number(editElements[path]._diameter)

            if (!rollerChildrenPaths.includes(rollerBodyPath)) {
              rollerChildrenPaths.push(rollerBodyPath)
            }

            (elementsHashes.RollerBody as any)['#hasChanges'] = true // to update store and views
          }
        })

      // update rollerBearing's diameter
      getChildrenArrayByIds(elementsHashes, 'RollerBearing', editElements[path]['#RollerBearingIds'])
        .forEach((rollerBearing: any) => {
          if (Number(editElements[path]._diameter) !== Number(rollerBearing._diameter)) {
            const rollerBearingPath = `${path}/RollerBearing:${rollerBearing._id}`

            if (!data[rollerBearingPath]) {
              data[rollerBearingPath] = {
                ...rollerBearing,
              }
            }

            if (!rollerChildrenPaths.includes(rollerBearingPath)) {
              rollerChildrenPaths.push(rollerBearingPath)
            }

            // there is no need to update elements hashes because rollerBearing take the radius of it's parent
            // if the bearing is in dirty list it will trigger setValues and thus rerender
          }
        })
    }
  })

  return data
}

export function setRollerPasslnCoord (
  editElements: any,
  paths: string[],
  elementsHashes: ElementsHashes,
  rollerChildrenPaths: string[],
) {
  const data = cloneDeep(editElements)

  paths.forEach(path => {
    const parentRoller = getElementFromPath(path, elementsHashes)

    // if roller's passLnCoord changed
    if (Number(editElements[path]._passln_coord) !== Number(parentRoller._passln_coord)) {
      // update rollerBodies
      getChildrenArrayByIds(elementsHashes, 'RollerBody', editElements[path]['#RollerBodyIds'])
        .forEach((rollerBody: any) => {
          const rollerBodyPath = `${path}/RollerBody:${rollerBody._id}`

          if (!data[rollerBodyPath]) {
            data[rollerBodyPath] = {
              ...rollerBody,
            }
          }

          data[rollerBodyPath]._passln_coord = Number(editElements[path]._passln_coord)

          if (!rollerChildrenPaths.includes(rollerBodyPath)) {
            rollerChildrenPaths.push(rollerBodyPath)
          }

          (elementsHashes.RollerBody as any)['#hasChanges'] = true // to update store and views
        })

      // update rollerBearings
      getChildrenArrayByIds(elementsHashes, 'RollerBearing', editElements[path]['#RollerBearingIds'])
        .forEach((rollerBearing: any) => {
          const rollerBearingPath = `${path}/RollerBearing:${rollerBearing._id}`

          if (!data[rollerBearingPath]) {
            data[rollerBearingPath] = {
              ...rollerBearing,
            }
          }

          data[rollerBearingPath]._passln_coord = Number(editElements[path]._passln_coord)

          if (!rollerChildrenPaths.includes(rollerBearingPath)) {
            rollerChildrenPaths.push(rollerBearingPath)
          }

          (elementsHashes.RollerBearing as any)['#hasChanges'] = true // to update store and views
        })
    }
  })

  return data
}
