export function computeMin (element: any) {
  return element._shim_min - element._shim_actual
}

export function computeMax (element: any) {
  return element._shim_max - element._shim_actual
}

function getSupportPointIds (element: any, SegmentGroup: SegmentGroupHash): number[] {
  const { id } = element['#parent']
  const parent = SegmentGroup[id]

  return (parent as any)?.['#SupportPointIds'] ?? []
}

export function getSupportPointPaths (element: any, SegmentGroup: SegmentGroupHash): string[] {
  const { type, id } = element['#parent']
  const supportPointIds = getSupportPointIds(element, SegmentGroup)
  const parentPath = `${type}:${id}`

  return supportPointIds.map((id) => `${parentPath}/SupportPoint:${id}`)
}

export function getChangedPaths (
  element: any,
  editElements: EditElements,
  SegmentGroup: SegmentGroupHash,
  SupportPoint: SupportPointHash,
): string[] {
  if (!element?._id) {
    return []
  }

  const { type, id } = element['#parent']
  const supportPointIds = getSupportPointIds(element, SegmentGroup)

  if (supportPointIds.length !== 4) {
    return []
  }

  const supportPoints = supportPointIds.map((id) => SupportPoint[id])
  const parentPath = `${type}:${id}`
  const changeElementPaths = []

  for (const supportPoint of supportPoints) {
    if (!supportPoint?._id) {
      continue
    }

    const path = `${parentPath}/SupportPoint:${supportPoint._id}`
    const editElement = editElements[path]

    if (editElement?._shim_propose === null || editElement?._shim_propose === '') {
      continue
    }

    if (Number(editElement?._shim_propose) !== Number(supportPoint._shim_propose)) {
      changeElementPaths.push(path)
    }
  }

  return changeElementPaths
}

function computeDisabled (
  element: any,
  editElements: EditElements,
  SegmentGroup: SegmentGroupHash,
  SupportPoint: SupportPointHash,
): boolean {
  if (!element?._id) {
    return false
  }

  const changeElementPaths = getChangedPaths(element, editElements, SegmentGroup, SupportPoint)

  return changeElementPaths.length === 3 && element?._shim_propose === null
}

/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SupportPoint = {
  categories: {
    1: 'general',
  },
  fields: {
    _id:           { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _name:         { type: 'text', category: 1, disabled: true },
    _shim_min:     { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _shim_actual:  { type: 'number', category: 1, defaultValue: 0, disabled: true },
    // eslint-disable-next-line max-len
    _shim_propose: { type: 'number', category: 1, defaultValue: 0, computeMin, computeMax, computeDisabled },
    _shim_max:     { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _normal_x:     { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _normal_y:     { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _normal_z:     { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _pos_x:        { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _pos_y:        { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _pos_z:        { type: 'number', category: 1, defaultValue: 0, disabled: true },
    _numericId:    { type: 'number', hidden: true },
    _originalId:   { type: 'number', hidden: true },
    _parentId:     { type: 'number', hidden: true },
  } as Record<string, any>,
}
/* eslint-enable key-spacing */
