import * as THREE from 'three'
import { Vector2, Vector3 } from 'three'
import { AnalyzeTime } from 'Util'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import Mold from './Mold'

export default class PasslineCurve extends BaseObject {
  static x = new THREE.Vector3(1, 0, 0)
  static curve: any
  static straight: any
  static plHeight: number
  static currentPssln: any
  static shouldBeStraight = false
  clickableObjects: any
  currentPssln?: Vector3
  @AnalyzeTime(0)
  static getCurve () {
    return PasslineCurve.shouldBeStraight ? PasslineCurve.straight : PasslineCurve.curve
  }

  @AnalyzeTime(0)
  static getAngle (v1: Vector3, v2: Vector3) {
    return Math.acos((v1.x * v2.x + v1.y * v2.y) / (v1.length() * v2.length()))
  }

  @AnalyzeTime(0)
  static getInfoAtPlCoord (plCoord: number, straight?: boolean, isDataPoint = false) {
    const position = new THREE.Vector3(0, PasslineCurve.plHeight - plCoord, 0)

    if (isNaN(plCoord) || straight === true || !PasslineCurve.curve) {
      if (isNaN(plCoord)) {
        // eslint-disable-next-line no-console
        console.log('plCoord is a NaN value')
      }

      return {
        position,
        normal: new THREE.Vector3(0, 0, -1),
        tangent: new THREE.Vector3(0, -1, 0),
        angleX: 0,
      }
    }

    const autoCurve = straight === undefined
    const curve = autoCurve ? PasslineCurve.getCurve() : PasslineCurve.curve

    const originalValue = plCoord / PasslineCurve.plHeight
    let value = originalValue

    if (value < 0) {
      value = 0
    }
    else if (value > 1) {
      value = 1
    }

    const tangent = new THREE.Vector3(0, 0, 0).add(curve.getTangentAt(value))

    tangent.z = tangent.z || 0

    const normal = tangent.clone().cross(new THREE.Vector3(0, 0, -1))
    const point: Vector3 = curve.getPointAt(value)

    if (originalValue < 0 && isDataPoint) {
      point.sub(new Vector3(0, plCoord + PasslineCurve.plHeight / 1000, 0))
    }

    const angleX = PasslineCurve.getAngle(PasslineCurve.x, normal)

    return {
      position: new THREE.Vector3(0, PasslineCurve.plHeight + point.y, -point.x),
      normal: new THREE.Vector3(-normal.z, normal.y, -normal.x),
      tangent: new THREE.Vector3(-tangent.z, tangent.y, -tangent.x),
      angleX,
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0) // @ts-ignore
  setValues (caster: Caster, clickableObjects: any, applyCurve: boolean) {
    const { Passline } = caster
    const plHeight = BaseObject.getPasslineHeight(caster)

    this.clickableObjects = clickableObjects

    PasslineCurve.plHeight = plHeight
    PasslineCurve.shouldBeStraight = !applyCurve

    this.setValuesCurved(Passline)
    this.setValuesStraight(Passline)
  }

  @AnalyzeTime(0)
  setValuesCurved (Passline: Passline) {
    let angleSum = 0
    let prevLength = 0
    let prevRadius = 0
    const prevPos = new THREE.Vector2(0, 0)
    const prevEnd = new THREE.Vector2(0, 0)
    const prevDir = new THREE.Vector2(1, 0)
    const prevTangent = new THREE.Vector2(0, -1)
    const points: Vector3[] = []
    const pointPrecision = PasslineCurve.plHeight * 10 // at least times 10 otherwise equi-spaced points won't work

    // eslint-disable-next-line camelcase
    Passline.section.forEach(({ _passln_coord: plCoord, _radius }) => {
      const length = plCoord / 1000 - prevLength
      const radius = _radius / 1000
      const pointCount = Math.ceil((length / PasslineCurve.plHeight) * pointPrecision) || 1

      if (radius > 0) {
        const circumference = 2 * Math.PI * radius
        const part = length / circumference
        const angle = 2 * Math.PI * part

        const newPos = prevDir.clone().setLength(prevRadius - radius).add(prevPos)

        const curve = new THREE.EllipseCurve(newPos.x, newPos.y, radius, radius, 0, -angle, true, -angleSum)

        const newPoints = curve.getSpacedPoints(pointCount)

        if (points.length) {
          newPoints.splice(0, 1)
        }

        newPoints.forEach(point => points.push(Util.getV3(point)))

        const end = curve.getPointAt(1)

        prevDir.copy(end.clone().sub(newPos))
        prevPos.copy(newPos)
        prevTangent.copy(curve.getTangentAt(1))
        prevEnd.copy(end)
        angleSum += angle
        prevRadius = radius
      }
      else {
        const linePoints = []
        const step = length / pointCount
        const endPos = prevTangent.clone().setLength(length)

        // subtracting 0.00001 from the length prevents the last two points to be too close for the curve to handle
        for (let i = 0; i < length - 0.00001; i += step) {
          const point = prevEnd.clone().add(prevTangent.clone().setLength(i))

          linePoints.push(Util.getV3(point))
        }

        const point = prevEnd.clone().add(endPos)

        linePoints.push(Util.getV3(point))

        if (points.length) {
          linePoints.splice(0, 1)
        }

        points.push(...linePoints)

        prevPos.add(endPos)
        prevEnd.add(endPos)
      }

      prevLength += length
    })

    if (!PasslineCurve.shouldBeStraight) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      const material = new THREE.LineBasicMaterial({ color: '#00b8ff' })

      geometry.translate(0, PasslineCurve.plHeight, 0)

      const line = new THREE.Line(geometry, material)

      line.name = 'PasslineCurve'
      Util.addOrReplace(this.container, line)

      this.objects.line = line
    }

    PasslineCurve.curve = new THREE.CatmullRomCurve3(points)
  }

  @AnalyzeTime(0)
  setValuesStraight (Passline: Passline) {
    let prevLength = 0
    const prevPos = new THREE.Vector2(0, 0)
    const prevEnd = new THREE.Vector2(0, 0)
    const prevTangent = new THREE.Vector2(0, -1)
    const points: Vector3[] = []
    const pointPrecision = PasslineCurve.plHeight * 10 // at least times 10 otherwise equi-spaced points won't work

    // eslint-disable-next-line camelcase
    Passline.section.forEach(({ _passln_coord: plCoord, _radius }) => {
      const length = plCoord / 1000 - prevLength
      const pointCount = Math.ceil((length / PasslineCurve.plHeight) * pointPrecision) || 1

      const linePoints = []
      const step = length / pointCount
      const endPos = prevTangent.clone().setLength(length)

      // subtracting 0.00001 from the length prevents the last two points to be too close for the curve to handle
      for (let i = 0; i < length - 0.00001; i += step) {
        const point = prevEnd.clone().add(prevTangent.clone().setLength(i))

        linePoints.push(Util.getV3(point))
      }

      const point = prevEnd.clone().add(endPos)

      linePoints.push(Util.getV3(point))

      if (points.length) {
        linePoints.splice(0, 1)
      }

      points.push(...linePoints)

      prevPos.add(endPos)
      prevEnd.add(endPos)

      prevLength += length
    })

    if (PasslineCurve.shouldBeStraight) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      const material = new THREE.LineBasicMaterial({ color: '#00b8ff' })

      geometry.translate(0, PasslineCurve.plHeight, 0)

      const line = new THREE.Line(geometry, material)

      line.name = 'PasslineCurve'
      Util.addOrReplace(this.container, line)

      this.objects.line = line
    }

    PasslineCurve.straight = new THREE.CatmullRomCurve3(points)
  }

  @AnalyzeTime(0)
  drawStrand () {
    // TODO: this hides the ruler header lines inside the mold, but it's a dirty hack
    const steps = Math.ceil(PasslineCurve.plHeight) * 4
    const points = PasslineCurve.getCurve().getSpacedPoints(steps - 1)
    const start = points[0].clone()

    start.y += 0.02
    points.unshift(start)

    if (points.length < 2) {
      // eslint-disable-next-line no-console
      console.error('Cannot build StrandGuide, not enough points in curve!')
    }

    const curve = new THREE.CatmullRomCurve3(points)

    const extrudeSettings = {
      steps,
      bevelEnabled: false,
      extrudePath: curve,
    }

    if (!Mold.innerShape) {
      // eslint-disable-next-line no-console
      console.error('Cannot build StrandGuide, Mold shape does not exist!')

      return
    }

    const geometry = new THREE.ExtrudeBufferGeometry(Mold.innerShape, extrudeSettings)
    const material = new THREE.MeshLambertMaterial({ color: '#d0ac70' })

    geometry.translate(0, PasslineCurve.plHeight, 0)

    const strand = new THREE.Mesh(geometry, material)

    strand.type = 'Strand'
    strand.name = 'CurvedStrand'

    Util.addOrReplace(this.container, strand, this.clickableObjects)

    this.objects.strand = strand
  }

  @AnalyzeTime(0)
  setPosition (position: Vector3) {
    this.objects.line.position.copy(position)

    this.currentPssln = position
  }

  @AnalyzeTime(0)
  hideStrand () {
    this.objects.strand.visible = false
  }

  @AnalyzeTime(0)
  showStrand () {
    this.objects.strand.visible = true
  }
}
