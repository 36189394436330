import { addAirLoops } from './airLoop'
import { addCoolingLoops } from './coolingLoop'
import { addCoolingZone } from './coolingZone'
import { addLoopAssignment } from './loopAssignment'
import { addNozzle } from './nozzle'
import { addRoller } from './roller'
import { addRollerBearing } from './rollerBearing'
import { addRollerBody } from './rollerBody'
import { addSupportPoint } from './supportPoint'
import { addSegment } from './segment'
import { addSegmentGroup } from './segmentGroup'
import { addStrandGuide } from './strandGuide'
import { elementsHashesEmptyObject, idsToBeDeletedObj } from './consts'
import type { ElementsHashes } from 'types/state'

const casterElementNamesArray = [
  'AirLoop',
  'CoolingLoop',
  'CoolingZone',
  'LoopAssignment',
  'Nozzle',
  'Roller',
  'RollerBearing',
  'RollerBody',
  'SupportPoint',
  'Segment',
  'SegmentGroup',
  'SensorPoint',
]

export function addAllElements (registry: Registry) {
  return (dispatch: any) => {
    dispatch(addAirLoops(registry.type.AirLoop as any))
    dispatch(addCoolingLoops(registry.type.CoolingLoop as any))
    dispatch(addCoolingZone(registry.type.CoolingZone as any))
    dispatch(addLoopAssignment(registry.type.LoopAssignment as any))
    dispatch(addNozzle(registry.type.Nozzle as any))
    dispatch(addRoller(registry.type.Roller as any))
    dispatch(addRollerBearing(registry.type.RollerBearing as any))
    dispatch(addRollerBody(registry.type.RollerBody as any))
    dispatch(addSupportPoint(registry.type.SupportPoint as any))
    dispatch(addSegment(registry.type.Segment as any))
    dispatch(addSegmentGroup(registry.type.SegmentGroup as any))
    dispatch(addStrandGuide(registry.type.StrandGuide as any))
  }
}

function getIdsToBeDeleted (state: any, idsToBeDeleted: typeof idsToBeDeletedObj, element: any) {
  Object.keys(element).forEach(key => {
    if (casterElementNamesArray.includes(key)) {
      let typeIds: number[] = (element[`#${key}Ids`] || [])

      typeIds = [ ...typeIds ]
      ;(idsToBeDeleted as any)[key].push(typeIds)
      typeIds.forEach(id => {
        getIdsToBeDeleted(state, idsToBeDeleted, state[key][id])
      })
    }
  })
}

export function getElementsHashes () {
  return (_: any, getState: any) => {
    const {
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SupportPoint,
      Segment,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
    } = getState()

    return {
      AirLoop,
      CoolingLoop,
      CoolingZone,
      LoopAssignment,
      Nozzle,
      Roller,
      RollerBearing,
      RollerBody,
      SupportPoint,
      Segment,
      SegmentGroup,
      SensorPoint,
      StrandGuide,
    }
  }
}

export function deleteElement (type: CasterElementNames, id: number) {

  // return (dispatch: any, getState: any) => {
  //   const idsToBeDeleted = { ...idsToBeDeletedObj }
  //   const generalState = getState()

  //   const element = { ...generalState[type][id] }
  //   const parentType = element ? element._parent ? element._parent.type : '' : ''

  //   getIdsToBeDeleted(generalState, idsToBeDeleted, element)
  //   Object.keys(idsToBeDeleted).forEach(key => {
  //     if((idsToBeDeleted as any)[key].length){
  //       dispatch({
  //         type: `${key}/DELETE_MULTIPLE`,
  //         ids: (idsToBeDeleted as any)[key],
  //       })
  //     }
  //   })
  //   dispatch({
  //     type: `${type}/DELETE`,
  //     id,
  //   })
  //   dispatch({
  //     type: `${parentType}/REMOVE_CHILD_ID`,
  //       id: element['#parent'].id,
  //       child: {
  //       type,
  //       id,
  //     },
  //   })

  // }
}

export function renameElement (type: CasterElementTypes, id: number, name: string) {
  // TODO: add to all types, right now only available for Segments and SegmentGroups
  return {
    type: `${type}/RENAME`,
    id,
    name,
  }
}

export function updateElementsHashes (elementsHashes: ElementsHashes, updateAll = false) {
  return (dispatch: any) => {
    // initialize data because if it is an empty object if the new caster doesn't have one type of element previous
    // elements won't be deleted
    const data: any = { ...elementsHashesEmptyObject }

    if (updateAll) {
      Object.keys(elementsHashes).forEach((key) => {
        if ((elementsHashes as any)[key]['#hasChanges'] || updateAll) {
          data[key] = {
            ...(elementsHashes as any)[key],
            '#hasChanges': false,
          }
        }
      })

      dispatch({
        type: 'UPDATE_WHOLE_STATE',
        data,
      })
    }
    else {
      Object.keys(elementsHashes).forEach((key) => {
        if ((elementsHashes as any)[key]['#hasChanges']) {
          dispatch({
            type: `${key}/UPDATE`,
            elements: (elementsHashes as any)[key],
          })
        }
      })
    }
  }

  // TODO: optimize, only update changed elements if they are not many

  // return (dispatch: any) => {
  //   Object.keys(elementsHashes).forEach((key) => {
  //     if ((elementsHashes as any)[key]['#hasChanges'] || updateAll) {
  //       dispatch({
  //         type: `${key}/UPDATE`,
  //         elements: (elementsHashes as any)[key],
  //       })
  //     }
  //   })
  // }
}

// function removeChildIdInParentState(type: CasterElementNames, childToBeDeletedId: number, element: unknown & Parent){
//   const parentTypeState = getElementTypeState(element.parent.type)
//   const parent = parentTypeState[element.parent.id]
//   parent[`#${type}Ids`] = parent[`#${type}Ids`].filter((childId: number) => childId !== childToBeDeletedId)
//   updateElementTypeState(element.parent.type, parent)
// }

// export function deleteChildren (type: string, element: any, registry: Registry) {
//   if (element instanceof Array) {
//     element
//       .splice(0, element.length)
//       .forEach(entry => deleteChildren(type, entry, registry))

//     // TODO: return here?
//   }

//   const childKeys = Object.keys(element || {}).filter(key => /^[A-Z]/.test(key))

//   childKeys.forEach(childKey => deleteElement(childKey as CasterElementNames, element[childKey]._id, registry))
// }

// export function deleteChildrenId (type: string, element: any) {
//   switch (type) {
//     case 'Nozzle':
//     case 'RollerBearing':
//     case 'RollerBody':
//       break
//     case 'Roller':
//       element.RollerBody.map((rb: any) => {
//         delete rb._id
//         delete rb._numericId
//         delete rb._originalId

//         return rb
//       })
//       element.RollerBearing.map((rb: any) => {
//         delete rb._id
//         delete rb._numericId
//         delete rb._originalId

//         return rb
//       })
//       break
//     default:
//       break
//   }
// }

// export function deleteAllElements (data: any, dataTypes: CasterElementNames[], registry: Registry) {
//   dataTypes.forEach(type => {
//     const elements = registry.type[type] || {}
//     const typeIds = Object.keys(elements)

//     for (let i = 0; i < typeIds.length; i++) {
//       const { _id } = (elements as any)[typeIds[i]]

//       deleteElement(type, _id, registry)
//     }

//     data.root.XML_Generation_Parameters[`_lastTypeId_${type}`] = '0'
//   })
// }
