import { AnalyzeTime } from 'Util'

export default class LodUtil {
  static LOD_ROLLER = 'LoDRoller'
  static LOD_ROLLER_BODY = 'LoDRollerBody'
  static LOD_ROLLER_BEARING = 'LoDRollerBearing'
  static LOD_TYPES = [ LodUtil.LOD_ROLLER, LodUtil.LOD_ROLLER_BODY, LodUtil.LOD_ROLLER_BEARING ]

  @AnalyzeTime(0)
  static handleLoDs (camera: THREE.Camera, elementList: any, phantomElementList: any) {
    LodUtil.LOD_TYPES.forEach(key => {
      const type = key.substring(3)

      // handle lod
      if (elementList[type]) {
        Object.values(elementList[type]).forEach((element: any) => {
          element.objects[key].update(camera)
        })
      }

      // handle lod phantom
      if (phantomElementList[type]) {
        Object.values(phantomElementList[type]).forEach((element: any) => {
          element.objects[key].update(camera)
        })
      }
    })
  }
}
