import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import Icon from 'react/specific/Icon'
import FeatureFlags from 'react/FeatureFlags/index'

import { Header, IconBox, NameBox, Trigger, Lock } from './TypeHeaderStyles'
import { DefaultState } from 'types/state'

const connector = connect((state: DefaultState) => ({
  selectedDataArray: state.data.selectedDataArray,
  currentSimulationCase: state.application.main.currentSimulationCase,
  authenticationData: state.application.main.authenticationData,
  featureFlags: state.application.main.authenticationData.featureFlags,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  title: string
  type: string
  isExpanded:boolean
  isSelected:boolean
  onClick:(e:any)=>void
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  icons: any
};

export class TypeHeader extends Component<Props, State> {
  state = {
    icons: {
      General: 'map-marker',
      Elements: 'list',
      SegmentGroup: 'square',
      Segment: 'square-full',
      SupportPoint: 'square-full',
      Nozzle: 'caret-down',
      Roller: 'circle',
      RollerBody: 'circle',
      RollerBearing: 'bullseye',
      SensorPoint: 'square-full',
      DataPoint: 'square-full',
      DataLine: 'gripLines',
      ConsistencyCheck: 'edit',
      Modules: 'objectGroup',
    },
  }

  render () {
    const { icons } = this.state
    const {
      type,
      isExpanded,
      isSelected,
      onClick: handleClick,
      title,
      currentSimulationCase,
      authenticationData,
      featureFlags,
      t,
    } = this.props
    const realFeatureFlags = FeatureFlags.getRealFeatureFlags({
      application:
        {
          main:
          {
            authenticationData: { featureFlags },
            currentSimulationCase,
          },
        },
    })
    const canEdit = FeatureFlags.canEditElement(type, realFeatureFlags)
    const { isLocked, lockedBy } = FeatureFlags.getLockedStatus(type, currentSimulationCase, authenticationData)

    const lockedInfo = isLocked
      ? lockedBy
        ? t('header.lockedBy', { type, user: lockedBy })
        : t('header.noPermissions', { type })
      : t('header.noPermissions', { type })

    return (
      <Header isSelected={isSelected} title={(!canEdit || isLocked) ? lockedInfo : title || type}>
        <IconBox isChild={![ 'General', 'Elements', 'Modules', 'ConsistencyCheck' ].includes(type)}>
          <Icon
            icon={(icons as any)[type]}
            type={type === 'RollerBody' ? 'fas' : undefined}
          />
        </IconBox>
        <NameBox pad={20} onClick={handleClick}>
          {type}
        </NameBox>
        {
          (!canEdit || isLocked) && type !== 'Modules' && type !== 'Elements' && (
            <Lock title={lockedInfo}>
              <i className='pe-7s-lock' />
            </Lock>
          )
        }
        <Trigger onClick={handleClick}>
          <i className={`pe-7s-angle-${isExpanded ? 'down' : 'right'}`} />
        </Trigger>
      </Header>
    )
  }
}

export default withNamespaces('caster')(connector(TypeHeader as any) as any) as any
