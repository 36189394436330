import LogicInterface from '../LogicInterface'

type InternalLogicEvents = {
  [key: string]: any
}
export default class InternalLogic extends LogicInterface {
  constructor () {
    super()

    window.addEventListener('beforeunload', event => {
      this.send('closeApplication', event)
    })
  }

  static events: InternalLogicEvents = {}

  on (...params: any[]) {
    const [ functionName, callback ] = params

    if (!InternalLogic.events[functionName]) {
      InternalLogic.events[functionName] = callback
    }
  }

  send (...params: any[]) {
    const [ functionName, ...restParams ] = params

    if (InternalLogic.events[functionName]) {
      InternalLogic.events[functionName](...restParams)
    }
  }

  removeListener (...params: any[]) {
    const [ functionName ] = params

    delete InternalLogic.events[functionName]
  }

  has (functionName: string) {
    return Object.keys(InternalLogic.events).includes(functionName)
  }
}
