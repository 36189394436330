import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'

export const ActionButton = styled(Button)`${({ theme }) => css`
  min-width: 30px !important;
  height: 22px;
  line-height: 10px !important;
  font-size: 13px !important;
  color: #e5e9f2 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 0 !important;

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`}`
