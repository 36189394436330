import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Util from 'three/logic/Util'
import type { ElementsHashes } from 'types/state'

import ElementsComponent from './tree/Elements/ElementsComponent'
import ChildrenElement from './tree/Elements/ChildrenElements'

import { FixedSizeList as List } from 'react-window'
import ThreeManager from 'three/ThreeManager'
import Icon from './specific/Icon'

const Tree = styled.div`
  height: calc(100vh - 50px - 16px);
`

const OpenIconContainer = styled.div<{left: number}>`${({ left }) => css`
  left:      ${left}px;
  position: fixed;
  top: 48px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: white;
  }
`}`

type Props = {
  openDialogs: string[],
  elementsHashes: ElementsHashes,
  name: string,
  selectedElements: string[]
  setTerm: (term: string, isCtrl?: boolean, isFilterClick?: boolean, isElementClick?: boolean) => void
  term: string
  target: string,
  onSelect: (e: any, elementPath: string) => void
  setTarget:(target: string) => void
  selectedMultiEditElements: (elementPath?: string, ctrlKey?: boolean, shiftKey?: boolean) => void
};
// TODO: Refactor, send nodes component to another file
export default class TreeView extends Component<Props> {
  render () {
    const {
      onSelect,
      elementsHashes,
      selectedElements,
      setTerm,
      selectedMultiEditElements,
      term,
      target,
      setTarget,
      openDialogs,
    } = this.props

    const segmentGroups = elementsHashes.SegmentGroup

    return (
      <Tree>
        {
          segmentGroups && Object.keys(segmentGroups).length
            ? (
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              <Nodes
                elementsHashes={elementsHashes}
                selectedElements={selectedElements}
                setTerm={setTerm}
                selectedMultiEditElements={selectedMultiEditElements}
                term={term}
                setTarget={setTarget}
                target={target}
                onSelect={onSelect}
                openDialogs={openDialogs}
              />
            )
            : null
        }
      </Tree>
    )
  }
}

type NodesProps = {
  elementsHashes: ElementsHashes
  selectedElements: string[]
  setTerm: (term: string, isCtrl?: boolean, isFilterClick?: boolean, isElementClick?: boolean) => void
  selectedMultiEditElements: (elementPath?: string, ctrlKey?: boolean, shiftKey?: boolean) => void
  onSelect: (event: any, path: string) => void
  term: string
  setTarget:(target: string) => void
  target: string,
  openDialogs: string[]
}

type MatchHashElement = {
  show: boolean,
  selected: boolean,
  parentTypeAndId: string,
  showChildren: boolean
  children: string[],
  indexInElementsArray: number,
}

type NodesState = {
  elementsArray: NodeElement[]
  shownPaths: string[]
  matchHash: Record<string, MatchHashElement>
}

type NodeElement = {
  name: string,
  nameAndId: string,
  element: any,
  // collapsed: boolean,
  depth: number
  hasChildren: boolean
  fullPath: string,
  amountOfChildren?: number
}

function openParentNodes (state: NodesState, elementTypeAndId: string) {
  // Retrieve the elementHashInfo
  const elementHashInfo = state.matchHash[elementTypeAndId]

  if (elementHashInfo.show) {
    return
  }

  elementHashInfo.show = true
  elementHashInfo.showChildren = true
  state.shownPaths.push(elementTypeAndId)

  // Retrieve parent's info
  const parentTypeAndId = elementHashInfo.parentTypeAndId

  // If doesnt have parent or parent is
  if (!parentTypeAndId) {
    return
  }

  const parentHashInfo = state.matchHash[parentTypeAndId]

  if (!parentHashInfo.show) {
    openParentNodes(state, parentTypeAndId)
  }
  else if (parentTypeAndId.split(':')[0] === 'SegmentGroup') {
    parentHashInfo.showChildren = true
  }
}

const Row = ({ data, index, style }: any) => {
  const { openedElements, matchHash } = data

  const element = openedElements[index]

  if (!element) {
    return <></>
  }

  const handleClick = element.hasChildren ? data.showChildren : data.handleSelect
  const elemType = element.nameAndId.split(':')[0]
  let selfTerm = element.fullPath

  if (elemType !== 'Nozzle' && !elemType.includes('RollerB')) {
    selfTerm += '/*'
  }

  const active = ~data.term.indexOf(selfTerm)

  const targetCondition = data.target === selfTerm

  if (element.nameAndId.includes('Selector')) {
    let nextElement = `${element.nameAndId.substring(0, element.nameAndId.indexOf('Selector'))}`

    if (nextElement !== 'RollerBody') {
      nextElement += 's'
    }
    else {
      nextElement = `${nextElement.substring(0, nextElement.length - 1)}ies`
    }

    return (
      <div style={style}>
        <ChildrenElement
          counter={element.amountOfChildren || 0}
          fontColor='white'
          onClick={handleClick}
          spacer={element.depth}
          treeStatus='block'
          nextElement={nextElement}
          selected=''
          visibleChildren={{}}
          name={element.nameAndId}
          element={element.element}
          open={matchHash[element.nameAndId].showChildren}
        />
      </div>
    )
  }

  return (
    <div style={style}>
      <ElementsComponent
        depth={element.depth}
        key={index}
        element={element.element}
        treeStatus='block'
        selected={matchHash[element.nameAndId].selected}
        childrenElement={[]}
        name={elemType}
        type={elemType}
        elementName={[ 'SegmentGroup', 'Segment' ].includes(elemType) ? element.name : ''}
        fullPath={element.fullPath}
        visible={matchHash[element.nameAndId].showChildren}
        active={active}
        target={targetCondition}
        hasChildren={element.hasChildren}
        onClick={handleClick}
        // eslint-disable-next-line react/jsx-handler-names
        onFilter={data.onFilter}
        // eslint-disable-next-line react/jsx-handler-names
        onTarget={data.onTarget}
      />
    </div>
  )
}

class Nodes extends Component<NodesProps, NodesState> {
  constructor (props: any) {
    super(props)
    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash)
    this.getOpenedElements(shownPaths, elementsArray)
    this.state = { elementsArray, shownPaths, matchHash }
  }

  componentDidMount (): void {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentDidUpdate (prevProps: NodesProps) {
    // eslint-disable-next-line react/prop-types
    if (!this.props.equals(prevProps)) {
      if (!prevProps.elementsHashes.equals(this.props.elementsHashes)) {
        const elementsArray: NodeElement[] = []
        const shownPaths: string[] = []
        const matchHash: Record<string, MatchHashElement> = {}

        this.handleBuildTree(elementsArray, shownPaths, matchHash)
        this.getOpenedElements(shownPaths, elementsArray)

        return this.setState({ elementsArray, shownPaths, matchHash })
      }

      const newState = { ...this.state }

      const elementsArrayLength = newState.elementsArray.length

      // Unselect all elements
      for (let i = 0; i < elementsArrayLength; i++) {
        newState.matchHash[newState.elementsArray[i].nameAndId].selected = false
      }

      this.props.selectedElements.forEach(path => {
        const elementTypeAndId = path.substring(path.lastIndexOf('/') + 1)

        if (!newState.matchHash[elementTypeAndId]) {
          return
        }

        if (!newState.matchHash[elementTypeAndId].show) {
          openParentNodes(newState, elementTypeAndId)
        }

        newState.matchHash[elementTypeAndId].selected = true
      })

      this.setState(newState)
    }
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  handleWindowSizeChange = () => {
    this.forceUpdate()
  }

  handleBuildTree (
    elementsArray: NodeElement[],
    shownPaths: string[],
    matchHash: Record<string, MatchHashElement>,
    showAll = false,
  ) {
    Util.runPerElementHashWithDepth(
      'SegmentGroup',
      'SegmentGroup',
      Object.keys(this.props.elementsHashes.SegmentGroup).filter(key => key[0] !== '#') as any, // except #hasChanges
      null,
      '',
      this.props.elementsHashes,
      0,
      (
        fullPath: string,
        type: CasterElementNames,
        element: any,
        depth: number,
        hasChildren: boolean,
        previousElement: any,
      ) => {
        if (!Object.keys(element || {}).length) {
          return
        }

        // If type is segment and element does not have children, dont show it
        if (
          type === 'Segment' &&
          !element['#NozzleIds']?.length &&
          !element['#RollerIds']?.length &&
          !element['#SensorPointIds']?.length
        ) {
          return
        }

        const nameAndId = `${type}:${element._id}`
        const parent = element['#parent']
        const parentTypeAndId = parent ? `${parent.type}:${parent.id}` : ''

        const selectorPath = `${type}Selector-${parentTypeAndId}`
        const shouldAddSelector = type !== 'SegmentGroup' && matchHash[selectorPath] === undefined
        const shouldShow = depth <= 2
        const shouldShowChildren = depth < 2

        // Push the selector
        if (shouldAddSelector) {
          elementsArray.push({
            name: 'selector',
            nameAndId: selectorPath,
            element: { [`#${type}Ids`]: [ ...previousElement[`#${type}Ids`] ] },
            depth: depth - 1,
            hasChildren: true,
            fullPath: '',
            amountOfChildren: previousElement[`#${type}Ids`].length, // ?
          })

          if (!Object.keys(element).length) {
            return
          }

          matchHash[selectorPath] = {
            children: elementsArray[matchHash[parentTypeAndId].indexInElementsArray].element[`#${type}Ids`]
              .map((id: number) => `${type}:${id}`),
            parentTypeAndId: parentTypeAndId,
            selected: false,
            show: shouldShow || showAll,
            showChildren: depth <= 2 || showAll,
            indexInElementsArray: elementsArray.length - 1,
          }

          if (shouldShow || showAll) {
            shownPaths.push(selectorPath)
          }
        }

        elementsArray.push({
          name: element._name,
          nameAndId,
          element,
          depth,
          hasChildren,
          fullPath,
        })

        matchHash[nameAndId] = {
          children: Object.keys(element)
            .filter(key => (key.includes('Ids'))) // TODO: add sensor points
            .map(key => `${key.slice(1, -3)}Selector-${nameAndId}`),
          parentTypeAndId: selectorPath,
          selected: false,
          show: shouldShow || showAll,
          showChildren: shouldShowChildren || showAll,
          indexInElementsArray: elementsArray.length - 1,
        }

        if (shouldShow || showAll) {
          shownPaths.push(nameAndId)
        }
      },
    )
  }

  handleFilterClick = (ctrl: boolean, name: string, elementPath: string) => {
    const { setTerm, selectedMultiEditElements, term } = this.props

    const filter = this.getTerm(name, elementPath)

    if (~term.indexOf(filter)) {
      setTerm(filter, ctrl, false, true)
      setTimeout(() => {
        ThreeManager.base.jumpToFiltered()
      }, 1)
    }
    else {
      setTerm(filter, true, false, true)
      setTimeout(() => {
        ThreeManager.base.jumpToFilter(filter, () => {
          setTimeout(() => {
            if (!ctrl) {
              setTerm(filter, false, false, false)
            }
          }, 1)
        })
      }, 1)
    }

    selectedMultiEditElements()
  };

  handleTargetClick = (name: string, elementPath: string) => {
    const { setTarget } = this.props

    const term = this.getTerm(name, elementPath)

    setTarget(term)
    ThreeManager.base.jumpToFilter(term)
  };

  handleSelect = (e: any, path: string) => {
    e.stopPropagation()

    const { onSelect } = this.props

    onSelect(e, path)
  }

  handleOpenAll = () => {
    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash, true)

    return this.setState({ elementsArray, shownPaths, matchHash })
  }

  handleCloseAll = () => {
    const matchHashCopy = { ...this.state.matchHash }

    Object.keys(matchHashCopy).forEach(key => {
      // if key starts with SegmentGroup, return, we always want to show them
      if (!key.indexOf('SegmentGroup')) {
        matchHashCopy[key].showChildren = false

        return
      }

      matchHashCopy[key].show = false
      matchHashCopy[key].showChildren = false
    })

    const newShownPaths = this.state.shownPaths.filter(path => path.indexOf('SegmentGroup') === 0)

    this.setState({ matchHash: matchHashCopy, shownPaths: newShownPaths })
  }

  handleOpenLikeDefault = () => {
    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash)

    return this.setState({ elementsArray, shownPaths, matchHash })
  }

  shouldAddSelector (elementsArray: NodeElement[], elementToBeAdded: any, type: CasterElementNames) {
    if (!elementsArray.length) {
      return false
    }

    const lastAddedItemType = elementsArray[elementsArray.length - 1].nameAndId.split(':')[0]

    if ([ 'RollerBearing', 'RollerBody' ].includes(lastAddedItemType) && type === 'Nozzle') {
      return true
    }

    if (lastAddedItemType === elementToBeAdded['#parent'].type) {
      if ([ 'RollerBody', 'RollerBearing' ].includes(type)) {
        return false
      }

      return true
    }

    return false
  }

  getOpenedElements (shownPaths: string[], elementsArray: NodeElement[]) {
    if (!shownPaths) {
      return []
    }

    return elementsArray.filter(element => shownPaths.includes(element.nameAndId))
  }

  closeAllChildren (newState: NodesState, elementTypeAndId: string) {
    const elementMatch = newState.matchHash[elementTypeAndId]

    if (!elementMatch) {
      return
    }

    elementMatch.children.forEach(childNameAndId => {
      const childMatch = newState.matchHash[childNameAndId]

      if (childMatch && childMatch.show) {
        childMatch.showChildren = false
        childMatch.show = false
        const index = newState.shownPaths.indexOf(childNameAndId)

        if (~index) {
          newState.shownPaths.splice(index, 1)
        }

        this.closeAllChildren(newState, childNameAndId)
      }
    })
  }

  toggleChildren = (element: any, elementTypeAndId: string) => {
    const newState = { ...this.state }
    const elementMatch = newState.matchHash[elementTypeAndId]
    const newShowChildren = !elementMatch.showChildren

    elementMatch.showChildren = newShowChildren

    if (newShowChildren) {
      elementMatch.children.forEach(childPath => {
        if (newState.matchHash[childPath]) {
          newState.shownPaths.push(childPath)
          newState.matchHash[childPath].show = true
        }
      })
    }
    else {
      this.closeAllChildren(newState, elementTypeAndId)
    }

    this.setState(newState)
  }

  getTerm = (name: string, elementPath: string) => {
    let term = elementPath

    if (name !== 'Nozzle' && !name.includes('RollerB')) {
      term += '/*'
    }

    return term
  }

  render () {
    const { shownPaths, elementsArray } = this.state
    const { openDialogs } = this.props
    const openedElements = this.getOpenedElements(shownPaths, elementsArray)
    const data = {
      openedElements,
      matchHash: this.state.matchHash,
      showChildren: this.toggleChildren,
      onFilter: this.handleFilterClick,
      term: this.props.term,
      target: this.props.target,
      onTarget: this.handleTargetClick,
      handleSelect: this.handleSelect,
    }
    const windowHeight = window && window.innerHeight ? window.innerHeight - 84 : 600

    return (
      <div>
        {
          openDialogs.includes('CasterTree') && (
            <div>
              <OpenIconContainer left={180}>
                <Icon
                  icon='ChevronCircleDown'
                  onClick={this.handleOpenAll}
                  title='Open all'
                />
              </OpenIconContainer>
              <OpenIconContainer left={210}>
                <Icon
                  icon='dot-circle'
                  onClick={this.handleOpenLikeDefault}
                  title='Default'
                />
              </OpenIconContainer>
              <OpenIconContainer left={240}>
                <Icon
                  icon='ChevronCircleUp'
                  onClick={this.handleCloseAll}
                  title='Close all'
                />
              </OpenIconContainer>
            </div>)
        }
        <List
          key='List'
          height={windowHeight} // TODO: make this autosize
          itemSize={25}
          itemCount={this.state.shownPaths.length}
          width={270}
          itemData={data}
        >
          {Row}
        </List>
      </div>

    )
  }
}
