import styled, { css, keyframes } from 'styled-components'

export const ErrorMessage = styled.div`${({ theme }) => css`
  width: 100%;
  margin: 3px 0;
  text-align: center;
  color: ${theme.error.main};
`}`

export const getPercentage = (half?: boolean, oneThird?: boolean, twoThirds?: boolean) => {
  if (half) {
    return 50
  }

  if (oneThird) {
    return 33
  }

  if (twoThirds) {
    return 66
  }
}

export const ConfirmWrapper = styled.div`
  display: inline-block;
  width: 100%;
`

export const BtnWrapper = styled.div<{half?: boolean,
  oneThird?: boolean,
  twoThirds?: boolean,
  right?: boolean,
  nextToDropDown?: boolean}>`${({ half, oneThird, twoThirds, right, nextToDropDown }) => css`
  display: inline-block;
  user-select: none;
  width: ${!(half || oneThird || twoThirds)
    ? '100%'
    : `calc(${getPercentage(half, oneThird, twoThirds)}% - 7px)`
};

  ${right && `margin-left: calc(${100 - (getPercentage(half, oneThird, twoThirds) || 100)}% + 7px);`}

  ${nextToDropDown && 'margin-top: 16px;'}

  ${(half || oneThird || twoThirds) && css`
    :first-of-type {
      margin-right: 14px;
    }
  `};
`}`

const loading = keyframes`
  0% {
    background-position: 0
  }
  
  100% {
    background-position: 80px
  }
`

const icon = keyframes`
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const error = (theme:any, type?: string) => {
  return keyframes`
    0% {
      background: ${theme[type || 'primary'].main};
    }
    10% {
      background: ${theme.error.main};
    }
    90% {
      background: ${theme.error.main};
    }
    100% {
      background: ${theme[type || 'primary'].main};
    }
  `
}

export const Label = styled.div`${({ theme }) => css`
  color: ${theme.mainFontColor};
  font-size: 14px;
  user-select: none;
  margin-top: 17px;
  margin-bottom: 10px;
`}`

export default styled.button<{
  onClick: (e: React.MouseEvent) => void
  title?: string
  disabled?: boolean
  isLoading?: boolean
  hasError?: boolean
  isSuccessful?: boolean
  type?: any
  icon?: string
  textPaddingLeft?: boolean
  noMargin?: boolean
  ref?: any
  height?: number
  bold?: boolean
  half?: boolean
  overrideColor?: string
  overrideHoverColor?: string
}>`${({
  theme,
  type,
  isLoading,
  hasError,
  noMargin,
  isSuccessful,
  height,
  bold,
  textPaddingLeft,
  overrideColor,
  overrideHoverColor,
}) =>
  css`
  background-color: ${theme[type || 'primary'].main};
  color: ${theme[type || 'primary'].font};
  width: 100%;
  position: relative;
  text-align: center;
  display: inline-block;
  height: ${height || '38'}px;
  line-height: ${height || '38'}px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  ${!noMargin && css`margin-top: 17px`};
  ${bold && css`font-weight: bold`};
  ${textPaddingLeft ? 'padding-left: 24px;' : ''}
  ${overrideColor && `background-color: ${overrideColor};`}
  &:-moz-focusring {
    outline: none;
  }

  ${isLoading && css`
    background-image: linear-gradient(
      -45deg, rgba(255,255,255,0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255,255,255,0.2) 50%,
      rgba(255,255,255,0.2) 75%,
      transparent 75%,
      transparent
    );
    background-size: 80px 80px;
    animation: ${loading} 1.5s linear infinite;
    text-shadow: 0 1px 0 #333;
  `}
  
  ${hasError && css`
    animation: ${error(theme, type)} 5s linear;
  `}
  
  ${(isSuccessful || hasError) && css`
    div i {
      animation: ${icon} 1s linear;
      ${hasError && css`animation-delay: 4s;`}
    }
  `}
  
  :hover {
    transition: 3ms ease-in;
    background-color: ${theme[hasError ? 'error' : type || 'primary'].dark};
    ${overrideHoverColor && `background-color: ${overrideHoverColor};`}
  }
  
  :disabled {
    ${!isLoading && !hasError && `background: ${theme[type || 'primary'].disabled}`};
    cursor: not-allowed;
  }

  div.cut {
    overflow: hidden;
    border-radius: 50%;
  }

  div {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 11px;
    top: 11px;
  }

  div i {
    font-size: 26px;
    transform: translate(-5px, -5px);
  }
`}`
