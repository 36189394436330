export function displayTime (rawSeconds: number | string, hideZero = false): string {
  const seconds = typeof rawSeconds === 'string' ? Number(rawSeconds) : rawSeconds

  if (isNaN(seconds) || seconds === 0) {
    return '0'
  }

  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const list = []

  if (h > 0 && !(hideZero && h === 0)) {
    list.push(`${h}h`)
  }

  if ((m > 0 || h > 0) && !(hideZero && m === 0)) {
    list.push(`${m}m`)
  }

  if ((s > 0 || m > 0 || h > 0) && !(hideZero && s === 0)) {
    list.push(`${s}s`)
  }

  return list.join(' ')
}
