import React, { Component } from 'react'
import { AnalyzeTime } from 'Util'
import { DeleteComponent, CheckIcon, AbortIcon, DeleteIcon, Loading } from './styles'

type Props = {
  onClick: (e: any) => void,
  isLoading?: boolean,
  disabled?: boolean
};

type State = {
  showApproval: boolean
};

class Delete extends Component<Props, State> {
  state = {
    showApproval: false,
  }

  // @AnalyzeTime(0)
  handleApproval = (showApproval: boolean) => {
    this.setState({
      showApproval,
    })
  };

  handleOpen = this.handleApproval.bind(this, true);
  handleClose = this.handleApproval.bind(this, false);

  @AnalyzeTime(0)
  render () {
    const { showApproval } = this.state
    const { onClick: handleClick, isLoading, disabled } = this.props || {}

    if (disabled) {
      return null
    }

    return (
      <DeleteComponent>
        {!isLoading && !showApproval && <DeleteIcon className='pe-7s-less' onClick={this.handleOpen} />}
        {
          !isLoading && showApproval &&
            <div>
              <CheckIcon className='pe-7s-check' onClick={handleClick} />
              <AbortIcon className='pe-7s-close-circle' onClick={this.handleClose} />
            </div>
        }
        {isLoading && <Loading type='spin' />}
      </DeleteComponent>
    )
  }
}

export default Delete
