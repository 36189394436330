
export class Const {
  static colors = [
    '#43c0ff',
    '#ff56e4',
    '#ff8b31',
    '#4348ff',
    '#c0c0c0',
    '#a52a2a',
    '#696969',
    '#ffff00',
    '#7e00ff',
    '#3366ff',
    '#5f0000',
    '#d70087',
    '#ffafff',
    '#ffd700',
    '#5f5f00',
    '#005faf',
    '#ffafd7',
    '#af0000',
    '#00af00',
    '#af00d7',
    '#ff87d7',
    '#d7ffff',
    '#00d7af',
    '#d7afd7',
    '#ffd7af',
    '#d787d7',
    '#af00af',
    '#005f5f',
    '#ff00ff',
    '#87d7d7',
    '#ffd75f',
    '#d75fff',
    '#d7d7af',
    '#5faf5f',
    '#ff005f',
    '#00afff',
    '#ff00d7',
    '#87ff87',
    '#878787',
    '#ff87ff',
    '#87005f',
    '#005f87',
    '#af8700',
    '#00d7ff',
    '#5faf00',
    '#afafd7',
    '#87ff00',
    '#ff5fff',
    '#87af00',
    '#00d7d7',
    '#87ffff',
    '#5f87d7',
    '#5fd75f',
    '#87ffaf',
    '#00ffff',
    '#5f00ff',
    '#af5f00',
    '#5f5faf',
    '#5fd700',
    '#d75f00',
    '#5fff5f',
    '#5f005f',
    '#d7ff00',
    '#5fafff',
    '#afd7af',
    '#87ff5f',
    '#ffafaf',
    '#5fffaf',
    '#5f5f5f',
    '#d787af',
    '#ffd787',
    '#008000',
    '#d700af',
    '#87d7af',
    '#af5faf',
    '#008080',
    '#000080',
    '#d7afff',
    '#ffaf5f',
    '#00afd7',
    '#af5fd7',
    '#5fd787',
    '#5f5fff',
    '#87af5f',
    '#afd7ff',
    '#af8787',
    '#ff875f',
    '#00ffff',
    '#5fd7af',
    '#5f87af',
    '#d7d75f',
    '#af5f5f',
    '#875fd7',
    '#800080',
    '#ffff87',
    '#d75fd7',
    '#800000',
    '#af875f',
    '#875faf',
    '#afaf87',
    '#d7af5f',
    '#afafaf',
    '#00ff87',
    '#5f8787',
    '#afff00',
    '#00afaf',
    '#00d75f',
    '#5fd7ff',
    '#5f5fd7',
    '#00af5f',
    '#ffff5f',
    '#005fd7',
    '#5fff00',
    '#87d7ff',
    '#00ff5f',
    '#d75faf',
    '#008700',
    '#afffff',
    '#d7ff87',
    '#808080',
    '#808000',
    '#8787d7',
    '#870000',
    '#af00ff',
    '#0087ff',
    '#afaf00',
    '#5fafd7',
    '#ff00af',
    '#87afff',
    '#d700ff',
    '#afaf5f',
    '#5faf87',
    '#87ffd7',
    '#5fd7d7',
    '#005fff',
    '#5fffff',
    '#8700af',
    '#00ff00',
    '#af87d7',
    '#ff87af',
    '#0000ff',
    '#00ffd7',
    '#87d700',
    '#0087d7',
    '#d7005f',
    '#afffd7',
    '#d700d7',
    '#afafff',
    '#d7d7ff',
    '#d7af00',
    '#878700',
    '#d7ffaf',
    '#ff5f00',
    '#ff0087',
    '#00ffaf',
    '#8700d7',
    '#5f875f',
    '#d78700',
    '#87af87',
    '#d70000',
    '#d78787',
    '#5f00d7',
    '#d7d700',
    '#ff0000',
    '#875f87',
    '#af5f87',
    '#8787ff',
    '#87afaf',
    '#008787',
    '#ff5f5f',
    '#ffffff',
    '#af87ff',
    '#00d787',
    '#0000d7',
    '#5f5f87',
    '#d7afaf',
    '#5fafaf',
    '#af5fff',
    '#d7d787',
    '#875f00',
    '#d7ffd7',
    '#00d700',
    '#000087',
    '#d7af87',
    '#8700ff',
    '#5f8700',
    '#5fffd7',
    '#ffd7ff',
    '#afff5f',
    '#afd7d7',
    '#875f5f',
    '#870087',
    '#d75f5f',
    '#afffaf',
    '#ffffd7',
    '#ff8700',
    '#00875f',
    '#d7ff5f',
    '#875fff',
    '#0087af',
    '#d7d7d7',
    '#005f00',
    '#ff5f87',
    '#af005f',
    '#ffaf87',
    '#ffffaf',
    '#87afd7',
    '#ff8787',
    '#d75f87',
    '#af87af',
    '#ff00ff',
    '#87d787',
    '#0000af',
    '#ff5faf',
    '#87875f',
    '#ffaf00',
    '#87d75f',
    '#ffd7d7',
    '#d7875f',
    '#afd787',
    '#5f0087',
    '#afff87',
    '#8787af',
    '#5f87ff',
    '#5f00af',
    '#afd700',
    '#af0087',
    '#ff5fd7',
    '#d787ff',
    '#00af87',
    '#00005f',
    '#afd75f',
  ]
}
