import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import ThreeUtil from 'three/logic/Util'
import { DefaultState } from 'types/state'
import AllInOne from '../AllInOne'
import { SectionContainer, SectionContent } from '../AllInOne/styles'
import Section from '../Section'

const connector = connect((state: DefaultState) => ({
  elementsHashes: {
    SupportPoint: state.SupportPoint,
    SegmentGroupSupportPoints: state.SupportPoint,
    SegmentGroup: state.SegmentGroup,
  },
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  // no actions yet
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  paths: string[]
}

type State = void

export class SegmentGroup extends PureComponent<Props, State> {
  render () {
    const { paths, elementsHashes } = this.props

    // only use the first path
    const path = paths?.[0] ?? ''

    const { id } = ThreeUtil.getElementInfo(path)

    const segmentGroup = elementsHashes.SegmentGroup[id]

    const supportPointPaths = (segmentGroup as any)?.['#SupportPointIds']
      ?.map((supportPointId: number) => `${path}/SupportPoint:${supportPointId}`) ?? []

    return (
      <div>
        <AllInOne
          paths={path ? [ path ] : []}
          type='SegmentGroup'
        />
        <SectionContainer>
          {/* TODO: translate */}
          <Section name='SupportPoints'>
            <SectionContent>
              {
                supportPointPaths.length
                  ? `${supportPointPaths.length} SupportPoints in ${path} selected`
                  : 'Nothing selected'
              }
            </SectionContent>
          </Section>
        </SectionContainer>
        {
          supportPointPaths.map((supportPointPath: any, index: number) => (
            <div key={supportPointPath}>
              <AllInOne
                paths={[ supportPointPath ]}
                allPaths={supportPointPaths}
                type='SegmentGroupSupportPoints'
                hideActions={index !== supportPointPaths.length - 1}
              />
            </div>
          ))
        }
      </div>
    )
  }
}

export default connector(SegmentGroup as any) as any
