import LogicInterface from './LogicInterface'
import IpcManager from '../IpcManager'

export default class BothLogic extends LogicInterface {
  on (...params: any[]) {
    IpcManager.external.on(...params)
    IpcManager.internal.on(...params)
  }

  send (...params: any[]) {
    IpcManager.external.send(...params)
    IpcManager.internal.send(...params)
  }

  removeListener (...params: any[]) {
    IpcManager.external.removeListener(...params)
    IpcManager.internal.removeListener(...params)
  }
}
