import cloneDeep from 'clone-deep'
import { DataActionsEnum } from 'store/data/consts'
import { handleWaterFluxFraction, actionNewData } from 'store/elements/logic/index'
import { ComparisonCasters } from 'types/comparison'
import type { ElementsHashes } from 'types/state'

export enum comparisonCastersActions {
  ACTION_ADD_COMPARISON_CASTER = 'ComparisonCaster/ACTION_ADD_COMPARISON_CASTER',
  DELETE = 'Nozzle/DELETE',
  DELETE_MULTIPLE = 'Nozzle/DELETE_MULTIPLE',
  UPDATE = 'Nozzle/UPDATE',
}

export function handleBuildComparisonCasterState (xmlData: XMLData) {
  const parsedXmlData = cloneDeep(xmlData)
  const elementsHashes = {} as ElementsHashes
  const changeItemHash: ChangeItemHash = {}
  const ccElementHash: CCElementHash = {}

  actionNewData(parsedXmlData, elementsHashes, false, changeItemHash, ccElementHash)
  const loopCounter = handleWaterFluxFraction(parsedXmlData)

  if (parsedXmlData.root.EditsBy3DCC) {
    delete parsedXmlData.root.EditsBy3DCC
  }

  delete parsedXmlData.root.Caster.StrandGuide
  const rootData = { ...parsedXmlData.root }

  return { elementsHashes, changeItemHash, ccElementHash, loopCounter, rootData }
}

// function to build elements hashes for every root before adding them to the store
export function addComparisonCaster (
  casterData: { simulationCaseId: string, root: Root},
  selectedIds: string[],
  overwrite: boolean,
) {
  // make a hash with each simulationCaseId as key and the root as value
  const comparisonCastersState: ComparisonCasters = {}

  try {
    const { simulationCaseId, root } = casterData
    const state = handleBuildComparisonCasterState({ root })

    comparisonCastersState[simulationCaseId] = state
  }
  catch (error) {
    console.log('error building comparison caster with key: ')
  }

  return {
    type: comparisonCastersActions.ACTION_ADD_COMPARISON_CASTER,
    comparisonCastersState,
    selectedIds,
    overwrite,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const comparisonCastersReducers: Record<string, Function | undefined> = {
  [DataActionsEnum.ACTION_RESET_ALL]: () => {
    return {}
  },

  [comparisonCastersActions.ACTION_ADD_COMPARISON_CASTER]:
  (
    state: Record<string, Root>,
    action: {comparisonCastersState: Record<string, any>, overwrite: boolean, selectedIds: string[]},
  ) => {
    if (action.overwrite) {
      return action.comparisonCastersState
    }

    const newState = { ...state, ...action.comparisonCastersState }

    Object.keys(newState).forEach(caseId => {
      if (!action.selectedIds.includes(caseId)) {
        delete newState[caseId]
      }
    })

    return newState
  },

  [comparisonCastersActions.DELETE]: (state: Record<string, Root>, action: { index: number }) => {
    const newState = { ...state }

    delete newState[action.index]

    return newState
  },
}

export default function (
  state: Record<string, Root> = {},
  action: any,
) {
  const handler = comparisonCastersReducers[action.type]

  return handler ? handler(state, action) : state
}
