import { AnalyzeTime } from 'Util'
import BaseObject from './BaseObject'

export default class SegmentGroup extends BaseObject {
  hasSupportPoints = false

  constructor (container: any, parent: any) {
    super(container, parent)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0) // @ts-ignore
  setValues (data: any, plHeight: number, path: string, isDeleted: boolean, isHidden: boolean) {
    super.setValues(path, isHidden)

    this.hasSupportPoints = Boolean(data['#SupportPointIds']?.length)
  }

  @AnalyzeTime(0)
  hide () {
    // don't hide because if it's hidden sensor points don't show up
    // super.hide()

    // this.container.visible = false

    // this.container.children.forEach((child: any) => {
    //   child.visible = false
    // })
  }
}
