import { AnalyzeTime } from 'Util'
import type { Data } from './type'

export default class Util {
  static NAMESPACE_URI = 'http://www.w3.org/2000/svg';
  static SIZE = 500
  static PADDING = 20

  @AnalyzeTime(0)
  static cutInRange (value: number, min: number, max: number) {
    const range = max - min
    let val = value - min

    if (range > 0) {
      if (val < 0) {
        val = 0
      }
      else if (val > range) {
        val = range
      }
    }
    else {
      if (val > 0) {
        val = 0
      }
      else if (val < range) {
        val = range
      }
    }

    return val
  }

  @AnalyzeTime(0)
  static getValue (value: number | string, decimals = 0, defaultValue = 0) {
    return ((Number(value)) || defaultValue).toFixed(decimals)
  }

  @AnalyzeTime(0)
  static hasValue (value: any) {
    return value !== undefined && value !== null
  }

  @AnalyzeTime(0)
  static getPointOnCircle (angle: number, radius: number) {
    const radians = (-angle + 180) / 180 * Math.PI

    return {
      x: radius * Math.sin(radians) + Util.SIZE / 2,
      y: radius * Math.cos(radians) + Util.SIZE / 2,
    }
  }

  @AnalyzeTime(0)
  static getCirclePathD (start: number, radius: number, reverse: boolean) {
    const { x: startX, y: startY } = Util.getPointOnCircle(start, radius)
    const { x: endX, y: endY } = Util.getPointOnCircle(start + 180, radius)

    return `
      M${startX} ${startY}
      A ${radius},${radius} 0 0 ${reverse ? 0 : 1} ${endX},${endY}
      A ${radius},${radius} 0 0 ${reverse ? 0 : 1} ${startX},${startY}
    `
  }

  @AnalyzeTime(0)
  static setDashArray (path: Element, radius: number, start: number, end: number) {
    const circumference = Math.PI * radius * 2

    path.setAttributeNS(null, 'stroke-dasharray', `${(end - start) / 360 * circumference}, ${circumference}`)
  }

  @AnalyzeTime(0)
  static getCirclePath (
    className: string,
    radius: number,
    start: number,
    end: number,
    color: string,
    thickness: number,
    reverse = false,
  ) {
    const path = document.createElementNS(Util.NAMESPACE_URI, 'path')

    path.setAttributeNS(null, 'd', Util.getCirclePathD(start, radius, reverse))
    path.setAttributeNS(null, 'fill', 'none')
    path.setAttributeNS(null, 'stroke', color)
    path.setAttributeNS(null, 'stroke-width', `${thickness}`)

    Util.setDashArray(path, radius, start, end)

    path.setAttribute('class', className)

    return path
  }

  @AnalyzeTime(0)
  static getText (
    className: string,
    value: number | string,
    x: number,
    y: number,
    fontSize: number,
    fontFamily: string,
    fontWeight: number | string,
    color: string,
    centerX = true,
    centerY = true,
  ) {
    const text = document.createElementNS(Util.NAMESPACE_URI, 'text')

    text.setAttributeNS(null, 'font-size', `${fontSize}`)
    text.setAttributeNS(null, 'font-family', fontFamily)
    text.setAttributeNS(null, 'font-weight', `${fontWeight}`)
    text.setAttributeNS(null, 'fill', color)

    if (centerX) {
      text.setAttributeNS(null, 'text-anchor', 'middle')
    }

    text.setAttributeNS(null, 'x', `${x}`)
    text.setAttributeNS(null, 'y', `${y - (centerY ? fontSize / 2 : 0)}`)

    text.setAttribute('class', className)

    text.textContent = `${value}`

    return text
  }

  @AnalyzeTime(0)
  static calcValues (options: any, data: Data) {
    const { max, min } = data
    const { endAngle, startAngle, primaryThickness, secondaryThickness } = options

    const halfSize = Util.SIZE / 2
    const radius = halfSize - primaryThickness / 2 - Util.PADDING
    const innerRadius = halfSize - primaryThickness - Util.PADDING - secondaryThickness / 2
    const range = max - min
    const angleRange = endAngle - startAngle

    return {
      halfSize,
      radius,
      innerRadius,
      range,
      angleRange,
      scale: angleRange / range,
    }
  }
}
