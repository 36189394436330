import { AnalyzeTime } from 'Util'

export default class ConditionUtil {
  @AnalyzeTime(0)
  static scrollValueNotUpdatedOr0 (lastDrawnScrollValue: number, scrollValue: number) {
    return (lastDrawnScrollValue === scrollValue || scrollValue === 0)
  }

  @AnalyzeTime(0)
  static noListOrNozzleOrRoller (elementList: any) {
    return (!elementList ||
    (!elementList.Nozzle && !elementList.Roller))
  }

  @AnalyzeTime(0)
  static mouseIsOutsideOfSectionView (sectionPlaneFolded: any, sectionPlaneHeader: any, raycaster: THREE.Raycaster) {
    // intersects the raycaster, which has the mouse's position with the section plane's elements
    // if intersects any of them, that means the mouse is in the section view
    if (sectionPlaneFolded && sectionPlaneHeader) {
      const intersects = raycaster.intersectObjects([ sectionPlaneFolded, sectionPlaneHeader ])

      return (!intersects.length)
    }

    return true
  }
}
