import * as THREE from 'three'

import Util from '../../logic/Util'
import BaseView from '../BaseView'
import Getters from './Getters'
import UiViewHandlers from './UiViewHandlers'
import CalculationUtil from './CalculationUtil'
import DrawHandlers from './DrawHandlers'
import EventHandlers from './EventHandlers'
import { OrthographicCamera, Vector2 } from 'three'
import { Views } from 'three/ThreeBase'
import type { PassedData } from 'react/Caster'
import FeatureFlags from 'react/FeatureFlags'
import ThreeManager from 'three/ThreeManager'
import TextureUtil from 'three/logic/TextureUtil'

export type ControlButtonImageNames = 'center.png' |
  'deselect.png' |
  'split.png' |
  'glasses.png' |
  'switcher.png' |
  'activeFilter.png' |
  'filter.png'

export default class UIView extends BaseView {
  static className = 'UIView'
  static currentPsslnPosition = 0
  static barMaterial = new THREE.MeshBasicMaterial({ color: '#BB1B1B' })
  static disabledBarMaterial = new THREE.MeshBasicMaterial({ color: '#5d0d0d' })
  static upOrDownButtonDisabled = false
  static sliderDimensions = {
    width: 20,
    height: 30,
    top: 150,
    bottom: 50,
  }

  UIButtonsMarginRight = 0
  mouseDown?: boolean
  isSectionActive = false
  scrollValue?: number
  prevScrollValue?: number
  buttons: any
  sectionViewDisabled
  switchScrollElementDisabled
  scrollableElements: Record<JumpOverOptions, boolean>
  selectionRect
  plHeight?: number
  shift?: boolean
  scrollBarButtonGroupActive?: boolean
  buttonUp?: any
  buttonDown?: any
  scrollBarButtonGroup?: any
  scrollBar?: any
  switchScrollElement?: any
  dataChanged?: boolean
  data?: RootData
  term: string
  termDisabled: boolean
  rollerChildren?: number
  tooltip?: Record<'UIView' | 'SectionView', Tooltip[]>
  featureFlags?: Record<string, boolean>
  camera: OrthographicCamera
  select?: (selectedData?: any, multiSelect?: boolean, massSelect?: boolean) => void
  setRollerVisible?: (rollerChildren: any) => void
  setTooltip?: (key: string, visible: boolean, tooltips?: any[]) => void
  setTerm?: (term: string) => void
  setTermDisabled?: (termDisabled: boolean) => void
  minPasslineCoord = 0
  maxPasslineCoord = 0
  scrolling = false
  buttonMaterials: {
    jumpOver: Record<'Nozzle' | 'Roller' | 'DataPoint', THREE.MeshBasicMaterial>,
    controls: Record<ControlButtonImageNames, THREE.MeshBasicMaterial>
  }

  constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    UIView.staticClassName = 'UIView'
    this.className = 'UIView'

    this.camera = Getters.getCamera()

    this.sectionViewDisabled = false
    this.switchScrollElementDisabled = false
    this.scrollableElements = {
      Nozzle: true,
      Roller: true,
      DataPoint: true,
    }
    this.term = ''
    this.termDisabled = false

    this.buttons = {}
    this.scrolling = false

    this.reset()

    this.selectionRect = Getters.getSelectionRect()
    UiViewHandlers.hideSelectionRect(this.selectionRect)

    this.scene.add(this.selectionRect)

    const jumpOverElementTypes: ('Nozzle' | 'Roller' | 'DataPoint')[] = [ 'Nozzle', 'Roller', 'DataPoint' ]
    const textureButtonsImageNames = [
      'center.png',
      'deselect.png',
      'split.png',
      'glasses.png',
      'switcher.png',
      'activeFilter.png',
      'filter.png',
    ]

    this.buttonMaterials = {
      jumpOver: {} as any,
      controls: {} as any,
    }

    for (let i = 0; i < jumpOverElementTypes.length; i++) {
      const jumpOverElementType = jumpOverElementTypes[i]
      const textureImage = DrawHandlers.textureImageMapper[jumpOverElementType]
      const texture = TextureUtil.load(`textures/ui/${textureImage}`)
      const material = new THREE.MeshBasicMaterial({ map: texture })

      this.buttonMaterials.jumpOver[jumpOverElementType] = material
    }

    for (let i = 0; i < textureButtonsImageNames.length; i++) {
      const textureButtonImageName = textureButtonsImageNames[i] as ControlButtonImageNames
      const texture = TextureUtil.load(`textures/ui/${textureButtonImageName}`)
      const material = new THREE.MeshBasicMaterial({ map: texture })

      this.buttonMaterials.controls[textureButtonImageName] = material
    }
  }

  reset () {
    this.plHeight = 0

    this.shift = false
    this.mouseDown = false
    this.isSectionActive = false
    this.scrollValue = 0
    this.scrollBarButtonGroupActive = false
    this.sectionViewDisabled = false
    this.term = ''
    this.scrollableElements = {
      Nozzle: true,
      Roller: true,
      DataPoint: true,
    }

    if (this.scrollBarButtonGroup) {
      this.scrollBar.visible = false
      this.scrollBarButtonGroup.visible = false
      this.buttonUp.visible = false
      this.buttonDown.visible = false
      this.switchScrollElement.visible = false
    }
  }

  setData (data: PassedData) {
    this.dataChanged = !(data.rootData || {}).equals(this.data || {})
    const redrawFilter = this.termDisabled !== data.termDisabled

    this.select = data.selectedMultiEditElements
    this.setRollerVisible = data.setRollerVisible
    this.setTooltip = data.setTooltip
    this.setTerm = data.setTerm
    this.setTermDisabled = data.setTermDisabled
    this.term = data.term
    this.termDisabled = data.termDisabled
    this.rollerChildren = data.rollerChildren || 2
    this.tooltip = data.tooltip
    this.data = data.rootData

    let minOrMaxPasslnChanged = false

    if (this.views.sectionView?.minPasslineCoord && this.views.sectionView?.maxPasslineCoord) {
      if (
        this.views.sectionView.minPasslineCoord !== this.minPasslineCoord ||
        this.views.sectionView.maxPasslineCoord !== this.maxPasslineCoord
      ) {
        this.minPasslineCoord = this.views.sectionView.minPasslineCoord
        this.maxPasslineCoord = this.views.sectionView.maxPasslineCoord
        minOrMaxPasslnChanged = true
      }
    }

    const prevSectionViewDisabled = this.sectionViewDisabled
    const prevSwitchScrollElementDisabled = this.switchScrollElementDisabled

    if (prevSwitchScrollElementDisabled !== this.switchScrollElementDisabled ||
      minOrMaxPasslnChanged
    ) {
      DrawHandlers.drawUpDownButtons(this)
    }

    const { Caster } = data.rootData || {}

    if (this.dataChanged && Caster) {
      UiViewHandlers.handleElementCount(this, data.elementsHashes)

      this.plHeight = CalculationUtil.calcPlHeight(Caster.Passline)
    }
    else if (!this.data || !this.data.Caster) {
      UiViewHandlers.handleElementCount(this, data.elementsHashes)
    }

    let redrawButtons = data.UIButtonsMarginRight !== this.UIButtonsMarginRight ||
      this.dataChanged ||
      prevSectionViewDisabled !== this.sectionViewDisabled ||
      redrawFilter

    if (!data.featureFlags.equals(this.featureFlags || {})) {
      this.scrollableElements = {
        Nozzle: FeatureFlags.canJumpOverNozzles(data.featureFlags),
        Roller: FeatureFlags.canJumpOverRollers(data.featureFlags),
        DataPoint: FeatureFlags.canJumpOverDataPoints(data.featureFlags),
      }
      redrawButtons = true

      if (FeatureFlags.canViewSidesCube(data.featureFlags)) {
        this.views.viewsView?.show()
      }
      else {
        this.views.viewsView?.hide()
      }
    }

    this.featureFlags = data.featureFlags

    if (redrawButtons) {
      DrawHandlers.drawButtons(this, redrawButtons, !!data.filterControlDefinitions.length, data.UIButtonsMarginRight)

      if (data.UIButtonsMarginRight !== this.UIButtonsMarginRight) {
        ThreeManager.base.renderScene()
      }
    }

    this.UIButtonsMarginRight = data.UIButtonsMarginRight
  }

  setButtonPos () {
    const { width, height, min, x, top, bottom } = this.scrollBarButtonGroup.userData

    if (
      !this.scrollBarButtonGroup ||
      this.plHeight === undefined ||
      !this.viewport ||
      this.scrollValue === undefined
    ) {
      return
    }

    const max = CalculationUtil.calcMaxPosition(min, top, bottom, height, this.viewport.height)
    const y = CalculationUtil.calcButtonYpos(min, max, this.scrollValue)

    const position = Getters.getPosition(width, height, x, y, this.viewport)

    this.scrollBarButtonGroup.position.copy(position)

    const currentPasslnPosition = Number((this.plHeight * this.scrollValue).toFixed(2))

    if (UIView.currentPsslnPosition !== currentPasslnPosition) {
      window.dispatchEvent(new CustomEvent('CurrentPasslnPositionChanged', { detail: { currentPasslnPosition } }))

      ;(window as any).currentPasslnPosition = currentPasslnPosition
    }

    UIView.currentPsslnPosition = currentPasslnPosition

    DrawHandlers.drawPasslineCoord(this.scrollBarButtonGroup, width)
    DrawHandlers.drawJumpToSectionPlaneButton(this, this.scrollBarButtonGroup, width)

    const upOrDownButtonDisabled = currentPasslnPosition <= this.minPasslineCoord ||
      currentPasslnPosition >= this.maxPasslineCoord

    if (UIView.upOrDownButtonDisabled !== upOrDownButtonDisabled) {
      DrawHandlers.drawUpDownButtons(this)
      ThreeManager.base.renderScene()
    }
  }

  resize (width: number, height: number) {
    this.viewport = {
      x: 0,
      y: 0,
      width,
      height,
    }

    UiViewHandlers.updateCamera(this, width, height)

    this.updateTransforms()
    DrawHandlers.drawScrollBar(this)
  }

  animate () {
    // override
  }

  updateTransforms () {
    const { width, height } = this.viewport

    if (!(width > 0 && height > 0)) {
      return
    }

    Object.values(this.buttons).forEach(button => {
      UiViewHandlers.updateButton(button, width, height)
    })
  }

  handleMouseDown (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseDown(event, mouseOnCanvas)

    this.mouseDown = true

    if (this.shift) {
      if (this.views.mainView) {
        this.views.mainView.controls.enableRotate = false
      }

      this.selectionRect.visible = true
    }

    if (intersects.length) {
      const { name } = intersects[0].object

      // handling clicks on slide bar or buttons at the slide bar
      EventHandlers.handleMouseDownAction(name, this)

      return false
    }
  }

  toggleRollerView = () => {
    EventHandlers.handleMouseUpAction('rollerSwitcher', this)
  }

  handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    if (this.scrolling) {
      this.scrolling = false
      this.views.sectionView?.animate()
      ThreeManager.base.renderScene()
      window.dispatchEvent(new CustomEvent('FinishedScrollingPasslnCoord'))
    }

    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    this.mouseDown = false
    this.scrollBarButtonGroupActive = false

    if (!event.shiftKey && this.views && this.views.mainView) {
      this.views.mainView.controls.enableRotate = true
    }

    if (this.selectionRect.visible) {
      EventHandlers.handleSelectionEnd(this, mouseOnCanvas)
    }

    if (intersects.length) {
      const { userData } = intersects[0].object
      const { action } = userData

      // handling clicks on buttons that are at the top
      EventHandlers.handleMouseUpAction(action, this, event.shiftKey)

      return false
    }
  }

  handleMouseMove (event: any, mouseOnCanvas: Vector2): boolean {
    if (this.selectionRect.visible && this.mouseDown && this.shift) {
      EventHandlers.handleSelection(this, mouseOnCanvas)
    }
    else {
      if (this.scrollBarButtonGroupActive) {
        EventHandlers.handleScroll(this, mouseOnCanvas)
      }
    }

    EventHandlers.handleTooltips(this, event, mouseOnCanvas)

    return true
  }

  handleKeyDown (event: any) {
    if (event.shiftKey) {
      this.shift = true
    }
  }

  handleKeyUp (event: any) {
    if (event.keyCode === 16) { // Shift
      this.shift = false

      UiViewHandlers.hideSelectionRect(this.selectionRect)
    }
  }

  sectionViewHandler (isSectionActive: boolean) {
    this.isSectionActive = isSectionActive
    this.views.mainView?.updateRoller(Util.RollerMode)

    if (this.views.mainView && this.views.sectionView) {
      if (isSectionActive) {
        this.views.mainView.sectionPlane.visible = true
        this.views.sectionView.showSection(this.viewport.width, this.viewport.height)
        this.scrollBar.visible = true
        this.scrollBarButtonGroup.visible = true
        this.buttonUp.visible = true
        this.buttonDown.visible = true
        this.switchScrollElement.visible = true
      }
      else {
        this.views.mainView.sectionPlane.visible = false
        this.views.sectionView.hideSection()
        this.scrollBar.visible = false
        this.scrollBarButtonGroup.visible = false
        this.buttonUp.visible = false
        this.buttonDown.visible = false
        this.switchScrollElement.visible = false
      }
    }
  }
}
