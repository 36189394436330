import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from '../../BaseDialog'
import HistoryEntry from './HistoryEntry'
import { CircularProgress } from '@material-ui/core'
import hotkeys from 'hotkeys-js'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const LoadMore = styled.div`${({ theme }) => css`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-top: ${theme.borderColor} solid 1px;
  color: ${theme.primary.font};
  cursor: pointer;
  height: 50px;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`}`

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  addHistory: ApplicationActions.addHistory,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  loadMore: boolean,
  noMoreItems: boolean
};

export class CasterHistoryDialog extends Component<Props & WithNamespaces, State> {
  static NAME: string = uuid()

  state = {
    loadMore: false,
    noMoreItems: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    ApiClient
      .get(`${Network.URI}/simulation_case/${currentSimulationCase._id}`)
      .then(({ simulationCase }) => {
        setCurrentSimulationCase(simulationCase)
      })

    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CasterHistoryDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleLoadData: (event: any) => void = () => {
    const { currentSimulationCase, addHistory } = this.props
    const { _id, history } = currentSimulationCase
    const lastSeen = history.slice(-1)[0]

    this.setState({ loadMore: true })

    ApiClient.get(`${Network.URI}/caster_history/${_id}/${lastSeen._id}`).then(({ history }) => {
      let noMoreItems = false

      if (history.length < 10) {
        noMoreItems = true
      }

      addHistory(history)

      this.setState({ loadMore: false, noMoreItems })
    })
  };

  @AnalyzeTime(0)
  render () {
    const { currentSimulationCase, t } = this.props
    const { loadMore, noMoreItems } = this.state

    return (
      <BaseDialog
        title={t('casterHistoryDialog.title')}
        icon='pe-7s-folder'
        header={t('casterHistoryDialog.header')}
        onClose={this.handleClose}
        small
        hasScroll
      >
        {
          currentSimulationCase.history.map((alteration: any) =>
            <HistoryEntry key={alteration._id} alteration={alteration} />)
        }
        {
          !noMoreItems &&
            <LoadMore onClick={this.handleLoadData}>
              {
                loadMore
                  ? <CircularProgress color='secondary' size={22} thickness={5} />
                  : <span>Load more</span>
              }
            </LoadMore>
        }
      </BaseDialog>
    )
  }
}

export default withNamespaces('caster')(connector(CasterHistoryDialog as any) as any) as any
