
import DrawUtil from '../../logic/DrawUtil'
import Util from '../../logic/Util'
import Nozzle from '../../objects/Nozzle'
import Getters from './Getters'
import * as THREE from 'three'
import CalculationUtil from './CalculationUtil'
import Segment from '../../objects/Segment'
import ConditionUtil from './ConditionUtil'
import CoordinateAxes from '../../objects/CoordinateAxes'
import MainUtil from './MainHandlers'
import MainView from '.'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'

export default class DrawHandlers {
  @AnalyzeTime(0)
  static handleRedrawView (view: MainView, data: any) {
    const { redraw, data: viewData, isRedrawing, dataChanged, hasChanges } = view

    // TODO: rework this it's messy...
    if (ConditionUtil.shouldBeRedrawing(redraw, viewData, data, hasChanges, isRedrawing, dataChanged)) {
      view.isRedrawing = true
      view.sceneReady = false // TODO: check if this is correct here

      if (view.caster) {
        view.scene.remove(view.caster)

        delete view.caster
      }

      view.reset()

      if (view.isNewCaster) {
        view.views?.uiView?.reset()
      }
    }
  }

  // TODO: view gets called too often
  @AnalyzeTime(0)
  static drawStrandGuide (view: MainView, mold?: Mold) {
    if (!view.caster) {
      return
    }

    const caster = view.caster
    const { SegmentGroup } = view.elementsHashes
    const { DataPoint, DataLine } = view.elementsHashes

    let container = caster

    Segment.reset()

    if (mold && mold.MoldFace) {
      const moldFacesGroup = view.caster.getObjectByName('moldFaces')

      mold.MoldFace.forEach(moldFace => {
        const face = new THREE.Group()

        face.name = moldFace._FixedLooseSide
        face.userData.side = moldFace._FixedLooseSide

        const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

        switch (moldFace._FixedLooseSide) {
          case 'FixedSide':
            newRotation.set(0, -Util.RAD90, 0)
            break
          case 'LooseSide':
            newRotation.set(0, Util.RAD270, 0)
            break
          case 'NarrowFaceRight':
            newRotation.set(0, Util.RAD270, 0)
            break
          case 'NarrowFaceLeft':
            newRotation.set(0, -Util.RAD90, 0)
            break
          default:
        }

        face.rotation.copy(newRotation)

        Util.addOrReplace(moldFacesGroup, face)

        for (const sensorPoint of moldFace.SensorPoint) {
          const path = `Mold/SensorPoint:${sensorPoint._id}`

          if (
            !(
              view.elementList.SensorPoint &&
                view.elementList.SensorPoint[path] &&
                view.dirtyList &&
                !~view.dirtyList.indexOf(path)
            )
          ) {
            DrawHandlers.handleDrawable(view, face, path, 'SensorPoint', sensorPoint)
          }
        }

        const StrandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

        for (const dataPoint of moldFace.DataPoint) {
          const path = `DataPoint:${dataPoint._id}`

          if (
            !(
              view.elementList.DataPoint &&
                view.elementList.DataPoint[path] &&
                view.dirtyList &&
                !~view.dirtyList.indexOf(path)
            )
          ) {
            DrawHandlers.handleDrawable(view, StrandContainer, path, 'DataPoint', dataPoint)
          }
        }
      })
    }

    Util.runPerElementHash(
      'SegmentGroup',
      'SegmentGroup',
      Object.keys(SegmentGroup).filter(key => key[0] !== '#').map(id => parseInt(id)),
      null,
      '',
      view.elementsHashes,
      (path: string, type: CasterElementNames, element: any, _parent: any, parentType: CasterElementNames) => {
        if (!view.views) {
          return
        }

        if (DrawUtil.isContainer(type)) {
          container = Getters.getContainer(view, container, caster, path, type, element)
        }

        if (!DrawUtil.isDrawable(type)) {
          return
        }

        if (view.className !== 'SectionView' && view.views.sectionView) {
          view.views.sectionView.largestNozzle = view.largestNozzle
          view.views.sectionView.widestNozzle = view.widestNozzle

          view.views.sectionView.largestNarrowNozzle = view.largestNarrowNozzle
          view.views.sectionView.widestNarrowNozzle = view.widestNarrowNozzle
        }

        if (
          (
            view.elementList[type] &&
              view.elementList[type][path] &&
              view.dirtyList &&
              !~view.dirtyList.indexOf(path)
          ) ||
          (type === 'Nozzle' && !FeatureFlags.canViewNozzlesIn3D(view.featureFlags)) ||
          ([ 'Roller', 'RollerBody', 'RollerBearing' ].includes(type) &&
          !FeatureFlags.canViewRollersIn3D(view.featureFlags))
        ) {
          return
        }

        if (
          type === 'SensorPoint' &&
          parentType === 'RollerBody' &&
          !FeatureFlags.canViewSensorPointsInRollerBodiesIn3D(view.featureFlags)
        ) {
          return
        }

        if (
          type === 'SupportPoint' &&
          !FeatureFlags.canViewSupportPointsIn3D(view.featureFlags)
        ) {
          return
        }

        DrawHandlers.handleDrawable(view, container, path, type, element)
      },
    )

    Object.keys(view.containerList.Segment).forEach(path => {
      const data = view.containerList.Segment[path].userData

      view.elementList.Segment[path].setValues(
        data,
        view.plHeight,
        path,
        ~view.deleteList.indexOf(path),
        ~(view.hideList || []).indexOf(path),
        view.elementList,
        view.elementsHashes,
      )
    })

    if (DataPoint) {
      const dataPointIds = Object.keys(DataPoint).filter(key => key[0] !== '#').map(id => parseInt(id))
      const numberOfDataPoints = dataPointIds.length
      const StrandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      for (let i = 0; i < numberOfDataPoints; i++) {
        DrawHandlers.handleDrawable(
          view,
          StrandContainer,
          `DataPoint:${dataPointIds[i]}`,
          'DataPoint',
          DataPoint[dataPointIds[i]],
        )
      }
    }

    if (view.className !== 'SectionView' && DataLine) {
      const dataLineIds = Object.keys(DataLine).filter(key => key[0] !== '#').map(id => parseInt(id))
      const numberOfDataLines = dataLineIds.length
      const StrandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      DrawHandlers.buildDataLineIndex(dataLineIds, DataLine)

      for (let i = 0; i < numberOfDataLines; i++) {
        DrawHandlers.handleDrawable(
          view,
          StrandContainer,
          `DataLine:${dataLineIds[i]}`,
          'DataLine',
          DataLine[dataLineIds[i]],
        )
      }
    }
  }

  static buildDataLineIndex (dataLineIds: number[], DataLine: DataLineHash) {
    const dataLinePositionIndexes: any = {}

    dataLineIds.forEach(id => {
      const dataLine = DataLine[id]

      if (!dataLine) {
        return
      }

      const positionKey = `${dataLine._xCoords[0]}-${dataLine._thickness_coord}-${dataLine._width_coord}`

      if (dataLinePositionIndexes[positionKey] === undefined) {
        dataLinePositionIndexes[positionKey] = 0
        dataLine.index = 0
      }
      else {
        dataLine.index = ++dataLinePositionIndexes[positionKey]
      }
    })
  }

  @AnalyzeTime(0)
  static handleNoDrawableElement (
    type: string,
    path: string,
    container: any,
    clickableObjects: any[],
    tooltipObjects: any[],
    sectionDetail: any,
    elementList: any,
    featureFlags?: Record<string, boolean>,
  ) {
    let parent = null

    if (type !== 'SegmentGroup' && type !== 'DataPoint' && type !== 'DataLine') {
      const parentInfo = Util.getParentInfo(path)

      if (!elementList[parentInfo.type]) {
        return
      }

      if (!parentInfo.path.includes('Mold')) {
        parent = elementList[parentInfo.type][parentInfo.path]
      }
    }

    elementList[type][path] = DrawUtil.getNewElementByType(
      type,
      parent,
      container,
      clickableObjects,
      tooltipObjects,
      sectionDetail,
      undefined,
      featureFlags,
    )
  }

  @AnalyzeTime(0)
  static handleDrawableNozzle (
    type: string,
    path: string,
    element: any,
    elementList: any,
    largestNozzle: number,
    widestNozzle: number,
    largestNarrowNozzle: number,
    widestNarrowNozzle: number,
  ) {
    const keysToBeChanged: any = {}
    const height = element._height / 1000
    const widthCoord = element._width_coord / 1000
    const angleWidth = element._anglewidth

    const b = Nozzle.getB(height, angleWidth / 2)
    const wide = b + Math.abs(widthCoord)

    const { side } = elementList[type][path].parent.container.userData

    if (!side.includes('Narrow')) {
      if (largestNozzle < height) {
        keysToBeChanged.largestNozzle = height
      }

      if (widestNozzle < wide) {
        keysToBeChanged.widestNozzle = wide
      }
    }
    else {
      if (largestNarrowNozzle < height) {
        keysToBeChanged.largestNarrowNozzle = height
      }

      if (widestNarrowNozzle < wide) {
        keysToBeChanged.widestNarrowNozzle = wide
      }
    }

    return keysToBeChanged
  }

  @AnalyzeTime(0)
  static handleDrawable (view: MainView, container: any, path: string, type: string, element: any) {
    if (!element) {
      return
    }

    if (!view.elementList[type]) {
      view.elementList[type] = {}
    }

    if (!view.elementList[type][path]) {
      DrawHandlers.handleNoDrawableElement(
        type,
        path,
        container,
        view.clickableObjects,
        view.tooltipObjects,
        view.sectionDetail,
        view.elementList,
        view.featureFlags,
      )
    }

    if (!view.elementList[type][path]) {
      return
    }

    if (type !== 'Segment') {
      view.elementList[type][path].setValues(
        element,
        view.plHeight,
        path,
        ~view.deleteList.indexOf(path),
        ~(view.hideList || []).indexOf(path),
        element._loop,
        view.sectionDetail,
      )

      switch (type) {
        case 'Roller':
        case 'RollerBody':
        case 'RollerBearing':
          view.elementList[type][path].setVisibility(view.rollerChildren)
          break
        case 'Nozzle':
          const keysToBeChanged = DrawHandlers.handleDrawableNozzle(
            type,
            path,
            element,
            view.elementList,
            view.largestNozzle,
            view.widestNozzle,
            view.largestNarrowNozzle,
            view.widestNarrowNozzle,
          )

          Object.keys(keysToBeChanged).forEach(key => {
            (view as any)[key] = keysToBeChanged[key]
          })
          break
        default:
      }

      // use to optimize
      // this.time3[type] = (this.time3[type] || 0) + (Date.now() - start3)
    }
  }

  @AnalyzeTime(0)
  static drawGridHelper (view: MainView) {
    if (!view.passlineCurve) {
      return
    }

    const { geometry } = view.passlineCurve.objects.line

    geometry.computeBoundingBox()
    const { min, max } = geometry.boundingBox

    const { length, calculatedPosition } = CalculationUtil.calcGridHelper(min, max)

    view.gridHelper = new THREE.GridHelper(length, length)
    view.gridHelper.name = 'GridHelper'
    view.gridHelper.position.set(
      calculatedPosition.x,
      calculatedPosition.y,
      calculatedPosition.z,
    )

    Util.addOrReplace(view.scene, view.gridHelper)
  }

  @AnalyzeTime(0)
  static redrawSectionPlane (view: MainView, Caster: Caster) {
    if (!view.views || !view.views.sectionView) {
      return
    }

    const { Mold: mold } = Caster

    view.coordinate = new CoordinateAxes(view.caster)
    view.coordinateStraight = new CoordinateAxes(view.caster, true)

    Util.sides.filter(side => side.indexOf('Narrow') === -1).forEach(name => {
      view.coordinate?.generateCoordinateAxes(Caster, name)
      view.coordinateStraight?.generateCoordinateAxes(Caster, name)
    })

    if (view.applyCurve) {
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
    }

    const { largestNozzle, widestNozzle, largestNarrowNozzle, widestNarrowNozzle } = view.views.sectionView

    const sectionPlaneGeometry = CalculationUtil.getSectionPlaneGeometry(
      mold,
      largestNarrowNozzle,
      largestNozzle,
      widestNarrowNozzle,
      widestNozzle,
    )

    view.sectionPlane.geometry = sectionPlaneGeometry
    view.sectionPlane.geometry.translate(-mold._thickness / 1000 / 2, 0, 0)

    view.views.sectionView.updatePlane = true

    if (!view.sectionDetail) {
      DrawHandlers.drawGridHelper(view)
    }
  }

  @AnalyzeTime(0)
  static drawCaster (view: MainView) {
    if (!view.data) {
      return
    }

    const { _thickness } = view.data.Caster.Mold
    const thickness = _thickness / 1000

    MainUtil.resetSegments(view.containerList)

    view.center2d = {
      x: -thickness / 2,
      y: view.plHeight - 1.5,
    }

    Object.values(view.sideLabels).forEach((label: any) => {
      label.visible = false
    })

    if (view.gridHelper) {
      view.gridHelper.visible = !view.sectionDetail
    }

    view.passlineCurve?.showStrand()
    view.passlineCurve?.setPosition(new THREE.Vector3(0, 0, 0))
    view.camera = view.perspectiveCamera
    view.sectionPlane.visible = !view.sectionDetail && Boolean(view.views?.uiView?.isSectionActive)

    view.setupControls()

    if (!view.sectionDetail) {
      view.jumpToFirst(false)
    }

    view.resize()
  }
}
