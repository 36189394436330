import { ApplicationMainActionsEnum, AppState, actionList } from './consts'
import IpcManager from 'IpcManager'
import ThreeManager from 'three/ThreeManager'

export function setOpenDialogs (dialogName: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_OPEN_DIALOGS,
    dialogName,
  }
}

export function setLoadingStatus (status: boolean) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_LOADING_STATUS,
    status,
  }
}

export function setTooltip (key: string, visible: boolean, tooltips: Array<any> = []) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_TOOLTIP,
    key,
    visible,
    tooltips,
  }
}

export function handleMenuAction (key: string, params: any) {
  if (Object.values(actionList).includes(key)) {
    return {
      type: key,
      params,
    }
  }
  else {
    IpcManager.send(key, params)

    return {
      type: null,
    }
  }
}

export function openDialog (dialogName: string, params?: any) {
  return {
    type: ApplicationMainActionsEnum.ACTION_OPEN_DIALOG,
    dialogName,
    params,
  }
}

export function closeDialog (dialogName: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_CLOSE_DIALOG,
    dialogName,
  }
}

export function setRequiredCommandFiles (
  nonexistentCommandFiles: any,
  nonexistentCommandFileMapping: Array<string>,
  visualizationConfigId: string,
) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_REQUIRED_COMMAND_FILES,
    nonexistentCommandFiles,
    nonexistentCommandFileMapping,
    visualizationConfigId,
  }
}

export function clearOpenAppDialogs () {
  return {
    type: ApplicationMainActionsEnum.ACTION_CLEAR_DIALOGS,
  }
}

export function setCurrentProject (currentProject: any) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_PROJECT,
    currentProject,
  }
}

export function setCurrentSimulationCase (currentSimulationCase: SimulationCase, preventDefault = false) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_SIMULATION_CASE,
    currentSimulationCase,
    preventDefault,
  }
}

export function addHistory (newHistory: Array<any>) {
  return {
    type: ApplicationMainActionsEnum.ACTION_UPDATE_HISTORY,
    newHistory,
  }
}

export function setCurrentCasterRoot (root: any = {}) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_CASTER_ROOT,
    root,
  }
}

export function updateCurrentProjectContainer (containerName: string, data: Array<any>) {
  return {
    type: ApplicationMainActionsEnum.ACTION_UPDATE_CURRENT_PROJECT_SCHEMA,
    containerName,
    data,
  }
}

export function setAuthenticationData (data: any = {}) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_AUTHENTICATION_DATA,
    data,
  }
}

export function switchProject () {
  return {
    type: 'ACTION_SWITCH_PROJECT',
  }
}

export function setTheme (darkTheme = true) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_THEME,
    darkTheme,
  }
}

export function setEditProjectId (projectId: string | undefined | null = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_PROJECT_ID,
    projectId,
  }
}

export function setEditSimulationCaseId (simulationCaseId: string | undefined | null = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_SIMULATION_CASE_ID,
    simulationCaseId,
  }
}

export function setEditConfigId (configId: string | undefined | null = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_CONFIG_ID,
    configId,
  }
}

export function setAppState (mode: number, avoidDashboard = false) {
  if (mode === AppState.Caster) {
    window.setTimeout(ThreeManager.base.resize, 0)
  }

  return {
    type: ApplicationMainActionsEnum.ACTION_SET_APP_STATE,
    mode,
    avoidDashboard,
  }
}

export function setSimpleDashboardTabIndex (tabIndex: number) {
  window.setTimeout(ThreeManager.base.resize, 0)

  return {
    type: ApplicationMainActionsEnum.ACTION_SET_SIMPLE_DASHBOARD_TAB_INDEX,
    tabIndex,
  }
}

export function setDefaultCasterDashboardConfig (defaultCasterDashboardConfigId?: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG,
    defaultCasterDashboardConfigId,
  }
}

export function setLastLoadedCasterCatalogId (lastLoadedCasterCatalogId: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID,
    lastLoadedCasterCatalogId,
  }
}

export function logout () {
  return {
    type: 'ACTION_RESET_STORE',
  }
}
