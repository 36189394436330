/* eslint-env browser */

import { AnalyzeTime } from 'Util'
import FastBase from './FastBase'

export default class Fill extends FastBase {
  constructor () {
    super('js-fill', 'fills', 'trace')
  }

  @AnalyzeTime(0)
  _buildPathD (x: Array<number>, y: Array<number>, width: number, height: number): string {
    let d = ''

    if (this.additionalData?.flipAxes) {
      const firstY = this._calcY(y[0], height)
      const lastY = this._calcY(y[y.length - 1], height)

      d += `L${0},${lastY}L${0},${firstY}`
    }
    else {
      const firstX = this._calcX(x[0], width)
      const lastX = this._calcX(x[x.length - 1], width)

      d += `L${lastX},${height}L${firstX},${height}`
    }

    for (let i = 0; i < y.length; i++) {
      const xValue = this._calcX(x[i], width)
      const yValue = this._calcY(y[i], height)

      // https://jsperf.com/string-concat-vs-array-join-10000/15
      d = d.concat('L', xValue.toFixed(2), ',', yValue.toFixed(2))
    }

    return `M${d.slice(1)}`
  }
}
