/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import * as VisualizationActions from 'store/visualization/actions'
import DataActions from 'store/data/actions'

import FeatureFlags from '../../../FeatureFlags'
import BaseDialog from '../../BaseDialog'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { Wrapper } from './Styles'

import SimulationCaseComponent from './SimulationCase'
import Logic from './Logic'
import CasterColumn from './CasterColumn'
import ProcessParameters from './ProcessParameters'
import VisualizationColumn from './VisualizationColumn'

import { DefaultState } from 'types/state'
import { AppState } from 'store/application/main/consts'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectDataDialog'

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  currentCasterRoot: state.application.main.currentCasterRoot,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
}), {
  logout: ApplicationActions.logout,
  setAppState: ApplicationActions.setAppState,
  openDialog: ApplicationActions.openDialog,
  setRequiredCommandFiles: ApplicationActions.setRequiredCommandFiles,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  setError: ErrorActions.setError,
  resetErrors: ErrorActions.resetErrors,

  // Logic
  setCurrentCasterRoot: ApplicationActions.setCurrentCasterRoot,
  resetReducer: DataActions.resetReducer,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

export class ProjectDataDialog extends Component<Props> {
  static NAME = uuid()
  processParametersButtonRef = React.createRef()

  @AnalyzeTime(0)
  componentDidMount (): void {
    Logic.init(this)

    const { setCurrentCatalogId, currentSimulationCase, setCurrentSimulationCase, t } = this.props

    ApiClient
      .get(`${Network.URI}/simulation_case/${currentSimulationCase._id}`)
      .then(({ simulationCase }) => {
        setCurrentSimulationCase(simulationCase)
      })
      .catch((response) => {
        // TODO: handle error
        // console.log(response)
      })

    const catalogSelectors = Logic.getSelectors(
      currentSimulationCase.casterCatalogList,
      'catalogId',
      'name',
      { value: t(`${PRE_TRANS}.caster.catalog.default`), disabled: true },
    )

    setCurrentCatalogId((catalogSelectors[1] || {}).key)

    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    Logic.init(this)
  }

  @AnalyzeTime(0)
  componentWillUnmount (): void {
    const { resetErrors } = this.props

    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
    resetErrors()
  }

  // @AnalyzeTime(0)
  handleClose = (isClick?: any) => {
    const { appState, closeDialog, currentSimulationCase, visualizationMetaInformation } = this.props
    const { config } = (visualizationMetaInformation || {})[appState] || {}

    if (isClick && appState === AppState.ParamDashboard && config) {
      const { processingParametersVerifications } = currentSimulationCase
      const lastProcessParametersVerification = (processingParametersVerifications || []).slice(-1)[0] || {}

      if (
        lastProcessParametersVerification.isVerified &&
        this.processParametersButtonRef &&
        this.processParametersButtonRef.current &&
        (this.processParametersButtonRef.current as any).props
      ) {
        (this.processParametersButtonRef.current as any).props.onClick()

        return
      }
    }

    closeDialog(ProjectDataDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleChange = (usedAppState: number, event: any) => {
    const { setCurrentCatalogId, setVisualizationMetaInformation, setError } = this.props
    const { name, value } = event.target

    setError(name)

    if (value === 'add') {
      switch (name) {
        case 'catalog': return Logic.handleUploadCasterCatalog(setCurrentCatalogId, setError)
        case 'config':
          return Logic.handleUploadVisualizationConfig(setVisualizationMetaInformation, setError, usedAppState)
        default:
      }
    }
    else {
      switch (name) {
        case 'catalog':
          return setCurrentCatalogId(value)
        case 'schema':
        case 'data':
        case 'config':
          return setVisualizationMetaInformation(name, value, usedAppState)
        default:
      }
    }
  };

  handleChangeCaster = this.handleChange.bind(this, AppState.Caster);
  handleChangeParamDashboard = this.handleChange.bind(this, AppState.ParamDashboard);
  handleChangeResultDashboard = this.handleChange.bind(this, AppState.ResultDashboard);

  // @AnalyzeTime(0)
  handleDelete = (event: any, type: string, key: string) => {
    const {
      updateCurrentProjectContainer,
      currentSimulationCase,
      appState,
      setAppState,
      visualizationMetaInformation,
    }:{
      updateCurrentProjectContainer: any
      currentSimulationCase:SimulationCase
      appState: 0 | 1 | 2
      setAppState: (state: AppState) => void
      visualizationMetaInformation: any
    } = event.props // TODO: this is not good!
    let name: string | null = null
    let data: any = null
    let namespace = 'visualization'

    switch (type) {
      case 'schema':
        name = 'visualizationSchemaList'
        data = ((currentSimulationCase as any)[name] as VisualizationSchema[]).filter(val => val.schemaId !== key)
        break
      case 'config':
        name = 'visualizationConfigList'
        data = ((currentSimulationCase as any)[name] as VisualizationConfig[]).filter(val => val.configId !== key)
        break
      case 'data':
        name = 'visualizationDataList'
        data = ((currentSimulationCase as any)[name] as any[]).filter(val => val.dataId !== key)
        break
      case 'catalog':
        namespace = 'caster'
        name = 'casterCatalogList'
        data = ((currentSimulationCase as any)[name] as CasterCatalog[]).filter(val => val.catalogId !== key)
        break
      default:
        return null
    }

    const { config } = (visualizationMetaInformation || {})[appState] || {}

    if (type === 'config' && config === key) {
      setAppState(AppState.Caster)
    }

    ApiClient
      .del(`${Network.URI}/${namespace}_${type}/${key}`)
      .then(() => updateCurrentProjectContainer(name, data))
      // eslint-disable-next-line no-console
      .catch((error: Error) => console.log(error))
  };

  handleDeleteSchema = this.handleDelete.bind('schema', this);
  handleDeleteConfig = this.handleDelete.bind('config', this);
  handleDeleteData = this.handleDelete.bind('data', this);
  handleDeleteCatalog = this.handleDelete.bind('catalog', this);

  @AnalyzeTime(0)
  render () {
    const { t, featureFlags, currentProject, appState, currentCasterRoot, visualizationMetaInformation } = this.props
    const casterViewer = FeatureFlags.canViewCaster(featureFlags)
    const canViewResultVisualization = FeatureFlags.canViewResultDashboard(featureFlags)

    const { config, data } = (visualizationMetaInformation || {})[appState] || {}

    return (
      <BaseDialog
        title={currentProject.description || t(`${PRE_TRANS}.title`)}
        icon='pe-7s-folder'
        header={currentProject.name || t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose.bind(this, true)}
        hideCloseButton={
          (appState === AppState.Caster && !Object.entries(currentCasterRoot || {}).length) ||
          (appState === AppState.ParamDashboard && !config) ||
          (appState === AppState.ResultDashboard && (!data || data === 'default' || !config))
        }
      >
        <SimulationCaseComponent />
        <Wrapper>
          {
            casterViewer &&
              <CasterColumn
                onClose={this.handleClose}
                onChange={this.handleChangeCaster}
                onDeleteConfig={this.handleDeleteConfig}
                onDeleteCatalog={this.handleDeleteCatalog}
              />
          }
          <ProcessParameters
            onClose={this.handleClose}
            onChange={this.handleChangeParamDashboard}
            onDeleteConfig={this.handleDeleteConfig}
            processParametersButtonRef={this.processParametersButtonRef}
          />
          {
            canViewResultVisualization &&
              <VisualizationColumn
                onClose={this.handleClose}
                onChange={this.handleChangeResultDashboard}
                onDeleteConfig={this.handleDeleteConfig}
                onDeleteData={this.handleDeleteData}
                onDeleteSchema={this.handleDeleteSchema}
              />
          }
        </Wrapper>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(ProjectDataDialog as any) as any) as any
