/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'core-js/stable'
import 'normalize.css'
import 'typeface-roboto'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'pixeden-stroke-7-icon'

import './rc-tooltip.css'
import './styles.css'

import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import styled from 'styled-components'

import App from './App'
// import registerServiceWorker from './registerServiceWorker' //TODO: fix service worker

import Index from './store/'
import './translation/i18n'

import Util from './logic/Util'
import { parseJWT } from 'Util/authUtil'
import ApiClient from 'store/apiClient'
import { useConfig } from 'config'

// TODO: try to solve as much warnings as possible! But some warnings are only in DEV so we can ignore them!
// @ts-ignore
window.ELECTRON_DISABLE_SECURITY_WARNINGS = true

Util.init()
// @ts-ignore
window.meta = window.meta || {}
// @ts-ignore
window.isElectron = Boolean(window && window.process && window.process.type)

require('./TitleBar')

if (window.isElectron) {
  localStorage.removeItem('tokenData')
}

document.addEventListener('dragover', function (event) {
  event.preventDefault()

  return false
}, false)

document.addEventListener('drop', function (event) {
  event.preventDefault()

  // console.log(event.dataTransfer.files[0])

  return false
}, false)

document.addEventListener('keydown', event => {
  if (event.ctrlKey === true && /^[+-=0]$/.test(event.key)) {
    event.preventDefault()
  }
}, true)
// @ts-ignore
document.getElementById('root').addEventListener('wheel', event => {
  if (event.ctrlKey === true) {
    event.preventDefault()
  }
}, true)
// @ts-ignore
document.getElementById('titlebar').addEventListener('wheel', event => {
  if (event.ctrlKey === true) {
    event.preventDefault()
  }
}, true)

const notistackRef = React.createRef()
// @ts-ignore
const onClickDismiss = key => () => {
  // @ts-ignore
  notistackRef.current.handleDismissSnack(key)
}

const Button = styled.span`
  cursor: pointer;
  margin-right: 10px;
  margin-top: 3px;

  :hover {
    text-decoration: underline;
  }

  i {
    font-size: 20px;
  }
`

setInterval(() => {
  const tokenDataRaw = localStorage.getItem('tokenData')

  if (!tokenDataRaw) {
    return
  }

  const tokenData = JSON.parse(tokenDataRaw) as AuthResult

  const accessJWTData = parseJWT(tokenData.accessToken)
  const refreshJWTData = parseJWT(tokenData.refreshToken)
  const now = Date.now() / 1000 + 10

  if (accessJWTData.exp < now && refreshJWTData.exp > now) {
    ApiClient.post(
      `${useConfig().apiBaseURL}/auth/refresh`,
      {
        data: {
          /* eslint-disable camelcase */
          refresh_token: tokenData.refreshToken,
          client_id: 'application',
          scopes: 'write',
          grant_type: 'refresh_token',
          /* eslint-enable camelcase */
        },
      },
    )
      .then((data: AuthResult) => {
        delete data.featureFlags

        localStorage.setItem('tokenData', JSON.stringify(data))
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error.message)
      })

    return
  }

  if (accessJWTData.exp < now && refreshJWTData.exp < now) {
    localStorage.removeItem('tokenData')

    // TODO: warn user!?

    window.location.reload()
  }
}, 1000)

const container = document.getElementById('root')

if (!container) {
  throw new Error('No root element found')
}

const root = ReactDOMClient.createRoot(container)

const content = (
  // @ts-ignore
  <Provider store={Index.getStore()}>
    <SnackbarProvider
      ref={notistackRef}
      action={key => <Button onClick={onClickDismiss(key)}><i className='pe-7s-close-circle' /></Button>}
      style={{ marginTop: '15px', whiteSpace: 'pre-line' }}
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'center',
        }
      }
    >
      <App />
    </SnackbarProvider>
  </Provider>
)

root.render(content)

// registerServiceWorker()
