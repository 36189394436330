import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from 'store/visualization/actions'

import { Dialog, DialogBackground, Header, I, HeaderTitle } from './Styles'

import PlotContent from './PlotContent'
import { Translation } from 'types/translation'

type Props = {
  showPlotList?: () => void,
  t: Translation
};

class PlotConfigurator extends Component<Props> {
  handleClose = () => {
    const { showPlotList } = this.props

    if (showPlotList) {
      showPlotList()
    }
  };

  render () {
    const { t } = this.props

    return (
      <div>
        <DialogBackground />
        <Dialog>
          <div>
            <Header title={t('plotConfigurator.title')}>
              <I className='pe-7s-graph2' left />
              <HeaderTitle>{t('plotConfigurator.label')}</HeaderTitle>
              <I className='pe-7s-close' onClick={this.handleClose} title={t('plotConfigurator.close')} />
            </Header>
            <PlotContent />
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connect(null, {
  showPlotList: VisualizationActions.showPlotList,
})(PlotConfigurator as any)) as any
