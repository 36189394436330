
import * as THREE from 'three'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TWEEN from '@tweenjs/tween.js'

import Util from '../../logic/Util'

import BaseView from '../BaseView'

import LodUtil from '../../logic/LodUtil'
import PhantomHandler from './PhantomElementHandler'
import MainHandlers from './MainHandlers'
import CalculationUtil from './CalculationUtil'
import DrawHandlers from './DrawHandlers'
import UpdateTransformHandler from './UpdateTransformHandler'
import Getters from './Getters'
import CameraHandlers from './CameraHandlers'
import JumpHandlers from './JumpHandlers'
import EventHandlers from './EventHandlers'
import { Views } from 'three/ThreeBase'
import PasslineCurve from 'three/objects/PasslineCurve'
import Mold from 'three/objects/Mold'
import { PerspectiveCamera, Vector2 } from 'three'
import CoordinateAxes from 'three/objects/CoordinateAxes'
import type { PassedData } from 'react/Caster'
import type { ElementsHashes } from 'types/state'
import Verify from '../../../logic/Util'
import ThreeManager from 'three/ThreeManager'

export const CAMERA_X_ANGLE = 35 // 25
export const CAMERA_Y_ANGLE = 40 // 35

export default class MainView extends BaseView {
  constructor (renderer: THREE.WebGLRenderer, views: Partial<Views>, splitMode = 0) {
    super(renderer, views)

    MainView.staticClassName = 'MainView'
    this.className = 'MainView'

    this.splitMode = splitMode
    this.sectionViewExpanded = true
    this.sectionDetail = false
    this.sceneReady = false
    this.isRedrawing = false

    this.reset()

    this.deleteList = []
    this.selection = []
    this.plHeight = 0
    this.spacer = 0.5

    this.largestNozzle = 0
    this.widestNozzle = 0
    this.largestNarrowNozzle = 0
    this.widestNarrowNozzle = 0

    this.font = Util.fontRobotoMedium
    this.fontMaterial = new THREE.MeshBasicMaterial({ color: '#565c61' })
    this.labelLineMaterial = new THREE.LineBasicMaterial({ color: '#3f454a' })

    this.perspectiveCamera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000)
    this.perspectiveCamera.position.z = 4
    this.orthographicCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.1, 1000)

    this.oldCameraPosition = this.perspectiveCamera.position.clone()

    this.camera = this.perspectiveCamera
    this.setupControls()
    this.setCameraPosition = CameraHandlers.setCameraPosition

    this.ambientLight = new THREE.AmbientLight(0xffffff, 1)
    this.ambientLight.name = 'AmbientLight'
    this.scene.add(this.ambientLight)

    this.light = new THREE.DirectionalLight(0xffffff, 1)
    this.light.position.set(1, 1, 0)

    this.light2 = new THREE.DirectionalLight(0xffffff, 0.5)
    this.light2.position.set(-1, -1, 0)

    this.lightGroup = new THREE.Group()
    this.lightGroup.name = 'DirectionalLightGroup'

    this.lightGroup.add(this.light)
    this.lightGroup.add(this.light2)

    this.lightGroup.position.copy(this.camera.position).normalize()

    this.scene.add(this.lightGroup)

    const planeGeometry = new THREE.PlaneBufferGeometry(2, 4, 1)
    const planeMaterial = new THREE.MeshBasicMaterial({
      color: '#00b0b2',
      side: THREE.DoubleSide, // !
    })

    this.sectionPlane = new THREE.Mesh(planeGeometry, planeMaterial)
    this.sectionPlane.name = 'SectionPlane'
    this.sectionPlane.rotateX(-Util.RAD90)
    this.sectionPlane.visible = false
    this.scene.add(this.sectionPlane)

    const geometry = new (THREE as any).Geometry() // TODO: is this used?

    geometry.vertices.push(
      new THREE.Vector3(-10, 0, -1),
      new THREE.Vector3(25, 0, -1),
    )
  }

  className
  splitMode
  sectionViewExpanded
  sectionDetail
  sceneReady
  isRedrawing
  deleteList: any[]
  selection: any[]
  plHeight
  spacer
  largestNozzle
  widestNozzle
  largestNarrowNozzle
  widestNarrowNozzle
  font: any
  fontMaterial: THREE.Material
  labelLineMaterial
  camera: any
  perspectiveCamera: PerspectiveCamera
  orthographicCamera
  oldCameraPosition
  setCameraPosition
  ambientLight
  light
  light2
  lightGroup
  sectionPlane
  setupPerspectiveControls: any
  setupOrthographicControls: any
  term? = ''
  containerList: any
  elementList: any
  phantomElementList: any
  sideLabels: any
  selectedElements: any
  applyCurve = true
  controls: any
  dataChanged?: boolean
  amountOfComparisonCasterColumns = 0
  elementsHashes: ElementsHashes = {
    AirLoop: {},
    CoolingLoop: {},
    CoolingZone: {},
    LoopAssignment: {},
    SegmentGroup: {},
    Segment: {},
    SegmentGroupSupportPoints: {},
    SupportPoint: {},
    RollerBody: {},
    RollerBearing: {},
    Nozzle: {},
    Roller: {},
    SensorPoint: {},
    StrandGuide: {},
    DataPoint: {},
    DataLine: {},
  }

  selectedElement?: (selection?: any, key?: boolean) => void
  clearDirtyList?: () => void
  dirtyList?: string[]
  hideList?: string[]
  data?: RootData
  resetTarget?: () => void
  rollerChildren?: unknown
  isNewCaster?: boolean
  featureFlags?: Record<string, boolean>
  redraw?: boolean
  setRollerVisible?: (mode: number) => void
  setLoadingStatus?: (status: boolean) => void
  target?: any
  removeDeletePaths?: (paths: string[]) => void
  passlineCurve?: PasslineCurve
  mold?: Mold
  hasChanges?: boolean
  additionalData?: any
  tweenActive?: boolean
  perspectiveControls?: any
  orthographicControls?: any
  cameraTween?: any
  caster?: THREE.Group
  setTerm?: any
  coordinate?: CoordinateAxes
  coordinateStraight?: CoordinateAxes
  gridHelper?: THREE.GridHelper
  center2d?: Coord
  editElements?: any
  parentPath?: string
  phantoms?: THREE.Group
  sectionPlaneFolded?: any

  reset () {
    super.reset()

    this.term = ''
    this.containerList = {}
    this.elementList = {}
    this.phantomElementList = {}
    this.sideLabels = {}
    this.selectedElements = []
    this.applyCurve = true
    this.largestNozzle = 0
    this.largestNarrowNozzle = 0
    this.widestNozzle = 0
    this.widestNarrowNozzle = 0
    this.deleteList = []
    this.selection = []
    this.plHeight = 0
    this.additionalData = undefined
  }

  setCenter () {
    if (!this.controls || !this.camera) {
      return
    }

    const center = new THREE.Vector3(0, this.controls.target.y, 0)
    const position = this.camera.position

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    CameraHandlers.tweenCamera(this, position, center, undefined, undefined, 500, () => {})
  }

  setView (side: number) {
    if (!this.camera) {
      return
    }

    const calculated = CalculationUtil.calcSetView(
      side,
      this.camera,
      this.controls,
      this.plHeight,
    )

    if (!calculated) {
      return
    }

    const { position, center, xAngle, yAngle } = calculated

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    CameraHandlers.tweenCamera(this, position, center, xAngle, yAngle, 500, () => {})
  }

  setData (data: PassedData) {
    // TODO: could verify if data changed

    this.dataChanged = !(this.data || {}).equals(data.rootData) // TODO: Highly inefficient
    this.elementsHashes = data.elementsHashes
    this.selectedElement = data.selectedMultiEditElements
    this.clearDirtyList = data.clearDirtyPaths
    this.dirtyList = data.dirtyPaths
    this.hideList = data.dirtyDeletePaths // since we want to hide on delete we are not using data.hidePaths here
    this.data = data.rootData
    this.resetTarget = data.resetTarget
    this.rollerChildren = data.rollerChildren || 2
    this.isNewCaster = data.isNewCaster
    this.featureFlags = data.featureFlags
    this.redraw = data.redraw
    this.setRollerVisible = data.setRollerVisible
    this.setLoadingStatus = data.setLoadingStatus
    this.target = data.target
    this.removeDeletePaths = data.removeDeletePaths
    this.amountOfComparisonCasterColumns = data.amountOfComparisonCasterColumns

    PhantomHandler.setPhantomData(this, data)

    DrawHandlers.handleRedrawView(this, data)

    this.hasChanges = data.hasChanges

    MainHandlers.updateCasterData(this, data)
  }

  updateTransforms () {
    const { Caster } = this.data || {}
    const SegmentGroupHash = this.elementsHashes.SegmentGroup

    if (!Caster || !Verify.hasElementType(SegmentGroupHash)) {
      return
    }

    this.passlineCurve?.setValues(Caster, this.clickableObjects, this.applyCurve)
    this.mold?.setValues(Caster, this.additionalData)
    this.passlineCurve?.drawStrand()

    UpdateTransformHandler.updateTransformPhantoms(
      this.elementList,
      this.phantomElementList,
      SegmentGroupHash,
      this.elementsHashes,
    )

    UpdateTransformHandler.updateTransformNewPhantoms(this.phantomElementList)

    UpdateTransformHandler.updateTransformSegments(
      this.containerList.Segment,
      this.elementList.Segment,
    )
    UpdateTransformHandler.updateTransformDataPoints(this.containerList.Strand, this.elementList.DataPoint)
    UpdateTransformHandler.updateTransformDataLines(this.containerList.Strand, this.elementList.DataLine)

    if (!this.sectionDetail) {
      MainHandlers.handleNoSectionDetail(this)
    }
  }

  updateRoller (displayType: number) {
    const rollerObject = this.elementList.Roller || {}
    const rollerPaths = Object.keys(rollerObject)

    for (let i = 0; i < rollerPaths.length; i++) {
      rollerObject[rollerPaths[i]].setVisibility(displayType)
    }
  }

  resize (width?: number, height?: number) {
    if (width !== undefined && height !== undefined) {
      const calc = CalculationUtil.calcResize(this.sectionViewExpanded, this.splitMode, height, width)

      this.viewport = {
        x: calc.x,
        y: calc.y,
        width: calc.nWidth,
        height: calc.nHeight,
      }
    }

    if (this.camera) {
      this.camera.aspect = this.viewport.width / this.viewport.height
      this.camera.updateProjectionMatrix()
    }
  }

  animate (elapsed: number) {
    if (!this.camera) {
      return
    }

    const { position } = this.camera

    TWEEN.update(elapsed)

    if (this.oldCameraPosition.equals(position)) {
      return
    }

    if (
      this.target &&
      this.resetTarget &&
      !this.tweenActive &&
      this.oldCameraPosition.clone().sub(position).length() > 0
    ) {
      this.resetTarget()
    }

    this.oldCameraPosition = position.clone()

    this.lightGroup.position.copy(position).normalize()
    this.lightGroup.lookAt(new THREE.Vector3(0, 0, 0))

    LodUtil.handleLoDs(this.camera, this.elementList, this.phantomElementList)

    this.controls.update()
  }

  handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    this.selection = []

    if (intersects.length) {
      MainHandlers.handleIntersects(this, intersects)
    }

    if (this.selection.length && this.selectedElement) {
      const multiSelect = (event.ctrlKey || event.metaKey)

      this.selectedElement(this.selection, multiSelect)
    }

    if (event.shiftKey) {
      this.controls.enableRotate = true
    }

    return intersects
  }

  // Filter
  jumpToFiltered () {
    if (this.resetTarget) {
      this.resetTarget()
    }

    if (!this.containerList || !this.containerList.Segment) {
      return
    }

    if (!this.term || this.term === 'r' || this.term === 'n') {
      this.jumpToFirst(true, true)

      return
    }

    const segmentList = Object.values(this.containerList.Segment)
    const segments = segmentList.filter((segment: any) => segment.visible)

    JumpHandlers.jumpToSegments(this, segments)

    this.views?.sectionView?.setView()
  }

  // Crosshairs
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  jumpToFilter (term: string, callback = () => {}, isJumpToSectionView = false) {
    const { elementsHashes } = this
    const segments = Getters.getSegmentsFromSegmentGroup(
      elementsHashes,
      term,
      this.containerList,
    )

    JumpHandlers.jumpToSegments(this, segments, callback, true, isJumpToSectionView)
  }

  jumpToFirst (playAnimation = true, isDefaultView = false) {
    if (!this.containerList || !this.containerList.Segment) {
      return
    }

    const segmentList = Object.values(this.containerList.Segment)
    const segments = segmentList.slice(0, 1)

    JumpHandlers.jumpToSegments(this, segments, undefined, playAnimation)

    if (!isDefaultView) {
      this.views?.sectionView?.setView()
    }
  }

  handleKeyDown (event: any) {
    if (!this.sceneReady) {
      return
    }

    if (event.shiftKey && !this.views?.uiView?.mouseDown) {
      this.controls.enableRotate = false
    }

    EventHandlers.handleKeyCode(this, event)
  }

  handleKeyUp (event: any) {
    if (!event.shiftKey) {
      this.controls.enableRotate = true
    }
  }

  handleSelection (start: Vector2, end: Vector2) {
    if (!this.camera) {
      return
    }

    const { min, max } = CalculationUtil.calcSelection(this.viewport, start, end)

    const elementPaths = Getters.getSelectedElementsPaths(this.camera, min, max, this.clickableObjects)

    if (this.selectedElement) {
      this.selectedElement(elementPaths, true)
    }
  }

  updateSegments (segmentPaths: number[], newName: string) {
    const { Segment } = this.elementList

    if (!Segment) {
      return
    }

    for (let i = 0; i < segmentPaths.length; i++) {
      const segment = Segment[segmentPaths[i]]

      if (segment) {
        segment.updateName(newName)
      }

      ThreeManager.base.renderScene()
    }
  }

  setupControls (center = false) {
    if (!this.perspectiveControls) {
      MainHandlers.setupPerspectiveControls(this)

      if (this.perspectiveControls) { // to render when user moves the camera
        this.perspectiveControls.addEventListener('change', () => ThreeManager.base.renderScene())
      }
    }

    if (!this.orthographicControls) {
      MainHandlers.setupOrthographicControls(this)
    }

    if (center) {
      this.orthographicControls.target.copy(center)
    }

    this.perspectiveControls.enabled = true
    this.orthographicControls.enabled = false

    this.controls = this.perspectiveControls
    this.controls.update()
  }

  unmount () {
    if (this.perspectiveControls) {
      this.perspectiveControls.dispose()
    }

    if (this.orthographicControls) {
      this.orthographicControls.dispose()
    }

    this.clickableObjects = []
    this.containerList = {}
    super.unmount()
  }
}
