import type { ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'

export default class CalculateHelperFunctions {
  @AnalyzeTime(0)
  static getRollerList (elementsHashes: ElementsHashes, startId: number, side: string, hidePaths: string[]) {
    // get segment groups, filter out #hasChanges
    const segmentGroups = Object.values(elementsHashes.SegmentGroup)
      .filter(value => typeof value === 'object')

    if (!segmentGroups || !segmentGroups.length || !side) {
      return []
    }

    const startIndex = segmentGroups.findIndex(segmentGroup => (segmentGroup || {})._id === startId)
    const rollerList = []

    for (let i = startIndex; i < segmentGroups.length; i++) {
      const { _id: sgId, '#SegmentIds': segmentIds } = (segmentGroups[i] as any) || {}
      let segment: Segment = null as any

      for (let j = 0; j < segmentIds.length; j++) {
        const seg = elementsHashes.Segment[segmentIds[j]]

        if (seg?._FixedLooseSide === side) {
          segment = seg
        }
      }

      if (!segment) {
        continue
      }

      const rollerPath = `SegmentGroup:${sgId}/Segment:${segment._id}/Roller`
      // get rollers and filter the ones which should be hidden
      const rollers = (segment as any)['#RollerIds'].map((id: number) => elementsHashes.Roller[id])
        .filter((roller: any) => roller._id !== undefined && !~hidePaths.indexOf(`${rollerPath}:${roller._id}`))

      rollerList.push(...rollers)
    }

    return rollerList
  }

  @AnalyzeTime(0)
  static calculatePassLn (
    minPassLn: number,
    { SegmentGroup, Segment }: { SegmentGroup: number, Segment: number },
    elementsHashes: ElementsHashes, hidePaths: string[],
  ) {
    if (elementsHashes.StrandGuide && SegmentGroup && Segment) {
      const segmentGroupIds = ((elementsHashes.StrandGuide[1] || {} as any)['#SegmentGroupIds'] || [])

      if (!segmentGroupIds) {
        return null
      }

      const segmentGroup = elementsHashes.SegmentGroup[segmentGroupIds[0]]
      const segmentSide = (elementsHashes.Segment[Segment] || {})._FixedLooseSide || ''

      const rollerArray = CalculateHelperFunctions.getRollerList(
        elementsHashes,
        (segmentGroup || {})._id || 1,
        segmentSide,
        hidePaths,
      )

      if (rollerArray.length === 0) {
        return Number(((elementsHashes.SegmentGroup[SegmentGroup] || {})._passln_coord || 0))
      }

      if (rollerArray.length === 1) {
        return Number(rollerArray[0]._passln_coord) - Number(rollerArray[0]._diameter) / 2
      }

      let rollerPasslnLow = rollerArray
        .filter(roller => Number(roller._passln_coord) <= minPassLn)
        .sort((a, b) => b._passln_coord - a._passln_coord)[0]

      let rollerPasslnHigh = rollerArray
        .filter(roller => Number(roller._passln_coord) > minPassLn)
        .sort((a, b) => a._passln_coord - b._passln_coord)[0]

      if (!rollerPasslnHigh || !rollerPasslnLow) {
        const segmentGroupIndex = this.getSegmentGroupIndex(elementsHashes.SegmentGroup, SegmentGroup)
        const prevSegmentGroup = segmentGroupIndex - 1

        if (prevSegmentGroup < 0) {
          const roller = rollerArray.sort((a, b) => a._passln_coord - b._passln_coord)[0]

          return Number(roller._passln_coord) - Number(roller._diameter) / 2
        }

        const prevRollerArray = (elementsHashes.Segment[Segment] || {}).Roller || []

        if (!rollerPasslnLow) {
          rollerPasslnLow = prevRollerArray.sort((a, b) => b._passln_coord - a._passln_coord)[0]
        }
        else {
          rollerPasslnLow = prevRollerArray.sort((a, b) => b._passln_coord - a._passln_coord)[0]
          rollerPasslnHigh = rollerArray.sort((a, b) => a._passln_coord - b._passln_coord)[0]
        }
      }

      if (rollerPasslnHigh && rollerPasslnLow) {
        const low = Number(rollerPasslnLow._passln_coord) + Number(rollerPasslnLow._diameter) / 2
        const high = Number(rollerPasslnHigh._passln_coord) - Number(rollerPasslnHigh._diameter) / 2

        return (low + (high - low) / 2).toString()
      }
    }

    return null
  }

  static getSegmentGroupIndex (segmentGroupHash: SegmentGroupHash, targetGroup: number) {
    return Object.keys(segmentGroupHash).findIndex(key => (segmentGroupHash[parseInt(key)] || {})._id === targetGroup)
  }
}
