import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import { Form, Button } from '../DialogStyles'
import Input from '../../../../specific/Input'
import { Network } from 'network/Network'
import ApiClient from 'store/apiClient'
import * as VisualizationActions from 'store/visualization/actions'
import { ErrorMessage } from 'react/components/Button/styles'
import VisUtil from '../../../VisUtil'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'addPlotDialog'

const connector = connect(({ visualization, application }: DefaultState) => ({
  addPlotViewId: visualization.addPlotViewId,
  currentDashboard: visualization.currentDashboard,
  currentSimulationCase: application.main.currentSimulationCase,
}), {
  addPlotTile: VisualizationActions.addPlotTile,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation,
  onClose: () => void,
}

type State = {
  [key: string]: any
  commandSelectors: Array<any>,
  fileSelectors: Array<any>,
  files: Array<any>,
  outputFileName: string,
  outputFile: string,
  commandLabel: string,
  command: string,
  error: string
};

class Commands extends Component<Props, State> {
  state: State = {
    commandSelectors: [],
    fileSelectors: [],
    files: [ { key: 'new', value: 'Add new file' } ],
    command: '',
    outputFileName: '',
    outputFile: 'new',
    commandLabel: '',
    error: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleLoadCommand()

    const handleSubmit = this.handleSubmit

    document.onkeyup = function (e) {
      if (e.which === 13) {
        return handleSubmit()
      }
    }
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    document.onkeyup = null
  }

  // @AnalyzeTime(0)
  handleLoadCommand = async () => {
    const { currentSimulationCase } = this.props
    const { files } = await ApiClient.get(`${Network.URI}/visualization_command/files/${currentSimulationCase._id}`)

    const fileSelectors = files.map((file: any) => ({ key: file._id, value: file.name })) || []

    fileSelectors.push({ key: 'new', value: 'Add new file' })

    let data:any = {}

    try {
      data = await ApiClient.get(`${Network.URI}/visualization_command/commands`)
    }
    catch (error) {
      this.setState({
        error: 'No command file found',
        files,
        fileSelectors,
      })

      return
    }

    const commandSelectors = data.commands
      .filter((command: any) => command['#OMSCommand'] !== '')
      .map((command: any) => {
        return ({ key: command['#OMSCommand'], value: command['#OMSCommand'] })
      })
      .sort(VisUtil.sortStringsASC)

    this.setState({
      files,
      fileSelectors,
      commandSelectors,
      command: commandSelectors[0].key,
    })
  }

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target
    let newValue = value

    if (name === 'outputFileName') {
      newValue = value.replace(/[^\w,.-]/g, '_')
    }

    this.setState({
      [name]: newValue,
    })
  }

  // @AnalyzeTime(0)
  handleSubmit = async (_event?: any) => {
    const {
      commandLabel,
      outputFile,
      outputFileName,
      command,
      files,
    } = this.state

    const { addPlotTile, addPlotViewId, currentDashboard, onClose: handleClose, currentSimulationCase } = this.props
    let file = files.filter((f: any) => f._id === outputFile)[0]
    let commandId = null

    if (outputFile === 'new') {
      if (outputFileName.length < 1) {
        this.setState({
          error: 'Output File not found',
        })
      }

      file = await ApiClient.post(`${Network.URI}/visualization_command/create`, {
        data: {
          name: outputFileName,
          commands: [ {
            fileName: outputFileName,
            command,
            parameter: [],
            assignedCommands: [],
          } ],
          simulationCaseId: currentSimulationCase._id,
        },
      })

      commandId = file.commands[0]._id
    }
    else {
      const data = await ApiClient.post(`${Network.URI}/visualization_command/${file._id}`, {
        data: {
          command: {
            fileName: file.name,
            command,
            parameter: [],
          },
          simulationCaseId: currentSimulationCase._id,
        },
      })

      commandId = data.commandId
      file = data.commandFile
    }

    addPlotTile(
      addPlotViewId,
      currentDashboard[addPlotViewId],
      {
        configId: commandId,
        name: commandLabel,
        type: 'command',
        shapeIds: [],
        command,
        commandId,
        fileId: file._id,
        fileName: file.name,
      },
    )
    handleClose()
  }

  @AnalyzeTime(0)
  render () {
    const { currentSimulationCase, t } = this.props
    const { commandSelectors, fileSelectors, command, outputFileName, outputFile, commandLabel, error } = this.state
    const isLocked = currentSimulationCase.simulationStartedAt

    return (
      <Form>
        {error.length > 0 && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          label={t(`${PRE_TRANS}.command.commandLabel.label`)}
          title={t(`${PRE_TRANS}.command.commandLabel.title`)}
          name='commandLabel'
          type='text'
          value={commandLabel}
          autoFocus
          onChange={this.handleInput}
        />
        <Input
          label={t(`${PRE_TRANS}.command.outputFile.label`)}
          title={t(`${PRE_TRANS}.command.outputFile.title`)}
          name='outputFile'
          type='select'
          value={outputFile}
          selectors={fileSelectors}
          onChange={this.handleInput}
          data-tile
        />
        {
          outputFile === 'new' &&
            <Input
              label={t(`${PRE_TRANS}.command.outputFileName.label`)}
              title={t(`${PRE_TRANS}.command.outputFileName.title`)}
              name='outputFileName'
              type='text'
              value={outputFileName}
              onChange={this.handleInput}
              data-tile
            />
        }
        <Input
          label={t(`${PRE_TRANS}.command.command.label`)}
          title={t(`${PRE_TRANS}.command.command.title`)}
          name='command'
          type='select'
          value={command}
          selectors={commandSelectors}
          onChange={this.handleInput}
          data-tile
        />
        <Button
          onClick={this.handleSubmit}
          title={isLocked ? t(`${PRE_TRANS}.button.titleLocked`) : t(`${PRE_TRANS}.button.title`)}
          disabled={Boolean(!command || isLocked)}
        >
          <div className={isLocked ? '' : 'cut'}>
            <i className={`pe-7s-${isLocked ? 'lock' : 'check'}`} />
          </div>
          <span>{t(`${PRE_TRANS}.button.label`)}</span>
        </Button>
      </Form>
    )
  }
}

export default withNamespaces('visualization')(connector(Commands as any) as any) as any
