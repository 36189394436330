import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as ApplicationActions from 'store/application/main/actions'

import { Form } from 'react/visualization/dashboard/Dialogs/DialogStyles'
import MatrixUtil from 'react/visualization/MatrixUtil'
import Input from 'react/specific/Input'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.simulationCaseNameEditor'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation,
  value?: string,
  data?: { id: string },
  selectedCellData?: Array<any>,
  onClose: () => void,
}

type State = {
  name: string,
  error: string,
  loading: boolean
};

export class SimulationCaseNameEditor extends Component<Props, State> {
  timeoutRef?: NodeJS.Timeout;

  state = {
    name: '',
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { value } = this.props

    if (value) {
      this.setState({ name: value })
    }
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)

      this.handleSubmit()
    }
  }

  // @AnalyzeTime(0)
  handleChange = (event: any) => {
    this.setState({ name: event.target.value })

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }

    this.timeoutRef = setTimeout(this.handleSubmit, 2000)
  };

  // @AnalyzeTime(0)
  handleError = (error: any) => {
    this.setState(({
      error: error ? error.status : 'unknown',
      loading: false,
    }))
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name } = this.state
    const { data, onClose, currentProject, setCurrentProject, selectedCellData } = this.props
    const { id } = data || {}

    if (name) {
      if (!id) {
        return this.handleError('no id given')
      }

      this.setState(({ loading: true }))

      MatrixUtil.updateSimulationCaseName(
        currentProject,
        setCurrentProject,
        name,
        id,
        selectedCellData,
        onClose,
        this.handleError,
      )
    }
  };

  @AnalyzeTime(0)
  render () {
    const { name } = this.state
    const { t } = this.props

    return (
      <Form>
        <Input
          autoFocus
          label={t(`${PRE_TRANS}.label`)}
          title={t(`${PRE_TRANS}.label`)}
          placeholder={t(`${PRE_TRANS}.name`)}
          name={name}
          type='text'
          value={name}
          onChange={this.handleChange}
        />
      </Form>
    )
  }
}

export default withNamespaces('application')(connector(SimulationCaseNameEditor as any) as any) as any
