import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import { Dialog, DialogBackground, Header, I, HeaderTitle, Content, SelectHeader } from './Styles'
import DragAndDrop from './DragAndDrop'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import Input from '../../../specific/Input'
import * as VisualizationActions from 'store/visualization/actions'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect(({ application }: DefaultState) => ({
  currentSimulationCase: application.main.currentSimulationCase,
}), {
  closeCommandView: VisualizationActions.handleCommandView,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  currentFile: string,
  files: Array<any>,
  fileSelectors: Array<any>
};

class CommandMapping extends Component<Props, State> {
  state: State = {
    currentFile: '',
    files: [],
    fileSelectors: [],
  }

  // @AnalyzeTime(0)
  componentDidMount = async () => {
    const { currentSimulationCase } = this.props
    const { files } = await ApiClient.get(`${Network.URI}/visualization_command/files/${currentSimulationCase._id}`)

    if (files.length > 0) {
      const fileSelectors = files.map((file: any) => ({ key: file._id, value: file.name }))

      this.setState({
        files,
        fileSelectors,
        currentFile: files[0]._id,
      })
    }

    hotkeys('Escape', this.handleClose)
  };

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { value } = event.target

    this.setState({
      currentFile: value,
    })
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeCommandView } = this.props

    closeCommandView()
  };

  @AnalyzeTime(0)
  render () {
    const { fileSelectors, currentFile, files } = this.state
    const { t } = this.props

    const selectedFile = files.filter(file => file._id === currentFile)[0] || {}

    return (
      <div>
        <DialogBackground />
        <Dialog>
          <div>
            <Header title={t('commandAssignment.title')}>
              <I className='pe-7s-share' left />
              <HeaderTitle>{t('commandAssignment.label')}</HeaderTitle>
              <I className='pe-7s-close' onClick={this.handleClose} title={t('commandAssignment.close')} />
            </Header>
            <Content>
              <SelectHeader>
                <span>
                  {t('commandAssignment.outputFileSelectLabel')}
                </span>
                <Input
                  name='file'
                  type='select'
                  value={currentFile}
                  selectors={fileSelectors}
                  onChange={this.handleInput}
                  style={{ 'margin-top': '10px' }}
                  noMarginTop
                />
              </SelectHeader>
              <DragAndDrop
                commands={selectedFile.commands || []}
                selectedFile={selectedFile}
              />
            </Content>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(CommandMapping as any) as any) as any
