import { Mesh, Raycaster, Vector2 } from 'three'
import { AnalyzeTime } from 'Util'

export default class Getters {
  @AnalyzeTime(0)
  static getMouse (mouseOnCanvas: Vector2, x: number, y: number, width: number, height: number) {
    const mouse = new Vector2()

    mouse.x = ((mouseOnCanvas.x - x) / width) * 2 - 1
    mouse.y = -((mouseOnCanvas.y - y) / height) * 2 + 1

    return mouse
  }

  @AnalyzeTime(0)
  static getIntersects (baseClickableObjects: Mesh[], raycaster: Raycaster) {
    const clickableObjects = baseClickableObjects.filter(object => object.visible)

    return raycaster.intersectObjects(clickableObjects)
  }
}
