import styled, { css, keyframes } from 'styled-components'

export const spin = keyframes`
  0% {
    transform:rotate(0deg);
  }
  100% { 
    transform:rotate(360deg); 
  }
`

export const blinkingDots = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% { 
    opacity: 0; 
  }
`

export const Loading = styled.div`${() => css`
  position: relative;
  margin: 0;
  padding: 0;
  user-select: none;
`}`

export const LoadingContainer = styled.div`${() => css`
  pointer-events: all;
  z-index: 1;
  height: calc(100vh - 40px);
`}`

export const LoadingCircle = styled.div`
  top: calc(50% - 118px);
  left: calc(50% - 50px);
  position: absolute;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 74px rgba(99, 99, 99, 0.6);
  border-radius: 120px;
  background-color: rgba(99, 99, 99, 0.25);
  pointer-events: none;
`

interface CircleProps {
  loadingStatus: boolean
}

export const OuterCircle = styled.img<CircleProps>`
  position: absolute;
  animation: ${({ loadingStatus }) => !loadingStatus ? 'none' : spin} 3.4s infinite linear;
`

export const InnerCircle = styled.img<CircleProps>`
  position: absolute;
  animation: ${({ loadingStatus }) => !loadingStatus ? 'none' : spin} 4.2s infinite linear;
`

export const MiddleCircle = styled.img<CircleProps>`
  position: absolute;
  animation: ${({ loadingStatus }) => !loadingStatus ? 'none' : spin} 3.8s infinite reverse linear;
`

export const Ball = styled.img`
  position: absolute;
`

export const Title = styled.span`${() => css`
  color: #c2c2c2;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  font-size: 25px;
  pointer-events: none;

  span {
    animation: ${blinkingDots} 2s infinite;
    animation-fill-mode: both;
  }
  span:nth-child(2) {
    animation-delay: .2s;
  }
  span:nth-child(3) {
    animation-delay: .4s;
  }
`}`

export const Onveda = styled.div`${() => css`
  color: #696969;
  text-align: right;
  width: 100%;
  position: absolute;
  font-size: 12px;
  bottom: 2px;
  pointer-events: none;

  img {
    height: 15px;
    position: relative;
    top: 2px;
    right: 1px;
  }
`}`
