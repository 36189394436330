import { VisualizationState } from 'types/visualization'
import { VisualizationActionsEnum as VisActionsEnum } from '../consts'

import AutoScale from '../util/AutoScale'

const autoscaleReducers = {
  [VisActionsEnum.ACTION_AUTOSCALE_GRID]: (state: VisualizationState, action: any) => {
    const gridWidth = Math.round((action.fullGridSize / action.fullWidth) * action.width) || 1
    const gridHeight = Math.floor((action.fullGridSize / action.fullHeight) * action.height) || 1
    const layouts = AutoScale.handleTiles(action.origLayout, { gridWidth, gridHeight })

    return {
      ...state,
      viewsObject: {
        ...state.viewsObject,
        [action.viewId]: {
          ...state.viewsObject[action.viewId],
          dashboards: {
            ...state.viewsObject[action.viewId].dashboards,
            [action.dashboardId]: {
              ...(state.viewsObject[action.viewId].dashboards[action.dashboardId] || {}),
              layout: [
                ...layouts,
              ],
              oldLayout: [
                ...action.origLayout,
              ],
              blockNewPlots: true,
            },
          },
        },
      },
    }
  },

  [VisActionsEnum.ACTION_RESET_AUTOSCALING]: (state: VisualizationState, action: any) => {
    return {
      ...state,
      viewsObject: {
        ...state.viewsObject,
        [action.viewId]: {
          ...state.viewsObject[action.viewId],
          dashboards: {
            ...state.viewsObject[action.viewId].dashboards,
            [action.dashboardId]: {
              ...(state.viewsObject[action.viewId].dashboards[action.dashboardId] || {}),
              layout: [
                ...action.origLayout,
              ],
              oldLayout: null,
              blockNewPlots: false,
            },
          },
        },
      },
    }
  },
}

export default autoscaleReducers
