import Plotly from 'plotly.js'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as saveSvgAsPng from 'save-svg-as-png/lib/saveSvgAsPng'
import { AnalyzeTime } from 'Util'
import { ViewLogic } from '../../ViewLogic'

export default class Logic {
  @AnalyzeTime(0)
  static _handleText (exportDataList: Array<any>, tileConfig: any, plotConfigs: any, fileName: string) {
    return new Promise<void>(resolve => {
      const element = document.getElementById(`text_${tileConfig.tileId}`)

      if (!element) {
        return resolve()
      }

      const content = Array.prototype.map.call(element.getElementsByClassName('data-export'), row => {
        const label = row.getAttribute('data-export-label') || ''
        const value = row.getAttribute('data-export-value') || ''

        return `${label}: ${value}`
      })

      exportDataList.push({
        content,
        fileName,
      })

      resolve()
    })
  }

  @AnalyzeTime(0)
  static _handleGage (exportDataList: Array<any>, tileConfig: any, fileName: string) {
    return new Promise<void>(resolve => {
      const svg = document.querySelector(`#gage_${tileConfig.tileId} svg`)

      if (!svg) {
        return resolve()
      }

      saveSvgAsPng.svgAsPngUri(svg, {}, (dataURL: string) => {
        exportDataList.push({
          dataURL,
          fileName,
          width: Number(svg.getAttribute('data-width')),
          height: Number(svg.getAttribute('data-height')),
        })

        resolve()
      })
    })
  }

  @AnalyzeTime(0)
  static async _handlePlotly (exportDataList: Array<any>, tileConfig: any, fileName: string) {
    const element = document.getElementById(`plot_${tileConfig.tileId}`)

    if (element) {
      const { offsetWidth, offsetHeight } = element

      const tempDiv = document.createElement('div')
      const newPlot = await ViewLogic.clonePlotAndChangeFontColorToBlack(element, tempDiv)

      const dataURL = await Plotly
        .toImage(newPlot, { format: 'png', width: offsetWidth, height: offsetHeight })

      tempDiv.remove()
      exportDataList.push({
        dataURL,
        fileName,
        width: offsetWidth,
        height: offsetHeight,
      })
    }
  }

  @AnalyzeTime(0)
  static handleExport (exportType: string, tileConfigs: any, plotConfigs: any) {
    const promises: Array<Promise<void>> = []
    const exportDataList: Array<any> = []

    Object.values(tileConfigs).forEach((rawTileConfig: any) => {
      const tileConfig = rawTileConfig || {}
      const plotConfig = plotConfigs[tileConfig.configId] || {}
      const fileName = (tileConfig.name || plotConfig.name).replace(/[^a-z0-9]/gi, '_').toLowerCase()
      const type = tileConfig.type || plotConfig.type

      let promise

      switch (type) {
        case 'text': promise = Logic._handleText(exportDataList, tileConfig, plotConfigs, fileName)
          break
        case 'gage': promise = Logic._handleGage(exportDataList, tileConfig, fileName)
          break
        default: promise = Logic._handlePlotly(exportDataList, tileConfig, fileName)
      }

      promises.push(promise)
    })

    return Promise.all(promises).then(() => exportDataList)
  }
}
