export enum SegmentActionsEnum {
  ACTION_ADD_SEGMENT = 'Segment/ACTION_ADD_SEGMENT',
  DELETE = 'Segment/DELETE',
  DELETE_MULTIPLE = 'Segment/DELETE_MULTIPLE',
  REMOVE_CHILD_ID = 'Segment/REMOVE_CHILD_ID',
  UPDATE = 'Segment/UPDATE',
  RENAME = 'Segment/RENAME',
}

export function addSegment (segmentHash: Record<number, Segment & Parent>) {
  return {
    type: SegmentActionsEnum.ACTION_ADD_SEGMENT,
    segmentHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentReducers: Record<string, Function | undefined> = {
  [SegmentActionsEnum.ACTION_ADD_SEGMENT]:
  (_state: SegmentHash, action: { segmentHash: SegmentHash}) => (
    action.segmentHash
  ),

  [SegmentActionsEnum.DELETE]: (state: SegmentHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentActionsEnum.DELETE_MULTIPLE]: (state: SegmentHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentActionsEnum.REMOVE_CHILD_ID]:
  (state: SegmentHash, action: {id: number, child: {type: string, id: number}}) => {
    const newState = { ...state }
    const element: any = newState[action.id]

    element[`#${action.child.type}Ids`] = element[`#${action.child.type}Ids`]
      .filter((childId: number) => childId !== action.child.id)

    return newState
  },

  [SegmentActionsEnum.UPDATE]: (state: SegmentHash, action: {elements: SegmentHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
  [SegmentActionsEnum.RENAME]: (state: SegmentHash, action: {name: string, id: number}) => {
    return {
      ...state,
      [action.id]: {
        ...state[action.id],
        _name: action.name,
      },
    }
  },
}

export default function (
  state: SegmentHash = {},
  action: any,
) {
  const handler = SegmentReducers[action.type]

  return handler ? handler(state, action) : state
}
