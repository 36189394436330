import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from '../../../../store/visualization/actions'

import { Dialog, DialogBackground, Header, Title, Form, Button, Text } from './DialogStyles'
import Input from '../../../specific/Input'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((store: DefaultState) => ({
  plotConfigs: store.visualization.plotConfigs,
  viewId: store.visualization.viewId,
  currentDashboard: store.visualization.currentDashboard,
}), {
  addPlotTile: VisualizationActions.addPlotTile,
  showDerivePlotDialog: VisualizationActions.showDerivePlotDialog,
  savePlotConfig: VisualizationActions.savePlotConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  name: string
};

class AddDerivedPlotDialog extends Component<Props, State> {
  state: State = {
    name: 'New Plot',
  }

  // @AnalyzeTime(0)
  handleClose = () => {
    const { name } = this.state
    const { showDerivePlotDialog, savePlotConfig, plotConfigs } = this.props
    const plotConfigIds = Object.keys(plotConfigs)
    const configId = plotConfigIds[plotConfigIds.length - 1]

    savePlotConfig({
      id: configId,
      name: name,
    })
    showDerivePlotDialog(false)
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name } = this.state
    const { addPlotTile, showDerivePlotDialog, plotConfigs, viewId, currentDashboard, savePlotConfig } = this.props
    const plotConfigIds = Object.keys(plotConfigs)
    const configId = plotConfigIds[plotConfigIds.length - 1]

    addPlotTile(viewId, currentDashboard[viewId], { configId, name, type: 'text' })
    savePlotConfig({
      id: configId,
      name: name,
    })
    showDerivePlotDialog(false)
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  };

  @AnalyzeTime(0)
  render () {
    const { t } = this.props
    const { name } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog height='auto' half>
          <Header title={t('addDerivedPlotDialog.title')}>
            <Title>{t('addDerivedPlotDialog.label')}</Title>
          </Header>
          <Form>
            <Text>
              {t('addDerivedPlotDialog.message')}
              <br />
              <br />
              {t('addDerivedPlotDialog.info')}
            </Text>
            <br />
            <Input
              label={t('addDerivedPlotDialog.tileName.label')}
              title={t('addDerivedPlotDialog.tileName.title')}
              name='name'
              type='text'
              value={name}
              onChange={this.handleInput}
            />
            <Button onClick={this.handleClose}>
              <span>{t('addDerivedPlotDialog.dismiss')}</span>
            </Button>
            <Button onClick={this.handleSubmit} ref={this.setFocus}>
              <div className='cut'><i className='pe-7s-plus' /></div>
              <span>{t('addDerivedPlotDialog.button')}</span>
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(AddDerivedPlotDialog))
