import React, { Component } from 'react'
import Button from 'react/components/Button'
import FeatureFlags from 'react/FeatureFlags'
import InputComponent, { InputWrapper, Label } from 'react/specific/Input'
import { ArrayOfTranslations, Translation } from 'types/translation'

interface Props {
  parameter: Parameter
  caseValues: Record<string, any>
  dynamicOptions: Record<string, Option[]>
  featureFlags: Record<string, boolean>
  handleInputChange?: (event: Event & { target: HTMLInputElement }) => void
  loading: boolean
  t: Translation & ArrayOfTranslations
  handleUploadFile?: (id: string) => void
  disabled?: boolean
}

const PRE_TRANS = 'executableDialog'

class ParameterComponent extends Component<Props> {
  getUploadButtonContent = (caseValues: Record<string, any>, param: Parameter) => {
    const { t } = this.props
    let fullContent = `${t(`${PRE_TRANS}.button.upload`)}`

    if (caseValues[param.id]) {
      fullContent += ` (${caseValues[param.id]})`
    }

    if (fullContent.length > 45) {
      fullContent = `${fullContent.substring(0, 42)}...`
    }

    return fullContent
  }

  getParamSelectors = (parameter: Parameter) => {
    const { dynamicOptions } = this.props

    let options: {key: string | number, value: any, disabled?: boolean }[] = []

    if (parameter.optionsFile) {
      options = ((dynamicOptions || {})[parameter.id] || []).map(({ name, name2, value }) => {
        const fullName = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName }
      })
    }
    else if (parameter.options) {
      options = parameter.options.map(({ name, name2, value }) => {
        const fullName: string = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName }
      })
    }

    options.unshift({ key: 'default', value: 'Please select an option', disabled: true })

    return options
  }

  getInputType = (parameter: Parameter) => {
    if (parameter.options || parameter.optionsFile) {
      return 'select'
    }

    switch (parameter.type) {
      case 'string':
        return 'text'
      case 'number':
        return 'number'
      case 'bool':
      case 'boolean':
        return 'checkbox'
      // file upload is handled by the upload button
      default:
        return 'text'
    }
  }

  getInputValue = (selectors: {key: string | number, value: string, disabled?: boolean}[]) => {
    const { parameter, caseValues } = this.props

    const value = caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value

    const valueIsInSelectors = selectors.map(selector => selector.key).includes(value)

    if (this.getInputType(parameter) === 'select' && (value === undefined || !valueIsInSelectors)) {
      return 'default'
    }

    return value
  }

  render () {
    const {
      parameter,
      caseValues,
      loading,
      featureFlags,
      disabled,
      handleInputChange,
      handleUploadFile,
      t,
    } = this.props

    const selectors = this.getParamSelectors(parameter)

    return (
      <div>

        {
          parameter.type !== 'file'
            ? (
              <InputComponent
                key={parameter.id}
                name={parameter.id}
                type={this.getInputType(parameter)}
                min={parameter.numberMin}
                max={parameter.numberMax}
                step={parameter.numberStep}
                label={parameter.name}
                title={parameter.name}
                value={this.getInputValue(selectors)}
                precision={parameter.precision !== undefined ? parameter.precision : 2}
                selectors={selectors}
                onChange={handleInputChange}
                disabled={disabled || loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
              />
            )
            : (
              <InputWrapper key={parameter.id}>
                <Label>{parameter.name}</Label>
                <Button
                  title={caseValues[parameter.id] ?? t(`${PRE_TRANS}.button.upload`)}
                  type='primary'
                  onClick={() => handleUploadFile ? handleUploadFile(parameter.id) : null}
                  noMargin
                  disabled={
                    disabled ||
                    loading ||
                    !FeatureFlags.canEditExecutableDialog(featureFlags) ||
                    !FeatureFlags.canUploadExecutableFile(featureFlags)
                  }
                >
                  {this.getUploadButtonContent(caseValues, parameter)}
                </Button>
              </InputWrapper>
            )
        }
      </div>
    )
  }
}

export default ParameterComponent
