/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { ThemeProvider } from 'styled-components'
import ReactDataSheet from 'react-datasheet'
import { LinearProgress, Button, ButtonGroup } from '@material-ui/core'
import { withSnackbar } from 'notistack'

import Icon from '../../specific/Icon'

import * as ApplicationActions from 'store/application/main/actions'
import * as MatrixActions from 'store/matrix/actions'

import StyleConfig from '../../visualization/dashboard/config/StyleConfig'

import Logic from './Logic'

import { Wrapper, TopBar, Title, SelectionInfo, ButtonBar, Content, I } from './Styles'
import Duplicate from './massActions/Duplicate'
import DeleteSelected from './massActions/DeleteSelected'
import SimulateSelected from './massActions/SimulateSelected'
import SimulateAll from './massActions/SimulateAll'
import StopSelected from './massActions/StopSelected'
import ResetSelected from './massActions/ResetSelected'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  darkTheme: state.application.main.darkTheme,
  loadingStatus: state.application.main.loadingStatus,
  selections: state.matrix.selections,
  grid: state.matrix.grid,
  columns: state.matrix.columns,
  resultData: state.matrix.resultData,
  lastScrollTopPosition: state.matrix.lastScrollTopPosition,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setColumns: MatrixActions.setColumns,
  setSelections: MatrixActions.setSelections,
  setGrid: MatrixActions.setGrid,
  updateResultData: MatrixActions.updateResultData,
  setLastScrollPosition: MatrixActions.setLastScrollPosition,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar,
  t: Translation,
}

export type State = {
  selectedCells: any,
  editorProps?: any,
  selectedCellData?: Array<any>,
  commands: Array<any>,
  updatedCells: any,
  simulateLoading: boolean,
  simulateError: string,
  stopSimulationLoading: boolean,
  stopSimulationError: string,
  resetSimulationLoading: boolean,
  resetSimulationError: string,
  openEditor: boolean
};

export class ProjectMatrixDialog extends Component<Props, State> {
  static NAME = uuid()
  static scrollCellHeight = 30;
  setLoadingStatus = () => null // TODO: remove, this is not being used

  contentRef: HTMLElement | undefined | null;
  scrollTimeout?: NodeJS.Timeout;

  constructor (props: Props) {
    super(props)

    Logic.init(this)
  }

  state: State = {
    selectedCells: {},
    editorProps: null,
    selectedCellData: [],
    commands: [],
    updatedCells: {},
    simulateLoading: false,
    simulateError: '',
    stopSimulationLoading: false,
    stopSimulationError: '',
    resetSimulationLoading: false,
    resetSimulationError: '',
    openEditor: false,
  }

  @AnalyzeTime(0)
  async componentDidMount () {
    await Logic.prepare()
    Logic.buildColumns()
    Logic.buildGrid()

    document.addEventListener('copy', Logic.handleCopy)
    document.addEventListener('paste', Logic.handlePaste)

    setTimeout(() => {
      ((this.contentRef || {}) as any).scrollTop = this.props.lastScrollTopPosition
    }, 100)
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props, prevState: State) {
    Logic.buildColumns(prevProps)
    Logic.buildGrid(prevProps, prevState)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    if (this.contentRef) {
      this.contentRef.removeEventListener('wheel', this.handleWheel)
      this.contentRef.removeEventListener('scroll', this.handleScroll)
      window.removeEventListener('keyup', this.handleEditor)
      window.removeEventListener('keydown', this.handleEditor)
      document.removeEventListener('copy', Logic.handleCopy)
      document.removeEventListener('paste', Logic.handlePaste)
    }
  }

  // @AnalyzeTime(0)
  handleWheel = (event: any) => {
    console.log('wheel')
    let element = event.target
    let count = 0

    while (element !== this.contentRef && count < 100) {
      count++

      if (Array.from(element.classList || []).includes('allowScroll')) {
        return
      }

      element = element.parentNode
    }

    event.preventDefault()

    if (!this.contentRef) {
      return
    }

    if (event.shiftKey) {
      this.contentRef.scrollLeft += event.deltaY

      return
    }

    if (event.deltaY > 0) {
      this.contentRef.scrollTop += ProjectMatrixDialog.scrollCellHeight
    }
    else if (event.deltaY < 0) {
      this.contentRef.scrollTop -= ProjectMatrixDialog.scrollCellHeight
    }
  };

  // @AnalyzeTime(0)
  handleScroll = (event: any) => {
    console.log('scrolling')

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout)
    }

    if (this.contentRef) {
      this.contentRef.scrollTop =
        (this.contentRef.scrollTop / ProjectMatrixDialog.scrollCellHeight) *
        ProjectMatrixDialog.scrollCellHeight
    }

    this.scrollTimeout = setTimeout(() => {
      this.props.setLastScrollPosition(event.srcElement.scrollTop)
    }, 1000)
  };

  // @AnalyzeTime(0)
  handleDataEditor = (editorProps: any) => {
    const { selectedCells, selectedCellData } = this.state

    return Logic.handleDataEditor(editorProps, selectedCells, selectedCellData)
  };

  // @AnalyzeTime(0)
  handleEditor = (event: any) => {
    this.setState({
      openEditor: event.ctrlKey,
    })
  };

  // @AnalyzeTime(0)
  bindContent = (ref: any) => {
    if (this.contentRef || !ref) {
      return
    }

    this.contentRef = ref

    if (this.contentRef) {
      this.contentRef.addEventListener('wheel', this.handleWheel)
      this.contentRef.addEventListener('scroll', this.handleScroll)
      window.addEventListener('keyup', this.handleEditor)
      window.addEventListener('keydown', this.handleEditor)
    }
  };

  @AnalyzeTime(0)
  render () {
    const {
      simulateLoading,
      stopSimulationLoading,
      resetSimulationLoading,
      editorProps,
      selectedCells,
      selectedCellData,
    } = this.state
    const { currentProject, grid, selections, loadingStatus, darkTheme, t } = this.props
    const selectionsKeys = Object.keys(selections)
    const selectedCount = selectionsKeys.filter(id => selections[id]).length
    const allSelected = selectedCount === selectionsKeys.length
    const isSelected = selectedCount > 0

    const isSelectedAndNotSimulatedAndVerified = currentProject.simulationCases.filter(simulationCase =>
      selections[simulationCase._id] &&
      !simulationCase.simulationStartedAt &&
      Logic.checkIfVerified(simulationCase)).length > 0
    const isSelectedAndRunning = currentProject.simulationCases.filter(simulationCase =>
      selections[simulationCase._id] &&
      simulationCase.simulationStartedAt &&
      !simulationCase.simulationDataReceivedAt).length > 0
    const isSelectedAndNotRunning = currentProject.simulationCases.filter(simulationCase =>
      selections[simulationCase._id] &&
        !(simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt)).length > 0
    const isSelectedAndSimulated = currentProject.simulationCases.filter(simulationCase =>
      selections[simulationCase._id] && simulationCase.simulationDataReceivedAt).length > 0
    const simulationRunning = currentProject.simulationCases.filter(simulationCase =>
      simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt).length > 0
    const isNotSimulatedAndVerified = currentProject.simulationCases.filter(simulationCase =>
      !simulationCase.simulationStartedAt && Logic.checkIfVerified(simulationCase)).length > 0

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <Wrapper>
          <TopBar>
            <Title>
              {t(`${Logic.PRE_TRANS}.title`, { name: currentProject.name })}
            </Title>
            <ButtonBar>
              <SelectionInfo>
                {t(`${Logic.PRE_TRANS}.selectionInfo`, { selected: selectedCount, total: grid.length })}
                {
                  (
                    simulationRunning ||
                    simulateLoading ||
                    stopSimulationLoading ||
                    resetSimulationLoading ||
                    loadingStatus
                  ) &&
                  <LinearProgress className='loading' color='primary' variant='query' />
                }
              </SelectionInfo>
              <ButtonGroup color='primary' size='large' variant='contained' aria-label='small contained button group'>
                <Button onClick={Logic.handleConfigure} title={t(`${Logic.PRE_TRANS}.button.configure`)}>
                  <Icon icon='cogs' />
                </Button>
                <Duplicate
                  handleLoadingStatus={this.setLoadingStatus} // TODO: this doesnt exist, remove?
                  isSelected={isSelected}
                  contentRef={this.contentRef}
                />
                <DeleteSelected
                  isSelected={isSelected}
                  allSelected={allSelected}
                  isSelectedAndNotRunning={isSelectedAndNotRunning}
                />
                <SimulateSelected
                  isSelected={isSelected}
                  simulateLoading={simulateLoading}
                  simulationRunning={simulationRunning}
                  isSelectedAndNotSimulated={isSelectedAndNotSimulatedAndVerified}
                />
                <SimulateAll
                  simulateLoading={simulateLoading}
                  simulationRunning={simulationRunning}
                  isNotSimulated={isNotSimulatedAndVerified}
                />
                <StopSelected
                  isSelected={isSelected}
                  stopSimulationLoading={stopSimulationLoading}
                  isSelectedAndRunning={isSelectedAndRunning}
                />
                <ResetSelected
                  isSelected={isSelected}
                  resetSimulationLoading={resetSimulationLoading}
                  isSelectedAndSimulated={isSelectedAndSimulated}
                />
              </ButtonGroup>
            </ButtonBar>
            <I className='pe-7s-close' onClick={Logic.handleClose} title={t(`${Logic.PRE_TRANS}.button.close`)} />
          </TopBar>
          <Content ref={this.bindContent}>
            <ReactDataSheet
              data={grid}
              sheetRenderer={Logic.sheetRenderer}
              rowRenderer={Logic.rowRenderer}
              valueRenderer={Logic.valueRenderer as any} // TODO: fix type
              // eslint-disable-next-line react/jsx-handler-names
              dataEditor={this.handleDataEditor}
              onSelect={Logic.handleSelect}
              selected={selectedCells as any} // TODO: fix type - is selected cells working pro
            />
            {editorProps && Logic.renderDataEditor(editorProps, selectedCellData)}
          </Content>
        </Wrapper>
      </ThemeProvider>
    )
  }
}

export default withSnackbar(withNamespaces('application')(connector(ProjectMatrixDialog as any) as any) as any) as any
