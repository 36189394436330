import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import Button from '@material-ui/core/Button'

import Icon from 'react/specific/Icon'
import * as ApplicationActions from 'store/application/main/actions'
import SimulateDialog from '../SimulateDialog'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog'

const connector = connect(null, {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  simulateLoading: boolean,
  simulationRunning: boolean,
  isNotSimulated: boolean,
  setSelections: any, // TODO: remove
  t: Translation
}

class SimulateAll extends Component<Props> {
  // @AnalyzeTime(0)
  handleClick = () => {
    const { openDialog } = this.props

    openDialog(SimulateDialog.ALLNAME)
  };

  // @AnalyzeTime(0)
  getTitle = () => {
    const { simulateLoading, simulationRunning, isNotSimulated, t } = this.props

    if (simulateLoading) {
      return t(`${PRE_TRANS}.titles.simulateAll.loading`)
    }

    if (simulationRunning) {
      return t(`${PRE_TRANS}.titles.simulateAll.running`)
    }

    if (!isNotSimulated) {
      return t(`${PRE_TRANS}.titles.simulateAll.none`)
    }

    return t(`${PRE_TRANS}.titles.simulateAll.default`)
  };

  @AnalyzeTime(0)
  render () {
    // restProps should not contain currentProject
    const { simulateLoading, simulationRunning, isNotSimulated, t, ...restProps } = this.props
    const disabled = simulateLoading || simulationRunning || !isNotSimulated

    return (
      <Button {...restProps} title={this.getTitle()} disabled={disabled} onClick={this.handleClick}>
        <Icon icon='bars' />
      </Button>
    )
  }
}

export default withNamespaces('application')(connector(SimulateAll as any) as any) as any
