import React from 'react'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnalyzeTime } from 'Util'

const helper = {
  far,
  fas,
}

type Props = {
  icon: string,
  type?: 'far' | 'fas',
  className?: string
  title?: string
  onClick?: (e: any) => void
};

export default class Icon extends React.Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { icon, type, ...restProps } = this.props
    const name = `fa${icon.replace(/\b\w/g, l => l.toUpperCase()).replace(/-/g, '')}`
    const iconName = (type ? helper[type][name] : (far[name] || fas[name])) as any

    return <FontAwesomeIcon {...restProps} icon={iconName} />
  }
}
