import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import DE from './de/index'
import EN from './en/index'

const resources = {
  de: DE.translation,
  en: EN.translation,
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
