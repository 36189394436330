import React, { Component } from 'react'
import * as far from '@fortawesome/free-regular-svg-icons'
import * as fas from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnalyzeTime } from 'Util'

const helper = {
  far,
  fas,
}

type Props = {
  style?: any
  icon: string,
  type?: 'far' | 'fas',
  className?: string
  spin?:boolean
  fixedWidth?: boolean
  title?: string
  onClick?: (e: any) => void
  viewBox?: string
};

export default class Icon extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { icon, type, ...restProps } = this.props

    if (!icon) {
      // eslint-disable-next-line no-console
      console.error('<Icon> attribute "icon" must be an icon name')

      return null
    }

    const name = `fa${icon.replace(/\b\w/g, l => l.toUpperCase()).replace(/-/g, '')}`

    return (
      <FontAwesomeIcon
        {...restProps}
        icon={
          type
            ? ((helper as any)[type] || {} as any)[name]
            : ((far as any)[name] || (fas as any)[name])
        }
      />
    )
  }
}
