import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { Button, ButtonGroup } from '@material-ui/core'

import Util from 'logicHandlers/ServerLogic/actions/Util'
import * as ApplicationActions from 'store/application/main/actions'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import Icon from 'react/specific/Icon'

import { ButtonBar } from './Styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectMatrixDialog.configureDialog.actions'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
}), {
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onUpload: any
  enqueueSnackbar: enqueueSnackbar
  t (key: string, params?: Record<string, unknown>): string
}

class Actions extends Component<Props> {
  // @AnalyzeTime(0)
  handleDownloadButtonClick = async () => {
    const { currentProject: { _id }, enqueueSnackbar, t } = this.props

    const { config } = await ApiClient.get(`${Network.URI}/matrix/config/${_id}`)

    if (window.isElectron) {
      enqueueSnackbar(('Download initiated...'), { variant: 'info', autoHideDuration: 3000 })
    }

    Util
      .openDownloadFileDialog(JSON.stringify(config), 'matrix_config.json')
      .then(() => {
        if (!window.isElectron) {
          enqueueSnackbar(t(`${PRE_TRANS}.download_successful`), { variant: 'success' })
        }
      })
      .catch(() => {
        enqueueSnackbar(t(`${PRE_TRANS}.download_failed`), { variant: 'error' })
      })
  };

  // @AnalyzeTime(0)
  handleUploadButtonClick = () => {
    const { currentProject: { _id }, onUpload, setCurrentProject, enqueueSnackbar, t } = this.props

    Util
      .openUploadFileDialog('.json', `/matrix/config/${_id}`)
      .then(({ project, missingCommands, missingResultKeys }:
        {project: Project, missingCommands: string[], missingResultKeys: string[]}) => {
        setCurrentProject(project)

        onUpload(project.matrixColumnOrder)

        const hasMissingCommands = missingCommands && missingCommands.length
        const hasMissingResultKeys = missingResultKeys && missingResultKeys.length

        if (hasMissingCommands || hasMissingResultKeys) {
          if (hasMissingCommands) {
            enqueueSnackbar(
              t(
                `${PRE_TRANS}.upload_successful_missing_commands`,
                { missingCommands: missingCommands.map((entry: string) => `-> ${entry}`).join('\n') },
              ),
              { variant: 'warning', persist: true },
            )
          }

          if (hasMissingResultKeys) {
            enqueueSnackbar(
              t(
                `${PRE_TRANS}.upload_successful_missing_result_keys`,
                { missingResultKeys: missingResultKeys.map(entry => `-> ${entry}`).join('\n') },
              ),
              { variant: 'warning', persist: true },
            )
          }

          enqueueSnackbar(t(`${PRE_TRANS}.upload_successful_missing_info`), { variant: 'info', persist: true })

          return
        }

        enqueueSnackbar(t(`${PRE_TRANS}.upload_successful`), { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar(t(`${PRE_TRANS}.upload_failed`), { variant: 'error' })
      })
  };

  @AnalyzeTime(0)
  render () {
    const { t } = this.props

    return (
      <ButtonBar>
        <ButtonGroup color='primary' size='small' variant='contained' aria-label='small contained button group'>
          <Button onClick={this.handleDownloadButtonClick} title={t(`${PRE_TRANS}.download`)}>
            <Icon icon='download' />
          </Button>
          <Button onClick={this.handleUploadButtonClick} title={t(`${PRE_TRANS}.upload`)}>
            <Icon icon='upload' />
          </Button>
        </ButtonGroup>
      </ButtonBar>
    )
  }
}

export default withNamespaces('application')(withSnackbar((Actions as any) as any) as any) as any
