/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { AppState } from 'store/application/main/consts'
import * as ApplicationActions from 'store/application/main/actions'
import * as ErrorActions from 'store/application/error/actions'
import * as VisualizationActions from 'store/visualization/actions'

import IpcManager from '../../../../IpcManager'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import FeatureFlags from '../../../FeatureFlags'

// TODO: move this file or its contents
import { Form } from '../../../visualization/dashboard/Dialogs/DialogStyles'
import Button from '../../../components/Button'
import Input from '../../../specific/Input'

import EditConfigDialog from '../EditConfigDialog'

import { Title, Hr } from './Styles'
import Logic from './Logic'
import { Spacer } from '../OpenProjectDialog/Styles'
import InfoMarker from '../../../components/InfoMarker'
import { DefaultState } from 'types/state'
import { ArrayOfTranslations, Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

const PRE_TRANS = 'projectDataDialog.visualization'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  darkTheme: state.application.main.darkTheme,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  openDialog: ApplicationActions.openDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
  setAppState: ApplicationActions.setAppState,
  setConfig: VisualizationActions.setConfig,
  resetViewObject: VisualizationActions.resetViewObject,
  setError: ErrorActions.setError,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onClose:(isClick?: any) => void
  onChange:(event: any) => void | {type: string, name: string, value: string}
  onDeleteConfig: (type: string, key: string)=> null | undefined
  onDeleteData:(type: string, key: string) => null | undefined
  onDeleteSchema:(type: string, key: string) => null | undefined
  t: Translation & ArrayOfTranslations
}

type State = {
  loading: any,
  selectedConfig: string | undefined | null,
  selectedData: string | undefined | null
};

export class VisualizationColumn extends Component<Props, State> {
  state = {
    loading: {
      openVis: false,
    },
    selectedConfig: null,
    selectedData: null,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { visualizationMetaInformation } = this.props
    const meta = visualizationMetaInformation[AppState.ResultDashboard] || {}

    this.setState({
      selectedConfig: meta.config,
      selectedData: meta.data,
    })
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props) {
    const {
      visualizationConfigList: oldVisualizationConfigList,
      visualizationDataList: oldVisualizationDataList,
    } = prevProps.currentSimulationCase

    const { visualizationConfigList, visualizationDataList } = this.props.currentSimulationCase

    const oldVisualizationParamConfigList = (oldVisualizationConfigList || [])
      .filter(config => config.appState === AppState.ResultDashboard)

    const visualizationParamConfigList = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.ResultDashboard)

    let newState: any = null

    if (oldVisualizationParamConfigList.length !== visualizationParamConfigList.length) {
      newState = {
        selectedConfig: (visualizationParamConfigList.slice(-1)[0] || {}).configId,
      }
    }

    if ((oldVisualizationDataList || []).length !== (visualizationDataList || []).length) {
      newState = newState || {}

      newState.selectedData = ((visualizationDataList || []).slice(-1)[0] || {}).configId
    }

    if (newState) {
      this.setState(newState)
    }
  }

  // @AnalyzeTime(0)
  handleOpenVisualization = async () => {
    const {
      setConfig,
      setAppState,
      currentSimulationCase,
      setCurrentSimulationCase,
      setError,
      onChange,
      onClose,
      resetViewObject,
    } = this.props

    let config = this.getSelectedConfig()

    this.setState({
      loading: {
        openVis: true,
      },
    })

    if (!config || config === 'default') {
      const formData = new FormData()
      const fileName = `config_${Date.now()}.json`

      const visData = {
        viewsObject: { [`view_${uuid()}`]: { dashboards: { [`dashboard_${uuid()}`]: { name: 'New Dashboard' } } } },
      }

      const file = new File([ JSON.stringify(visData) ], fileName, { type: 'text/plain', lastModified: Date.now() })

      formData.append('file', file)
      formData.append('simulationCaseId', currentSimulationCase._id)
      formData.append('appState', AppState.ResultDashboard.toString())

      const response = await ApiClient
        .post(`${Network.URI}/visualization_config`, { data: formData })
        .catch(({ status }) => {
          setError('openDashboard', status)
        })

      if (!response) {
        this.setState({ loading: { openVis: false } })

        return
      }

      setCurrentSimulationCase(response.simulationCase)

      config = (response.simulationCase.visualizationConfigList.slice(-1)[0] || {}).configId
    }

    resetViewObject()
    onChange({ target: { name: 'config', value: config } })

    setAppState(AppState.ResultDashboard)

    const { visualizationConfig } = await ApiClient
      .get(`${Network.URI}/visualization_config/${config}?rid=8`)
      .catch((error) => {
        setError('openDashboard', error.status)

        return {}
      })

    if (!visualizationConfig) {
      this.setState({ loading: { openVis: false } })

      return
    }

    setConfig(visualizationConfig.data)

    if (currentSimulationCase.visualizationDataList && currentSimulationCase.visualizationDataList.length) {
      const data = this.getSelectedData()

      const { visualizationData } = await ApiClient
        .get(`${Network.URI}/visualization_data/${data}`)
        .catch((error) => {
          setError('openDashboard', error.status)

          return {}
        })

      if (!visualizationData) {
        this.setState({
          loading: {
            openVis: false,
          },
        })

        return
      }

      IpcManager.internal.send('VisualizationData', null, visualizationData)

      onChange({ target: { name: 'data', value: data } })
    }

    onClose()
  };

  // @AnalyzeTime(0)
  handleDownloadConfig = async (key: string) => {
    const { darkTheme, currentSimulationCase: { visualizationConfigList } } = this.props

    const { visualizationConfig } = await ApiClient.get(`${Network.URI}/visualization_config/${key}?rid=9`)

    IpcManager.both.send(
      'saveVisualizationConfig',
      JSON.stringify({ ...visualizationConfig.data, darkTheme }),
      (visualizationConfigList.filter(config => config.configId === key)[0] || {}).name,
    )
  };

  // @AnalyzeTime(0)
  handleEditConfig = (key: string) => {
    const { openDialog, setEditConfigId } = this.props

    setEditConfigId(key)
    openDialog(EditConfigDialog.NAME)
  };

  // @AnalyzeTime(0)
  handleChangeConfig = (event: any) => {
    const { onChange } = this.props
    const { value } = event.target

    if (value === 'add') {
      return onChange({ target: { name: 'config', value: 'add' } })
    }

    this.setState({
      selectedConfig: value,
    })
  };

  // @AnalyzeTime(0)
  handleChangeData = (event: any) => {
    this.setState({
      selectedData: event.target.value,
    })
  };

  // @AnalyzeTime(0)
  getSelectedData = () => {
    const { selectedData } = this.state
    const { currentSimulationCase: { visualizationDataList } } = this.props
    const dataList = visualizationDataList || []

    if (selectedData && !dataList.length) {
      return 'default'
    }

    if (selectedData && dataList.findIndex(data => data.dataId === selectedData) === -1) {
      return (dataList[0] || {}).dataId || 'default'
    }

    return selectedData || (dataList[0] || {}).dataId || 'default'
  };

  // @AnalyzeTime(0)
  getSelectedConfig = () => {
    const { selectedConfig } = this.state
    const { currentSimulationCase: { visualizationConfigList } } = this.props
    const configs = (visualizationConfigList || [])
      .filter(config => config.appState === AppState.ResultDashboard)

    return Logic.getSelectedConfig(selectedConfig, configs)
  };

  @AnalyzeTime(0)
  render () {
    const { loading } = this.state
    const {
      t,
      visualizationMetaInformation,
      currentSimulationCase,
      featureFlags,
      error,
      onDeleteConfig,
    } = this.props

    const { data } = visualizationMetaInformation[AppState.ResultDashboard] || {}

    const visualizationEditor = FeatureFlags.canEditVisualization(featureFlags)
    const { visualizationDataList, visualizationConfigList } = currentSimulationCase
    const hasVisualizationFile = visualizationDataList && visualizationDataList.length && visualizationDataList[0]

    const dataSelectors = Logic.getSelectors(
      (visualizationDataList || []).sort((a, b) => a.birthtime - b.birthtime),
      'dataId',
      'name',
      { value: t(`${PRE_TRANS}.data.default`), disabled: true },
    )

    const configSelectors = Logic.getSelectors(
      (visualizationConfigList || [])
        .filter(config => config.appState === AppState.ResultDashboard),
      'configId',
      'name',
      FeatureFlags.canCreateResultDashboardConfig(featureFlags) ? {
        value: t(`${PRE_TRANS}.config.${visualizationEditor ? 'default' : 'viewerDefault'}`),
        disabled: false,
      } : null,
    )

    if (FeatureFlags.canUploadResultDashboardConfig(featureFlags)) {
      configSelectors.push({
        key: 'add',
        notRemovable: true,
        value: t(`${PRE_TRANS}.config.add`),
        disabled: !visualizationEditor,
      })
    }

    const loadDisabled = !visualizationDataList || !visualizationDataList.length

    return (
      <Form maxWidth='300px' padding='18px 28px 20px'>
        <Title>
          {t(`${PRE_TRANS}.title`)}
          <InfoMarker
            message={t(`${PRE_TRANS}.info`, { returnObjects: true }).join('\n')}
            x={224}
            y={-23}
            placement='top-end'
          />
        </Title>
        <Input
          name='data'
          type='select'
          label={t(`${PRE_TRANS}.data.label`)}
          title={
            !hasVisualizationFile
              ? t(`${PRE_TRANS}.noFile`)
              : t(`${PRE_TRANS}.data.title`)
          }
          value={this.getSelectedData()}
          selectors={dataSelectors}
          onChange={this.handleChangeData}
          noDeleteGiven={[ data ]}
        />
        <Spacer h={141} br />
        <Spacer h={17} br />
        <Hr />
        <Input
          name='config'
          type='select'
          title={
            error.config
              ? t([ `error.${error.config}`, 'error.default' ])
              : t(`${PRE_TRANS}.config.title`)
          }
          error={error.config && t([ `error.${error.config}`, 'error.default' ])}
          value={this.getSelectedConfig()}
          selectors={configSelectors}
          onChange={this.handleChangeConfig}
          onDelete={onDeleteConfig}
          onDownload={this.handleDownloadConfig}
          onEdit={this.handleEditConfig}
        />
        <Button
          onClick={this.handleOpenVisualization}
          loading={loading.openVis}
          error={error.openDashboard ? t([ `error.${error.openDashboard}`, 'error.default' ]) : ''}
          title={
            error.openDashboard
              ? t([ `error.${error.openDashboard}`, 'error.default' ])
              : (loadDisabled
                ? t(`${PRE_TRANS}.open.disabled`)
                : t(`${PRE_TRANS}.open.title`)
              )
          }
          hideError
          disabled={loadDisabled}
        >
          {t(`${PRE_TRANS}.open.label`)}
        </Button>
      </Form>
    )
  }
}

export default withNamespaces('application')(connector(VisualizationColumn as any) as any) as any
