import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next'
import { Translation } from 'types/translation'

import Icon from '../../../specific/Icon'
import { InputForm, NumberSelector, HiddenButton } from '../InputStyles'

type Props = {
  isDisabled: boolean,
  mass: boolean,
  value: string,
  min?: number,
  max?: number,
  massValue: string,
  name: string,
  placeholder: string,
  onChange: (e: any) => void,
  inputRef: any
  t: Translation
  style?: any
  onlyPositiveNumbers?: boolean
};

class NumberField extends Component<Props> {
  handleMinMax = (num: number) => {
    const { min, max } = this.props

    if (min !== undefined && num < min) {
      return min
    }

    if (max !== undefined && num > max) {
      return max
    }

    return num
  }

  handleInputChange = (event: any) => {
    const { onlyPositiveNumbers, onChange, name, min } = this.props
    const { value } = event.target as { value: string}
    const numberedValue = this.handleMinMax(Number(value))

    if (value === '' || (!onlyPositiveNumbers && (min === undefined || min < 0) && value === '-')) {
      onChange(event)

      return
    }

    const isNumber = !isNaN(numberedValue)

    if (!isNumber) {
      return
    }

    if (onlyPositiveNumbers) {
      const isNegative = value.split('').some(char => char === '-')

      if (isNegative) {
        return
      }
    }

    event.target.value = String(numberedValue)

    onChange(event)
  }

  handleArrowClick = (event: any) => {
    const { value: receivedValue, mass, onChange, name, onlyPositiveNumbers } = this.props

    const num = event.currentTarget.value === 'up' ? 1 : -1

    if (onlyPositiveNumbers && num === -1 && Number(receivedValue) === 0) {
      return
    }

    const newNumber = this.handleMinMax(Number(receivedValue) + num)
    const value = mass ? '0' : String(newNumber)

    onChange({ target: { value, name } })
  };

  render () {
    const { isDisabled, massValue, placeholder, name, inputRef, t, style, onlyPositiveNumbers } = this.props
    const downButtonDisabled = onlyPositiveNumbers && Number(massValue) <= 0

    return (
      <div style={{ paddingRight: isDisabled ? '17px' : undefined }}>
        <InputForm
          placeholder={placeholder}
          type='text'
          value={massValue}
          title={massValue}
          onChange={this.handleInputChange}
          disabled={isDisabled}
          name={name}
          ref={inputRef}
          style={{ marginRight: '-17px', ...style }}
        />
        {
          !isDisabled &&
            <NumberSelector>
              <HiddenButton
                title={t('up')}
                value='up'
                onClick={this.handleArrowClick}
              >
                <Icon icon='angle-up' />
              </HiddenButton>
              <HiddenButton
                title={t('down')}
                value='down'
                onClick={this.handleArrowClick}
                disabled={downButtonDisabled}
              >
                <Icon icon='angle-down' />
              </HiddenButton>
            </NumberSelector>
        }
      </div>
    )
  }
}
export default withNamespaces('caster')(NumberField as any) as any
