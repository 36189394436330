import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import * as VisualizationActions from 'store/visualization/actions'

import { Dialog, DialogBackground, Header, Title, Text, Form, I, Button } from './DialogStyles'
import NetworkManager from 'network/NetworkManager'

import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((store: DefaultState) => ({
  currentSimulationCase: store.application.main.currentSimulationCase,
  deleteDialogPlot: store.visualization.deleteDialogPlot,
  tileConfigs: store.visualization.tileConfigs,
}), {
  showDeleteDialog: VisualizationActions.showDeleteDialog,
  deletePlotTile: VisualizationActions.deletePlotTile,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

class DeletePlotDialog extends Component<Props> {
  @AnalyzeTime(0)
  componentDidMount () {
    hotkeys('Escape', this.handleClose as any)
    hotkeys('Enter', this.handleSubmit as any)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
    hotkeys.unbind('Enter', this.handleSubmit as any)
  }

  // @AnalyzeTime(0)
  handleSubmit = async () => {
    const { currentSimulationCase, showDeleteDialog, deletePlotTile, deleteDialogPlot, tileConfigs } = this.props

    if (!deleteDialogPlot.tileId) {
      return
    }

    const currentTile = tileConfigs[deleteDialogPlot.tileId]

    if (currentTile.type === 'command') {
      await ApiClient.del(
        `${Network.URI}/visualization_command/${currentTile.fileId}/${currentTile.commandId}`,
        { data: { simulationCaseId: currentSimulationCase._id } },
      )
    }

    NetworkManager.removePlot(deleteDialogPlot.tileId)

    deletePlotTile(deleteDialogPlot.tileId)
    showDeleteDialog()
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { showDeleteDialog } = this.props

    showDeleteDialog()
  };

  @AnalyzeTime(0)
  render () {
    const { t } = this.props

    return (
      <div>
        <DialogBackground />
        <Dialog height='200px'>
          <Header title={t('deletePlotDialog.title')}>
            <Title>{t('deletePlotDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('deletePlotDialog.close')}
            />
          </Header>
          <Form>
            <Text>{t('deletePlotDialog.message')}</Text>
            <br />
            <Button
              onClick={this.handleSubmit}
              $type='error'
              half
              title={t('deletePlotDialog.delete.title')}
            >
              {t('deletePlotDialog.delete.label')}
            </Button>
            <Button
              onClick={this.handleClose}
              half
              title={t('deletePlotDialog.abort.label')}
            >
              {t('deletePlotDialog.abort.title')}
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(DeletePlotDialog))
