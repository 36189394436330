/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'
import { withSnackbar } from 'notistack'

import Button from '../../components/Button'
import { ConfirmWrapper } from '../../components/Button/styles'
import { Form, Text } from '../../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../../specific/Input'
import { Spacer } from 'react/dialogs/project/OpenProjectDialog/Styles'

import * as ApplicationActions from 'store/application/main/actions'
import * as VisualizationActions from 'store/visualization/actions'

import BaseDialog from '../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import Util from 'logic/Util'
import ThreeUtil from 'three/logic/Util'
import FilterHandler from 'three/logic/FilterHandler'
import { AppState } from 'store/application/main/consts'
import FeatureFlags from 'react/FeatureFlags/index'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import VisUtil from 'react/visualization/VisUtil'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  appState: state.application.main.appState,
  editConfigId: state.application.main.editConfigId,
  selectedDataArray: state.data.selectedDataArray,
  rootData: state.data.rootData,
  visualizationData: state.visualization.data,
  term: state.filter.term,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  currentSimulationCase: state.application.main.currentSimulationCase,
  elementsHashes: {
    AirLoop: state.AirLoop,
    CoolingLoop: state.CoolingLoop,
    CoolingZone: state.CoolingZone,
    LoopAssignment: state.LoopAssignment,
    SegmentGroup: state.SegmentGroup,
    Segment: state.Segment,
    SupportPoint: state.SupportPoint,
    SegmentGroupSupportPoints: state.SupportPoint,
    RollerBody: state.RollerBody,
    RollerBearing: state.RollerBearing,
    Nozzle: state.Nozzle,
    Roller: state.Roller,
    SensorPoint: state.SensorPoint,
    DataPoint: state.DataPoint,
    DataLine: state.DataLine,
    StrandGuide: state.StrandGuide,
  },
}), {
  setDataSources: VisualizationActions.setDataSources,
  setConfig: VisualizationActions.setConfig,
  closeDialog: ApplicationActions.closeDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  enqueueSnackbar: enqueueSnackbar,
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string | boolean | null
  previousName: string,
  name: string,
  error: string,
  loading: boolean,
  previousSelectedSet: string | null
  selectedSet: string,
  previousSelectedX: string | null,
  selectedX: string,
  previousSelectedY: string | null
  selectedY: string
  selectedDataSource: string,
  xInputWarning: boolean,
  yInputWarning: boolean,
  verticalLines: string,
};

const TRANS = 'manageDynamicDataSourcesDialog'

export class ManageDynamicDataSourcesDialog extends Component<Props, State> {
  static NAME = uuid()

  didInit = false;

  state = {
    previousName: '',
    name: '',
    error: '',
    loading: false,
    selectedSet: 'default',
    selectedX: 'default',
    selectedY: 'default',
    selectedDataSource: 'new',
    previousSelectedSet: null,
    previousSelectedX: null,
    previousSelectedY: null,
    xInputWarning: false,
    yInputWarning: false,
    verticalLines: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { rootData, enqueueSnackbar, closeDialog, t } = this.props

    if (!rootData.Caster) {
      enqueueSnackbar(t(`${TRANS}.casterNeeded`), { autoHideDuration: 3000, variant: 'info' })

      closeDialog(ManageDynamicDataSourcesDialog.NAME)

      // TODO: project dialog?

      return
    }

    this.handleInit()

    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleInit()
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  handleInit = () => {
    // TODO: do we need to save the data with the caster or with the vis config?
  };

  handleClose = () => {
    const { closeDialog, setEditConfigId } = this.props

    closeDialog(ManageDynamicDataSourcesDialog.NAME)
    setEditConfigId()
  };

  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  handleKeyDown = (event: any) => {
    if (event.keyCode === 13 && this.isValid()) {
      this.handleSubmit()
    }
  };

  handleSubmit = async () => {
    const { name, selectedSet, selectedX, selectedY, selectedDataSource } = this.state
    const { term, visualizationMetaInformation, plotConfigs } = this.props
    const { config } = (visualizationMetaInformation || {})[AppState.Caster] || {}

    this.setState(({
      loading: true,
    }))

    const isVerticalLine = (selectedX === 'verticalLine' || selectedY === 'verticalLine')

    try {
      let dataSources: any

      if (selectedDataSource === 'new') {
        const elements = selectedSet === 'currentFilter' || selectedSet === 'allElements' ? [] : this.getElementPaths()
        const filter = selectedSet === 'currentFilter' ? term : null

        const res = await ApiClient.post(
          `${Network.URI}/visualization_config/add_dynamic_data_source/${config}`,
          { data: { name, selectedSet, selectedX, selectedY, elements, filter, isVerticalLine } },
        )

        dataSources = res.dataSources
      }
      else {
        const dataSourceId = plotConfigs[selectedDataSource]._id
        const previousElements = plotConfigs[selectedDataSource].elements
        const previousFilter = plotConfigs[selectedDataSource].filter

        const elements =
          selectedSet === 'previousFilter' || selectedSet === 'currentFilter' || selectedSet === 'allElements'
            ? []
            : selectedSet === 'previousSelection' ? previousElements : this.getElementPaths()

        const filter = selectedSet === 'currentFilter'
          ? term
          : selectedSet === 'previousFilter' ? previousFilter : null

        const res = await ApiClient.patch(
          `${Network.URI}/visualization_config/update_dynamic_data_source/${config}/${dataSourceId}`,
          { data: { name, selectedSet, selectedX, selectedY, elements, filter, isVerticalLine } },
        )

        dataSources = res.dataSources
      }

      this.props.setDataSources(dataSources)
      this.handleClose()
    }
    catch (response: any) {
      const { error } = response

      this.setState(({
        error: error ? error.status : 'unknown',
        loading: false,
      }))
    }
  };

  handleSetChange = (event: any) => {
    const { value } = event.target
    const { previousSelectedX, previousSelectedY } = this.state
    let newSelectedX = 'default'
    let newSelectedY = 'default'

    if (value.includes('previous')) {
      newSelectedX = previousSelectedX || ''
      newSelectedY = previousSelectedY || ''
    }

    this.setState({ selectedSet: value, selectedX: newSelectedX, selectedY: newSelectedY })
  };

  handleXChange = (event: any) => {
    const { value } = event.target

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)

    const showXWarning = !(value === 'default' || value === 'verticalLine') &&
    !allAttributes.find(attr => attr.key === value)

    this.setState({ selectedX: value, xInputWarning: showXWarning })
  };

  handleYChange = (event: any) => {
    const { value } = event.target

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)
    const showYWarning = value !== 'default' && !allAttributes.find(attr => attr.key === value)

    this.setState({ selectedY: value, yInputWarning: showYWarning })
  };

  handleChosenDataSourceChange = (event: any) => {
    const { plotConfigs, t } = this.props
    const { value } = event.target

    const originalConfig = plotConfigs[value] || {}

    if (value === 'new' || value === '') {
      this.setState({
        selectedDataSource: value || 'new',
        previousSelectedSet: null,
        previousSelectedX: null,
        previousSelectedY: null,
        previousName: '',
        selectedSet: 'default',
        selectedX: 'default',
        selectedY: 'default',
        xInputWarning: false,
        yInputWarning: false,
      })
    }
    else {
      // check if there are elements that have the requested values
      let newSelectedSet = 'allElements'

      switch (originalConfig.selectedSet) {
        case 'currentFilter':
          newSelectedSet = 'previousFilter'
          break
        case 'currentSelection':
          newSelectedSet = 'previousSelection'
          break
        default:
          break
      }

      this.setState({
        selectedDataSource: value,
        previousSelectedSet: originalConfig.selectedSet,
        previousSelectedX: originalConfig.selectedX,
        previousSelectedY: originalConfig.selectedY,
        previousName: originalConfig.name,
        name: originalConfig.name,
        selectedSet: newSelectedSet,
        selectedX: originalConfig.selectedX,
        selectedY: originalConfig.selectedY,
        xInputWarning: false,
        yInputWarning: false,
      })
    }
  }

  handleSourceClick = () => {
    const { selectedDataSource } = this.state
    const { plotConfigs } = this.props
    const filter = plotConfigs[selectedDataSource]?.filter

    if (filter) {
      navigator.clipboard.writeText(filter)
    }
  }

  handleDeleteDataSource = (inputName: string, plotConfigId: string) => {
    const { visualizationMetaInformation, plotConfigs, enqueueSnackbar, setConfig, setDataSources } = this.props
    const { config: configId } = (visualizationMetaInformation || {})[AppState.Caster] || {}
    const dataSourceId = (plotConfigs[plotConfigId] || {})._id

    if (!dataSourceId) {
      enqueueSnackbar('Error deleting data source', { variant: 'error', autoHideDuration: 4000 })

      return
    }

    ApiClient
      .del(
        `${Network.URI}/visualization_config/delete_dynamic_data_source/${configId}/${dataSourceId}`,
        { data: { plotConfigId } },
      )
      .then((visualizationConfig) => {
        setConfig(visualizationConfig.data)
        setDataSources(visualizationConfig.dataSources)
      })
  }

  getElementPaths = (): any[] => {
    const { selectedSet, selectedDataSource } = this.state
    const { selectedDataArray, term, elementsHashes, rootData, plotConfigs } = this.props

    if (!rootData.Caster) {
      return []
    }

    const { SegmentGroup } = elementsHashes

    switch (selectedSet) {
      case 'allElements':
        const allElements = FilterHandler.getAllElements(SegmentGroup, elementsHashes)
        const elementListAll = (allElements || []).map(element => element.path)

        return elementListAll
      case 'currentSelection':
        return selectedDataArray
      case 'previousSelection':
        return plotConfigs[selectedDataSource].elements
      case 'currentFilter':
      case 'previousFilter':
        const filteredElements = FilterHandler.getFilteredElements(
          elementsHashes,
          selectedSet === 'currentFilter' ? term : plotConfigs[selectedDataSource].filter,
          false,
        ) as Record<string, FilterableElementType>

        const elementList = Object.entries(filteredElements)
          .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
          .map(([ path ]) => path)

        return elementList
      default:
        return []
    }
  };

  getAttributes = (elementPaths: string[]) => {
    const { elementsHashes } = this.props

    const attributes = []
    const addedAttributes:any[] = []

    for (const path of elementPaths || []) {
      const type = ThreeUtil.getElementInfo(path).type
      const element = Util.getElement(path, elementsHashes)

      if (!element) {
        continue
      }

      const attributeKeys = Object.keys(element).sort((a, b) => a.localeCompare(b))

      for (const key of attributeKeys) {
        const completeKey = `${type}|${key}`

        if (key[0] === '_' && key !== '_numericId' && key !== '_originalId' && !addedAttributes.includes(completeKey)) {
          addedAttributes.push(completeKey)
          attributes.push({ key: completeKey, value: key.replace(/^_/, ''), group: type })
        }
      }
    }

    return attributes
  };

  isValid = () => {
    const { name, selectedSet, selectedX, selectedY, selectedDataSource, xInputWarning, yInputWarning } = this.state

    const condition = Boolean(name) && selectedSet !== 'default' && selectedX !== 'default' && selectedY !== 'default'

    if (selectedDataSource !== 'new') {
      if (xInputWarning || yInputWarning) {
        return false
      }

      const elementPaths = this.getElementPaths()
      const allAttributes = this.getAttributes(elementPaths)

      const { previousName, previousSelectedSet, previousSelectedX, previousSelectedY } = this.state

      let selectedSetChanged: boolean

      if (previousSelectedSet === 'currentFilter') {
        selectedSetChanged = selectedSet !== 'previousFilter'
      }
      else if (previousSelectedSet === 'currentSelection') {
        selectedSetChanged = selectedSet !== 'previousSelection'
      }
      else {
        selectedSetChanged = selectedSet === previousSelectedSet
      }

      const showXWarning = !(selectedX === 'default' || selectedX === 'verticalLine') &&
      !allAttributes.find(attr => attr.key === selectedX)

      const showYWarning = selectedY !== 'default' && !allAttributes.find(attr => attr.key === selectedY)

      if (showXWarning) {
        this.setState({ xInputWarning: true })
      }

      if (showYWarning) {
        this.setState({ yInputWarning: true })
      }

      return condition &&
      !showXWarning &&
      !showYWarning &&
      (name !== previousName ||
        selectedSetChanged ||
        previousSelectedX !== selectedX ||
        previousSelectedY !== selectedY)
    }

    return condition
  };

  getSetSelectors = (setSelectors: any[]) => {
    const { selectedDataSource } = this.state
    const { plotConfigs } = this.props

    if (selectedDataSource === 'new') {
      return setSelectors
    }

    const config = plotConfigs[selectedDataSource]
    const previousSet = config.selectedSet

    switch (previousSet) {
      case 'allElements':
        return setSelectors
      case 'currentFilter':
        return [ ...setSelectors, { key: 'previousFilter', value: `Previous Filter` } ]
      case 'currentSelection':
        return [ ...setSelectors, { key: 'previousSelection', value: 'Previous Selection' } ]
      default:
        return setSelectors
    }
  }

  getPreviousSelectionSelector = (selector: string) => {
    if (selector === 'verticalLine') {
      return selector
    }

    return selector.replace('_', '').split('|')
  }

  // TODO: handle duplicate names, show message and confirm override

  @AnalyzeTime(0)
  render () {
    const {
      name,
      error,
      loading,
      selectedSet,
      selectedX,
      selectedY,
      xInputWarning,
      yInputWarning,
    } = this.state
    const { t, plotConfigs, featureFlags, visualizationMetaInformation, appState } = this.props

    const setSelectors = [
      { key: 'default', value: t(`${TRANS}.setSelector.default`) },
      { key: 'allElements', value: t(`${TRANS}.setSelector.allElements`) },
      { key: 'currentFilter', value: t(`${TRANS}.setSelector.currentFilter`) },
      { key: 'currentSelection', value: t(`${TRANS}.setSelector.currentSelection`) },
    ]

    const elementPaths = this.getElementPaths()
    const allAttributes = this.getAttributes(elementPaths)

    const selectedXAttr = allAttributes.find(attr => attr.key === selectedX)
    const selectedYAttr = allAttributes.find(attr => attr.key === selectedY)

    const xSelectors = [
      { key: 'default', value: t(`${TRANS}.xSelector.default`), group: ' ' },
      ...(
        !selectedYAttr
          ? allAttributes
          : allAttributes.filter(attr => attr.group === selectedYAttr.group && attr.key !== selectedYAttr.key)
      ),
    ]

    const getXSelectors = () => {
      const { previousSelectedX } = this.state

      if (!previousSelectedX) {
        return xSelectors
      }

      let [ group, value ] = this.getPreviousSelectionSelector(previousSelectedX)

      if (previousSelectedX === 'verticalLine') {
        group = ' '
        value = previousSelectedX
      }

      if (!xSelectors.find(selector => selector.key === previousSelectedX)) {
        return [
          ...xSelectors,
          { group, value, key: previousSelectedX, disabled: true },
        ]
      }

      return xSelectors
    }

    const allXSelectors = getXSelectors()

    if (allXSelectors.length > 1 && !allXSelectors.find(selector => selector.key === 'verticalLine')) {
      allXSelectors.splice(1, 0, { key: 'verticalLine', value: 'verticalLine', group: ' ' })
    }

    const ySelectors = [
      { key: 'default', value: t(`${TRANS}.ySelector.default`), group: ' ' },
      ...(
        !selectedXAttr
          ? allAttributes
          : allAttributes.filter(attr => attr.group === selectedXAttr.group && attr.key !== selectedXAttr.key)
      ),
    ]

    const getYSelectors = () => {
      const { previousSelectedY } = this.state

      if (!previousSelectedY) {
        return ySelectors
      }

      const [ group, value ] = this.getPreviousSelectionSelector(previousSelectedY)

      if (!ySelectors.find(selector => selector.key === previousSelectedY)) {
        return [
          ...ySelectors,
          { group, value, key: previousSelectedY, disabled: true },
        ]
      }

      return ySelectors
    }

    const allYSelectors = getYSelectors()

    const configIds = Object.keys(plotConfigs || {})

    const allDataSources = []

    allDataSources.push(...configIds.reduce((acc: any[], configId: string) => {
      const config = plotConfigs[configId]

      if (config.group !== 'dynamicDataSource') {
        return acc
      }

      const { name, _id } = config
      const { value, isVerticalLine } = VisUtil.getConfigInfo(name, _id, _id, 'dynamicDataSource', config)

      return [
        ...acc,
        { key: configId, value: isVerticalLine ? `(VL) ${value}` : value },
      ]
    }, []))

    // sort data sources alphabetically case unsensitive
    allDataSources.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    if (FeatureFlags.canAddDynamicData(featureFlags, visualizationMetaInformation, appState)) {
      allDataSources.unshift({ key: 'new', value: 'New Dynamic Data Source' })
    }

    const currentDataSourceInfo = plotConfigs[this.state.selectedDataSource]?.filter || ''
    const disableEdition =
      this.state.selectedDataSource !== 'new' &&
      !FeatureFlags.canEditDynamicData(featureFlags, visualizationMetaInformation, appState)

    return (
      <BaseDialog
        title={t(`${TRANS}.title`)}
        icon='pe-7s-server'
        header={t(`${TRANS}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='dynamicDataSources'
            type='select'
            label='Dynamic Data'
            title={currentDataSourceInfo}
            value={this.state.selectedDataSource}
            selectors={[ ...allDataSources ]}
            onDelete={
              FeatureFlags.canDeleteDynamicData(featureFlags, visualizationMetaInformation, appState) &&
              this.handleDeleteDataSource
            }
            onChange={this.handleChosenDataSourceChange}
            onLabelRightClick={this.handleSourceClick}
          />
          <Input
            label={t(`${TRANS}.name.label`)}
            title={t(`${TRANS}.name.label`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            disabled={disableEdition}
          />
          <Input
            name='set'
            type='select'
            label={t(`${TRANS}.setSelector.label`)}
            title={t(`${TRANS}.setSelector.title`)}
            value={selectedSet}
            selectors={this.getSetSelectors(setSelectors)}
            onChange={this.handleSetChange}
            disabled={disableEdition}
          />
          <Spacer h={5} br />
          {
            (elementPaths && elementPaths.length > 0)
              ? <Text>{t(`${TRANS}.setSelector.elements`, { elements: elementPaths.length })}</Text>
              : <Text>{t(`${TRANS}.setSelector.noElements`)}</Text>
          }
          <Input
            name='x'
            type='select'
            label={t(`${TRANS}.xSelector.label`)}
            title={t(`${TRANS}.xSelector.title`)}
            value={selectedX}
            selectors={allXSelectors}
            onChange={this.handleXChange}
            disabled={(selectedSet.includes('previous') && xSelectors.length <= 1) || disableEdition}
          />
          <Spacer h={5} br />
          {
            (xInputWarning)
              ? <Text style={{ color: 'yellow' }}>The set has no elements</Text>
              : null
          }
          <Input
            name='y'
            type='select'
            label={t(`${TRANS}.ySelector.label`)}
            title={t(`${TRANS}.ySelector.title`)}
            value={selectedY}
            selectors={allYSelectors}
            onChange={this.handleYChange}
            disabled={(selectedSet.includes('previous') && ySelectors.length <= 1) || disableEdition}
          />
          <Spacer h={5} br />
          {
            (yInputWarning)
              ? <Text style={{ color: 'yellow' }}>The set has no elements</Text>
              : null
          }
          <ConfirmWrapper>
            <Button
              title={this.state.selectedDataSource === 'new' ? 'Create' : 'Edit'}
              type='primary'
              disabled={!this.isValid()}
              onClick={this.handleSubmit} // see if this.state.selectedDataSource is 'New ..' if is, post , else patch
              error={error}
              loading={loading}
              isRef
              half
            >
              {this.state.selectedDataSource === 'new' ? t(`${TRANS}.save`) : 'Update'}
            </Button>
            <Button
              value=''
              onClick={this.handleClose}
              title={t(`${TRANS}.cancel`)}
              half
            >
              {t(`${TRANS}.cancel`)}
            </Button>
          </ConfirmWrapper>
        </Form>
      </BaseDialog>
    )
  }
}

const connected = connector(ManageDynamicDataSourcesDialog as any) as any

export default withSnackbar(withNamespaces('application')(connected) as any) as any
