import styled, { css } from 'styled-components'
import { ListItemText } from '@material-ui/core'

export const StyledListItem = styled(ListItemText)`${({ theme }) => css`
  color: ${theme.mainFontColor}
`}`

export const InfoBox = styled.div`${({ theme }) => css`
  color: ${theme.mainFontColor}
`}`

export const Bottom = styled.div`${() => css`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
`}`

export const Wrapper = styled.div`${() => css`
  padding: 10px;
`}`
