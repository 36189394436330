import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import * as VisualizationActions from 'store/visualization/actions'

import { Dialog, DialogBackground, Header, Title, Form, I } from './DialogStyles'
import Input from '../../../specific/Input'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((store:DefaultState) => ({
  editDialogDashboardData: store.visualization.editDialogDashboardData,
  viewsObject: store.visualization.viewsObject,
}), {
  showEditDashboardDialog: VisualizationActions.showEditDashboardDialog,
  setDashboardObject: VisualizationActions.setDashboardObject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  name: string
};

class EditDashboardDialog extends Component<Props, State> {
  state: State = {
    name: '',
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { editDialogDashboardData: { viewId, dashboardId }, viewsObject } = this.props

    const { name } = (((viewsObject as any)[viewId || ''] || {}).dashboards || {})[dashboardId || '']

    this.setState({
      name,
    })

    hotkeys('Escape, Enter', this.handleHotkeys)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape, Enter', this.handleHotkeys)
  }

  // @AnalyzeTime(0)
  handleHotkeys = (_event?: any) => this.handleClose();

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { value } = event.target

    this.setState({
      name: value,
    })
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const {
      editDialogDashboardData: { viewId, dashboardId },
      viewsObject,
      setDashboardObject,
      showEditDashboardDialog,
    } = this.props

    if (!viewId || !dashboardId) {
      return
    }

    const { name } = this.state

    setDashboardObject(
      viewId,
      {
        ...viewsObject[viewId].dashboards[dashboardId],
        name,
      },
      dashboardId,
    )

    showEditDashboardDialog()
  };

  @AnalyzeTime(0)
  render () {
    const { t } = this.props
    const { name } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog height='200px' half>
          <Header title={t('editDashboardDialog.title')}>
            <Title>{t('editDashboardDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('editDashboardDialog.close')}
            />
          </Header>
          <Form>
            <Input
              label={t('editDashboardDialog.name.label')}
              title={t('editDashboardDialog.name.title')}
              name='name'
              type='text'
              value={name || ''}
              onChange={this.handleInput}
              autoFocus
            />
          </Form>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(EditDashboardDialog as any) as any) as any
