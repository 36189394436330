import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from '../../../../../store/visualization/actions'
import VisUtil from '../../../VisUtil'
import { CheckListHeader, FormWrapper, IButton, InputWrapper } from '../../Dialogs/DialogStyles'
import Input from '../../../../specific/Input'
import ColorPicker from '../../ColorPicker'

import { Translation } from 'types/translation'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean,
  t: Translation
}

type State = {
  tileConfig: any
};

class AdditionalTab extends Component<Props, State> {
  timeoutRef?: number;

  constructor (props: Props) {
    super(props)
    const { tileConfigs, currentTileId } = props

    if (!currentTileId) {
      return
    }

    const additionalYAxes = [ ...tileConfigs[currentTileId].additionalYAxes ]

    this.state = {
      tileConfig: {
        id: currentTileId,
        additionalYAxes,
      },
    }
  }

  // @AnalyzeTime(0)
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!prevState.tileConfig.equals(tileConfig || {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        this.timeoutRef = undefined
      }, 1000)
    }
  };

  // @AnalyzeTime(0)
  componentWillUnmount = () => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  };

  // @AnalyzeTime(0)
  handleAddAdditionalYAxis = (_event: any) => {
    // TODO: verify that works
    const { tileConfigs, currentTileId, plotConfigs, editDialogConfigId } = this.props

    const plotConfig = plotConfigs[editDialogConfigId]
    const config = currentTileId
      ? tileConfigs[currentTileId]
      : { additionalYAxes: undefined, configId: undefined }
    const additionalYAxes = [ ...(config.additionalYAxes || []) ]
    const plotsConfigIds = (plotConfigs[config.configId || (plotConfig.configIds || [])[0]] || {}).configIds

    const nextId = (plotsConfigIds || [])
      .filter((id: any) => !~additionalYAxes.map((additionalYAxis: any) => additionalYAxis.id).indexOf(id))[0]

    if (nextId !== undefined) {
      additionalYAxes.push({
        id: nextId,
      })
    }

    this.setState({
      tileConfig: {
        id: currentTileId,
        additionalYAxes,
      },
    })
  };

  // @AnalyzeTime(0)
  handleRemoveAdditionalYAxis = (event: any) => {
    const { id } = event.target
    const { tileConfigs, currentTileId } = this.props
    const additionalYAxes = currentTileId
      ? [ ...tileConfigs[currentTileId].additionalYAxes ]
      : []

    additionalYAxes.splice(Number(id), 1)

    this.setState({ tileConfig: { id: currentTileId, additionalYAxes } })
  };

  // @AnalyzeTime(0)
  handleAdditionalYAxes = (event: any, id: number) => {
    const { name, value } = event.target
    const { tileConfigs, currentTileId } = this.props
    const config = currentTileId ? tileConfigs[currentTileId] : {}
    const additionalYAxes = [ ...((config as any).additionalYAxes || []) ]

    additionalYAxes[id] = {
      ...additionalYAxes[id],
      [name]: value,
    }

    this.setState({
      tileConfig: {
        id: currentTileId,
        additionalYAxes,
      },
    })
  };

  @AnalyzeTime(0)
  render () {
    const { plotConfigs, tileConfigs, editDialogConfigId, currentTileId, fullscreen, t, data } = this.props
    const { tileConfig: { additionalYAxes } } = this.state
    const { configId } = tileConfigs[currentTileId || ''] || {}
    const { configIds } = plotConfigs[editDialogConfigId]

    const plotsConfigIds = (plotConfigs[configId || (configIds || [])[0]] || {}).configIds
    const allPlotsConfigIds = VisUtil.getConfigSelectors(data, plotConfigs, plotsConfigIds)

    return (
      <FormWrapper>
        <CheckListHeader>{t('plotConfig.additional')}</CheckListHeader>
        {
          additionalYAxes && additionalYAxes.length > 0 &&
        additionalYAxes.map(({ id, title, color, yDomainMin, yDomainMax, showLine }:{
          id: string,
          title: string,
          color: string
          yDomainMin: number,
          yDomainMax: number,
          showLine: boolean,
        }, index: number) => (
          <div key={index}>
            <InputWrapper fullscreen={fullscreen}>
              <Input
                id={index.toString()}
                label={t('plotConfig.title.label', { index: index + 2 })}
                title={t('plotConfig.title.title', { index: index + 2 })}
                name='title'
                type='text'
                value={title || ''}
                onChange={e => this.handleAdditionalYAxes(e, index)}
              />
              <Input
                id={index.toString()}
                label={t('plotConfig.plotDataId.label', { index: index + 2 })}
                title={t('plotConfig.plotDataId.title', { index: index + 2 })}
                name='id'
                type='select'
                selectors={
                  allPlotsConfigIds.filter(selector => selector.key === id ||
                    !additionalYAxes.map((additionalYAxis: any) => additionalYAxis.id).includes(selector.key))
                }
                value={id}
                onChange={e => this.handleAdditionalYAxes(e, index)}
              />
              <ColorPicker
                labelSpacer
                color={color || '#a2a6a9'}
                colors={
                  [
                    '#a2a6a9',
                    ...VisUtil.TRACE_COLORS,
                  ]
                }
                id={index.toString()}
                name='color'
                onChange={e => this.handleAdditionalYAxes(e, index)}
              />
            </InputWrapper>
            <InputWrapper style={{ marginRight: '58px' }}>
              <Input
                label={t('plotConfig.yDomain.label')}
                title={t('plotConfig.yDomain.title')}
                name='yDomain'
                type='range'
                id={index.toString()}
                min={yDomainMin}
                max={yDomainMax}
                onChange={e => this.handleAdditionalYAxes(e, index)}
              />
            </InputWrapper>
            <InputWrapper style={{ marginRight: '58px' }}>
              <Input
                label={t('plotConfig.showLine.label')}
                title={t('plotConfig.showLine.title')}
                name='showLine'
                type='select'
                selectors={[ true, false ]}
                id={index.toString()}
                value={showLine || false}
                onChange={e => this.handleAdditionalYAxes(e, index)}
              />
            </InputWrapper>
            <IButton
              className='pe-7s-less'
              onClick={this.handleRemoveAdditionalYAxis}
              id={index.toString()}
              title={t('plotConfig.remove')}
            />
            {additionalYAxes.length - 1 > index && <hr />}
          </div>
        ))
        }
        {
          (!additionalYAxes || additionalYAxes.length < (plotsConfigIds || []).length - 1) &&
            <IButton className='pe-7s-plus' onClick={this.handleAddAdditionalYAxis} title={t('plotConfig.add')} />
        }
      </FormWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(AdditionalTab as any) as any) as any
