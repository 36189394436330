import React, { Component, ReactNode } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { withNamespaces } from 'react-i18next'

import ScrollBar from './specific/ScrollBar'
import TreePlaceholder from './specific/TreePlaceholder'
import CasterOptions from './CasterOptions'
import TreeView from './TreeView'
import CasterTreeContent from './CasterTreeContent'

import DataActions from '../store/data/actions'
import * as FilterActions from 'store/filter/actions'
import * as VisualizationActions from 'store/visualization/actions'

import type { KeyboardEvent } from 'react'
import { DefaultState } from 'types/state'
import FeatureFlags from './FeatureFlags'
import { getCurrentDashboardEntry } from 'App/util'

export const ResizeHandle = styled.div<{width: number, onDragStart: any, onDrag: any}>`${({ width }) => css`
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: ${width}px;
  height: 30px;
  width: 30px;
  background: #474b4e;
  display: flex;
  cursor: ew-resize;
  display:flex;
  align-items: center;
  justify-content: center;
  color: #CCCCCC;
  font-size: 20px;
  border-top-right-radius: 7px;
`}`

export const CurrentDashboardWidth = styled.div<{visible: boolean}>`${({ visible }) => css`
  display: ${visible ? 'unset' : 'none'};
  position: absolute;
  bottom: 7px;
  left: 10px;
  z-index: 100;
  color: #CCCCCC;
  user-select: none;
`}`

const CasterTreeView = styled.div<{
  dashboardWidth: number,
  isBig: boolean,
  isOpen: boolean,
  canResize: boolean,
  children: ReactNode,
  maxDashboardWidth: number,
  dragging: boolean,
}>`${({
  isOpen,
  isBig,
  canResize,
  dashboardWidth,
  maxDashboardWidth,
  dragging,
}: any) => css`
  position:   absolute;
  top:        0;
  bottom:     0;
  left:       ${isOpen ? 0 : (isBig ? `-${dashboardWidth}px` : '-280px')};
  width:      ${isBig ? `${dashboardWidth}px` : '280px'};
  min-width:  ${isBig ? '500px' : '280px'};
  max-width: ${maxDashboardWidth}px;
  background: #22282e;
  font-size:  .9em;
  ::after {
    display:  ${canResize && isBig && isOpen ? 'unset' : 'none'};
    opacity: ${dragging ? '1' : 'var(--dragging)'};
    transition: opacity .1s ease-out;;
    content: '';
    background-color: #ccc;
    position: absolute;
    right: -4px;
    width: 4px;
    height: 100%;
    cursor: ew-resize;
  }
`}`

const Scroll = styled(ScrollBar)`
  position:   absolute;
  width:      100%;
  padding:    0 8px;
  height:     calc(100vh - 50px - 16px);
  top:        50px;
  /* border-top: solid 1px grey; */
  overflow-y: auto;
`

const NoFile = styled.div`
  position:     absolute;
  top:          50%;
  left:         50%;
  transform:    translate(-50%, -50%);
  color:        #cccccc;
  font-size:    14px;
  font-weight:  bold;
  white-space:  nowrap;
  user-select:  none;
`

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  currentSimpleDashboardTabIndex: state.application.main.currentSimpleDashboardTabIndex,
  currentProject: state.application.main.currentProject,
  openDialogs: state.application.main.openDialogs,
  loadingStatus: state.application.main.loadingStatus,
  AirLoop: state.AirLoop,
  CoolingLoop: state.CoolingLoop,
  CoolingZone: state.CoolingZone,
  LoopAssignment: state.LoopAssignment,
  Nozzle: state.Nozzle,
  Roller: state.Roller,
  RollerBearing: state.RollerBearing,
  RollerBody: state.RollerBody,
  SupportPoint: state.SupportPoint,
  SegmentGroupSupportPoints: state.SupportPoint,
  Segment: state.Segment,
  SegmentGroup: state.SegmentGroup,
  SensorPoint: state.SensorPoint,
  StrandGuide: state.StrandGuide,
  DataPoint: state.DataPoint,
  DataLine: state.DataLine,
  selectedElements: state.data.selectedDataArray,
  term: state.filter.term,
  target: state.filter.target,
  viewsObject: state.visualization.viewsObject,
  currentDashboard: state.visualization.currentDashboard,
  currentDashboardWidth: state.visualization.currentDashboardWidth,
  featureFlags: state.application.main.authenticationData.featureFlags,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  appState: state.application.main.appState,
}), {
  setTarget: FilterActions.setTarget,
  setTerm: FilterActions.setTerm,
  selectedMultiEditElements: DataActions.selectedMultiEditElements,
  setDashboardObject: VisualizationActions.setDashboardObject,
  setCurrentDashboardWidth: VisualizationActions.setCurrentDashboardWidth,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type State = {
  initialPosition: number,
  initialSize: number,
  currentPanelWidth: number
  mousePosition: number,
  dragging: boolean,
};
interface Props extends PropsFromRedux {
  isOpen: boolean
  t (key: string, params?: Record<string, unknown>): string
  handleResizeCasterTree: (width: number) => void
}

export class CasterTree extends Component<Props, State> {
  state = {
    initialPosition: 0,
    initialSize: 0,
    currentPanelWidth: 0,
    mousePosition: 0,
    dragging: false,
  }

  shouldComponentUpdate (nextProps: Props, nextState: State) {
    const condition = !this.props.equals(nextProps) || !this.state.dragging.equals(nextState.dragging)

    return condition
  }

  componentDidUpdate (prevProps: Props) {
    const {
      featureFlags,
      visualizationMetaInformation,
      appState,
      currentDashboard,
      viewsObject,
      currentSimpleDashboardTabIndex,
      currentDashboardWidth,
    } = this.props
    const isTreeViewActive = currentSimpleDashboardTabIndex === 0

    if (isTreeViewActive && currentDashboardWidth !== 280) {
      this.handleSetWidth(280)
    }

    // TODO: this needs to be reworked, !== doesnt work for objects, it will always be true, but for now it works
    // leaving this so because the resize functionality will be changed soon, then we could change this also
    const dashboardChanged = this.props.currentDashboard !== prevProps.currentDashboard
    const tabChanged = this.props.currentSimpleDashboardTabIndex !== prevProps.currentSimpleDashboardTabIndex

    if (dashboardChanged || tabChanged) {
      if (isTreeViewActive) {
        return
      }

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
          const viewObject = viewsObject[viewId as string]

          this.handleSetWidth(viewObject?.dashboards?.[dashboardId]?.width ?? 500)
        })
      })

      if (!FeatureFlags.canResizeCasterDashboards(featureFlags, visualizationMetaInformation, appState)) {
        return
      }

      const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

      if (panel1) {
        document.addEventListener('pointerover', this.handleHoverPseudoElement, false)
        panel1.addEventListener('pointerdown', this.handleSetResize, false)
      }
    }
  }

  handleHoverPseudoElement = (event: any) => {
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (!panel1) {
      return
    }

    const dragging = Number(panel1.style.getPropertyValue('--dragging'))

    // only hover on pseudo element width
    if (Math.abs(panel1.offsetWidth - event.offsetX) <= 4) {
      panel1.style.setProperty(
        '--dragging',
        '1',
      )
    }
    else if (dragging) {
      panel1.style.setProperty('--dragging', '0')
    }
  }

  handleSetResize =(event: any) => {
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (Math.abs(panel1.offsetWidth - event.offsetX) <= 4) {
      this.setState({ mousePosition: event.x })

      document.addEventListener('pointermove', this.handleResize, false)
      document.addEventListener('pointerup', this.handleMouseUp, false)
    }
  }

  handleSetWidth = (width: number) => {
    const { setCurrentDashboardWidth, handleResizeCasterTree } = this.props
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (panel) {
      panel.style.width = `${width}px`
    }

    if (panel1) {
      panel1.style.width = `${width}px`
    }

    handleResizeCasterTree(width)
    setCurrentDashboardWidth(width)
  }

  handleResize = (event: any) => {
    // TODO: only resizable if mousedown and mpusemove?
    const { handleResizeCasterTree } = this.props
    const maxDashboardWidth = window.innerWidth - 390
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    if (panel && panel1) {
      const isInRange = event.x >= 500 && event.x <= maxDashboardWidth
      const isNotInRange = event.x <= 500 || event.x >= maxDashboardWidth
      const isUnderRange = event.x <= 500

      const dashboardWidth = isInRange
        ? parseInt(getComputedStyle(panel1, '').width) - (this.state.mousePosition - event.x)
        : isUnderRange ? 500 : maxDashboardWidth

      panel.style.width = `${dashboardWidth}px`
      panel1.style.width = `${dashboardWidth}px`
      handleResizeCasterTree(dashboardWidth)

      if (isNotInRange) {
        this.setState({ currentPanelWidth: this.convertToMultipleOf16(dashboardWidth), dragging: true })

        return
      }

      this.setState({
        currentPanelWidth: this.convertToMultipleOf16(dashboardWidth),
        mousePosition: event.x,
        dragging: true,
      })
    }
  }

  handleMouseUp = () => {
    if (!this.state.dragging) {
      return
    }

    document.removeEventListener('pointermove', this.handleResize, false)

    const { currentDashboard, viewsObject, setDashboardObject, setCurrentDashboardWidth } = this.props
    const panel = document.getElementById('DashboardWrapper') as HTMLElement
    const panel1 = document.getElementById('CustomResizableElement') as HTMLElement

    this.setState({ dragging: false })

    panel.style.width = `${this.state.currentPanelWidth}px`
    panel1.style.width = `${this.state.currentPanelWidth}px`
    setCurrentDashboardWidth(this.state.currentPanelWidth)

    document.removeEventListener('pointerup', this.handleMouseUp, false)

    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]

    if (!viewObject) {
      return
    }

    setDashboardObject(
      viewId as string,
      {
        ...viewObject.dashboards[dashboardId],
        width: this.state.currentPanelWidth,
      },
      dashboardId,
    )
  }

  handleSelect = (event: KeyboardEvent<EventTarget>, elementPath: string) => {
    const { selectedMultiEditElements } = this.props || {}

    selectedMultiEditElements(elementPath, (event.ctrlKey || event.metaKey), event.shiftKey)
  };

  handleScrollToTop = () => {
    const element: any = document.getElementById('scroll')

    element.scrollTop = 0
  };

  convertToMultipleOf16 (value: number) {
    const newValue = Math.round(value / 16) * 16

    if (newValue < 500) {
      return 500
    }

    return newValue
  }

  render () {
    const {
      rootData,
      openDialogs,
      loadingStatus,
      t,
      currentSimpleDashboardTabIndex,
      selectedElements,
      selectedMultiEditElements,
      term,
      setTerm,
      setTarget,
      target,
      currentDashboardWidth,
      featureFlags,
      visualizationMetaInformation,
      appState,
    } = this.props

    const elementsHashes = {
      AirLoop: this.props.AirLoop,
      CoolingLoop: this.props.CoolingLoop,
      CoolingZone: this.props.CoolingZone,
      LoopAssignment: this.props.LoopAssignment,
      Nozzle: this.props.Nozzle,
      Roller: this.props.Roller,
      RollerBearing: this.props.RollerBearing,
      RollerBody: this.props.RollerBody,
      SupportPoint: this.props.SupportPoint,
      SegmentGroupSupportPoints: this.props.SegmentGroupSupportPoints,
      Segment: this.props.Segment,
      SegmentGroup: this.props.SegmentGroup,
      SensorPoint: this.props.SensorPoint,
      StrandGuide: this.props.StrandGuide,
      DataPoint: this.props.DataPoint,
      DataLine: this.props.DataLine,
    }

    const isOpen = openDialogs.includes('CasterTree')
    const isBig = currentSimpleDashboardTabIndex > 0
    const maxDashboardWidth = window.innerWidth - 390
    const canResize = FeatureFlags.canResizeCasterDashboards(featureFlags, visualizationMetaInformation, appState)

    let content = null

    if (loadingStatus) {
      content = <TreePlaceholder />
    }
    else if (!(rootData && rootData.Caster)) {
      content = (
        <NoFile>
          {t('treeView.openCaster')}
        </NoFile>
      )
    }
    else if (!FeatureFlags.canViewTreeView(featureFlags)) {
      content = (
        <NoFile>
          Please load a dashboard
        </NoFile>
      )
    }
    else {
      content = (
        <Scroll id='scroll'>
          <TreeView
            selectedElements={selectedElements}
            elementsHashes={elementsHashes}
            name='SegmentGroup'
            onSelect={this.handleSelect}
            setTerm={setTerm}
            selectedMultiEditElements={selectedMultiEditElements}
            term={term}
            setTarget={setTarget}
            target={target}
            openDialogs={openDialogs}
          />
        </Scroll>
      )
    }

    return (
      <CasterTreeView
        isOpen={isOpen}
        isBig={isBig}
        canResize={canResize}
        id='CustomResizableElement'
        dashboardWidth={currentDashboardWidth}
        maxDashboardWidth={maxDashboardWidth}
        dragging={this.state.dragging}
      >
        <CasterOptions onScrollToTop={this.handleScrollToTop} />
        <CasterTreeContent defaultContent={content} />
        <CurrentDashboardWidth visible={this.state.dragging}>
          {this.state.currentPanelWidth}
        </CurrentDashboardWidth>
      </CasterTreeView>
    )
  }
}

export default withNamespaces('caster')(connector(CasterTree as any) as any) as any
