import styled from 'styled-components'

export default styled.div`
  display:    inline-block;
  position:   relative;
  width:      100%;
  height:     100%;
  overflow-y: auto;
  overflow-x: hidden;
  
  ::-webkit-scrollbar {
    width:  6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button {
    width:  0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: #666666;
    border:     none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #666666;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #666666;
  }

  ::-webkit-scrollbar-track {
    background: #444444;
    border:     none;
  }

  ::-webkit-scrollbar-track:hover {
    background: #444444;
  }

  ::-webkit-scrollbar-track:active {
    background: #444444;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`
