import React, { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import FeatureFlags from '../../FeatureFlags'
import Section from '../Section'
import FormBuilder from '../form/FormBuilder'
import DropDown from '../form/DropDown'
import { PARENT, CHILDREN } from './consts'
import { DEFINITION } from '../../../store/type/consts'
import GlobalUtil from '../../../logic/Util'
import ThreeUtil from '../../../three/logic/Util'

import { SectionContainer, SectionContent } from './styles'

import DataActions from '../../../store/data/actions'
import * as FilterActions from '../../../store/filter/actions'
import { DefaultState } from 'types/state'
import { getCurrentDashboardEntry } from 'App/util'
import { getChangedPaths, getSupportPointPaths } from 'store/type/SupportPointDefinition'

const connector = connect((state: DefaultState) => ({
  rootData: state.data.rootData,
  editElements: state.data.editElements,
  editElementsInitial: state.data.editElementsInitial,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  editValues: state.data.editValues,
  filterElement: state.filter.filterElement,
  term: state.filter.term,
  pendingDeleteList: state.data.pendingDeleteList,
  hasEditChanges: state.data.hasEditChanges,
  featureFlags: state.application.main.authenticationData.featureFlags,
  loopCounter: state.data.loopCounter,
  currentDashboard: state.visualization.currentDashboard,
  viewsObject: state.visualization.viewsObject,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  AirLoop: state.AirLoop,
  CoolingLoop: state.CoolingLoop,
  CoolingZone: state.CoolingZone,
  LoopAssignment: state.LoopAssignment,
  Nozzle: state.Nozzle,
  Roller: state.Roller,
  RollerBearing: state.RollerBearing,
  RollerBody: state.RollerBody,
  SupportPoint: state.SupportPoint,
  SegmentGroupSupportPoints: state.SupportPoint,
  Segment: state.Segment,
  SegmentGroup: state.SegmentGroup,
  SensorPoint: state.SensorPoint,
  StrandGuide: state.StrandGuide,
  DataPoint: state.DataPoint,
  DataLine: state.DataLine,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  resetGapWarnings: DataActions.resetGapWarnings,
  saveElement: DataActions.saveElement,
  createElement: DataActions.createElement,
  validateInput: DataActions.validateInput,
  addDirtyPath: DataActions.addDirtyPath,
  setElements: DataActions.setElements,
  setParentPath: DataActions.setParentPath,
  addDirtyDeletePath: DataActions.addDirtyDeletePath,
  addPendingDeleteList: DataActions.addPendingDeleteList,
  copyElement: DataActions.copyElement,
  setEditChanges: DataActions.setEditChanges,
  setEditValues: DataActions.setEditValues,
  repeatElement: DataActions.repeatElement,
  undoChanges: DataActions.undoChanges,
  setAdditionalData: DataActions.setAdditionalData,
  setFilterValues: FilterActions.setFilterValues,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  paths: string[]
  allPaths?: string[]
  type: CasterDialogElementType,
  hideActions?: boolean,
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedTarget: string,
  copyMode: string,
  newNozzle: any,
  selectedParentIds: Record<string, number>,
  initialParentIds: any,
  defaultValues: Record<string, Record<string, any> | undefined>,
  multiParentValue: string[],
  offset: number,
  gapOffset: number,
  copies: number,
  direction: string
  [key: string]: any,
};

export class AllInOne extends PureComponent<Props, State> {
  timeoutHandle?: NodeJS.Timeout;

  state = {
    selectedTarget: '',
    copyMode: 'offset',
    newNozzle: {},
    selectedParentIds: {} as Record<string, number>,
    initialParentIds: {},
    defaultValues: {} as Record<string, Record<string, any>>,
    multiParentValue: new Array<string>(),
    offset: 0,
    gapOffset: 1,
    copies: 0,
    direction: 'positive',
  }

  constructor (props: Props) {
    super(props)
    const {
      paths,
      dirtyDeletePaths,
      setEditChanges,
      type,
      filterElement,
      editValues,
      setFilterValues,
      setEditValues,
      rootData,
    } = props

    let selectedParentIds = {}

    if (!paths.length) {
      this.state = {
        ...this.state,
        selectedParentIds: {},
        initialParentIds: {},
        multiParentValue: [],
      }
    }
    else if (paths.length === 1) {
      const parents = paths[0].split('/')

      for (let i = 0; i < parents.length - 1; i++) {
        const [ type, id ] = parents[i].split(':')

        selectedParentIds = {
          ...selectedParentIds,
          [type]: Number(id),
        }
      }

      this.state = {
        ...this.state,
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue: [],
      }
    }
    else {
      const segmentGroupIds: any = []
      const segmentSideIds: any = []

      paths.forEach(path => {
        const pathArray = GlobalUtil.getPathArrayFromPath(path)

        if (!segmentGroupIds.includes(pathArray[1])) {
          segmentGroupIds.push(pathArray[1])
        }

        if (!segmentSideIds.includes(pathArray[3])) {
          segmentSideIds.push(pathArray[3])
        }
      })

      const multiParentValue = []

      if (segmentGroupIds.length !== 1) {
        multiParentValue.push('SegmentGroup')
        multiParentValue.push('Segment')
      }
      else if (segmentSideIds.length !== 1) {
        multiParentValue.push('Segment')
      }

      selectedParentIds = {
        ...selectedParentIds,
        SegmentGroup: segmentGroupIds[0],
        Segment: segmentSideIds[0],
      }

      this.state = {
        ...this.state,
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue,
      }
    }

    if (paths.some(path => dirtyDeletePaths.includes(path))) {
      setEditChanges(true, type)
    }

    const definition = DEFINITION[type]

    let defaultValues: Record<string, any> = {}

    Object.keys(definition.fields).forEach(key => {
      defaultValues = {
        ...defaultValues,
        [type]: {
          ...defaultValues[type],
          [key]: definition.fields[key].defaultValue,
        },
      }
    })

    this.state = {
      ...this.state,
      defaultValues,
    }

    const element = {
      ...filterElement,
      ...editValues,
      ...defaultValues,
    }

    if (type === 'General') {
      Object.keys(definition.fields).forEach(key => {
        (element as any)[type][key] = (rootData.Caster.Mold as any)[key] // TODO: review
      })
    }

    setFilterValues({ ...element })
    setEditValues({ ...element })
  }

  componentDidUpdate (prevProps: Props) {
    const { paths: _oldPaths, allPaths: oldAllPaths } = prevProps
    const {
      paths: newPaths,
      allPaths,
      editElements,
      dirtyDeletePaths,
      editElementsInitial,
      type,
      setEditChanges,
      filterElement,
      hasEditChanges,
    } = this.props
    const { copies, initialParentIds, selectedParentIds } = this.state

    if (allPaths && newPaths[0] !== allPaths[0]) {
      return
    }

    let elementsAreChanged = false

    const paths = allPaths ?? newPaths
    const oldPaths = oldAllPaths ?? _oldPaths

    const areSomePathsDirty = paths.some(path => dirtyDeletePaths.includes(path))
    const didParentsChange = !initialParentIds.equals(selectedParentIds)

    const realType = type === 'SegmentGroupSupportPoints' ? 'SupportPoint' : type
    // TODO: reset or going back to the initial state does not work!?

    for (const path in editElements) {
      if (paths.includes(path) && path.includes(realType)) {
        const initialElement = editElementsInitial[path]
        const initial = initialElement ? initialElement.equals(editElements[path]) : true

        if (!initial || areSomePathsDirty || copies || didParentsChange) {
          elementsAreChanged = true

          break
        }
      }
    }

    if (paths && oldPaths && typeof paths === 'object' && !paths.equals(oldPaths)) {
      this.handleSelectInitialParents(paths)
      this.setState({
        offset: 0,
        copies: 0,
      })

      elementsAreChanged = areSomePathsDirty
    }

    if (!paths.length) {
      if (filterElement[type] && Object.keys(filterElement[type]).length) {
        elementsAreChanged = true
      }
    }

    // TODO: refactor expression
    if (
      ((hasEditChanges || {}) as Record<string, any>)[type] !== elementsAreChanged
    ) {
      setEditChanges(elementsAreChanged, type)
    }
  }

  handleDeleteElement = () => {
    const {
      type,
      addDirtyPath,
      addDirtyDeletePath,
      editElements,
      paths,
      dirtyDeletePaths,
      setEditChanges,
      addPendingDeleteList,
      pendingDeleteList,
    } = this.props

    const elementsHashes = {
      AirLoop: this.props.AirLoop,
      CoolingLoop: this.props.CoolingLoop,
      CoolingZone: this.props.CoolingZone,
      LoopAssignment: this.props.LoopAssignment,
      Nozzle: this.props.Nozzle,
      Roller: this.props.Roller,
      RollerBearing: this.props.RollerBearing,
      RollerBody: this.props.RollerBody,
      SupportPoint: this.props.SupportPoint,
      SegmentGroupSupportPoints: this.props.SupportPoint,
      Segment: this.props.Segment,
      SegmentGroup: this.props.SegmentGroup,
      SensorPoint: this.props.SensorPoint,
      StrandGuide: this.props.StrandGuide,
      DataPoint: this.props.DataPoint,
      DataLine: this.props.DataLine,
    }
    const dirtyPaths: string[] = [ ...paths ]
    const pendingList: string[] = []

    paths.forEach((path: string) => {
      if (!(pendingDeleteList.includes(path))) { // TODO: lists could be hashes to improve performance
        pendingList.push(path)
      }
    })

    if (type === 'RollerBody' || type === 'RollerBearing') {
      paths.forEach((path: string) => {
        const parentPath = ThreeUtil.getParentInfo(path).path
        const parentRoller = GlobalUtil.getElement(parentPath, elementsHashes)
        const parentIndexOfDirtyDelete = ~dirtyDeletePaths.indexOf(parentPath)
        const parentIndexOfDirty = ~dirtyPaths.indexOf(parentPath)
        let allChildren = GlobalUtil.RollerChildren(parentRoller, parentPath)

        allChildren = allChildren.filter((childPath: string) => {
          const indexOfDirtyDelete = ~dirtyDeletePaths.indexOf(childPath)
          const indexOfDirty = ~dirtyPaths.indexOf(childPath)

          if (indexOfDirtyDelete && indexOfDirty) {
            return true
          }

          return !(indexOfDirtyDelete || indexOfDirty)
        })

        if (!allChildren.length && !parentIndexOfDirtyDelete && !parentIndexOfDirty) {
          dirtyPaths.push(parentPath)
        }

        if (allChildren.length && parentIndexOfDirtyDelete) {
          dirtyPaths.push(parentPath)
        }
      })
    }

    if (type === 'Roller') {
      paths.forEach((path: string) => {
        const indexOfDirtyDelete = ~dirtyDeletePaths.indexOf(path)
        let allChildren = GlobalUtil.RollerChildren(editElements[path], path)

        allChildren = allChildren.filter((childPath) => {
          const childIndexOfDirtyDelete = ~dirtyDeletePaths.indexOf(childPath)

          if (indexOfDirtyDelete && childIndexOfDirtyDelete) {
            return true
          }

          return !indexOfDirtyDelete && !childIndexOfDirtyDelete
        })

        dirtyPaths.push(...allChildren)
      })
    }

    addDirtyPath(dirtyPaths)
    addDirtyDeletePath(dirtyPaths)
    addPendingDeleteList(pendingList)
    setEditChanges(true, type)
  }

  handleSave = (commentsByTypeAndKey?: {[type: string]: {[key: string]: string}}) => {
    const {
      paths,
      allPaths,
      saveElement,
      setEditChanges,
      type,
      setEditValues,
      setAdditionalData,
      editValues,
      pendingDeleteList,
      hasEditChanges,
    } = this.props

    const target = this.getParentsArray()

    if (hasEditChanges && hasEditChanges[type as CasterElementNames]) {
      setEditChanges(false, type)
    }

    if (type === 'General') {
      setTimeout(() => {
        setAdditionalData({ Mold: editValues[type] })
      }, 1000)
    }
    else {
      const typeReg = new RegExp(type, 'g')
      const deleteList = pendingDeleteList.filter((deletePath: any) => typeReg.test(deletePath))

      const action = deleteList.length > 0 ? 'delete' : 'update'

      saveElement(allPaths ?? paths, target, action, type, commentsByTypeAndKey)
      setEditValues({ [type]: {} })
    }
  }

  handleInput = (event: any) => {
    const {
      setElements,
      paths,
      setFilterValues,
      filterElement,
      setTerm,
      term,
      type,
      setEditChanges,
      setEditValues,
      editValues,
      editElements,
      validateInput,
      featureFlags,
      loopCounter,
      hasEditChanges,
      currentSimulationCase,
      SupportPoint,
      SegmentGroup,
    } = this.props
    const { name, value } = event.target
    const path = paths[0]
    const massValues = paths.length > 1

    const typePart = (term || '').split(' ').filter((part: any) => part.indexOf(type) > -1) || []
    const isFilterActive = type && typePart.length && typePart.join(' ').indexOf(`${name.substring(1)}`) > -1

    if (isFilterActive) {
      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle)
      }

      this.timeoutHandle = setTimeout(() => {
        if (value) {
          setTerm(`${type}#${name.substring(1)}=${value}`, false, true)
        }
        else {
        // TODO: this is a workaround to prevent a bug! But it's also not a too bad way to handle this case
          setTerm(`${type}#${name.substring(1)}=`, false, true)
        }
      }, 250)
    }

    if (!path) {
      if (type === 'General') {
        setEditChanges(true, type)
      }

      let filterElementCopy = {
        ...filterElement[type],
        ...editValues[type],
      }

      filterElementCopy[name] = value

      if (name !== '_name') {
        if (value === 'conic' || parseInt(value, 10) === 0 || (typeof value === 'string' && !value.length)) {
          delete filterElementCopy[name]
        }
      }
      else if (!value.length) {
        delete filterElementCopy[name]
      }

      if (type === 'Nozzle' && FeatureFlags.canEditNozzle(featureFlags) && !currentSimulationCase.simulationStartedAt) {
        if (name === '_passln_coord' && value !== 0) {
          filterElementCopy = {
            ...filterElementCopy,
            _height: filterElementCopy._height || 200,
            _anglelength: filterElementCopy._anglelength || 120,
            _anglewidth: filterElementCopy._anglewidth || 120,
          }
        }

        if (name === '_loop') {
          const waterFluxFraction = loopCounter[Math.abs(Number(value))]
            ? (1 / (loopCounter[Math.abs(Number(value))] + 1) || 1)
            : 1

          filterElementCopy = {
            ...filterElementCopy,
            _loop: Math.abs(Number(value)),
            // eslint-disable-next-line camelcase
            _water_flux_fraction: waterFluxFraction.toFixed(4) || 0,
          }
        }
      }

      setFilterValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })

      setEditValues({
        [type]: {
          ...filterElementCopy,
        },
      })

      validateInput(type)

      return
    }

    if (!hasEditChanges[type]) {
      setEditChanges(true, type)
    }

    if (massValues) {
      setElements(paths, { [name]: value })
    }
    else {
      setElements(path, { [name]: value })
    }

    if (!massValues && path && type === 'SegmentGroupSupportPoints' && name === '_shim_propose') {
      const info = ThreeUtil.getElementInfo(path)
      const element = { _id: info.id, '#parent': ThreeUtil.getParentInfo(path) }
      const newEditElements = { ...editElements, [path]: { [name]: value } }
      const changedPaths = getChangedPaths(element, newEditElements, SegmentGroup, SupportPoint)
      const supportPointPaths = getSupportPointPaths(element, SegmentGroup)

      if (changedPaths.length === 3) {
        for (const supportPointPath of supportPointPaths) {
          if (!changedPaths.includes(supportPointPath)) {
            // eslint-disable-next-line camelcase
            setElements(supportPointPath, { _shim_propose: null })
          }
        }
      }
    }
  }

  handlePatternInput = (event: any) => {
    const { resetGapWarnings } = this.props
    const { name, value: originalValue } = event.target
    let value = originalValue

    if (name === 'gapOffset' && Number(value) < 1) {
      value = 1
    }

    this.setState({
      [name]: value,
    })

    resetGapWarnings()
  }

  handleUndo = (event: any) => {
    const {
      undoChanges,
      setFilterValues,
      setEditValues,
      filterElement,
      type,
      setEditChanges,
      hasEditChanges,
    } = this.props
    const { defaultValues } = this.state
    const name = event.target.value

    if (name) {
      const filterElementCopy = { ...filterElement[type] }

      filterElementCopy[name] = defaultValues[type][name]

      undoChanges(name, type)
      setFilterValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })

      setEditValues({
        ...filterElement,
        [type]: {
          ...filterElementCopy,
        },
      })
    }
    else {
      undoChanges(null, type)

      setFilterValues({
        ...filterElement,
        [type]: {
          ...defaultValues[type],
        },
      })

      setEditValues({
        [type]: {
          ...defaultValues[type],
        },
      })

      this.setState({
        multiParentValue: [],
        selectedParentIds: {},
      })

      if (hasEditChanges[type]) {
        setEditChanges(false, type)
      }
    }
  }

  handlePatternUndo = (event: any) => {
    const { value } = event.target
    let val: number | string = 0

    switch (value) {
      case 'copyMode':
        val = 'offset'
        break
      case 'direction':
        val = 'positive'
        break
      case 'gapOffset':
        val = 1
        break
      default:
    }

    this.setState({
      [value]: val,
    })
  }

  handleMirrorElements = () => {
    const { paths, repeatElement, type } = this.props
    const target = this.getParentsArray()

    repeatElement(paths, target, type as CasterElementNames, 0, 0, 'mirror')
  }

  handleRepeatElements = () => {
    const { paths, repeatElement, type } = this.props
    const { copyMode, offset, gapOffset, copies, direction } = this.state
    const off = copyMode === 'offset' ? offset : gapOffset
    const offsetWithDirection = direction === 'positive' ? Math.abs(off) : -Math.abs(off)
    const target = this.getParentsArray()

    repeatElement(paths, target, type as CasterElementNames, copies, offsetWithDirection, copyMode)
  }

  handleCreateOrClone = () => {
    const {
      paths,
      copyElement,
      filterElement,
      createElement,
      type,
      setEditChanges,
    } = this.props
    const { copies } = this.state

    const target = this.getParentsArray()

    setEditChanges(false, type)

    if (paths && paths.length) {
      copyElement(paths, target, type as CasterElementNames)

      if (copies) {
        this.handleRepeatElements()
      }
    }
    else {
      createElement(filterElement, target, type)
    }
  }

  handleSelectInitialParents = (paths: Array<any>) => {
    let selectedParentIds = {}

    if (!paths.length) {
      this.setState({
        selectedParentIds: {},
        initialParentIds: {},
        multiParentValue: [],
      })
    }

    if (paths.length === 1) {
      const parents = paths[0].split('/')

      for (let i = 0; i < parents.length - 1; i++) {
        const [ type, id ] = parents[i].split(':')

        selectedParentIds = {
          ...selectedParentIds,
          [type]: Number(id),
        }
      }

      this.setState({
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue: [],
      })
    }

    if (paths.length > 1) {
      const segmentGroupIds: any = []
      const segmentSideIds: any = []

      paths.forEach(path => {
        const pathArray = GlobalUtil.getPathArrayFromPath(path)

        if (!segmentGroupIds.includes(pathArray[1])) {
          segmentGroupIds.push(pathArray[1])
        }

        if (!segmentSideIds.includes(pathArray[3])) {
          segmentSideIds.push(pathArray[3])
        }
      })

      const multiParentValue = []

      if (segmentGroupIds.length !== 1) {
        multiParentValue.push('SegmentGroup')
        multiParentValue.push('Segment')
      }
      else if (segmentSideIds.length !== 1) {
        multiParentValue.push('Segment')
      }

      selectedParentIds = {
        ...selectedParentIds,
        SegmentGroup: segmentGroupIds[0],
        Segment: segmentSideIds[0],
      }

      this.setState({
        selectedParentIds,
        initialParentIds: selectedParentIds,
        multiParentValue,
      })
    }
  }

  handleChildren = (children: CasterElementNames[], selectedParentIds: string[]) => {
    children.forEach(child => {
      delete (selectedParentIds as any)[child]

      if (CHILDREN[child].length) {
        this.handleChildren(CHILDREN[child] as any, selectedParentIds)
      }
    })
  }

  handleParentChange = (event: any) => {
    const { setEditChanges, setParentPath, type, setEditValues, editValues, validateInput } = this.props
    const { selectedParentIds: stateSelectedParentIds, multiParentValue } = this.state

    const selectedParentIds = { ...stateSelectedParentIds }

    selectedParentIds[event.target.name] = Number(event.target.value)

    this.handleChildren((CHILDREN as any)[event.target.name], selectedParentIds as any)

    this.setState({
      selectedParentIds: {
        ...selectedParentIds,
      },
      multiParentValue: [
        ...multiParentValue.filter((parent: any) => !Object.keys(selectedParentIds).includes(parent)),
      ],
    })

    setParentPath(selectedParentIds)
    const editPath = {
      [type]: {
        ...editValues[type],
        parentPath: {
          ...selectedParentIds,
        },
      },
    }

    setEditValues(editPath)
    setEditChanges(true, type)
    validateInput(type)
  }

  getParentsArray = () => {
    const { selectedParentIds, multiParentValue } = this.state

    return Object.keys(selectedParentIds).reduce((list: any, key: string) => {
      if (!multiParentValue.includes(key)) {
        return [
          ...list,
          key,
              `${selectedParentIds[key]}`,
        ]
      }

      return [ ...list ]
    }, [])
  }

  getParents = () => {
    const { type } = this.props
    const elementsHashes = {
      AirLoop: this.props.AirLoop,
      CoolingLoop: this.props.CoolingLoop,
      CoolingZone: this.props.CoolingZone,
      LoopAssignment: this.props.LoopAssignment,
      Nozzle: this.props.Nozzle,
      Roller: this.props.Roller,
      RollerBearing: this.props.RollerBearing,
      RollerBody: this.props.RollerBody,
      SupportPoint: this.props.SupportPoint,
      SegmentGroupSupportPoints: this.props.SupportPoint,
      Segment: this.props.Segment,
      SegmentGroup: this.props.SegmentGroup,
      SensorPoint: this.props.SensorPoint,
      StrandGuide: this.props.StrandGuide,
      DataPoint: this.props.DataPoint,
      DataLine: this.props.DataLine,
    }
    const { StrandGuide } = elementsHashes
    const { selectedParentIds } = this.state

    let selectedParent = StrandGuide[1] || {}
    let parents: Array<any> = []
    let currentType: CasterElementNames | null | undefined = type as CasterElementNames

    while (currentType && PARENT[currentType]) {
      parents.push(PARENT[currentType])
      currentType = PARENT[currentType]
    }

    parents = parents.reverse().map<any>((parentType: CasterElementNames) => {
      const ids = selectedParent && (selectedParent as any)[`#${parentType}Ids`] &&
      ((selectedParent as any)[`#${parentType}Ids`] || []).length
        ? (selectedParent as any)[`#${parentType}Ids`].map((id: number) => ({
          key: id,
          value: parentType === 'Segment'
            ? elementsHashes.Segment[id]?._FixedLooseSide
            : `${parentType === 'SegmentGroup' ? 'SG' : parentType}:${id}`,
        }))
        : []

      selectedParent = selectedParentIds[parentType] !== undefined &&
      selectedParent && (selectedParent as any)[`#${parentType}Ids`]
        ? elementsHashes[parentType][selectedParentIds[parentType]]
        : {}

      return {
        type: parentType,
        ids,
      }
    })

    return parents
  }

  render () {
    const { selectedParentIds, multiParentValue, direction, offset, copies, copyMode, gapOffset } = this.state
    const {
      type,
      paths,
      allPaths,
      hideActions,
      filterElement,
      editValues,
      featureFlags,
      t,
      viewsObject,
      amountOfComparisonCasterColumns,
      currentDashboard,
    } = this.props
    const parents = this.getParents()
    const target = this.getParentsArray()

    const len = paths && paths.length

    // TODO: this does not work with selected Segments or SegmentGroups
    const segments = len ? [ ...Array.from(new Set(paths.map(path =>
      path.split('/').slice(0, 2).join('/')))) ].length : 0
    const segmentGroups = len ? [ ...Array.from(new Set(paths.map(path =>
      path.split('/').slice(0, 1).join('/')))) ].length : 0

    const elementType = len ? paths[0].split('/').slice(-1)[0].split(':')[0].toLowerCase() : ''
    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const isComparingCasters = Boolean(viewObject?.selectedComparisonCasters?.length) &&
      amountOfComparisonCasterColumns > 0

    return (
      <div>
        {
          type !== 'General' && type !== 'SegmentGroupSupportPoints' &&
            <SectionContainer>
              <Section name={t('allInOne.selection.title')} title={t('allInOne.selection.tooltip')}>
                <SectionContent>
                  {
                    t('allInOne.selectedValues', {
                      selected: len ? `${len} ${type}${len > 1 ? 's' : ''}` : t('allInOne.nothing'),
                    })
                  }
                  {
                    len === 1
                      ? ` (${t(`allInOne.types.${elementType}`)}: ${paths[0].split('/').slice(-1)[0].split(':')[1]})`
                      : ''
                  }
                  {
                    len > 0 && type !== 'DataPoint' && type !== 'DataLine' && type !== 'SegmentGroup' &&
                    <div>
                      {
                        parents.length < 2 && t('allInOne.selectedInSG', {
                          segmentGroups,
                          segmentGroupS: segmentGroups > 1 ? 's' : '',
                          segments,
                          segmentS: segments > 1 ? 's' : '',
                        })
                      }
                      {
                        parents.length > 1 && t('allInOne.selected', {
                          segmentGroups,
                          segmentGroupS: segmentGroups > 1 ? 's' : '',
                          segments,
                          segmentS: segments > 1 ? 's' : '',
                        })
                      }
                    </div>
                  }
                </SectionContent>
              </Section>
              {
                ![
                  'SensorPoint', 'DataLine', 'DataPoint', 'SegmentGroup', 'SupportPoint', 'SegmentGroupSupportPoints',
                ].includes(type) &&
                  <Section name={t('allInOne.parents.title')} title={t('allInOne.parents.tooltip')}>
                    {
                      parents.map(parent =>
                        <DropDown
                          key={parent.type}
                          values={parent.ids}
                          value={
                            multiParentValue.includes(parent.type)
                              ? t('allInOne.multiValue')
                              : (selectedParentIds as any)[parent.type] // TODO: Fix type
                          }
                          label={parent.type}
                          onChange={this.handleParentChange}
                          multiValues={multiParentValue.includes(parent.type)}
                          disabled={
                            (
                              !FeatureFlags.canEditNozzle(featureFlags) &&
                              !FeatureFlags.canEditRoller(featureFlags) &&
                              paths[0]
                            ) ||
                            isComparingCasters
                          }
                        />)
                    }
                  </Section>
              }
            </SectionContainer>
        }
        <FormBuilder
          type={type}
          path={paths[0]}
          paths={paths}
          allPaths={allPaths}
          hideActions={hideActions}
          target={target}
          // TODO: fix type
          filterValue={(editValues as any)[type] || (filterElement as any)[type] || {}}
          selectedParentIds={selectedParentIds}
          onSave={this.handleSave}
          onCreateOrCopy={this.handleCreateOrClone}
          massForm={paths.length > 1}
          onInput={this.handleInput}
          onDeleteElement={this.handleDeleteElement}
          onUndo={this.handleUndo}
          direction={direction}
          onPatternInput={this.handlePatternInput}
          onMirrorElements={this.handleMirrorElements}
          onPatternUndo={this.handlePatternUndo}
          copies={copies}
          copyMode={copyMode}
          offset={offset}
          gapOffset={gapOffset}
          onPatternApply={this.handleRepeatElements}
          isComparingCasters={isComparingCasters}
        />
      </div>
    )
  }
}

export default withNamespaces('caster')(connector(AllInOne as any) as any) as any
