import styled, { css } from 'styled-components'

export const FromField = styled.div`${() => css`
  margin:         13px 5px;
  height:         18px;
  display:        flex;
`}`

export const FromCompareCasterField = styled.div`${() => css`
  margin:         13px 5px;
  height:         18px;
  display:        flex;
`}`

export const FormLabel = styled.label`${() => css`
  display:          inline-block;
  text-align:       left;
  color:            #CCCCCC;
  font-size:        13px;
  align-self:       center;
  flex:             1;
  overflow:         hidden;
  user-select: none;
`}`

export const InputForm = styled.input`${(disabled) => css`
  flex:          1;
  padding:       3px 17px 3px 3px;
  outline:       none;
  background:    #22282e;
  border:        0;
  color:         #CCCCCC;
  border-bottom: solid 1px #CCCCCC;
  text-align:    right;
  font-size:     13px;
  max-width:     100px;
  height:        21px;
  ${disabled && 'user-select: none;'}

  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
  }
`}`

export const Selector = styled.select<{
  children:any
  placeholder: string
  onChange: (e:any)=>void
  disabled?: boolean
  title: string
  value: string | number
  name: string
}>`${({ disabled }) => css`
  display:         inline-block;
  outline:         none;
  background:      #22282e;
  padding-bottom:  1px;
  color:           #CCCCCC;
  border:          none;
  border-bottom:   ${disabled ? 'initial;' : 'solid 1px #CCCCCC;'}; 
  text-align:      right;
  font-size:       13px;
  height:          21px;
  flex:            1;
  max-width:       100px;
  text-align-last: right;
  ${disabled && 'user-select: none;'}
`}`

export const NumberSelector = styled.div<{disabled?:boolean}>`${({ disabled }) => css`
  display:   inline-grid;
  position:  relative;
  top: -6px;
  font-size: 8px;
  ${disabled && 'user-select: none;'}

  ::placeholder {
    text-align: right;
  }
`}`

export const HiddenButton = styled.button<{disabled?:boolean}>`${({ disabled }) => css`
  border:           none;
  height:           10px;
  background-color: transparent;
  color:            ${disabled ? '#777777' : 'inherit'};
  ${!disabled && 'cursor: pointer;'}

  &:hover {
    height: 10px;
    ${!disabled && 'color: #CCCCCC;'}
  }
  
  &:focus {
    outline: 0;
  }
`}`

export const InlineButton = styled.button<{active?:boolean, disabled?:boolean}>`${({ active, disabled }) => css`
  border:           none;
  background-color: transparent;
  padding-left:     0;
  color:            inherit;
  cursor:           pointer;
  
  &:hover {
    height: 10px;
    color:  #CCCCCC;
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    color:          #777777;
    pointer-events: none;
  }

  svg {
    pointer-events: none;
  }
`}`
