import { Props } from 'react/context/form/FormBuilder'

import { SupportPoint } from './SupportPointDefinition'

export function getCategoryName ({ path, editElements }: Props) {
  const categoryLabel = path.substring(path.lastIndexOf('/') + 1)
  const { _name } = editElements?.[path] ?? {}

  if (!_name) {
    return categoryLabel
  }

  return `${_name} - ${categoryLabel}`
}

const VISIBLE_FIELDS = [ '_shim_min', '_shim_actual', '_shim_propose', '_shim_max' ]

/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SegmentGroupSupportPoints = {
  categories: {
    1: getCategoryName,
  },
  fields: {
    ...Object.keys(SupportPoint.fields).reduce((fields, key) => {
      const field = SupportPoint.fields[key]

      return { ...fields, [key]: { ...field, hidden: !VISIBLE_FIELDS.includes(key) } }
    }, {}),
  },
}
/* eslint-enable key-spacing */
