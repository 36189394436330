export enum ccElementActionsEnum {
  ACTION_SET_CC_ELEMENTS = 'CCElement/ACTION_SET_CC_ELEMENTS',
  DELETE = 'CCElement/DELETE',
  DELETE_MULTIPLE = 'CCElement/DELETE_MULTIPLE',
  UPDATE = 'CCElement/UPDATE',
  RESET = 'CCElement/RESET'
}

export function setCCElements (ccElementHash: CCElementHash) {
  return {
    type: ccElementActionsEnum.ACTION_SET_CC_ELEMENTS,
    ccElementHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ccElementReducers: Record<string, Function | undefined> = {
  [ccElementActionsEnum.ACTION_SET_CC_ELEMENTS]:
  (state: CCElementHash, action: { ccElementHash: CCElementHash }) => {
    return {
      ...action.ccElementHash,
    }
  },

  [ccElementActionsEnum.DELETE]: (state: CCElementHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [ccElementActionsEnum.DELETE_MULTIPLE]: (state: CCElementHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [ccElementActionsEnum.UPDATE]: (state: CCElementHash, action: {elements: CCElementHash}) => {
    return {
      ...state,
      ...action.elements,
    }
  },
}

export default function (state: CCElementHash = {}, action: any) {
  const handler = ccElementReducers[action.type]

  return handler ? handler(state, action) : state
}
