import cloneDeep from 'clone-deep'
import { getElementFromPath } from 'store/elements/logic/index'
import FilterHandler from 'three/logic/FilterHandler'
import type { ElementsHashes } from 'types/state'
import type { PlotConfig } from 'types/visualization'
import { AnalyzeTime } from 'Util'
import Plotly from 'plotly.js'

export class ViewLogic {
  @AnalyzeTime(0)
  static getFilteredElements (elementsHashes: ElementsHashes, filter: string) {
    const filteredElements = FilterHandler
      .getFilteredElements(elementsHashes, filter, false) as Record<string, FilterableElementType>
    const elementList = Object.entries(filteredElements)
      .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
      .map(([ path ]) => path)

    return elementList
  }

  @AnalyzeTime(0)
  static getAllElements (elementsHashes: ElementsHashes) {
    const allElements = FilterHandler.getAllElements(elementsHashes.SegmentGroup, elementsHashes)
    const elementListAll = (allElements || [])
      .map(element => element.path)

    return elementListAll
  }

  @AnalyzeTime(0)
  static getDynamicElementsFromConfig (elementsHashes: ElementsHashes, config: PlotConfig, filteredElementCache: any) {
    const elementsType = !config.filter
      ? (config.elements.length ? 'ConfigElements' : 'AllElements')
      : 'FilteredElements'

    let elements: string[]

    switch (elementsType) {
      case 'AllElements':
        elements = filteredElementCache[elementsType] || ViewLogic.getAllElements(elementsHashes)

        filteredElementCache[elementsType] = elements
        break
      case 'FilteredElements':
        elements = filteredElementCache[config.filter] || ViewLogic.getFilteredElements(elementsHashes, config.filter)

        filteredElementCache[config.filter] = elements
        break
      case 'ConfigElements':
        elements = config.elements
        break
    }

    return elements
  }

  static getDynamicDataFromDataLines (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
    fallback = true,
    hasRef = false,
  ) {
    const rawData = elements.length > 0
      ? elements.filter(path => path.includes('DataLine'))
        .map((path: string) => getElementFromPath(path, elementsHashes))
      : (fallback ? Object.values((elementsHashes as any)[type] || {}) : [])

    const dynamicData = []

    for (const el of rawData) {
      const xValues = (el || {})[attrX]
      const yValues = (el || {})[attrY]
      const parsedXValues = attrX === '_xCoords'
        ? xValues
        : typeof xValues === 'string'
          ? xValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
          : []
      const parsedYValues = attrY === '_xCoords'
        ? yValues
        : typeof yValues === 'string'
          ? yValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
          : []

      if (
        Array.isArray(parsedXValues) &&
        parsedXValues.length > 1 &&
        Array.isArray(parsedYValues) &&
        parsedYValues.length > 1
      ) {
        const line: {x:number, y:number}[] = []
        const amountOfPoints = Math.min(parsedXValues.length, parsedYValues.length)

        for (let i = 0; i < amountOfPoints; i++) {
          const x = parsedXValues[i]
          const y = parsedYValues[i]

          if (x !== undefined && y !== undefined) {
            line.push({ x, y })
          }
        }

        if (line.length) {
          if (hasRef) {
            return line
          }

          dynamicData.push(line)
        }
      }
    }

    return dynamicData
  }

  @AnalyzeTime(0)
  static checkDynamicDataFromElements (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
  ) {
    const rawData = elements.length > 0
      ? elements.map((path: string) => getElementFromPath(path, elementsHashes))
      : Object.values((elementsHashes as any)[type])

    const hasX = !!rawData.find((el: any) => (el || {})[attrX] !== undefined)
    const hasY = !!rawData.find((el: any) => (el || {})[attrY] !== undefined)

    return { hasX, hasY }
  }

  @AnalyzeTime(0)
  static getDynamicDataFromElements (
    elements: string[],
    elementsHashes: ElementsHashes,
    type: string,
    attrX: string,
    attrY: string,
    fallback = true,
  ) {
    const rawData = elements.length > 0
      ? elements.map((path: string) => getElementFromPath(path, elementsHashes))
      : (fallback ? Object.values((elementsHashes as any)[type]) : [])

    const dynamicData = []

    for (const el of rawData) {
      const x = Number((el || {})[attrX])
      const y = Number((el || {})[attrY])

      if (!isNaN(x) && !isNaN(y)) {
        dynamicData.push({ x, y })
      }
    }

    dynamicData.sort((a: any, b: any) => a.x - b.x)

    return dynamicData
  }

  static getElementsFromPaths (elementsHashes: ElementsHashes, pathsObject: string[]) {
    const paths = Object.keys(pathsObject)
    const elements = []

    for (const path of paths) {
      const element = getElementFromPath(path, elementsHashes)

      if (element) {
        elements.push(element)
      }
    }

    return elements
  }

  static getShapeDynamicData (elements: string[], elementsHashes: ElementsHashes, type: string, attrY: string) {
    const rawData = elements.map((path: string) => getElementFromPath(path, elementsHashes))

    const dynamicData = []

    for (const el of rawData) {
      const y = 0
      const x = Number((el || {})[attrY])

      if (!isNaN(x) && !isNaN(y)) {
        dynamicData.push({ x, y })
      }
    }

    dynamicData.sort((a: any, b: any) => a.x - b.x)

    return dynamicData
  }

  static isDynamicDataSourceWithCurrentPassLnCoordInFilter (plotConfig: PlotConfig) {
    const { group, selectedSet, filter } = plotConfig || {}

    return group === 'dynamicDataSource' && selectedSet === 'currentFilter' && filter?.includes('CurrentPassLnCoord')
  }

  static isDynamicDataSourceWithFilterControlVariableInFilter (
    plotConfig: PlotConfig,
    filterControlVariables: string[],
  ) {
    const { group, selectedSet, filter } = plotConfig || {}

    return (
      group === 'dynamicDataSource' &&
      selectedSet === 'currentFilter' &&
      Boolean(filter) &&
      filterControlVariables.some(variable => filter.includes(variable))
    )
  }

  static isMergedDynamicDataSourceWithFilterControlVariableInFilter (
    plotConfig: PlotConfig,
    filterControlVariables: string[],
  ) {
    // when deleting plot it could lead to an error, this is a fallback
    if (!plotConfig) {
      return false
    }

    return plotConfig.isMergedDynamicDataSource && plotConfig.configs.some((config: PlotConfig) =>
      ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(config, filterControlVariables))
  }

  static isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter (plotConfig: PlotConfig) {
    // when deleting plot it could lead to an error, this is a fallback
    if (!plotConfig) {
      return false
    }

    return plotConfig.isMergedDynamicDataSource && plotConfig.configs.some((config: PlotConfig) =>
      ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(config))
  }

  static getDataLineRanges (dynamicData: [{ x: number, y: number}][]) {
    let tempMaxX = 0
    let tempMinX = Infinity
    let tempMaxY = 0
    let tempMinY = Infinity
    const amountOfLines = dynamicData.length

    for (let i = 0; i < amountOfLines; i++) {
      const amountOfPoints = dynamicData[i].length
      const line = dynamicData[i]

      for (let j = 0; j < amountOfPoints; j++) {
        const point: {x:number, y:number} = line[j]

        if (point.x > tempMaxX) {
          tempMaxX = point.x
        }

        if (point.x < tempMinX) {
          tempMinX = point.x
        }

        if (point.y > tempMaxY) {
          tempMaxY = point.y
        }

        if (point.y < tempMinY) {
          tempMinY = point.y
        }
      }
    }

    return { x: [ tempMinX, tempMaxX ], y: [ tempMinY, tempMaxY ] }
  }

  // xValues should be sorted
  static getNearestValueSmallerThanX (x: number, xValues: number[], xIndex?: number): {value?: number, index?: number} {
    if (xIndex === undefined) {
      xIndex = xValues.length - 1
    }

    if (xIndex < 0) {
      return { value: undefined, index: undefined }
    }

    for (let i = xIndex; i >= 0; i--) {
      if (xValues[i] < x) {
        return { value: xValues[i], index: i }
      }
    }

    return { value: undefined, index: undefined }
  }

  static getNearestValueBiggerThanX (x: number, xValues: number[], xIndex?: number): {value?: number, index?: number} {
    if (xIndex === undefined) {
      xIndex = 0
    }

    if (xIndex >= xValues.length) {
      return { value: undefined, index: undefined }
    }

    for (let i = xIndex; i < xValues.length; i++) {
      if (xValues[i] > x) {
        return { value: xValues[i], index: i }
      }
    }

    return { value: undefined, index: undefined }
  }

  static clonePlotAndChangeFontColorToBlack (plot: any, tempDiv: any, imageHeight?: number, imageWidth?: number) {
    const plotData = cloneDeep(plot.data)
    const plotLayout = cloneDeep(plot.layout)

    if (imageHeight && imageWidth) {
      tempDiv.style.height = `${imageHeight}px`
      tempDiv.style.width = `${imageWidth}px`
    }

    // Modify the layout to set the background color to white
    // eslint-disable-next-line camelcase
    plotLayout.plot_bgcolor = 'white'
    // eslint-disable-next-line camelcase
    plotLayout.paper_bgcolor = 'white'

    // change the font color to black in every axis, and every legend
    for (const axis of [ 'xaxis', 'yaxis' ]) {
      if (plotLayout[axis]) {
        // eslint-disable-next-line camelcase
        plotLayout[axis].tickfont = { color: 'black' }
        // eslint-disable-next-line camelcase
        plotLayout[axis].titlefont = { color: 'black' }

        if (plotLayout[axis].title) {
          // eslint-disable-next-line camelcase
          plotLayout[axis].title.font = { color: 'black' }
        }
      }
    }

    // Create a new plotly chart with the copied data and modified layout
    return Plotly.newPlot(tempDiv, plotData, plotLayout)
  }
}
