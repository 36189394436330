import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import moment from 'moment'

import {
  StatusBarWrapper,
  StatusTile,
  Label,
  SecondLabel,
} from './styles'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect((state: DefaultState) => ({
  networkStatus: state.visualization.networkStatus,
  authenticationData: state.application.main.authenticationData,
}), {})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  time: moment.Moment
};

class StatusBar extends Component<Props, State> {
  interval?: number;

  state = {
    time: moment(),
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.interval = window.setInterval(() => {
      this.setState({
        time: moment(),
      })
    }, 1000)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    clearInterval(this.interval)
  }

  @AnalyzeTime(0)
  render () {
    const {
      networkStatus,
      authenticationData,
      t,
    } = this.props
    const { time } = this.state

    return (
      <StatusBarWrapper>
        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-display1' />
            <Label>
              {t('dashboard.mode.heading')}
            </Label>
            <SecondLabel color='#18b497'>
              {t('dashboard.mode.secondHeading')}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-clock' />
            <Label>
              {time.format('HH:mm')}
            </Label>
            <SecondLabel color='#1d94e3'>
              {time.format('DD/MM/YYYY')}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-id' />
            <Label>
              {t('dashboard.user.label')}
            </Label>
            <SecondLabel color='#1d94e3'>
              {(authenticationData || {}).name}
            </SecondLabel>
          </div>
        </StatusTile>

        <StatusTile>
          <div style={{ width: '100%' }}>
            <i className='pe-7s-global' />
            <Label>
              {t('dashboard.server.label')}
            </Label>
            <SecondLabel color={networkStatus === 'connected' ? '#18b497' : '#e84c3d'}>
              {networkStatus}
            </SecondLabel>
          </div>
        </StatusTile>
      </StatusBarWrapper>
    )
  }
}

export default withNamespaces('visualization')(connector(StatusBar))
