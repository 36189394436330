import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import styled from 'styled-components'

import IpcManager from '../../IpcManager'
import FeatureFlags from '../../react/FeatureFlags'
import { AppState } from 'store/application/main/consts'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const DragWrapper = styled.div`
  flex: 8;
`

const DragBar = styled.div`
  -webkit-app-region: drag;
  height: 100%;
  z-index: 1;
`

const Title = styled.div`
  -webkit-app-region: no-drag;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  max-width: 32%;
  z-index: 2;
`

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  authenticationData: state.application.main.authenticationData,
  savePath: state.util.savePath,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
  hasChanges: state.data.hasChanges,
}), {
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

class ApplicationTitle extends Component<Props> {
  @AnalyzeTime(0)
  componentDidUpdate () {
    const { hasChanges } = this.props

    window.hasChanges = hasChanges
    window.saveChangesFunction = () => IpcManager.send('closeApplication', hasChanges)
  }

  @AnalyzeTime(0)
  render () {
    const {
      appState,
      savePath,
      hasChanges,
      isLoggedIn,
      featureFlags,
      currentSimulationCase,
      authenticationData,
    } = this.props

    if (isLoggedIn && FeatureFlags.canViewCaster(featureFlags)) {
      return <DragWrapper><DragBar /></DragWrapper>
    }

    const parts = (currentSimulationCase || {}).casterFileName || (savePath || '').split('/')
    const path = parts[parts.length - 1]

    const name = appState !== AppState.Caster
      ? 'Visualization'
      : `${!(authenticationData && authenticationData.name) && hasChanges
            ? '*'
            : ''}Caster ${path ? `~ ${path}` : ''}`

    return (
      <DragWrapper>
        <DragBar />
        <Title title={path || ''}>
          {name}
        </Title>
      </DragWrapper>
    )
  }
}

export default withNamespaces('titlebar')(connector(ApplicationTitle))
