import { DataActionsEnum } from '../consts'

const parseReducers = {
  [DataActionsEnum.ACTION_PARSE_XML_DATA]: (state: DataState, { rootData, hasChanges, loopCounter }: any) => ({
    ...state,
    rootData,
    hasChanges,
    loopCounter,
  }),

  [DataActionsEnum.ACTION_PARSE_JSON_DATA]: (state: DataState, action: any) => ({
    ...state,
    parsedJsonData: action.parsedJsonData,
  }),
}

export default parseReducers
