import PhantomObject from 'three/objects/PhantomObject'
import { AnalyzeTime } from 'Util'
import MainView from '.'
import FeatureFlags from '../../../react/FeatureFlags'
import Util from '../../logic/Util'

export default class PhantomElementHandler {
  @AnalyzeTime(0)
  static setPhantomData (view: MainView, data: any) {
    const { editValues, editElements, featureFlags, selectedDataArray, createValid } = data

    if (!featureFlags || !selectedDataArray.length || selectedDataArray.length >= 20) {
      // reset
      Object.values(view.phantomElementList).forEach((elementType: any) => {
        for (const key in elementType) {
          if (elementType[key] && elementType[key].dispose) {
            elementType[key].dispose(view.scene)
          } // TODO: make sure it works

          delete elementType[key]
        }
      })

      return
    }
    else if (selectedDataArray.length < 20) {
      Object.values(view.phantomElementList).forEach((elementType: any) => {
        for (const key in elementType) {
          if (!selectedDataArray.includes(key)) {
            if (elementType[key].dispose) {
              elementType[key].dispose()
            }

            delete elementType[key]
          }
        }
      })
    }

    // create new phantom
    for (const selectedPath of selectedDataArray) {
      const { type } = Util.getElementInfo(selectedPath)

      if (Util.isPhantom(type, view.sectionDetail) && editElements[selectedPath]) {
        PhantomElementHandler.handlePhantom(view, selectedPath, type, editElements[selectedPath])
      }
    }

    const keys = Object.keys(editValues)

    keys.forEach(type => {
      const { parentPath } = editValues[type] || {}

      // handle new element
      if (createValid[type] && parentPath && FeatureFlags.canEditElement(type, featureFlags)) {
        const parentKeys = Object.keys(parentPath) || []
        let path = ''

        if (parentKeys.length > 1) {
          for (let i = 0; i < parentKeys.length; i++) {
            path = `${path.length > 0 ? `${path}/` : path}${parentKeys[i]}:${parentPath[parentKeys[i]]}`
          }

          const { type: parentType } = Util.getElementInfo(path)

          if (view.elementList && view.elementList[parentType] && view.elementList[parentType][path]) {
            path = `${path}/${type}:new`

            PhantomElementHandler.handlePhantom(view, path, type, editValues[type], true)
          }
        }
      }
      else {
        if (view.phantomElementList[type] && view.phantomElementList[type].new) {
          view.phantomElementList[type].new.hide()
        }
      }
    })
  }

  @AnalyzeTime(0)
  static handlePhantom (view: MainView, path: string, type: string, element: any, newElement = false) {
    if (!(Object.keys(view.elementList).length > 0)) {
      return
    }

    const pathOrNew = newElement ? 'new' : path

    const phantomElement =
      view.phantomElementList && view.phantomElementList[type] && view.phantomElementList[type][pathOrNew]
        ? view.phantomElementList[type][pathOrNew]
        : PhantomObject.createPhantomObject(type, path, view.elementList, view.phantoms, newElement)

    if (!phantomElement) {
      return
    }

    phantomElement.setValues(
      element,
      view.plHeight,
      path,
      Boolean(~(view.hideList || []).indexOf(path)), // isDeleted
      false, // isHidden
      0, // Nozzle: loopIndex
      view.sectionDetail,
      true, // isPhantom
    )

    phantomElement.show()

    if (!view.phantomElementList[type]) {
      view.phantomElementList[type] = {}
    }

    view.phantomElementList[type][pathOrNew] = phantomElement

    if (newElement) {
      const { type, id } = Util.getParentInfo(path)
      const { _FixedLooseSide } = (view.elementsHashes as any)[type][id] || {}

      phantomElement.container.userData.side = _FixedLooseSide

      if ((phantomElement as any).updateTransform) {
        (phantomElement as any).updateTransform()
      }
    }
    else {
      phantomElement.container.userData.side = view.elementList[type][pathOrNew].container.userData.side
    }
  }
}
