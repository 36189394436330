
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'

import Button from '../../components/Button'
import { Form } from '../../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../../specific/Input'

import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from '../BaseDialog'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'

import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'
import FeatureFlags from 'react/FeatureFlags'

const PRE_TRANS = 'editSimulationCaseDialog'

const connector = connect((state: DefaultState) => ({
  editSimulationCaseId: state.application.main.editSimulationCaseId,
  currentProject: state.application.main.currentProject,
  featureFlags: state.application.main.authenticationData.featureFlags,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setEditSimulationCaseId: ApplicationActions.setEditSimulationCaseId,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}
type State = {
  [key: string]: string | boolean
  name: string,
  description: string,
  error: string,
  loading: boolean
};

export class EditSimulationCaseDialog extends Component<Props, State> {
  static NAME = uuid()

  didInit = false;

  state = {
    name: '',
    description: '',
    error: '',
    loading: false,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    this.handleInit()

    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentDidUpdate () {
    this.handleInit()
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleInit = () => {
    const { editSimulationCaseId } = this.props

    if (!this.didInit && editSimulationCaseId) {
      this.didInit = true

      ApiClient
        .get(`${Network.URI}/simulation_case/${editSimulationCaseId}`)
        .then(({ simulationCase: { name, description } }) => {
          this.setState({
            name,
            description,
          })
        })
        .catch((response) => {
          const { error } = response

          this.setState(({
            error: error ? error.status : 'unknown',
            loading: false,
          }))
        })
    }
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { closeDialog, setEditSimulationCaseId } = this.props

    closeDialog(EditSimulationCaseDialog.NAME)
    setEditSimulationCaseId()
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { name, description } = this.state
    const { editSimulationCaseId, currentProject, setCurrentProject } = this.props

    if (name) {
      this.setState(({
        loading: true,
      }))

      ApiClient
        .patch(
          `${Network.URI}/simulation_case/${editSimulationCaseId || ''}`,
          { data: { name, description } },
        )
        .then(() => {
          const simulationCase: SimulationCase | undefined = currentProject.simulationCases
            .find(simulationCase => simulationCase._id === editSimulationCaseId) as SimulationCase

          if (simulationCase) {
            simulationCase.name = name
            simulationCase.description = description

            setCurrentProject(currentProject)
          }

          this.handleClose()
        })
        .catch((response) => {
          const { error } = response

          this.setState(({
            error: error ? error.status : 'unknown',
            loading: false,
          }))
        })
    }
  };

  @AnalyzeTime(0)
  render () {
    const { name, description, error, loading } = this.state
    const { t, featureFlags } = this.props
    const submitDisabled = !(name.length > 0 && description.length > 0)

    return (
      <BaseDialog
        title={t(`${PRE_TRANS}.title`)}
        icon='pe-7s-folder'
        header={t(`${PRE_TRANS}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t(`${PRE_TRANS}.name.label`)}
            title={t(`${PRE_TRANS}.name.label`)}
            placeholder={t(`${PRE_TRANS}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            disabled={!FeatureFlags.canRenameCase(featureFlags)}
          />
          <Input
            label={t(`${PRE_TRANS}.description.label`)}
            title={t(`${PRE_TRANS}.description.label`)}
            placeholder={t(`${PRE_TRANS}.description.placeholder`)}
            name='description'
            type='text'
            rows={8}
            value={description}
            onChange={this.handleInput}
            disabled={!FeatureFlags.canEditCaseDescription(featureFlags)}
          />
          <Button
            type='primary'
            title={submitDisabled ? t(`${PRE_TRANS}.save.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            isRef
          >
            {t(`${PRE_TRANS}.save.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t(`${PRE_TRANS}.cancel`)}
          >
            {t(`${PRE_TRANS}.cancel`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

export default withNamespaces('application')(connector(EditSimulationCaseDialog as any) as any) as any
