import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { AnalyzeTime } from 'Util'

const Wrapper = styled.div<{theme:any, size?:number}>`${({ theme, size }) => css`
  position: relative;
  ${size && css`font-size: ${size}px;`}
`}`

const Position = styled.div<{theme:any, x:number, y:number}>`${({ theme, x, y }) => css`
  display: inline-block;
  position: absolute;
  top: ${y}px;
  left: ${x}px;

  i {
    color: ${theme.colors.swatch19};
    cursor: help;
  }
`}`

const Text = styled.div`${({ theme }) => css`
  white-space: pre-wrap;
`}`

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: 425,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '0 0 5px 0 rgba(127,127,127,.5)',
  },
}))(Tooltip)

type Props = {
  placement?: 'bottom' |
  'left' |
  'right' |
  'top' |
  'bottom-end' |
  'bottom-start' |
  'left-end' |
  'left-start' |
  'right-end' |
  'right-start' |
  'top-end' |
  'top-start' |
  undefined,
  message: string,
  x: number,
  y: number,
  size?: number
};

export class InfoMarker extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { placement, message, x, y, size } = this.props

    return (
      <Wrapper size={size}>
        <Position x={x} y={y}>
          <StyledTooltip title={<Text>{message}</Text>} placement={placement || 'top'}>
            <i className='pe-7s-info' />
          </StyledTooltip>
        </Position>
      </Wrapper>
    )
  }
}

export default InfoMarker
