import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import onvedaLogo from '../../icon/onvedaLogo.png'

import {
  Loading,
  LoadingContainer,
  LoadingCircle,
  OuterCircle,
  InnerCircle,
  MiddleCircle,
  Ball,
  Title,
  Onveda,
} from './LoadingScreenStyles'
import { DefaultState } from 'types/state'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

type Props = {
  loadingStatus: boolean,
  t: Translation
};

export class LoadingScreen extends Component<Props> {
  @AnalyzeTime(0)
  render () {
    const { loadingStatus, t } = this.props

    return (
      <Loading>
        <LoadingContainer>
          <LoadingCircle>
            <Ball src='images/waiting-1.svg' alt='' />
            <InnerCircle src='images/waiting-2.svg' loadingStatus={loadingStatus} alt='' />
            <MiddleCircle src='images/waiting-3.svg' loadingStatus={loadingStatus} alt='' />
            <OuterCircle src='images/waiting-4.svg' loadingStatus={loadingStatus} alt='' />
          </LoadingCircle>
          <Title>{
            loadingStatus
              ? <div>{t('specific.loading.loadingText')}<span>.</span><span>.</span><span>.</span></div>
              : t('specific.loading.default')
          }
          </Title>
          <Onveda>
            {t('specific.loading.powered')}
            <img src={onvedaLogo} alt='Onveda' />
          </Onveda>
        </LoadingContainer>
      </Loading>
    )
  }
}

export default withNamespaces('caster')(connect(({ application } : DefaultState) => ({
  loadingStatus: application.main.loadingStatus,
}))(LoadingScreen as any)) as any
