import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import hotkeys from 'hotkeys-js'

import * as VisualizationActions from 'store/visualization/actions'

import { Dialog, TabsWrapper, Tab, DialogBackground, Header, Title, Form, I, Text } from '../DialogStyles'
import VisUtil from '../../../VisUtil'
import ApiClient from 'store/apiClient'
import { Network } from 'network/Network'
import { AppState } from 'store/application/main/consts'

import Commands from './Commands'
import EditBox from './EditBox'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization, application }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  addPlotViewId: visualization.addPlotViewId,
  currentDashboard: visualization.currentDashboard,
  viewsObject: visualization.viewsObject,
  data: visualization.data,
  appState: application.main.appState,
  currentSimulationCase: application.main.currentSimulationCase,
}), {
  addPlotTile: VisualizationActions.addPlotTile,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}
type State = {
  [key: string]: any
  configId: string | null,
  name: string,
  type: string | null,
  activeTab: number,
  shapeIds: Array<any>,
  editableFiles: Array<string>
};

class AddPlotDialog extends Component<Props, State> {
  state: State = {
    configId: null,
    name: 'New Plot',
    type: null,
    activeTab: 0,
    shapeIds: [],
    editableFiles: [],
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { currentSimulationCase } = this.props

    ApiClient
      .get(`${Network.URI}/simulation_case/editable_files/${currentSimulationCase._id}`)
      .then(({ files }) => this.setState({
        editableFiles: files,
      }))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})

    hotkeys('Escape', this.handleClose)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { showAddPlotDialog } = this.props

    showAddPlotDialog()
  };

  // @AnalyzeTime(0)
  handleSubmit = () => {
    const { configId: configIdRaw, name, type, shapeIds, editableFiles } = this.state
    const {
      appState,
      addPlotViewId,
      addPlotTile,
      showAddPlotDialog,
      plotConfigs,
      currentDashboard,
      currentSimulationCase,
    } = this.props

    const allEditableFiles = VisUtil.getEditableFileSelectors(editableFiles)
    const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase)
    const allFiles = [ ...allEditableFiles, ...allViewableFiles ]
    const configId = configIdRaw ||
      (appState === AppState.ResultDashboard ? Object.keys(plotConfigs || {})[0] : (allFiles[0] || {}).key)

    const group = (plotConfigs[configId] || {}).group ||
      ((editableFiles || []).includes(configId)
        ? 'Editable Files'
        : (allViewableFiles.findIndex(file => file.key === configId) >= 0 ? 'Viewable Files' : null)
      )

    const types = VisUtil.getTypeSelectors(group || '').map(type => type.key)

    const newType = type ? (types.includes(type) ? type : types[0]) : types[0]

    const isDynamicData = (plotConfigs[configId] || {}).group === 'dynamicDataSource'
    const isMergedDynamicData = (plotConfigs[configId] || {}).isMergedDynamicDataSource

    if (configId !== undefined) {
      addPlotTile(
        addPlotViewId,
        currentDashboard[addPlotViewId],
        {
          configId,
          name,
          type: newType,
          shapeIds,
          isDynamicData,
          isMergedDynamicData,
        },
      )
      showAddPlotDialog()
    }
  };

  // @AnalyzeTime(0)
  handleTabClick = (event: any) => {
    this.setState({
      activeTab: Number(event.target.id),
    })
  }

  @AnalyzeTime(0)
  render () {
    const {
      configId,
      name,
      type,
      editableFiles,
      activeTab,
    } = this.state

    const {
      plotConfigs,
      currentDashboard,
      viewsObject,
      addPlotViewId,
      t,
      data,
      currentSimulationCase,
      appState,
    } = this.props

    const configIds = Object.keys(plotConfigs || {})

    if (
      viewsObject[addPlotViewId] &&
      (viewsObject[addPlotViewId].dashboards[currentDashboard[addPlotViewId]] || {}).blockNewPlots
    ) {
      return (
        <div>
          <DialogBackground />
          <Dialog height='200px' half>
            <Header title={t('addPlotDialog.title')}>
              <Title>{t('addPlotDialog.label')}</Title>
              <I className='pe-7s-close' onClick={this.handleClose} />
            </Header>
            <Form>
              <Text>
                {t('addPlotDialog.message')}
              </Text>
            </Form>
          </Dialog>
        </div>
      )
    }

    const allConfigIds = appState === AppState.ResultDashboard
      ? VisUtil.getConfigSelectors(data, plotConfigs)
      : []
    const allEditableFiles = VisUtil.getEditableFileSelectors(editableFiles)
    const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase)
    const allFiles = [ ...allEditableFiles, ...allViewableFiles ]

    const allDataSources = configIds.reduce((acc: any[], configId: string) => {
      const config = plotConfigs[configId]

      if (config.group !== 'dynamicDataSource') {
        return acc
      }

      const { name, _id } = config
      const { value } = VisUtil.getConfigInfo(name, _id, _id, 'dynamicDataSource', config)

      return [
        ...acc,
        { group: 'DynamicData', key: configId, value },
      ]
    }, [])

    allDataSources.push(...configIds.reduce((acc: any[], configId: string) => {
      if (!plotConfigs[configId].isMergedDynamicDataSource) {
        return acc
      }

      return [
        ...acc,
        { group: 'DynamicData (Merged)', key: configId, value: plotConfigs[configId].name },
      ]
    }, []))

    const selectedId = configId ||
      (appState === AppState.ResultDashboard ? configIds[0] : (allFiles[0] || {}).key)
    const typeSelectors = plotConfigs
      ? VisUtil.getTypeSelectors((plotConfigs[selectedId] || allFiles.find(file => file.key === selectedId) ||
        plotConfigs[Object.keys(plotConfigs)[0]] || {}).group)
      : []

    const connectTabs = [
      { title: 'edit', icon: 'pe-7s-graph3' },
      { title: 'commands', icon: 'pe-7s-gleam' },
    ]

    // TODO: handle additionalYAxes if plot is merged
    return (
      <div>
        <DialogBackground />
        <Dialog height='auto' half>
          <Header title={t('addPlotDialog.title')}>
            <Title>
              {
                t(!activeTab
                  ? 'addPlotDialog.parameterDashboardLabel.editBox'
                  : 'addPlotDialog.parameterDashboardLabel.command')
              }
            </Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('addPlotDialog.close')}
            />
          </Header>
          {
            !activeTab
              ? (
                <EditBox
                  onInput={this.handleInput}
                  onSubmit={this.handleSubmit}
                  {...{ name, selectedId, allConfigIds, allFiles, allDataSources, typeSelectors, type }}
                />
              )
              : <Commands onClose={this.handleClose} />
          }
          <TabsWrapper>
            {
              connectTabs.map((tab, i) =>
                <Tab
                  id={i.toString()}
                  key={i}
                  title={t(`selectSourceDialog.tabs.${tab.title}`)}
                  activeTab={activeTab === i}
                  onClick={this.handleTabClick}
                >
                  <i className={tab.icon} />
                </Tab>)
            }
          </TabsWrapper>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(AddPlotDialog as any) as any) as any
