import Getters from './Getters'
import CalculationUtil from './CalculationUtil'
import UIView from '.'
import { Mesh } from 'three'
import { AnalyzeTime } from 'Util'
import type { ElementsHashes } from 'types/state'
import SectionView from '../SectionView'
import DrawHandlers from './DrawHandlers'
import ThreeManager from 'three/ThreeManager'
import FeatureFlags from 'react/FeatureFlags'

export default class UiViewHandlers {
  static getAmountOfJumpableElements (view: UIView) {
    return (
      Number(view.scrollableElements.Roller) +
      Number(view.scrollableElements.Nozzle) +
      Number(view.scrollableElements.DataPoint)
    )
  }

  static handleElementCount (view: UIView, elementsHashes: ElementsHashes) {
    const {
      nozzleCount,
      rollerCount,
      dataPointCount,
    } = Getters.getNozzleRollerAndDataPointCount(elementsHashes)

    if (!nozzleCount && !rollerCount && !dataPointCount) {
      UiViewHandlers.handleNoElements(view)
    }
    else {
      view.sectionViewDisabled = false

      view.scrollableElements.Roller = !!rollerCount && FeatureFlags.canJumpOverRollers(view.featureFlags)
      view.scrollableElements.Nozzle = !!nozzleCount && FeatureFlags.canJumpOverNozzles(view.featureFlags)
      view.scrollableElements.DataPoint = !!dataPointCount && FeatureFlags.canJumpOverDataPoints(view.featureFlags)

      const amountOfScrollableElements = UiViewHandlers.getAmountOfJumpableElements(view)

      if (
        view.views.sectionView &&
        view.views.sectionView.jumpOver !== null &&
        !view.scrollableElements[view.views.sectionView.jumpOver]
      ) {
        const nextElement = this.getNextScrollableElement(view.views.sectionView, view)

        UiViewHandlers.handleSwitchScrollElement(view, nextElement)
      }

      if (amountOfScrollableElements === 1) {
        view.switchScrollElementDisabled = true
      }
      else {
        view.switchScrollElementDisabled = false
      }
    }
  }

  static handleJumpToSection (view: UIView) {
    const mainView = view.views.mainView

    if (!mainView) {
      return
    }

    const currentSegmentGroupId = SectionView.currentSegmentGroup.id
    const currentSegmentGroup = mainView.elementsHashes.SegmentGroup[currentSegmentGroupId]

    if (!currentSegmentGroup) {
      return
    }

    const segmentIds: number[] = (currentSegmentGroup as any)['#SegmentIds']
    const segmentSideHash: any = {}

    // search for the looseSide segment first, if not found, search for the fixedSide segment, if not, any
    for (let i = 0; i < segmentIds.length; i++) {
      const segment: any = mainView.elementsHashes.Segment[segmentIds[i]]

      if (
        !segment ||
        (
          !(segment['#NozzleIds'] || []).length &&
          !(segment['#RollerIds'] || []).length &&
          !(segment['#SensorPointIds'] || []).length
        )
      ) {
        continue
      }

      segmentSideHash[segment?._FixedLooseSide] = segment._id
    }

    if (segmentSideHash.LooseSide) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash.LooseSide}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash.FixedSide) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash.FixedSide}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash.NarrowFaceLeft) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash.NarrowFaceLeft}`,
        undefined,
        true,
      )
    }
    else if (segmentSideHash.NarrowFaceRight) {
      ThreeManager.base.jumpToFilter(
        `SegmentGroup:${currentSegmentGroupId}/Segment:${segmentSideHash.NarrowFaceRight}`,
        undefined,
        true,
      )
    }
  }

  @AnalyzeTime(0)
  static handleNoElements (view: UIView) {
    view.sectionViewHandler(false)

    view.sectionViewDisabled = true
  }

  @AnalyzeTime(0)
  static updateButton (button: any, width: number, height: number) {
    const { align, x, y, width: bWidth, height: bHeight } = button.userData

    const posX = width / 2
    const posY = height / 2
    let dirX = 1
    let dirY = 1

    switch (align) {
      case 'tr': // top right
        break
      case 'tl': // top left
        dirX = -1
        break
      case 'br': // bottom right
        dirY = -1
        break
      case 'bl': // bottom left
        dirX = -1
        dirY = -1
        break
      default: break
    }

    const pos = CalculationUtil.calcUpdatedButtonPos(posX, posY, bWidth, bHeight, dirX, dirY, x, y)

    button.position.set(pos.x, pos.y, pos.z)
  }

  @AnalyzeTime(0)
  static hideSelectionRect (selectionRect: Mesh) {
    selectionRect.visible = false
    selectionRect.scale.x = 0.00001
    selectionRect.scale.y = 0.00001
  }

  @AnalyzeTime(0)
  static handleSwitchScrollElement (view: UIView, jumpOverOverride?: JumpOverOptions | null) {
    if (!view.views || !view.views.sectionView) {
      return
    }

    // Order: 1-Nozzle  2-Roller  3-DataPoint

    if (jumpOverOverride !== undefined) {
      view.views.sectionView.jumpOver = jumpOverOverride
    }
    else if (!view.switchScrollElementDisabled) {
      view.views.sectionView.jumpOver = this.getNextScrollableElement(view.views.sectionView, view)
    }

    view.views.sectionView?.updateMinAndMaxPasslineCoordinates()
    view.minPasslineCoord = view.views.sectionView.minPasslineCoord
    view.maxPasslineCoord = view.views.sectionView.maxPasslineCoord
    DrawHandlers.drawUpDownButtons(view)

    if (!view.views.sectionView.jumpOver) {
      return
    }

    view.switchScrollElement.material = view.buttonMaterials.jumpOver[view.views.sectionView.jumpOver]
  }

  static getNextScrollableElement (sectionView: SectionView, uiView: UIView) {
    if (!sectionView.jumpOver) {
      return null
    }

    const scrollableElements = uiView.scrollableElements
    const order = [ 'Roller', 'Nozzle', 'DataPoint' ]
    const currentIndex = order.indexOf(sectionView.jumpOver)

    let counter = 1

    while (counter < order.length) {
      const nextIndex = (currentIndex + counter) % order.length
      const nextElement = order[nextIndex] as JumpOverOptions

      if (scrollableElements[nextElement as JumpOverOptions]) {
        sectionView.sectionPlaneFolded.visible = true
        sectionView.sectionPlane.visible = true

        if (sectionView.views.mainView) {
          sectionView.views.mainView.sectionPlane.visible = true
        }

        return nextElement
      }

      counter++
    }

    return sectionView.jumpOver
  }

  @AnalyzeTime(0)
  static updateCamera (view: UIView, width: number, height: number) {
    if (!view.camera) {
      return
    }

    view.camera.left = width / -2
    view.camera.right = width / 2
    view.camera.top = height / 2
    view.camera.bottom = height / -2

    view.camera.updateProjectionMatrix()
  }
}
