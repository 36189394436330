import React, { Component } from 'react'
import FeatureFlags from 'react/FeatureFlags'
import InputComponent from 'react/specific/Input'
import { ArrayOfTranslations, Translation } from 'types/translation'
import ParameterComponent from './Parameter'
import StepComponent from './Step'

interface Props {
  stepIndex: number,
  step: Step,
  caseValues: Record<string, any>
  dynamicOptions: Record<string, Option[]>
  featureFlags: Record<string, boolean>
  handleInputChange: (event: Event & { target: HTMLInputElement }, stepNumber?: number) => void
  loading: boolean
  t: Translation & ArrayOfTranslations
  handleUploadFile: (id: string, stepIndex?: number) => void
  expanded: boolean
  handleExpandStep: (index: number) => void
  handleRunStep: (stepNumber: number) => void
  valid: boolean
  disabled: boolean
  lastStep: boolean
  getParameterByRef: (ref: string) => any
  onCaseOptionChange: (stepIndex: number, caseOptionIndex: number) => void
  getChosenCaseOption: (step: Step, stepIndex: number) => {
    chosenCaseOption: CaseOption | null
    selectors: { key: string, value: string, disabled?: boolean | undefined }[]
  }
}

class StepWithOptions extends Component<Props> {
  handleToggleStep = () => {
    const { handleExpandStep, stepIndex } = this.props

    handleExpandStep(stepIndex)
  }

  handleCaseOptionChange = (e: any) => {
    const { stepIndex, onCaseOptionChange, step } = this.props

    const optionIndex = step.caseOptions?.findIndex((option: any) => option.name === e.target.value) || 0

    onCaseOptionChange(stepIndex, optionIndex)
  }

  getParameterComponentByRef = (ref: string) => {
    const { getParameterByRef, caseValues, featureFlags, loading, t, dynamicOptions } = this.props
    const parameter = getParameterByRef(ref)

    if (!parameter) {
      return null
    }

    return (
      <ParameterComponent
        caseValues={caseValues}
        dynamicOptions={dynamicOptions}
        featureFlags={featureFlags || {}}
        loading={loading}
        parameter={parameter}
        t={t}
        handleInputChange={() => null}
        handleUploadFile={() => null}
        disabled
      />
    )
  }

  render () {
    const {
      step,
      stepIndex,
      dynamicOptions,
      caseValues,
      loading,
      t,
      featureFlags,
      expanded,
      valid,
      handleRunStep,
      disabled,
      lastStep,
      getParameterByRef,
      handleInputChange,
      handleUploadFile,
      getChosenCaseOption,
    } = this.props
    const { caseOptions, caseDropdownLabel = 'Case Selector' } = step

    if (!caseOptions) {
      return null
    }

    const { chosenCaseOption, selectors } = getChosenCaseOption(step, stepIndex)

    return (
      <div>
        <div
          onClick={this.handleToggleStep}
          style={{ color: '#a2a6a9', fontSize: '20px', marginTop: '20px', cursor: 'pointer' }}
        >
          {expanded ? <span>&#9662;</span> : <span>&#9656;</span>}
          <span style={{ marginLeft: '5px' }}>{step.name || `Step ${stepIndex}`}</span>
        </div>
        {
          expanded && (
            <>
              <InputComponent
                key={`${step.id}-caseOptions-Selector`}
                name={step.id}
                type='select'
                label={caseDropdownLabel}
                title={caseDropdownLabel}
                value={chosenCaseOption ? chosenCaseOption.name : 'default'}
                selectors={selectors}
                onChange={this.handleCaseOptionChange}
                disabled={disabled || loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
              />
              {
                chosenCaseOption
                  ? (
                    <StepComponent
                      handleRunStep={handleRunStep}
                      valid={valid}
                      disabled={disabled}
                      step={chosenCaseOption}
                      stepIndex={stepIndex}
                      key={`${step.id}-step`}
                      caseValues={caseValues}
                      dynamicOptions={dynamicOptions}
                      featureFlags={featureFlags || {}}
                      loading={loading}
                      t={t}
                      handleInputChange={handleInputChange}
                      handleUploadFile={handleUploadFile}
                      expanded
                      lastStep={lastStep}
                      getParameterByRef={getParameterByRef}
                    />
                  )
                  : null
              }
            </>
          )
        }
      </div>
    )
  }
}

export default StepWithOptions
