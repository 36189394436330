import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'

import PlotContextMenu from './PlotContextMenu'

import * as VisualizationActions from '../../../store/visualization/actions'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

export const ContextMenuWrapper = styled.div<{position: {x:number, y: number}}>`${({ theme, position }) => css`
  position: fixed;
  top: ${(position || {}).y || 0}px;
  left: ${(position || {}).x || 0}px;
  display: inline-block;
  z-index: 1000001;
  background-color: ${theme.colors.swatch3};
  z-index: 5000;
  border: 1px solid ${theme.colors.swatch6};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
`}`

export const ContextMenuItem = styled.div`${({ theme }) => css`
  width: 100%;
  line-height: 20px;
  color: ${theme.colors.swatch9};
  background-color: ${theme.colors.swatch3};
  padding: 3px 8px;
  cursor: pointer;
  user-select: none;

  :hover {
    color: ${theme.colors.swatch8};
    background-color: ${theme.colors.swatch6};
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.swatch6};
  }
`}`

const connector = connect((store: DefaultState) => ({
  contextMenu: store.visualization.contextMenu,
  isEditModeOn: store.visualization.isEditModeOn,
}), {
  closeContextMenu: VisualizationActions.closeContextMenu,
})

type PropsFromRedux = ConnectedProps<typeof connector>

class ContextMenu extends Component<PropsFromRedux> {
  @AnalyzeTime(0)
  componentDidMount () {
    document.addEventListener('pointerdown', this.handleBackgroundClick, true)
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    document.removeEventListener('pointerdown', this.handleBackgroundClick, true)
  }

  // @AnalyzeTime(0)
  handleBackgroundClick = (event: any) => {
    const { contextMenu, closeContextMenu } = this.props

    if (!contextMenu || !contextMenu.type || event.path[0].getAttribute('data-contextmenu')) {
      return
    }

    closeContextMenu()
  };

  @AnalyzeTime(0)
  render () {
    const { contextMenu, isEditModeOn } = this.props

    if (!isEditModeOn || !contextMenu || !contextMenu.type) {
      return null
    }

    switch (contextMenu.type) {
      case 'plot':
        return <PlotContextMenu data={contextMenu.data} />
      default:
        return null
    }
  }
}

export default connector(ContextMenu)
