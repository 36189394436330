import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { DefaultState } from 'types/state'
import Module from './Module'

const connector = connect((store: DefaultState) => ({
  modules: store.modules,
}), {
})

type PropsFromRedux = ConnectedProps<typeof connector>

export class Modules extends React.Component<PropsFromRedux> {
  render () {
    const { modules: modulesObject } = this.props
    const modules = Object.keys(modulesObject)

    return (
      <div>
        {
          modules.map(module => (
            <Module key={module} moduleName={module} />
          ))
        }
      </div>
    )
  }
}

export default (connector(Modules as any)) as any
