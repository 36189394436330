/* eslint-env browser */

import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'

import * as VisualizationActions from '../../../../../store/visualization/actions'

import IpcManager from '../../../../../IpcManager'

import Input from '../../../../specific/Input'
import {
  Dialog,
  DialogBackground,
  I,
  Header,
  Title,
  Form,
  Button,
  Text,
} from '../DialogStyles'

import Logic from './Logic'

import { DefaultState } from 'types/state'

const connector = connect((state: DefaultState) => ({
  tileConfigs: state.visualization.tileConfigs,
  plotConfigs: state.visualization.plotConfigs,
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
}), {
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  handleClosePlotExportDialog: () => void // TODO: remove, not being used
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  exportType: 'zip' | 'pdf'
};

class PlotExportDialog extends Component<Props, State> {
  state: State = {
    exportType: 'zip',
  }

  // @AnalyzeTime(0)
  handlePlotExport = (_e?: any) => {
    const { exportType } = this.state
    const {
      appState,
      tileConfigs,
      plotConfigs,
      currentProject,
      currentSimulationCase,
      visualizationMetaInformation,
    } = this.props

    Logic
      .handleExport(exportType, tileConfigs, plotConfigs)
      .then(exportData => {
        IpcManager.both.send(
          'plotExportImages',
          exportData,
          exportType,
          currentProject,
          currentSimulationCase,
          (visualizationMetaInformation || {})[appState] || {},
        )

        this.handleClose() // TODO: call this function when export is done
      })
  };

  // @AnalyzeTime(0)
  handleClose = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog(false)
  };

  // @AnalyzeTime(0)
  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  // @AnalyzeTime(0)
  render () {
    const { t } = this.props
    const { exportType } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog height='auto' half>
          <Header title={t('plotExportDialog.label')}>
            <Title>{t('plotExportDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('plotExportDialog.close')}
            />
          </Header>
          <Form>
            <Text>{t('plotExportDialog.message')}</Text>
            <br />
            <Input
              label={t('plotExportDialog.export.label')}
              title={t('plotExportDialog.export.title')}
              name='exportType'
              type='select'
              value={exportType}
              selectors={[ 'zip', 'pdf' ]}
              onChange={this.handleInput}
              data-tile
            />
            <Button
              onClick={this.handlePlotExport}
              title={t('plotExportDialog.apply.title')}
            >
              {t('plotExportDialog.apply.label')}
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

export default withNamespaces('visualization')(connector(PlotExportDialog as any) as any) as any
