import ApiClient from '../../../../store/apiClient'
import { Network } from '../../../../network/Network'
import VisUtil from '../../VisUtil'
import { Translation } from 'types/translation'
import { AnalyzeTime } from 'Util'

export type Props = {
  tileId: string,
  configId: string,
  currentSimulationCase: SimulationCase,
  onChildState: any, // TODO: remove
  t: Translation,
  enqueueSnackbar: enqueueSnackbar
};

export type State = {
  content: string,
  editContent: string,
  change: any,
  isEditing: boolean,
  savePending: boolean,
  loading: boolean
};

export const TRANS = 'editBoxWrapper'

export default class Logic {
  @AnalyzeTime(0)
  static getContent (filePath: string, simulationCaseId: string): Promise<any> {
    return ApiClient
      .get(`${Network.URI}/simulation_case/editable_file/${simulationCaseId}`, { params: { filePath } })
  }

  @AnalyzeTime(0)
  static patchContent (filePath: string, simulationCaseId: string, content: string): Promise<any> {
    return ApiClient
      .patch(`${Network.URI}/simulation_case/editable_file/${simulationCaseId}`, { data: { content, filePath } })
  }

  @AnalyzeTime(0)
  static isFrozen (simulationCase: any): boolean {
    return simulationCase && simulationCase.simulationStartedAt
  }

  @AnalyzeTime(0)
  static isOnlyViewable (simulationCase: any, configId: string): boolean {
    return VisUtil
      .getViewableFileSelectors(simulationCase)
      .findIndex(selector => selector.key === configId) >= 0
  }
}
