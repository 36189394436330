import * as THREE from 'three'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Stats from 'stats-js'

import Util from './logic/Util'
import TextureUtil from './logic/TextureUtil'

import MainView from './views/MainView'
import SectionView from './views/SectionView'
import AxisView from './views/AxisView'
import ViewsView from './views/ViewsView'
import UIView from './views/UIView'
// import { AnalyzeTime } from 'Util'
import { PassedData } from 'react/Caster'
import UiViewHandlers from './views/UIView/UiViewHandlers'

export type Views = {
  mainView?: MainView
  sectionView?: SectionView
  axisView?: AxisView
  viewsView?: ViewsView
  uiView?: UIView
}

export default class ThreeBase {
  static actualFPS = 0

  isInitialized?: boolean
  skipRendering = false
  fpsTarget?: number
  rate?: number
  stats?: any
  views: any
  renderer?: THREE.WebGLRenderer
  bgColor?: string | number
  container?: any
  width?: number
  height?: number
  lastFrame?: number
  frameId?: number

  constructor () {
    this.isInitialized = false
    this.fpsTarget = 60

    function calcNormal (normals: any, normal: any, angle: number) {
      const allowed = normals.filter((n: any) => n.angleTo(normal) < angle * Util.RAD)

      return allowed.length
        ? allowed.reduce((a: any, b: any) => a.clone().add(b)).normalize()
        : new THREE.Vector3(0, 1, 0)
    }

    (THREE.GeometryUtils as any).computeVertexNormals = function (geometry: any, angle: number) {
      geometry.computeFaceNormals()

      // TODO: maybe we can use a shader instead of this, since the graphics card could be better at this (math)!?

      const vertices = geometry.vertices.map(() => [])

      geometry.faces.forEach((face: any) => {
        vertices[face.a].push(face.normal)
        vertices[face.b].push(face.normal)
        vertices[face.c].push(face.normal)
      })

      geometry.faces.forEach((face: any) => {
        face.vertexNormals[0] = calcNormal(vertices[face.a], face.normal, angle)
        face.vertexNormals[1] = calcNormal(vertices[face.b], face.normal, angle)
        face.vertexNormals[2] = calcNormal(vertices[face.c], face.normal, angle)
      })

      if (geometry.faces.length > 0) {
        geometry.normalsNeedUpdate = true
      }
    }
  }

  // @AnalyzeTime(0)
  kill = () => {
    if (this.stats && this.stats.dom) {
      this.container.removeChild(this.stats.dom)
    }
  }

  // @AnalyzeTime(0)
  init = (container: any) => {
    this.isInitialized = true

    this.container = container

    this.width = -1
    this.height = -1

    // const start = Date.now()

    this.rate = 1000 / (this.fpsTarget || 1)
    this.lastFrame = 0
    this.skipRendering = false
    // this.lastFrameTime = start

    this.renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'high-performance' })
    this.renderer.autoClear = false
    this.bgColor = 0x000000
    this.renderer.setClearColor(this.bgColor)

    TextureUtil.init()

    this.views = {}
    this.views.mainView = new MainView(this.renderer, this.views)
    this.views.sectionView = new SectionView(this.renderer, this.views)
    this.views.axisView = new AxisView(this.renderer, this.views)
    this.views.viewsView = new ViewsView(this.renderer, this.views)
    this.views.uiView = new UIView(this.renderer, this.views)

    this.resize()

    this.container.appendChild(this.renderer.domElement)

    if (window.meta.DEV) {
      this.stats = new Stats()

      this.stats.dom.style.position = 'absolute' // TODO: verify that works, changed domElement to dom
      this.stats.dom.style.left = '0px'
      this.stats.dom.style.bottom = '0px'

      this.container.appendChild(this.stats.dom)
    }
    else {
      this.stats = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        begin: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        end: () => {},
      }
    }

    setTimeout(this.start, 1)
  };

  updateSegments = (segmentPaths: number[], newName: string) => {
    this.views?.mainView?.updateSegments(segmentPaths, newName)
  }

  // @AnalyzeTime(0)
  setData = (data: PassedData) => { // TODO: verify if only change is mouse position
    this.views?.sectionView?.setData(data)
    this.views?.mainView?.setData(data)
    this.views?.uiView?.setData(data)
  };

  // @AnalyzeTime(0)
  mount = () => {
    window.addEventListener('resize', this.resize, false)

    window.addEventListener('pointerdown', this.handleMouseDown, false)
    window.addEventListener('pointerup', this.handleMouseUp, false)
    window.addEventListener('pointermove', this.handleMouseMove, false)

    window.addEventListener('keydown', this.handleKeyDown, false)
    window.addEventListener('keyup', this.handleKeyUp, false)
  };

  // @AnalyzeTime(0)
  unmount = () => {
    this.stop()

    window.removeEventListener('resize', this.resize, false)

    window.removeEventListener('pointerdown', this.handleMouseDown, false)
    window.removeEventListener('pointerup', this.handleMouseUp, false)
    window.removeEventListener('pointermove', this.handleMouseMove, false)

    window.removeEventListener('keydown', this.handleKeyDown, false)
    window.removeEventListener('keyup', this.handleKeyUp, false)

    this.container.removeChild(this.renderer?.domElement)

    Object.values(this.views || {}).forEach((view: any) => view.unmount())
    Object.keys(this.views || {}).forEach(key => {
      delete this.views[key]
    })
  };

  // @AnalyzeTime(0)
  resize = () => {
    if (this.container) {
      const { width = 0, height = 0 } = this
      const { clientWidth, clientHeight } = this.container

      if (width > 0 && height > 0 && clientWidth === width && clientHeight === height) {
        return
      }

      this.width = clientWidth
      this.height = clientHeight

      this.rate = 1000 / (this.fpsTarget || 1)

      Object.values(this.views || {}).forEach((view: any) => view.resize(clientWidth, clientHeight))

      this.renderer?.setSize(clientWidth, clientHeight)

      this.renderScene()
    }
  };

  // @AnalyzeTime(0)
  handleMouseDown = (event: any) => {
    const { clientX, clientY } = event
    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views || {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseDown(event, mouseOnCanvas) === false) {
        break
      }
    }
  };

  // @AnalyzeTime(0)
  handleMouseUp = (event: any) => {
    const { clientX, clientY } = event
    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views || {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseUp(event, mouseOnCanvas) === false) {
        break
      }
    }
  };

  // @AnalyzeTime(0)
  handleMouseMove = (event: any) => {
    const { clientX, clientY } = event
    const { x, y } = this.container.getBoundingClientRect()
    const mouseOnCanvas = new THREE.Vector2(clientX - x, clientY - y)

    const views: any = Object.values(this.views || {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleMouseMove(event, mouseOnCanvas) === false) {
        break
      }
    }
  };

  // @AnalyzeTime(0)
  handleKeyDown = (event: any) => {
    const views: any = Object.values(this.views || {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleKeyDown(event) === false) {
        break
      }
    }
  };

  // @AnalyzeTime(0)
  handleKeyUp = (event: any) => {
    const views: any = Object.values(this.views || {}).reverse()

    for (let i = 0; i < views.length; i++) {
      if (views[i].handleKeyUp(event) === false) {
        break
      }
    }
  };

  // @AnalyzeTime(0)
  jumpToFiltered = (isCenterButton = false) => {
    if (isCenterButton) {
      this.views?.mainView.jumpToFiltered()

      return
    }

    if (!this.views?.uiView) {
      return
    }

    UiViewHandlers.handleJumpToSection(this.views?.uiView)
  };

  // @AnalyzeTime(0)
  jumpToFilter = (term: string, callback?: () => void, isJumpToSectionView = false) => {
    this.views?.mainView.jumpToFilter(term, callback, true, isJumpToSectionView)
  };

  jumpToCoolingLoop = (coolingLoopId: number) => {
    this.views?.mainView.jumpToCoolingLoop(coolingLoopId)
  }

  // @AnalyzeTime(0)
  start = () => {
    if (!this.frameId) {
      const animate = window?.meta?.DEV ? this.animateDebug : this.animate

      this.animate = animate
      this.frameId = window.requestAnimationFrame(animate)
    }
  };

  // @AnalyzeTime(0)
  stop = () => {
    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId)
    }
  };

  // @AnalyzeTime(0)
  animate = (elapsed: number) => {
    this.stats?.begin() // TODO: remove
    // const now = Date.now()

    this.frameId = window.requestAnimationFrame(this.animate)

    const frame = (0.5 + (elapsed / (this.rate || 1))) | 0

    if (this.skipRendering || frame === this.lastFrame) {
      return
    }

    this.lastFrame = frame

    // const deltaTime = (now - this.lastFrameTime) / 1000
    // this.lastFrameTime = now

    Object.values(this.views || {}).forEach((view: any) => view.animate(elapsed))

    // this.renderScene()

    this.stats?.end() // TODO: remove
  };

  // @AnalyzeTime(0)
  animateDebug = (elapsed: number) => {
    const now = performance.now()

    this.stats?.begin()
    // const now = Date.now()
    this.frameId = window.requestAnimationFrame(this.animate)

    const frame = (0.5 + (elapsed / (this.rate || 1))) | 0

    if (this.skipRendering || frame === this.lastFrame) {
      return
    }

    this.lastFrame = frame

    // const deltaTime = (now - this.lastFrameTime) / 1000
    // this.lastFrameTime = now

    Object.values(this.views || {}).forEach((view: any) => view.animate(elapsed))

    // this.renderScene()

    const fps = 1000 / (performance.now() - now)

    ThreeBase.actualFPS = fps
    this.stats?.end()
  };

  _renderScene = () => {
    this.renderer?.clear()

    Object.values(this.views || {}).forEach((view: any) => view.render())
  };

  renderScene = () => {
    requestAnimationFrame(this._renderScene)
  };

  cleanViews = (viewsToBeCleaned: string[]) => {
    Object.values(this.views || {}).forEach((view: any) => {
      if (!viewsToBeCleaned.includes(view.className)) {
        return
      }

      if (view.scene) {
        this.clearThree(view.scene)
        view.clickableObjects.length = 0
      }

      if (view.renderer) {
        view.renderer.renderLists.dispose()
      }
    })
  }

  clearThree (obj: any) {
    const avoidedElements = [ 'AmbientLight', 'DirectionalLightGroup', 'sectionPlaneFolded', 'SectionPlane' ]

    for (const child of obj.children) {
      if (!avoidedElements.includes(child.name)) {
        this.clearThree(child)
        obj.remove(child)
      }
    }

    if (obj.geometry) {
      obj.geometry.dispose()
    }

    if (obj.material) {
      // in case of map, bumpMap, normalMap, envMap ...
      Object.keys(obj.material).forEach(prop => {
        if (!obj.material[prop]) {
          return
        }

        if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') {
          obj.material[prop].dispose()
        }
      })

      if (obj.material.dispose) {
        obj.material.dispose()
      }
    }
  }

  toggleRollerView = () => {
    this.views?.uiView?.toggleRollerView()
  }
}
