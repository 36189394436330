import hotkeys from 'hotkeys-js'
import { AnalyzeTime } from 'Util'

export default class HotKeyHandler {
  static boundKeys: any = []
  static items: any = []

  @AnalyzeTime(0)
  static handleShortcuts (bind: boolean, item: any, clickFunction?: any, state?: any) {
    if (item.shortcut) {
      if (bind && this.boundKeys.includes(item.shortcut)) {
        return
      }

      if (!bind) {
        HotKeyHandler.boundKeys.splice(HotKeyHandler.boundKeys.lastIndexOf(item.shortcut), 1)
        HotKeyHandler.items.splice(HotKeyHandler.boundKeys.lastIndexOf(item.shortcut), 1)
        hotkeys.unbind(item.shortcut)
      }

      if ((bind && !this.boundKeys.includes(item.shortcut)) || item.shortcut.includes('O')) {
        if (!item.shortcut.includes('O') || !HotKeyHandler.boundKeys.includes(item.shortcut)) {
          HotKeyHandler.boundKeys.push(item.shortcut)
          HotKeyHandler.items.push(item)
        }

        hotkeys(item.shortcut, event => {
          if (!item.doNotPreventDefault) {
            event.preventDefault()
          }

          if (clickFunction) {
            return clickFunction(item)
          }
        })
      }
    }

    if (item.submenu) {
      item.submenu.forEach((subItem: any) => {
        const isDisabled = state !== undefined && subItem.disabled !== undefined ? subItem.disabled(state) : false

        if (!isDisabled) {
          HotKeyHandler.handleShortcuts(bind, subItem, clickFunction, state)
        }
      })
    }
  }
}
