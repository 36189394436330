export enum SegmentGroupActionsEnum {
  ACTION_ADD_SEGMENT_GROUP = 'SegmentGroup/ACTION_ADD_SEGMENT_GROUP',
  DELETE = 'SegmentGroup/DELETE',
  DELETE_MULTIPLE = 'SegmentGroup/DELETE_MULTIPLE',
  REMOVE_CHILD_ID = 'SegmentGroup/REMOVE_CHILD_ID',
  UPDATE = 'SegmentGroup/UPDATE',
  RENAME = 'SegmentGroup/RENAME',
}

export function addSegmentGroup (segmentGroupHash: Record<number, SegmentGroup & Parent>) {
  return {
    type: SegmentGroupActionsEnum.ACTION_ADD_SEGMENT_GROUP,
    segmentGroupHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SegmentGroupReducers: Record<string, Function | undefined> = {
  [SegmentGroupActionsEnum.ACTION_ADD_SEGMENT_GROUP]:
  (_state: SegmentGroupHash, action: { segmentGroupHash: SegmentGroupHash}) => (
    action.segmentGroupHash
  ),

  [SegmentGroupActionsEnum.DELETE]: (state: SegmentGroupHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [SegmentGroupActionsEnum.DELETE_MULTIPLE]: (state: SegmentGroupHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [SegmentGroupActionsEnum.REMOVE_CHILD_ID]:
  (state: SegmentGroupHash, action: {id: number, child: {type: string, id: number}}) => {
    const newState = { ...state }
    const element: any = newState[action.id]

    element[`#${action.child.type}Ids`] = element[`#${action.child.type}Ids`]
      .filter((childId: number) => childId !== action.child.id)

    return newState
  },

  [SegmentGroupActionsEnum.UPDATE]: (state: SegmentGroupHash, action: {elements: SegmentGroupHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },

  [SegmentGroupActionsEnum.RENAME]: (state: SegmentHash, action: {name: string, id: number}) => {
    return {
      ...state,
      [action.id]: {
        ...state[action.id],
        _name: action.name,
      },
    }
  },
}

export default function (
  state: SegmentGroupHash = {},
  action: any,
) {
  const handler = SegmentGroupReducers[action.type]

  return handler ? handler(state, action) : state
}
