import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import hotkeys from 'hotkeys-js'
import { withSnackbar } from 'notistack'

import NetworkManager from 'network/NetworkManager'
import { Form, Text, Error } from '../visualization/dashboard/Dialogs/DialogStyles'
import Input from '../specific/Input'
import Button from '../specific/SubmitButton'

import ApiClient from 'store/apiClient'
import { AppState } from 'store/application/main/consts'
import * as ApplicationActions from 'store/application/main/actions'

import BaseDialog from './BaseDialog'
import IpcManager from '../../IpcManager'

import { DefaultState } from 'types/state'
import { useConfig } from 'config'
import { parseJWT } from 'Util/authUtil'
import UsageTimeExceededDialog from './UsageTimeExceededDialog'

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  openAppDialogs: state.application.main.openAppDialogs,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setAuthenticationData: ApplicationActions.setAuthenticationData,
  setAppState: ApplicationActions.setAppState,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  hideCloseButton?: boolean,
  t (key: string, params?: Record<string, unknown>): string
  enqueueSnackbar (message: string, options: any): void
}

type State = {
  [key: string]: boolean | string | undefined | null,
  userName: string,
  password: string,
  authError: string | undefined | null,
  authErrorParams: string | undefined | null,
  loading: boolean
};

export class LoginDialog extends Component<Props, State> {
  static NAME = uuid()

  state = {
    userName: '',
    password: '',
    authError: null,
    authErrorParams: null,
    loading: false,
    isLocalLogin: false,
    hasOfflineCredentials: false,
  }

  componentDidMount () {
    hotkeys('Escape', this.handleClose)

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.handleTryLoginOnLoad()
      })
    })
  }

  componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(LoginDialog.NAME)
  };

  handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  };

  handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit(false)
    }
  };

  handleTryLoginOnLoad = () => {
    const isMySMSLogin = this.handleMySMSLogin()

    if (isMySMSLogin) {
      return
    }

    this.setState({ loading: true })

    const tokenDataRaw = localStorage.getItem('tokenData')

    if (!tokenDataRaw) {
      this.setState({ loading: false })

      return
    }

    const tokenData = JSON.parse(tokenDataRaw) as AuthResult

    const jwtData = parseJWT(tokenData.accessToken)

    if (jwtData.exp > Date.now() / 1000 + 5) {
      this.handleAPIAuthResult(tokenData)

      return
    }

    localStorage.removeItem('tokenData')

    this.setState({ loading: false })
  }

  handleAuthSuccess = (data: any) => {
    const {
      setAuthenticationData,
      setAppState,
    } = this.props

    setAuthenticationData(data)
    NetworkManager.reRegisterPlots()

    if (!data?.featureFlags?.Caster__View) {
      setAppState(AppState.ResultDashboard)
    }

    this.setState({ loading: false })

    IpcManager.internal.send('loadDefaultCase', data?.featureFlags)
  };

  handleAPIAuthResult = (data: AuthResult) => {
    delete data.featureFlags

    NetworkManager.authenticateViaJWT(data.accessToken, this.handleDispatcherAuthResult)

    // TODO: maybe save cookie (token) with .domain!?
    localStorage.setItem('tokenData', JSON.stringify(data))

    // TODO: handle token expiration and refresh!

    if (window.location.search.includes('session_state=')) {
      // using requestAnimationFrame to make sure the state is updated before the redirect
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const { href } = window.location

          window.history.replaceState({}, document.title, href.substring(0, href.indexOf('?')))
        })
      })
    }
  }

  handleDispatcherAuthResult = (data: any) => {
    if (!data || data.error) {
      const error = (data ? data.error : 'NoData') || 'NoError'

      this.setState({ authError: error, loading: false })

      return
    }

    this.handleAuthSuccess(data)
  }

  handleSubmit = (isLocalLogin: boolean) => {
    this.setState({ isLocalLogin, loading: true })

    if (isLocalLogin) {
      if (window.usageTimeInfo && window.usageTimeInfo.usageTimeExceeded) {
        const { openDialog, closeDialog, openAppDialogs } = this.props

        if (!openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
          closeDialog(LoginDialog.NAME)
          openDialog(UsageTimeExceededDialog.NAME)
        }

        return
      }

      NetworkManager.authenticateUser(null, null, true, IpcManager.featureFlags, this.handleDispatcherAuthResult)

      return
    }

    const { enqueueSnackbar, t } = this.props
    const { userName, password } = this.state

    if (!userName || !password) {
      const authErrorParams = !userName ? 'Username' : 'Password'

      this.setState({ authError: 'empty', authErrorParams, loading: false })

      return
    }

    ApiClient.post(`${useConfig().apiBaseURL}/auth/login`, { data: { username: userName, password } })
      .then(this.handleAPIAuthResult)
      .catch((error: any) => {
        console.log(error)

        if (error.body?.statusCode === 401) {
          this.setState({ authError: 'invalid', loading: false })

          return
        }

        enqueueSnackbar(t('loginDialog.loginError'), { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleMySMSLogin = () => {
    const { enqueueSnackbar, t } = this.props
    const params = new URLSearchParams(window.location.search)
    const sessionState = params.get('session_state')
    const code = params.get('code')

    if (!sessionState || !code) {
      if (document.referrer.includes(useConfig().mySMSReferrer)) {
        location.href = this.getMySMSLoginURL()
      }

      return false
    }

    this.setState({ loading: true })

    const apiURL = `${useConfig().apiBaseURL}/auth/authenticate/${code}/${sessionState}/${this.getRedirectURI()}`

    ApiClient.get(apiURL)
      .then(this.handleAPIAuthResult)
      .catch((error: any) => {
        console.log(error)

        this.setState({ loading: false })

        enqueueSnackbar(t('loginDialog.loginWithMySMSError'), { variant: 'error' })
      })

    return true
  }

  handleOpenExternalMySMS = () => {
    IpcManager.electron.send('openExternal', this.getMySMSLoginURL())
  };

  setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  };

  getRedirectURI = () => {
    if (!window.isElectron) {
      return encodeURIComponent(useConfig().mySMSRedirectURL)
    }

    // TODO: find a way to get deep links working with electron on linux, for testing purposes
    // currently the following is working: Electron Version on Windows, Online Version (in Browser) in DEV and PROD
    return encodeURIComponent('caster-app://open')
  }

  getMySMSLoginURL = () => {
    const { keycloakURL, keycloakClientID } = useConfig()

    const url = `${keycloakURL}?client_id=${keycloakClientID}&response_type=code`

    return `${url}&redirect_uri=${this.getRedirectURI()}`
  }

  render () {
    const { userName, password, authError, authErrorParams, loading } = this.state
    const { hideCloseButton, t } = this.props

    return (
      <BaseDialog
        title={t('loginDialog.title')}
        icon='pe-7s-user'
        header={t('loginDialog.header')}
        onClose={this.handleClose}
        hideCloseButton={hideCloseButton}
        small
      >
        <Form>
          <Text>
            {t('loginDialog.message')}
          </Text>
          <br />
          {authError && <Error>{t(`error.${authError}`, { value: authErrorParams })}<br /></Error>}
          <Input
            label={t('loginDialog.userName')}
            title={t('loginDialog.userName')}
            name='userName'
            type='text'
            value={userName}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            inputRef={this.setFocus}
          />
          <Input
            label={t('loginDialog.password')}
            title={t('loginDialog.password')}
            name='password'
            type='password'
            value={password}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button onClick={this.handleSubmit.bind(this, false)} isLoading={loading}>
            <div className='cut'><i className='pe-7s-angle-right-circle' /></div>
            <span>{t('loginDialog.login')}</span>
          </Button>
          {
            this.state.hasOfflineCredentials &&
              <Button onClick={this.handleSubmit.bind(this, true)} isLoading={loading}>
                <div className='cut'><i className='pe-7s-angle-right-circle' /></div>
                <span>{t('loginDialog.loginLocally')}</span>
              </Button>
          }
          {
            !window.isElectron &&
              <a href={this.getMySMSLoginURL()} onClick={() => this.setState({ loading: true })}>
                <Button isLoading={loading}>
                  <div className='cut'><i className='pe-7s-angle-right-circle' /></div>
                  <span>{t('loginDialog.loginWithMySMS')}</span>
                </Button>
              </a>
          }
          {
            window.isElectron &&
              <Button onClick={this.handleOpenExternalMySMS} isLoading={loading}>
                <div className='cut'><i className='pe-7s-angle-right-circle' /></div>
                <span>{t('loginDialog.loginWithMySMS')}</span>
              </Button>
          }
        </Form>
      </BaseDialog>
    )
  }
}

export default withSnackbar(withNamespaces('login')(connector(LoginDialog as any) as any) as any) as any
