import React, { Component } from 'react'

import TextWrapper from './TextWrapper'
import GageWrapper from './GageWrapper'
import MessageWrapper from './MessageWrapper'
import PlotlyWrapper from './PlotlyWrapper'
import EditBoxWrapper from './EditBoxWrapper'
import CommandWrapper from './CommandWrapper'

export type Props = {
  tileId: string,
  type: 'line' | 'bar' | 'gage' | 'contour' | 'pie' | 'area' | 'text' | 'edit_box' | 'command' | 'message' | 'radar',
  message?: string
  key: string
  configId: string
  xDomain?: number[]
  yDomain?: number[]
  xRange?: number[]
  xValues?: number[]
  yValueRange?: number[]
  valueRange?: number[]
  shapeIds?: string[]
  radiusDomain?: number[]
  radius0?: number
  radius?: number
  frequency?: number
  xLabel?: string
  yLabel?: string
  viewId?: string
  flipAxes?: boolean
  dynamicData?: any
  isDynamicData?: boolean
  dynamicDataList?: any[]
  isMergedDynamicData?: boolean
  hasNoData?: boolean
  isMultiLinePlot?: boolean
  shapeData?: any[]
  forceUpdateHandler?: () => void,
};

export default class PlotWrapper extends Component<Props> {
  render () {
    const { type, tileId } = this.props

    switch (type) {
      case 'area':
      case 'line':
      case 'bar':
      case 'contour':
      case 'pie':
      case 'radar':
        return (
          <PlotlyWrapper {...this.props} />
        )
      case 'text':
        return (
          <TextWrapper {...this.props} />
        )
      case 'gage':
        return (
          <GageWrapper {...{ ...this.props, xRange: [ 0, 5 ] }} />
        )
      case 'message':
        return <MessageWrapper id={`text_${tileId}`} message={this.props.message || ''} />
      case 'edit_box':
        return (
          <EditBoxWrapper {...this.props} />
        )
      case 'command':
        return (
          <CommandWrapper {...this.props} />
        )
      default:
        return null
    }
  }
}
