import { AnalyzeTime } from 'Util'

export default class Checker {
  // @AnalyzeTime(0)
  static checkIfVerified = (simulationCase?: SimulationCase) => {
    if (!simulationCase) {
      return false
    }

    const { processingParametersVerifications, catalogVerifications, casterVerifications } = simulationCase

    if (!processingParametersVerifications || !catalogVerifications || !casterVerifications) {
      return false
    }

    const currentProcessingParametersVerification =
        (processingParametersVerifications[processingParametersVerifications.length - 1] || {}).isVerified || false
    const currentCatalogVerification = (catalogVerifications[catalogVerifications.length - 1] || {}).isVerified || false
    const currentCasterVerification = (casterVerifications[casterVerifications.length - 1] || {}).isVerified || false

    return currentProcessingParametersVerification && currentCatalogVerification && currentCasterVerification
  }
}
