import React, { Component } from 'react'
import styled from 'styled-components'

import Icon from '../specific/Icon'

import type { ReactNode } from 'react'
import { AnalyzeTime } from 'Util'

const Toggler = styled.button`
  background-color: #333a44;
  padding:    5px;
  border:     none;
  color:      inherit;
  width:      100%;
  text-align: left;
  user-select: none;
  cursor: pointer;
  
  &:focus {
    outline: 0;
  }
`

const ToggleIcon = styled.div`
  display:        inline-block;
  position:       absolute;
  right:          23px;
  pointer-events: none;
`

const Separator = styled.div`
  border-bottom: 1px solid #333a44;
  margin: 16px 0;
`

const Spacer = styled.div`
  margin: 18px 0;
`

type Props = {
  children: ReactNode,
  name: string,
  title?: string,
  hideSeparator?: boolean,
  closed?: boolean
  spaceForComparisonCasterTitle?: boolean
};

type State = {
  isExpanded: boolean
};

export default class Section extends Component<Props, State> {
  state = {
    isExpanded: true,
  }

  @AnalyzeTime(0)
  componentDidMount () {
    const { closed } = this.props

    if (closed) {
      this.setState({
        isExpanded: false,
      })
    }
  }

  // @AnalyzeTime(0)
  handleToggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  };

  @AnalyzeTime(0)
  render () {
    const { isExpanded } = this.state
    const { children, name, title, hideSeparator, spaceForComparisonCasterTitle } = this.props

    return (
      <div>
        <Toggler onClick={this.handleToggle} title={title || name}>
          {name}
          <ToggleIcon>
            {isExpanded ? <Icon icon='angle-down' /> : <Icon icon='angle-right' />}
          </ToggleIcon>
        </Toggler>
        {spaceForComparisonCasterTitle && title !== 'Selection' && <Spacer />}
        {isExpanded && children}
        {!hideSeparator && <Separator />}
      </div>
    )
  }
}
