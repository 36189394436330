import * as THREE from 'three'
// TODO: review guards
import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Util from '../logic/Util'
import { Material, Texture } from 'three'
import { AnalyzeTime } from 'Util'
import Mold from './Mold'

export default class CoordinateAxes extends BaseObject {
  drawStraight: boolean
  caster?: Caster
  plHeight?: number
  rulerTexture?: Texture
  canvasMaterial?: Material
  headerMaterial?: Material
  constructor (container: any, drawStraight = false) {
    super(container)

    this.objects.group = new THREE.Group()
    this.objects.group.name = `CoordinateAxes${drawStraight ? '_Straight' : ''}`

    this.drawStraight = drawStraight

    Util.addOrReplace(this.container, this.objects.group)
  }

  @AnalyzeTime(0)
  getRulerNumberRotation (sideName: string, angleX: number) {
    const rotation = new THREE.Euler(0, 0, 0, 'XYZ')

    switch (sideName) {
      case 'FixedSide':
        rotation.x = angleX
        rotation.y = 45 * Util.RAD
        break
      case 'LooseSide':
        rotation.x = -angleX
        rotation.y = 45 * Util.RAD
        break
      case 'NarrowFaceRight':
        rotation.set(0, 45 * Util.RAD, angleX, 'ZYX')
        break
      case 'NarrowFaceLeft':
        rotation.set(0, 45 * Util.RAD, -angleX, 'ZYX')
        break
      default:
    }

    return rotation
  }

  @AnalyzeTime(0)
  generateCoordinateAxes (Caster: Caster, sideName: string) {
    const plHeight = Number(BaseObject.getPasslineHeight(Caster))
    const sideGroup = new THREE.Group()

    this.caster = Caster
    this.plHeight = plHeight

    sideGroup.rotation.y = Util.RAD180
    sideGroup.name = `RulerPlane_${sideName}`

    let distance = 1

    if (sideName === 'LooseSide' || sideName === 'NarrowFaceLeft') {
      distance = 1.1 + Caster.Mold._thickness / 1000
    }

    const coordHeading = Util.getText('[mm]', 0.15, false, true)
    const { newPosition, offset } = this.getRulerPositions(0, sideName, distance)

    if (!newPosition || !offset) {
      return
    }

    const rotation = this.getRulerNumberRotation(sideName, 0)

    coordHeading.position.copy(newPosition.add(offset))
    Util.flipXZ(coordHeading.position)
    coordHeading.position.y += 0.65
    coordHeading.rotation.copy(rotation)
    coordHeading.name = 'RulerHeading'

    Util.addOrReplace(sideGroup, coordHeading)

    for (let y = 0; y <= parseInt((plHeight as unknown as string), 10); y++) { // TODO: Review ParseInt
      const { newPosition, offset, angleX } = this.getRulerPositions(y, sideName, 1)

      if (!newPosition || !offset || angleX === undefined) {
        return
      }

      const position = newPosition.sub(offset)
      const rotation = this.getRulerNumberRotation(sideName, angleX)
      const text = `${y * 1000}`
      const number = Util.getText(text, 0.15, false, true)

      number.position.copy(position)
      number.rotation.copy(rotation)
      number.name = `RulerNumber_${text}`

      Util.addOrReplace(sideGroup, number)

      if (plHeight > y + 0.5) {
        const { newPosition, offset, angleX } = this.getRulerPositions(y + 0.5, sideName, 1)

        if (!newPosition || !offset || angleX === undefined) {
          return
        }

        const position = newPosition.sub(offset)
        const rotation = this.getRulerNumberRotation(sideName, angleX)
        const text = `${y * 1000 + 500}`
        const half = Util.getText(text, 0.1, false, true)

        half.position.copy(position)
        half.rotation.copy(rotation)
        half.name = `RulerNumber_${text}`

        Util.addOrReplace(sideGroup, half)
      }
    }

    Util.addOrReplace(this.objects.group, sideGroup)

    this.drawLineal(plHeight, sideName)
    this.setPosition(sideName)
  }

  @AnalyzeTime(0)
  getCanvas () {
    const canvas = document.createElement('canvas')
    const factor = 1024

    canvas.width = factor
    canvas.height = factor

    const ctx = canvas.getContext('2d')

    if (ctx) {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.0)'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    }

    return { canvas, ctx }
  }

  @AnalyzeTime(0)
  getRulerTexture (plHeight: number) {
    if (this.rulerTexture) {
      return this.rulerTexture
    }

    const { canvas, ctx } = this.getCanvas()

    this.drawLine(ctx, canvas.width * 0.6, 1, canvas.width * 0.6, canvas.height)

    const distance = canvas.height / 10

    for (let i = 0; i <= 10; i++) {
      const position = i * distance
      const lineType = i === 0 || i === 10 ? 0.4 : (i === 5 ? 0.475 : 0.55)
      const line = canvas.width * lineType
      const lineStart = canvas.width * 0.6

      this.drawLine(ctx, line, position, lineStart, position)
    }

    // eslint-disable-next-line no-undef
    const image = new Image()

    image.src = canvas.toDataURL()

    const texture = new THREE.Texture(image)

    texture.anisotropy = 8
    texture.needsUpdate = true
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(0.5, plHeight)
    texture.offset.set(-0.6, -plHeight - parseInt((plHeight as any), 10)) // TODO: review parseInt

    this.rulerTexture = texture

    return this.rulerTexture
  }

  @AnalyzeTime(0)
  getRulerPositions (plCoord: number, sideName: string, distance: number) {
    if (!this.caster) {
      return {}
    }

    const { _thickness } = this.caster.Mold || {}
    const thickness = _thickness / 1000
    const mnZ = new THREE.Vector3(1, 1, -1)

    const { position, normal, tangent, angleX } = PasslineCurve.getInfoAtPlCoord(plCoord, this.drawStraight)

    let newPosition = new THREE.Vector3(0, 0, 0)
    let offset = new THREE.Vector3(0, 0, 0)

    switch (sideName) {
      case 'FixedSide':
        newPosition = position.clone().multiply(mnZ)
        offset = normal.clone().multiply(mnZ).setLength(-distance)
        offset.applyAxisAngle(tangent.clone().multiply(mnZ), (90 - 45) * Util.RAD)
        break
      case 'LooseSide':
        newPosition = position.clone().add(normal.clone().setLength(-thickness))
        offset = normal.clone().setLength(distance)
        offset.applyAxisAngle(tangent, (90 - 45) * Util.RAD)
        break
      case 'NarrowFaceRight':
        newPosition = position.clone()
        Util.flipXZ(newPosition)
        offset = normal.clone().setLength(-distance)
        offset.applyAxisAngle(tangent, (90 - 45) * Util.RAD)
        Util.flipXZ(offset)
        break
      case 'NarrowFaceLeft':
        newPosition = position.clone().add(normal.clone().setLength(-thickness))
        newPosition.multiply(mnZ)
        Util.flipXZ(newPosition)
        offset = normal.clone().setLength(distance)
        const tang = tangent.clone()

        // TODO: fix accuracy in ruler
        Util.flipXZ(tang)
        tang.multiply(mnZ)
        offset.applyAxisAngle(tang, (90 - 45) * Util.RAD)
        break
      default:
    }

    return {
      newPosition,
      offset,
      angleX,
      tangent,
    }
  }

  @AnalyzeTime(0)
  drawLineal (plHeight: number, sideName: string) {
    const texture = this.getRulerTexture(plHeight)

    if (!this.caster) {
      return
    }

    this.canvasMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true })

    const { _width } = this.caster.Mold
    const width = _width / 1000
    // TODO: this makes no sense since this is the width of the mold and it's being used for the with of the ruler plane

    const geoWidth = 0.6
    const geometry = new THREE.PlaneGeometry(width, plHeight, 1, Math.ceil(plHeight) * 2) // no Buffer!

    const length = Math.abs((geometry as any).vertices.length / 2)
    const step = (plHeight / (length - 1))

    for (let i = 0; i < length; i++) {
      const { newPosition, offset } = this.getRulerPositions(i * step, sideName, geoWidth)

      if (newPosition && offset) {
        const j = i * 2;

        (geometry as any).vertices[j].copy(newPosition.clone().sub(offset))
        ;(geometry as any).vertices[j + 1].copy(newPosition)
      }
    }

    (geometry as any).computeFaceNormals()
    geometry.computeVertexNormals()

    const plane = new THREE.Mesh(geometry, this.canvasMaterial)
    const sideGroup = this.objects.group.getObjectByName(`RulerPlane_${sideName}`)

    plane.name = `RulerPlane_${sideName}`

    Util.addOrReplace(sideGroup, plane)
  }

  @AnalyzeTime(0)
  drawLine (ctx: CanvasRenderingContext2D | null, pointA: number, pointB: number, pointC: number, pointD: number) {
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.strokeStyle = '#FFFFFF'
    ctx.lineWidth = 10
    ctx.moveTo(pointA, pointB)
    ctx.lineTo(pointC, pointD)
    ctx.stroke()
  }

  @AnalyzeTime(0)
  getHeaderMaterial () {
    if (this.headerMaterial) {
      return this.headerMaterial
    }

    const { canvas, ctx } = this.getCanvas()

    this.drawLine(ctx, canvas.width * 0.15, canvas.width * 0.5, (this.caster?.Mold?._width || 0), canvas.height * 0.5)

    // eslint-disable-next-line no-undef
    const image = new Image()

    image.src = canvas.toDataURL()

    const texture = new THREE.Texture(image)

    texture.anisotropy = 8
    texture.needsUpdate = true

    this.headerMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true })

    return this.headerMaterial
  }

  @AnalyzeTime(0)
  drawHeaderLine (sideName: string, plCoord: number) {
    const material = this.getHeaderMaterial()
    const geometry = new THREE.PlaneBufferGeometry(1, 1)

    geometry.translate(0, 0, 0.001)
    const line = new THREE.Mesh(geometry, material)
    const { newPosition, offset, angleX } = this.getRulerPositions(plCoord, sideName, 0.25)

    if (!newPosition || !offset || angleX === undefined) {
      return
    }

    const rotation = this.getRulerNumberRotation(sideName, angleX)

    line.position.copy(newPosition.sub(offset))
    line.rotation.copy(rotation)
    line.name = `RulerHeaderLine_${plCoord}`

    return line
  }

  @AnalyzeTime(0)
  drawHeader (sideName: string) {
    const sideGroup = this.objects.group.getObjectByName(`RulerPlane_${sideName}`)
    const planeLineTop = this.drawHeaderLine(sideName, 0)
    const planeLineBottom = this.drawHeaderLine(sideName, parseInt((this.plHeight as any), 10))

    Util.addOrReplace(sideGroup, planeLineTop)
    Util.addOrReplace(sideGroup, planeLineBottom)
  }

  @AnalyzeTime(0)
  setPosition (sideName: string) {
    if (!this.caster) {
      return
    }

    const width = Mold.getWidestWidth()
    const sideGroup = this.objects.group.getObjectByName(`RulerPlane_${sideName}`)

    switch (Util.sides2Angles(sideName)) {
      case 0: // NarrowFaceRight
        sideGroup.rotation.y += 0 // * Util.RAD
        sideGroup.position.z = -width / 2
        break
      case 90: // LooseSide
        sideGroup.rotation.y += Util.RAD90
        sideGroup.position.z = -width / 2
        break
      case 180: // NarrowFaceLeft
        sideGroup.rotation.y += Util.RAD180
        sideGroup.position.z = width / 2
        break
      case 270: // FixedSide
        sideGroup.rotation.y += Util.RAD270
        sideGroup.position.z = width / 2
        break
      default:
        sideGroup.rotation.y += Util.sides2Angles(sideName) * Util.RAD
    }

    this.drawHeader(sideName)
  }
}
