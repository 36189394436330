/* eslint-env browser */

import { AnalyzeTime } from 'Util'
import FastBase from './FastBase'

export default class Line extends FastBase {
  constructor () {
    super('js-line', 'lines', 'trace')
  }

  @AnalyzeTime(0)
  _buildPathD (x: Array<number>, y: Array<number>, width: number, height: number): string {
    let d = ''

    for (let i = 0; i < y.length; i++) {
      const xValue = this._calcX(x[i], width)
      const yValue = this._calcY(y[i], height)

      // https://jsperf.com/string-concat-vs-array-join-10000/15
      d = d.concat('L', xValue.toFixed(2), ',', yValue.toFixed(2))
    }

    return `M${d.slice(1)}`
  }
}
