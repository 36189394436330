import BothLogic from './logicHandlers/BothLogic'
import ElectronLogic from './logicHandlers/ElectronLogic'
import ServerLogic from './logicHandlers/ServerLogic'
import InternalLogic from './logicHandlers/InternalLogic'

import { LoginDialog } from './react/dialogs/LoginDialog'
import { UsageTimeExceededDialog } from './react/dialogs/UsageTimeExceededDialog'
import ExternalLogic from './logicHandlers/ExternalLogic'
import ThreeManager from './three/ThreeManager'
import { AppState } from './store/application/main/consts'
import Util from 'store/visualization/util/Util'
import DownloadUtil from './logicHandlers/ServerLogic/actions/Util'
import { ManageDynamicDataSourcesDialog } from 'react/dialogs/project/ManageDynamicDataSourcesDialog'
import { SelectDashboardDialog } from 'react/dialogs/project/SelectDashboardDialog'
import { Network } from './network/Network'
import NetworkManager from './network/NetworkManager'
import AppHandlers from './App/handlers'
import { App, type Props } from './App'
import { ExecutableDialog } from 'react/dialogs/executables/ExecutableDialog'
import { CasterColumnLogic } from 'react/dialogs/project/ProjectDataDialog/CasterColumnLogic'
import SelectCatalogDialog from 'react/dialogs/project/ProjectDataDialog/SelectCatalogDialog'
import OpenProjectDialog from 'react/dialogs/project/OpenProjectDialog'
import NewProjectDialog from 'react/dialogs/project/NewProjectDialog'
import { DownloadXMLDialog } from 'react/dialogs/executables/DownloadXMLDialog'
import ApiClient from 'store/apiClient'
import FeatureFlags from 'react/FeatureFlags'
import { Info } from 'logic/Info'

export default class IpcManager {
  static props: Props
  static state = {
    isNewCaster: false,
    redraw: false,
    openDashboard: true,
    loadingCatalog: false,
  }

  // Function to handle lock caster inside App class
  static appLockCaster: AppHandlers['handleLockCaster']

  static electronHandler: ElectronLogic
  static serverHandler: ServerLogic
  static externalHandler: ExternalLogic
  static internalHandler: InternalLogic
  static bothHandler: BothLogic
  static app: App
  static featureFlags: Record<string, boolean>

  static init (app: App) {
    Network.IpcManager = this
    IpcManager.app = app

    window.IpcManager = IpcManager
  }

  static get electron () {
    if (!IpcManager.electronHandler) {
      IpcManager.electronHandler = new ElectronLogic()
    }

    return IpcManager.electronHandler
  }

  static get server () {
    if (!IpcManager.serverHandler) {
      IpcManager.serverHandler = new ServerLogic()
    }

    return IpcManager.serverHandler
  }

  static get external () {
    if (!IpcManager.externalHandler) {
      IpcManager.externalHandler = new ExternalLogic()
    }

    return IpcManager.externalHandler
  }

  static get internal () {
    if (!IpcManager.internalHandler) {
      IpcManager.internalHandler = new InternalLogic()
    }

    return IpcManager.internalHandler
  }

  static get both () {
    if (!IpcManager.bothHandler) {
      IpcManager.bothHandler = new BothLogic()
    }

    return IpcManager.bothHandler
  }

  static send (key: string, ...params: unknown[]) {
    if (IpcManager.internal.has(key)) {
      return IpcManager.internal.send(key, ...params)
    }

    return IpcManager.external.send(key, ...params)
  }

  static updateProps (props: Props) {
    IpcManager.props = props
  }

  static registerEvents (props: Props, forceUpdate: () => void) {
    IpcManager.props = props

    const {
      addComparisonCaster,
      appState,
      authenticationData,
      closeDialog,
      enqueueSnackbar,
      hasChanges,
      openDialog,
      resetHasChanges,
      resetReducer,
      saveAndParseXmlData,
      saveCatalog,
      setAppState,
      setAuthenticationData,
      setConfig,
      setCurrentCasterRoot,
      setCurrentProject,
      setCurrentSimulationCase,
      setDataSources,
      setLoadingStatus,
      setSavePath,
      setSimpleDashboardTabIndex,
      setTerm,
      setUserSettings,
      setVisualizationMetaInformation,
      switchProject,
      t,
    } = IpcManager.props

    IpcManager.both.on('loggingInToLocalServer', (_event: Event, loggingIn: boolean) => {
      IpcManager.app.setState({
        loggingInToLocalServer: loggingIn,
      })
    })

    IpcManager.both.on(
      'loadConsistencyCheckCase',
      (event: Event, consistencyCheckCaseId: string) => {
        NetworkManager.connect(IpcManager.appLockCaster)
        NetworkManager.authenticateUser(
          'ConsistencyCheck',
          consistencyCheckCaseId,
          false,
          {},
          (data: any) => {
            // this is set in smsg-caster Session.js loginAsConsistencyCheckUser
            const { authData, simulationCase, project, root } = data

            if (!authData || authData.error) {
              enqueueSnackbar(t(`localLogin.consistencyCheckError`), { autoHideDuration: 4000, variant: 'error' })

              return
            }

            setAuthenticationData(authData)
            NetworkManager.reRegisterPlots()

            if (!authData?.featureFlags?.Caster__View) {
              setAppState(AppState.ResultDashboard)
            }

            closeDialog(LoginDialog.NAME)
            closeDialog(OpenProjectDialog.NAME)
            closeDialog(NewProjectDialog.NAME)

            IpcManager.app.setState({
              loggingInToLocalServer: false,
            })

            setAppState(AppState.Caster)
            setCurrentProject(project)
            setCurrentSimulationCase(simulationCase)
            setCurrentCasterRoot(root)

            setTimeout(() => {
              IpcManager.internal.send(
                'data',
                null,
                root,
                true,
                null,
              )
            }, 100)
          },
        )
      },
    )

    IpcManager.both.on(
      'loadDefaultCase',
      async (dataFeatureFlags: Record<string, boolean>) => {
        const info = Info.getRecentlyUsedInfo()

        // dataFeatureFlags is sent when authenticated because the event is called before the authentication data
        // is set in redux
        try {
          let defaultProjectId: string | null = null
          let defaultCaseId: string | null = null

          if (info.projectId && info.caseId) {
            defaultProjectId = info.projectId
            defaultCaseId = info.caseId
          }
          else {
            const { projects } = await ApiClient
              .get(`${Network.URI}/projects`, { params: { isDefault: true, defaultCasterDashboardConfigId: true } })

            for (const project of projects as Project[]) {
              for (const case_ of project.simulationCases) {
                if (case_.isDefault) {
                  defaultProjectId = project?._id ?? null
                  defaultCaseId = case_?._id ?? null
                }
              }
            }
          }

          if (defaultCaseId && defaultProjectId) {
            const slimVersion = FeatureFlags.usesSlimVersion(dataFeatureFlags)
            const projectId = defaultProjectId

            const { project } = await ApiClient.get(`${Network.URI}/project/${projectId}`) as { project: Project }

            const populatedCase = project.simulationCases.find((case_) => case_._id === defaultCaseId)

            if (!populatedCase) {
              throw new Error('Failed to load default case')
            }

            switchProject()
            setCurrentProject(project)
            setCurrentSimulationCase(populatedCase, slimVersion)
            closeDialog(LoginDialog.NAME)

            const res = await ApiClient
              .get(`${Network.URI}/caster_data_json/${populatedCase._id}?r=1`)

            const { root } = res || {}

            if (!res || !root) {
              return
            }

            if (root) {
              setAppState(AppState.Caster)

              setCurrentCasterRoot(root)

              IpcManager.both.send('loading', null, true, true)

              setTimeout(async () => {
                IpcManager.internal.send(
                  'data',
                  null,
                  { root },
                  true,
                  null,
                )

                const { authenticationData } = IpcManager.props

                const defaultConfigId = info.configId ??
                  populatedCase.defaultCasterDashboardConfigId ??
                  authenticationData.defaultCasterDashboardConfig?.id

                if (defaultConfigId) {
                  await CasterColumnLogic
                    .handleLoadCasterConfigWithId({
                      featureFlags: dataFeatureFlags,
                      setConfig,
                      setDataSources,
                      setSimpleDashboardTabIndex,
                      setVisualizationMetaInformation,
                      currentSimulationCase: populatedCase,
                      appState,
                      project,
                      addComparisonCaster,
                      authenticationData,
                    } as any, defaultConfigId, '1')
                }
              }, 100)

              // timeout to avoid reset_all action
              setTimeout(async () => {
                if (populatedCase?.lastLoadedCasterCatalogId) {
                  const { casterCatalog } = await ApiClient
                    .get(`${Network.URI}/caster_catalog/${populatedCase.lastLoadedCasterCatalogId}`)

                  if (!casterCatalog?.data) {
                    return
                  }

                  saveCatalog(casterCatalog.data)
                }
              }, 1000)
            }

            IpcManager.app.setState({
              loggingInToLocalServer: false,
            })

            return
          }

          closeDialog(LoginDialog.NAME)
          openDialog(OpenProjectDialog.NAME)
          IpcManager.app.setState({
            loggingInToLocalServer: false,
          })
        }
        catch (error) {
          enqueueSnackbar('Failed to load default case', { variant: 'error' })
          // eslint-disable-next-line no-console
          console.log(error)
          closeDialog(LoginDialog.NAME)
          openDialog(OpenProjectDialog.NAME)
        }
      },
    )

    IpcManager.both.on(
      'useOfflineServer',
      (
        _event: Event,
        localServer: { ip: string, port: number, featureFlags: Record<string, boolean> },
      ) => {
        Network.setLocalURI(localServer.ip, localServer.port)
        NetworkManager.connect(IpcManager.appLockCaster)
        IpcManager.featureFlags = localServer.featureFlags
        NetworkManager.authenticateUser(null, null, true, localServer.featureFlags, (data: AuthData) => {
          if (!data || data.error) {
            enqueueSnackbar(t(`localLogin.error`), { autoHideDuration: 4000, variant: 'error' })

            return
          }

          setAuthenticationData(data)
          NetworkManager.reRegisterPlots()

          if (!data?.featureFlags?.Caster__View) {
            setAppState(AppState.ResultDashboard)
          }

          IpcManager.internal.send('loadDefaultCase', data?.featureFlags)
        })
      },
    )

    IpcManager.both.on('useOnlineServer', () => {
      NetworkManager.connect(IpcManager.appLockCaster)
    })

    IpcManager.both.on('setUserSettings', (event: Event, settings: {version: any
      projectName: any
      featureFlags: any
  }) => {
      IpcManager.app.setState({
        electronReady: true,
      })
      setUserSettings(settings)
    })

    IpcManager.both.on('meta', (event: Event, meta: { DEV: boolean }) => {
      window.meta = meta
    })

    IpcManager.both.on(
      'data',
      (
        event: Event,
        data: any,
        newData: any,
        path: string,
        catalogCallback: (data?: any) => void,
      ) => {
        setTerm('')

        if (newData) {
          resetReducer(true, true)
        }

        if (data) {
        // event !== null when 'data' is triggered by electron!
          saveAndParseXmlData(data, false, Boolean(event))
        }

        setSavePath(path)

        // TODO: find a better solution for this
        IpcManager.app.setState({
          redraw: true,
        })

        setTimeout(() => {
          IpcManager.app.setState({
            redraw: false,
          })
        }, 500)

        if (window.meta.DEV) {
        // eslint-disable-next-line no-console
          console.log(data)
        }

        if (catalogCallback) {
          catalogCallback()
        }
      },
    )

    IpcManager.both.on(
      'loading',
      (_event: any, status: boolean, forceLoading: boolean, ready: string, isLoadingNewCaster: boolean) => {
        IpcManager.app.setState({
          isNewCaster: false,
        })

        if (hasChanges || forceLoading) {
          setLoadingStatus(status)
        }

        if (ready === 'loadingReact') {
          IpcManager.external.send('loadingReactReady')
        }

        if (ready === 'loadingFileOpen') {
          if (isLoadingNewCaster) {
            IpcManager.app.setState({
              isNewCaster: true,
            })

            setTimeout(() => {
              IpcManager.app.setState({
                isNewCaster: false,
              })
            }, 2000)
          }

          IpcManager.both.send('loadingFileOpenReady', isLoadingNewCaster)
        }
      },
    )

    IpcManager.both.on('mergeData', (_event: Event, data: Record<string, unknown> | null) => {
      const { mergeNew } = IpcManager.props

      mergeNew([ 'Nozzle' ], data)

      IpcManager.app.setState({
        redraw: true,
      })

      setTimeout(() => {
        IpcManager.app.setState({
          redraw: false,
        })
      }, 500)

      setLoadingStatus(false)
    })

    IpcManager.both.on('replaceData', (_event: string, data:Record<string, unknown> | null) => { // TODO: is used?
      const { overwriteAll } = IpcManager.props

      overwriteAll([ 'Nozzle' ], data)

      IpcManager.app.setState({
        redraw: true,
      })

      setTimeout(() => {
        IpcManager.app.setState({
          redraw: false,
        })
      }, 500)

      setLoadingStatus(false)
    })

    IpcManager.both.on('setLoadingStatus', (_event: string, status:boolean, save:boolean) => {
      setLoadingStatus(status)

      if (save) {
        setTimeout(() => {
          setLoadingStatus(false)
        }, 1000)
      }
    })

    IpcManager.both.on('updateSavePath', (_event: string, savePath: string) => {
      setUserSettings({ savePath })
    })

    IpcManager.both.on('reset', () => {
      resetHasChanges()
    })

    // Visualization
    IpcManager.both.on('setLoading', (_event: string, loadingStatus: boolean, type: string) => {
      setLoadingStatus(loadingStatus)

      if (type && loadingStatus) {
        setTimeout(() => {
          IpcManager.both.send(type)
        }, 500)
      }
    })

    IpcManager.both.on('VisualizationData', (_event: string, data:any) => {
      const { setVisualizationData } = IpcManager.props

      data.meta.forEach((metaObject:any) => {
        metaObject.id = `config_${Util.generateConfigHash(metaObject.group, metaObject.key)}`
      })

      setVisualizationData(data)
    })

    IpcManager.both.on('openPlotList', (_event: string) => {
      const { showPlotList } = IpcManager.props

      showPlotList()
    })

    IpcManager.both.on('openSelectSourceDialog', (_event: string) => {
      const { openSelectSourceDialog, setAppState } = IpcManager.props

      openSelectSourceDialog()
      setAppState(AppState.ResultDashboard)
    })

    IpcManager.both.on('openPlotExportDialog', (_event: string) => {
      const { openPlotExportDialog, setAppState } = IpcManager.props

      setAppState(AppState.Caster) // TODO: toggle???
      openPlotExportDialog()
    })

    IpcManager.both.on('handleDashboard', (_event: string) => {
      const { setAppState } = IpcManager.props

      setAppState(AppState.Caster) // TODO: toggle???
    })

    IpcManager.both.on('getPlotVisDataElectron', () => {
      const { viewsObject, plotConfigs, tileConfigs, darkTheme } = IpcManager.props
      const saveObject = { viewsObject, plotConfigs, tileConfigs, darkTheme }

      IpcManager.electron.send('saveVisualizationConfigFile', JSON.stringify(saveObject))
    })

    IpcManager.both.on('setViewsObject', (_event: string, newDataObjectRaw: any) => {
      const { setConfig, setTheme } = IpcManager.props
      const newDataObject = typeof newDataObjectRaw === 'object' ? newDataObjectRaw : JSON.parse(newDataObjectRaw)

      setConfig({
        viewsObject: newDataObject.viewsObject || {},
        tileConfigs: newDataObject.tileConfigs || {},
        plotConfigs: newDataObject.plotConfigs || {},
      })

      setTheme(newDataObject.darkTheme)
    })

    IpcManager.both.on('addHdf5Schema', (_event: string, newHdf5SchemaPath: string) => {
      const { addHdf5Schema } = IpcManager.props

      addHdf5Schema(newHdf5SchemaPath)
    })

    IpcManager.both.on('noSchemaFileSync', (_event: string, nonExistentPath: string) => {
      const { setHdf5Schema, removeHdf5Schema } = IpcManager.props

      // TODO: Display warning
      setHdf5Schema('default')
      removeHdf5Schema(nonExistentPath)
    })

    IpcManager.both.on('openProjectDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(OpenProjectDialog.NAME)
    })

    IpcManager.both.on('uploadCaster', (_event: string) => {
      const { setLoadingStatus } = IpcManager.props

      CasterColumnLogic.handleUploadCasterData(
        'replaceCaster',
        IpcManager.props as any,
        true,
        setLoadingStatus,
      )
    })

    IpcManager.both.on('uploadCatalog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(SelectCatalogDialog.NAME)
    })

    IpcManager.both.on('loadCaster', async (_event: string) => {
      await CasterColumnLogic.openCaster(IpcManager.props as any)
    })

    IpcManager.both.on('openLoginDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(LoginDialog.NAME)
    })

    IpcManager.both.on('openNewProjectDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(NewProjectDialog.NAME)
    })

    IpcManager.both.on('openManageDynamicDataSourcesDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(ManageDynamicDataSourcesDialog.NAME)
    })

    IpcManager.both.on('sendCasterToConsistencyCheck', (_event: string) => {
      const { enqueueSnackbar, t } = IpcManager.props

      const simulationCaseId = IpcManager.props.currentSimulationCase._id

      IpcManager.send('sendToConsistencyCheck', simulationCaseId, enqueueSnackbar, t)
    })

    IpcManager.both.on('openSelectDashboard', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(SelectDashboardDialog.NAME)
    })

    IpcManager.both.on('openExecutableDialog', (definition: ExecutableDefinition) => {
      const { openDialog } = IpcManager.props

      openDialog(ExecutableDialog.NAME, { definition })
    })

    IpcManager.both.on('openDownloadXMLDialog', (definition: ExecutableDefinition) => {
      const { openDialog } = IpcManager.props

      openDialog(DownloadXMLDialog.NAME, { definition })
    })

    IpcManager.both.on('saveCasterXML', () => {
      const { enqueueSnackbar, t, currentSimulationCase } = IpcManager.props

      if (!currentSimulationCase) {
        return
      }

      if (window.isElectron) {
        enqueueSnackbar(('Download initiated...'), { variant: 'info', autoHideDuration: 3000 })
      }

      ApiClient
        .get(`${Network.URI}/caster_data/${currentSimulationCase._id}`)
        .then(async ({ data }) => {
          await DownloadUtil.openDownloadFileDialog(data, 'caster.xml')

          if (!window.isElectron) {
            enqueueSnackbar(t('download.successful'), { variant: 'success' })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)

          enqueueSnackbar(t('download.failed'), { variant: 'error' })
        })
    })

    IpcManager.internal.on('saveVisualizationConfig', (data: any, name: string) => {
      const { enqueueSnackbar, t } = IpcManager.props

      if (window.isElectron) {
        enqueueSnackbar(('Download initiated...'), { variant: 'info', autoHideDuration: 3000 })
      }

      DownloadUtil.openDownloadFileDialog(data, name || 'visualization_config.json')
        .then(() => {
          if (!window.isElectron) {
            enqueueSnackbar(t('download.successful'), { variant: 'success' })
          }
        })
        .catch(() => {
          enqueueSnackbar(t('download.failed'), { variant: 'error' })
        })
    })

    IpcManager.internal.on('openSimulationDataDialog', (data: any, name: string) => {
      const { enqueueSnackbar, t } = IpcManager.props

      IpcManager.both.send('saveSimulationData', data, name, enqueueSnackbar, t)
    })

    IpcManager.internal.on('kill3D', (_event: string) => {
      setLoadingStatus(true)

      ThreeManager.base.unmount()
      ThreeManager.killBase()
      ThreeManager.reInit()
      ThreeManager.base.mount()
    })

    IpcManager.internal.on('toggleFullScreenReact', () => {
      // prevent fullscreen on local development
      if (location.hostname?.endsWith('.local')) {
        return
      }

      if (window.toggleMaxRestoreButtons && window.isElectron) {
        IpcManager.external.send('toggleFullScreen')

        window.toggleMaxRestoreButtons()

        forceUpdate()

        return
      }

      const element: any = document.documentElement

      if (element.requestFullscreen) {
        element.requestFullscreen()
      }
      else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen()
      }
    })

    IpcManager.external.on('minimizeReact', () => {
      if (window.isElectron) {
        IpcManager.external.send('minimize')
      }
    })

    IpcManager.internal.on('exitFullScreenReact', () => {
      const { openAppDialogs } = this.props

      if (openAppDialogs.length) {
        return
      }

      if (window.toggleMaxRestoreButtons && window.isElectron) {
        IpcManager.external.send('exitFullScreen')

        window.toggleMaxRestoreButtons()

        forceUpdate()

        return
      }

      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
      else if ((document as any).webkitExitFullscreen) { /* Safari */
        (document as any).webkitExitFullscreen()
      }
    })

    IpcManager.external.on('usageTimeUpdate', (_event: any, data: any) => {
      if (data.usageTimeExceeded) {
        const { openDialog, openAppDialogs } = IpcManager.props

        if (!openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
          openDialog(UsageTimeExceededDialog.NAME)
        }
      }
      else {
        const { closeDialog } = IpcManager.props

        closeDialog(UsageTimeExceededDialog.NAME)
      }

      window.usageTimeInfo = data
    })

    IpcManager.external.send('reactReady')
  }
}
