import BaseObject from './BaseObject'
import Util from '../logic/Util'
import LodUtil from '../logic/LodUtil'
import * as THREE from 'three'

import Roller from './Roller'
import { AnalyzeTime } from 'Util'

export default class RollerBody extends BaseObject {
  static _3DGeometryCache: Record<string, THREE.CylinderGeometry> = {}
  static _2DLineGeometryCache: Record<string, THREE.BufferGeometry> = {}
  static _2DPlaneGeometryCache: Record<string, THREE.PlaneGeometry> = {}

  static defaultPlaneSectionView = new THREE.MeshBasicMaterial({
    color: '#3a6ce0',
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
  })

  static defaultLineSectionView = new THREE.LineBasicMaterial({ color: '#4ffaff' })
  static defaultMaterial1 = new THREE.MeshStandardMaterial({ color: '#3a6ce0' })
  static defaultMaterial2 = new THREE.MeshStandardMaterial({ color: '#94dfe0' })
  static phantomMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.4 })
  static selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41' }) // , transparent: true, opacity: 0.75
  static selectedMaterial2d = new THREE.MeshBasicMaterial({ color: '#7eda41' }) // , transparent: true, opacity: 0.75
  static deletedMaterial = new THREE.MeshStandardMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })
  static selectedDeletedMaterial = new THREE.MeshStandardMaterial({ color: '#da4515', transparent: true, opacity: 0.6 })

  static DetailDistance = [ 0, 3, 8 ]

  @AnalyzeTime(0)
  static get3DGeometry (radius: number, width: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${radius}_${width}_${radial}`
    let geometry = RollerBody._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(radius, radius, width, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD)

      ;(THREE.GeometryUtils as any).computeVertexNormals(geometry, 80)

      RollerBody._3DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  @AnalyzeTime(0)
  static get2DGeometry (bodyWidthStart: number, bodyWidth: number, diameter: number) {
    const lineGeometryKey = `${bodyWidthStart}_${bodyWidth}_${diameter}`
    const planeGeometryKey = `${bodyWidth}_${diameter}`

    let lineGeometry = RollerBody._2DLineGeometryCache[lineGeometryKey]
    let planeGeometry = RollerBody._2DPlaneGeometryCache[planeGeometryKey]

    if (!lineGeometry) {
      lineGeometry = new (THREE as any).Geometry()

      ;(lineGeometry as any).vertices.push(
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, diameter / 2, 0),
        new THREE.Vector3(bodyWidth + bodyWidthStart, -diameter / 2, 0),
        new THREE.Vector3(bodyWidthStart, -diameter / 2, 0),
      )

      RollerBody._2DLineGeometryCache[lineGeometryKey] = lineGeometry
    }

    if (!planeGeometry) {
      planeGeometry = new THREE.PlaneBufferGeometry(bodyWidth, diameter)

      RollerBody._2DPlaneGeometryCache[planeGeometryKey] = planeGeometry
    }

    return { lineGeometry, planeGeometry }
  }

  clickableObjects: any
  parentContainer: any
  isPhantom?: boolean
  sectionDetail?: any
  isSegmentGroupIDEven?: any
  width?: number
  startWidth?: number
  radius?: number

  constructor (container: any, parent: any, clickableObjects: any, phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.parentContainer = phantomGroup || parent.objects.roller

    if (phantomGroup) {
      const parentRoller = this.parent.objects.roller
      const parentPosition = parentRoller.position
      const parentRotation = parentRoller.rotation

      this.parentContainer.position.copy(parentPosition)
      this.parentContainer.rotation.copy(parentRotation)
    }
  }

  @AnalyzeTime(0)
  dispose (): void {
    super.dispose()

    if (!this.container || !this.container.children || !this.clickableObjects) {
      return
    }

    for (const child of this.container.children) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      this.container.remove(child)
    }

    for (const child of this.clickableObjects) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      if (child.parent) {
        child.parent.remove(child)
      }
    }

    this.clickableObjects = []
    this.objects = {}
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    rollerBodyData: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    _: any,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.isPhantom = isPhantom
    this.sectionDetail = sectionDetail

    if (this.sectionDetail) {
      this._renderSectionDetail(rollerBodyData, path)
    }
    else {
      this._renderNormal(rollerBodyData, path, isDeleted, isHidden)
    }

    this.setSelected((this.objects[LodUtil.LOD_ROLLER_BODY] || this.objects.rollerBodyPlane).userData.selected)
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    const { deleted } = this.parentContainer.userData

    if (this.isPhantom) {
      return
    }

    if (this.sectionDetail) {
      const material = isSelected
        ? (deleted ? RollerBody.selectedDeletedMaterial : RollerBody.selectedMaterial2d)
        : (deleted ? RollerBody.deletedMaterial : RollerBody.defaultPlaneSectionView)

      this.objects.rollerBodyPlane.material = material.clone()

      return
    }

    const element = this.objects[LodUtil.LOD_ROLLER_BODY] || this.objects.rollerBodyPlane

    element.userData.selected = isSelected

    element.children.forEach((child: any) => {
      const visible = child.material.visible

      if (child.name.includes('Roller_Body')) {
        return
      }

      const material = isSelected
        ? (deleted ? RollerBody.selectedDeletedMaterial : RollerBody.selectedMaterial)
        : (deleted
          ? RollerBody.deletedMaterial
          : (this.isSegmentGroupIDEven ? RollerBody.defaultMaterial1 : RollerBody.defaultMaterial2)
        )

      child.material = material.clone()
      child.material.visible = visible
    })
  }

  @AnalyzeTime(0)
  _setVisibility (visible: boolean) {
    const {
      [LodUtil.LOD_ROLLER_BODY]: lod,
      rollerGroupText,
      rollerGroupBacksideText,
      rollerBodyPlane,
      rollerBodyLine,
    } = this.objects

    if (!this.isPhantom && !this.sectionDetail) {
      rollerGroupText.material.visible = visible
      rollerGroupBacksideText.material.visible = visible
    }

    if (lod) {
      lod.visible = visible
    }

    if (rollerBodyPlane && rollerBodyLine) {
      rollerBodyPlane.visible = visible
      rollerBodyLine.visible = visible
    }
  }

  @AnalyzeTime(0)
  setVisibility (displayType: number) {
    if (this.isHidden) {
      return
    }
    else if (this.sectionDetail) {
      displayType = Roller.DisplayTypes.RollerChildren
    }

    switch (displayType) {
      case Roller.DisplayTypes.All:
        this._setVisibility(true)
        break
      case Roller.DisplayTypes.Roller:
        this._setVisibility(false)
        break
      case Roller.DisplayTypes.RollerChildren:
        this._setVisibility(true)
        break
      default:
    }
  }

  @AnalyzeTime(0)
  show () {
    if (this.isHidden) {
      return
    }

    super.show()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = true
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = true
      }
    })
  }

  @AnalyzeTime(0)
  hide () {
    super.hide()

    Object.values(this.objects).forEach((object: any) => {
      if (object instanceof THREE.LOD) {
        Object.values(object.children).forEach((child: any) => {
          child.material.visible = false
        })
      }
      else if (!this.isPhantom) {
        object.material.visible = false
      }
    })
  }

  @AnalyzeTime(0)
  _renderNormal (rollerBodyData: any, path: string, isDeleted: boolean, isHidden: boolean) {
    // eslint-disable-next-line camelcase
    const { _diameter, _width, _width_start, _numericId } = rollerBodyData

    this.width = _width / 1000
    // eslint-disable-next-line camelcase
    this.startWidth = _width_start / 1000
    this.radius = _diameter / 1000 / 2

    const parentInfo = Util.getParentInfo(this.parent.container.userData.path)

    // TODO: should it not be Mesh type?
    this.isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    const material = !this.isPhantom
      ? (this.isSegmentGroupIDEven ? RollerBody.defaultMaterial1 : RollerBody.defaultMaterial2)
      : RollerBody.phantomMaterial

    const lod = new THREE.LOD()

    for (let i = 0; i < 3; i++) {
      const geometry = RollerBody.get3DGeometry(this.radius, this.width, i, this.isPhantom)

      const mesh = new THREE.Mesh(geometry, material.clone())

      mesh.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
      mesh.type = 'RollerBody';
      (mesh as any).path = path

      lod.addLevel(mesh, RollerBody.DetailDistance[i])

      const oldElement = this.objects[LodUtil.LOD_ROLLER_BODY]
        ? this.objects[LodUtil.LOD_ROLLER_BODY].getObjectByName(mesh.name)
        : null

      Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
    }

    const rollerBodyLoD = lod

    rollerBodyLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`;
    (rollerBodyLoD as any).type = 'RollerBody';
    (rollerBodyLoD as any).path = path

    rollerBodyLoD.userData.deleted = isDeleted
    rollerBodyLoD.userData.hidden = isHidden
    rollerBodyLoD.userData.selected = this.objects[LodUtil.LOD_ROLLER_BODY]?.userData?.selected

    this.objects[LodUtil.LOD_ROLLER_BODY] = rollerBodyLoD

    const rollWidth = this.parent.rollerData._roll_width / 1000 / 2

    const side = this.container.userData.side

    switch (side) {
      case 'NarrowFaceRight':
        rollerBodyLoD.position.x = -(this.startWidth + this.width / 2 - rollWidth)
        break
      case 'NarrowFaceLeft':
        rollerBodyLoD.position.x = this.startWidth + this.width / 2 - rollWidth
        break
      default:
        rollerBodyLoD.position.x = this.startWidth + this.width / 2
    }

    // TODO: test values for Phong
    // rollerBodyLoD.position.y -= (150 / 1000) * (1 - 2 * Math.random())

    if (!this.isPhantom) {
      const { number } = this.parent.objects.rollerGroupText.userData
      const rollerGroupText = Util.getText(number, 0.06, false, true)
      const rollerGroupBacksideText = Util.getText(number, 0.06, false, true)

      rollerGroupText.name = `Roller_Body_Number_${number}_${_numericId}`
      rollerGroupBacksideText.name = `Roller_Body_Number_Backside_${number}_${_numericId}`

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.position.set(rollerBodyLoD.position.x + this.width / 2 + 0.0001, 0, 0)

      rollerGroupBacksideText.rotateY(-Util.RAD90)
      rollerGroupBacksideText.position.set(rollerBodyLoD.position.x - (this.width / 2 + 0.0001), 0, 0)

      this.objects.rollerGroupText = rollerGroupText
      this.objects.rollerGroupBacksideText = rollerGroupBacksideText

      Util.addOrReplace(this.parentContainer, rollerGroupText)
      Util.addOrReplace(this.parentContainer, rollerGroupBacksideText)
    }

    Util.addOrReplace(this.parentContainer, rollerBodyLoD)
  }

  @AnalyzeTime(0)
  _renderSectionDetail (rollerBody: any, path: string) {
    const bodyWidthStart = rollerBody._width_start / 1000
    const bodyWidth = rollerBody._width / 1000
    const diameter = this.parent.radius * 2

    const { lineGeometry, planeGeometry } = RollerBody.get2DGeometry(bodyWidthStart, bodyWidth, diameter)

    const line = new THREE.Line(lineGeometry, RollerBody.defaultLineSectionView)

    line.name = `RollerBodyLine_${path}`

    const plane = new THREE.Mesh(
      planeGeometry,
      RollerBody.defaultPlaneSectionView,
    )

    plane.name = `RollerBodyPlane_${path}`
    plane.type = `RollerBody`
    ;(plane as any).path = path

    const rollWidth = this.parent.rollerData._roll_width / 1000 / 2
    const side = this.container.userData.side

    switch (side) {
      case 'NarrowFaceRight':
        line.rotation.z += Util.RAD180
        line.position.x = rollWidth
        plane.position.x = -(bodyWidth / 2 + bodyWidthStart - rollWidth)
        break
      case 'NarrowFaceLeft':
        line.position.x = -rollWidth
        plane.position.x = bodyWidth / 2 + bodyWidthStart - rollWidth
        break
      case 'LooseSide':
        plane.rotation.x += Util.RAD180
        plane.position.x = bodyWidth / 2 + bodyWidthStart
        break
      default:
        plane.position.x = bodyWidth / 2 + bodyWidthStart
    }

    this.objects.rollerBodyPlane = plane
    this.objects.rollerBodyLine = line

    Util.addOrReplace(this.parentContainer, line)
    Util.addOrReplace(this.parentContainer, plane, this.clickableObjects)
  }
}
