import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { withNamespaces } from 'react-i18next'

import Icon from './Icon'

import * as FilterActions from '../../store/filter/actions'
import { DefaultState } from 'types/state'
import { AnalyzeTime } from 'Util'

const Button = styled.button<{active?: boolean}>`${({ active }) => css`
  border:           none;
  background-color: transparent;
  padding-left:     0;
  color:            ${active ? '#BB1B1B' : 'inherit'};
  
  &:hover {
    height: 10px;
    color:  ${active ? '#A71A1A' : '#CCCCCC'};
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled {
    color:          #777777;
    pointer-events: none;
  }
`}`

const connector = connect((store: DefaultState) => ({
  term: store.filter.term,
}), {
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  elementType: string
  value: string
  name: string
  disabled: boolean
  t (key: string, params?: Record<string, unknown>): string
}

export class FilterButton extends Component<Props> {
  // @AnalyzeTime(0)
  handleFilterClick = (event: any) => {
    const { value, setTerm, name, elementType } = this.props

    setTerm(`${elementType}#${name.substring(1)}=${value}`, event.ctrlKey || event.metaKey, true)
  };

  @AnalyzeTime(0)
  render () {
    const { value, elementType, term, name, disabled, t } = this.props

    const typePart = (term || '').split(' ').filter(part => part.indexOf(elementType) > -1) || []
    const isFilterActive = Boolean(elementType && typePart.length &&
      typePart.join(' ').indexOf(`${name.substring(1)}=${value}`) > -1)

    return (
      <Button
        title={t('specific.filterButton')}
        active={isFilterActive}
        disabled={disabled}
        onClick={this.handleFilterClick}
      >
        <Icon icon='filter' />
      </Button>
    )
  }
}

export default withNamespaces('caster')(connector(FilterButton as any) as any) as any
