import React, { Component } from 'react'

import { Elements, Counter, Spacer } from '../ElementGroupStyles'

import type { MouseEvent } from 'react'
import { AnalyzeTime } from 'Util'

type Props = {
  treeStatus: string,
  visibleChildren: any,
  selected: string,
  spacer: number,
  counter: number,
  nextElement: string,
  fontColor: string,
  title?: string,
  name: string,
  element: any
  open: boolean,
  onClick: (element: any, value: string) => void
};

export default class ChildrenElement extends Component<Props> {
  // @AnalyzeTime(0)
  handleClick = (event: any) => {
    event.stopPropagation()
    const { name, onClick, element } = this.props

    onClick(element, name)
  };

  @AnalyzeTime(0)
  render () {
    const {
      treeStatus,
      selected,
      spacer,
      counter,
      nextElement,
      fontColor,
      title,
      open,
    } = this.props

    const arrowChildren = open
      ? <Spacer onClick={this.handleClick}>&#9662;</Spacer>
      : <Spacer onClick={this.handleClick}>&#9656;</Spacer>

    return (
      <Elements
        treeStatus={treeStatus}
        selected={selected}
        onClick={this.handleClick}
        spacer={spacer * 15}
        fontColor={fontColor}
        title={title}
      >
        {arrowChildren} {nextElement}
        <Counter>
          {counter}
        </Counter>
      </Elements>
    )
  }
}
