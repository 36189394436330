import cloneDeep from 'clone-deep'
import type { ElementsHashes } from 'types/state'
import { AnalyzeTime } from 'Util'

import ThreeUtil from '../three/logic/Util'

declare global {
  interface Object {
    // eslint-disable-next-line @typescript-eslint/ban-types
    equals(obj: Object): boolean;
  }
  interface Array<T> {
    equals(array: Array<T>): boolean
  }
}

type MathKeyMap = {
 [match: string]: string
}
export default class Util {
  @AnalyzeTime(0)
  static _registerObjectEquals () {
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.hasOwnProperty('equals')) {
      // eslint-disable-next-line no-console
      console.warn('Overriding existing Object.prototype.equals. Possible causes: New API defines the method,' +
        ' there\'s a framework conflict or you\'ve got double inclusions in your code.')
    }

    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Object.prototype, 'equals', {
      value: function (object: any) {
      // if the other object is a falsy value or not an object, return
        if (!object || !(object instanceof Object)) {
          return false
        }

        const thisKeys = Object.keys(this)
        const objectKeys = Object.keys(object)

        // if key count is not the same, return
        if (thisKeys.length !== objectKeys.length) {
          return false
        }

        // if the key names differ (this only works with Array.prototype.equals)
        if (!thisKeys.equals(objectKeys)) {
          return false
        }

        // compare keys, return if anything is not equal
        for (let i = 0; i < thisKeys.length; i++) {
          const thisValue = this[thisKeys[i]]
          const objectValue = object[thisKeys[i]]

          // if it's not the same type it's not equal
          if (typeof thisValue !== typeof objectValue) {
            return false
          }

          // this includes objects and arrays (this only works with Array.prototype.equals)
          if (thisValue && typeof thisValue === 'object') {
            if (!thisValue.equals(objectValue)) {
              return false
            }
          }
          else if (thisValue !== objectValue) {
          // if values are primitives just compare them
            return false
          }
        }

        return true
      },
      // Hide method from for-in loops
      // eslint-disable-next-line no-extend-native
      enumerable: false,
    })
  }

  @AnalyzeTime(0)
  static _registerArrayEquals () {
    // eslint-disable-next-line no-prototype-builtins
    if (Array.prototype.hasOwnProperty('equals')) {
      // eslint-disable-next-line no-console
      console.warn('Overriding existing Array.prototype.equals. Possible causes: New API defines the method,' +
        ' there\'s a framework conflict or you\'ve got double inclusions in your code.')
    }

    // attach the .equals method to Array's prototype to call it on any array
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'equals', {
      value: function (array: Array<any>) {
        // if the other array is a falsy value or not an array, return
        if (!array || !(array instanceof Array)) {
          return false
        }

        // compare lengths - can save a lot of time
        if (this.length !== array.length) {
          return false
        }

        for (let i = 0, l = this.length; i < l; i++) {
          // Check if we have nested arrays
          if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i])) {
              return false
            }
          }
          // Check if we have nested objects
          else if (this[i] instanceof Object && array[i] instanceof Object) {
            // (recurse) into the nested objects (this only works with Object.prototype.equals)
            if (!this[i].equals(array[i])) {
              return false
            }
          }
          else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false
          }
        }

        return true
        // Hide method from for-in loops
        // eslint-disable-next-line no-extend-native
      },
      enumerable: false,
    })
  }

  @AnalyzeTime(0)
  static init () {
    Util._registerArrayEquals()
    Util._registerObjectEquals()
  }

  @AnalyzeTime(0)
  static RollerChildren (parentRoller: any, parentPath: string) {
    const allChildren: string[] = []

    if (parentRoller['#RollerBodyIds']) {
      allChildren.push(...parentRoller['#RollerBodyIds'].map((id: number) => {
        return `${parentPath}/RollerBody:${id}`
      }))
    }

    if (parentRoller['#RollerBearingIds']) {
      allChildren.push(...parentRoller['#RollerBearingIds'].map((id: number) => {
        return `${parentPath}/RollerBearing:${id}`
      }))
    }

    return allChildren
  }

  @AnalyzeTime(0)
  static getElement (path: string, elementsHashes: ElementsHashes, copy = false) {
    const { type, id } = ThreeUtil.getElementInfo(path)

    if (copy) {
      const elementIndex = (elementsHashes as any)[type]

      return cloneDeep(elementIndex[id])
    }

    return ((elementsHashes as any)[type] || {})[id]
  }

  @AnalyzeTime(0)
  static getPathFromPathArray (array: Array<any>) {
    const names = array.filter((_, i) => i % 2 === 0)
    const ids = array.filter((_, i) => i % 2 === 1)

    return names.map((name, index) => `${name}:${ids[index]}`).join('/')
  }

  @AnalyzeTime(0)
  static getPathArrayFromPath (path: string) {
    return path.split('/').reduce((list: Array<any>, part: string) => (parts => ([
      ...list,
      parts[0],
      Number(parts[1]),
    ]))(part.split(':')), [])
  }

  static eventStorage: any = {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  @AnalyzeTime(0)
  static AddDelegatedEventListener (context: string, type: string, selector: string, handler: any) {
    const contextElement = context ? document.querySelector(context) : document

    const func = Util.eventStorage[`${context || ''}_${type}_${selector}`] = function (e: any) {
      for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(selector)) {
          handler.call(target, e)

          break
        }
      }
    }

    if (contextElement) {
      contextElement.addEventListener(type, func, false)
    }
  }

  @AnalyzeTime(0)
  static RemoveDelegatedEventListener (context: string, type: string, selector: string) {
    const contextElement = context ? document.querySelector(context) : document

    if (contextElement) {
      contextElement.removeEventListener(type, Util.eventStorage[`${context || ''}_${type}_${selector}`], false)
    }
  }

  static mathKeyMap: MathKeyMap = Object.getOwnPropertyNames(Math).reduce((map, key) =>
    ({ ...map, [key.toLowerCase()]: key }), {})

  static mathKeys = new RegExp(`(${Object.keys(Util.mathKeyMap).join('|')})`, 'gi')

  @AnalyzeTime(0)
  static prepareFormula (value: string) {
    return value
      .replace(/^\./, '0.')
      .replace(/([^\d]|^)\.(\d)/g, '$10.$2') // .1 => 0.1
      .replace(/(\d)\.([^\d])/g, '$1$2') // 1. => 1
      .replace(/([^\d])\.([^\d])/g, '$1$2') // . =>
      .replace(/ /g, '') // get rid of all spaces
      // .replace(/[^y+\-*/\d.]/g, '') // only keep allowed characters
      .replace(/^[+\-*/]/g, '') // no +-*/ start
      .replace(/(\d*)\.(\d*)([\d.]*)/g, '$1#$2$3') // 5.5.5.5
      .replace(/([^.]\d+)\.([^$])/g, '$1$2') // 5.5.5.5
      .replace(/#/g, '.') // 5.5.5.5
      .replace(/([^\d]|^)0(\d)/g, '$1$2') // 08 => 8
      .replace(/([y+\-*/])\1+/g, '$1') // no duplication
      .replace(/[+\-*/.]([+*/])/g, '$1') // e.g. '+ +' will be replaced
      .replace(/(\))([\dy])/g, '$1*$2') // )8 => )*8
      .replace(/(( |^)[\dy]+)(\()/gi, '$1*$3') // 8( => 8*(
      .replace(/(\))(\()/g, '$1*$2') // )( => )*(
      .replace(/(y)(\d)/g, '$1*$2') // y9 => y*9
      .replace(/(\d)(y)/g, '$1*$2') // 9y => 9*y
      .replace(/([+*\-/])/g, ' $1 ') // spaces around +*-/
      .replace(/([+*/] -) /g, '$1') // keep '-' at the number
      .replace(/(\.\d+)\./, '$1')
      .replace(/,/g, ', ') // comma space
      .replace(Util.mathKeys, match => Util.mathKeyMap[match.toLowerCase()])
      .trim()
  }

  @AnalyzeTime(0)
  static compileFormula (dynamicFormula: string) {
    return dynamicFormula ? dynamicFormula.replace(Util.mathKeys, match => `Math.${match}`) : 'y'
  }

  @AnalyzeTime(0)
  static testFormula (dynamicFormula: string, error: string) {
    const value = 42
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return `y = ${value}; f(y) = ${value}`
    }

    try {
      // eslint-disable-next-line no-eval
      const result = eval(formula.replace(/y/g, value.toString()))

      if (isNaN(result)) {
        throw new Error('not a number')
      }

      return `y = ${value}; f(y) = ${result}`
    }
    catch (e) {
      return `y = ${value}; f(y) = ${error}`
    }
  }

  @AnalyzeTime(0)
  static handleFormula (value: number, dynamicFormula: string) {
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return value
    }

    let result = value

    try {
      // eslint-disable-next-line no-eval
      result = eval(formula.replace(/y/g, value.toString()))
    }
    catch (e) {}

    return result
  }

  @AnalyzeTime(0)
  static bindMethods (obj: any, context: any) {
    Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
      .map(key => {
        if (obj[key] instanceof Function && key !== 'constructor') {
          obj[key] = obj[key].bind(context)
        }
      })
  }

  static hasElementType (elementType: Record<number | string, any>) {
    return Object.keys(elementType).filter(key => key !== '#hasChanges').length
  }
}
