import * as THREE from 'three'

import Util from '../../logic/Util'
import Getters from './Getters'
import UIView, { ControlButtonImageNames } from '.'
import FeatureFlags from 'react/FeatureFlags'
import UiViewHandlers from './UiViewHandlers'

export default class DrawHandlers {
  static defaultFilterControlIcon = 'zelda'

  static textureImageMapper = {
    Nozzle: 'viewswitch-nozzle.png',
    Roller: 'viewswitch-roller.png',
    DataPoint: 'viewswitch-datapoint.png',
  }

  static drawButtons (view: UIView, redrawButtons: boolean, filerControlDefinitions: boolean, marginRight: number) {
    const size = 22
    const margin = 5

    const buttons = [
      {
        tooltip: 'Center position',
        icon: 'center.png',
        action: 'center',
      },
      {
        tooltip: 'Deselect all',
        icon: 'deselect.png',
        action: 'deselect',
      },
      {
        tooltip: (FeatureFlags.canJumpOverSomeElement(view.featureFlags) &&
        UiViewHandlers.getAmountOfJumpableElements(view) > 0)
          ? 'Toggle section view'
          : 'Toggle section view (disabled)',
        icon: 'split.png',
        action: 'section',
      },
      {
        tooltip: 'Toggle roller details',
        icon: 'glasses.png',
        action: 'rollerSwitcher',
      },
      {
        tooltip: 'Toggle bending',
        icon: 'switcher.png',
        action: 'bend',
      },
      {
        tooltip: view.termDisabled ? 'Enable Filter' : 'Disable Filter',
        icon: view.termDisabled ? 'activeFilter.png' : 'filter.png',
        action: 'toggleFilter',
      },
    ]

    let position = 0

    buttons.forEach(button => {
      const { icon, action, tooltip } = button

      switch (action) {
        case 'bend':
          if (!FeatureFlags.canToggleBending(view.featureFlags)) {
            return
          }

          break
        case 'section':
          if (!FeatureFlags.canToggleSectionView(view.featureFlags)) {
            return
          }

          break
        case 'rollerSwitcher':
          if (!FeatureFlags.canToggleRollerDetails(view.featureFlags)) {
            return
          }

          break
        case 'deselect':
          if (!FeatureFlags.canDeselectAll(view.featureFlags)) {
            return
          }

          break
        case 'center':
          if (!FeatureFlags.canCenter3DPosition(view.featureFlags)) {
            return
          }

          break
        case 'toggleFilter':
          if (!FeatureFlags.canDeleteFilterWith3DButton(view.featureFlags)) {
            return
          }

          break
      }

      const transparent = false

      if (Object.keys(view.buttons).includes(action) && !redrawButtons) {
        return
      }

      // if (icon === 'split.png' && view.sectionViewDisabled) {
      //   transparent = true
      // }

      DrawHandlers.drawTextureButton(
        view,
        'tr',
        (filerControlDefinitions ? marginRight - 10 : 125) + size * position + margin * position,
        margin,
        icon as ControlButtonImageNames,
        action,
        transparent,
        tooltip,
      )

      position++
    })

    view.updateTransforms()
  }

  static drawBar (view: UIView) {
    const width = 6
    const x = 50 - width / 2
    const y = 150
    const height = view.viewport.height - y - 50

    const barGeometry = new THREE.PlaneBufferGeometry(width, height, 1)
    const barMaterial = new THREE.MeshBasicMaterial({ color: '#c7e5ff', transparent: true, opacity: 0.25 })
    const scrollBar = new THREE.Mesh(barGeometry, barMaterial)

    scrollBar.position.copy(Getters.getPosition(width, height, x, y, view.viewport))
    scrollBar.visible = view.isSectionActive
    scrollBar.name = 'scrollBar'

    Util.addOrReplace(view.scene, scrollBar)

    view.scrollBar = scrollBar
  }

  static drawJumpOverElementTypeButton (view: UIView) {
    const { width } = UIView.sliderDimensions
    const position = Getters.getPosition(width, 0, 50 - width / 2, 150, view.viewport)
    const material = view.buttonMaterials.jumpOver[view.views?.sectionView?.jumpOver || 'Nozzle']
    const geometry = new THREE.PlaneBufferGeometry(width, width, 1)

    const switchScrollElement = new THREE.Mesh(geometry, material)

    if (view.switchScrollElementDisabled) {
      material.transparent = true
      material.opacity = 0.5
    }

    switchScrollElement.name = 'switchScrollElement'
    switchScrollElement.position.copy(position)
    switchScrollElement.position.y += width * 2.5
    switchScrollElement.visible = view.isSectionActive
    Util.addOrReplace(view.scene, switchScrollElement, view.clickableObjects)
    view.switchScrollElement = switchScrollElement
    view.views.sectionView?.updateMinAndMaxPasslineCoordinates()
  }

  static drawUpDownButtons (view: UIView) {
    const { width } = UIView.sliderDimensions
    const position = Getters.getPosition(width, 0, 50 - width / 2, 150, view.viewport)

    const a = new THREE.Vector3(width / 2, 0, 0)
    const b = new THREE.Vector3(-width / 2, 0, 0)
    const c = new THREE.Vector3(0, width / 2, 0)
    const d = new THREE.Vector3(0, -width / 2, 0)

    const { buttonUp, buttonDown } = Getters.getUpAndDownButtons(a, b, c, d, width, position, view)

    buttonUp.visible = view.isSectionActive
    buttonDown.visible = view.isSectionActive

    // add to scene
    Util.addOrReplace(view.scene, buttonUp, view.clickableObjects)
    Util.addOrReplace(view.scene, buttonDown, view.clickableObjects)

    view.buttonUp = buttonUp
    view.buttonDown = buttonDown
  }

  static drawJumpToSectionPlaneButton (view: UIView, scrollBarButtonGroup: THREE.Group, width: number) {
    const buttonWidth = 20
    const jumpToSectionButton = Getters.getJumpToSectionButton(buttonWidth)

    jumpToSectionButton.position.set(0, 0, 0)

    jumpToSectionButton.name = 'jumpToSectionButton'
    jumpToSectionButton.type = 'Button'
    jumpToSectionButton.userData = {
      action: 'jumpToSection',
    }

    jumpToSectionButton.position.set(width + buttonWidth / 2, 0, 0)

    Util.addOrReplace(scrollBarButtonGroup, jumpToSectionButton, view.clickableObjects)
  }

  static drawScrollBar (view: UIView) {
    DrawHandlers.drawBar(view)
    DrawHandlers.drawUpDownButtons(view)
    DrawHandlers.drawJumpOverElementTypeButton(view)

    const { width, height, top, bottom } = UIView.sliderDimensions

    const x = bottom - width / 2
    const min = top

    const barGeometry = new THREE.PlaneBufferGeometry(width, height, 1)
    const sliderMaterial = UIView.barMaterial
    const slider = new THREE.Mesh(barGeometry, sliderMaterial)

    slider.name = 'scrollBarSlider'

    if (!view.scrollBarButtonGroup) {
      view.scrollBarButtonGroup = new THREE.Group()
      view.scrollBarButtonGroup.name = 'scrollBarButtonGroup'
      view.scrollBarButtonGroup.visible = view.isSectionActive
      view.scrollBarButtonGroup.userData.width = width
      view.scrollBarButtonGroup.userData.height = height
      view.scrollBarButtonGroup.userData.min = min
      view.scrollBarButtonGroup.userData.x = x
      view.scrollBarButtonGroup.userData.top = top
      view.scrollBarButtonGroup.userData.bottom = bottom

      Util.addOrReplace(view.scene, view.scrollBarButtonGroup)
    }

    Util.addOrReplace(view.scrollBarButtonGroup, slider, view.clickableObjects)

    view.setButtonPos()
  }

  static drawPasslineCoord (scrollBarButtonGroup: any, width: number) {
    const coordMesh = Util.getText(UIView.currentPsslnPosition.toString(), 15, true, true)

    coordMesh.name = 'PassLineCoord'
    coordMesh.geometry.computeBoundingBox()

    const { max: bbMax, min: bbMin } = coordMesh.geometry.boundingBox
    const plX = bbMax.x - bbMin.x

    coordMesh.position.set(-(plX / 2 + width), 0, 0)

    Util.addOrReplace(scrollBarButtonGroup, coordMesh)
  }

  static drawTextureButton (
    view: UIView,
    align: string,
    x: number,
    y: number,
    imageName: ControlButtonImageNames,
    action: string,
    transparent = false,
    tooltipText: string,
  ) {
    const size = 22
    const geometry = new THREE.PlaneBufferGeometry(size, size, 1)
    const material = view.buttonMaterials.controls[imageName]
    const button = new THREE.Mesh(geometry, material)

    button.userData.align = align
    button.userData.x = x
    button.userData.y = y
    button.userData.width = size
    button.userData.height = size
    button.userData.action = action
    button.name = action

    if (tooltipText) {
      button.type = 'TooltipMarker'
      button.name = `TooltipMarker_${action}`
      button.userData.tooltip = tooltipText

      const index = view.tooltipObjects.findIndex(object => object.name === button.name)

      // TODO: could make an object with button.name as key instead of an array
      if (index > -1) {
        view.tooltipObjects.splice(index, 1)
      }

      view.tooltipObjects.push(button)
    }

    view.buttons[action] = button

    if (transparent) {
      material.transparent = transparent
      material.opacity = 0.5

      Util.addOrReplace(view.scene, button, view.clickableObjects)

      return
    }

    Util.addOrReplace(view.scene, button, view.clickableObjects)
  }
}
