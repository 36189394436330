import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled, { css } from 'styled-components'
import { DefaultState } from 'types/state'

import TypeBuilder from './context/type/TypeBuilder'
import ScrollBar from './specific/ScrollBar'

const CasterDialogView = styled.div<{
  dialogWidth: number,
  isOpen: boolean,
}>`${({
  isOpen,
  dialogWidth,
}: any) => css`
  display:    inline-block;
  position:   absolute;
  top:        0;
  right:      ${isOpen ? '0' : '-335px'};
  bottom:     0;
  width:      ${dialogWidth}px;
  min-width:  335px;
  background: #22282e;
  color:      #FFFFFF;
  font-size:  14px;
  overflow:   hidden;
`}`

const connector = connect((state: DefaultState) => ({
  selectedDataArray: state.data.selectedDataArray,
  openDialogs: state.application.main.openDialogs,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
}))

type Props = ConnectedProps<typeof connector>

type SelectedPathsByType = {
  [elementType: string]: string[]
  General: string[],
  // SegmentGroup: string[],
  // Segment: string[],
  Nozzle: string[],
  Roller: string[],
  RollerBody: string[],
  RollerBearing: string[],
  SensorPoint: string[],
}

type State = {
  showElements: boolean
  dragging: boolean
  currentPanelWidth: number
  mousePosition: number
}
export class CasterDialog extends Component<Props> {
  state: State = {
    showElements: false,
    dragging: false,
    currentPanelWidth: 335,
    mousePosition: 0,
  }

  componentDidUpdate (prevProps: Props) {
    const { selectedDataArray } = this.props

    if (!selectedDataArray.equals(prevProps.selectedDataArray)) {
      this.setState({
        showElements: true,
      })
    }
  }

  toggleElements = () => {
    this.setState({
      showElements: !this.state.showElements,
    })
  }

  render () {
    const {
      selectedDataArray,
      openDialogs,
      currentCasterDialogWidth,
    } = this.props || {}
    const isOpen = openDialogs.includes('CasterDialog')
    const { showElements } = this.state

    const selectedPathsByType = selectedDataArray.reduce((object: SelectedPathsByType, path) => (type => ({
      ...object,
      [type]: [
        ...object[type],
        path,
      ],
    }))(path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf(':'))), {
      // TODO: use when necessary
      General: [],
      Elements: [],
      SegmentGroup: [],
      // Segment: [],
      SupportPoint: [],
      Nozzle: [],
      Roller: [],
      RollerBody: [],
      RollerBearing: [],
      SensorPoint: [],
      DataPoint: [],
      DataLine: [],
      Modules: [],
    })

    let types = Object.keys(selectedPathsByType) as CasterDialogElementType[]

    if (!showElements) {
      types = types.filter(type => [ 'Modules', 'General', 'ConsistencyCheck', 'Elements' ].includes(type))
    }

    return (
      <CasterDialogView
        isOpen={isOpen}
        dialogWidth={currentCasterDialogWidth}
      >
        <ScrollBar hidden={!isOpen}>
          {
            types
              .map((type: CasterDialogElementType) => (
                <TypeBuilder
                  key={type}
                  type={type}
                  paths={selectedPathsByType[type]}
                  areElementsShown={showElements}
                  toggleElements={this.toggleElements}
                />
              ))
          }
        </ScrollBar>
      </CasterDialogView>
    )
  }
}

export default connector(CasterDialog as any) as any
