import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import { withNamespaces } from 'react-i18next'

import SecondaryButton from '../../specific/SecondaryButton'
import Section from '../Section'
import Button from '../../specific/Button'
import FeatureFlags from '../../FeatureFlags'
import IpcManager from '../../../IpcManager'

import DataActions from '../../../store/data/actions'
import * as FilterActions from '../../../store/filter/actions'
import { DefaultState } from 'types/state'

export const NozzleCatalogSelect = styled.select<{ placeholder: string, value: string, onChange: (e: any) => void }>`
  outline:       none;
  background:    #22282e;
  color:         #CCCCCC;
  border:        none;
  border-bottom: solid 1px #CCCCCC; 
  width:         120px;
  height:        27px;
  margin:        10px 9px;
  float:         left;
`

const UseButton = styled(SecondaryButton)`
  float: right;
`

const RedButton = styled(Button)`
  margin: 10px 0 0 0;
  width:  calc(100% - 10px);
`

const SelectBox = styled.div`
  display:    block;
  text-align: center;
`

const CatalogBlock = styled.div`
  min-height: 40px;
`

const connector = connect((state: DefaultState) => ({
  catalog: state.data.catalog,
  catalogElement: state.data.catalogElement,
  selectedDataArray: state.data.selectedDataArray,
  editValues: state.data.editValues,
  filterElement: state.filter.filterElement,
  hasEditChanges: state.data.hasEditChanges,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
}), {
  setElements: DataActions.setElements,
  setEditChanges: DataActions.setEditChanges,
  setEditValues: DataActions.setEditValues,
  setCatalogElement: DataActions.setCatalogElement,
  setFilterValues: FilterActions.setFilterValues,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  path: string
  type: string
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCatalogNozzle: string,
  loadingCatalog: boolean
};

export class NozzleCatalog extends Component<Props, State> {
  state = {
    selectedCatalogNozzle: '',
    loadingCatalog: false,
  }

  timeoutId: number | null = null

  componentDidMount () {
    const { setCatalogElement } = this.props

    IpcManager.both.on('loadingCatalog', this.handleLoadingCatalog)

    setCatalogElement()
  }

  componentWillUnmount () {
    IpcManager.both.removeListener('loadingCatalog', this.handleLoadingCatalog)

    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
    }
  }

  handleLoadingCatalog = (event: any, value: State) => {
    if (!value.loadingCatalog) {
      this.timeoutId = window.setTimeout(() => {
        this.setState({
          ...value,
        })
      }, 1000)
    }
    else {
      this.setState({
        ...value,
      })
    }
  }

  handleChange = (event: any) => {
    const { catalog, setCatalogElement } = this.props
    const selectedCatalogNozzle = event.target.value

    this.setState({
      selectedCatalogNozzle,
    })

    if (catalog && (catalog as any)[selectedCatalogNozzle]) {
      const selectedCatalog = (catalog as any)[selectedCatalogNozzle]
      let catalogElement = {}

      const createCatalogElement = (sourceKey: string, targetKey: string) => {
        if ((selectedCatalog as any)[sourceKey]) {
          catalogElement = {
            ...catalogElement,
            [targetKey]: (selectedCatalog as any)[sourceKey],
          }
        }
      }

      createCatalogElement('field5', '_name')
      createCatalogElement('field2', '_nozzletype')
      createCatalogElement('field9', '_anglewidth')
      createCatalogElement('field11', '_anglelength')

      setCatalogElement(catalogElement)
    }
  };

  handleCatalogOption = () => {
    const {
      catalog,
      selectedDataArray,
      setElements,
      setFilterValues,
      path,
      filterElement,
      catalogElement,
      setEditChanges,
      type,
      setEditValues,
      editValues,
      featureFlags,
      hasEditChanges,
    } = this.props

    const { selectedCatalogNozzle } = this.state

    if (catalog && (catalog as any)[selectedCatalogNozzle] && catalogElement) {
      if (!path) {
        setFilterValues({
          ...filterElement,
          Nozzle: {
            ...filterElement.Nozzle,
            ...catalogElement,
          },
        })

        if (FeatureFlags.canEditNozzle(featureFlags)) {
          setEditValues({
            Nozzle: {
              ...editValues.Nozzle,
              ...catalogElement,
            },
          })
        }

        return
      }

      if (!(hasEditChanges as any || {})[type]) {
        setEditChanges(true, type)
      }

      setElements(selectedDataArray, catalogElement)
    }
  };

  handleUploadCatalog = () => {
    IpcManager.both.send('openUploadCatalogDialog')
  };

  render () {
    const { catalog, featureFlags, isLoggedIn, t } = this.props
    const { selectedCatalogNozzle, loadingCatalog } = this.state

    return (
      <Section name={t('catalog.title')} closed>
        <SelectBox>
          {
            catalog && catalog.length
              ? (
                <CatalogBlock>
                  <NozzleCatalogSelect
                    placeholder={selectedCatalogNozzle || '0'}
                    value={selectedCatalogNozzle || ''}
                    onChange={this.handleChange}
                  >
                    <option value='' disabled>{t('catalog.choose')}</option>
                    {
                      catalog && catalog.map((value, index) =>
                        <option value={index} key={index}>
                          {
                            Object.keys(value).map((key, index) => {
                              return `${index < 7
                            ? `${value[`field${index + 1}`] ? `${value[`field${index + 1}`]} | ` : '- | '}`
                            : ''}`
                            })
                          }
                        </option>)
                    }
                  </NozzleCatalogSelect>
                  <UseButton
                    onClick={this.handleCatalogOption}
                    value={t('catalog.use')}
                    title={t('catalog.use')}
                    small
                    disabled={!catalog}
                  />
                  {
                    !isLoggedIn && FeatureFlags.canEditNozzle(featureFlags) &&
                      <RedButton
                        value={t('catalog.change')}
                        title={t('catalog.change')}
                        onClick={this.handleUploadCatalog}
                        loading={loadingCatalog}
                      />
                  }
                </CatalogBlock>
              )
              : !isLoggedIn && FeatureFlags.canEditNozzle(featureFlags)
                ? <RedButton value={t('catalog.open')} onClick={this.handleUploadCatalog} />
                : <RedButton value={t('catalog.found')} disabled />
          }
        </SelectBox>
      </Section>
    )
  }
}

export default withNamespaces('caster')(connector(NozzleCatalog as any) as any) as any
