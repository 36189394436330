import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import styled from 'styled-components'
import { withStyles, createStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Icon from '../../../visualization/dashboard/specific/Icon'

import ApiClient from '../../../../store/apiClient'
import { Network } from '../../../../network/Network'
import TimeUtil from '../../../../logic/TimeUtil'

import * as ApplicationActions from '../../../../store/application/main/actions'
import { AnalyzeTime } from 'Util'

const I = styled.i`
  font-size: 18px;
  font-weight: bold;
  vertical-align: sub;
`

const styles = (_theme:any) => createStyles({
  authorName: {
    fontSize: 13,
    flexBasis: '20%',
    flexShrink: 0,
  },
  action: {
    fontSize: 13,
    flexBasis: '25%',
    flexShrink: 0,
  },
  createdAt: {
    fontSize: 13,
    flexBasis: '55%',
    flexShrink: 0,
    textAlign: 'right',
  },
  content: {
    width: '100%',
  },
  spinner: {
    marginLeft: 'calc(50% - 11px)',
  },
  oldValue: {
    color: '#ff4646',
  },
  newValue: {
    color: '#00c100',
  },
})

const connector = connect(null, {
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  alteration: any,
  classes: any,
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  expanded: boolean,
  content: any | undefined | null
};

class HistoryEntry extends Component<Props & WithNamespaces, State> {
  state = {
    expanded: false,
    content: null,
  }

  // @AnalyzeTime(0)
  handleChange: (event: any, expanded: boolean) => void = () => {
    const { expanded, content } = this.state
    const { alteration } = this.props

    this.setState({
      expanded: !expanded,
    })

    if (!expanded && !content) {
      ApiClient
        .get(`${Network.URI}/caster_history/${alteration.alterationId}`)
        .then(({ alteration }) => {
          delete alteration.RollerBody
          delete alteration.RollerBearing

          this.setState({ content: alteration })
        })
    }
  };

  @AnalyzeTime(0)
  renderTable () {
    const { content } = this.state
    const { previousData, data, elementType } = content || {
      previousData: undefined,
      data: undefined,
      elementType: undefined,
    }
    const { classes, t, alteration: { action } } = this.props
    const displayData = previousData || data || {}
    const changedKeys = Object.keys(displayData).filter(key =>
      !/^(_(numeric|original)Id)|Roller(Body|Bearing)/.test(key))

    return (
      <div>
        <Typography className={classes.content}>
          {
            t(
            `historyElement.info.${elementType === 'Mold' ? 'mold' : action}`,
            { type: elementType, id: (data || { _numericId: undefined })._numericId },
            )
          }
        </Typography>
        <Table>
          <TableBody>
            {
              changedKeys.map(key =>
                <TableRow key={key}>
                  <TableCell align='left'>{key.substring(1)}</TableCell>
                  <TableCell align='right' className={classes.oldValue}>
                    {action !== 'create' ? (displayData as any)[key] : <span>&nbsp;</span>}
                  </TableCell>
                  <TableCell align='center'>
                    <Icon icon={action !== 'create' ? 'long-arrow-alt-right' : 'caret-right'} />
                  </TableCell>
                  <TableCell align='left' className={classes.newValue}>
                    {
                      key === '_id'
                        ? (data || { _numericId: undefined })._numericId
                        : (data || { [key]: undefined })[key]
                    }
                  </TableCell>
                </TableRow>)
            }
          </TableBody>
        </Table>
      </div>
    )
  }

  @AnalyzeTime(0)
  render () {
    const { expanded, content } = this.state
    const { alteration, classes, t } = this.props
    const deleteTranslationProps = {
      type: (content || { elementType: undefined }).elementType,
      id: (content || { data: { _numericId: undefined } }).data._numericId,
    }

    return (
      <ExpansionPanel expanded={expanded} onChange={this.handleChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.authorName}><I className='pe-7s-user' /> {alteration.authorName}</Typography>
          <Typography className={classes.action}>{t(`historyElement.action.${alteration.action}`)}</Typography>
          <Typography className={classes.createdAt}>
            {TimeUtil.getDisplayDateTime(alteration.createdAt)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {
            content && alteration.action === 'delete' &&
              <Typography className={classes.content}>
                {t(`historyElement.info.delete`, deleteTranslationProps)}
              </Typography>
          }
          {
            content && !/^(update|delete|create)$/.test(alteration.action) &&
              <Typography className={classes.content}>
                {t(`historyElement.info.${alteration.action}`)}<br />
                {(((content || {}) as any).data || { _name: undefined })._name || ''}
              </Typography>
          }
          {!content && <CircularProgress color='secondary' className={classes.spinner} size={22} thickness={5} />}
          {content && /^(update|create)$/.test(alteration.action) && this.renderTable()}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withNamespaces('caster')(connector(HistoryEntry)))
