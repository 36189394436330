export enum NozzleActionsEnum {
  ACTION_ADD_NOZZLE = 'Nozzle/ACTION_ADD_NOZZLE',
  DELETE = 'Nozzle/DELETE',
  DELETE_MULTIPLE = 'Nozzle/DELETE_MULTIPLE',
  UPDATE = 'Nozzle/UPDATE',
}

export function addNozzle (nozzleHash: Record<number, Nozzle & Parent>) {
  return {
    type: NozzleActionsEnum.ACTION_ADD_NOZZLE,
    nozzleHash,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const nozzleReducers: Record<string, Function | undefined> = {
  [NozzleActionsEnum.ACTION_ADD_NOZZLE]:
  (_state: NozzleHash, action: {nozzleHash: NozzleHash}) => (
    action.nozzleHash
  ),

  [NozzleActionsEnum.DELETE]: (state: NozzleHash, action: { id: number }) => {
    const newState = { ...state }

    delete newState[action.id]

    return newState
  },

  [NozzleActionsEnum.DELETE_MULTIPLE]: (state: NozzleHash, action: {ids: number[]}) => {
    const newState = { ...state }

    action.ids.forEach(id => {
      delete newState[id]
    })

    return newState
  },

  [NozzleActionsEnum.UPDATE]: (state: NozzleHash, action: {elements: NozzleHash}) => {
    return {
      ...state,
      ...action.elements,
      '#hasChanges': false,
    }
  },
}

export default function (
  state: NozzleHash = {},
  action: any,
) {
  const handler = nozzleReducers[action.type]

  return handler ? handler(state, action) : state
}
