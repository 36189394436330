import { AnalyzeTime } from 'Util'
import SectionView from '.'

export default class CalculationUtil {
  @AnalyzeTime(0)
  static getButtonCoordinates (x: number | null, y: number | null, name: string, buttons: any) {
    const buttonCoordinates: any = {}

    buttonCoordinates.x = (x || ((buttons[name] || {}).position || {}).x || 0)
    buttonCoordinates.y = (y || ((buttons[name] || {}).position || {}).y || 0)

    return buttonCoordinates
  }

  @AnalyzeTime(0)
  static calcSectionPlane (param1: number, param2: number, param3: number, param4: number) {
    return Math.max(
      param1 + param2 * 2,
      param3 * 2,
    ) + param4
  }

  @AnalyzeTime(0)
  static calcCameraZoom (height: number, width: number, min: Coord, max: Coord) {
    return Math.min(width / (max.x - min.x), height / (max.y + 0.5 - min.y)) / 50 * 0.85
  }

  @AnalyzeTime(0)
  static calcMousePosition (mouseValueOnCanvas: number, axisValue: number, dimension: number, isY = false) {
    const valueToAdd = isY ? 1 : -1
    let firstExpression = ((mouseValueOnCanvas - axisValue) / dimension)

    if (isY) {
      firstExpression = -firstExpression
    }

    return (firstExpression * 2 + valueToAdd)
  }

  @AnalyzeTime(0)
  static getActualSegmentGroup (view: SectionView, currentPsslnPosition: number) {
    if (!currentPsslnPosition || view.jumpOver === null) {
      const firstSGName = (view.elementsHashes.SegmentGroup[1] || {} as any)._name || ''

      return {
        id: 1,
        name: firstSGName,
      }
    }

    if (view.jumpOver === 'DataPoint') {
      const id = this.getActualSegmentGroupDataPointCase(view, currentPsslnPosition)
      const name = (view.elementsHashes.SegmentGroup[id] || {} as any)._name || ''

      return {
        id,
        name,
      }
    }

    const elementTypeHash = view.elementsHashes[view.jumpOver]
    const elements = Object.values(elementTypeHash)
    const segmentId = ((elements
      .find(element => element._passln_coord === currentPsslnPosition) || {})['#parent'] || {}).id

    if (!segmentId) {
      return {
        id: 1,
        name: '',
      }
    }

    const segmentGroupId = (view.elementsHashes.Segment[segmentId] || {} as any)['#parent'].id || 1
    const segmentGroupName = (view.elementsHashes.SegmentGroup[segmentGroupId] || {} as any)._name || ''

    return {
      id: segmentGroupId,
      name: segmentGroupName,
    }
  }

  // calculate get actual segment group when jumping over data points
  @AnalyzeTime(0)
  static getActualSegmentGroupDataPointCase (view: SectionView, currentPsslnPosition: number) {
    // search for the nearest nozzle or roller to the current pssln position
    const nozzles = Object.values(view.elementsHashes.Nozzle)
    const rollers = Object.values(view.elementsHashes.Roller)
    const segmentGroups = Object.values(view.elementsHashes.SegmentGroup)

    const { element: nozzle, difference: d1 } = CalculationUtil.findNearestElementToPassLn(
      nozzles,
      currentPsslnPosition,
    )
    const { element: roller, difference: d2 } = CalculationUtil.findNearestElementToPassLn(
      rollers,
      currentPsslnPosition,
    )
    const { element: SegmentGroup, difference: d3 } = CalculationUtil.findNearestElementToPassLn(
      segmentGroups,
      currentPsslnPosition,
    )

    if (!nozzle && !roller && !SegmentGroup) {
      return 1
    }

    if (d3 < d2 && d3 < d1 && SegmentGroup) {
      return SegmentGroup._id
    }

    if (d1 < d2 && nozzle) {
      const segmentId = (nozzle || {} as any)['#parent'].id

      return ((view.elementsHashes.Segment[segmentId] || {} as any)['#parent'] || {}).id || 1
    }

    if (!roller) {
      return 1
    }

    const segmentId = (roller || {} as any)['#parent'].id

    return ((view.elementsHashes.Segment[segmentId] || {} as any)['#parent'] || {}).id || 1
  }

  static findNearestElementToPassLn (elements: any[], currentPasslnPosition: number) {
    let minDistance = Infinity
    let index = 0
    const elementCount = elements.length

    for (let i = 0; i < elementCount; i++) {
      const element = elements[i]
      const distance = Math.abs(currentPasslnPosition - element._passln_coord)

      if (distance < minDistance) {
        minDistance = distance
        index = i
      }
    }

    return { element: elements[index] || null, difference: minDistance }
  }
}
