import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import sizeMe from 'react-sizeme'

import Util from 'logic/Util'
import NetworkManager from 'network/NetworkManager'

import logic from './logic'
import { Wrapper, TextContainer, Label, Value, pad } from './styles'

import * as VisualizationActions from 'store/visualization/actions'

import { DefaultState } from 'types/state'
import { Definition } from 'types/visualization'
import { AnalyzeTime } from 'Util'

const connector = connect(({ visualization }: DefaultState) => ({
  tileConfigs: visualization.tileConfigs,
  plotConfigs: visualization.plotConfigs,
  data: visualization.data,
  isNewData: visualization.isNewData,
  networkStatus: visualization.networkStatus,
}), {
  updatedPlot: VisualizationActions.updatedPlot,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  width: number,
  height: number,
  dynamicData: Array<any>,
  tileId: string,
  configId: string,
  viewId: string,
  xLabel: string,
  yLabel: string,
  type: 'line' | 'bar' | 'gage' | 'contour' | 'pie' | 'area' | 'text',
  configIds: number[],
  yDomain: number[],
  xDomain: number[],
  xRange: number[],
  valueRange: number[],
  shapeIds?: number[],
  radiusDomain?: any[],
  radius0?: number,
  radius?: number,
  frequency: number,
  flipAxes: boolean,
  isDynamicData: boolean,
}

type State = {
  data: any[]
};

class TextWrapper extends Component<Props, State> {
  didCreate: boolean;
  value: Array<any> = [];
  wrapperRef?: HTMLElement;

  constructor (props: Props) {
    super(props)

    this.didCreate = false
  }

  state: State = {
    data: [],
  }

  @AnalyzeTime(0)
  componentDidUpdate (prevProps: Props) {
    const { data } = this.state

    const forceReload = data && data.length === 0

    logic.connect(prevProps, this.props, forceReload, this.setData)

    this.handleResize()
  }

  @AnalyzeTime(0)
  componentWillUnmount () {
    const { tileId } = this.props

    NetworkManager.removePlot(tileId)
  }

  // @AnalyzeTime(0)
  handleMouseDown = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const offset = event.pageY - this.wrapperRef.getBoundingClientRect().top
    const containerHeight = this.wrapperRef.getBoundingClientRect().height

    if (event.button === 0) {
      if (offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: -10,
        })
      }

      if (containerHeight - offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: 10,
        })
      }
    }
  };

  // @AnalyzeTime(0)
  handleWheel = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const target = this.wrapperRef

    target.scrollTop += event.deltaY

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }
  };

  // @AnalyzeTime(0)
  handleWrapperRef = (ref: any) => {
    if (ref) {
      this.wrapperRef = ref
    }
  };

  // @AnalyzeTime(0)
  handleResize = () => {
    this.handleWheel({
      target: this.wrapperRef,
      deltaY: 0,
    })
  };

  // @AnalyzeTime(0)
  setData = ({ tileId, definitions }:{tileId: string, definitions: Definition[]}) => {
    const { data: stateData } = this.state
    const { tileConfigs, isDynamicData, dynamicData, configId } = this.props

    const defaultData = logic.getData(this.props)

    if (isDynamicData) {
      defaultData.map((data, index) => {
        defaultData[index].name = configId
        defaultData[index].x = dynamicData.map(el => el.x)
        defaultData[index].y = dynamicData.map(el => el.y)
      })

      this.setState({
        data: defaultData,
      })

      return
    }

    const data = []

    for (let i = 0; i < definitions.length; i++) {
      const dataConfigId = definitions[i].id
      const rawValues = definitions[i].data

      data.push({
        ...defaultData[0],
        y: (rawValues || []).map((value: any) => Util.handleFormula(value, tileConfigs[tileId].dynamicFormula || '')),
        name: dataConfigId,
      })
    }

    if (!data.equals(stateData)) {
      this.setState({
        data,
      })
    }
  };

  @AnalyzeTime(0)
  render () {
    const { data } = this.state
    const { width, height, plotConfigs, configId, tileConfigs, tileId, isDynamicData } = this.props
    const nWidth = width ? width - 10 : 1
    const nHeight = height ? height - 10 : 1

    const tConf = tileConfigs[tileId]
    const conf = plotConfigs[configId]
    const defaultLabels: string[] = []

    data.forEach(val => {
      if ((conf && conf.key) || (plotConfigs[val.name] && plotConfigs[val.name].key)) {
        defaultLabels.push((conf.key ? conf : plotConfigs[val.name]).key.split('_').join(' '))
      }
    })

    return (
      <Wrapper
        id={`text_${tileId}`}
        width={nWidth}
        height={nHeight - pad}
        count={data ? data.length : 0}
        ref={this.handleWrapperRef}
        onWheel={this.handleWheel}
        onResize={this.handleResize}
        onMouseDown={this.handleMouseDown}
      >
        {
          Boolean(data) && data.map((val, i) => {
            const label = isDynamicData
              ? (tConf.label || {} as any)[val.name] || val.x[0]
              : ((tConf.label || {} as any)[val.name] || defaultLabels[i] || '').replace('[C]', '[°C]')

            const value = /^[0-9]/.test(val.y[0]) ? Number(val.y[0]).toFixed(2) : val.y[0] || ''

            return (
              <TextContainer key={i} className='data-export' data-export-label={label} data-export-value={value}>
                <Label title={label}>{label}</Label>
                <Value>{value}</Value>
              </TextContainer>
            )
          })
        }
      </Wrapper>
    )
  }
}

export default sizeMe()(connector(TextWrapper as any) as any) as any
