import * as THREE from 'three'

import Util from '../../logic/Util'
import MainView from '../MainView'
import PasslineCurve from '../../objects/PasslineCurve'
import Getters from './Getters'
import DrawHandlers from './DrawHandlers'
import SectionUtil from './SectionUtil'
import ConditionUtil from './ConditionUtil'
import SectionHandlers from './SectionHandlers'
import CameraHandlers from './CameraHandlers'
import EventHandlers from './EventHandlers'
import CalculationUtil from '../MainView/CalculationUtil'
import { Views } from 'three/ThreeBase'
import { Group, Mesh, Vector2 } from 'three'
import type { PassedData } from 'react/Caster'

export default class SectionView extends MainView {
  static planeMaterial = new THREE.MeshBasicMaterial({ color: '#22282e', transparent: true, opacity: 0.85 })
  static planeHeaderMaterial = new THREE.MeshBasicMaterial({ color: '#212529' })
  static headerPartingMaterial = new THREE.MeshBasicMaterial({ color: '#000000' })
  static strandMaterial = new THREE.MeshBasicMaterial({ color: '#8c7200' }) // #FFD931
  static minArrowColor = '#f5f603'
  static maxArrowColor = '#f5b903'
  static maxStrandMaterial = new THREE.MeshBasicMaterial({ color: '#463900' })
  static minStrandLineMaterial = new THREE.LineBasicMaterial({
    color: this.minArrowColor,
    linewidth: 2,
  })

  static maxStrandLineMaterial = new THREE.LineBasicMaterial({
    color: this.maxArrowColor,
    linewidth: 2,
  })

  static labelLineMaterial = new THREE.LineBasicMaterial({ color: '#FFFFFF' })
  static rollerBodyMaterial = new THREE.MeshBasicMaterial({ color: '#3a6ce0', transparent: true, opacity: 0.5 })
  static rollerBearingMaterial = new THREE.MeshBasicMaterial({ color: '#383838', transparent: true, opacity: 0.5 })
  static rollerLineMaterial = new THREE.LineBasicMaterial({ color: '#4ffaff' })
  static rollerBearingLineMaterial = new THREE.LineBasicMaterial({ color: '#6a4dff' })
  static currentSegmentGroup = { id: 1, name: '1' }
  static currentSegmentGroupNameChanged = false

  static debug = false // TODO: set as command line argument

  updatePlane
  viewMode
  buttons: any
  side
  rollerGroup: any
  statusPrevNext
  additionalLayerType: 'Nozzle' | 'Roller'
  jumpOver: JumpOverOptions | null
  sectionPlaneHeader?: any
  sectionPartingLineHeader?: any
  jumpUp?: boolean
  jumpDown?: boolean
  setTooltip?: (key: string, visible: boolean, tooltips?: any[]) => void
  tooltip?: any
  sectionViewExpanded: boolean
  targetHeight?: number
  lastDrawnScrollValue?: number
  sectionPlaneWidth?: number
  sectionPlaneHeight?: number
  passLineCoordinates?: any
  strand?: Mesh
  minStrand?: Group
  maxStrand?: Group
  buttonBackground?: Mesh
  buttonBackgroundLine?: Mesh & { name: string }
  perspectiveCamera: any
  nozzleGroup?: any
  minPasslineCoord = 0
  maxPasslineCoord = 0
  heightPos = 0

  constructor (renderer: THREE.WebGLRenderer, views: Views, splitMode = 0) {
    super(renderer, views, splitMode)

    SectionView.staticClassName = 'SectionView'
    this.className = 'SectionView'

    // noinspection JSUnusedGlobalSymbols
    this.sectionDetail = true
    this.updatePlane = true
    this.sectionPlane.visible = false
    this.additionalLayerType = 'Nozzle'

    this.viewMode = false
    this.buttons = {}
    this.side = {
      FixedSide: true,
      LooseSide: true,
      NarrowFaceLeft: true,
      NarrowFaceRight: true,
    }

    this.rollerGroup = {}

    // noinspection JSUnusedGlobalSymbols
    this.spacer = 0.5
    this.largestNarrowNozzle = 0
    this.largestNozzle = 0
    this.widestNozzle = 0
    this.widestNarrowNozzle = 0
    // false = next/ true = previous
    this.statusPrevNext = false
    this.additionalLayerType = 'Nozzle'
    this.sectionViewExpanded = true

    this.jumpOver = 'Roller'

    const near = 0.1
    const far = 0.101

    SectionHandlers.setupRaycaster(this, near, far)

    SectionHandlers.setupCamera(this, near, far)

    SectionHandlers.setupControls(this)

    if (this.gridHelper) {
      this.scene.remove(this.gridHelper)
    }

    // section plane folded is the box where everything is displayed
    SectionHandlers.setupSectionPlaneFolded(this)

    Util.addOrReplace(this.scene, this.sectionPlaneFolded)
  }

  // Overwrite
  updateRoller () {
    // empty
  }

  setData (data: PassedData) {
    // TODO: improve this, we could just send a key in the data object
    const hasNewData = !(data.rootData || {}).equals(this.data || {})
    const termChanged = this.term !== data.term

    super.setData(data)

    this.setTooltip = data.setTooltip
    this.tooltip = data.tooltip
    this.isNewCaster = data.isNewCaster

    if (hasNewData) {
      this.data = data.rootData
      this.updatePlane = true
    }

    if (hasNewData || termChanged) {
      this.updateMinAndMaxPasslineCoordinates()
    }

    if (this.data && this.data.Caster && this.largestNarrowNozzle) {
      this.updateTransform(this.redraw)
    }

    if (this.isNewCaster) {
      this.sectionViewExpanded = false
      SectionHandlers.hideAllButtons(this.buttons)
    }

    if (this.data && this.data.Caster && (this.updatePlane || hasNewData || (this.dirtyList || []).length)) {
      this.emptyPrevOrNextElements()
      SectionHandlers.handleUpdatePlane(this)
    }
  }

  emptyPrevOrNextElements () {
    const prevOrNextElementsGroup = this.scene.getObjectByName('PrevOrNextElements')

    if (prevOrNextElementsGroup) {
      for (let i = prevOrNextElementsGroup.children.length - 1; i >= 0; i--) {
        const obj = prevOrNextElementsGroup.children[i]

        if (obj) {
          prevOrNextElementsGroup.remove(obj)
        }
      }
    }
  }

  setView (targetHeight = Infinity, forceUpdate = false) {
    const jump = !!(this.jumpUp || this.jumpDown)

    if (
      !forceUpdate &&
      (
        ConditionUtil.noListOrNozzleOrRoller(this.elementList) ||
        (targetHeight === this.targetHeight && !jump)
      )
    ) {
      return
    }

    if (forceUpdate) {
      targetHeight = this.targetHeight || 0
    }

    const jumpDirection = SectionUtil.getJumpDirection(this.jumpUp, this.jumpDown)

    if (jumpDirection !== 0) {
      this.jumpUp = false
      this.jumpDown = false
    }

    this.targetHeight = targetHeight
    // TODO: is this needed?
    // this.handleVisibility()

    if (!this.elementList.Nozzle && !this.elementList.Roller) {
      return
    }

    const yCoordinates = Getters.getCurrentElementListYPositionsInOrderDesc(this)

    if (!yCoordinates.length) {
      return
    }

    const { position, center, heightPos } = Getters.getCenterAndPosition(
      yCoordinates,
      jumpDirection,
      targetHeight,
      jump,
      this.center2d || { x: 0, y: 0 },
      this.heightPos,
    )

    SectionView.debug
      ? this.setCameraPosition(new THREE.Vector3(7, position.y + 10, 20), center, this.camera, this.controls)
      : this.setCameraPosition(position, center, this.camera, this.controls)

    const { position: pos, angleX } = PasslineCurve.getInfoAtPlCoord(this.plHeight - heightPos)

    Util.flipXZ(pos)
    pos.multiply(new THREE.Vector3(-1, 1, 1))

    if (this.views && this.views.mainView && this.views.uiView) {
      this.views.mainView.sectionPlane.position.copy(pos)
      this.views.mainView.sectionPlane.rotation.y = angleX

      this.sectionPlaneFolded.position.set(0, heightPos - 0.0001, 0)
      this.heightPos = heightPos
      this.views.uiView.scrollValue = (heightPos - this.plHeight) / -this.plHeight
      this.views.uiView.setButtonPos()
    }
  }

  // TODO: rework if needed because this ignores the filter...
  // handleVisibility () {
  //   const hideType = !this.jumpOverNozzles ? 'Nozzle' : 'Roller'
  //   const showType = this.jumpOverNozzles ? 'Nozzle' : 'Roller'

  //   if (!this.elementList[hideType] || !this.elementList[showType]) {
  //     return
  //   }

  //   Object.values(this.elementList[hideType]).forEach(({ path }: any) => {
  //     if (this.elementList[hideType] && this.elementList[hideType][path]) {
  //       this.elementList[hideType][path].hide()
  //     }
  //   })

  //   Object.values(this.elementList[showType]).forEach(({ path }: any) => {
  //     if (this.elementList[showType] && this.elementList[showType][path]) {
  //       this.elementList[showType][path].show()
  //     }
  //   })
  // }

  handleMouseMove (_event: any, mouseOnCanvas: Vector2) {
    const { x, y, width, height } = this.viewport
    const tooltipList = (this.tooltip || {})[SectionView.staticClassName] || []

    const mouse = Getters.getMouse(mouseOnCanvas, x, y, width, height)

    this.raycaster.setFromCamera(mouse, this.camera)

    if (ConditionUtil.mouseIsOutsideOfSectionView(this.sectionPlaneFolded, this.sectionPlaneHeader, this.raycaster)) {
      if (tooltipList.length && this.setTooltip) {
        this.setTooltip(SectionView.staticClassName, false)
      }

      return
    }

    const { tooltips, snaps } = Getters.getInsersectedTooltipsAndSnaps(this.tooltipObjects, this.raycaster)

    if (snaps.length && !tooltips.length) {
      CalculationUtil.orderSnapsByDistanceToMouse(this.camera, mouse, snaps)

      SectionHandlers.showClosestSnapsTooltip(snaps[0], tooltips)
    }

    if (tooltips.length) {
      if (!tooltipList.equals(tooltips) && this.setTooltip) {
        this.setTooltip(SectionView.staticClassName, true, tooltips)
      }

      return
    }

    if (tooltipList.length && this.setTooltip) {
      this.setTooltip(SectionView.staticClassName, false)
    }
  }

  handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    if (intersects.length) {
      EventHandlers.handleActions(intersects, this)

      return false
    }

    if (this.selection.length && this.views?.mainView?.jumpToFilter) {
      this.views.mainView.jumpToFilter(this.selection.join(' '))
    }

    return intersects
  }

  handleKeyDown () {
    // override
  }

  render (): void {
    if (!this.sectionPlaneFolded.visible) {
      return
    }

    super.render()
  }

  resize (width: number, height: number) {
    if (this.splitMode === 0) {
      super.resize(0, 0)

      return
    }

    super.resize(width, height)

    CameraHandlers.setZoom(this)

    CameraHandlers.updateCamera(this.viewport, this.camera)
  }

  handleKeyUp (_event: any) {
    // override
  }

  animate () {
    if (this.sectionPlaneFolded.visible) {
      this.setView(this.plHeight - this.plHeight * (this.views?.uiView?.scrollValue || 1))
      DrawHandlers.drawPassLineCoordinates(this)
    }
  }

  showSection (width: number, height: number) {
    this.sectionPlaneFolded.visible = true
    this.splitMode = 1
    this.resize(width, height)
  }

  hideSection () {
    this.sectionPlaneFolded.visible = false
    this.splitMode = 0
    this.resize(0, 0)
  }

  updateTransform (force = false) {
    if (!force && !this.isNewCaster) {
      return
    }

    this.lastDrawnScrollValue = 0

    DrawHandlers.drawPassLineCoordinates(this)

    DrawHandlers.drawAllSectionViewSwitches(this)
  }

  getMinAndMaxPasslineCoordinates (): { min: number, max: number } {
    if (!this.elementList.Nozzle && !this.elementList.Roller) {
      return { min: 0, max: 0 }
    }

    const passlnCoordinates = Getters.getCurrentElementListPasslineCoordinates(this)

    if (!passlnCoordinates.length) {
      return { min: 0, max: 0 }
    }

    return { min: Math.min(...passlnCoordinates), max: Math.max(...passlnCoordinates) }
  }

  updateMinAndMaxPasslineCoordinates () {
    const { min, max } = this.getMinAndMaxPasslineCoordinates()

    this.minPasslineCoord = min
    this.maxPasslineCoord = max
  }
}
