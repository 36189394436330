import * as THREE from 'three'
import React from 'react'

import Util from '../logic/Util'

import BaseObject from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Mold from './Mold'
import { Const } from './Const'
import { AnalyzeTime } from 'Util'

export default class Nozzle extends BaseObject {
  static _3DGeometryCache: Record<string, THREE.ConeGeometry> = {}
  static _2DGeometryCache: Record<string, THREE.BufferGeometry> = {}

  static selectedMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41' })
  static selectedMaterialBasic = new THREE.MeshBasicMaterial({ color: '#7eda41' })
  static phantomMaterial = new THREE.MeshStandardMaterial({ color: '#7eda41', transparent: true, opacity: 0.6 })
  static phantomMaterialBasic = new THREE.MeshBasicMaterial({ color: '#7eda41', transparent: true, opacity: 0.6 })
  static deletedMaterial = new THREE.MeshStandardMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })
  static deletedMaterialBasic = new THREE.MeshBasicMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })
  static selectedDeletedMaterial = new THREE.MeshStandardMaterial({ color: '#da4515', transparent: true, opacity: 0.4 })
  static selectedDeletedMaterialBasic = new THREE.MeshBasicMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.4,
  })

  tooltipObjects: any
  wCoord: number
  plCoord: number
  loopColorIndex?: number
  sectionDetail: any
  isPhantom?: boolean

  static loopColoredMaterials = Const.colors.map(color => new THREE.MeshStandardMaterial({ color }))
  static loopColoredMaterialsBasic = Const.colors.map(color => new THREE.MeshBasicMaterial({
    color,
    transparent: true,
    opacity: 0.6,
  }))

  static loopColoredMaterialsLine = Const.colors.map(color => new THREE.LineBasicMaterial({
    color: Util.updateColor(color, 66),
    linewidth: 1,
  }))

  static loopColorHelper = Const.colors.length

  static coneRadius = 0.5
  static rectangularScale = Nozzle.coneRadius / Math.sqrt(Math.pow(Nozzle.coneRadius, 2) / 2)
  // rectangularScale is about Math.sqrt(2) if coneRadius === 0.5

  dispose (): void {
    super.dispose()

    if (!this.container || !this.container.children) {
      return
    }

    for (const child of this.container.children) {
      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      this.container.remove(child)
    }

    this.objects = {}
  }

  @AnalyzeTime(0)
  static getSections (format: string, ratio: number) {
    switch (format) {
      case 'conic': return ratio > 0.5 ? 8 : 4 // 8 % 2 === 0 (see rectangularScale)
      case 'rectangular': return 4
      default: return 4
    }
  }

  @AnalyzeTime(0)
  static getB (a: number, beta: number) {
    const sinAlpha = Math.sin((90 - beta) * Util.RAD)
    const sinBeta = Math.sin(beta * Util.RAD)

    return (a / sinAlpha) * sinBeta
  }

  @AnalyzeTime(0)
  static get3DGeometry (nozzleData: any) {
    const {
      _anglelength,
      _anglewidth,
      _format,
      _height,
    } = nozzleData

    const height = _height / 1000

    let scale = 1

    if (_format === 'rectangular') {
      scale = Nozzle.rectangularScale
    }

    const diameterWidth = Nozzle.getB(height, _anglewidth / 2) * 2 * scale

    const segments = Nozzle.getSections(_format, Math.abs(_anglelength / _anglewidth))

    const geometryKey = `${Nozzle.coneRadius}_${height}_${_anglelength}_${_anglewidth}_${segments}_${_format}`
    let geometry = Nozzle._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.ConeGeometry(Nozzle.coneRadius, height, segments) // no Buffer!

      ;(THREE as any).GeometryUtils.computeVertexNormals(geometry, 80)

      if (_format === 'rectangular') {
        geometry.rotateY(45 * Util.RAD)
      }

      const diameterLength = Nozzle.getB(height, _anglelength / 2) * 2 * scale;

      (geometry as any)
        .applyMatrix(new THREE.Matrix4().makeScale(diameterWidth || 0.00001, 1, diameterLength || 0.00001))

      ;(THREE.GeometryUtils as any).computeVertexNormals(geometry, 80)

      geometry.translate(0, _height / 1000 / 2, 0)

      Nozzle._3DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  @AnalyzeTime(0)
  static get2DGeometry (nozzleData: any) {
    const {
      _anglewidth,
      _format,
      _height,
    } = nozzleData

    const height = _height / 1000

    const geometryKey = `${Nozzle.coneRadius}_${height}_${_anglewidth}_${_format}`
    let geometry = Nozzle._2DGeometryCache[geometryKey]

    if (!geometry) {
      let scale = 1

      if (_format === 'rectangular') {
        scale = Nozzle.rectangularScale
      }

      const diameterWidth = Nozzle.getB(height, _anglewidth / 2) * 2 * scale
      const dia = diameterWidth / scale / 2

      const a = new THREE.Vector3(dia, 0, 0)
      const b = new THREE.Vector3(-dia, 0, 0)
      const c = new THREE.Vector3(0, 0, -height)
      const mesh = Util.getTriangleMesh(a, c, b)

      geometry = mesh.geometry

      Nozzle._2DGeometryCache[geometryKey] = geometry
    }

    return geometry
  }

  constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent)

    this.tooltipObjects = tooltipObjects

    const geometryKey = `initial`
    let geometry = Nozzle._3DGeometryCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.ConeGeometry(Nozzle.coneRadius, 1, 4) // no Buffer!

      ;(THREE as any).GeometryUtils.computeVertexNormals(geometry, 80)

      Nozzle._3DGeometryCache[geometryKey] = geometry
    }

    this.objects.nozzle = new THREE.Mesh(geometry, Nozzle.loopColoredMaterials[0])
    this.objects.nozzle.type = 'Nozzle'

    this.wCoord = 0
    this.plCoord = 0

    clickableObjects.push(this.objects.nozzle)
    container.add(this.objects.nozzle)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  @AnalyzeTime(0)// @ts-ignore
  setValues (
    nozzleData: any,
    plHeight: number,
    path: string,
    isDeleted: boolean,
    isHidden: boolean,
    loopColorIndex: number,
    sectionDetail: any,
    isPhantom = false,
  ) {
    super.setValues(path, isHidden)

    this.loopColorIndex = loopColorIndex
    this.sectionDetail = sectionDetail
    this.isPhantom = isPhantom

    // eslint-disable-next-line camelcase
    const {
      _anglewidth,
      _format,
      _height,
      _passln_coord: passlineCoord,
      _width_coord: widthCoord,
    } = nozzleData

    const { nozzle } = this.objects
    const height = _height / 1000

    this.wCoord = widthCoord / 1000
    this.plCoord = passlineCoord / 1000

    let scale = 1

    if (_format === 'rectangular') {
      scale = Nozzle.rectangularScale
    }

    const diameterWidth = Nozzle.getB(height, _anglewidth / 2) * 2 * scale
    const colorIndex = this.loopColorIndex % Nozzle.loopColorHelper

    if (!this.sectionDetail) {
      const geometry = Nozzle.get3DGeometry(nozzleData)

      nozzle.geometry = geometry
      nozzle.material = !isPhantom
        ? Nozzle.loopColoredMaterials[colorIndex]
        : Nozzle.phantomMaterial
    }
    else {
      // get rid of the scale if it's other than 1
      const dia = diameterWidth / scale / 2

      const a = new THREE.Vector3(dia, 0, 0)
      const b = new THREE.Vector3(-dia, 0, 0)
      const c = new THREE.Vector3(0, 0, -height)

      nozzle.geometry = Nozzle.get2DGeometry(nozzleData)

      nozzle.material = !isPhantom
        ? Nozzle.loopColoredMaterialsBasic[colorIndex]
        : Nozzle.phantomMaterialBasic

      const wireFrame = new THREE.Group()
      const material = Nozzle.loopColoredMaterialsLine[colorIndex]

      Util.drawLine(wireFrame, a.x, a.y, a.z, b.x, b.y, b.z, 'WireFrameLineAB', material)
      Util.drawLine(wireFrame, b.x, b.y, b.z, c.x, c.y, c.z, 'WireFrameLineBC', material)
      Util.drawLine(wireFrame, c.x, c.y, c.z, a.x, a.y, a.z, 'WireFrameLineCA', material)

      wireFrame.position.y += 0.0001
      wireFrame.name = 'WireFrame'

      Util.addOrReplace(nozzle, wireFrame)

      if (!isPhantom) {
        const offset = dia * 1000
        const { id } = Util.getElementInfo(path)
        const { side } = this.container.userData

        const s = side === 'LooseSide' || side === 'NarrowFaceRight' ? -1 : 1

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerHeight_${side}_${id}`,
          c,
          (
            <div>
              <b>Nozzle:{id}</b><br />
              <br />
              width_coord: {widthCoord}<br />
              height: {_height}<br />
              FixedLooseSide: {side}
            </div>
          ),
        )

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerR_${side}_${id}`,
          b,
          (
            <div>
              <b>Nozzle:{id}</b><br />
              <br />
              width_coord: {(Number(widthCoord) - offset * s).toFixed(2)}<br />
              FixedLooseSide: {side}
            </div>
          ),
        )

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerL_${side}_${id}`,
          a,
          (
            <div>
              <b>Nozzle:{id}</b><br />
              <br />
              width_coord: {(Number(widthCoord) + offset * s).toFixed(2)}<br />
              FixedLooseSide: {side}
            </div>
          ),
        )
      }
    }

    this.updateTransform()

    nozzle.geometryNeedsUpdate = true
    nozzle.path = path

    this.objects.nozzle.userData.deleted = isDeleted

    this.setSelected(this.objects.nozzle.userData.selected)
  }

  @AnalyzeTime(0)
  updateTransform () {
    const { nozzle } = this.objects
    const { side } = this.container.userData
    const { position, angleX, normal } =
      PasslineCurve.getInfoAtPlCoord(this.plCoord || 0, this.sectionDetail ? true : undefined)

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    switch (side) {
      case 'FixedSide':
        newPosition.set(this.wCoord, position.y, position.z + FixedSide.x)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case 'LooseSide':
        newPosition.set(this.wCoord, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        newSectionRotation.y = Util.RAD180
        break
      case 'NarrowFaceRight':
        newPosition.set(NarrowFaceRight.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        newSectionRotation.y = Util.RAD90
        break
      case 'NarrowFaceLeft':
        newPosition.set(NarrowFaceLeft.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        newSectionRotation.y = -Util.RAD90
        break
      default:
    }

    nozzle.position.copy(newPosition)
    nozzle.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  // noinspection JSUnusedGlobalSymbols
  @AnalyzeTime(0)
  getMeasures () {
    const { geometry, position: { y } } = this.objects.nozzle

    geometry.computeBoundingBox()

    const { min, max } = geometry.boundingBox

    return {
      heightMin: y + min.z,
      heightMax: y + max.z,
    }
  }

  @AnalyzeTime(0)
  setSelected (isSelected: boolean) {
    const { deleted } = this.objects.nozzle.userData

    if (this.isPhantom) {
      return
    }

    this.objects.nozzle.userData.selected = isSelected

    if (!this.sectionDetail) {
      this.objects.nozzle.material = isSelected
        ? (deleted ? Nozzle.selectedDeletedMaterial : Nozzle.selectedMaterial)
        : (deleted ? Nozzle.deletedMaterial
          : Nozzle.loopColoredMaterials[(this.loopColorIndex || 0) % Nozzle.loopColorHelper]) ||
          Nozzle.loopColoredMaterials[0]
    }
    else {
      this.objects.nozzle.material = isSelected
        ? (deleted ? Nozzle.selectedDeletedMaterialBasic : Nozzle.selectedMaterialBasic)
        : (deleted ? Nozzle.deletedMaterialBasic
          : Nozzle.loopColoredMaterialsBasic[(this.loopColorIndex || 0) % Nozzle.loopColorHelper]) ||
          Nozzle.loopColoredMaterialsBasic[0]
    }
  }
}
